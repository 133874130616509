import { Box, Button, styled, InputBase } from "@mui/material";

// styled components
export const SocialIconButton = styled(Button)(({ theme }) => ({
  width: "48%",
  height: 48,
  fontSize: 13,
  borderRadius: "6px",
  border: "2px solid",
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.text.secondary
      : theme.palette.divider,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "0.5rem",
  },
}));

export const TextFieldWrapper = styled(Box)(({ theme }) => ({
  width: "48%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "0.5rem",
  },
}));

export const FullTextFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "0.5rem",
  },
}));

export const StyledSelectInput = styled(InputBase)(({ theme }) => ({
  height: 40,
  fontSize: 12,
  width: "100%",
  fontWeight: 600,
  padding: "0 8px",
  border: "2px solid",
  borderRadius: "8px",
  color: theme.palette.text.primary,
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[300]
      : theme.palette.divider,
  "& .MuiPopover-paper": { boxShadow: "none" },
  "& > .MuiSelect-select": { paddingRight: "0 !important" },
}));
