import { CircularProgress } from "@mui/material";
import FlexBox from "./FlexBox";

const LoadingWrapper = ({ bg }: { bg?: string }) => {
  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        ...(bg
          ? { background: bg }
          : {
              background: "#E5E5E5",
              boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            }),
      }}
    >
      <CircularProgress />
    </FlexBox>
  );
};

export default LoadingWrapper;
