export type FeedbackGraph = {
  store: string;
  data: {
    lastWeek: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
    lastMonth: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
    lastQuarter: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
    lastYear: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
    allTime: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
    custom: {
      data: {
        complaints: never[];
        compliments: never[];
        suggestions: never[];
      };
    };
  };
}[];

export const FeedbackGraphInit: FeedbackGraph = [];

export const SentimentOverTimeGraphInit = {
  lastWeek: {
    data: {
      positive: [],
      negative: [],
      overall: [],
    },
  },
  lastMonth: {
    data: {
      positive: [],
      negative: [],
      overall: [],
    },
  },
  lastQuarter: {
    data: {
      positive: [],
      negative: [],
      overall: [],
    },
  },
  lastYear: {
    data: {
      positive: [],
      negative: [],
      overall: [],
    },
  },
  allTime: {
    data: {
      positive: [],
      negative: [],
      overall: [],
    },
  },
  custom: {
    data: {
      complaints: [],
      compliments: [],
      suggestions: [],
    },
  },
};
