import { Box, Button, FormHelperText } from "@mui/material";
import { useState, useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr } from "./AddGithub";
import { getGithubRepositories } from "../../services/api/Apps.api";
import FlexBox from "../shared/wrappers/FlexBox";
import { primary } from "../shared/theme/themeColors";

const Config = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    setRepositories,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    name: string;
    token: string;
  } = { name: "", token: "", ...formData };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("App name is required"),
    token: Yup.string().required("Personal Access Token is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        setError("");
        try {
          if (values.name !== "" && values.token !== "") {
            const data = { ...formData, ...values };
            const response = await getGithubRepositories(values.token);
            if (response && response.body && response.body.length) {
              let body = JSON.parse(response.body);
              if (body && body.message) {
                setError(
                  "We are facing some issue with your provided configuration"
                );
              } else if (body && body.length) {
                setRepositories(JSON.parse(response.body));
                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
              }
            }
          }
        } catch (e: any) {
          setError(e.message);
        }

        setLoading(false);
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Provide config details
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"App Name"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"name"}
              type={"name"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <Small>
              This is the name that'll be assigned to the imported github issues
            </Small>
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Personal Access Token (PAT)"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"token"}
              type={"token"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.token || ""}
              error={Boolean(touched.token && errors.token)}
              helperText={touched.token && errors.token}
            />
            <Small>
              You can create a new Personal Access Token (PAT) from{" "}
              <a
                href="https://github.com/settings/tokens"
                target="_blank"
                rel="noreferrer"
                style={{
                  fontWeight: 700,
                  fontStyle: "italic",
                  // textDecorationLine: "underline",
                  color: primary.main,
                }}
              >
                here
              </a>
            </Small>
          </FullTextFieldWrapper>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching repositories"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Config;
