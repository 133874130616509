import {
  Card,
  Box,
  Button,
  Grid,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useState, useEffect, useMemo } from "react";
import {
  searchPlayStoreApps,
  addPlayStoreApp,
  addAppleStoreApp,
  createOrEditOrganization,
  // dbCleanup,
} from "../../services/api/Apps.api";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getUserDetails } from "../../services/api/Graphql.api";
import {
  ADMIN_MANAGEMENT_TYPE,
  determineAppStatus,
  getUserAppIDs,
  importPageExtention,
  SETTINGS_PAGE,
  setUserDataAndRole,
} from "../shared/constants";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

export type AddFromSettingsProps = {
  location?: string;
  store?: string[];
  step?: number;
  onAdd?: () => void;
  onBack?: () => void;
};

const AddApp = ({ location, store, onAdd, onBack }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const setUserData = async (email: string) => {
      const res: any = await getUserDetails(email);
      setCheckApp(false);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);

        // Getting appID on org level or individual level
        const appId = await getUserAppIDs(res.getUser);
        if (appId && appId.length && appId !== "[]") {
          localStorage.setItem("appId", appId);
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId && !location) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        (!appData ||
          (appData && !appData.user) ||
          (appData && appData.user && !appData.user.userAppId)) &&
        !location
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.user]);

  const [appList, setAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState<any>("");
  const [searchText, setSearchText] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [inportLoading, setImportLoading] = useState(false);

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any) => {
      const getApps = async () => {
        let res = await searchPlayStoreApps(e);

        if (!Array.isArray(res) && res && res.body) {
          try {
            res = JSON.parse(res.body);
          } catch (e) {
            console.log("Error occured", e);
            res = res.body;
          }
        }

        setAppList(res.map((res: any) => ({ ...res, id: res.appId })));
        setLoading(false);
      };

      getApps();
    }, 500);
  }, []);

  useEffect(() => {
    if (
      selectedApp &&
      selectedApp.length > 1 &&
      (!searchText ||
        (searchText && selectedApp && searchText.title !== selectedApp))
    ) {
      setLoading(true);
      if (error && error.length !== 0) {
        setError("");
      }
      debounceChangeHandler(selectedApp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  const importDataHandler = async () => {
    if (searchText && searchText.appId && searchText.store) {
      setImportLoading(true);
      // In case of settings page, check with the previously added applications
      // If data any app exists from the same store as current, prevent addition
      // and show error to user
      // if (location && location === "settings" && store && store.length) {
      //   if (store.includes(searchText.store)) {
      //     setError(
      //       `An app from ${searchText.store.toUpperCase()} already exists in your account.`
      //     );
      //     console.log(
      //       "App from same store already exists. Showing error to user."
      //     );
      //     setImportLoading(false);
      //     return;
      //   }
      // }
      try {
        const funcPointer =
          searchText.store === "google" ? addPlayStoreApp : addAppleStoreApp;
        const res = await funcPointer(searchText.store, searchText);
        // Adding backward compatibility with the new API changes to a unified API Gateway endpoint
        if (res && res.body) {
          let response = {
            data: undefined,
          };
          try {
            response = JSON.parse(res.body);
          } catch (e) {
            response = res.body;
          }

          if (response && response.data) {
            res["data"] = response.data;
          }
        }
        localStorage.setItem("store", searchText.store);
        if (res && res.data && (res.data.createApp || res.data.getApp)) {
          const data = res.data.createApp || res.data.getApp;

          if (!location) {
            dispatch(
              AppActions.setAppData([
                ...appData.app,
                {
                  appID: data.appID,
                  store: searchText.store,
                  data: data,
                },
              ])
            );
          }

          let appIDs = [];
          if (appData.user.userAppId) {
            try {
              appIDs = JSON.parse(appData.user.userAppId);
            } catch (e) {
              console.log(e);
              appIDs = appData.user.userAppId;
            }
          }
          if (!Array.isArray(appIDs)) {
            appIDs = [appIDs];
          }
          if (data.appID && !appIDs.includes(data.appID)) {
            appIDs = appIDs.concat([data.appID]);
          }
          dispatch(
            AppActions.setUserData({
              ...appData.user,
              userAppId: appIDs,
            })
          );

          try {
            await createOrEditOrganization({
              admin:
                appData?.userRole?.email || localStorage.getItem("username"),
              appID: JSON.stringify(appIDs),
              organization: appData?.userRole?.organization || "",
              type: ADMIN_MANAGEMENT_TYPE.edit,
            });
          } catch (e) {
            console.log(e);
          }

          // Determine what steps to take and move to next step
          await determineAppStatus(data, searchText);
          // await startAppleImport(
          //   localStorage.getItem("appId") || data.appID,
          //   data.name,
          //   data.releasedDate
          // );
          setImportLoading(false);
          if (!location) {
            navigate(`/dashboard/${importPageExtention}`);
          }

          if (location && onAdd) {
            onAdd();
          }
        }
      } catch (e) {
        console.log(e);
        setImportLoading(false);
      }
    }
  };

  // TODO: Swicth to fetching the apps from App store and Play store in a faster way

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),
          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",
          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
              marginBottom: "20px",
            }}
          >
            Add your app
          </FlexBox>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
              height: "fit-content",
              marginBottom: "30px",
            }}
          >
            Type the name of your app in the Apple app store or Google play
            store
          </Box>
          <Grid item xs={6} style={{ position: "relative" }}>
            <Autocomplete
              loading={loading}
              id={"autocomplete"}
              // disableCloseOnSelect={true}
              fullWidth
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  fullWidth
                  sx={{
                    ...(error && error !== ""
                      ? { border: "1px solid red", borderRadius: "8px" }
                      : {}),
                  }}
                  // InputProps={{
                  //   ...(searchText && searchText.store && searchText.title === selectedApp ? {startAdornment: (<InputAdornment position="start">
                  //       {searchText.store && <img
                  //     loading="lazy"
                  //     width="20"
                  //     src={searchText.store === 'google' ? PlayStoreIcon: AppleStoreIcon}
                  //     alt="Store"
                  //   />}
                  //   {searchText.icon && <img
                  //     loading="lazy"
                  //     width="20"
                  //     src={searchText.icon}
                  //     alt="Store"
                  //   />}
                  //     </InputAdornment>)}: {})
                  // }}
                />
              )}
              value={searchText}
              onInputChange={(event, newValue) => {
                setSelectedApp(newValue);
                if (error && error !== "") {
                  setError("");
                }
                //debounceChangeHandler(newValue)
              }}
              // onBlur={(e: any) => {
              //     setSearchText(e.target.value.trim());
              // }}
              onChange={(event, newValue) => {
                setSearchText(newValue);
                if (error && error !== "") {
                  setError("");
                }
              }}
              options={appList}
              getOptionLabel={(option: any) => option.title || ""}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={`${option.title}--${option.appId}`}
                >
                  {option.store && option.store !== "" && (
                    <LazyLoadImage
                      alt={"Store"}
                      src={
                        option.store === "google"
                          ? PlayStoreIcon
                          : option.store === "apple"
                          ? AppleStoreIcon
                          : ""
                      }
                      width={"20"}
                    />
                  )}
                  {option.icon && (
                    <LazyLoadImage
                      alt={"app"}
                      src={option.icon}
                      width={"20"}
                      effect="blur"
                      style={{ marginRight: "4px" }}
                      placeholder={<CircularProgress />}
                    />
                  )}
                  {option.title}
                </Box>
              )}
              freeSolo={true}
            />
            <Box
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#3A3C40",
              }}
            >
              {
                "Note: You could either select your app from Apple Store or Google Play Store."
              }
            </Box>
            {error && error !== "" && (
              <FormHelperText
                error
                sx={{
                  // mt: 1,
                  fontSize: 13,
                  fontWeight: 500,
                  // textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}
          </Grid>
          <Box sx={{ mt: 8, textAlign: "center" }}>
            {inportLoading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Adding app..."}
              </LoadingButton>
            ) : (
              <Button
                fullWidth
                type="button"
                variant="contained"
                onClick={importDataHandler}
              >
                Add app
              </Button>
            )}
            <Box sx={{ mt: 1, justifyContent: "center" }}>
              <Button
                type="button"
                variant="outlined"
                sx={{
                  fontWeight: 500,
                  border: "none",
                  "&:hover": { border: "none", background: "#FFF" },
                }}
                onClick={() => {
                  if (location && onBack) {
                    onBack();
                  } else {
                    navigate(`/dashboard/data-source`);
                  }
                }}
              >
                {location && location === SETTINGS_PAGE
                  ? "Back to settings"
                  : "Back"}
              </Button>
            </Box>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default AddApp;
