import {
  createOrEditOrganization,
  importErrorHandler,
  startAppleImport,
  startGoogleImport,
} from "../../services/api/Apps.api";
import {
  deleteUserAppDetails,
  fetchAppFeedbackList,
  fetchNextAppFeedbackList,
  fetchNextComplaintsData,
  fetchNextComplimentsData,
  fetchNextSuggestionsData,
  fetchOrganisationsList,
  fetchSentimentsData,
  fetchSentimentsNextPageData,
  getAppData,
  getComplaintsData,
  getComplimentsData,
  getNextTrendingTopicsData,
  getSuggestionsData,
  getTrendingTopicsData,
  getUserDetails,
  updateAppImportStatus,
  updateUserAppDetails,
  updateUserDetails,
} from "../../services/api/Graphql.api";
import { removeUserTokens } from "../../services/context/Account";
import {
  AppActions,
  WidgetId,
} from "../../services/store/AppStore/AppStoreSlice";
import { FeedbackGraphInit } from "../../services/store/GraphStore/GraphInitState";
import { ReviewPoint } from "../Analytics/Analytics";
import { ClusterData, SubClusterData } from "./types";
import { v4 as uuidv4 } from "uuid";
import { ROLES, USER_ROLES } from "../Settings/AdminManagement";
import utf8 from "utf8";
// import { string } from "yup/lib/locale";

export const COMPACT = 1;
export const NORMAL = 2;
export const EXPANDED = 3;
export const FULLWIDTH = 4;

export const ADDWIDGET = 5;

export const CONTAINERWIDTH = 6;
export const CONTAINER_WIDTH_AND_HEIGHT = 7;

export const OVER_NORMAL = 8;
export const OVER_COMPACT = 9;
export const NORMAL_TRANS = 10;
export const COMPACT_2 = 11;
export const FULLWIDTH_2 = 12;

export const BAR_LINE_GRAPH = "barLineGraph";
export const LINE_GRAPH = "lineGraph";
export const LINE_GRAPH_WITH_SCALE = "lineGraphWithScale";
export const INDEPENDENT_LINE_GRAPH = "independentLineGraph";
export const INDEPENDENT_LINE_GRAPH_EXTENTION = "independentLineGraphExtention";
export const DETAILS_COMPONENT = "detailsComponent";
export const HORIZONTAL_SCALE_GRAPH = "horizontalScaleGraph";
export const SENTIMENTS_WIDGET = "sentimentsWidget";
export const ADD_WIDGET = "addWidget";
export const STAR_RATINGS = "starRatings";
export const TOP_RATED_RATINGS = "topRatedRatings";
export const INDEPENDENT_BAR_LINE_GRAPH = "independentBarLineGraph";
export const CLUSTER_SUBCLUSTER = "clusterSubcluster";
export const REVIEWS = "reviews";
export const FEEDBACKS = "feedbacks";
export const HORIZONTAL_GRAPH_WITH_REVIEWS = "horizontalScaleWithReviews";
export const HORIZONTAL_GRAPH_WITH_REVIEWS_COMPONENT =
  "horizontalScaleWithReviewsComponent";
export const BAR_LINE_GRAPH_WITH_REVIEWS = "barLineGraphWithReviews";
export const AREA_WITH_LINE_GRAPH = "areaWithLineGraph";
export const SUMMARY = "summaryComponent";
export const TOPIC_DETAILS = "topicDetails";

export const POSITIVE = "positive";
export const NEGATIVE = "negatives";

export const MORE_SENTIMENTS = "moreSentiments";
export const SENTIMENTS_ALL = "sentimentsAll";

export const COMPLIMENTS = "compliments";
export const COMPLAINTS = "complaints";
export const SUGGESTIONS = "suggestions";
export const SENTIMENTS = "sentiments";
export const TRENDING = "trending";
export const APP_FEEDBACK = "app-feedback";

export const COMPLAINT_FETCH = "COMPLAINT";
export const COMPLIMENT_FETCH = "COMPLIMENT";
export const SUGGESTION_FETCH = "SUGGESTION";
export const SENTIMENT_FETCH = "SENTIMENT";
export const TRENDING_FETCH = "TRENDING";

export const SETTINGS_PAGE = "settings";
export const DATASOURCE_PAGE = "datasource";

export const ORGANISATION = "organisation";

// Adding constant extension for import page to migrate all required changes to here
// export const importPageExtention = "import-status"
export const importPageExtention = "import";

// Keyword split charatcers to create or for words
export const keywordOrSplit = "##$##";

// Stores
export const STORE = {
  apple: "apple",
  google: "google",
  "apple-play-store": "play-apple-store",
  aha: "aha",
  csv: "csv",
  slack: "slack",
  zapier: "zapier",
  sof: "stack-overflow",
  amazon: "amazon",
  github: "github",
};

// Status flags for the different status
export const STATUS_FLAG = {
  INIT: 0,
  START_IMPORT: 1,
  IMPORT_DONE: 2,
  GPT_PROCESS_START: 3,
  GPT_ERROR: 4,
  CLUSTERING_ERROR: 5,
  SENTIMENT_ERROR: 6,
  PROCESS_COMPLETE: 7,
};

export type FilterOption = {
  label: string;
  value: string;
  dates?: any;
  action?: any;
  color?: string;
};

export const allowedZaps = [1499686, 1499688];

// Global filter options
export const filterOptions: FilterOption[] = [
  {
    label: "Last week",
    value: "lastWeek",
  },
  {
    label: "Last month",
    value: "lastMonth",
  },
  {
    label: "Last quarter",
    value: "lastQuarter",
  },
  {
    label: "Last year",
    value: "lastYear",
  },
  {
    label: "All time",
    value: "allTime",
  },
  {
    label: "Custom",
    value: "custom",
    dates: [],
  },
];

export const widgetSettingsOption: FilterOption[] = [
  {
    label: "Replace widget",
    value: "replaceWidget",
  },
  {
    label: "Edit widget",
    value: "editWidget",
    color: "#3579E3",
  },
  {
    label: "Remove widget",
    value: "removeWidget",
    color: "#DB6C56",
  },
];

export const timeDiff = 1000 * 3600 * 24;

export const DEFAULT_DASHBOARD_PAGES = [
  "Feedback dashboard",
  "Sentiments",
  "Star ratings",
  "Analytics",
  "App feedback",
];

export const ADMIN_MANAGEMENT_TYPE = {
  create: "CREATE",
  edit: "EDIT",
  delete: "DELETE",
};

export const getUserRights = (user: any) => {
  let rights = undefined;
  try {
    rights = JSON.parse(user.userRights);
  } catch (e) {
    rights = user.userRights;
  }

  return rights;
};

export const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const getImageAndTextFromHTML = (htmlString: string) => {
  // Parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  let imgSrc: string[] = [];
  let content = "";

  // Find the img element and extract the src attribute
  const imgElements = doc.querySelectorAll("img");
  if (imgElements && imgElements.length) {
    imgSrc = Array.from(imgElements).map(
      (imgElement) => imgElement.getAttribute("src") || ""
    );

    imgElements.forEach((imgElement) => {
      if (imgElement && imgElement.parentNode) {
        imgElement.parentNode.removeChild(imgElement);
      }
    });
  }

  // Get the content without the img element
  content = doc.body.innerHTML;

  return {
    imgSrc: imgSrc.filter((i: string) => i !== ""),
    content,
  };
};

export const checkIfUserInOrg = async (user: any) => {
  // Check if the user exists in an ORG.
  if (user.userRights) {
    // User belongs to an org
    const userRights = getUserRights(user);
    // Fetch the list matching this
    const response = await fetchOrganisationsList(userRights.organization);
    if (
      response &&
      response.listOrganisations &&
      response.listOrganisations.items &&
      response.listOrganisations.items.length
    ) {
      // An Org exists in the Organisation schema
      // User is allowed to add apps
      return true;
    }
  }
  return false;
};

export const checkIfUserIsAdminOrPremium = (user: any) => {
  // Check if current user is an admin
  let ADMIN_IDS: any = [];
  try {
    ADMIN_IDS = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL_IDS || "[]");
  } catch (e) {
    ADMIN_IDS = process.env.REACT_APP_ADMIN_EMAIL_IDS || [];
  }

  if (ADMIN_IDS.includes(localStorage.getItem("username"))) {
    return false;
  }

  if (
    user.isPremium &&
    (user.isPremium === "two" || user.isPremium === "infinite")
  ) {
    return false;
  }

  return true;
};

export const getUserAppIDs = async (user: any) => {
  let appId;

  // Check if the user exists in an ORG. If so, fetch the org appID
  if (user.userRights) {
    // User belongs to an org
    const userRights = getUserRights(user);
    // Fetch the list matching this
    const response = await fetchOrganisationsList(userRights.organization);
    if (
      response &&
      response.listOrganisations &&
      response.listOrganisations.items &&
      response.listOrganisations.items.length
    ) {
      // Org exists in the db
      const orgs = response.listOrganisations.items;
      if (orgs && orgs[0]) {
        appId = Array.isArray(JSON.parse(orgs[0].appID))
          ? JSON.stringify(JSON.parse(orgs[0].appID))
          : JSON.stringify([JSON.parse(orgs[0].appID)]);
        // console.log(
        //   "Added apps present in Organization:",
        //   userRights.organization
        // );
      }
    }
  }

  // If the user doesn't belong to org, or if some error occured in fetching the org,
  // set the ids present with the user as the one
  if (!appId && user.userAppId) {
    // Otherwise proceed with the user's appID
    try {
      appId = Array.isArray(JSON.parse(user.userAppId))
        ? JSON.stringify(JSON.parse(user.userAppId))
        : JSON.stringify([JSON.parse(user.userAppId)]);
    } catch (e) {
      appId = Array.isArray(user.userAppId)
        ? JSON.stringify(user.userAppId)
        : JSON.stringify([user.userAppId]);
    }
  }

  return appId;
};

export const getUserRole = ({
  isAdmin,
  isViewer,
  isEditor,
}: {
  isAdmin?: boolean;
  isViewer?: boolean;
  isEditor: boolean;
  organization?: string;
}) => {
  if (isAdmin && isAdmin === true) {
    return USER_ROLES[0].value;
  }

  if (isEditor && isEditor === true) {
    return USER_ROLES[2].value;
  }

  if (isViewer && isViewer === true) {
    return USER_ROLES[1].value;
  }

  return USER_ROLES[1].value;
};

export const getHashedAPIKey = () => {
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_RARGUS_CLIENT_SECRET;
  const secretHash = btoa(utf8.encode(`${clientId}:${clientSecret}`));

  const refreshToken = getWithExpiry("refreshToken") || "";

  return `${refreshToken}${keywordOrSplit}${secretHash}`;
};

// Get the array of widget source
export const getWidgetSource = (appData: any, widget: WidgetId) => {
  let availableStores = appData.app.map((item: DATA_BY_STORE) =>
    item.appID ? item.appID : item.data && item.data.appID
  );

  // Get the data sources to fetch the data from
  let defaultStore: any =
    // If the widget contains the source data, use it
    widget.source &&
    Array.isArray(widget.source) &&
    widget.source.filter((item: any) => availableStores.includes(item.appID))
      .length !== 0
      ? // Check if the data in the widget source actually exists in the data
        widget.source
      : // Else use the first item in the appData.store to fetch the data
      appData.app && appData.app.length && availableStores && availableStores[0]
      ? appData.app.map((app: DATA_BY_STORE) => ({
          store: Array.isArray(app.store) ? app.store[0] : app.store,
          appID: app.appID ? app.appID : app.data && app.data.appID,
        }))
      : // .map((s: GET_DATA_STORE) => s.store)
        [];

  return defaultStore;
};

// Check the difference of new apps between the two arrays
export const checkForNewAppAddition = async (
  initArray: DATA_BY_STORE[],
  appData: any,
  dispatch: any
) => {
  const finalArray: any = await getAppDetails(appData, dispatch);

  if (initArray && finalArray && finalArray.length) {
    if (initArray.length === finalArray.length) {
      return false;
    }

    if (initArray.length < finalArray.length) {
      // Check which new app was added
      const initArrayApps = initArray.map((item: DATA_BY_STORE) => item.appID);
      const newApp = finalArray.filter(
        (item: any) => !initArrayApps.includes(item.appID)
      );

      if (newApp && newApp.length) {
        // check if the store of the new app is not apple/google/aha
        if (![STORE.aha, STORE.apple, STORE.google].includes(newApp[0].store)) {
          // Since a new app exists, and it doesn't belong to apple, google or aha, it must be added from zapier
          return true;
        }
      }
    }
  }
  return false;
};

// Returns True for admin and editor roles
// Returns False for viewer role
export const getEditorUserRoleAccess = (userRole: any) => {
  return (
    userRole &&
    // When no userRole object exist for the user
    (!userRole.role ||
      // When user has role  and belongs to either editor or above roles
      (userRole.role &&
        (userRole.role === ROLES.admin || userRole.role === ROLES.editor)))
  );
};

// Returns True for admin and editor roles
// Returns False for viewer role
export const getAdminUserRoleAccess = (userRole: any) => {
  return (
    userRole &&
    // When no userRole object exist for the user
    (!userRole.role ||
      // When user belongs to an org, only admins can delete the app
      (userRole.role && userRole.organization && userRole.role === ROLES.admin))
  );
};

export const updateLayoutAndUserWidgets = (userWidgets: any, dispatch: any) => {
  dispatch(AppActions.setWidgetData(userWidgets));

  // Update for user in db
  updateUserDetails({
    id: localStorage.getItem("username") || "",
    userWidgets: JSON.stringify(userWidgets),
  });
};

export const updateUserDetailsInRedux = async (
  user: any,
  userRights: any,
  dispatch: any
) => {
  dispatch(
    AppActions.setUserData({
      ...user,
      userRights,
    })
  );

  // Update for user in db
  await updateUserDetails({
    id: localStorage.getItem("username") || "",
    userRights: JSON.stringify(userRights),
  });
};

export const getRouteName = (newName: string) => {
  let routeName = `dashboard`;
  let routeArray = newName.toLowerCase().split(" ");
  if (routeArray && routeArray.length) {
    routeArray.forEach((route: string, index: number) => {
      if (index === 0) {
        routeName = route;
      } else routeName += route.charAt(0).toUpperCase() + route.slice(1);
    });
  }
  return routeName;
};

export const getSortedApps = (appIds: string[]) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  const sortedStrings = appIds.sort(collator.compare);
  return sortedStrings;
};

// Global filter values and the expression function to evaluate what falls under the filter
export const getFilterValues = (appData: any) => {
  const timeDiff = 1000 * 3600 * 24;

  const getNumDays =
    appData.filter.value === "lastWeek"
      ? 7
      : appData.filter.value === "lastMonth"
      ? 30
      : appData.filter.value === "lastQuarter"
      ? 90
      : appData.filter.value === "lastYear"
      ? 365
      : appData.filter.value === "allTime"
      ? 100000
      : appData.filter.value === "custom" &&
        appData.filter.dates &&
        appData.filter.dates.length === 2
      ? 100
      : 1;

  let expression = (date: string) => false;
  let expression2 = (date: string) => false;
  let expression3 = (date1: string, date2: string, getNumDays: number) => false;

  if (appData.filter.value !== "custom") {
    expression = (date: string) =>
      (new Date().getTime() - new Date(date).getTime()) / timeDiff <
        getNumDays &&
      (new Date().getTime() - new Date(date).getTime()) / timeDiff >= 0;

    expression2 = (date: string) =>
      (new Date().getTime() - new Date(date).getTime()) / timeDiff >
        getNumDays &&
      (new Date().getTime() - new Date(date).getTime()) / timeDiff <=
        2 * getNumDays;

    expression3 = (date1: string, date2: any, getNumDays: number) => {
      if (
        (new Date(date1).getTime() - date2.getTime()) / timeDiff < getNumDays &&
        (new Date(date1).getTime() - date2.getTime()) / timeDiff >= 0
      ) {
        return true;
      }

      return false;
    };
  }

  if (appData.filter.value === "custom" && getNumDays === 100) {
    const date_1 = new Date(appData.filter.dates[0]);
    const date_2 = new Date(appData.filter.dates[1]);

    expression = (date: string) => {
      let value =
        (date_2.getTime() - new Date(date).getTime()) / timeDiff >= 0 &&
        (new Date(date).getTime() - date_1.getTime()) / timeDiff >= 0;
      // if (value) {
      //   console.log(date_1, date_2, date, value);
      // }
      return value;
    };

    const date_3 = new Date(appData.filter.dates[0]);
    const date_4 = new Date(appData.filter.dates[1]);
    const dayDiff = (date_4.getTime() - date_3.getTime()) / timeDiff;
    date_4.setDate(date_3.getDate() - dayDiff);

    expression2 = (date: string) => {
      let value =
        (date_3.getTime() - new Date(date).getTime()) / timeDiff >= 0 &&
        (new Date(date).getTime() - date_4.getTime()) / timeDiff >= 0;
      // if (value) {
      //   console.log(date_3, date_4, date, value);
      // }
      return value;
    };

    expression3 = (date1: string, date2: any, getNumDays: number) => {
      // console.log(
      //   (new Date(date1).getTime() - date2.getTime()) / timeDiff,
      //   getNumDays,
      //   (new Date(date1).getTime() - date2.getTime()) / timeDiff < getNumDays &&
      //     (new Date(date1).getTime() - date2.getTime()) / timeDiff >= 0
      // );
      if (
        (new Date(date1).getTime() - date2.getTime()) / timeDiff < getNumDays &&
        (new Date(date1).getTime() - date2.getTime()) / timeDiff >= 0
      ) {
        return true;
      }

      return false;
    };
  }

  return {
    timeDiff,
    getNumDays,
    expression,
    expression2,
    expression3,
  };
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
export const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const loadingTooltipTitle = "Please wait while the data is loading";

// Decide which db table to fetch based on type
export const getDBTable: (type: string) => string = (type: string) => {
  switch (type) {
    case "SENTIMENT":
      return process.env.REACT_APP_SENTIMENTS_TABLE || "";
    case "SUGGESTION":
      return process.env.REACT_APP_SUGGESTIONS_TABLE || "";
    case "COMPLIMENT":
      return process.env.REACT_APP_COMPLIMENTS_TABLE || "";
    case "COMPLAINT":
      return process.env.REACT_APP_COMPLAINTS_TABLE || "";
    case "TRENDING":
      return process.env.REACT_APP_TRENDING_TABLE || "";
    case "APP":
      return "App-pehdb5dpwvea3ayhwqqtsn3b6u-staging";
    default:
      return process.env.REACT_APP_SENTIMENTS_TABLE || "";
  }
};

export const getTableKey: (type: string) => string = (type: string) => {
  switch (type) {
    case "SENTIMENT":
      return "sentiments";
    case "SUGGESTION":
      return "suggestions_cluster";
    case "COMPLIMENT":
      return "complements_cluster";
    case "COMPLAINT":
      return "complaints_cluster";
    case "TRENDING":
      return "trendings";
    default:
      return "";
  }
};

// Generate substrings
export const generateSubstrings = (parentString: string, length?: number) => {
  let subStrings: string[] = [];
  let subStringLength = length ? length : 3;
  try {
    const parentArray = parentString
      .split(" ")
      .map((val: string) => val.replace("-", "").replace(/(^"|"|"$)/g, ""))
      .filter((val: any) => val.trim() !== "");
    if (parentArray && parentArray.length) {
      for (let i = 0; i < parentArray.length - subStringLength; i++) {
        let tempString: string[] = [];

        for (let j = i; j - i < subStringLength; j++) {
          tempString = tempString.concat(parentArray[j]);
        }

        subStrings.push(tempString.join(" ").replace(/(^"|"|"$)/g, ""));
      }
    }
  } catch (e) {
    console.log("problem during finding sub string");
  }

  return subStrings;
};

export const createSentimentCluster = (clus: any) => {
  let positive = 0,
    negative = 0;
  if (clus.clusterGroup !== "0") {
    clus.subClusters.forEach((sub: any) => {
      sub.sentimentData.forEach((rev: any) => {
        // Neutral Reviews not counted
        if (
          rev.sentimentScore !== undefined &&
          parseFloat(rev.sentimentScore) > 0.1
        ) {
          positive++;
        } else if (
          rev.sentimentScore !== undefined &&
          parseFloat(rev.sentimentScore) < -0.1
        ) {
          negative++;
        }
      });
    });
  }

  if (
    clus.clusterGroup === "0" &&
    clus.sentimentData &&
    clus.sentimentData.length
  ) {
    clus.sentimentData.forEach((rev: any) => {
      // Neutral Reviews not counted
      if (
        rev.sentimentScore !== undefined &&
        parseFloat(rev.sentimentScore) > 0.1
      ) {
        positive++;
      } else if (
        rev.sentimentScore !== undefined &&
        parseFloat(rev.sentimentScore) < -0.1
      ) {
        negative++;
      }
    });
  }

  return {
    ...clus,
    positive,
    negative,
  };
};

// Create positive negative count for clusters
export const createTopTopicsData = (cluster: any[]) => {
  let clusters: any[] = [];
  cluster.forEach((clus: any) => {
    const computedCluster = createSentimentCluster(clus);

    clusters.push(computedCluster);
  });

  return clusters;
};

// Code from Analytics page, required for finding out the cluster points and
// creating sub string from the same. Use, if required at a later stage!

// const getClusterPoints = useCallback(() => {
//   let clusterPoints: string[] = [];

//   if (
//     filteredSubcluster &&
//     filteredSubcluster.clusterGroup !== "0" &&
//     filteredSubcluster.subClusters &&
//     filteredSubcluster.subClusters.length
//   ) {
//     // Sort the list, get the top 6 clusters and then take 3 points from those top 5
//     [...filteredSubcluster.subClusters]
//       .sort(
//         (a: any, b: any) =>
//           getTotalNumberOfVotes(undefined, b) -
//           getTotalNumberOfVotes(undefined, a)
//       )
//       // Check data without slicing the number of clusters
//       .slice(1, 6)
//       .forEach((sub: any) => {
//         let points = [];

//         if (Array.isArray(sub.clusterPoints)) {
//           clusterPoints = clusterPoints.concat(sub.clusterPoints.slice(1, 4));
//         } else {
//           try {
//             points = sub.clusterPoints
//               .replace("]", "")
//               .replace("[", "")
//               .split(",");
//           } catch (e) {
//             console.log("Problem in splitting cluster points");
//           }
//           clusterPoints = clusterPoints.concat(points.slice(1, 4));
//         }
//       });
//   }

//   if (
//     filteredSubcluster &&
//     filteredSubcluster.clusterGroup !== "0" &&
//     filteredSubcluster.clusterPoints
//   ) {
//     let points = [];

//     if (Array.isArray(filteredSubcluster.clusterPoints)) {
//       clusterPoints = clusterPoints.concat(
//         filteredSubcluster.clusterPoints.slice(1, 4)
//       );
//     } else {
//       try {
//         points = filteredSubcluster.clusterPoints
//           .replace("]", "")
//           .replace("[", "")
//           .split(",");
//       } catch (e) {
//         console.log("Problem in splitting cluster points");
//       }
//       // Taking in the first three points from each cluster points list
//       // Future changes needs to include taking this from the top 5 clusters
//       clusterPoints = clusterPoints.concat(points.slice(1, 4));
//     }
//   }

//   return clusterPoints;
// }, [filteredSubcluster]);

// const getSubstringsFromCluster = useCallback(() => {
//   let subStrings: string[] = [];

//   if (filteredSubcluster) {
//     // Generate for Group Topic
//     subStrings = generateSubstrings(
//       filteredSubcluster && filteredSubcluster.groupTopic
//         ? filteredSubcluster.groupTopic
//         : "",
//       3
//     );
//     // if (
//     //   filteredSubcluster.clusterGroup !== "0" &&
//     //   filteredSubcluster.subClusters &&
//     //   filteredSubcluster.subClusters.length
//     // ) {
//     //   filteredSubcluster.subClusters.forEach((sub: any) => {
//     //     subStrings = subStrings.concat(
//     //       generateSubstrings(
//     //         sub && sub.clusterTopics ? sub.clusterTopics : "",
//     //         6
//     //       )
//     //     );
//     //   });
//     // }
//     // if (
//     //   filteredSubcluster.clusterGroup === "0" &&
//     //   filteredSubcluster.clusterTopics
//     // ) {
//     //   subStrings = subStrings.concat(
//     //     generateSubstrings(
//     //       filteredSubcluster && filteredSubcluster.clusterTopics
//     //         ? filteredSubcluster.clusterTopics
//     //         : "",
//     //       3
//     //     )
//     //   );
//     // }
//   }

//   const clusterPoints = getClusterPoints();

//   if (clusterPoints && clusterPoints.length) {
//     clusterPoints.forEach((point: string) => {
//       subStrings = subStrings.concat(generateSubstrings(point, 6));
//     });
//   }

//   // console.log(subStrings);
//   return subStrings;
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [filteredSubcluster]);

export const setUserDataAndRole = (dispatch: any, userData: any) => {
  if (userData) {
    dispatch(AppActions.setUserData(userData));

    if (userData && userData.userRights && userData.userRights.length) {
      // userRights exist
      const userRights = getUserRights(userData);
      if (userRights) {
        dispatch(
          AppActions.setUserRole({
            name: userData.name ? userData.name : userData.email,
            email: userData.email,
            role: getUserRole(userRights),
            organization: userRights.organization
              ? userRights.organization
              : "",
          })
        );
      }
    }
  }
};

// Function to find distinct reviews based on certain fields
export const distictReviews = (data: any) => {
  let sentArray: any[] = [];
  let idArray: string[] = [];
  let dateArray: string[] = [];
  let userArray: string[] = [];
  if (data && data.length) {
    data.forEach((sent: any) => {
      let foundIndex = userArray.findIndex(
        (id: string) => id === sent.username
      );
      if (
        foundIndex === -1 ||
        (foundIndex !== -1 &&
          dateArray[foundIndex] !== sent.date &&
          idArray[foundIndex] !== sent.reviewID)
      ) {
        idArray.push(sent.reviewID);
        dateArray.push(sent.date);
        userArray.push(sent.username);
        sentArray.push(sent);
      }
    });
  }
  return sentArray;
};

export const distictObjectById = (data: any, providedId?: string) => {
  let sentArray: any[] = [];
  let idArray: string[] = [];
  let dateArray: string[] = [];
  if (data && data.length) {
    data.forEach((sent: any) => {
      let foundIndex = idArray.findIndex(
        (id: string) => id === (providedId ? sent[providedId] : sent.id)
      );
      if (
        foundIndex === -1 ||
        (foundIndex !== -1 &&
          dateArray[foundIndex] !== sent.date &&
          idArray[foundIndex] !== (providedId ? sent[providedId] : sent.id))
      ) {
        idArray.push(providedId ? sent[providedId] : sent.id);
        dateArray.push(sent.date);
        sentArray.push(sent);
      }
    });
  }
  return sentArray;
};

export const distictArray = (data: any, providedId?: string) => {
  let sentArray: any[] = [];
  let idArray: string[] = [];
  if (data && data.length) {
    data.forEach((sent: any) => {
      let foundIndex = idArray.findIndex(
        (id: string) => id === (providedId ? sent[providedId] : sent.id)
      );
      if (foundIndex === -1) {
        idArray.push(providedId ? sent[providedId] : sent.id);
        sentArray.push(sent);
      }
    });
  }
  return sentArray;
};

// Remove apps that have a complaintStatus, complementStatus, suggestionStatus, or sentimentStatus other than 7.
// If only one app's status is not 7, remove that app and redirect to the add app page.
// If more than one app's status is not 7, remove all of them and redirect.
// If every app's status is not 7, remove all and redirect.
export const removeCurrentApp = async (
  user: string,
  appIdsToRemove: any,
  appIDs: string[],
  userRole: any,
  storeData: any[],
  dispatch: any,
  navigate: any
) => {
  try {
    //Extact all the unsuccessful appIds from the data
    const removeAppIds = appIdsToRemove.map((apps: any) => apps.appId);

    // remove the appId from the user appId list
    const AppDetails = appIDs.filter(
      (app: string) => !removeAppIds.includes(app)
    );

    try {
      //updata userApplist in the database
      await updateUserAppDetails(user || "", JSON.stringify(AppDetails));
    } catch (e) {
      console.log(e);
    }

    try {
      await createOrEditOrganization({
        admin: userRole?.email || localStorage.getItem("username"),
        appID: JSON.stringify(AppDetails),
        organization: userRole?.organization || "",
        type: ADMIN_MANAGEMENT_TYPE.edit,
      });
    } catch (e) {
      console.log(e);
    }
    // // remove the app details from the user details
    // dispatch(AppActions.setUserData({ email: user, appUserId: AppDetails }));

    // remove the app from all sources
    // dispatch(AppActions.removeUnsuccefulAppFromAll(appIdsToRemove));

    // if no app is present then remove the appId and store from local store and redirect to add app page.
    if (AppDetails.length === 0) {
      localStorage.removeItem("appId");
      localStorage.removeItem("store");
      navigate("/dashboard/home");
    }

    // Remove unsuccessful appDetails from local storage
    else {
      localStorage.setItem("appId", JSON.stringify(AppDetails));
      localStorage.setItem("store", JSON.stringify(storeData));
    }

    // Reload the page after update
    window.location.reload();
  } catch (e) {
    removeUserTokens();
  }
};

// Remove the app added to the user and redirect to add app page
export const removeApp = async (user: string, dispatch: any, navigate: any) => {
  try {
    await deleteUserAppDetails(user || "");
    dispatch(AppActions.setUserData({ email: user, appUserId: null }));
    localStorage.removeItem("appId");
    localStorage.removeItem("store");
    navigate("/dashboard/home");
  } catch (e) {
    removeUserTokens();
  }
};

export const setWithExpiry = (key: string, value: any, ttl: number = 7) => {
  const now = new Date();

  const timeInDay = 1000 * 60 * 60 * 24;

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl * timeInDay,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const getCustomDayDifference = (days: number) => {
  if (days < 12) {
    return 1;
  }

  if (days >= 12) {
    return days / 12;
  }

  return 1;
};

export const getUuid = () => {
  return uuidv4().toString();
};

// Helper function to get the date object for creating the graphs
export const constructLabelObject = (selectedFilter: FilterOption) => {
  switch (selectedFilter.value) {
    case "lastWeek":
      return weekDays
        .map((_, index) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - index);
          return {
            month: initDate.getMonth(),
            day: initDate.getDate(),
            year: initDate.getFullYear(),
            date: initDate,
          };
        })
        .reverse();
    case "lastMonth":
      return [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          //const year = initDate.getFullYear();
          const day = initDate.getDate();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          return {
            month,
            day,
            year: initDate.getFullYear(),
            date: initDate,
          };
        })
        .reverse();
    case "lastQuarter":
      return [0, 15, 30, 45, 60, 75, 90]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          //const year = initDate.getFullYear();
          const day = initDate.getDate();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          return {
            month,
            day,
            year: initDate.getFullYear(),
            date: initDate,
          };
        })
        .reverse();
    case "lastYear":
      return months
        .map((_, index) => {
          const initDate = new Date();
          const day = initDate.getDate();
          const minusDays = index * 30;
          initDate.setDate(initDate.getDate() - minusDays - day + 1);

          const year = initDate.getFullYear();

          return {
            month: initDate.getMonth(),
            year,
            day,
            date: initDate,
          };
        })
        .reverse();
    case "allTime":
      return [
        0, 150, 300, 450, 600, 750, 900, 1050, 1200, 1350, 1500, 1650, 1800,
        1950, 2100,
      ]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          //const year = initDate.getFullYear();
          const day = initDate.getDate();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          return {
            month,
            day,
            year: initDate.getFullYear(),
            date: initDate,
          };
        })
        .reverse();
    case "custom":
      let date_1 = new Date(selectedFilter.dates[0]);
      let date_2 = new Date(selectedFilter.dates[1]);
      const dayRange = (date_2.getTime() - date_1.getTime()) / timeDiff;
      const dayDiff = getCustomDayDifference(dayRange);

      let returnArr = [];

      for (let i = 0; i <= Math.floor(dayRange / dayDiff); i++) {
        let date_3 = new Date(selectedFilter.dates[1]);
        date_3.setDate(date_3.getDate() - Math.floor(i * dayDiff));
        const month = date_3.getMonth();
        //const year = initDate.getFullYear();
        const day = date_3.getDate();
        returnArr.push({
          month,
          day,
          year: date_3.getFullYear(),
          date: date_3,
        });
      }
      return returnArr.reverse();
    default:
      return months;
  }
};

// Get app details based on the app id
export const getAppDetails = async (appData: any, dispatch: any) => {
  try {
    let appIDs: any = localStorage.getItem("appId") || "";

    // Retrieving apps when they aren't present in local
    if (appIDs === "") {
      console.log("App id doesn't exist in local.");

      const res = await getUserDetails(localStorage.getItem("username") || "");
      if (res && res.getUser) {
        appIDs = await getUserAppIDs(res.getUser);
      }
    }

    try {
      if (
        Array.isArray(JSON.parse(JSON.parse(appIDs))) ||
        JSON.parse(JSON.parse(appIDs)) instanceof Array
      ) {
        appIDs = JSON.parse(JSON.parse(appIDs));
      } else appIDs = [JSON.parse(JSON.parse(appIDs))];
    } catch (e) {
      if (
        Array.isArray(JSON.parse(appIDs)) ||
        JSON.parse(appIDs) instanceof Array
      ) {
        appIDs = JSON.parse(appIDs);
      } else appIDs = [JSON.parse(appIDs)];
    }

    // console.log(appIDs);

    let store: string[] = [];
    let apps: any[] = [];

    for (let i = 0; i < appIDs.length; i++) {
      const res = await getAppData(appIDs[i]);

      // Handling conditions when an app has been removed from the schema, but still exists in the user's app list
      if (res.getApp) {
        if (res.getApp.store) {
          store = [...store, res.getApp.store];
        } else {
          if (res.getApp.installs === null) {
            store = [...store, STORE.aha];
          }
          if (res.getApp.installs === "-1") {
            store = [...store, STORE.apple];
          } else if (res.getApp.installs) {
            store = [...store, STORE.google];
          }
        }

        apps = [
          ...apps,
          {
            store: store[i],
            appID: res.getApp.appID,
            data: { ...res.getApp },
          },
        ];
      }
    }

    dispatch(AppActions.setStoreData(store));
    dispatch(AppActions.setAppData(apps));
    localStorage.setItem(
      "appId",
      JSON.stringify(apps.map((app: any) => app.appID))
    );
    return apps;
  } catch (e) {
    console.log(e);
    // Need to look into this
    // if (appData && appData.app && appData.app.length === 0) {
    //   localStorage.removeItem("appId");
    //   localStorage.removeItem("token");
    //   // logout(() => {});
    // }

    return [
      {
        status: 0,
        complementStatus: 0,
        complaintStatus: 0,
        suggestionStatus: 0,
        sentimentStatus: 0,
      },
    ];
  }
};

export type DATA_BY_STORE = {
  store: string;
  appID: string;
  data: any;
};

export type GET_DATA_STORE = {
  store: string;
  appID: string;
};

export const getDataByStore = (
  data: DATA_BY_STORE[],
  store: GET_DATA_STORE[]
) => {
  let returnData: any = [];

  if (data && data.length && store && store.length) {
    let source = store.map((s: GET_DATA_STORE) => s.store);
    let appIDs = store.map((s: GET_DATA_STORE) => s.appID);

    source.forEach((store: string, index: number) => {
      const dataObject = data.find(
        (item: DATA_BY_STORE) =>
          store &&
          store.length &&
          store.includes(item.store) &&
          appIDs &&
          appIDs.length &&
          appIDs[index] &&
          appIDs[index].includes(item.appID)
      );

      if (dataObject && dataObject.data) {
        if (store && store.length === 1) {
          return dataObject.data;
        } else if (store && store.length > 1) {
          if (Array.isArray(dataObject.data)) {
            returnData = returnData.concat(dataObject.data);
          } else if(dataObject.data && dataObject.data.name){
            // This is returning app data
            returnData = returnData.concat(dataObject.data)
          } else {
            // When the data is an object, object concatenation needs to be done
            returnData = { ...returnData, ...dataObject.data };
          }
        }
      }
    });
  }

  return returnData;
};

export const setDataByStore = (
  data: DATA_BY_STORE[],
  newData: DATA_BY_STORE
) => {
  let oldObject = data ? [...data] : [];

  if (newData && newData.store && newData.appID) {
    const foundIndex = oldObject.findIndex(
      (item: DATA_BY_STORE) =>
        item.store === newData.store && item.appID === newData.appID
    );

    // If the store object already exists
    if (foundIndex !== -1) {
      oldObject[foundIndex] = newData;
    } else {
      // If a new item needs to be added for the store
      oldObject = [...oldObject, newData];
    }
  }
  return oldObject;
};

// Determine app status and import app
export const determineAppStatus = async (data: any, searchText: any) => {
  // If any error had occured during previous import, start the import process again
  let reImportErrored = false;
  if (
    data &&
    // Might need to change accepeted status flags
    [
      STATUS_FLAG.INIT,
      STATUS_FLAG.START_IMPORT,
      STATUS_FLAG.IMPORT_DONE,
      STATUS_FLAG.GPT_ERROR,
      STATUS_FLAG.CLUSTERING_ERROR,
      STATUS_FLAG.SENTIMENT_ERROR,
    ].includes(data.status) &&
    [
      data.complaintStatus,
      data.complementStatus,
      data.sentimentStatus,
      data.suggestionStatus,
    ].filter((st) => st === STATUS_FLAG.PROCESS_COMPLETE).length !== 4
  ) {
    // Check if the app import had led to an error
    // Error state check
    let errorStates = 0;
    let errorObject: {
      status: number;
      complementStatus?: number;
      complaintStatus?: number;
      suggestionStatus?: number;
      sentimentStatus?: number;
      reviewsFetched?: number;
    } = { status: STATUS_FLAG.INIT };

    // initially considering everything to be done
    let lambdaTrigger = {
      GPT: 0,
      CLUSTERING: 0,
      SENTIMENT: 0,
    };

    if (
      data.status === STATUS_FLAG.IMPORT_DONE ||
      data.reviewsFetched >= 1500
    ) {
      errorObject.status = STATUS_FLAG.IMPORT_DONE;
    }

    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(data.status)
    ) {
      // Some error had occured. Putting status 2 during reset. No need to re-import everything
      errorStates++;
      errorObject = {
        ...errorObject,
        status: STATUS_FLAG.IMPORT_DONE,
      };
    }
    if (
      [
        STATUS_FLAG.INIT,
        STATUS_FLAG.GPT_PROCESS_START,
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
        // Trending topics status flags
        99,
        999,
      ].includes(data.complaintStatus)
    ) {
      errorStates++;
      if (
        [STATUS_FLAG.INIT, STATUS_FLAG.GPT_ERROR].includes(data.complaintStatus)
      ) {
        errorObject = {
          ...errorObject,
          complaintStatus: STATUS_FLAG.INIT,
        };

        console.log("Complaint GPT3 Error Occured");
        lambdaTrigger.GPT = 1;
      }

      if (
        [
          STATUS_FLAG.GPT_PROCESS_START,
          STATUS_FLAG.CLUSTERING_ERROR,
          99,
          999,
        ].includes(data.complaintStatus)
      ) {
        errorObject = {
          ...errorObject,
          complaintStatus: STATUS_FLAG.GPT_PROCESS_START,
        };

        console.log("Complaint GPT3 Error Occured");
        lambdaTrigger.CLUSTERING = 1;
      }
    }

    if (
      [
        STATUS_FLAG.INIT,
        STATUS_FLAG.GPT_PROCESS_START,
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(data.complementStatus)
    ) {
      errorStates++;

      if (
        [STATUS_FLAG.INIT, STATUS_FLAG.GPT_ERROR].includes(
          data.complementStatus
        )
      ) {
        errorObject = {
          ...errorObject,
          complementStatus: STATUS_FLAG.INIT,
        };

        console.log("Compliment Clustering Error Occured");
        lambdaTrigger.GPT = 1;
      }

      if (
        [STATUS_FLAG.GPT_PROCESS_START, STATUS_FLAG.CLUSTERING_ERROR].includes(
          data.complementStatus
        )
      ) {
        errorObject = {
          ...errorObject,
          complementStatus: STATUS_FLAG.GPT_PROCESS_START,
        };

        console.log("Compliment Clustering Error Occured");
        lambdaTrigger.CLUSTERING = 1;
      }
    }

    if (
      [
        STATUS_FLAG.INIT,
        STATUS_FLAG.GPT_PROCESS_START,
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(data.suggestionStatus)
    ) {
      errorStates++;

      if (
        [STATUS_FLAG.INIT, STATUS_FLAG.GPT_ERROR].includes(
          data.suggestionStatus
        )
      ) {
        errorObject = {
          ...errorObject,
          suggestionStatus: STATUS_FLAG.INIT,
        };

        console.log("Suggestion GPT3 Error Occured");
        lambdaTrigger.GPT = 1;
      }

      if (
        [STATUS_FLAG.GPT_PROCESS_START, STATUS_FLAG.CLUSTERING_ERROR].includes(
          data.suggestionStatus
        )
      ) {
        errorObject = {
          ...errorObject,
          suggestionStatus: STATUS_FLAG.GPT_PROCESS_START,
        };

        console.log("Suggestion Clustering Error Occured");
        lambdaTrigger.CLUSTERING = 1;
      }
    }

    if (
      [
        STATUS_FLAG.INIT,
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(data.sentimentStatus)
    ) {
      errorStates++;

      errorObject = {
        ...errorObject,
        sentimentStatus: STATUS_FLAG.INIT,
      };

      console.log("Sentiment GPT3 Error Occured");
      lambdaTrigger.SENTIMENT = 1;
    }
    // Triggering only when reviews fetched is greater than 1499
    if (data.reviewsFetched >= 1500) {
      // Some error had occured in any one of the steps
      // If clustering error has happened, rather than deleting all others, just trigger the clustering again
      // and reset the status of those to 3 again before moving the user
      // to the import status page
      await updateAppImportStatus(data.appID, errorObject);

      // Trigger the re-process lambda based on the lambda trigger object.
      // No more db clean up required
      //
      //
      //  Put the lambda trigger function here

      try {
        await importErrorHandler({
          appID: data.appID,
          offset: 0,
          size: 100,
          limit: 1500,
          ...lambdaTrigger,
          // If process needs to start from GPT, there is no need to send clustering reprocess as well
          ...(lambdaTrigger.GPT === 1 ? { CLUSTERING: 0 } : {}),
        });
      } catch (e: any) {
        console.log(e);
        reImportErrored = true;
        // errorObject["reviewsFetched"] = 0;
        await updateAppImportStatus(data.appID, {
          status: STATUS_FLAG.INIT,
          reviewsFetched: 0,
        });
        // if (e.includes("files not in imported bucket")) {
        //   console.log("File not present in bucket, start import again");
        //   reImportErrored = true;
        //   errorObject["reviewsFetched"] = 0;
        // }
      }
    }

    console.log("Error States", errorStates);

    // Start the import only when the number of reviews fetched is less than 1500 and the status is not 7 for all
    if (
      (data.reviewsFetched < 1500 &&
        [
          data.complaintStatus,
          data.complementStatus,
          data.sentimentStatus,
          data.suggestionStatus,
        ].filter((st) => st === STATUS_FLAG.PROCESS_COMPLETE).length !== 4) ||
      reImportErrored
    ) {
      try {
        if (searchText.store === "google") {
          await startGoogleImport(
            data.appID,
            getWithExpiry("token") || "",
            localStorage.getItem("username") || ""
          );
        } else if (searchText.store === "apple") {
          await startAppleImport(
            data.appID,
            data.name,
            data.releasedDate,
            getWithExpiry("token") || "",
            localStorage.getItem("username") || ""
          );
        }
      } catch (e) {
        console.log("Some error happened, ", e);
      }
    }
  }
};

// Get user data based on the username
export const getUserData = async (appData: any, dispatch: any) => {
  try {
    let res = await getUserDetails(
      localStorage.getItem("username") || appData.user.email || ""
    );
    if (appData.user && !appData.user.email) {
      // Retrieving apps when they aren't present in local
      // Adding logic to check if apps exists in the org if not present with user's account
      if (res && res.getUser && !res.getUser.userAppId) {
        console.log("App id doesn't exist in user schema");

        let appIDs = await getUserAppIDs(res.getUser);

        if (appIDs && appIDs.length) {
          res = {
            ...res,
            getUser: {
              ...res.getUser,
              userAppId: appIDs,
            },
          };
        }
      }
      setUserDataAndRole(dispatch, res.getUser);
    }
    return res.getUser;
  } catch (e) {
    console.log(e);
  }
};

// Helper function to create the legend for the graphs based on the filter selected
export const constructLegend = (selectedFilter: FilterOption) => {
  const curDate = new Date();
  const currDay = curDate.getDay();
  const currMonth = curDate.getMonth();

  switch (selectedFilter.value) {
    case "lastWeek":
      return weekDays
        .map((_, index) => {
          return weekDays[(currDay - index + 7) % 7];
        })
        .reverse();
    case "lastMonth":
      return [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          //const year = initDate.getFullYear();
          const day = initDate.getDate();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          const labelString = `${months[month]} ${day}`;
          return labelString;
        })
        .reverse();
    case "lastQuarter":
      return [0, 15, 30, 45, 60, 75, 90]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          //const year = initDate.getFullYear();
          const day = initDate.getDate();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          const labelString = `${months[month]} ${day}`;
          return labelString;
        })
        .reverse();
    case "lastYear":
      return months
        .map((_, index) => {
          const initDate = new Date();
          const day = initDate.getDate();
          const minusDays = index * 30;
          initDate.setDate(initDate.getDate() - minusDays - day + 1);

          const year = initDate.getFullYear();
          if (currMonth > index) {
            return `${months[(currMonth - index) % 12]} ${year
              .toString()
              .slice(2, 4)}`;
          }
          return `${months[(currMonth - index + 12) % 12]} ${year
            .toString()
            .slice(2, 4)}`;
        })
        .reverse();
    // For 5 years
    case "allTime":
      return [
        0, 150, 300, 450, 600, 750, 900, 1050, 1200, 1350, 1500, 1650, 1800,
        1950, 2100,
      ]
        .map((days) => {
          const initDate = new Date();
          initDate.setDate(initDate.getDate() - days);

          const month = initDate.getMonth();
          const year = initDate.getFullYear();
          //const labelString = `${months[month]} ${year.toString().slice(2, 4)}`
          const labelString = `${months[month]} ${year.toString().slice(2, 4)}`;
          return labelString;
        })
        .reverse();
    case "custom":
      let date_1 = new Date(selectedFilter.dates[0]);
      let date_2 = new Date(selectedFilter.dates[1]);
      const dayRange = (date_2.getTime() - date_1.getTime()) / timeDiff;
      const dayDiff = getCustomDayDifference(dayRange);

      let returnArr = [];
      for (let i = 0; i <= Math.floor(dayRange / dayDiff); i++) {
        let date_3 = new Date(selectedFilter.dates[1]);
        date_3.setDate(date_3.getDate() - Math.floor(i * dayDiff));
        const day = date_3.getDate();
        const month = date_3.getMonth();
        const year = date_3.getFullYear();
        // Same month and year
        if (
          date_1.getMonth() === date_2.getMonth() &&
          date_1.getFullYear() === date_2.getFullYear()
        ) {
          returnArr.push(`${months[month]} ${day}`);
          // Only same year
        } else if (date_1.getFullYear() === date_2.getFullYear()) {
          returnArr.push(`${months[month]} ${day}`);
          // Different month and year
        } else
          returnArr.push(
            `${months[month]} ${day}, ${year.toString().slice(2, 4)}`
          );
      }
      return returnArr.reverse();
    default:
      return months;
  }
};

// Helper function to construct data points for the graph based on filter
// Returns an array
// Case 1: For single data point, returns the count in the first variable, e.g. report data
// Case 2: For sentiments, returns positive count in first var, negative count in second var
// Case 3: For star ratings, returns the total sum of ratings in first var, the total count of reviews in second var
export const constructData = (
  selectedFilter: FilterOption,
  data: any,
  type?: string
) => {
  const { expression3 } = getFilterValues({
    filter: selectedFilter,
  });
  const labels = constructLabelObject(selectedFilter);
  let countData = labels.map((_) => 0);
  let countDataTwo = labels.map((_) => 0);
  switch (selectedFilter.value) {
    case "lastYear": {
      data.forEach((data: any) => {
        const dataDate = new Date(data.date);
        const month = dataDate.getMonth();
        const year = dataDate.getFullYear();

        const index = labels.findIndex((l: any) => {
          return l.month === month && l.year === year;
        });

        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });
      return [countData, countDataTwo];
    }
    case "lastQuarter": {
      data.forEach((data: any) => {
        const index = labels.findIndex((l: any) => {
          return expression3(data.date, l.date, 15);
        });

        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });
      return [countData, countDataTwo];
    }
    case "lastMonth": {
      data.forEach((data: any) => {
        const index = labels.findIndex((l: any) => {
          return expression3(data.date, l.date, 3);
        });

        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });

      return [countData, countDataTwo];
    }
    case "lastWeek": {
      data.forEach((data: any) => {
        const index = labels.findIndex((l: any) => {
          return expression3(data.date, l.date, 1);
        });
        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });
      return [countData, countDataTwo];
    }
    case "allTime": {
      data.forEach((data: any) => {
        const index = labels.findIndex((l: any) => {
          return expression3(data.date, l.date, 150);
        });

        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });
      return [countData, countDataTwo];
    }
    case "custom": {
      data.forEach((data: any, i: number) => {
        let date_1 = new Date(selectedFilter.dates[0]);
        let date_2 = new Date(selectedFilter.dates[1]);
        const dayRange = (date_2.getTime() - date_1.getTime()) / timeDiff;
        const dayDiff = getCustomDayDifference(dayRange);
        const index = labels.findIndex((l: any) => {
          return expression3(data.date, l.date, dayDiff);
        });

        if (index !== -1) {
          if (type && type === SENTIMENTS) {
            if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) > 0.1
            ) {
              countData[index]++;
            } else if (
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) < -0.1
            ) {
              countDataTwo[index]++;
            }
          } else {
            if (type && type === "rating") {
              if (data.rating) {
                countData[index] += data.rating;
                countDataTwo[index]++;
              }
            } else {
              countData[index]++;
            }
          }
        }
      });
      return [countData, countDataTwo];
    }
    default: {
      return [countData, countDataTwo];
    }
  }
};

// Construct the data array to show cluster and sub cluster
// Used for intial creation of clusters from raw data
export const constructDataArray = (
  data: any,
  type: string,
  setData?: (value: any) => void
) => {
  let clusters: ClusterData[] = [];
  // let report: any[] = months.map((_) => 0);
  // let labels: any[] = [...label];
  data.forEach((data: any) => {
    if (
      clusters.filter((cluster) => cluster.clusterGroup === data.clusterGroup)
        .length
    ) {
      const index = clusters.findIndex(
        (cluster) => cluster.clusterGroup === data.clusterGroup
      );
      let clusterPoints = [];

      try {
        clusterPoints = data.clusterPoints
          .replace("]", "")
          .replace("[", "")
          .split(",");
      } catch (e) {
        console.log("Problem in splitting cluster points");
      }

      let subClusters = [...clusters[index].subClusters];
      let count = clusters[index].count;
      const subclusterIndex = clusters[index].subClusters.findIndex(
        (sub: any) => sub.clusterLabel === data.clusterLabel
      );
      if (subclusterIndex === -1) {
        let subCluster: SubClusterData = {
          clusterTopics: data.clusterTopics,
          clusterSummary: data.clusterSummary ? data.clusterSummary : "",
          clusterLabel: data.clusterLabel,
          [type]: data[type],
          content: data.content,
          date: data.date,
          dates: [data.date],
          new: 0,
          clusterPoints,
          id: data.id,
          reviewId: [data.reviewID],
          reviewMapping: [
            {
              reviewId: data.reviewID,
              point: data[type],
            },
          ],
          rating: data.rating,
        };
        subClusters.push(subCluster);
        count = count + clusterPoints.length;
      } else {
        let reviews = [
          ...clusters[index].subClusters[subclusterIndex].reviewId,
          data.reviewID,
        ];
        // if (!reviews.includes(data.reviewID)) {
        //   reviews.push(data.reviewID);
        // }
        let subCluster: SubClusterData = {
          ...clusters[index].subClusters[subclusterIndex],
          dates: [
            ...clusters[index].subClusters[subclusterIndex].dates,
            data.date,
          ],
          reviewMapping: [
            ...clusters[index].subClusters[subclusterIndex].reviewMapping,
            {
              reviewId: data.reviewID,
              point: data[type],
            },
          ],
          reviewId: reviews,
        };
        subClusters[subclusterIndex] = subCluster;
      }

      const newDate =
        new Date(clusters[index].date).getTime() -
          new Date(data.date).getTime() >
        0
          ? data.date
          : clusters[index].date;

      clusters[index] = {
        ...clusters[index],
        count,
        date: newDate,
        subClusters,
      };
    } else {
      let clusterPoints = [];
      try {
        clusterPoints = data.clusterPoints
          .replace("]", "")
          .replace("[", "")
          .split(",");
      } catch (e) {
        console.log("Problem in splitting cluster points");
      }

      clusters.push({
        id: `${data.clusterGroup}_${data.id}`,
        appID: data.appID,
        clusterGroup: data.clusterGroup,
        groupTopic: data.groupTopic ? data.groupTopic : "",
        groupSummary: data.groupSummary ? data.groupSummary : "",
        clusterSummary: data.clusterSummary ? data.clusterSummary : "",
        clusterTopics: data.clusterTopics,
        count: clusterPoints.length,
        date: data.date,
        rating: 0,
        subClusters: [
          {
            clusterTopics: data.clusterTopics,
            clusterSummary: data.clusterSummary ? data.clusterSummary : "",
            clusterLabel: data.clusterLabel,
            [type]: data[type],
            content: data.content,
            date: data.date,
            dates: [data.date],
            new: 0,
            clusterPoints,
            id: data.id,
            reviewId: [data.reviewID],
            reviewMapping: [
              {
                reviewId: data.reviewID,
                point: data[type],
              },
            ],
            rating: data.rating,
          },
        ],
      });
    }
  });

  clusters.forEach((cluster: ClusterData, index: number) => {
    let rating = 0;
    cluster.subClusters.forEach((sub: any) => {
      rating += sub.rating;
    });
    clusters[index].rating = (rating / cluster.subClusters.length).toFixed(1);
  });

  // Putting the un-grouped cluster at the end of the cluster list
  const unGroupedCluster: ClusterData[] = clusters.filter(
    (clus: any) => clus.clusterGroup === "0"
  );

  let sortedClusters = clusters
    .filter((clus: ClusterData) => clus.clusterGroup !== "0")
    .sort((a: ClusterData, b: ClusterData) => b.count - a.count);
  // .concat(unGroupedCluster);
  // Add ungrouped clusters as part of individual group cluster
  if (
    unGroupedCluster &&
    unGroupedCluster.length &&
    unGroupedCluster[0].subClusters &&
    unGroupedCluster[0].subClusters.length
  ) {
    unGroupedCluster[0].subClusters.forEach((data: any) => {
      sortedClusters.push({
        id: `${unGroupedCluster[0].clusterGroup}_${data.id}`,
        appID: unGroupedCluster[0].appID,
        [type]: data[type],
        clusterGroup: unGroupedCluster[0].clusterGroup,
        clusterLabel: data.clusterLabel ? data.clusterLabel : "",
        groupTopic: `${data.clusterTopics}`,
        clusterSummary: data.clusterSummary,
        groupSummary: data.clusterSummary,
        clusterTopics: data.clusterTopics,
        clusterPoints: data.clusterPoints,
        count: data.clusterPoints.length,
        date: data.date,
        rating: data.rating.toFixed(1),
        reviewId: data.reviewId,
        reviewMapping: data.reviewMapping,
        subClusters: [],
      });
    });
  }

  setData && setData(sortedClusters);
  return sortedClusters;
};

// Construct trending array based on recency score.
export const constructTrendingrray = (
  data: any,
  type: string,
  setData?: (value: any) => void
) => {
  let clusters: any[] = [];
  // let report: any[] = months.map((_) => 0);
  // let labels: any[] = [...label];
  data.forEach((data: any) => {
    if (
      clusters.filter(
        (cluster) =>
          `${cluster.clusterLabel}_${cluster[type]}` === data.clusterGroup
      ).length
    ) {
      const index = clusters.findIndex(
        (cluster) =>
          `${cluster.clusterLabel}_${cluster[type]}` === `${data.clusterGroup}`
      );
      let clusterPoints = [];

      // try {
      //   clusterPoints = data.clusterPoints
      //     .replace("]", "")
      //     .replace("[", "")
      //     .split(",");
      // } catch (e) {
      //   console.log("Problem in splitting cluster points");
      // }

      let subClusters = [...clusters[index].subClusters];
      let subCluster = {};
      let count = clusters[index].count;
      const subclusterIndex = clusters[index].subClusters.findIndex(
        (sub: any) => sub.clusterLabel === data.clusterLabel
      );
      if (subclusterIndex === -1) {
        subCluster = {
          clusterTopics: data.clusterTopics,
          clusterLabel: data.clusterLabel,
          [type]: data[type],
          content: data.content,
          date: data.date,
          dates: [data.date],
          new: 0,
          //clusterPoints,
          id: data.id,
          reviewId: [data.reviewID],
          rating: data.rating,
        };
        subClusters.push(subCluster);
        count = count + clusterPoints.length;
      } else {
        subCluster = {
          ...clusters[index].subClusters[subclusterIndex],
          dates: [
            ...clusters[index].subClusters[subclusterIndex].dates,
            data.date,
          ],
          reviewId: [
            ...clusters[index].subClusters[subclusterIndex].reviewId,
            data.reviewID,
          ],
        };
        subClusters[subclusterIndex] = subCluster;
      }

      const newDate =
        new Date(clusters[index].date).getTime() -
          new Date(data.date).getTime() >
        0
          ? data.date
          : clusters[index].date;

      clusters[index] = {
        ...clusters[index],
        count,
        date: newDate,
        subClusters,
      };
    } else {
      let clusterPoints = [];
      // try {
      //   clusterPoints = data.clusterPoints
      //     .replace("]", "")
      //     .replace("[", "")
      //     .split(",");
      // } catch (e) {
      //   console.log("Problem in splitting cluster points");
      // }

      clusters.push({
        id: `${data.clusterGroup}_${data.id}`,
        appID: data.appID,
        clusterGroup: `${data.clusterLabel}_${data[type]}`,
        groupTopic: data.groupTopic ? data.groupTopic : "",
        clusterTopics: data.clusterTopics,
        count: clusterPoints.length,
        date: data.date,
        rating: 0,
        subClusters: [
          {
            clusterTopics: data.clusterTopics,
            clusterLabel: data.clusterLabel,
            [type]: data[type],
            content: data.content,
            date: data.date,
            dates: [data.date],
            new: 0,
            //clusterPoints,
            id: data.id,
            reviewId: [data.reviewID],
            rating: data.rating,
          },
        ],
      });
    }
  });

  clusters.forEach((cluster: any, index: number) => {
    let rating = 0;
    cluster.subClusters.forEach((sub: any) => {
      rating += sub.rating;
    });
    clusters[index].rating = (rating / cluster.subClusters.length).toFixed(1);
  });

  // Putting the un-grouped cluster at the end of the cluster list
  const unGroupedCluster = clusters.filter(
    (clus: any) => clus.clusterGroup === "0"
  );

  const sortedClusters = clusters
    .filter((clus: any) => clus.clusterGroup !== "0")
    .sort((a, b) => b.count - a.count)
    .concat(unGroupedCluster);

  setData && setData(sortedClusters);
  return sortedClusters;
};

// Get Complaints data related to an app from db until nextToken is null
// Now only used for getting all the app feedbacks from db
export const complaintsFunc = async (
  type: string,
  complaintsItems: any[],
  setComplaintsItems: (value: any[]) => void,
  dispatch: any,
  action: any,
  property: string,
  token?: string
) => {
  let funcPointer;
  switch (type) {
    case COMPLAINTS:
      funcPointer = token ? fetchNextComplaintsData : getComplaintsData;
      break;
    case COMPLIMENTS:
      funcPointer = token ? fetchNextComplimentsData : getComplimentsData;
      break;
    case SUGGESTIONS:
      funcPointer = token ? fetchNextSuggestionsData : getSuggestionsData;
      break;
    case SENTIMENTS:
      funcPointer = token ? fetchSentimentsNextPageData : fetchSentimentsData;
      break;
    case TRENDING:
      funcPointer = token ? getNextTrendingTopicsData : getTrendingTopicsData;
      break;
    case APP_FEEDBACK:
      funcPointer = token ? fetchNextAppFeedbackList : fetchAppFeedbackList;
      break;
    default:
      funcPointer = token ? fetchNextComplaintsData : getComplaintsData;
      break;
  }

  const res = await funcPointer(
    localStorage.getItem("appId") || "",
    token ? token : ""
  );
  let newComplaints: any[] = [];
  if (res && res[property] && res[property].items) {
    newComplaints = [...complaintsItems, ...res[property].items];
    // console.log(newComplaints);
    if (!(res && res[property] && res[property].nextToken)) {
      dispatch(action(newComplaints));
      return newComplaints;
    }
  }

  if (res && res[property] && res[property].nextToken) {
    complaintsFunc(
      type,
      newComplaints,
      setComplaintsItems,
      dispatch,
      action,
      property,
      res[property].nextToken
    );
  }
};

// Truncate string from middle
export const truncateFromMiddle = (
  fullStr: string,
  strLen: number,
  separator = "..."
) => {
  if (fullStr.length <= strLen) return fullStr;
  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);
  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

// Truncate string from end
export const truncateFromEnd = (
  fullStr: string,
  strLen: number,
  separator = "..."
) => {
  if (fullStr.length <= strLen) return fullStr;
  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow);
  return fullStr.substr(0, frontChars) + separator;
};

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

// // Helper function to take the data created from clusters, and attach the reviews from sentiment analysis after the data is ready
// export const constructClusterSentiment = (
//   clusterData: any[],
//   sentimentsData: any[],
//   trendingTopics?: any[]
// ) => {
//   const clusters: any[] = [];
//   if (clusterData && clusterData.length) {
//     clusterData.forEach((clus: any) => {
//       let reviewMappings: ReviewPoint[] = [];
//       if (clus.clusterGroup !== "0") {
//         let subClusters: any[] = [];
//         let reviews: any[] = [];
//         clus.subClusters.forEach((sub: any) => {
//           let sentimentData: any[] = [];
//           reviews = reviews.concat(sub.reviewId.filter(onlyUnique));
//           // Add the review mappings to global set
//           if (sub.reviewMapping && sub.reviewMapping.length) {
//             reviewMappings = reviewMappings.concat(sub.reviewMapping);
//           }

//           sub.reviewId.filter(onlyUnique).forEach((id: string) => {
//             const indexFound = sentimentsData.findIndex(
//               (sent) => sent.reviewID === id
//             );
//             if (indexFound !== -1) {
//               sentimentData.push({
//                 ...sentimentsData[indexFound],
//               });
//             }
//           });
//           let subCluster = { ...sub };
//           if (trendingTopics && trendingTopics.length) {
//             const indexFound = trendingTopics.findIndex(
//               (tren: any) =>
//                 tren.clusterLabel === sub.clusterLabel &&
//                 sub[tren.type.toLowerCase()]
//             );

//             if (indexFound !== -1) {
//               subCluster["recencyScore"] =
//                 trendingTopics[indexFound]["recencyScore"];
//             } else subCluster["recencyScore"] = 0;
//           }
//           subClusters.push({ ...subCluster, sentimentData });
//         });
//         const recencyArray = subClusters.map((sub) =>
//           isNaN(parseFloat(sub.recencyScore)) ? 0 : parseFloat(sub.recencyScore)
//         );
//         const recencyScore =
//           recencyArray.reduce((a: number, b: number) => a + b, 0) /
//           (subClusters.length === 0 ? 1 : subClusters.length);
//         clusters.push({
//           ...clus,
//           subClusters,
//           count: reviews.filter(onlyUnique),
//           recencyScore: isNaN(recencyScore) ? 0 : recencyScore,
//           reviewMappings,
//         });
//       }

//       if (clus.clusterGroup === "0") {
//         let sentimentData: any[] = [];
//         let subCluster = { ...clus };
//         // Add the review mappings to global set
//         if (clus.reviewMapping && clus.reviewMapping.length) {
//           reviewMappings = reviewMappings.concat(clus.reviewMapping);
//         }
//         clus.reviewId.filter(onlyUnique).forEach((id: string) => {
//           const indexFound = sentimentsData.findIndex(
//             (sent) => sent.reviewID === id
//           );
//           if (indexFound !== -1) {
//             sentimentData.push({
//               ...sentimentsData[indexFound],
//             });
//           }
//         });

//         if (trendingTopics && trendingTopics.length) {
//           const indexFound = trendingTopics.findIndex(
//             (tren: any) =>
//               tren.clusterLabel === clus.clusterLabel &&
//               clus[tren.type.toLowerCase()]
//           );

//           if (indexFound !== -1) {
//             subCluster["recencyScore"] =
//               trendingTopics[indexFound]["recencyScore"];
//           } else subCluster["recencyScore"] = 0;
//         }

//         clusters.push({
//           ...subCluster,
//           sentimentData,
//           reviewMappings,
//         });
//       }
//     });
//   }

// Helper function to take the data created from clusters, and attach the reviews from sentiment analysis after the data is ready
export const constructClusterSentiment = (
  clusterData: any[],
  sentimentsData: any[],
  trendingTopics?: any[]
) => {
  const clusters: any[] = [];

  if (!clusterData || !clusterData.length) {
    return clusters;
  }

  clusterData.forEach((clus: any) => {
    let reviewMappings: ReviewPoint[] = [];
    let subClusters: any[] = [];
    let reviews: any[] = [];

    if (clus.clusterGroup !== "0") {
      clus.subClusters.forEach((sub: any) => {
        const sentimentData = getSentimentData(sub.reviewId, sentimentsData);
        reviews = reviews.concat(sub.reviewId.filter(onlyUnique));
        reviewMappings = reviewMappings.concat(sub.reviewMapping || []);
        const subCluster = processSubCluster(
          sub,
          sentimentData,
          trendingTopics
        );
        subClusters.push(subCluster);
      });

      const recencyScore = calculateRecencyScore(subClusters);
      clusters.push({
        ...clus,
        subClusters,
        count: reviews.filter(onlyUnique),
        recencyScore: isNaN(recencyScore) ? 0 : recencyScore,
        reviewMappings,
      });
    }

    if (clus.clusterGroup === "0") {
      const sentimentData = getSentimentData(clus.reviewId, sentimentsData);
      reviewMappings = reviewMappings.concat(clus.reviewMapping || []);
      const subCluster = processSubCluster(clus, sentimentData, trendingTopics);
      clusters.push({ ...subCluster, reviewMappings });
    }
  });

  return clusters.sort((a: any, b: any) => {
    const bScore = parseFloat(b.recencyScore) || 0;
    const aScore = parseFloat(a.recencyScore) || 0;
    return bScore - aScore;
  });
};

const getSentimentData = (reviewId: string[], sentimentsData: any) => {
  return reviewId
    .filter(onlyUnique)
    .map((id: string) => sentimentsData[id])
    .filter(Boolean);
};

const processSubCluster = (
  subCluster: any,
  sentimentData: any,
  trendingTopics?: any[]
) => {
  const recencyScore = getRecencyScore(subCluster, trendingTopics);
  return { ...subCluster, sentimentData, recencyScore };
};

const calculateRecencyScore = (subClusters: any[]) => {
  const recencyArray = subClusters.map(
    (sub) => parseFloat(sub.recencyScore) || 0
  );
  return (
    recencyArray.reduce((a: number, b: number) => a + b, 0) /
    (subClusters.length || 1)
  );
};

const getRecencyScore = (subCluster: any, trendingTopics?: any[]) => {
  if (!trendingTopics || !trendingTopics.length) {
    return 0;
  }

  const indexFound = trendingTopics.findIndex(
    (trend: any) =>
      trend.clusterLabel === subCluster.clusterLabel &&
      subCluster[trend.type.toLowerCase()]
  );

  return indexFound !== -1
    ? parseFloat(trendingTopics[indexFound].recencyScore) || 0
    : 0;
};

export const getReviewMappings = (cluster: any[]) => {
  let reviewMappings: ReviewPoint[] = [];

  if (cluster && cluster.length) {
    cluster.forEach((clus: any) => {
      reviewMappings = reviewMappings.concat(clus.reviewMappings);
    });
  }

  return reviewMappings;
};

export const NONE_LIST = [
  "None",
  " None",
  "none",
  " none",
  "none ",
  "none  ",
  "None ",
  " None ",
  " None  ",
  "None.",
  " None. ",
  " None.",
  " None    ",
  "\nNone.",
  " None provided",
  " None provided.",
  "\nNone",
  " None given",
  " None given.",
  "None mentioned.",
  "None mentioned",
  " None mentioned",
  " None mentioned.",
  "None mentioned.",
  "None mentioned",
  " None mentioned in review.",
  " None stated in the review.",
  " None stated",
  " None stated.",
  "None stated",
  "None stated.",
  " None specified.",
  " None specified",
  "None specified.",
  "None specified",
];

export const checkNoneList = (text: string) => {
  let flag = false;

  NONE_LIST.some((none: string) => {
    if (text && text.toLowerCase().includes(none.trim().toLowerCase())) {
      flag = true;
      return true;
    }
    return false;
  });

  return flag;
};

// Helper function to calculate sentiment score for an application
export const getSentimentScore = (
  selectedFilter: {
    label: string;
    value: string;
  },
  sentimentData: any
) => {
  const { expression, expression2, getNumDays } = getFilterValues({
    filter: selectedFilter,
  });

  let filteredData: any[] = [];
  let filteredData2: any[] = [];

  if (sentimentData && sentimentData.data && sentimentData.data.length) {
    filteredData = sentimentData.data.filter((review: any) =>
      expression(review.date)
    );
    filteredData2 = sentimentData.data.filter((review: any) =>
      expression2(review.date)
    );
  } else {
    filteredData = sentimentData.filter((review: any) =>
      expression(review.date)
    );
    filteredData2 = sentimentData.filter((review: any) =>
      expression2(review.date)
    );
  }

  let scoreWeight = filteredData.map((fil: any) => {
    if (fil.sentimentScore !== undefined) {
      return Math.abs(parseFloat(fil.sentimentScore));
    }
    return 0;
  });
  let weightedAverage = 0;
  let scoreWeight2 = filteredData2.map((fil: any) => {
    if (fil.sentimentScore !== undefined) {
      return Math.abs(parseFloat(fil.sentimentScore));
    }
    return 0;
  });
  let weightedAverage2 = 0;

  scoreWeight.forEach((w, index) => {
    if (filteredData[index].sentimentScore !== undefined) {
      let val = w * parseFloat(filteredData[index].sentimentScore);
      // Handling exception cases
      if (val <= 100) {
        weightedAverage += val;
      }
    }
  });
  weightedAverage = weightedAverage / filteredData.length;

  scoreWeight2.forEach((w, index) => {
    if (filteredData2[index].sentimentScore !== undefined) {
      let val = w * parseFloat(filteredData2[index].sentimentScore);
      if (val <= 100) {
        weightedAverage2 += val;
      }
    }
  });
  weightedAverage2 = weightedAverage2 / filteredData2.length;

  let oldRange = 2;
  let newRange = 100;

  let percentageScore = Math.round(
    ((weightedAverage + 1) * newRange) / oldRange
  );
  let percentageScore2 = Math.round(
    ((weightedAverage2 + 1) * newRange) / oldRange
  );

  const initDate = new Date();
  initDate.setDate(initDate.getDate() - getNumDays);

  const day = initDate.getDate();
  const month = initDate.getMonth();
  const year = initDate.getFullYear();

  return {
    weightedAverage: isNaN(weightedAverage) ? 0 : weightedAverage,
    total: isNaN(percentageScore) ? 0 : percentageScore,
    lastMonthPercentage:
      (isNaN(percentageScore) ? 0 : percentageScore) -
      (isNaN(percentageScore2) ? 0 : percentageScore2),
    dataSince: `${months[month]} ${day}, ${year.toString().slice(2, 4)}`,
  };
};

// Helper function to calculate star ratings for an app
export const starRatingScore = (
  selectedFilter: {
    label: string;
    value: string;
  },
  sentimentData: any
) => {
  const { expression } = getFilterValues({
    filter: selectedFilter,
  });

  let filteredData: any[] = [];

  if (sentimentData && sentimentData.data && sentimentData.data.length) {
    filteredData = sentimentData.data.filter((review: any) =>
      expression(review.date)
    );
  } else {
    filteredData = sentimentData.filter((review: any) =>
      expression(review.date)
    );
  }

  let rating = 0;
  filteredData.forEach((fil: any) => {
    if (typeof fil.rating === "string" && fil.rating !== "") {
      rating += parseInt(fil.rating);
    } else if (typeof fil.rating === "number") {
      rating += fil.rating;
    }
  });

  return (rating / filteredData.length).toFixed(1);
};

// Get feedback dashboard graph
export const getFeedbackDashboardGraph = (appData: any) => {
  let graph: any = [];

  if (appData && appData.app && appData.app.length) {
    appData.app.forEach((app: DATA_BY_STORE) => {
      let returnObject: any = {};
      filterOptions.forEach((filter: FilterOption) => {
        const store = [
          {
            store: app.store,
            appID: app.appID,
          },
        ];
        const [complaints] = constructData(
          filter,
          getDataByStore(
            appData.complaints,
            // store.map((s: GET_DATA_STORE) => s.store)
            store
          )
        );
        const [compliments] = constructData(
          filter,
          getDataByStore(
            appData.compliments,
            // store.map((s: GET_DATA_STORE) => s.store)
            store
          )
        );
        const [suggestions] = constructData(
          filter,
          getDataByStore(
            appData.suggestions,
            // store.map((s: GET_DATA_STORE) => s.store)
            store
          )
        );

        returnObject[filter.value] = {
          data: {
            complaints,
            compliments,
            suggestions,
          },
        };
      });
      graph.push({
        store: app.store,
        data: returnObject,
      });
    });
  }

  return graph;
};

// Get sentiment over time graph
export const getSentimentOverTimeGraph = (appData: any) => {
  let returnObject: any = { ...FeedbackGraphInit };
  filterOptions.forEach((filter: FilterOption) => {
    const [positive, negative] = constructData(
      filter,
      appData.sentiments,
      SENTIMENTS
    );

    returnObject[filter.value] = {
      data: {
        positive,
        negative,
        overall: positive.map(
          (pos: any, index: number) => pos + negative[index]
        ),
      },
    };
  });

  return returnObject;
};
