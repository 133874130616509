import axios from "axios";
import {
  getHashedAPIKey,
  getWithExpiry,
  setWithExpiry,
} from "../../components/shared/constants";
import { removeUserTokens } from "../context/Account";
import { getTokenFromRefreshToken } from "./Apps.api";

declare global {
  interface Window {
    RARGUS: any;
  }
}

let token = localStorage.getItem("token");
let accessToken: string = "";
if (token) {
  try {
    accessToken = JSON.parse(token).value;
  } catch (e) {
    accessToken = token;
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI,
  timeout: 30000,
});

// Interceptor for adding token to api
api.interceptors.request.use(
  async (config: any) => {
    if (getWithExpiry("token") || (accessToken && accessToken !== "")) {
      config.headers.Authorization = `Bearer ${
        getWithExpiry("token") || accessToken
      }`;
      // config.headers["Access-Control-Allow-Origin"] = "*";
      // config.headers["Access-Control-Allow-Methods"] =
      //   "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH";

      return config;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

// Interceptor for token refresh
api.interceptors.response.use(undefined, async (err) => {
  const { response } = err;

  if (
    response &&
    response.status === 401 &&
    response.config &&
    !response.config.__isRetryRequest
  ) {
    const rememberMe = getWithExpiry("rememberMe");
    //console.log(rememberMe)
    if (rememberMe && rememberMe === 1) {
      try {
        // Prevent infinite loop
        response.config.__isRetryRequest = true;
        // Get the hashed key
        const refreshHash = getHashedAPIKey();
        // Fetch the new token
        const responseObject = await getTokenFromRefreshToken(refreshHash);

        let accessObject;
        if (responseObject && responseObject.body) {
          try {
            accessObject = JSON.parse(responseObject.body);
          } catch (e) {
            accessObject = responseObject.body;
          }
        }

        if (accessObject && accessObject.message) {
          removeUserTokens();
          window.location.href = "/login";
        } else if (accessObject && accessObject.access_token) {
          // Update in local storage
          setWithExpiry("token", accessObject.access_token, 1);
          setWithExpiry("idToken", accessObject.id_token, 1);

          // Setup the new token
          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessObject.access_token}`;
          accessToken = accessObject.access_token;
          err.config.headers.Authorization = `Bearer ${accessObject.access_token}`;

          return api(err.config);
        }
      } catch (e) {
        removeUserTokens();
        window.location.href = "/login";
      }
    } else {
      removeUserTokens();
      window.location.href = "/login";
    }
  }

  if (
    response &&
    response.status === 429 &&
    response.config &&
    !response.config.__isRetryRequest
  ) {
    try {
      // Prevent infinite loop
      response.config.__isRetryRequest = true;
      return api(err.config);
    } catch (e) {
      console.log("Some error occured", e);
    }
  }
});

api.interceptors.response.use(undefined, async (err) => {
  const { response } = err;

  if (response && response.status === 403) {
    window.location.href = "/404";
  }
});

export default api;
