import Tooltip from "../shared/wrappers/Tooltip";
import FlexBox from "../shared/wrappers/FlexBox";
import "./widget.css";
import { useState, useEffect } from "react";

const SentimentBar = ({ data }: any) => {
  const [positive, setPositive] = useState(0);
  const [negative, setNegative] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (data && data.datasets && data.datasets.length) {
      const positiveData = data.datasets[1].data;
      const negativeData = data.datasets[2].data;

      const pos = positiveData.reduce((a: number, b: number) => a + b, 0);
      const neg = negativeData.reduce((a: number, b: number) => a + b, 0);
      const prog = Math.floor((pos / (pos + neg)) * 100);

      setPositive(pos);
      setNegative(neg);
      setProgress(prog ? prog : 0);
    }
  }, [data]);

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "79%",
        width: "7rem",
        marginTop: "0.5rem",
      }}
    >
      {positive && positive !== 0 ? (
        <FlexBox
          sx={{
            background: "#4FAEB0",
            height: `${progress}%`,
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
            ...(progress === 100
              ? {
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }
              : {}),
            cursor: "pointer",
            position: "relative",
          }}
        >
          <Tooltip title={`${positive} positive`} placement="top">
            <div
              style={{
                width: "100%",
                height: "100%",
                color: "#4FAEB0",
                // display: "none",
              }}
            >
              {/* {`${positive} positive`} */}
              {"."}
            </div>
          </Tooltip>
        </FlexBox>
      ) : (
        <></>
      )}

      {negative && negative !== 0 ? (
        <FlexBox
          sx={{
            background: "#DB4E82",
            height: `${100 - progress}%`,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            ...(progress === 0
              ? {
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                }
              : {}),
            position: "relative",
            cursor: "pointer",
          }}
        >
          <Tooltip title={`${negative} negative`} placement="top">
            <div
              style={{
                width: "100%",
                height: "100%",
                color: "#DB4E82",
                // display: "none",
              }}
            >
              {/* {`${negative} negative`} */}
              {"."}
            </div>
          </Tooltip>
        </FlexBox>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default SentimentBar;
