import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthStore/AuthSlice";
import AppReducer from "./AppStore/AppStoreSlice";
import GraphReducer from "./GraphStore/GraphSlice";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: {
    auth: AuthReducer,
    app: AppReducer,
    graphs: GraphReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
