import { useContext } from "react";
import { FormContext, FormContextAttr } from "./AddSlack";
import Basic from "./Basic";
import Config from "./Config";
import SelectChannel from "./SelectChannel";
import StartImport from "./StartImport";

const Step = ({ location }: { location: string | undefined }) => {
  const { activeStepIndex } = useContext(FormContext) as FormContextAttr;
  let stepContent = <></>;
  // if (location && location === SETTINGS_PAGE) {
  switch (activeStepIndex) {
    case 0:
      stepContent = <Basic />;
      break;
    case 1:
      stepContent = <Config />;
      break;
    case 2:
      stepContent = <SelectChannel />;
      break;
    case 3:
      stepContent = <StartImport />;
      break;
    default:
      <></>;
      break;
  }
  // } else {
  //   switch (activeStepIndex) {
  //     // case 0:
  //     //   stepContent = <Basic />;
  //     //   break;
  //     case 0:
  //       stepContent = <Config />;
  //       break;
  //     case 1:
  //       stepContent = <SelectChannel />;
  //       break;
  //     case 2:
  //       stepContent = <StartImport />;
  //       break;
  //     default:
  //       <></>;
  //       break;
  //   }
  // }

  return stepContent;
};

export default Step;
