import { Fragment, ReactNode, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Login from '../Login/Login'

interface AuthorizedProps {
    children: ReactNode;
}

const Authorized = ({ children }: AuthorizedProps) => {
    const { pathname } = useLocation()
    const token = localStorage.getItem('token')
    const [requestedLocation, setRequestedLocation] = useState<string | null>(
        null
    );

    if(!token || (token && token === "")) {
        if(pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }

        return <Login />
    }

    if(requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <Fragment>{children}</Fragment>
}

export default Authorized;