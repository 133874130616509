import { Box, Button, FormHelperText } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr } from "./AddSlack";
import { getSlackChannels } from "../../services/api/Apps.api";
import FlexBox from "../shared/wrappers/FlexBox";
import { getWithExpiry, SETTINGS_PAGE } from "../shared/constants";
import { useLocation } from "react-router";

const Config = () => {
  const location = useLocation();

  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    setChannels,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    token: string;
  } = { token: "", ...formData };

  const validationSchema = Yup.object().shape({
    token: Yup.string().required("User token is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        try {
          if (values.token !== "") {
            const data = { ...formData, ...values };
            const response = await getSlackChannels(
              values.token,
              formData.private || false,
              formData.direct || false,
              formData.mpim || false
            );
            if (response && response.body) {
              const channels = JSON.parse(response.body);
              setFormData(data);
              setChannels(channels);
              setActiveStepIndex(activeStepIndex + 1);
            } else {
              setError(
                "We are facing some issue with your provided configuration"
              );
            }
          }
        } catch (e: any) {
          setError(e.message);
        }

        setLoading(false);
      },
    });

  useEffect(() => {
    const slackToken = getWithExpiry("slack-token");
    const slackScope = getWithExpiry("slack_scope");

    let fd = { ...formData };

    if (slackToken && slackToken.length) {
      fd = {
        ...fd,
        token: slackToken,
      };
    }

    if (slackScope && slackScope.length) {
      fd = {
        ...fd,
        ...JSON.parse(slackScope),
      };
    }

    setFormData(fd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Provide your user token
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"User token"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"token"}
              type={"token"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.token || ""}
              error={Boolean(touched.token && errors.token)}
              helperText={touched.token && errors.token}
            />
            <Small>
              Your user token is auto filled from slack authentication on the
              previous page
            </Small>
          </FullTextFieldWrapper>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          {location &&
          location.pathname &&
          !location.pathname.includes(SETTINGS_PAGE) ? (
            <Box
              sx={{
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Small
                color="primary.main"
                sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
                onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              >
                Back
              </Small>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Config;
