import FlexBox from "../shared/wrappers/FlexBox";
import UnfilledStar from "../shared/icons/unfilled-star.svg";
import { TextFilterPopover } from "../shared/wrappers/FilterPopper";
import { STORE } from "../shared/constants";
import { getTotalNumberOfVotes } from "../Widgets/InnerComponents";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { getWorkspaceCount } from "./Cluster";
import { ClusterData, SubClusterData } from "../shared/types";

type Filter = {
  label: string;
  value: string;
};

type SubClusterProps = {
  selectedSubtopic: ClusterData | null;
  filterReviewsHandler: (reviewIds: string[]) => void;
  selectedSubcluster: SubClusterData;
  setSelectedSubcluster: (cluster: any) => void;
  type?: string;
  filterOptions?: Filter[];
  selectedFilter?: Filter;
  setSelectedFilter?: (value: Filter) => void;
  topicSort?: Filter;
};

const SubClusters = ({
  selectedSubtopic,
  filterReviewsHandler,
  selectedSubcluster,
  setSelectedSubcluster,
  type,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  topicSort,
}: SubClusterProps) => {
  const appData: any = useSelector((state: RootState) => state.app);

  const getCount = (subClusters: any) => {
    let count = 0;
    subClusters.forEach(
      (cluster: any) => (count += cluster.sentimentData.length)
    );
    return count;
  };

  const getCountForAha = (
    selectedSubtopic: any,
    subtopic: any,
    topicSort?: Filter
  ) => {
    if (topicSort && topicSort.value) {
      if (topicSort.value === "numberOfVotes") {
        return getTotalNumberOfVotes(selectedSubtopic, subtopic);
      }
      if (topicSort.value === "numberOfWorkspaces") {
        if (subtopic) {
          return subtopic.workspaces && subtopic.workspaces.length;
        }

        if (!subtopic && selectedSubtopic) {
          return getWorkspaceCount(selectedSubtopic).length;
        }
      }
    }

    return 0;
  };

  const getSortedSubclusters = (subClusters: any[]) => {
    const sorted =
      type && type === "Generic"
        ? // No need to sort in case of generic, as the data comes from the parent component based on the
          // type of sort chosen by the user
          subClusters
        : type && type === STORE.aha
        ? [...subClusters]
            .sort(
              (a: any, b: any) =>
                getCountForAha(undefined, b, topicSort) -
                getCountForAha(undefined, a, topicSort)
            )
            .filter((clus) =>
              clus.sentimentData ? clus.sentimentData.length : true
            )
        : [...subClusters]
            .sort(
              (a: any, b: any) =>
                b.sentimentData.length - a.sentimentData.length
            )
            .filter((clus) =>
              clus.sentimentData ? clus.sentimentData.length : true
            );

    return sorted;
  };

  return (
    <FlexBox
      sx={{
        width: "49%",
        flexDirection: "column",
      }}
    >
      <FlexBox
        sx={{
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "0.8rem",
        }}
      >
        <FlexBox
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "17px",
            textTransform: "uppercase",
            color: "#3A3C40",
          }}
        >
          Subtopics
          {type === STORE.aha ? (
            <FlexBox
              sx={{
                marginLeft: "0.2rem",
              }}
            >{`(${
              selectedSubtopic &&
              selectedSubtopic.subClusters &&
              selectedSubtopic.subClusters.length
                ? selectedSubtopic.subClusters.length
                : 0
            })`}</FlexBox>
          ) : (
            <></>
          )}
        </FlexBox>{" "}
        <FlexBox
          sx={{
            fonrWeight: 500,
            fontSize: "10px",
            lineHeight: "14px",
            color: "#3A3C40",
          }}
        >
          {type && type === "Generic" ? (
            <TextFilterPopover
              selectedFilter={selectedFilter}
              setSelectedFilter={
                setSelectedFilter ? setSelectedFilter : () => {}
              }
              filterOptions={filterOptions ? filterOptions : []}
            />
          ) : (
            `${
              appData.store &&
              appData.store.length &&
              appData.store.includes(STORE.aha)
                ? topicSort && topicSort.label
                  ? topicSort.label
                  : "# votes"
                : appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.sof)
                ? "# questions"
                : "# reviews"
            }`
          )}
        </FlexBox>
      </FlexBox>
      {selectedSubtopic ? (
        <FlexBox
          key={`subcluster-${selectedSubtopic.id}`}
          sx={{
            padding: "0 0.1rem 0 0.1rem",
            ...(selectedSubtopic.type === "Compliments"
              ? {
                  background: "#CFE9E9",
                }
              : selectedSubtopic.type === "Suggestions"
              ? {
                  background: "#DDD5FF",
                }
              : selectedSubtopic.type === "Complaints" // Need to add background color for complaints
              ? {
                  background: "#F4CFD9",
                }
              : type === "Generic" && {}),
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            flexDirection: "column",
            alignItems: "center",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          }}
        >
          {selectedSubtopic && (
            <FlexBox
              sx={{
                justifyContent: "space-between",
                width: "100%",
                padding: "0.5rem",
                cursor: "pointer",
                //   marginBottom: "0.5rem",
                ...(selectedSubtopic.type === "Compliments"
                  ? {
                      background: "#CFE9E9",
                      "&:hover": {
                        background: "#AFDADB",
                      },
                    }
                  : selectedSubtopic.type === "Suggestions"
                  ? {
                      background: "#DDD5FF",
                      "&:hover": {
                        background: "#CCC1FF",
                      },
                    }
                  : selectedSubtopic.type === "Complaints" // Need to add background color for complaints
                  ? {
                      background: "#F4CFD9",
                      "&:hover": {
                        background: "#F0B3BD",
                      },
                    }
                  : type && type === "Generic"
                  ? selectedSubtopic.rating &&
                    Number(selectedSubtopic.rating) >= 2.5
                    ? {
                        background: "#CFE9E9",
                        "&:hover": {
                          background: "#AFDADB",
                        },
                      }
                    : selectedSubtopic.rating &&
                      Number(selectedSubtopic.rating) < 2.5 && {
                        background: "#FFE4E1",
                        "&:hover": {
                          background: "#FFC5C3",
                        },
                      }
                  : {}),
                ...(!selectedSubcluster
                  ? selectedSubtopic.type === "Compliments"
                    ? {
                        background: "#AFDADB",
                      }
                    : selectedSubtopic.type === "Suggestions"
                    ? {
                        background: "#CCC1FF",
                      }
                    : selectedSubtopic.type === "Complaints"
                    ? {
                        background: "#F0B3BD",
                      }
                    : type && type === "Generic"
                    ? selectedSubtopic.rating &&
                      Number(selectedSubtopic.rating) >= 2.5
                      ? {
                          background: "#AFDADB",
                        }
                      : selectedSubtopic.rating &&
                        Number(selectedSubtopic.rating) < 2.5 && {
                          background: "#FFC5C3",
                        }
                    : {}
                  : {}),
              }}
              key={`subtopic-item-all`}
              onClick={() => {
                setSelectedSubcluster(undefined);
                if (
                  selectedSubtopic.subClusters &&
                  selectedSubtopic.subClusters.length &&
                  selectedSubtopic.subClusters[0].sentimentData &&
                  selectedSubtopic.subClusters[0].sentimentData.length
                ) {
                  let reviewObj: any[] = [];
                  selectedSubtopic.subClusters.forEach((sub: any) => {
                    reviewObj = reviewObj.concat(sub.sentimentData);
                  });
                  filterReviewsHandler(reviewObj);
                } else {
                  let reviewIds: string[] = [];
                  selectedSubtopic.subClusters.forEach((sub: any) => {
                    reviewIds = reviewIds.concat(sub.reviewId);
                  });
                  filterReviewsHandler(reviewIds);
                }
              }}
            >
              <FlexBox
                sx={{
                  fontSize: "13px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  color: "#0B0E1E",
                  width: "80%",
                }}
              >
                {type && type === "Generic" && (
                  <FlexBox
                    sx={{
                      background: "#E3E6EB",
                      borderRadius: "4px",
                      width: "2.8rem",
                      height: "20px",
                      padding: "1px 6px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "0.4rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {selectedSubtopic.rating}
                    <img
                      src={UnfilledStar}
                      alt="*"
                      width="9px"
                      height="9px"
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </FlexBox>
                )}
                {"all"}
              </FlexBox>
              <FlexBox
                sx={{
                  fontSize: "10px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: "#0B0E1E",
                }}
              >
                {appData.store && appData.store === STORE.aha
                  ? getCountForAha(selectedSubtopic, undefined, topicSort)
                  : selectedSubtopic.clusterGroup !== "0"
                  ? selectedSubtopic.subClusters
                    ? getCount(selectedSubtopic.subClusters)
                    : 0
                  : selectedSubtopic.sentimentData
                  ? selectedSubtopic.sentimentData.length
                  : selectedSubtopic.count}
              </FlexBox>
            </FlexBox>
          )}
          {selectedSubtopic && getCount(selectedSubtopic.subClusters) !== 0 ? (
            getSortedSubclusters(selectedSubtopic.subClusters).map(
              (subtopic: any, index: number) => (
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0.5rem",
                    cursor: "pointer",
                    //   marginBottom: "0.5rem",

                    ...(selectedSubtopic.type === "Compliments"
                      ? {
                          background: "#CFE9E9",
                          "&:hover": {
                            background: "#AFDADB",
                          },
                        }
                      : selectedSubtopic.type === "Suggestions"
                      ? {
                          background: "#DDD5FF",
                          "&:hover": {
                            background: "#CCC1FF",
                          },
                        }
                      : selectedSubtopic.type === "Complaints" // Need to add background color for complaints
                      ? {
                          background: "#F4CFD9",
                          "&:hover": {
                            background: "#F0B3BD",
                          },
                        }
                      : type && type === "Generic"
                      ? subtopic.rating && subtopic.rating >= 2.5
                        ? {
                            background: "#CFE9E9",
                            "&:hover": {
                              background: "#AFDADB",
                            },
                          }
                        : subtopic.rating &&
                          subtopic.rating < 2.5 && {
                            background: "#FFE4E1",
                            "&:hover": {
                              background: "#FFC5C3",
                            },
                          }
                      : {}),
                    ...(selectedSubcluster &&
                    subtopic.id === selectedSubcluster.id
                      ? selectedSubtopic.type === "Compliments"
                        ? {
                            background: "#AFDADB",
                          }
                        : selectedSubtopic.type === "Suggestions"
                        ? {
                            background: "#CCC1FF",
                          }
                        : selectedSubtopic.type === "Complaints"
                        ? {
                            background: "#F0B3BD",
                          }
                        : type && type === "Generic"
                        ? subtopic.rating && subtopic.rating >= 2.5
                          ? {
                              background: "#AFDADB",
                            }
                          : subtopic.rating &&
                            subtopic.rating < 2.5 && {
                              background: "#FFC5C3",
                            }
                        : {}
                      : {}),
                  }}
                  key={`subtopic-item-${index}-${subtopic.clusterTopics}`}
                  onClick={() => {
                    setSelectedSubcluster(subtopic);
                    if (
                      subtopic.sentimentData &&
                      subtopic.sentimentData.length
                    ) {
                      filterReviewsHandler(subtopic.sentimentData);
                    } else {
                      filterReviewsHandler(subtopic.reviewId);
                    }
                  }}
                >
                  {/* {console.log(subtopic.clusterLabel, subtopic.clusterPoints)}
                  {subtopic.clusterLabel} */}
                  <FlexBox
                    sx={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                      width: "80%",
                    }}
                  >
                    {type === "Generic" && (
                      <FlexBox
                        sx={{
                          background: "#E3E6EB",
                          borderRadius: "4px",
                          width: "3rem",
                          height: "20px",
                          padding: "1px 6px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "0.4rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {`${subtopic.rating}.0`}
                        <img
                          src={UnfilledStar}
                          alt="*"
                          width="9px"
                          height="9px"
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </FlexBox>
                    )}
                    {subtopic.clusterTopics.replace(/(^"|"|"|'|'|'$)/g, "")}
                  </FlexBox>
                  <FlexBox
                    sx={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                    }}
                  >
                    {appData.store && appData.store === STORE.aha
                      ? getCountForAha(selectedSubtopic, subtopic, topicSort)
                      : subtopic.sentimentData.length}
                  </FlexBox>
                </FlexBox>
              )
            )
          ) : selectedSubtopic.clusterGroup !== "0" ? (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No subtopics in selected range
            </FlexBox>
          ) : (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              Topic has no subtopics
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default SubClusters;
