import { createSlice } from "@reduxjs/toolkit";
import { Review, ReviewPoint } from "../../../components/Analytics/Analytics";
import {
  DATA_BY_STORE,
  FilterOption,
  isValidURL,
  setDataByStore,
} from "../../../components/shared/constants";
import {
  ClusterData,
  Complaints,
  Compliments,
  Suggestions,
  TrendingTopics,
} from "../../../components/shared/types";

export type WidgetId = {
  id: string;
  widgetId: string;
  label: string;
  source?: string[];
  filter?: FilterOption;
  dependent?: WidgetId[];
};

export type WidgetPage = {
  name: string;
  widgets: WidgetId[];
};

export type AppFeedbackType = {
  feedback: string;
  rating: string;
  user: string;
  id: string;
  createdAt: string;
};

type InitAppStoreSlice = {
  app: {
    store: string;
    data: any;
  }[];
  store: string[];
  reviews: {};
  user: {};
  userRole: {
    isAdmin?: boolean;
    isViewer?: boolean;
    isEditor?: boolean;
    organization?: string;
  };
  filter: {
    label: string;
    value: string;
  };
  toast: never[];
  complaints: {
    appID: string;
    store: string;
    data: Complaints[];
  }[];
  compliments: {
    appID: string;
    store: string;
    data: Compliments[];
  }[];
  suggestions: {
    appID: string;
    store: string;
    data: Suggestions[];
  }[];
  sentiments: {
    appID: string;
    store: string;
    data: Review[];
  }[];
  sentimentsObject: {
    appID: string;
    store: string;
    data: Review[];
  }[];
  sentimentFetch: {
    appID: string;
    store: string;
    data: boolean;
  }[];
  complaintCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  complimentCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  suggestionCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  tempComplaintCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  tempComplimentCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  tempSuggestionCluster: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  clusterSentiment: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  trendingSentiment: {
    appID: string;
    store: string;
    data: ClusterData[];
  }[];
  trendingTopics: {
    appID: string;
    store: string;
    data: TrendingTopics[];
  }[];
  widgetLayout: {
    dashboard: WidgetPage;
    sentiment: WidgetPage;
    rating: WidgetPage;
    analytics: WidgetPage;
  };
  widgetSettings: any;
  dashboardSettings: any;
  complaintReviewMapping: {
    store: string;
    data: ReviewPoint[];
  }[];
  complimentReviewMapping: {
    store: string;
    data: ReviewPoint[];
  }[];
  suggestionReviewMapping: {
    store: string;
    data: ReviewPoint[];
  }[];
  appFeedback: AppFeedbackType[];
};

const initialState: InitAppStoreSlice = {
  app: [],
  store: [],
  reviews: {},
  user: {},
  userRole: {},
  filter: {
    ...{
      label: "All time",
      value: "allTime",
    },
  },
  toast: [],
  complaints: [],
  compliments: [],
  suggestions: [],
  sentiments: [],
  sentimentsObject: [],
  sentimentFetch: [],
  complaintCluster: [],
  complimentCluster: [],
  suggestionCluster: [],
  tempComplaintCluster: [],
  tempComplimentCluster: [],
  tempSuggestionCluster: [],
  clusterSentiment: [],
  trendingSentiment: [],
  trendingTopics: [],
  widgetLayout: {
    dashboard: {
      name: "Feedback dashboard",
      widgets: [],
    },
    sentiment: {
      name: "Sentiments",
      widgets: [],
    },
    rating: {
      name: "Star ratings",
      widgets: [],
    },
    analytics: {
      name: "Analytics",
      widgets: [],
    },
  },
  widgetSettings: undefined,
  dashboardSettings: undefined,
  complaintReviewMapping: [],
  complimentReviewMapping: [],
  suggestionReviewMapping: [],
  appFeedback: [],
};

export const AppSlice: any = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAppData: (state, action) => {
      if (Array.isArray(action.payload)) {
        state["app"] = action.payload;
      }
    },
    setStoreData: (state, action) => {
      state["store"] = action.payload;
    },
    setUserData: (state, action) => {
      state["user"] = action.payload;
    },
    setUserRole: (state, action) => {
      state["userRole"] = action.payload;
    },
    setGlobalFilter: (state, action) => {
      state["filter"] = action.payload;
    },
    setComplaintsData: (state, action) => {
      const existingData = JSON.parse(JSON.stringify(state["complaints"]));
      state["complaints"] = setDataByStore(existingData, action.payload[0]);
    },
    setComplimentsData: (state, action) => {
      const existingData = JSON.parse(JSON.stringify(state["compliments"]));
      state["compliments"] = setDataByStore(existingData, action.payload[0]);
    },
    setSuggestionsData: (state, action) => {
      const existingData = JSON.parse(JSON.stringify(state["suggestions"]));
      state["suggestions"] = setDataByStore(existingData, action.payload[0]);
    },
    setSentimentsData: (state, action) => {
      let sentiments: DATA_BY_STORE[] = [];

      // To store in key value pair
      let sentimentsObject: DATA_BY_STORE[] = [];

      if (action.payload && action.payload.length) {
        action.payload.forEach((item: DATA_BY_STORE) => {
          if (item && item.data) {
            let items: any[] = [];

            // to store in key value pair
            let KeyItem: any = {};
            item.data.forEach((sent: any, index: number) => {
              let sObject = { ...sent, store: item.store };
              if (
                sObject &&
                (sObject.index || sObject.doc_id) &&
                !sObject.reviewID
              ) {
                sObject["reviewID"] = sObject.index || sObject.doc_id;
              }
              if (
                sObject &&
                sObject.Sentiment_Score !== undefined &&
                !sObject.sentimentScore
              ) {
                sObject["sentimentScore"] = sObject.Sentiment_Score.toString();
              }
              if (sObject && !sObject.content) {
                sObject["content"] = sObject.review || "";
              }

              // Extract unchanging data from index or doc_id
              // Create the attributes required by Aha data
              try {
                const dataArray = sObject["reviewID"].split("##$##");
                if (dataArray && dataArray.length > 1) {
                  // title ##$## referenceId ##$## status ##$## workspaceName ##$## ideaCategory ##$## date
                  if (dataArray[1]) {
                    // For Aha
                    sObject["title"] = dataArray[0] || "";
                  }
                  if (dataArray[1]) {
                    // For Aha
                    sObject["ideaReference"] = dataArray[1] || "";
                    // For Kim Kim
                    sObject["specialist"] = dataArray[1] || "";
                  }
                  if (dataArray[2]) {
                    // For Aha amd github
                    if (sObject.store === "github" || sObject.store === "aha" || sObject.store === "csv") {
                      sObject["status"] = dataArray[2] || "";
                      // For Kim Kim
                      sObject["company"] = dataArray[2] || "";
                      // For Github with PAT
                      sObject["state"] = dataArray[2];
                    } else {
                      sObject["status"] = "N/A";
                    }
                  } else {
                    if (sObject.store === "github") {
                      sObject["status"] = "Open";
                      // For Kim Kim
                      sObject["company"] = "Open";
                      // For Github with PAT
                      sObject["state"] = "Open";
                    } else {
                      sObject["status"] = "N/A";
                    }
                  }
                  if (dataArray[3]) {
                    // For Aha
                    sObject["workspaceName"] = dataArray[3] || "";
                    // For Kim Kim
                    sObject["destination"] = dataArray[3] || "";
                    // For Github with PAT
                    if (isValidURL(dataArray[3])) {
                      sObject["url"] = dataArray[3] || "";
                    }
                  }
                  if (dataArray[4]) {
                    sObject["ideaCategory"] = dataArray[4] || "";
                    // For Amazon
                    if (isValidURL(dataArray[4])) {
                      sObject["url"] = dataArray[4] || "";
                    }
                    // For Github with PAT
                    sObject["repositoryOwner"] = dataArray[4];
                    // For stack-overflow labels
                    try {
                      const tags = JSON.parse(dataArray[4]);
                      if (tags && tags.length) {
                        sObject["tags"] = tags;
                      }
                    } catch (e) {
                      // Error occured during getting the tags of labels
                    }
                  }
                  if (dataArray[5]) {
                    // FOr Github with PAT
                    sObject["repositoryName"] = dataArray[5];
                  }
                  if (dataArray[6]) {
                    // For Github, get the tags
                    try {
                      const tags = JSON.parse(dataArray[6]);
                      if (tags && tags.length) {
                        sObject["tags"] = tags;
                      }
                    } catch (e) {
                      // Error occured during getting the tags
                    }
                    sObject["repositoryName"] = dataArray[5];
                  }
                }
              } catch (e) {
                console.log("split didn't work");
              }
              items.push(sObject);

              // store in key value pair to find the data directly using key
              // use reviewId as key inside the keyItem object
              KeyItem[sObject["reviewID"]] = sObject;
            });

            sentiments.push({
              appID: item.appID,
              store: item.store,
              data: items,
            });
            // use to store in key value pair
            sentimentsObject.push({
              appID: item.appID,
              store: item.store,
              data: KeyItem,
            });
          }
        });
      }

      const existingData = JSON.parse(JSON.stringify(state["sentiments"]));
      state["sentiments"] = setDataByStore(existingData, sentiments[0]);

      // use to store the data in key value pair
      // To fetch the data in less time by directly accessing the key
      const existingDataObject = JSON.parse(
        JSON.stringify(state["sentimentsObject"])
      );

      state["sentimentsObject"] = setDataByStore(
        existingDataObject,
        sentimentsObject[0]
      );
    },
    setSentimentsFetch: (state, action) => {
      state["sentimentFetch"] = action.payload;
    },
    setComplaintCluster: (state, action) => {
      state["complaintCluster"] = action.payload;
    },
    setComplimentCluster: (state, action) => {
      state["complimentCluster"] = action.payload;
    },

    //Note:use
    setSuggestionCluster: (state, action) => {
      state["suggestionCluster"] = action.payload;
      // const existingData = JSON.parse(
      //   JSON.stringify(state["suggestionCluster"])
      // );
      // if (existingData.length === 0) {
      //   state["suggestionCluster"] = action.payload;
      // } else {
      //   let suggestionCluster = existingData.map((ele: any) => {
      //     const matchingPayload = action.payload.find(
      //       (actionPayload: any) => ele.appID === actionPayload.appID
      //     );

      //     if (
      //       matchingPayload &&
      //       ele.data.length < matchingPayload.data.length
      //     ) {
      //       return matchingPayload;
      //     }

      //     return ele;
      //   });

      //   const newItems = action.payload.filter((ele: any) => {
      //     return (
      //       !existingData.find(
      //         (actionPayload: any) => ele.appID === actionPayload.appID
      //       ) &&
      //       !suggestionCluster.find((item: any) => item.appID === ele.appID)
      //     );
      //   });

      //   suggestionCluster = [...suggestionCluster, ...newItems];
      //   console.log(suggestionCluster);
      //   state["suggestionCluster"] = suggestionCluster;
      // }

      // console.log(state["suggestionCluster"]);
    },
    setTempComplaintCluster: (state, action) => {
      const existingData = JSON.parse(
        JSON.stringify(state["tempComplaintCluster"])
      );
      state["tempComplaintCluster"] = setDataByStore(
        existingData,
        action.payload[0]
      );
    },
    setTempComplimentCluster: (state, action) => {
      const existingData = JSON.parse(
        JSON.stringify(state["tempComplimentCluster"])
      );
      state["tempComplimentCluster"] = setDataByStore(
        existingData,
        action.payload[0]
      );
    },
    setTempSuggestionCluster: (state, action) => {
      const existingData = JSON.parse(
        JSON.stringify(state["tempSuggestionCluster"])
      );
      state["tempSuggestionCluster"] = setDataByStore(
        existingData,
        action.payload[0]
      );
    },
    setClusterSentiment: (state, action) => {
      state["clusterSentiment"] = action.payload;
    },
    setTrendingTopics: (state, action) => {
      const existingData = JSON.parse(JSON.stringify(state["trendingTopics"]));
      state["trendingTopics"] = setDataByStore(existingData, action.payload[0]);
    },
    setTrendingSentiment: (state, action) => {
      state["trendingSentiment"] = action.payload;
    },
    setToastData: (state, action) => {
      state["toast"] = action.payload;
    },
    setWidgetData: (state, action) => {
      // console.log(action.payload);
      state["widgetLayout"] = action.payload;
    },
    setWidgetSettings: (state, action) => {
      state["widgetSettings"] = action.payload;
    },
    setDashboardSettings: (state, action) => {
      state["dashboardSettings"] = action.payload;
    },
    setComplaintReviewMapping: (state, action) => {
      state["complaintReviewMapping"] = action.payload;
    },
    setComplimentReviewMapping: (state, action) => {
      state["complimentReviewMapping"] = action.payload;
    },
    setSuggestionReviewMapping: (state, action) => {
      state["suggestionReviewMapping"] = action.payload;
    },

    // remove the unsuccessful apps
    removeUnsuccefulAppFromAll: (state, action) => {
      // stores the data to be remove
      const removeData = action.payload;
      //console.log("the app id is send to delete is :", removeData);

      // extract the appIds from the data to be remove
      const removeAppIds = removeData.map((apps: any) => apps.appId);

      // Remove unsuccessful appIDs
      const apps = JSON.parse(JSON.stringify(state["app"])).filter(
        (item: DATA_BY_STORE) => !removeAppIds.includes(item.appID)
      );
      state["app"] = apps;

      // Remove the store of removed apps
      state["store"] = apps.map((app: any) => app.store);

      // Remove unsuccessful apps from user
      let user = JSON.parse(JSON.stringify(state["user"]));
      state["user"] = {
        ...user,
        userAppId: JSON.stringify(apps.map((app: any) => app.appID)),
      };

      // compare the new and old data for complaints if any changes then add the new data
      // const existingDataforComplaints = JSON.parse(
      //   JSON.stringify(state["complaints"])
      // );
      // const newDataforComplaints = JSON.parse(
      //   JSON.stringify(state["complaints"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["complaints"] = setDataByStore(
      //   existingDataforComplaints,
      //   newDataforComplaints
      // );

      state["complaints"] = JSON.parse(
        JSON.stringify(state["complaints"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for compliments if any changes then add the new data
      // const existingDataforCompliments = JSON.parse(
      //   JSON.stringify(state["compliments"])
      // );
      // const newDataforCompliments = JSON.parse(
      //   JSON.stringify(state["compliments"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["compliments"] = setDataByStore(
      //   existingDataforCompliments,
      //   newDataforCompliments
      // );

      state["compliments"] = JSON.parse(
        JSON.stringify(state["compliments"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for sentiments if any changes then add the new data
      // const existingDataforSentiments = JSON.parse(
      //   JSON.stringify(state["sentiments"])
      // );
      // const newDataforSentiments = JSON.parse(
      //   JSON.stringify(state["sentiments"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["sentiments"] = setDataByStore(
      //   existingDataforSentiments,
      //   newDataforSentiments
      // );

      state["sentiments"] = JSON.parse(
        JSON.stringify(state["sentiments"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for suggestions if any changes then add the new data
      // const existingDataforSuggestions = JSON.parse(
      //   JSON.stringify(state["suggestions"])
      // );
      // const newDataforSuggestions = JSON.parse(
      //   JSON.stringify(state["suggestions"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["suggestions"] = setDataByStore(
      //   existingDataforSuggestions,
      //   newDataforSuggestions
      // );

      state["suggestions"] = JSON.parse(
        JSON.stringify(state["suggestions"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for sentimentFetch if any changes then add the new data
      // const existingDataforSentimentFetch = JSON.parse(
      //   JSON.stringify(state["sentimentFetch"])
      // );
      // const newDataforSentimentFetch = JSON.parse(
      //   JSON.stringify(state["sentimentFetch"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["sentimentFetch"] = setDataByStore(
      //   existingDataforSentimentFetch,
      //   newDataforSentimentFetch
      // );

      state["sentimentFetch"] = JSON.parse(
        JSON.stringify(state["sentimentFetch"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for complaintCluster if any changes then add the new data
      // const existingDataforComplaintCluster = JSON.parse(
      //   JSON.stringify(state["complaintCluster"])
      // );
      // const newDataforComplaintCluster = JSON.parse(
      //   JSON.stringify(state["complaintCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["complaintCluster"] = setDataByStore(
      //   existingDataforComplaintCluster,
      //   newDataforComplaintCluster
      // );

      state["complaintCluster"] = JSON.parse(
        JSON.stringify(state["complaintCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for complimentCluster if any changes then add the new data
      // const existingDataforComplimentCluster = JSON.parse(
      //   JSON.stringify(state["complimentCluster"])
      // );
      // const newDataforComplimentCluster = JSON.parse(
      //   JSON.stringify(state["complimentCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["complimentCluster"] = setDataByStore(
      //   existingDataforComplimentCluster,
      //   newDataforComplimentCluster
      // );

      state["complimentCluster"] = JSON.parse(
        JSON.stringify(state["complimentCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for suggestionCluster if any changes then add the new data
      // const existingDataforSuggestionCluster = JSON.parse(
      //   JSON.stringify(state["suggestionCluster"])
      // );
      // const newDataforSuggestionCluster = JSON.parse(
      //   JSON.stringify(state["suggestionCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["suggestionCluster"] = setDataByStore(
      //   existingDataforSuggestionCluster,
      //   newDataforSuggestionCluster
      // );

      state["suggestionCluster"] = JSON.parse(
        JSON.stringify(state["suggestionCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for tempComplaintCluster if any changes then add the new data
      // const existingDataforTempComplaintCluster = JSON.parse(
      //   JSON.stringify(state["tempComplaintCluster"])
      // );
      // const newDataforTempComplaintCluster = JSON.parse(
      //   JSON.stringify(state["tempComplaintCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["tempComplaintCluster"] = setDataByStore(
      //   existingDataforTempComplaintCluster,
      //   newDataforTempComplaintCluster
      // );

      state["tempComplaintCluster"] = JSON.parse(
        JSON.stringify(state["tempComplaintCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for tempComplimentCluster if any changes then add the new data
      // const existingDataforTempComplimentCluster = JSON.parse(
      //   JSON.stringify(state["tempComplimentCluster"])
      // );
      // const newDataforTempComplimentCluster = JSON.parse(
      //   JSON.stringify(state["tempComplimentCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["tempComplimentCluster"] = setDataByStore(
      //   existingDataforTempComplimentCluster,
      //   newDataforTempComplimentCluster
      // );
      state["tempComplimentCluster"] = JSON.parse(
        JSON.stringify(state["tempComplimentCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for tempSuggestionCluster if any changes then add the new data
      // const existingDataforTempSuggestionCluster = JSON.parse(
      //   JSON.stringify(state["tempSuggestionCluster"])
      // );
      // const newDataforTempSuggestionCluster = JSON.parse(
      //   JSON.stringify(state["tempSuggestionCluster"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["tempSuggestionCluster"] = setDataByStore(
      //   existingDataforTempSuggestionCluster,
      //   newDataforTempSuggestionCluster
      // );
      state["tempSuggestionCluster"] = JSON.parse(
        JSON.stringify(state["tempSuggestionCluster"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));
      // compare the new and old data for clusterSentiment if any changes then add the new data
      // const existingDataforClusterSentiment = JSON.parse(
      //   JSON.stringify(state["clusterSentiment"])
      // );
      // const newDataforClusterSentiment = JSON.parse(
      //   JSON.stringify(state["clusterSentiment"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["clusterSentiment"] = setDataByStore(
      //   existingDataforClusterSentiment,
      //   newDataforClusterSentiment
      // );
      state["clusterSentiment"] = JSON.parse(
        JSON.stringify(state["clusterSentiment"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));
      // compare the new and old data for complaintReviewMapping if any changes then add the new data
      // const existingDataforComplaintReviewMapping = JSON.parse(
      //   JSON.stringify(state["complaintReviewMapping"])
      // );
      // const newDataforComplaintReviewMapping = JSON.parse(
      //   JSON.stringify(state["complaintReviewMapping"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["complaintReviewMapping"] = setDataByStore(
      //   existingDataforComplaintReviewMapping,
      //   newDataforComplaintReviewMapping
      // );

      state["complaintReviewMapping"] = JSON.parse(
        JSON.stringify(state["complaintReviewMapping"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for complimentReviewMapping if any changes then add the new data
      // const existingDataforComplimentReviewMapping = JSON.parse(
      //   JSON.stringify(state["complimentReviewMapping"])
      // );
      // const newDataforComplimentReviewMapping = JSON.parse(
      //   JSON.stringify(state["complimentReviewMapping"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["complimentReviewMapping"] = setDataByStore(
      //   existingDataforComplimentReviewMapping,
      //   newDataforComplimentReviewMapping
      // );

      state["complimentReviewMapping"] = JSON.parse(
        JSON.stringify(state["complimentReviewMapping"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for suggestionReviewMapping if any changes then add the new data
      // const existingDataforSuggestionReviewMapping = JSON.parse(
      //   JSON.stringify(state["suggestionReviewMapping"])
      // );
      // const newDataforSuggestionReviewMapping = JSON.parse(
      //   JSON.stringify(state["suggestionReviewMapping"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["suggestionReviewMapping"] = setDataByStore(
      //   existingDataforSuggestionReviewMapping,
      //   newDataforSuggestionReviewMapping
      // );

      state["suggestionReviewMapping"] = JSON.parse(
        JSON.stringify(state["suggestionReviewMapping"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // compare the new and old data for trendingTopics if any changes then add the new data
      // const existingDataforTrendingTopics = JSON.parse(
      //   JSON.stringify(state["trendingTopics"])
      // );
      // const newDataforTrendingTopics = JSON.parse(
      //   JSON.stringify(state["trendingTopics"])
      // ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));

      // state["trendingTopics"] = setDataByStore(
      //   existingDataforTrendingTopics,
      //   newDataforTrendingTopics
      // );

      state["trendingTopics"] = JSON.parse(
        JSON.stringify(state["trendingTopics"])
      ).filter((item: DATA_BY_STORE) => !removeAppIds.includes(item.appID));
    },

    removeDataSource: (state, action) => {
      const key = action.payload;
      const apps = JSON.parse(JSON.stringify(state["app"])).filter(
        (item: DATA_BY_STORE) => item.appID !== key.appID
      );
      state["app"] = apps;
      let user = JSON.parse(JSON.stringify(state["user"]));
      state["user"] = {
        ...user,
        userAppId: JSON.stringify(apps.map((app: any) => app.appID)),
      };
      state["store"] = apps.map((app: any) => app.store);
      state["complaints"] = JSON.parse(
        JSON.stringify(state["complaints"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["compliments"] = JSON.parse(
        JSON.stringify(state["compliments"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["suggestions"] = JSON.parse(
        JSON.stringify(state["suggestions"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["sentiments"] = JSON.parse(
        JSON.stringify(state["sentiments"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["sentimentFetch"] = JSON.parse(
        JSON.stringify(state["sentimentFetch"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["complaintCluster"] = JSON.parse(
        JSON.stringify(state["complaintCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["complimentCluster"] = JSON.parse(
        JSON.stringify(state["complimentCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["suggestionCluster"] = JSON.parse(
        JSON.stringify(state["suggestionCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["tempComplaintCluster"] = JSON.parse(
        JSON.stringify(state["tempComplaintCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["tempComplimentCluster"] = JSON.parse(
        JSON.stringify(state["tempComplimentCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["tempSuggestionCluster"] = JSON.parse(
        JSON.stringify(state["tempSuggestionCluster"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["clusterSentiment"] = JSON.parse(
        JSON.stringify(state["clusterSentiment"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["trendingTopics"] = JSON.parse(
        JSON.stringify(state["trendingTopics"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["complaintReviewMapping"] = JSON.parse(
        JSON.stringify(state["complaintReviewMapping"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["complimentReviewMapping"] = JSON.parse(
        JSON.stringify(state["complimentReviewMapping"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
      state["suggestionReviewMapping"] = JSON.parse(
        JSON.stringify(state["suggestionReviewMapping"])
      ).filter((item: DATA_BY_STORE) => item.appID !== key.appID);
    },
    setAppFeedback: (state, action) => {
      state["appFeedback"] = action.payload.map((item: AppFeedbackType) => ({
        ...item,
        content: item.feedback,
        date: item.createdAt,
        rating: parseInt(item.rating),
        username: item.user,
        reviewID: item.id,
      }));
    },
    setInit: (state, _) => {
      state["app"] = [];
      state["store"] = [];
      state["reviews"] = {};
      // state["user"] = {};
      state["toast"] = [];
      state["complaints"] = [];
      state["compliments"] = [];
      state["suggestions"] = [];
      state["sentiments"] = [];
      state["sentimentFetch"] = [];
      state["complaintCluster"] = [];
      state["complimentCluster"] = [];
      state["suggestionCluster"] = [];
      state["tempComplaintCluster"] = [];
      state["tempComplimentCluster"] = [];
      state["tempSuggestionCluster"] = [];
      state["clusterSentiment"] = [];
      state["trendingSentiment"] = [];
      state["trendingTopics"] = [];
      state["appFeedback"] = [];
      state["widgetLayout"] = {
        dashboard: {
          name: "Feedback dashboard",
          widgets: [],
        },
        sentiment: {
          name: "Sentiments",
          widgets: [],
        },
        rating: {
          name: "Star ratings",
          widgets: [],
        },
        analytics: {
          name: "Analytics",
          widgets: [],
        },
      };
      state["complaintReviewMapping"] = [];
      state["complimentReviewMapping"] = [];
      state["suggestionReviewMapping"] = [];
    },
  },
});

// this is for dispatch
export const AppActions: any = AppSlice.actions;

// this is for configureStore
export default AppSlice.reducer;
