import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getAppData, getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  getAppDetails,
  getUserAppIDs,
  SETTINGS_PAGE,
  setUserDataAndRole,
  STATUS_FLAG,
} from "../shared/constants";
import IdleImportIcon from "../shared/icons/idle-import.svg";
import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import { AddFromSettingsProps } from "./AddApp";

const IdleImport = ({ location, onBack }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const checkAppData = async (appIds: string[]) => {
      // Check if all app data has success status...
      // If yes, navigate to feedback
      // If not, show the error Modal// Check if all app data has success status...

      if (appIds && appIds.length) {
        let flag: boolean[] = [];
        for (let i = 0; i < appIds.length; i++) {
          const app: any = await getAppData(appIds[i]);
          if (app && app.getApp) {
            if (
              [
                app.getApp.complaintStatus,
                app.getApp.complementStatus,
                app.getApp.suggestionStatus,
                app.getApp.sentimentStatus,
              ].filter((status) => status === STATUS_FLAG.PROCESS_COMPLETE)
                .length === 4
            ) {
              // The app data is succesfully processed
              flag = [...flag, true];
            }
          }
        }
        // Trigger these only when both appIds are succesful
        if (flag.length === appIds.length) {
          await getAppDetails(appData, dispatch);
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        // Getting appID on org level or individual level
        const appId = await getUserAppIDs(res.getUser);
        if (appId && appId.length && appId !== "[]") {
          localStorage.setItem("appId", appId);
          checkAppData(JSON.parse(appId));
        } else {
          navigate("/dashboard/data-source");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId && !location) {
      checkAppData(
        Array.isArray(JSON.parse(appData.user.appUserId))
          ? JSON.parse(appData.user.appUserId)
          : [JSON.parse(appData.user.appUserId)]
      );
    }

    if (checkApp) {
      if (
        (!appData ||
          (appData && !appData.user) ||
          (appData && appData.user && !appData.user.userAppId)) &&
        !location
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  // Use effect to check app import status and redirect user directly
  useEffect(() => {
    const checkAppData = async (appData: any) => {
      // Check if all app data has success status...
      // If yes, navigate to feedback

      const res: any = await getAppDetails(appData, dispatch);
      if (res) {
        if (
          res.length !== 0 &&
          res.filter((val: { store: string; data: any }) => {
            return checkAllStatusFlags(val.data, [7], 4);
          }).length === 0
        ) {
          // The app data is succesfully processed
          // TODO: Only one mail should go to user, hence some uniform logic needs to be present for this.
          // TODO: Check if the date of import is same as current date. If yes, send mail from here.
          // Otherwise wait for betch job to send the mail
          // await sendEmailToUser([localStorage.getItem("username") || ""]);
          navigate("/dashboard/insights/feedback");
          // To reload the page and fetch the newly added app
          if (location && location === SETTINGS_PAGE) {
            window.location &&
              window.location.reload &&
              window.location.reload();
          }
        }
      }
    };

    checkAppData(appData);
    const interval = setInterval(() => {
      checkAppData(appData);
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",

          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Sit back and relax
          </FlexBox>
          <Box
            sx={{
              mt: 4,
              ml: 1,
              mb: 1,
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
              height: "fit-content",
            }}
          >
            Importing the data will take a couple of minutes. We will send you
            an email when we’re done.
          </Box>
          <Box sx={{ width: "100%", textAlign: "right" }}>
            <img
              src={IdleImportIcon}
              alt="Idle import"
              width="364"
              height="191"
            />
          </Box>
          {location && location === SETTINGS_PAGE ? (
            <Box sx={{ mt: 1, justifyContent: "center" }}>
              <Button
                fullWidth
                type="button"
                variant="contained"
                onClick={() => {
                  if (location && onBack) {
                    onBack();
                  } else {
                    navigate(`/dashboard/home`);
                  }
                }}
              >
                Back to settings
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default IdleImport;
