import { COMPLAINTS, COMPLIMENTS, SUGGESTIONS } from "../shared/constants";
import FlexBox from "../shared/wrappers/FlexBox";

type MetricWidgetProps = {
  data: {
    title: string;
    value: string | number;
  };
  index: number;
  subComponent: string | undefined;
  hideRating: boolean;
};

const MetricWidget = ({
  data,
  index,
  subComponent,
  hideRating,
}: MetricWidgetProps) => {
  return (
    <FlexBox
      sx={{
        border: "1px solid #E3E6EB",
        borderRadius: "4px",
        boxSizing: "border-box",
        height: "25%",
        minHeight: "80px",
        padding: "0.8rem 1.2rem",
        flexDirection: "column",
        ...(index === 2
          ? { width: "40%", minWidth: "120px" }
          : { width: "27%", minWidth: "90px" }),
      }}
      key={`${index}--metric-widget-${Math.random()}`}
    >
      <FlexBox
        sx={{
          fontWeight: 500,
          fontSize: "10px",
          lineHeight: "14px",
          color: "#3A3C40",
        }}
      >
        {data.title}
      </FlexBox>
      <FlexBox
        sx={{
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "38px",
          marginTop: "0.2rem",
          width: "100%",
          maxWidth: "fit-content",
          ...(subComponent
            ? subComponent === COMPLIMENTS
              ? { color: "#418F90" }
              : subComponent === COMPLAINTS
              ? { color: "#FF90A3" }
              : subComponent === SUGGESTIONS && { color: "#A798FF" }
            : {}),
        }}
      >
        {!hideRating ? data.value : "--"}
      </FlexBox>
    </FlexBox>
  );
};

export default MetricWidget;
