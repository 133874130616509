import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import { FilterTextPopover } from "../shared/wrappers/FilterPopper";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DATA_BY_STORE,
  distictObjectById,
  FilterOption,
  filterOptions,
  getDataByStore,
  getFilterValues,
  STORE,
} from "../shared/constants";
import WidgetContainer from "./WidgetContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { useDispatch } from "react-redux";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

const StarRatings = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const appData: any = useSelector((state: RootState) => state.app);

  // Cluster & Subcluster Widgets States
  // Subtopic
  const [selectedSubtopic, setSelectedSubtopic] = useState<any>();

  // Selected SubCluster for review
  const [selectedSubcluster, setSelectedSubcluster] = useState<any>();

  const [filteredSentiments, setFilteredSentiments] = useState<any[]>([]);

  // Reviews Array
  const [filteredReviews, setFilteredReviews] = useState<Review[]>([]);

  const filterReviewsHandler = useMemo(
    () => (reviewIds: any[]) => {
      setFilteredReviews(reviewIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Horizontal Sentiment Graph Review Filters
  const [positive, setPositive] = useState(true);
  const [negative, setNegative] = useState(true);
  const [oneStar, setOneStar] = useState(true);
  const [twoStar, setTwoStar] = useState(true);
  const [threeStar, setThreeStar] = useState(true);
  const [fourStar, setFourStar] = useState(true);
  const [fiveStar, setFiveStar] = useState(true);

  const [filteredReviews2, setFilteredReviews2] = useState<any[]>([]);

  const [clusterPoints, setClusterPoints] = useState<ReviewPoint[]>([]);
  const [reviewPoints, setReviewPoints] = useState<ReviewPoint[]>([]);

  // Pagination offset value
  const [itemOffset, setItemOffset] = useState(0);

  // Pagination variables
  const itemsPerPage = 100;

  useEffect(() => {
    if (filteredReviews && filteredReviews.length) {
      let maxOffset = filteredReviews.length / 100;
      if (itemOffset > maxOffset) {
        setItemOffset(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReviews]);

  const defaultStore =
    appData.app && appData.app.length
      ? appData.app.map((app: DATA_BY_STORE) => ({
          store: app.store,
          appID: app.appID,
        }))
      : // .map((s: GET_DATA_STORE) => s.store)
        [];

  const getReviewPointMapping = useCallback(() => {
    let reviewMapping: ReviewPoint[] = [];

    if (selectedSubtopic) {
      if (
        selectedSubtopic.clusterGroup !== "0" &&
        selectedSubtopic.subClusters &&
        selectedSubtopic.subClusters.length
      ) {
        selectedSubtopic.subClusters.forEach((sub: any) => {
          if (sub.reviewMapping && sub.reviewMapping.length) {
            reviewMapping = reviewMapping.concat(sub.reviewMapping);
          }
        });
      }
      if (
        selectedSubtopic.clusterGroup === "0" &&
        selectedSubtopic.clusterTopics
      ) {
        if (
          selectedSubtopic.reviewMapping &&
          selectedSubtopic.reviewMapping.length
        ) {
          reviewMapping = reviewMapping.concat(selectedSubtopic.reviewMapping);
        }
      }
    }

    // console.log(subStrings);
    return reviewMapping;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubtopic]);

  // use effect to determine the cluster points
  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.slack))
    ) {
      let points = getReviewPointMapping();
      let clusteredPoints = distictObjectById(points, "point");
      setClusterPoints(clusteredPoints);

      // Set all the review points in this
      const complaintReviewMapping = getDataByStore(
        appData.complaintReviewMapping,
        defaultStore
      );
      const complimentReviewMapping = getDataByStore(
        appData.complimentReviewMapping,
        defaultStore
      );
      const suggestionReviewMapping = getDataByStore(
        appData.suggestionReviewMapping,
        defaultStore
      );
      if (
        complaintReviewMapping &&
        complimentReviewMapping &&
        suggestionReviewMapping
      ) {
        let reviewIds = clusteredPoints.map(
          (item: ReviewPoint) => item.reviewId
        );
        setReviewPoints(
          [
            ...complaintReviewMapping,
            ...complimentReviewMapping,
            ...suggestionReviewMapping,
          ].filter((item: ReviewPoint) => reviewIds.includes(item.reviewId))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubtopic]);

  useEffect(() => {
    let filteredData: any[] = [...filteredReviews];
    let filCluster: any[] = [];

    // // Filter neutral reviews
    // filteredData = filteredData.filter(
    //   (data) =>
    //     data.sentimentScore &&
    //     (parseFloat(data.sentimentScore) > 0.1 ||
    //       parseFloat(data.sentimentScore) < -0.1)
    // );

    if (!positive) {
      filteredData = filteredData.filter(
        (data) =>
          data.sentimentScore !== undefined &&
          parseFloat(data.sentimentScore) <= 0.1
      );
    }
    if (!negative) {
      filteredData = filteredData.filter(
        (data) =>
          data.sentimentScore !== undefined &&
          parseFloat(data.sentimentScore) >= -0.1
      );
    }
    if (!oneStar) {
      filteredData = filteredData.filter((data) => data.rating !== 1);
    }
    if (!twoStar) {
      filteredData = filteredData.filter((data) => data.rating !== 2);
    }
    if (!threeStar) {
      filteredData = filteredData.filter((data) => data.rating !== 3);
    }
    if (!fourStar) {
      filteredData = filteredData.filter((data) => data.rating !== 4);
    }
    if (!fiveStar) {
      filteredData = filteredData.filter((data) => data.rating !== 5);
    }

    // Global Filter Values are set here
    if (appData.filter) {
      const { expression } = getFilterValues(appData);

      try {
        filteredData = filteredData.filter((rev) => {
          return expression(rev.date);
        });

        const clusterSentiment = getDataByStore(
          appData.clusterSentiment,
          defaultStore
        );
        clusterSentiment.forEach((comp: any, index: number) => {
          let subCluster: any[] = [];
          comp.subClusters.forEach((sub: any) => {
            let subC = sub.sentimentData.filter((sent: any) =>
              expression(sent.date)
            );
            subCluster.push({
              ...sub,
              sentimentData: subC,
            });
          });

          filCluster.push({
            ...comp,
            subClusters: subCluster.filter(
              (clus) => clus.sentimentData.length !== 0
            ),
          });
        });
      } catch (e) {
        //console.log(e);
      }
    }

    // let distinctArray = [
    //   ...new Map(
    //     filteredData.map((item: any) => [item["username"], item])
    //   ).values(),
    // ];
    setFilteredSentiments(
      filCluster.filter((it) => it.subClusters.length !== 0)
    );
    setFilteredReviews2(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneStar,
    twoStar,
    threeStar,
    fourStar,
    fiveStar,
    positive,
    negative,
    appData.filter,
    filteredReviews,
    appData.clusterSentiment,
  ]);

  // Getting the ids when a cluster group is clicked
  useEffect(() => {
    if (
      selectedSubtopic &&
      selectedSubtopic.subClusters &&
      selectedSubtopic.subClusters.length
    ) {
      let reviewObjs: any[] = [];

      selectedSubtopic.subClusters.forEach((sub: any) => {
        reviewObjs = reviewObjs.concat(sub.sentimentData);
      });

      // Obj of the reviews to fetch from sentiment analysis
      filterReviewsHandler(reviewObjs);

      // Initially all of the sentiments will be fetched when the page loads
      // No further fetching needs to be done
      // Later on filter can be done on that list based on user selection
      // That way there will be no lag whatsoever on any filter selection
    } else if (
      selectedSubtopic &&
      selectedSubtopic.clusterGroup === "0" &&
      selectedSubtopic.sentimentData &&
      selectedSubtopic.sentimentData.length
    ) {
      filterReviewsHandler(selectedSubtopic.sentimentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubtopic]);

  useEffect(() => {
    // Setting initial subtopic selection
    if (filteredSentiments && filteredSentiments.length && !selectedSubtopic) {
      const sortedData = [...filteredSentiments].sort(
        (a: any, b: any) => b.rating - a.rating
      );
      setSelectedSubtopic(sortedData[0]);
    }

    // When the filtered sentiments don't have any data but some topic is still selected
    // Or when global filter changes, there is a need to update the subClusters in the selected topic
    if (selectedSubtopic && filteredSentiments && filteredSentiments.length) {
      const findIndex = filteredSentiments.findIndex(
        (item: any) => item.id === selectedSubtopic.id
      );
      if (findIndex === -1) {
        setSelectedSubtopic(undefined);
      } else if (
        filteredSentiments[findIndex].subClusters &&
        filteredSentiments[findIndex].subClusters.length !==
          selectedSubtopic.subClusters.length
      ) {
        setSelectedSubtopic(filteredSentiments[findIndex]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filteredSentiments,
    appData.complaintCluster,
    appData.complimentCluster,
    appData.suggestionCluster,
    appData.trendingTopics,
    appData.filter,
  ]);

  const selectAllHandler = () => {
    setPositive(true);
    setNegative(true);
    setOneStar(true);
    setTwoStar(true);
    setThreeStar(true);
    setFourStar(true);
    setFiveStar(true);
  };

  const clearAllHandler = () => {
    setPositive(false);
    setNegative(false);
    setOneStar(false);
    setTwoStar(false);
    setThreeStar(false);
    setFourStar(false);
    setFiveStar(false);
  };

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        <FlexBox
          sx={{
            width: "90%",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginBottom: "1.5rem",
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                color: "#3579E3",
              }}
            >
              Star Ratings
            </FlexBox>
            <FlexBox>
              <FilterTextPopover
                filterOptions={filterOptions}
                selectedFilter={appData.filter}
                setSelectedFilter={(filter: FilterOption) =>
                  dispatch(AppActions.setGlobalFilter(filter))
                }
              />
            </FlexBox>
          </FlexBox>
          <FlexBox>
            <WidgetContainer
              widgets={
                appData.widgetLayout &&
                appData.widgetLayout.rating &&
                appData.widgetLayout.rating.widgets
                  ? [...appData.widgetLayout.rating.widgets].splice(0, 2)
                  : []
              }
              type={"rating"}
              hideDraggable={true}
              otherData={{}}
            />
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              sx={{
                ...(width > 1300 ? { width: "60%" } : { width: "55%" }),
              }}
            >
              <WidgetContainer
                widgets={
                  appData.widgetLayout &&
                  appData.widgetLayout.rating &&
                  appData.widgetLayout.rating.widgets
                    ? [...appData.widgetLayout.rating.widgets].splice(2, 2)
                    : []
                }
                type={"rating"}
                hideDraggable={true}
                otherData={{
                  filteredSentiments,
                  selectedSubtopic,
                  setSelectedSubtopic,
                  selectedSubcluster,
                  setSelectedSubcluster,
                  filterReviewsHandler,
                }}
              />
            </FlexBox>
            <FlexBox
              sx={{
                ...(width > 1300 ? { width: "39%" } : { width: "45%" }),
                height: "100%",
              }}
            >
              <WidgetContainer
                widgets={
                  appData.widgetLayout &&
                  appData.widgetLayout.rating &&
                  appData.widgetLayout.rating.widgets
                    ? [...appData.widgetLayout.rating.widgets].splice(4, 1)
                    : []
                }
                type={"rating"}
                hideDraggable={true}
                otherData={{
                  filteredReviews,
                  positive,
                  setPositive,
                  negative,
                  setNegative,
                  oneStar,
                  setOneStar,
                  twoStar,
                  setTwoStar,
                  threeStar,
                  setThreeStar,
                  fourStar,
                  setFourStar,
                  fiveStar,
                  setFiveStar,
                  selectAll: selectAllHandler,
                  clearAll: clearAllHandler,
                  filteredReviews2,
                  setFilteredReviews,
                  reviewPoints,
                  clusterPoints,
                  itemOffset,
                  itemsPerPage,
                  setItemOffset,
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default StarRatings;
