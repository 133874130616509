import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "./Toast";
import { RootState } from "../../../services/store/store";
import FlexBox from "./FlexBox";
import { AppActions } from "../../../services/store/AppStore/AppStoreSlice";

const ToastContainer = () => {
  const BLOCK = "toast";
  const dispatch = useDispatch();
  const commons: any = useSelector((state: RootState) => state.app);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    setList(commons.toast);

    // eslint-disable-next-line
  }, [commons.toast]);

  const deleteToast = (listItemIndex: number) => {
    dispatch(
      AppActions.setToastData(
        commons.toast.filter((_: any, index: number) => index !== listItemIndex)
      )
    );
  };

  return (
    <>
      <FlexBox
        sx={{
          boxSizing: "border-box",
          position: "fixed",
          top: "2rem",
          alignItems: "center",
          zIndex: 999999,
          fontSize: "0.875rem",
          flexDirection: "column",
        }}
      >
        {list.map((toast, i) => (
          <Toast
            BLOCK={BLOCK}
            toast={toast}
            index={i}
            deleteToast={deleteToast}
            key={i}
          />
        ))}
      </FlexBox>
    </>
  );
};

export default ToastContainer;
