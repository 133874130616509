import FlexBox from "../shared/wrappers/FlexBox";
import { useEffect, useState } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { SettingsProps } from "./Settings";
import { FormattedCheckbox } from "../shared/wrappers/FilterPopper";
import { getUserRights, updateUserDetailsInRedux } from "../shared/constants";
import { useDispatch } from "react-redux";

const UserSettings = ({ initValues }: SettingsProps) => {
  const dispatch = useDispatch();
  const [syncDashboard, setSyncDashboard] = useState(false);

  useEffect(() => {
    // Fetch the sync dashboard value for the user
    const userRights = getUserRights(initValues.user);

    if (!!userRights.syncDashboard) {
      setSyncDashboard(!!userRights.syncDashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncDashboardHandler = async () => {
    const newValue = !syncDashboard;
    setSyncDashboard(newValue);

    // Set the new value for the dashboard field for the user
    let userRights: any = {};
    // Get the current userRights property and add to it
    if (initValues.user && initValues.user.userRights) {
      try {
        userRights = { ...JSON.parse(initValues.user.userRights) };
      } catch (e) {
        userRights = { ...initValues.user.userRights };
      }
    }
    userRights["syncDashboard"] = newValue;

    // Update data in UI redux as well as backend db
    await updateUserDetailsInRedux(initValues.user, userRights, dispatch);
  };

  const handleChange = (event: any) => {
    if (event && event.target && event.target.name) {
      switch (event.target.name) {
        case "syncDashboard":
          syncDashboardHandler();
          return;
        default:
          console.log("Error option");
          return;
      }
    }
  };

  return (
    <FlexBox
      sx={{
        marginTop: "0.75rem",
        width: "100%",
        height: "100%",
      }}
    >
      <FullTextFieldWrapper key={`checkbox-organisation-setting`}>
        <FlexBox
          sx={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            paddingLeft: "0.5rem",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "center",
            }}
          >
            <FlexBox>{"Sync with Organisation Dashboard?"}</FlexBox>
          </FlexBox>
          <FlexBox>
            <FormattedCheckbox
              checked={syncDashboard}
              value={syncDashboard}
              name={"syncDashboard"}
              onChange={handleChange}
            />
          </FlexBox>
        </FlexBox>
      </FullTextFieldWrapper>
    </FlexBox>
  );
};

export default UserSettings;
