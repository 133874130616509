import { Box, Button, FormHelperText, MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormContext, FormContextAttr } from "./AddSlack";
import FlexBox from "../shared/wrappers/FlexBox";
import { LoadingButton } from "@mui/lab";
import { Small } from "../shared/wrappers/Typography";
import { Filter } from "../shared/wrappers/FilterPopper";
import {
  ADMIN_MANAGEMENT_TYPE,
  getWithExpiry,
  SETTINGS_PAGE,
} from "../shared/constants";
import {
  createOrEditOrganization,
  startSlackDataImport,
} from "../../services/api/Apps.api";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import LightTextField from "../shared/wrappers/LightTextField";

export type Product = {
  created_at: string;
  id: string;
  name: string;
  product_line: boolean;
  reference_prefix: string;
  workspace_type: string;
};

type Attrs = {
  key: string;
  help: string;
  required: boolean;
  multiple?: boolean;
  default?: string;
  options?: {
    label: string;
    value: string;
  }[];
};

export const PREDEFINED_ATTRS: Attrs[] = [
  {
    key: "channel",
    help: "Select the channel you wish the import the data from",
    required: true,
    multiple: true,
  },
  {
    key: "bot",
    help: "Do you wish to include bot messages?",
    required: true,
    default: "no",
    options: [
      {
        label: "yes",
        value: "yes",
      },
      {
        label: "no",
        value: "no",
      },
    ],
  },
  {
    key: "thread",
    help: "Do you wish to include messages in a thread?",
    required: true,
    default: "yes",
    options: [
      {
        label: "yes",
        value: "yes",
      },
      {
        label: "no",
        value: "no",
      },
    ],
  },
];

const createObject = (arr: Attrs[]) => {
  let obj: any = {};

  arr.forEach((item: Attrs) => {
    if (item.multiple) {
      obj[item.key] = [];
    } else obj[item.key] = "";

    if (item.default) {
      obj[item.key] = item.default;
    }
  });

  return obj;
};

const SelectChannel = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    channels,
  } = useContext(FormContext) as FormContextAttr;
  const location = useLocation();

  const appData: any = useSelector((state: RootState) => state.app);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues = {
    ...createObject(PREDEFINED_ATTRS),
    ...formData,
    name: "",
  };

  const createValidationSchema = (arr: Attrs[]) => {
    //products: Yup.array().required("Please select any one workspace"),
    let obj: any = {
      name: Yup.string().required("App name is required"),
    };
    arr.forEach((item: Attrs) => {
      if (item.required) {
        if (item.multiple) {
          obj[item.key] = Yup.array().required(`${item.key} is required`);
        } else obj[item.key] = Yup.string().required(`${item.key} is required`);
      } else {
        if (item.multiple) {
          obj[item.key] = Yup.array();
        } else obj[item.key] = Yup.string();
      }
    });

    return obj;
  };

  const validationSchema = Yup.object().shape(
    createValidationSchema(PREDEFINED_ATTRS)
  );

  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        if (values.channel.length !== 0) {
          const data = {
            ...formData,
            ...values,
          };

          try {
            const res = await startSlackDataImport({
              user: localStorage.getItem("username") || "",
              token: formData.token,
              accessToken: getWithExpiry("token"),
              channelId: values.channel,
              includeBot: values.bot,
              includeThread: values.thread,
              name: values.name,
            });

            if (res && res.body) {
              const response = JSON.parse(res.body);
              if (response && response.appID) {
                // Add previous appIDs as well if exists
                let appIDs = [];
                if (appData.user.userAppId) {
                  try {
                    appIDs = JSON.parse(appData.user.userAppId);
                  } catch (e) {
                    console.log(e);
                    appIDs = appData.user.userAppId;
                  }
                }
                if (!Array.isArray(appIDs)) {
                  appIDs = [appIDs];
                }

                appIDs = appIDs.concat([response.appID]);

                localStorage.setItem("appId", JSON.stringify(appIDs));

                try {
                  await createOrEditOrganization({
                    admin: localStorage.getItem("username") || "",
                    appID: JSON.stringify(appIDs),
                    organization: appData?.userRole?.organization || "",
                    type: ADMIN_MANAGEMENT_TYPE.edit,
                  });
                } catch (e) {
                  console.log(e);
                }

                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
              }
            }
          } catch (e) {
            setError("There was some error");
          }
        } else {
          setError("Error");
        }
        setLoading(false);
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Select slack channel
      </FlexBox>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "30px",
        }}
      >
        Please select the channel you wish to import the data from.
      </Box>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              maxHeight: "15rem",
              overflowY: "auto",
              overflowX: "none",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            {/* {attributes.map((filter: any, index: number) => {
            return ( */}
            {channels && channels.length !== 0 ? (
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <FullTextFieldWrapper
                  sx={{
                    padding: "0.5rem 1rem",
                  }}
                >
                  <FlexBox
                    sx={{
                      marginBottom: "0.4rem",
                    }}
                  >
                    {`${"* App name".toUpperCase()}: ${"This is the name that'll be assigned to the imported slack messages"}`}
                  </FlexBox>
                  <LightTextField
                    fullWidth
                    name={"name"}
                    type={"name"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name || ""}
                    error={Boolean(touched.name && errors.name)}
                  />
                </FullTextFieldWrapper>
                {PREDEFINED_ATTRS.map((attr: Attrs, index: number) => (
                  <FullTextFieldWrapper key={`checkbox-product-name-${index}`}>
                    <FlexBox
                      sx={{
                        // justifyContent: "space-between",
                        width: "100%",
                        // alignItems: "center",
                        padding: "0.5rem 1rem",
                        flexDirection: "column",
                      }}
                    >
                      <FlexBox
                        sx={
                          {
                            // justifyContent: "center",
                          }
                        }
                      >
                        <FlexBox>
                          {attr.required ? "* " : ""}
                          {`${attr.key.toUpperCase()}: ${attr.help}`}
                        </FlexBox>
                      </FlexBox>
                      <FlexBox
                        sx={{
                          width: "100%",
                          marginTop: "0.4rem",
                        }}
                      >
                        <Select
                          fullWidth
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name={attr.key}
                          multiple={!!attr.multiple}
                          value={values[attr.key]}
                          label=""
                          onChange={handleChange}
                          sx={{
                            borderRadius: "8px",
                            height: "2.6rem",
                          }}
                          MenuProps={{
                            sx: {
                              height: "18rem",
                              // maxHeight: "15rem",
                              // overflowY: "auto",
                              "&::-webkit-scrollbar": {
                                width: "0.4rem",
                              },
                              "&::-webkit-scrollbar-track": {
                                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                                webkitBoxShadow:
                                  "inset 0 0 6px rgba(0,0,0,0.00)",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#A3A6AB",
                                borderRadius: "5px",
                              },
                            },
                          }}
                        >
                          {attr.options && attr.options.length
                            ? attr.options.map(
                                (item: Filter, index: number) => (
                                  <MenuItem
                                    value={item.value}
                                    key={`${item.value}--menu-item-${index}`}
                                  >
                                    <em>{item.label}</em>
                                  </MenuItem>
                                )
                              )
                            : channels.map((item: any, index: number) => (
                                <MenuItem
                                  value={item.id}
                                  key={`${item.id}--menu-item-${index}`}
                                >
                                  <em>{item.name}</em>
                                </MenuItem>
                              ))}
                        </Select>
                      </FlexBox>
                    </FlexBox>
                  </FullTextFieldWrapper>
                ))}
              </FlexBox>
            ) : (
              <></>
            )}
            {/* );
          })}*/}
          </FlexBox>
          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          {location &&
          location.pathname &&
          !location.pathname.includes(SETTINGS_PAGE) ? (
            <Box
              sx={{
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Small
                color="primary.main"
                sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
                onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              >
                Back
              </Small>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default SelectChannel;
