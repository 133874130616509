import { Link } from "react-router-dom";
import FlexBox from "../shared/wrappers/FlexBox";

type WidgetLinkProps = {
  linkTo: string;
  linkText: string;
  state?: any;
};

const WidgetLink = ({ linkTo, linkText, state }: WidgetLinkProps) => {
  return (
    <FlexBox
      sx={{
        position: "absolute",
        bottom: "1rem",
        right: "1.5rem",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#1C5FCA",
      }}
    >
      <Link
        to={{
          pathname: linkTo,
        }}
        // Send a state to the destination page
        state={{ ...(state ? { ...state } : {}) }}
        style={{ color: "#1C5FCA" }}
      >
        {linkText}
      </Link>
    </FlexBox>
  );
};

export default WidgetLink;
