import { useEffect } from "react";
import CrossIcon from "../icons/white-cross.svg";
import FlexBox from "./FlexBox";

type Props = {
  BLOCK: string;
  toast: any;
  index: number;
  deleteToast: (listItemIndex: number) => void;
};
const Toast = ({ BLOCK, toast, index, deleteToast }: Props) => {
  console.log("Show toast", toast);
  useEffect(() => {
    setTimeout(() => {
      deleteToast(0);
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <FlexBox
      key={index}
      sx={{
        transition: "0.3s ease",
        pointerEvents: "auto",
        overflow: "hidden",
        marginBottom: "1rem",
        borderRadius: "3px 3px 3px 3px",
        boxShadow: "0 0 10px #999",
        color: "white",
        opacity: "0.9",
        padding: "1rem",
        backgroundColor: "black",
        "&:hover": {
          boxShadow: "0 0 12px $white",
          opacity: 1,
          cursor: "pointer",
        },
      }}
    >
      <FlexBox>
        <FlexBox
          sx={{
            margin: "0",
            marginRight: "2rem",
            textAlign: "left",
            marginLeft: "-1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "40rem",
          }}
        >
          {toast.description}
        </FlexBox>
      </FlexBox>
      <button
        style={{
          marginLeft: "1rem",
          fill: "black",
        }}
        onClick={() => deleteToast(index)}
      >
        <img src={CrossIcon} width={20} height={20} alt="Cross" />
      </button>
    </FlexBox>
  );
};

export default Toast;
