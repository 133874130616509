import { Box, Button, FormHelperText } from "@mui/material";
import { useState, useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr } from "./AddGithub";
import FlexBox from "../shared/wrappers/FlexBox";
import {
  startGithubIssuesImport,
  createOrEditOrganization,
} from "../../services/api/Apps.api";
import { getWithExpiry, ADMIN_MANAGEMENT_TYPE } from "../shared/constants";
import { RootState } from "../../services/store/store";
import { useSelector } from "react-redux";

const Config = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext) as FormContextAttr;

  const appData: any = useSelector((state: RootState) => state.app);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    name: string;
    url: string;
  } = { name: "", url: "", ...formData };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("App name is required"),
    url: Yup.string().required("Repository URL is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        setError("");
        try {
          if (values.name !== "" && values.url !== "") {
            const data = { ...formData, ...values };
            // There's a need to parse the url so that it has the correct structure. Create the logic to do that here
            let url = new URL(values.url);

            if (
              url.protocol &&
              url.protocol === "https:" &&
              url.hostname &&
              url.hostname.includes("github") &&
              url.pathname
            ) {
              const paths = url.pathname
                .split("/")
                .filter((item: string) => item !== "");
              let repositoryUrl =
                url.protocol +
                "//" +
                url.hostname +
                "/" +
                paths[0] +
                "/" +
                paths[1] +
                "/" +
                "issues";

              try {
                const githubConfig = {
                  user: localStorage.getItem("username") || "",
                  token: "without_pat",
                  accessToken: getWithExpiry("token"),
                  appName: values.name,
                  repositories: repositoryUrl,
                  type: "without_pat",
                };

                const res = await startGithubIssuesImport(githubConfig);

                if (res && res.body) {
                  const response = JSON.parse(res.body);
                  if (response && response.appID) {
                    // Add previous appIDs as well if exists
                    let appIDs = [];
                    if (appData.user.userAppId) {
                      try {
                        appIDs = JSON.parse(appData.user.userAppId);
                      } catch (e) {
                        console.log(e);
                        appIDs = appData.user.userAppId;
                      }
                    }
                    if (!Array.isArray(appIDs)) {
                      appIDs = [appIDs];
                    }

                    appIDs = appIDs.concat([response.appID]);

                    localStorage.setItem("appId", JSON.stringify(appIDs));

                    try {
                      await createOrEditOrganization({
                        admin: localStorage.getItem("username") || "",
                        appID: JSON.stringify(appIDs),
                        organization: appData?.userRole?.organization || "",
                        type: ADMIN_MANAGEMENT_TYPE.edit,
                      });
                    } catch (e) {
                      console.log(e);
                    }

                    setFormData(data);
                    setActiveStepIndex(activeStepIndex + 1);
                  } else if (response && response.message) {
                    setError(response.message);
                  }
                }
              } catch (e: any) {
                setError(e.message);
              }
            }
          }
        } catch (e: any) {
          setError(e.message);
        }

        setLoading(false);
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Provide config details
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"App Name"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"name"}
              type={"name"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <Small>
              This is the name that'll be assigned to the imported github issues
            </Small>
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Repository URL"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"url"}
              type={"url"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.url || ""}
              error={Boolean(touched.url && errors.url)}
              helperText={touched.url && errors.url}
            />
            <Small>
              Please provide the url to the github repository where you wish to
              import the issues from, e.g.{" "}
              <i>https://github.com/owner-name/project-name</i>
            </Small>
          </FullTextFieldWrapper>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching repositories"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Config;
