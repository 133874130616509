import { createSlice } from '@reduxjs/toolkit';

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: [],
  reducers: {
  }
});

// this is for dispatch
export const AuthActions = AuthSlice.actions;

// this is for configureStore
export default AuthSlice.reducer;