import { useContext } from "react";
import { FormContext, FormContextAttr } from "./AdminBoard";
import FlexBox from "../shared/wrappers/FlexBox";

type CustomizedSteppersProps = {
  steps: string[];
  activeStepIndex: number;
};

const AdminSteppers = ({ steps }: CustomizedSteppersProps) => {
  const { activeStepIndex, setActiveStepIndex } = useContext(
    FormContext
  ) as FormContextAttr;
  return (
    <FlexBox sx={{ width: "100%", flexDirection: "row", display: "flex" }}>
      {steps.map((item: string, index: number) => (
        <FlexBox
          sx={{
            ...(activeStepIndex === index
              ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
              : { backgroundColor: "white", color: "#82868C" }),
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            minHeight: "32px",
            height: "fit-content",
            padding: "10px 15px",
            borderRadius: "4px",
          }}
          onClick={() => setActiveStepIndex(index)}
          key={`admin-stepper-${index}-${item}`}
        >
          {item}
        </FlexBox>
      ))}
    </FlexBox>
  );
};

export default AdminSteppers;
