import {
  Box,
  Button,
  MenuItem,
  Popover,
  styled,
  Checkbox,
  CircularProgress,
  TextField,
} from "@mui/material";
import { H6 } from "./Typography";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import FlexBox from "./FlexBox";
import ArrowIcon from "../icons/arrow.svg";
import ArrowGreyIcon from "../icons/arrow-grey.svg";
import useWindowDimensions from "../../../services/hooks/useWindowDimensions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WhiteCrossIcon from "../icons/white-cross.svg";
import SearchIcon from "../icons/search.svg";
import TooltipComponent from "./Tooltip";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "./Custom-Calender.css";
import { FilterOption } from "../constants";
import DragSettingIcon from "../icons/drag-setting.svg";

// custom styled components
const IconWrapper = styled(Box)(() => ({
  display: "flex",
  height: "fitContent",
  minWidth: "8rem",
  width: "fit-content",
}));

const SettingWrapper = styled(Box)(() => ({
  display: "flex",
  height: "fitContent",
  width: "fit-content",
  cursor: "pointer",
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  "& img": { width: "100%" },
}));

export const FormattedCheckbox = styled(Checkbox)(() => ({
  root: {
    color: "#E3E6EB",
    borderColor: "#E3E6EB",
    backgroundColor: "#E3E6EB",
    "&:not($checked) .MuiIconButton-label:after": {
      backgroundColor: "#E3E6EB",
      fill: "#E3E6EB",
      color: "#E3E6EB",
      accentColor: "#E3E6EB",
    },
    "&:checked:before": {
      backgroundColor: "#E3E6EB",
      fill: "#E3E6EB",
      color: "#E3E6EB",
      accentColor: "#E3E6EB",
    },
    "&:disabled": {
      backgroundColor: "black",
      fill: "black",
      color: "black",
      accentColor: "black",
    },
  },
  checked: {},
  disabled: {
    "&:unchecked:before": {
      backgroundColor: "black",
      fill: "black",
      color: "black",
      accentColor: "black",
    },
  },
}));

export const getDateLabel = (value: any[]) => {
  let dateStr = "";

  if (
    value &&
    value.length === 2 &&
    value[0] &&
    value[0].toDate &&
    value[1] &&
    value[1].toDate
  ) {
    if (
      value[0].month.number === value[1].month.number &&
      value[0].year === value[1].year
    ) {
      dateStr = `${value[0].month.shortName} ${value[0].day}-${value[1].day}, ${value[1].year}`;
    } else if (value[0].year === value[1].year) {
      dateStr = `${value[0].month.shortName} ${value[0].day}-${value[1].month.shortName} ${value[1].day}, ${value[1].year}`;
    } else {
      dateStr = `${value[0].month.shortName} ${value[0].day}, ${value[0].year}-${value[1].month.shortName} ${value[1].day}, ${value[1].year}`;
    }
  }

  return dateStr;
};

type FilterPopperProps = {
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  setSelectedFilter: (value: FilterOption) => void;
  showCustom?: boolean;
  type?: string;
};

const FilterPopover = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
}: FilterPopperProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (filter: { label: string; value: string }) => {
    setSelectedFilter(filter);
    setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper onClick={handleOpen} ref={anchorRef}>
        <Button
          fullWidth
          type="button"
          variant="contained"
          sx={{
            height: "fitContent",
            borderRadius: "8px",
            padding: "0.5rem 0.3rem",
          }}
        >
          {selectedFilter.label}
          <FlexBox
            sx={{
              marginLeft: "4px",
              ...(open ? {} : { transform: "rotate(180deg)" }),
            }}
          >
            <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
          </FlexBox>
        </Button>
      </IconWrapper>

      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{ sx: { width: 120, padding: "0.5rem 0" } }}
        sx={{
          position: "absolute",
          left: "-3.2%",
          top: "10px",
        }}
      >
        {filterOptions.map((filter: { label: string; value: string }) => (
          <MenuItem
            key={filter.label}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper>
              <H6 fontWeight={600} ml={1}>
                {filter.label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

export const FilterTextPopover = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  type,
}: FilterPopperProps) => {
  const { width } = useWindowDimensions();
  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);

  const [value, onChange] = useState<any>([
    // [new DateObject().subtract(365, "days"), new DateObject().add(0, "days")],
    selectedFilter.value === "custom"
      ? selectedFilter.dates
      : [
          new DateObject().subtract(365, "days"),
          new DateObject().add(0, "days"),
        ],
  ]);
  const datePickerRef: any = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (filter: FilterOption) => {
    setSelectedFilter(filter);
    setOpen(false);
  };

  // const dateRegex = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
  const getDateString = (value: any | undefined) => {
    let dateStr = "__.__.____";

    if (value && value.toDate) {
      dateStr = `${value.month.number.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}.${value.day.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}.${value.year.toString().slice(2, 4)}`;
    }
    return dateStr;
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
        zIndex: 9999999,
      }}
    >
      <Box
        sx={{
          ...(type && type === "widget"
            ? { transform: "translate(7rem, 3rem)" }
            : {}),
        }}
      >
        <DatePicker
          ref={datePickerRef}
          value={value}
          onChange={onChange}
          numberOfMonths={width && width < 980 ? 1 : 2}
          range
          rangeHover
          // animations={[opacity()]}
          arrow={false}
          offsetX={50}
          render={<></>}
          className="custom-calendar"
          // fixMainPosition={true}
          // fixRelativePosition={true}
          // calendarPosition={`bottom`}
          style={{
            width: "100%",
            boxSizing: "border-box",
            height: "26px",
            // transform: "translate(-250px)",
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                background: "#E3E6EB",
                border: "1px solid #E3E6EB",
                borderRadius: "3px",
              }}
            ></FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
                height: "4rem",
                padding: "1rem",
                fontSize: "13px",
              }}
            >
              <FlexBox
                sx={{
                  background: "rgba(245, 245, 247, 0.4)",
                  border: "1px solid #BABFC5",
                  borderRadius: "8px",
                  width: "max-content",
                  padding: "0.3rem 0.6rem",
                }}
              >
                <FlexBox>{getDateString(value[0])}</FlexBox>
                <FlexBox sx={{ margin: "0 0.5rem" }}>{"-"}</FlexBox>
                <FlexBox>{getDateString(value[1])}</FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  // width: "30%",
                  justifyContent: "space-between",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#82868C",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "0.8rem",
                    marginRight: "0.8rem",
                  }}
                  onClick={() => {
                    onChange([]);
                    datePickerRef.current!.closeCalendar!();
                    // setOpen(true);
                  }}
                >
                  {"Cancel"}
                </FlexBox>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    if (value && value.length === 2) {
                      datePickerRef.current!.closeCalendar!();
                      handleChangeLanguage({
                        label: "Custom",
                        value: "custom",
                        dates: value,
                      });
                    }
                  }}
                >
                  {"Apply"}
                </Button>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </DatePicker>
      </Box>
      <IconWrapper
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          width: "100%",
        }}
      >
        {type && type === "widget" ? (
          <FlexBox
            sx={{
              // justifyContent: "space-between",
              width: "100%",
              // alignItems: "center",
              padding: "0.5rem 0",
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                // justifyContent: "center",
                width: "100%",
                marginBottom: "0.3rem",
              }}
            >
              <FlexBox
                sx={{
                  color: "#3A3C40",
                  lineHeight: "17px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {"Date range"}
              </FlexBox>
            </FlexBox>
            <FlexBox
              sx={{
                borderRadius: "8px",
                border: "2px solid #E5EAF2",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
                cursor: "pointer",
                "&:hover": {
                  border: "2px solid black",
                },
                ...(open
                  ? {
                      border: "2px solid #2499EF",
                    }
                  : {}),
              }}
            >
              <FlexBox
                sx={{
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
              >
                {" "}
                {selectedFilter.value === "custom"
                  ? getDateLabel(selectedFilter.dates)
                  : selectedFilter.label}
              </FlexBox>
              <FlexBox
                sx={{
                  marginLeft: "4px",
                  cursor: "pointer",
                  ...(!open ? {} : { transform: "rotate(180deg)" }),
                }}
              >
                <img
                  src={ArrowGreyIcon}
                  alt="arrow"
                  width="16px"
                  height="16px"
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ) : (
          <FlexBox
            sx={{
              height: "fitContent",
              borderRadius: "8px",
              padding: "0.5rem 0.3rem",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "16px",
            }}
          >
            <FlexBox
              sx={{
                color: "#82868C",
              }}
            >
              {"Date range"}
            </FlexBox>

            <FlexBox
              sx={{
                color: "#3579E3",
                marginLeft: "0.4rem",
                cursor: "pointer",
              }}
            >
              {selectedFilter.value === "custom"
                ? getDateLabel(selectedFilter.dates)
                : selectedFilter.label}
            </FlexBox>

            <FlexBox
              sx={{
                marginLeft: "4px",
                cursor: "pointer",
                ...(!open ? {} : { transform: "rotate(180deg)" }),
              }}
            >
              <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
            </FlexBox>
          </FlexBox>
        )}
      </IconWrapper>
      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            ...(type && type === "widget"
              ? {
                  width:
                    anchorRef.current && anchorRef.current.clientWidth
                      ? anchorRef.current.clientWidth
                      : 300,
                  padding: "0.5rem 0",
                }
              : { width: 120, padding: "0.5rem 0" }),
          },
        }}
        sx={{
          position: "absolute",
          left: "-3.2%",
          top: "10px",
          zIndex: 9999999,
          ...(type && type === "widget"
            ? width < 550
              ? {
                  transform: "translateX(-6%)",
                }
              : width < 800
              ? {
                  transform: "translateX(-4%)",
                }
              : {
                  transform: "translateX(-17%)",
                }
            : {}),
        }}
      >
        {filterOptions.map((filter: { label: string; value: string }) => {
          if (filter.value !== "custom") {
            return (
              <MenuItem
                key={filter.label}
                onClick={() => handleChangeLanguage(filter)}
              >
                <ItemWrapper>
                  <H6 fontWeight={600} ml={1}>
                    {filter.label}
                  </H6>
                </ItemWrapper>
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={filter.label}
              onClick={() => {
                // setShowDatepicker(!showDatepicker);
                datePickerRef.current.openCalendar();
                setOpen(false);
              }}
            >
              <ItemWrapper>
                <H6 fontWeight={600} ml={1}>
                  {filter.label}
                </H6>
              </ItemWrapper>
            </MenuItem>
          );
        })}
      </Popover>
    </FlexBox>
  );
};

type TextFilterProps = {
  selectedFilter: any;
  setSelectedFilter: (values: any) => void;
  filterOptions: Filter[];
};

export const TextFilterPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
}: TextFilterProps) => {
  const { width } = useWindowDimensions();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (filter: { label: string; value: string }) => {
    setSelectedFilter(filter);
    setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper onClick={handleOpen} ref={anchorRef}>
        <FlexBox
          sx={{
            fontWeight: 600,
            fontSize: "13px",
            lineHeight: "16px",
            color: "#82868C",
            cursor: "pointer",
            marginLeft: "12px",
            ...(width < 1100 ? { width: "6rem" } : ""),
          }}
        >
          {"Sort by " + selectedFilter.label}
          <FlexBox
            sx={{
              marginLeft: "4px",
              ...(open ? { transform: "rotate(180deg)" } : {}),
            }}
          >
            <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
          </FlexBox>
        </FlexBox>
      </IconWrapper>

      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{ sx: { width: 120, padding: "0.5rem 0" } }}
        sx={{
          position: "absolute",
          left: "-4.1%",
          top: "10px",
        }}
      >
        {filterOptions.map((filter: { label: string; value: string }) => (
          <MenuItem
            key={filter.label}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper sx={{ width: "max-content" }}>
              <H6 fontWeight={600} ml={1} sx={{ width: "max-content" }}>
                {filter.label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

type CheckboxFilterProps = {
  selectedFilter: Filter[];
  setSelectedFilter: (values: Filter[]) => void;
  filterOptions: Filter[];
  openP?: boolean;
  setOpenP?: (value: boolean) => void;
  width?: string;
  label?: ReactElement;
  disableSelectAll?: boolean;
  removeFilter?: () => void;
  enableSearch?: (keyword: string) => void;
  internalSearch?: boolean;
  disableCheckbox?: boolean;
  enableMultiselect?: boolean;
  handleFilter?: (type: string) => void;
  popOffset?: number;
  children?: ReactNode;
};

export type Filter = {
  label: string;
  value: string;
  id?: string;
  icon?: any;
  source?: string;
  disabled?: boolean;
  color?: string;
  group?: string;
  element?: boolean;
};

function longest_str_in_array(arra: string[]) {
  if (arra && arra.length !== 0 && arra[0]) {
    var max_str = arra[0].length;
    var ans = arra[0];
    for (var i = 1; i < arra.length; i++) {
      var maxi = arra[i].length;
      if (maxi > max_str) {
        ans = arra[i];
        max_str = maxi;
      }
    }
    return ans;
  }
  return "";
}

export const WidgetSettingsPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  disableSelectAll,
  handleFilter,
  openP,
  setOpenP,
  popOffset,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  const handleOpen = () => {
    if (setOpenP) {
      setOpenP(true);
    } else setOpen(true);
  };
  const handleClose = () => {
    setSelectedFilter(selectedFilter);
    if (setOpenP) {
      setOpenP(false);
    } else setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selectedFilter.length) {
        setSelectedFilter([]);
      } else setSelectedFilter(filterOptions);
      return;
    }

    let currentFilters = [...selectedFilter];
    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelectedFilter([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelectedFilter(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <SettingWrapper
        sx={{
          borderRadius: "8px",
        }}
      >
        <div onClick={handleOpen} ref={anchorRef}>
          <img
            src={DragSettingIcon}
            alt={"setting"}
            width={"16px"}
            height={"16px"}
          />
        </div>
      </SettingWrapper>

      <Popover
        keepMounted
        open={openP !== undefined ? openP : open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "25rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `${
            width
              ? `-${
                  parseInt(width.replace("rem", "")) -
                  (popOffset ? popOffset : 4)
                }rem`
              : `-${size * 8 + offset - (popOffset ? popOffset : 70)}px`
          }`,
          top: "7px",
        }}
      >
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ].map((filter: Filter) => (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={filter.label}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {filter.icon && (
                    <FlexBox
                      sx={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                    >
                      <LazyLoadImage
                        alt={"app"}
                        src={filter.icon}
                        width={"20"}
                        effect="blur"
                        // placeholder={<CircularProgress />}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    sx={{
                      ...((filter.value === "selectAll" &&
                        selectedFilter.length === filterOptions.length) ||
                      selectedFilter.findIndex(
                        (fil: Filter) => fil.value === filter.value
                      ) !== -1
                        ? { color: "#3579E3" }
                        : { color: "#82868C" }),
                      ...(filter.color ? { color: filter.color } : {}),
                    }}
                  >
                    {filter.label}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

export const CheckboxFilterPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  label,
  disableSelectAll,
  removeFilter,
  enableSearch,
  handleFilter,
  openP,
  setOpenP,
  popOffset,
  children,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [selFilters, setSelFilters] = useState(selectedFilter);

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  useEffect(() => {
    if (selectedFilter) {
      setSelFilters(selectedFilter);
    }
  }, [selectedFilter]);

  const handleOpen = () => {
    if (setOpenP) {
      setOpenP(true);
    } else setOpen(true);
  };
  const handleClose = () => {
    setSelectedFilter(selFilters);
    if (setOpenP) {
      setOpenP(false);
    } else setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selFilters.length) {
        setSelFilters([]);
      } else setSelFilters(filterOptions);
      return;
    }

    let currentFilters = [...selFilters];
    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelFilters([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelFilters(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    // setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper
        sx={{
          borderRadius: "8px",
          ...(children ? {} : { backgroundColor: "#2499EF" }),
        }}
      >
        {removeFilter ? (
          <FlexBox
            sx={{
              marginLeft: "1rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={removeFilter}
          >
            <FlexBox
              sx={{
                width: "12px",
                height: "12px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={WhiteCrossIcon} alt="cross" width={15} height={15} />
            </FlexBox>
          </FlexBox>
        ) : (
          <></>
        )}
        {children ? (
          <div onClick={handleOpen} ref={anchorRef}>
            {children}
          </div>
        ) : (
          <Button
            fullWidth
            type="button"
            variant="contained"
            sx={{
              height: "fitContent",
              borderRadius: "8px",
              padding: "0.5rem 1rem 0.5rem 0.5rem",
              "&:hover": { backgroundColor: "#2499EF" },
            }}
            onClick={handleOpen}
            ref={anchorRef}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FlexBox>{label}</FlexBox>
                {selectedFilter &&
                selectedFilter.length &&
                selectedFilter[0].icon ? (
                  selectedFilter.map((fil: Filter, index: number) => (
                    <FlexBox
                      sx={{
                        margin: "0 3px",
                        background: "#FFFFFF",
                        padding: "1px 2px 2px 2px",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "16px",
                        width: "24px",
                      }}
                      key={`filter-key-${index}`}
                    >
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={fil.icon}
                          width={"20"}
                          height={"10"}
                          effect="blur"
                          placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    </FlexBox>
                  ))
                ) : (
                  <></>
                )}
                <FlexBox
                  sx={{
                    marginLeft: "0.5rem",
                    background: "#FFFFFF",
                    padding: "1px 2px 2px 2px",
                    height: "16px",
                    alignItems: "center",
                    color: "#0B0E1E",
                  }}
                >
                  {selFilters.length}
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox
                  sx={{
                    marginLeft: "4px",
                    ...(openP !== undefined
                      ? openP
                        ? {}
                        : { transform: "rotate(180deg)" }
                      : open
                      ? {}
                      : { transform: "rotate(180deg)" }),
                  }}
                >
                  <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Button>
        )}
      </IconWrapper>

      <Popover
        keepMounted
        open={openP !== undefined ? openP : open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            // width: `${size * 8 + offset}px`,
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "25rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `${
            width
              ? `-${
                  parseInt(width.replace("rem", "")) -
                  (popOffset ? popOffset + selectedFilter.length : 4)
                }rem`
              : `-${size * 8 + offset - (popOffset ? popOffset : 70)}px`
          }`,
          top: "10px",
        }}
      >
        {enableSearch ? (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`enable-search-input`}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label=""
                  variant="standard"
                  placeholder="Search"
                  InputProps={{ disableUnderline: true }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13 && e.target.value) {
                      enableSearch(e.target.value);
                      e.target.value = "";
                    }
                  }}
                />
                <TooltipComponent
                  title={"Input a keyword and press enter to search."}
                  placement="top"
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                    }}
                  >
                    <img src={SearchIcon} alt="search" width={15} height={15} />
                  </div>
                </TooltipComponent>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ) : (
          <></>
        )}
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ].map((filter: Filter, index: number) => (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`${filter.label}--${index}`}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {filter.icon && (
                    <FlexBox
                      sx={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                    >
                      <LazyLoadImage
                        alt={"app"}
                        src={filter.icon}
                        width={"20"}
                        effect="blur"
                        // placeholder={<CircularProgress />}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    sx={{
                      ...((filter.value &&
                        filter.value === "selectAll" &&
                        selFilters.length === filterOptions.length) ||
                      selFilters.findIndex(
                        (fil: Filter) => fil.value === filter.value
                      ) !== -1
                        ? { color: "#3579E3" }
                        : { color: "#82868C" }),
                    }}
                  >
                    {filter.label.length > 25
                      ? `${filter.label.slice(0, 25)}...`
                      : filter.label}
                  </FlexBox>
                </FlexBox>
                <FlexBox>
                  <FormattedCheckbox
                    checked={
                      filter.value === "selectAll"
                        ? selFilters.length === filterOptions.length
                        : selFilters.findIndex(
                            (fil: Filter) => fil.value === filter.value
                          ) !== -1
                    }
                    disabled={!!filter.disabled}
                  />
                </FlexBox>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

export const CheckboxGroupFilterPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  label,
  disableSelectAll,
  removeFilter,
  enableSearch,
  handleFilter,
  openP,
  setOpenP,
  popOffset,
  children,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [selFilters, setSelFilters] = useState(selectedFilter);

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  const createGroupSources = (filterOptions: Filter[]) => {
    // Get the arrays of difference sources
    let sourceObjects: any = {};

    filterOptions.forEach((filter: Filter) => {
      if (filter && filter.source) {
        if (sourceObjects[filter.source]) {
          // Source array exists
          sourceObjects[filter.source] = [
            ...sourceObjects[filter.source],
            filter,
          ];
        } else {
          // Source array doesn't exist. Create it
          sourceObjects[filter.source] = [filter];
        }
      }
    });

    return sourceObjects;
  };

  const createFilterItemsFromGroups = (sourceObjects: any) => {
    let newFilterOptions: Filter[] = [];

    Object.keys(sourceObjects).forEach((source: string) => {
      // Push the group item
      newFilterOptions.push({
        label: source,
        value: source,
        group: source,
        source: source,
        element: false,
      });

      // Push the individual itwms within the group
      sourceObjects[source].forEach((filter: Filter) => {
        newFilterOptions.push({
          ...filter,
          group: source,
          element: true,
        });
      });
    });

    return newFilterOptions;
  };

  useEffect(() => {
    if (selectedFilter) {
      setSelFilters(selectedFilter);
    }
  }, [selectedFilter]);

  const handleOpen = () => {
    if (setOpenP) {
      setOpenP(true);
    } else setOpen(true);
  };
  const handleClose = () => {
    setSelectedFilter(selFilters);
    if (setOpenP) {
      setOpenP(false);
    } else setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selFilters.length) {
        setSelFilters([]);
      } else setSelFilters(filterOptions);
      return;
    }

    // Current selected filters
    let currentFilters = [...selFilters];

    // For groups other than selectAll
    if (filter.group && !filter.element) {
      let availableOpts = filterOptions.filter(
        (fil: Filter) =>
          fil.source && filter.source && fil.source === filter.source
      );
      let selectedOpts = selFilters.filter(
        (fil: Filter) =>
          fil.source && filter.source && fil.source === filter.source
      );

      if (availableOpts.length === selectedOpts.length) {
        // All sources are already selected. Deselect them
        let fil = availableOpts.map((fil: Filter) => fil.value);
        currentFilters = currentFilters.filter(
          (filter: Filter) => !fil.includes(filter.value)
        );
      }

      if (availableOpts.length !== selectedOpts.length) {
        // All sources not selected. Add them
        let fil = currentFilters.map((fil: Filter) => fil.value);
        availableOpts.forEach((filter: Filter) => {
          if (!fil.includes(filter.value)) {
            currentFilters.push(filter);
          }
        });
      }

      setSelFilters([...currentFilters]);
      return;
    }

    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelFilters([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelFilters(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    // setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper
        sx={{
          borderRadius: "8px",
          ...(children ? {} : { backgroundColor: "#2499EF" }),
        }}
      >
        {removeFilter ? (
          <FlexBox
            sx={{
              marginLeft: "1rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={removeFilter}
          >
            <FlexBox
              sx={{
                width: "12px",
                height: "12px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={WhiteCrossIcon} alt="cross" width={15} height={15} />
            </FlexBox>
          </FlexBox>
        ) : (
          <></>
        )}
        {children ? (
          <div onClick={handleOpen} ref={anchorRef}>
            {children}
          </div>
        ) : (
          <Button
            fullWidth
            type="button"
            variant="contained"
            sx={{
              height: "fitContent",
              borderRadius: "8px",
              padding: "0.5rem 1rem 0.5rem 0.5rem",
              "&:hover": { backgroundColor: "#2499EF" },
            }}
            onClick={handleOpen}
            ref={anchorRef}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FlexBox>{label}</FlexBox>
                {selectedFilter &&
                selectedFilter.length &&
                selectedFilter[0].icon ? (
                  selectedFilter.map((fil: Filter, index: number) => (
                    <FlexBox
                      sx={{
                        margin: "0 3px",
                        background: "#FFFFFF",
                        padding: "1px 2px 2px 2px",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "16px",
                        width: "24px",
                      }}
                      key={`filter-key-${index}`}
                    >
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={fil.icon}
                          width={"20"}
                          height={"10"}
                          effect="blur"
                          placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    </FlexBox>
                  ))
                ) : (
                  <></>
                )}
                <FlexBox
                  sx={{
                    marginLeft: "0.5rem",
                    background: "#FFFFFF",
                    padding: "1px 2px 2px 2px",
                    height: "16px",
                    alignItems: "center",
                    color: "#0B0E1E",
                  }}
                >
                  {selFilters.length}
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox
                  sx={{
                    marginLeft: "4px",
                    ...(openP !== undefined
                      ? openP
                        ? {}
                        : { transform: "rotate(180deg)" }
                      : open
                      ? {}
                      : { transform: "rotate(180deg)" }),
                  }}
                >
                  <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Button>
        )}
      </IconWrapper>

      <Popover
        keepMounted
        open={openP !== undefined ? openP : open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            // width: `${size * 8 + offset}px`,
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "25rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `${
            width
              ? `-${
                  parseInt(width.replace("rem", "")) -
                  (popOffset ? popOffset + selectedFilter.length : 4)
                }rem`
              : `-${size * 8 + offset - (popOffset ? popOffset : 70)}px`
          }`,
          top: "10px",
        }}
      >
        {enableSearch ? (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`enable-search-input`}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label=""
                  variant="standard"
                  placeholder="Search"
                  InputProps={{ disableUnderline: true }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13 && e.target.value) {
                      enableSearch(e.target.value);
                      e.target.value = "";
                    }
                  }}
                />
                <TooltipComponent
                  title={"Input a keyword and press enter to search."}
                  placement="top"
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                    }}
                  >
                    <img src={SearchIcon} alt="search" width={15} height={15} />
                  </div>
                </TooltipComponent>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ) : (
          <></>
        )}
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                  group: "selectAll",
                  element: false,
                },
              ]
            : []),
          ...createFilterItemsFromGroups(createGroupSources(filterOptions)),
        ].map((filter: Filter, index: number) => (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`${filter.label}--${index}`}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: `${filter.element ? "1rem" : "0.5rem"}`,
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {filter.icon && (
                    <FlexBox
                      sx={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                    >
                      <LazyLoadImage
                        alt={"app"}
                        src={filter.icon}
                        width={"20"}
                        effect="blur"
                        // placeholder={<CircularProgress />}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    sx={{
                      ...((filter.value &&
                        filter.value === "selectAll" &&
                        selFilters.length === filterOptions.length) ||
                      selFilters.findIndex(
                        (fil: Filter) => fil.value === filter.value
                      ) !== -1
                        ? { color: "#3579E3" }
                        : // For groups
                        filter.group &&
                          !filter.element &&
                          filter.source &&
                          selFilters.filter(
                            (fil: Filter) =>
                              fil.source &&
                              filter.source &&
                              fil.source === filter.source
                          ).length ===
                            filterOptions.filter(
                              (fil: Filter) =>
                                fil.source &&
                                filter.source &&
                                fil.source === filter.source
                            ).length
                        ? { color: "#3579E3" }
                        : { color: "#82868C" }),
                      ...(filter.group && !filter.element
                        ? { fontWeight: 700 }
                        : {}),
                    }}
                  >
                    {filter.group && !filter.element
                      ? `${
                          filter.label.charAt(0).toUpperCase() +
                          filter.label.slice(1).toLowerCase()
                        }`
                      : filter.label.length > 22
                      ? `${filter.label.slice(0, 22)}...`
                      : filter.label}
                  </FlexBox>
                </FlexBox>
                <FlexBox>
                  <FormattedCheckbox
                    checked={
                      // For select all
                      filter.value === "selectAll"
                        ? selFilters.length === filterOptions.length
                        : // For other groups
                        filter.group && !filter.element && filter.source
                        ? selFilters.filter(
                            (fil: Filter) =>
                              fil.source &&
                              filter.source &&
                              fil.source === filter.source
                          ).length ===
                          filterOptions.filter(
                            (fil: Filter) =>
                              fil.source &&
                              filter.source &&
                              fil.source === filter.source
                          ).length
                        : // For elements
                          selFilters.findIndex(
                            (fil: Filter) => fil.value === filter.value
                          ) !== -1
                    }
                    disabled={!!filter.disabled}
                  />
                </FlexBox>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

export const SearchFilterPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  label,
  disableSelectAll,
  removeFilter,
  internalSearch,
  disableCheckbox,
  enableMultiselect,
  handleFilter,
  openP,
  setOpenP,
  popOffset,
  children,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [selFilters, setSelFilters] = useState(selectedFilter);
  const [searchText, setSearchText] = useState("");

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  useEffect(() => {
    if (selectedFilter) {
      setSelFilters(selectedFilter);
    }
  }, [selectedFilter]);

  const handleOpen = () => {
    if (setOpenP) {
      setOpenP(true);
    } else setOpen(true);
  };
  const handleClose = () => {
    setSelectedFilter(selFilters);
    if (setOpenP) {
      setOpenP(false);
    } else setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selFilters.length) {
        setSelFilters([]);
      } else setSelFilters(filterOptions);
      return;
    }

    let currentFilters = [...selFilters];
    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelFilters([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelFilters(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    setSearchText("");
    if (disableCheckbox && !enableMultiselect) {
      setSelectedFilter([filter]);
      if (setOpenP) {
        setOpenP(false);
      }
    }
    // setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper
        sx={{
          borderRadius: "8px",
          ...(children ? {} : { backgroundColor: "#2499EF" }),
        }}
      >
        {removeFilter ? (
          <FlexBox
            sx={{
              marginLeft: "1rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={removeFilter}
          >
            <FlexBox
              sx={{
                width: "12px",
                height: "12px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={WhiteCrossIcon} alt="cross" width={15} height={15} />
            </FlexBox>
          </FlexBox>
        ) : (
          <></>
        )}
        {children ? (
          <div onClick={handleOpen} ref={anchorRef}>
            {children}
          </div>
        ) : (
          <Button
            fullWidth
            type="button"
            variant="contained"
            sx={{
              height: "fitContent",
              borderRadius: "8px",
              padding: "0.5rem 1rem 0.5rem 0.5rem",
              "&:hover": { backgroundColor: "#2499EF" },
            }}
            onClick={handleOpen}
            ref={anchorRef}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FlexBox>{label}</FlexBox>
                {selectedFilter &&
                selectedFilter.length &&
                selectedFilter[0].icon ? (
                  selectedFilter.map((fil: Filter, index: number) => (
                    <FlexBox
                      sx={{
                        margin: "0 3px",
                        background: "#FFFFFF",
                        padding: "1px 2px 2px 2px",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "16px",
                        width: "24px",
                      }}
                      key={`filter-key-${index}`}
                    >
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={fil.icon}
                          width={"20"}
                          height={"10"}
                          effect="blur"
                          placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    </FlexBox>
                  ))
                ) : (
                  <></>
                )}
                <FlexBox
                  sx={{
                    marginLeft: "0.5rem",
                    background: "#FFFFFF",
                    padding: "1px 2px 2px 2px",
                    height: "16px",
                    alignItems: "center",
                    color: "#0B0E1E",
                  }}
                >
                  {selFilters.length}
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox
                  sx={{
                    marginLeft: "4px",
                    ...(openP !== undefined
                      ? openP
                        ? {}
                        : { transform: "rotate(180deg)" }
                      : open
                      ? {}
                      : { transform: "rotate(180deg)" }),
                  }}
                >
                  <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Button>
        )}
      </IconWrapper>

      <Popover
        keepMounted
        open={openP !== undefined ? openP : open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "25rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `${
            width
              ? `-${
                  parseInt(width.replace("rem", "")) -
                  (popOffset ? popOffset : 4)
                }rem`
              : `-${size * 8 + offset - (popOffset ? popOffset : 70)}px`
          }`,
          top: "10px",
        }}
      >
        {internalSearch ? (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`enable-search-input`}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label=""
                  variant="standard"
                  placeholder="Search"
                  InputProps={{ disableUnderline: true }}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  // onKeyDown={(e: any) => {
                  //   if (e.keyCode === 13 && e.target.value) {
                  //     enableSearch(e.target.value);
                  //     e.target.value = "";
                  //   }
                  // }}
                />
                <TooltipComponent
                  title={"Input a keyword and press enter to search."}
                  placement="top"
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                    }}
                  >
                    <img src={SearchIcon} alt="search" width={15} height={15} />
                  </div>
                </TooltipComponent>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ) : (
          <></>
        )}
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ]
          .filter((item: Filter) =>
            item.label?.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((filter: Filter, index: number) => (
            <MenuItem
              sx={{
                padding: "0",
                border: "1px solid #E3E6EB",
                minHeight: "24px",
                height: "30px",
                width: "100%",
              }}
              key={`filter-${filter.label}-${index}`}
              onClick={() => handleChangeLanguage(filter)}
            >
              <ItemWrapper
                sx={{
                  padding: "0",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <FlexBox
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {filter.icon && (
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          marginRight: "6px",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={filter.icon}
                          width={"20"}
                          effect="blur"
                          // placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    )}
                    <FlexBox
                      sx={{
                        ...((filter.value === "selectAll" &&
                          selFilters.length === filterOptions.length) ||
                        selFilters.findIndex(
                          (fil: Filter) => fil.value === filter.value
                        ) !== -1
                          ? { color: "#3579E3" }
                          : { color: "#82868C" }),
                      }}
                    >
                      {filter.label}
                    </FlexBox>
                  </FlexBox>
                  {disableCheckbox ? (
                    <></>
                  ) : (
                    <FlexBox>
                      <FormattedCheckbox
                        checked={
                          filter.value === "selectAll"
                            ? selFilters.length === filterOptions.length
                            : selFilters.findIndex(
                                (fil: Filter) => fil.value === filter.value
                              ) !== -1
                        }
                        disabled={!!filter.disabled}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </ItemWrapper>
            </MenuItem>
          ))}
      </Popover>
    </FlexBox>
  );
};

export const AddWidgetsFilterPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  label,
  disableSelectAll,
  removeFilter,
  internalSearch,
  disableCheckbox,
  enableMultiselect,
  handleFilter,
  openP,
  setOpenP,
  popOffset,
  children,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [selFilters, setSelFilters] = useState<FilterOption[]>([]);
  const [searchText, setSearchText] = useState("");

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  const handleOpen = () => {
    if (setOpenP) {
      setOpenP(true);
    } else setOpen(true);
  };
  const handleClose = () => {
    setSelectedFilter(selFilters);
    setSelFilters([]);
    if (setOpenP) {
      setOpenP(false);
    } else setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selFilters.length) {
        setSelFilters([]);
      } else setSelFilters(filterOptions);
      return;
    }

    let currentFilters = [...selFilters];

    // Logic to add only one variety of the same widget
    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelFilters([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelFilters(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    setSearchText("");

    // Adding as many of the same widgets as required.
    // setSelFilters([...currentFilters, filter]);
    // handleFilter && handleFilter(filter.value);
    // setSearchText("");

    if (disableCheckbox && !enableMultiselect) {
      setSelectedFilter([filter]);
      if (setOpenP) {
        setOpenP(false);
      }
    }
    // setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper
        sx={{
          borderRadius: "8px",
          ...(children ? {} : { backgroundColor: "#2499EF" }),
        }}
      >
        {removeFilter ? (
          <FlexBox
            sx={{
              marginLeft: "1rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={removeFilter}
          >
            <FlexBox
              sx={{
                width: "12px",
                height: "12px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={WhiteCrossIcon} alt="cross" width={15} height={15} />
            </FlexBox>
          </FlexBox>
        ) : (
          <></>
        )}
        {children ? (
          <div onClick={handleOpen} ref={anchorRef}>
            {children}
          </div>
        ) : (
          <Button
            fullWidth
            type="button"
            variant="contained"
            sx={{
              height: "fitContent",
              borderRadius: "8px",
              padding: "0.5rem 1rem 0.5rem 0.5rem",
              "&:hover": { backgroundColor: "#2499EF" },
            }}
            onClick={handleOpen}
            ref={anchorRef}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FlexBox>{label}</FlexBox>
                {selectedFilter &&
                selectedFilter.length &&
                selectedFilter[0].icon ? (
                  selectedFilter.map((fil: Filter, index: number) => (
                    <FlexBox
                      sx={{
                        margin: "0 3px",
                        background: "#FFFFFF",
                        padding: "1px 2px 2px 2px",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "16px",
                        width: "24px",
                      }}
                      key={`filter-key-${index}`}
                    >
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={fil.icon}
                          width={"20"}
                          height={"10"}
                          effect="blur"
                          placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    </FlexBox>
                  ))
                ) : (
                  <></>
                )}
                <FlexBox
                  sx={{
                    marginLeft: "0.5rem",
                    background: "#FFFFFF",
                    padding: "1px 2px 2px 2px",
                    height: "16px",
                    alignItems: "center",
                    color: "#0B0E1E",
                  }}
                >
                  {selFilters.length}
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox
                  sx={{
                    marginLeft: "4px",
                    ...(openP !== undefined
                      ? openP
                        ? {}
                        : { transform: "rotate(180deg)" }
                      : open
                      ? {}
                      : { transform: "rotate(180deg)" }),
                  }}
                >
                  <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Button>
        )}
      </IconWrapper>

      <Popover
        keepMounted
        open={openP !== undefined ? openP : open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "17rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `${
            width
              ? `-${
                  parseInt(width.replace("rem", "")) -
                  (popOffset ? popOffset : 4)
                }rem`
              : `-${size * 8 + offset - (popOffset ? popOffset : 70)}px`
          }`,
          top: "10px",
        }}
      >
        {internalSearch ? (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`enable-search-input`}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label=""
                  variant="standard"
                  placeholder="Search"
                  InputProps={{ disableUnderline: true }}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  // onKeyDown={(e: any) => {
                  //   if (e.keyCode === 13 && e.target.value) {
                  //     enableSearch(e.target.value);
                  //     e.target.value = "";
                  //   }
                  // }}
                />
                <TooltipComponent
                  title={"Input a keyword and press enter to search."}
                  placement="top"
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                    }}
                  >
                    <img src={SearchIcon} alt="search" width={15} height={15} />
                  </div>
                </TooltipComponent>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ) : (
          <></>
        )}
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ]
          .filter((item: Filter) =>
            item.label?.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((filter: Filter, index: number) => (
            <MenuItem
              sx={{
                padding: "0",
                border: "1px solid #E3E6EB",
                minHeight: "24px",
                height: "30px",
                width: "100%",
              }}
              key={`filter-${filter.label}-${index}`}
              onClick={() => handleChangeLanguage(filter)}
            >
              <ItemWrapper
                sx={{
                  padding: "0",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <FlexBox
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {filter.icon && (
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          marginRight: "6px",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={filter.icon}
                          width={"20"}
                          effect="blur"
                          // placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    )}
                    <FlexBox
                      sx={{
                        ...((filter.value === "selectAll" &&
                          selFilters.length === filterOptions.length) ||
                        selFilters.findIndex(
                          (fil: Filter) =>
                            fil.value &&
                            filter.value &&
                            fil.value === filter.value
                        ) !== -1
                          ? { color: "#3579E3" }
                          : { color: "#82868C" }),
                        // color: "#82868C",
                      }}
                    >
                      {filter.label}
                    </FlexBox>
                  </FlexBox>
                  {disableCheckbox ? (
                    <></>
                  ) : (
                    <FlexBox>
                      <FormattedCheckbox
                        checked={
                          filter.value === "selectAll"
                            ? selFilters.length === filterOptions.length
                            : selFilters.findIndex(
                                (fil: Filter) => fil.value === filter.value
                              ) !== -1
                        }
                        disabled={!!filter.disabled}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </ItemWrapper>
            </MenuItem>
          ))}
      </Popover>
    </FlexBox>
  );
};

interface CustomMenuItem {
  anchorEl: null | HTMLElement;
  child: any;
}

type WidgetSettingsMultiPopoverProps = {
  selectedFilter: Filter[];
  setSelectedFilter: (values: Filter[]) => void;
  filterOptions: Filter[];
  selectedFilter2: Filter[];
  setSelectedFilter2: (values: Filter[]) => void;
  filterOptions2: Filter[];
  width?: string;
  disableSelectAll?: boolean;
  removeFilter?: () => void;
  enableSearch?: (keyword: string) => void;
  internalSearch?: boolean;
  disableCheckbox?: boolean;
  enableMultiselect?: boolean;
  handleFilter?: (type: string) => void;
  popOffset?: number;
  children?: ReactNode;
};

export const WidgetSettingsMultiPopover = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  selectedFilter2,
  setSelectedFilter2,
  filterOptions2,
  disableSelectAll,
  internalSearch,
  disableCheckbox,
  handleFilter,
}: WidgetSettingsMultiPopoverProps) => {
  const [searchText, setSearchText] = useState("");

  const handleClose = () => {
    setPopover1({ ...popover1, anchorEl: null });
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selectedFilter.length) {
        setSelectedFilter([]);
      } else setSelectedFilter(filterOptions);
      return;
    }

    let currentFilters = [...selectedFilter];

    // Adding as many of the same widgets as required.
    setSelectedFilter([...currentFilters, filter]);
    handleFilter && handleFilter(filter.value);
    setSearchText("");
    setPopover1({ ...popover1, anchorEl: null });
    // setOpen(false);
  };

  const handleChangeLanguage2 = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions2.length === selectedFilter2.length) {
        setSelectedFilter2([]);
      } else setSelectedFilter2(filterOptions2);
      return;
    }

    setSelectedFilter2([filter]);
    setSearchText("");
    setPopover1({ ...popover1, anchorEl: null });
    setPopover2({ ...popover2, anchorEl: null });
  };

  function Popover2() {
    return <></>;
  }
  const [popover2, setPopover2] = useState<CustomMenuItem>({
    anchorEl: null,
    child: <Popover2 />,
  });

  function Popover1() {
    return <></>;
  }
  const [popover1, setPopover1] = useState<CustomMenuItem>({
    anchorEl: null,
    child: <Popover1 />,
  });

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <SettingWrapper
        sx={{
          borderRadius: "8px",
        }}
      >
        <div
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            setPopover1({ ...popover1, anchorEl: event.currentTarget })
          }
        >
          <img
            src={DragSettingIcon}
            alt={"setting"}
            width={"16px"}
            height={"16px"}
          />
        </div>
      </SettingWrapper>
      <Popover
        id="menu2Popover"
        open={Boolean(popover1.anchorEl)}
        anchorEl={popover1.anchorEl}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            // width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "17rem",
            width: "7.4rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // position: "absolute",
          // left: `${width ? -width  : `-${size * 8 + offset}px`}`,
          left: `-7.2rem`,
          // transform: "translate(-50%)",
          // "-webkit-transform": "translate(-50%) !important",
          // transform: "translateX(-50%)",
          top: "0.15rem",
        }}
      >
        {internalSearch ? (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={`enable-search-input`}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <TextField
                  fullWidth
                  id="input-with-sx"
                  label=""
                  variant="standard"
                  placeholder="Search"
                  InputProps={{ disableUnderline: true }}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  // onKeyDown={(e: any) => {
                  //   if (e.keyCode === 13 && e.target.value) {
                  //     enableSearch(e.target.value);
                  //     e.target.value = "";
                  //   }
                  // }}
                />
                <TooltipComponent
                  title={"Input a keyword and press enter to search."}
                  placement="top"
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                    }}
                  >
                    <img src={SearchIcon} alt="search" width={15} height={15} />
                  </div>
                </TooltipComponent>
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ) : (
          <></>
        )}
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ]
          .filter((item: Filter) =>
            item.label?.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((filter: Filter, index: number) => (
            <MenuItem
              sx={{
                padding: "0",
                border: "1px solid #E3E6EB",
                minHeight: "24px",
                height: "30px",
                width: "100%",
              }}
              key={`filter-${filter.label}-${index}`}
              onClick={(event: React.MouseEvent<any>) => {
                if (
                  filter.value === "removeWidget" ||
                  filter.value === "editWidget"
                ) {
                  handleChangeLanguage(filter);
                }

                if (filter.value === "replaceWidget") {
                  setPopover2({ ...popover2, anchorEl: event.currentTarget });
                }
              }}
              // onMouseEnter={(event: React.MouseEvent<any>) => {
              //   if (filter.value === "replaceWidget") {
              //     setPopover2({ ...popover2, anchorEl: event.currentTarget });
              //   }
              // }}
              // onMouseLeave={(event: React.MouseEvent<any>) => {
              //   if (filter.value === "replaceWidget") {
              //     setPopover2({ ...popover2, anchorEl: null });
              //   }
              // }}
            >
              <ItemWrapper
                sx={{
                  padding: "0",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <FlexBox
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {filter.icon && (
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          marginRight: "6px",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={filter.icon}
                          width={"20"}
                          effect="blur"
                          // placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    )}
                    <FlexBox
                      sx={{
                        // ...((filter.value === "selectAll" &&
                        //   selectedFilter.length === filterOptions.length) ||
                        // selectedFilter.findIndex(
                        //   (fil: Filter) =>
                        //     fil.id && filter.id && fil.id === filter.id
                        // ) !== -1
                        //   ? { color: "#3579E3" }
                        //   : { color: "#82868C" }),
                        // color: "#82868C",
                        ...(filter.color
                          ? { color: filter.color }
                          : { color: "#82868C" }),
                      }}
                    >
                      {filter.label}
                    </FlexBox>
                  </FlexBox>
                  {disableCheckbox ? (
                    <></>
                  ) : (
                    <FlexBox>
                      <FormattedCheckbox
                        checked={
                          filter.value === "selectAll"
                            ? selectedFilter.length === filterOptions.length
                            : selectedFilter.findIndex(
                                (fil: Filter) => fil.value === filter.value
                              ) !== -1
                        }
                        disabled={!!filter.disabled}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </ItemWrapper>
            </MenuItem>
          ))}
      </Popover>
      <Popover
        keepMounted
        id="menu4Popover"
        open={Boolean(popover2.anchorEl)}
        onClose={() => setPopover2({ ...popover2, anchorEl: null })}
        anchorEl={popover2.anchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "17rem",
            width: "13rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          // left: "-9.3rem",
          top: "0",
        }}
      >
        <MenuItem
          sx={{
            padding: "0",
            border: "1px solid #E3E6EB",
            minHeight: "24px",
            height: "30px",
            width: "100%",
          }}
          key={`enable-search-input`}
        >
          <ItemWrapper
            sx={{
              padding: "0",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                paddingLeft: "0.5rem",
              }}
            >
              <TextField
                fullWidth
                id="input-with-sx"
                label=""
                variant="standard"
                placeholder="Search"
                InputProps={{ disableUnderline: true }}
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
                // onKeyDown={(e: any) => {
                //   if (e.keyCode === 13 && e.target.value) {
                //     enableSearch(e.target.value);
                //     e.target.value = "";
                //   }
                // }}
              />
              <TooltipComponent
                title={"Input a keyword and press enter to search."}
                placement="top"
              >
                <div
                  style={{
                    width: "25%",
                    display: "flex",
                  }}
                >
                  <img src={SearchIcon} alt="search" width={15} height={15} />
                </div>
              </TooltipComponent>
            </FlexBox>
          </ItemWrapper>
        </MenuItem>
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions2,
        ]
          .filter((item: Filter) =>
            item.label?.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((filter: Filter, index: number) => (
            <MenuItem
              sx={{
                padding: "0",
                border: "1px solid #E3E6EB",
                minHeight: "24px",
                height: "30px",
                width: "100%",
              }}
              key={`filter-${filter.label}-${index}`}
              onClick={() => handleChangeLanguage2(filter)}
            >
              <ItemWrapper
                sx={{
                  padding: "0",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <FlexBox
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {filter.icon && (
                      <FlexBox
                        sx={{
                          width: "12px",
                          height: "12px",
                          marginRight: "6px",
                        }}
                      >
                        <LazyLoadImage
                          alt={"app"}
                          src={filter.icon}
                          width={"20"}
                          effect="blur"
                          // placeholder={<CircularProgress />}
                        />
                      </FlexBox>
                    )}
                    <FlexBox
                      sx={{
                        ...((filter.value === "selectAll" &&
                          selectedFilter2.length === filterOptions2.length) ||
                        selectedFilter2.findIndex(
                          (fil: Filter) =>
                            fil.id && filter.id && fil.id === filter.id
                        ) !== -1
                          ? { color: "#3579E3" }
                          : { color: "#82868C" }),
                        // color: "#82868C",
                      }}
                    >
                      {filter.label}
                    </FlexBox>
                  </FlexBox>
                  {disableCheckbox ? (
                    <></>
                  ) : (
                    <FlexBox>
                      <FormattedCheckbox
                        checked={
                          filter.value === "selectAll"
                            ? selectedFilter2.length === filterOptions2.length
                            : selectedFilter2.findIndex(
                                (fil: Filter) => fil.value === filter.value
                              ) !== -1
                        }
                        disabled={!!filter.disabled}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </ItemWrapper>
            </MenuItem>
          ))}
      </Popover>
    </FlexBox>
  );
};

export const FilterAdder = ({
  selectedFilter,
  setSelectedFilter,
  filterOptions,
  width,
  label,
  disableSelectAll,
  handleFilter,
}: CheckboxFilterProps) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const size = longest_str_in_array(
    filterOptions.map((fil) => fil.label)
  ).length;

  const offset = 20;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (filter: Filter) => {
    if (filter.disabled) {
      return;
    }
    if (filter.value === "selectAll") {
      if (filterOptions.length === selectedFilter.length) {
        setSelectedFilter([]);
      } else setSelectedFilter([]);
      return;
    }

    let currentFilters = [...selectedFilter];
    const findIndex = currentFilters.findIndex(
      (fil: Filter) => fil.value === filter.value
    );
    if (findIndex === -1) {
      setSelectedFilter([...currentFilters, filter]);
      handleFilter && handleFilter(filter.value);
    } else {
      setSelectedFilter(
        currentFilters.filter((fil: Filter) => fil.value !== filter.value)
      );
    }
    setOpen(false);
  };

  return (
    <FlexBox
      sx={{
        position: "relative",
      }}
    >
      <IconWrapper onClick={handleOpen} ref={anchorRef}>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          sx={{
            height: "fitContent",
            borderRadius: "8px",
            padding: "0.5rem 1rem",
            border: "1px solid #BABFC5",
            color: "#3A3C40",
            "&:hover": { border: "1px solid #BABFC5", background: "#E3E6EB" },
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FlexBox>{label}</FlexBox>
              {selectedFilter &&
              selectedFilter.length &&
              selectedFilter[0].icon ? (
                selectedFilter.map((fil: Filter, index: number) => (
                  <FlexBox
                    sx={{
                      margin: "0 3px",
                      background: "#FFFFFF",
                      padding: "1px 2px 2px 2px",
                      alignItems: "center",
                      height: "16px",
                      width: "24px",
                    }}
                    key={`filter-key-${index}`}
                  >
                    <FlexBox
                      sx={{
                        width: "12px",
                        height: "12px",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        alt={"app"}
                        src={fil.icon}
                        width={"20"}
                        effect="blur"
                        placeholder={<CircularProgress />}
                      />
                    </FlexBox>
                  </FlexBox>
                ))
              ) : (
                <></>
              )}
              {/* <FlexBox
                sx={{
                  marginLeft: "0.5rem",
                  background: "#FFFFFF",
                  padding: "1px 2px 2px 2px",
                  height: "16px",
                  alignItems: "center",
                  color: "#0B0E1E",
                }}
              >
                {selFilters.length}
              </FlexBox> */}
            </FlexBox>
            {/* <FlexBox>
              <FlexBox
                sx={{
                  marginLeft: "4px",
                  ...(open ? {} : { transform: "rotate(180deg)" }),
                }}
              >
                <img src={ArrowIcon} alt="arrow" width="16px" height="16px" />
              </FlexBox>
            </FlexBox> */}
          </FlexBox>
        </Button>
      </IconWrapper>

      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{
          sx: {
            // width: { width },
            width: `${width ? width : `${size * 8 + offset}px`}`,
            padding: "0rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "max-content",
            maxHeight: "25rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          },
        }}
        sx={{
          position: "absolute",
          transform: "translate(-3.8rem,0)",
          top: "10px",
        }}
      >
        {[
          ...(!disableSelectAll
            ? [
                {
                  label: "Select all",
                  value: "selectAll",
                },
              ]
            : []),
          ...filterOptions,
        ].map((filter: Filter) => (
          <MenuItem
            sx={{
              padding: "0",
              border: "1px solid #E3E6EB",
              minHeight: "24px",
              height: "30px",
              width: "100%",
            }}
            key={filter.label}
            onClick={() => handleChangeLanguage(filter)}
          >
            <ItemWrapper
              sx={{
                padding: "0",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {filter.icon && (
                    <FlexBox
                      sx={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                    >
                      <LazyLoadImage
                        alt={"app"}
                        src={filter.icon}
                        width={"20"}
                        effect="blur"
                        // placeholder={<CircularProgress />}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    sx={{
                      ...((filter.value === "selectAll" &&
                        selectedFilter.length === filterOptions.length) ||
                      selectedFilter.findIndex(
                        (fil: Filter) => fil.value === filter.value
                      ) !== -1
                        ? { color: "#3579E3" }
                        : { color: "#82868C" }),
                    }}
                  >
                    {filter.label}
                  </FlexBox>
                </FlexBox>
                {/* <FlexBox>
                  <FormattedCheckbox
                    checked={
                      filter.value === "selectAll"
                        ? selFilters.length === filterOptions.length
                        : selFilters.findIndex(
                            (fil: Filter) => fil.value === filter.value
                          ) !== -1
                    }
                    disabled={!!filter.disabled}
                  />
                </FlexBox> */}
              </FlexBox>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </FlexBox>
  );
};

export default FilterPopover;
