import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, FormHelperText } from "@mui/material";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import FlexBox from "../shared/wrappers/FlexBox";
import LightTextField from "../shared/wrappers/LightTextField";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthLayoutWrapper from "./AuthLayoutWrapper";
import {
  sendVerificationCode,
  changeForgotPassword,
} from "../../services/context/Account";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

const ForgotPassword = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  // Email validation to add
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyCodeProcess, setVerifyCodeProcess] = useState(false);

  const initialValues: {
    code: string;
    password: string;
    confirmPassword: string;
  } = {
    code: "",
    password: "",
    confirmPassword: "",
  };

  const verificationCodeCallback = () => {
    setLoading(false);
    setVerifyCodeProcess(true);
  };

  const forgotPasswordCallback = () => {
    setLoading(false);
    navigate("/login");
  };

  const wrongCodeCallback = () => {
    setLoading(false);
    setError("Incorrect verification code");
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const verificationCodeHandler = () => {
    if (validateEmail(email)) {
      setError("");
      setLoading(true);
      sendVerificationCode(email, verificationCodeCallback);
    } else setError("Enter valid email");
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Email is required"),
    password: Yup.string()
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        setLoading(true);

        try {
          // Fogot Password Flow
          changeForgotPassword(
            email,
            values.code,
            values.password,
            forgotPasswordCallback,
            wrongCodeCallback
          );
        } catch (e: any) {
          // Error Occured
          setLoading(false);
        }
      },
    });

  return (
    <AuthLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
        }}
      >
        <Card
          sx={{
            padding: 4,
            ...(width < 1100 ? {} : { maxWidth: 600 }),
            minWidth: 400,
            overflowY: "auto",
            border: "none",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <FlexBox
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 50,
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                fontSize: "60px",
                fontWeight: 700,
                lineHeight: "82px",
                color: "#3579E3",
                fontFamily: "Barlow",
                alignItems: "center",
                justifyContent: "center",
                width: "740px",
                marginBottom: "20px",
                whiteSpace: "-moz-pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {"Reset your password"}
            </FlexBox>
            <FlexBox
              sx={{ padding: "0 1.7rem 0 1.7rem", flexDirection: "column" }}
            >
              {!verifyCodeProcess ? (
                <Fragment>
                  <FlexBox
                    sx={{
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginBottom: "40px",
                      maxWidth: "440px",
                    }}
                  >
                    {
                      "Enter your email address and we'll send you a code to reset your password."
                    }
                  </FlexBox>
                  <FlexBox sx={{ marginBottom: "20px", textAlign: "left" }}>
                    <FullTextFieldWrapper>
                      <Paragraph fontWeight={600} mb={1}>
                        {"Email"}
                      </Paragraph>
                      <LightTextField
                        fullWidth
                        name={"email"}
                        type={"email"}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        error={!!error.length}
                        helperText={error}
                      />
                    </FullTextFieldWrapper>
                  </FlexBox>
                  <Box sx={{ marginBottom: "10px" }}>
                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        {"Send verification code"}
                      </LoadingButton>
                    ) : (
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={verificationCodeHandler}
                      >
                        {"Send verification code"}
                      </Button>
                    )}
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <FlexBox
                    sx={{
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginBottom: "40px",
                      maxWidth: "440px",
                    }}
                  >
                    {
                      "Check your email. If you have an account, we’ll send you a verification code to reset your password."
                    }
                  </FlexBox>
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <FlexBox sx={{ marginBottom: "20px", textAlign: "left" }}>
                      <FullTextFieldWrapper>
                        <Paragraph fontWeight={600} mb={1}>
                          {"Code"}
                        </Paragraph>
                        <LightTextField
                          fullWidth
                          name={"code"}
                          type={"code"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code || ""}
                          error={Boolean(touched.code && errors.code)}
                          helperText={touched.code && errors.code}
                        />
                      </FullTextFieldWrapper>
                    </FlexBox>
                    <FlexBox sx={{ marginBottom: "20px", textAlign: "left" }}>
                      <FullTextFieldWrapper>
                        <Paragraph fontWeight={600} mb={1}>
                          {"New password"}
                        </Paragraph>
                        <LightTextField
                          fullWidth
                          name={"password"}
                          type={"password"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password || ""}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </FullTextFieldWrapper>
                    </FlexBox>
                    <FlexBox sx={{ marginBottom: "20px", textAlign: "left" }}>
                      <FullTextFieldWrapper>
                        <Paragraph fontWeight={600} mb={1}>
                          {"Confirm new password"}
                        </Paragraph>
                        <LightTextField
                          fullWidth
                          name={"confirmPassword"}
                          type={"password"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword || ""}
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />
                      </FullTextFieldWrapper>
                    </FlexBox>
                    {error && (
                      <FormHelperText
                        error
                        sx={{
                          mt: 2,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </FormHelperText>
                    )}
                    <Box sx={{ marginBottom: "10px" }}>
                      {loading ? (
                        <LoadingButton loading fullWidth variant="contained">
                          {"Reset my password"}
                        </LoadingButton>
                      ) : (
                        <Button fullWidth type="submit" variant="contained">
                          {"Reset my password"}
                        </Button>
                      )}
                    </Box>
                  </form>
                </Fragment>
              )}
              <FlexBox
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginBottom: "20px",
                  maxWidth: "440px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {"Or go back to "}
                <Link to="/login" style={{ paddingLeft: "0.3rem" }}>
                  <Small color="primary.main">Log in</Small>
                </Link>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Card>{" "}
      </FlexBox>
    </AuthLayoutWrapper>
  );
};

export default ForgotPassword;
