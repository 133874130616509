import FlexBox from "../shared/wrappers/FlexBox";
import AddWidgetIcon from "../shared/icons/addWidget.svg";
import { Fragment, useState, useEffect } from "react";
import CommentsWidget from "./CommentsWidget";
import WidgetLink from "./WidgetLink";
import MetricWidget from "./MetricWidget";
import { Widget } from "../Insights/WidgetContainer";
import SentimentBar from "./SentimentBar";
import LineChart from "../Charts/LineChart";
import BarWithLine from "../Charts/BarWithLine";
import {
  // getDateLabel,
  TextFilterPopover,
} from "../shared/wrappers/FilterPopper";
import {
  createSentimentCluster,
  FilterOption,
  getFilterValues,
  GET_DATA_STORE,
  loadingTooltipTitle,
  MORE_SENTIMENTS,
  NEGATIVE,
  POSITIVE,
  SENTIMENTS_ALL,
  starRatingScore,
  STORE,
  timeDiff,
} from "../shared/constants";
import IndependentLineChart from "../Charts/IndependentLineChart";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import FilledStarIcon from "../shared/icons/filled-star-black.svg";
import Point1Star from "../shared/icons/Point1.svg";
import Point2Star from "../shared/icons/Point2.svg";
import Point3Star from "../shared/icons/Point3.svg";
import Point4Star from "../shared/icons/Point4.svg";
import Point5Star from "../shared/icons/Point5.svg";
import Point6Star from "../shared/icons/Point5.svg";
import Point7Star from "../shared/icons/Point7.svg";
import Point8Star from "../shared/icons/Point8.svg";
import Point9Star from "../shared/icons/Point9.svg";
import UnfilledStar from "../shared/icons/unfilled-star.svg";
import Topics from "./Topics";
import FormattedText from "./FormattedText";
import SubClusters from "../Analytics/SubClusters";
import Cluster, { getSubClusterCount } from "../Analytics/Cluster";
import ReviewComponent from "../ReviewComponents/Review";
import ReviewFilters from "../ReviewComponents/ReviewFilters";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import ArrowGreyIcon from "../shared/icons/arrow-grey.svg";
import AreaWithLine from "../Charts/AreaWithLine";
import TooltipComponent from "../shared/wrappers/Tooltip";
import { Review } from "../Analytics/Analytics";
import BarLineGraphWithReviews from "./BarLineGraphWithReviews";
import HorizontalScaleGraphWithReviews from "./HorizontalGraphWithReviews";
import TopRatedRatingComponent from "./TopRatedRating";
import CsvReviewComponent from "../ReviewComponents/Csv";
import ReactPaginate from "react-paginate";
import "../Analytics/pagination.css";
import InfoIcon from "../shared/icons/info.svg";
import SOFReviewComponent from "../ReviewComponents/SOF";
import AmazonReviewComponent from "../ReviewComponents/Amazon";
import GithubReviewComponent from "../ReviewComponents/Github";

const constructArray = (data: any, filter: FilterOption) => {
  return [
    {
      title: "Total",
      value: data.total ? data.total : "",
    },
    {
      title: filter.label,
      value: data.lastMonthReviews ? data.lastMonthReviews : "",
    },
    {
      title: filter.label,
      value: data.lastMonthPercentage ? data.lastMonthPercentage : "",
    },
    // {
    //   title:
    //     filter.value === "custom" ? getDateLabel(filter.dates) : filter.label,
    //   value: data.lastMonthReviews ? data.lastMonthReviews : "",
    // },
    // {
    //   title:
    //     filter.value === "custom" ? getDateLabel(filter.dates) : filter.label,
    //   value: data.lastMonthPercentage ? data.lastMonthPercentage : "",
    // },
  ];
};

export const addWidgetComponent = () => (
  <Fragment>
    <FlexBox
      sx={{
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: "1rem",
        color: "#BABFC5",
      }}
    >
      Add Widget
    </FlexBox>
    <FlexBox
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "0.5rem",
        cursor: "pointer",
      }}
    >
      <FlexBox>
        <img src={AddWidgetIcon} width="194" height="194" alt="Add Widget" />
      </FlexBox>
      <FlexBox
        sx={{
          fontSize: "10px",
          lineHeight: "14px",
          color: "#82868C",
          width: "140px",
          height: "fit-content",
          textAlign: "center",
          marginTop: "0.5rem",
        }}
      >
        Upgrade to Premium to customize your dashboard.
      </FlexBox>
    </FlexBox>{" "}
  </Fragment>
);

export const DetailsComponent = (data: Widget) => {
  const [ratings, setRatings] = useState<any[]>([]);
  const [filteredRating, setFilteredRating] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { expression, expression2 } = getFilterValues({
    filter: data.filter!,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.data) {
      //const clusters = createCluster(data);
      setRatings(data.data.data);
      setLoading(false);
    }
  }, [data]);

  const getSubClusterCount = (subClusters: any[]) => {
    let count = 0;

    subClusters.forEach((sub: any) => {
      count += sub.sentimentData.length;
    });
    return count;
  };

  useEffect(() => {
    if (ratings && ratings.length) {
      let filtered = [...ratings];

      const { expression } = getFilterValues({
        filter: data.filter!,
      });

      // filtered.forEach((cluster: any) => {
      //   let avgRating = 0;
      //   let count = 0;
      //   if (cluster.clusterGroup !== "0") {
      //     cluster.subClusters.forEach((sub: any) => {
      //       const dataDate = new Date(sub.date);
      //       if (
      //         (new Date().getTime() - dataDate.getTime()) / timeDiff <=
      //           getNumDays &&
      //         (new Date().getTime() - dataDate.getTime()) / timeDiff >= 0
      //       ) {
      //         avgRating += sub.rating;
      //         count++;
      //       }
      //     });
      //   }

      // if (cluster.clusterGroup === "0") {
      //   const dataDate = new Date(cluster.date);
      //   if (
      //     (new Date().getTime() - dataDate.getTime()) / timeDiff <=
      //       getNumDays &&
      //     (new Date().getTime() - dataDate.getTime()) / timeDiff >= 0
      //   ) {
      //     avgRating += cluster.rating ? cluster.rating : 0;
      //     count += cluster.reviewId ? cluster.reviewId.length : 0;
      //   }
      // }

      //   cluster.avgRating = Number(
      //     (avgRating / (count ? count : 1)).toFixed(1)
      //   );
      // });

      let filteredData: any[] = [];
      filtered.forEach((clus: any) => {
        let subClusters: any[] = [];

        if (clus.clusterGroup !== "0") {
          clus.subClusters.forEach((sub: any) => {
            if (sub.sentimentData && sub.sentimentData.length) {
              let subC = sub.sentimentData.filter((sent: any) =>
                expression(sent.date)
              );
              subClusters.push({
                ...sub,
                sentimentData: subC,
              });
            }
          });

          filteredData.push({
            ...clus,
            subClusters:
              clus.clusterGroup !== "0"
                ? subClusters.filter((clus) => clus.sentimentData.length !== 0)
                : clus.sentimentData && clus.sentimentData.length !== 0,
          });
        }

        if (clus.clusterGroup === "0" && clus.sentimentData) {
          let subC = clus.sentimentData.filter((sent: any) =>
            expression(sent.date)
          );
          if (subC.length) {
            filteredData.push({
              ...clus,
              sentimentData: subC,
            });
          }
        }
      });

      setFilteredRating(
        filteredData
          .filter(
            (clus: any) =>
              (clus.clusterGroup !== "0" && clus.subClusters.length !== 0) ||
              (clus.clusterGroup === "0" &&
                clus.sentimentData &&
                clus.sentimentData.length !== 0)
          )
          // .sort((a: any, b: any) => b.avgRating - a.avgRating)
          .sort((a: any, b: any) => {
            if (data.data.hideRating) {
              // For Store Aha, go by votes
              return (
                getTotalNumberOfVotes(b, undefined) -
                getTotalNumberOfVotes(a, undefined)
              );
            } else {
              // For other stores, go by number of reviews
              let bCount =
                b.clusterGroup !== "0"
                  ? getSubClusterCount(b.subClusters)
                  : b.sentimentData
                  ? b.sentimentData.length
                  : b.clusterPoints.length;
              let aCount =
                a.clusterGroup !== "0"
                  ? getSubClusterCount(a.subClusters)
                  : a.sentimentData
                  ? a.sentimentData.length
                  : b.clusterPoints.length;
              return bCount - aCount;
            }
          })
        // Filter the Subcluster as well
        // .filter(
        //   (rating) =>
        //     (new Date().getTime() - new Date(rating.date).getTime()) /
        //       timeDiff <=
        //       getNumDays &&
        //     (new Date().getTime() - new Date(rating.date).getTime()) /
        //       timeDiff >=
        //       0
        // )
      );
    }
  }, [ratings, data.filter, data.data.hideRating]);

  const lastDaysHandler = (data: any[], filter: FilterOption) => {
    let lastMonthReviews = 0;
    let secondLastMonth = 0;
    let totalCount = 0;

    [...data]
      .filter((item: any) => {
        if (item.clusterGroup !== "0") {
          let reviews = 0;
          item.subClusters &&
            item.subClusters.forEach((sub: any) => {
              if (sub.sentimentData && sub.sentimentData.length) {
                reviews += sub.sentimentData.length;
              }
            });
          return reviews;
        }

        if (item.clusterGroup === "0") {
          return item.sentimentData && item.sentimentData.length;
        }

        return false;
      })
      .forEach((data: any) => {
        const dataDate = new Date(data.date);
        // Previous Implementation
        // const month = dataDate.getMonth();
        // const year = dataDate.getFullYear();
        // const day = dataDate.getDate();

        // const currentDate = new Date();
        // const currYear = currentDate.getFullYear();
        // const currMonth = currentDate.getMonth();
        // const currDay = currentDate.getDate();

        if (expression(data.date)) {
          lastMonthReviews++;
        }

        if (expression2(data.date)) {
          secondLastMonth++;
        }

        if (
          (new Date().getTime() - dataDate.getTime()) / timeDiff <= 100000 &&
          (new Date().getTime() - dataDate.getTime()) / timeDiff >= 0
        ) {
          totalCount++;
        }
      });

    let lastMonthPercentage = 0;

    if (secondLastMonth === 0) {
      lastMonthPercentage = lastMonthReviews * 100;
    } else
      lastMonthPercentage =
        ((lastMonthReviews - secondLastMonth) / secondLastMonth) * 100;

    return {
      total: totalCount,
      lastMonthReviews: `${
        lastMonthReviews > secondLastMonth
          ? `+${lastMonthReviews - secondLastMonth}`
          : `-${secondLastMonth - lastMonthReviews}`
      }`,
      lastMonthPercentage: `${
        secondLastMonth === 0
          ? "--"
          : lastMonthPercentage > 0
          ? `+${Math.round(lastMonthPercentage)}%`
          : `${Math.round(lastMonthPercentage)}%`
      }`,
    };
  };

  const constructDataObject = (data: Widget) => {
    return lastDaysHandler(data.data.data, data.filter!);
  };

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          <FlexBox
            sx={{
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <FlexBox>
              <FlexBox
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {data.label
                  ? data.label
                  : data.subComponent
                  ? data.subComponent
                  : "Details"}
              </FlexBox>
              {data.help ? (
                <TooltipComponent title={data.help} placement={"top"}>
                  <div
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <img src={InfoIcon} alt="i" width={12} height={12} />
                  </div>
                </TooltipComponent>
              ) : (
                <></>
              )}
            </FlexBox>
            <FlexBox
              sx={{
                color: "#3A3C40",
                fontSize: "10px",
                lineHeight: "14px",
                fontWeight: 500,
                marginLeft: "0.4rem",
                alignItems: "center",
                height: "100%",
                marginTop: "0.15rem",
              }}
            >
              {data.source
                ? `${data.source.length} Data ${
                    data.source.length === 1 ? "source" : "sources"
                  }, `
                : ""}
              {data.filter ? `${data.filter.label}` : ""}
            </FlexBox>
          </FlexBox>

          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            {data &&
              data.data &&
              constructArray(constructDataObject(data), data.filter!).map(
                (
                  metric: { title: string; value: string | number },
                  index: number
                ) => (
                  <MetricWidget
                    data={metric}
                    index={index}
                    subComponent={data.subComponent}
                    key={`${Math.random()}--${index}`}
                    hideRating={
                      !(
                        data.data &&
                        data.data.data &&
                        data.data.data.length !== 0
                      )
                    }
                  />
                )
              )}
          </FlexBox>
          <FlexBox
            sx={{
              flexDirection: "column",
              marginTop: "0.8rem",
            }}
          >
            <FlexBox
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "14px",
              }}
            >
              {`Top ${
                data.subComponent
                  ? data.subComponent.charAt(0).toUpperCase() +
                    data.subComponent.slice(1)
                  : "Data"
              }`}
            </FlexBox>
            <FlexBox
              sx={{
                flexDirection: "column",
                marginBottom: "0.5rem",
              }}
            >
              {filteredRating && filteredRating.length ? (
                filteredRating.slice(0, 5).map(
                  (
                    review: {
                      avgRating: number;
                      clusterTopics: string;
                      groupTopic: string;
                    },
                    index: number
                  ) => (
                    <CommentsWidget
                      data={review}
                      index={index}
                      subComponent={data.subComponent}
                      key={`${Math.random()}--${index}`}
                      hideRating={!!data.data.hideRating}
                    />
                  )
                )
              ) : (
                <FlexBox
                  sx={{
                    marginTop: "0.5rem",
                  }}
                >
                  {"No data within the specified range"}
                </FlexBox>
              )}
              {filteredRating && filteredRating.length ? (
                <WidgetLink
                  linkTo={"/dashboard/analytics"}
                  linkText={"See more in Analytics"}
                  state={{
                    filters: {
                      globalFilter: data.filter,
                      localFilter: data.subComponent,
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </FlexBox>
          </FlexBox>
        </Fragment>
      ) : (
        <LoadingWrapper bg={"#FFFFFF"} />
      )}
    </Fragment>
  );
};

export const SummaryComponent = (data: Widget) => {
  const [showMore, setShowMore] = useState(false);

  const summary = data.data && data.data.content ? data.data.content : "";
  const textSplits = summary.split("\n");

  // Dividing text into paragraphs
  function NewlineText({ textSplits }: { textSplits: string[] }) {
    let newText = [];

    if (textSplits && textSplits.length >= 3) {
      newText = [...textSplits]
        .slice(0, showMore ? textSplits.length : 8)
        .filter((item: string) => item !== "")
        .map((str, index) => {
          if (str && str.length) {
            if (str.includes("Source")) {
              // Contains the name of the source
              return (
                <span
                  key={`summary-${index}`}
                  style={{
                    ...(index === 0
                      ? { marginBottom: "0.3rem" }
                      : { marginTop: "1rem", marginBottom: "0.3rem" }),
                  }}
                >
                  Source:{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      marginLeft: "0.5rem",
                    }}
                  >
                    {str
                      .replaceAll("TL;DR:", "")
                      .replaceAll("tl;dr:", "")
                      .replace("Source:", "")
                      .replace("-", "")
                      .trim()}
                  </span>
                </span>
              );
            }

            // Generalized bullet points case
            return (
              <li key={`summary-${index}`}>
                {str
                  .replaceAll("TL;DR:", "")
                  .replaceAll("tl;dr:", "")
                  .replace("-", "")
                  .trim()}
              </li>
            );
          }
          return <ul key={`summary-${index}`}></ul>;
        });
    } else {
      newText = textSplits.map((str, index) => {
        if (str && str.length) {
          return (
            <p key={`summary-${index}`}>
              {str.replaceAll("TL;DR:", "").replaceAll("tl;dr:", "")}
            </p>
          );
        }
        return <Fragment key={`summary-${index}`}></Fragment>;
      });
    }

    return (
      <FlexBox
        sx={{
          flexDirection: "column",
        }}
      >
        {newText}
      </FlexBox>
    );
  }

  return (
    <Fragment>
      <FlexBox>
        <FlexBox>
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 600,
              textTransform: "uppercase",
              //marginBottom: "1rem",
            }}
          >
            {data.label ? data.label : "tl;dr"}
          </FlexBox>
          {data.help ? (
            <TooltipComponent title={data.help} placement={"top"}>
              <div
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <img src={InfoIcon} alt="i" width={12} height={12} />
              </div>
            </TooltipComponent>
          ) : (
            <></>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            color: "#3A3C40",
            fontSize: "10px",
            lineHeight: "14px",
            fontWeight: 500,
            marginLeft: "0.4rem",
            alignItems: "center",
            height: "100%",
            marginTop: "0.1rem",
          }}
        >
          {data.source
            ? `${data.source.length} Data ${
                data.source.length === 1 ? "source" : "sources"
              }, `
            : ""}
          {data.filter ? `${data.filter.label}` : ""}
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          fontWeight: 500,
          // fontSize: "12px",
          // lineHeight: "17px",
          color: "#2A2E37",
          marginTop: "0.4rem",
        }}
      >
        {" "}
        {/* {data.data && data.data.content ? data.data.content : ""} */}
        {NewlineText({
          textSplits,
        })}
      </FlexBox>
      {textSplits &&
      textSplits.length &&
      textSplits.filter((item: string) => item !== "").length > 8 ? (
        <FlexBox
          sx={{
            width: "100%",
            height: "1rem",
            background: "#FFFFFF",
            boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
            borderRadius: "0px 0px 8px 8px",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            padding: "0.8rem",
            left: 0,
          }}
        >
          <FlexBox
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              lineHeight: "16px",
              color: "#82868C",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => setShowMore && setShowMore(!showMore)}
          >
            {`See ${showMore ? "less" : "more"}`}
            <FlexBox
              sx={{
                marginLeft: "4px",
                ...(showMore
                  ? { transform: "rotate(180deg)" }
                  : { transform: "rotate(0deg)" }),
              }}
            >
              <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export const sentimentsComponent = (data: Widget) => {
  let path = data.route
    ? data.route.split("/")[data.route.split("/").length - 1]
    : "page";
  let structuredPath = path.toUpperCase().charAt(0) + path.slice(1);

  return (
    <Fragment>
      {data.total !== undefined ? (
        <Fragment>
          <FlexBox>
            <FlexBox>
              <FlexBox
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {data.label ? data.label : "Sentiment Score"}
              </FlexBox>
              {data.help ? (
                <TooltipComponent title={data.help} placement={"top"}>
                  <div
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <img src={InfoIcon} alt="i" width={12} height={12} />
                  </div>
                </TooltipComponent>
              ) : (
                <></>
              )}
            </FlexBox>
            <FlexBox
              sx={{
                color: "#3A3C40",
                fontSize: "10px",
                lineHeight: "14px",
                fontWeight: 500,
                marginLeft: "0.4rem",
                alignItems: "center",
                height: "100%",
                width: "30%",
              }}
            >
              <FormattedText
                text={`${
                  data.source
                    ? `${data.source.length} Data ${
                        data.source.length === 1 ? "source" : "sources"
                      }`
                    : ""
                }
              ${data.filter ? `, ${data.filter.label}` : ""}`}
                key={`${data.source}--${data.filter}`}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <FlexBox
              sx={{
                flexDirection: "column",
              }}
            >
              {data.hidden ? (
                <TooltipComponent title={loadingTooltipTitle} placement="top">
                  <div>
                    <FlexBox
                      sx={{
                        fontWeight: 700,
                        fontSize: "96px",
                        lineHeight: "115px",
                        color: "#0B0E1E",
                        margin: "2rem 0 0 0",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "2rem",
                          height: "2px",
                          backgroundColor: "#0B0E1E",
                        }}
                      ></div>
                    </FlexBox>
                  </div>
                </TooltipComponent>
              ) : (
                <FlexBox
                  sx={{
                    fontWeight: 700,
                    fontSize: "96px",
                    lineHeight: "115px",
                    color: "#0B0E1E",
                    margin: "2rem 0 0 0",
                  }}
                >
                  {data.total}
                </FlexBox>
              )}
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <FlexBox
                  sx={{
                    fontWeight: 700,
                    fontSize: "32px",
                    lineHeight: "38px",
                    color: "#4FAEB0",
                    justifyContent: "center",
                  }}
                >
                  {data.lastMonthPercentage
                    ? `${
                        Number(data.lastMonthPercentage) > 0
                          ? `+${data.lastMonthPercentage}%`
                          : `${data.lastMonthPercentage}%`
                      }`
                    : ""}
                </FlexBox>
                {data.subComponent && data.subComponent === SENTIMENTS_ALL && (
                  <FlexBox
                    sx={{
                      fontWeight: 500,
                      fontSize: "10px",
                      linHeight: "14px",
                      color: "#82868C",
                      justifyContent: "center",
                    }}
                  >
                    {data.dataSince ? `since ${data.dataSince}` : ""}
                  </FlexBox>
                )}
              </FlexBox>
              {data.subComponent && data.subComponent === MORE_SENTIMENTS && (
                <WidgetLink
                  linkTo={
                    data.route ? data.route : "/dashboard/insights/sentiments"
                  }
                  linkText={`Go to ${structuredPath}`}
                />
              )}
            </FlexBox>
          </FlexBox>
        </Fragment>
      ) : (
        data.total === undefined && <LoadingWrapper bg={"#FFFFFF"} />
      )}
    </Fragment>
  );
};

export const starRatingsComponent = (data: Widget) => {
  const getStars = (rating: number) => {
    return (
      <>
        {[1, 2, 3, 4, 5].map((r: number) => {
          if (r <= rating) {
            return (
              <img
                src={FilledStarIcon}
                alt="Star"
                width="32px"
                height="32px"
                style={{ marginRight: "2px" }}
                key={`filled-star-${r}`}
              />
            );
          } else if (r === Math.ceil(rating)) {
            const point = (rating * 10) % 10;
            let src = FilledStarIcon;
            switch (point) {
              case 1:
                src = Point1Star;
                break;
              case 2:
                src = Point2Star;
                break;
              case 3:
                src = Point3Star;
                break;
              case 4:
                src = Point4Star;
                break;
              case 5:
                src = Point5Star;
                break;
              case 6:
                src = Point6Star;
                break;
              case 7:
                src = Point7Star;
                break;
              case 8:
                src = Point8Star;
                break;
              case 9:
                src = Point9Star;
                break;
            }
            return (
              <img
                src={src}
                alt="Star"
                width="32px"
                height="32px"
                style={{ marginRight: "2px" }}
                key={`filled-star-${r}`}
              />
            );
          }

          return (
            <img
              src={UnfilledStar}
              alt="Star"
              width="32px"
              height="32px"
              style={{ marginRight: "2px" }}
              key={`unfilled-star-${r}`}
            />
          );
        })}
      </>
    );
  };

  let path = data.route
    ? data.route.split("/")[data.route.split("/").length - 1]
    : "page";
  let structuredPath = path.toUpperCase().charAt(0) + path.slice(1);

  return (
    <Fragment>
      {data.rating ? (
        <Fragment>
          <FlexBox>
            <FlexBox>
              <FlexBox
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {data.label ? data.label : "Current Rating"}
              </FlexBox>
              {data.help ? (
                <TooltipComponent title={data.help} placement={"top"}>
                  <div
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <img src={InfoIcon} alt="i" width={12} height={12} />
                  </div>
                </TooltipComponent>
              ) : (
                <></>
              )}
            </FlexBox>

            <FlexBox
              sx={{
                color: "#3A3C40",
                fontSize: "10px",
                lineHeight: "14px",
                fontWeight: 500,
                marginLeft: "0.4rem",
                alignItems: "center",
                height: "100%",
                width: "30%",
                marginTop: "0.05rem",
              }}
            >
              <FormattedText
                text={`${
                  data.source
                    ? `${data.source.length} Data ${
                        data.source.length === 1 ? "source" : "sources"
                      }`
                    : ""
                }
              ${data.filter ? `, ${data.filter.label}` : ""}`}
                key={`${data.source}--${data.filter}`}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <FlexBox
              sx={{
                flexDirection: "column",
              }}
            >
              {data.hidden ? (
                <TooltipComponent title={loadingTooltipTitle} placement="top">
                  <div>
                    <FlexBox
                      sx={{
                        fontWeight: 700,
                        fontSize: "96px",
                        lineHeight: "115px",
                        color: "#0B0E1E",
                        margin: "2rem 0 0 0",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "2rem",
                          height: "2px",
                          backgroundColor: "#0B0E1E",
                        }}
                      ></div>
                    </FlexBox>
                  </div>
                </TooltipComponent>
              ) : (
                <FlexBox
                  sx={{
                    fontWeight: 700,
                    fontSize: "96px",
                    lineHeight: "115px",
                    color: "#0B0E1E",
                    margin: "2rem 0 0 0",
                    justifyContent: "center",
                  }}
                >
                  {!isNaN(parseFloat(data.rating))
                    ? parseFloat(data.rating)
                    : 0}
                </FlexBox>
              )}
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                {data.hidden ? (
                  <></>
                ) : (
                  <FlexBox>
                    {getStars(
                      !isNaN(parseFloat(data.rating))
                        ? parseFloat(data.rating)
                        : 0
                    )}
                  </FlexBox>
                )}
                {data.subComponent &&
                  data.subComponent === SENTIMENTS_ALL &&
                  data.data.store !== "" && (
                    <FlexBox
                      sx={{
                        fontWeight: 500,
                        fontSize: "10px",
                        linHeight: "14px",
                        color: "#82868C",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <LazyLoadImage
                        alt={""}
                        src={
                          data.data.store === "google"
                            ? PlayStoreIcon
                            : data.data.store === "apple"
                            ? AppleStoreIcon
                            : ""
                        }
                        width={"20"}
                      />
                    </FlexBox>
                  )}
              </FlexBox>
            </FlexBox>
            {data.subComponent && data.subComponent === MORE_SENTIMENTS && (
              <WidgetLink
                linkTo={
                  data.route ? data.route : "/dashboard/insights/sentiments"
                }
                linkText={`Go to ${structuredPath}`}
              />
            )}
          </FlexBox>
        </Fragment>
      ) : (
        data.rating === undefined && <LoadingWrapper bg={"#FFFFFF"} />
      )}
    </Fragment>
  );
};

export const lineGraphWithScaleComponent = (data: Widget) => (
  <Fragment>
    <FlexBox
      sx={{
        marginBottom: "1rem",
        alignItems: "center",
      }}
    >
      <FlexBox
        sx={{
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 600,
          textTransform: "uppercase",
        }}
      >
        {data.label ? data.label : data.data ? data.data.title : ""}
      </FlexBox>
      <FlexBox>
        <FlexBox
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {data.label ? data.label : data.data ? data.data.title : ""}
        </FlexBox>
        {data.help ? (
          <TooltipComponent title={data.help} placement={"top"}>
            <div
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            >
              <img src={InfoIcon} alt="i" width={12} height={12} />
            </div>
          </TooltipComponent>
        ) : (
          <></>
        )}
      </FlexBox>
      <FlexBox
        sx={{
          color: "#3A3C40",
          fontSize: "10px",
          lineHeight: "14px",
          fontWeight: 500,
          marginLeft: "0.4rem",
          alignItems: "center",
          height: "100%",
          // marginTop: "0.15rem",
        }}
      >
        {data.source
          ? `${data.source.length} Data ${
              data.source.length === 1 ? "source" : "sources"
            }`
          : ""}
        {data.filter ? `, ${data.filter.label}` : ""}
      </FlexBox>
    </FlexBox>
    <FlexBox
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <FlexBox
        sx={{
          width: "88%",
          height: "100%",
        }}
      >
        <LineChart height={"100%"} data={data.data.metrics} />
      </FlexBox>
      <FlexBox
        sx={{
          width: "10%",
          height: "100%",
        }}
      >
        <SentimentBar data={data.data.metrics} />
      </FlexBox>
    </FlexBox>
  </Fragment>
);

export const lineGraphComponent = (data: Widget) => (
  <Fragment>
    <FlexBox>
      <FlexBox
        sx={{
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 600,
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
      >
        {data.label ? data.label : "Relevancy"}
      </FlexBox>
      {data.help ? (
        <TooltipComponent title={data.help} placement={"top"}>
          <div
            style={{
              marginLeft: "0.5rem",
              cursor: "pointer",
            }}
          >
            <img src={InfoIcon} alt="i" width={12} height={12} />
          </div>
        </TooltipComponent>
      ) : (
        <></>
      )}
    </FlexBox>

    <FlexBox
      sx={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <FlexBox
        sx={{
          width: "95%",
          justifyContent: "center",
        }}
      >
        <LineChart height={"100%"} />
      </FlexBox>
    </FlexBox>
  </Fragment>
);

export const IndependentLineGraphComponent = (data: Widget) => (
  <Fragment>
    <FlexBox
      sx={{
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <FlexBox>
        <FlexBox
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {data.label
            ? data.label
            : data.data && data.data.title
            ? data.data.title
            : "Report"}
        </FlexBox>
        {data.help ? (
          <TooltipComponent title={data.help} placement={"top"}>
            <div
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            >
              <img src={InfoIcon} alt="i" width={12} height={12} />
            </div>
          </TooltipComponent>
        ) : (
          <></>
        )}
      </FlexBox>
      <FlexBox
        sx={{
          color: "#3A3C40",
          fontSize: "10px",
          fontWeight: 500,
          marginLeft: "0.4rem",
          lineHeight: "21px",
          height: "100%",
          marginTop: "0.15rem",
        }}
      >
        {data.source
          ? `${data.source.length} Data ${
              data.source.length === 1 ? "source" : "sources"
            }`
          : ""}
        {data.filter ? `, ${data.filter.label}` : ""}
      </FlexBox>
    </FlexBox>
    <FlexBox
      sx={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <FlexBox
        sx={{
          width: "95%",
          justifyContent: "center",
        }}
      >
        <IndependentLineChart
          data={data.data.metrics}
          customYAxis={data.data.customYAxis}
        />
      </FlexBox>
    </FlexBox>
  </Fragment>
);

export const IndependentLineGraphComponentExtention = (data: Widget) => (
  <Fragment>
    <FlexBox
      sx={{
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <FlexBox>
        <FlexBox
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {data.label
            ? data.label
            : data.data && data.data.title
            ? data.data.title
            : "Report"}
        </FlexBox>
        {data.help ? (
          <TooltipComponent title={data.help} placement={"top"}>
            <div
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            >
              <img src={InfoIcon} alt="i" width={12} height={12} />
            </div>
          </TooltipComponent>
        ) : (
          <></>
        )}
      </FlexBox>
      <FlexBox
        sx={{
          color: "#3A3C40",
          fontSize: "10px",
          fontWeight: 500,
          marginLeft: "0.4rem",
          lineHeight: "21px",
          height: "100%",
          marginTop: "0.15rem",
        }}
      >
        {data.source
          ? `${data.source.length} Data ${
              data.source.length === 1 ? "source" : "sources"
            }`
          : ""}
        {data.filter ? `, ${data.filter.label}` : ""}
      </FlexBox>
    </FlexBox>
    <FlexBox
      sx={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <FlexBox
        sx={{
          width: "95%",
          justifyContent: "center",
        }}
      >
        <IndependentLineChart
          data={data.data.metrics}
          customYAxis={data.data.customYAxis}
        />
      </FlexBox>
    </FlexBox>
  </Fragment>
);

export const BarWithLineGraph = (data: Widget) => {
  return (
    <Fragment>
      <FlexBox
        sx={{
          alignItems: "center",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 600,
              textTransform: "uppercase",
              marginBottom: "1rem",
            }}
          >
            {data.label
              ? data.label
              : data.data.title
              ? data.data.title
              : "Trending Data"}
          </FlexBox>
          {data.help ? (
            <TooltipComponent title={data.help} placement={"top"}>
              <div
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <img src={InfoIcon} alt="i" width={12} height={12} />
              </div>
            </TooltipComponent>
          ) : (
            <></>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            color: "#3A3C40",
            fontSize: "10px",
            lineHeight: "14px",
            fontWeight: 500,
            marginLeft: "0.4rem",
            alignItems: "center",
            height: "100%",
            // marginTop: "0.15rem",
          }}
        >
          {data.data.source
            ? `${data.data.source.length} Data ${
                data.data.source.length === 1 ? "source" : "sources"
              }`
            : ""}
          {data.data.filter ? `, ${data.data.filter.label}` : ""}
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          justifyContent: "space-between",
          marginBottom: "0.5rem",
          ...(data.data.showReviews || data.data.width < 880
            ? { flexDirection: "column" }
            : { flexDirection: "row" }),
        }}
      >
        <FlexBox
          sx={{
            ...(data.data.showReviews || data.data.width < 880
              ? { width: "100%", marginBottom: "0.75rem" }
              : { width: "49%" }),
            minHeight: "220px",
          }}
        >
          {data.hidden ? (
            <TooltipComponent title={loadingTooltipTitle} placement="top">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <LoadingWrapper bg={`#FFFFFF`} />
              </div>
            </TooltipComponent>
          ) : (
            <Topics data={data.data} />
          )}
        </FlexBox>
        <FlexBox
          sx={{
            ...(data.data.showReviews || data.data.width < 880
              ? { width: "100%" }
              : { width: "49%" }),
          }}
        >
          <BarWithLine
            height={140}
            data={data.data.metrics}
            customYAxis={data.data.customYAxis}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          width: "100%",
          height: "1rem",
          background: "#FFFFFF",
          boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
          borderRadius: "0px 0px 8px 8px",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: 0,
          padding: "0.8rem",
          left: 0,
        }}
      >
        <FlexBox
          sx={{
            fontSize: "13px",
            fontWeight: 600,
            lineHeight: "16px",
            color: "#82868C",
            padding: "0.5rem",
            cursor: "pointer",
          }}
          onClick={() =>
            data.data.setShowReviews &&
            data.data.setShowReviews(!data.data.showReviews)
          }
        >
          {`${data.data.showReviews ? "Hide" : "See"} reviews`}
          <FlexBox
            sx={{
              marginLeft: "4px",
              ...(data.data.showReviews
                ? { transform: "rotate(90deg)" }
                : { transform: "rotate(270deg)" }),
            }}
          >
            <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export const BarLineGraphWithReviewsComponent = (data: Widget) => (
  <BarLineGraphWithReviews source={data.source} filter={data.filter} />
);

export const ClusterSubcluster = (data: Widget) => {
  const textFilterOptions = [
    {
      label: "Top ratings",
      value: "topRatings",
    },
    {
      label: "Bottom ratings",
      value: "bottomRatings",
    },
    {
      label: "# of new reviews",
      value: "numberOfNewReviews",
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState(textFilterOptions[0]);

  const getSelectedSubtopic = (
    subCluster: any,
    selectedFilter: { value: any }
  ) => {
    if (subCluster && selectedFilter) {
      // Perform Sorting or operations based on local filter
      // and set in the filtered data
      switch (selectedFilter.value) {
        case "topRatings":
          return {
            ...subCluster,
            subClusters: [...subCluster.subClusters].sort(
              (a: any, b: any) => b.rating - a.rating
            ),
          };

        case "bottomRatings":
          return {
            ...subCluster,
            subClusters: [...subCluster.subClusters].sort((a: any, b: any) => {
              return a.rating - b.rating;
            }),
          };
        case "numberOfNewReviews": {
          if (data.filter) {
            const { expression } = getFilterValues({
              filter: data.filter,
            });

            let subC: number[] = [];

            subCluster.subClusters.forEach((sub: any, index: number) => {
              let count = 0;
              sub.dates.forEach((d: any) => {
                if (expression(d)) {
                  count++;
                }
              });
              subC.push(count);
            });

            let sortedArray = [...subCluster.subClusters];
            for (let i = 0; i < sortedArray.length; i++) {
              for (let j = i + 1; j < sortedArray.length; j++) {
                if (subC[i] < subC[j]) {
                  let temp = sortedArray[i];
                  sortedArray[i] = sortedArray[j];
                  sortedArray[j] = temp;
                }
              }
            }

            return {
              ...subCluster,
              subClusters: sortedArray,
            };
          }

          return subCluster;
        }
        default:
          return subCluster;
      }
    }

    return subCluster;
  };

  const getClusters = (clusters: any, selectedFilter: { value: any }) => {
    if (clusters && selectedFilter) {
      // Perform Sorting or operations based on local filter
      // and set in the filtered data
      let clustersByReviews = [...clusters]
        // Sort based on number of reviews first
        .sort((a: any, b: any) => {
          let bCount =
            b.clusterGroup !== "0"
              ? getSubClusterCount(b.subClusters)
              : b.clusterPoints
              ? b.clusterPoints.length
              : 0;
          let aCount =
            a.clusterGroup !== "0"
              ? getSubClusterCount(a.subClusters)
              : a.clusterPoints
              ? a.clusterPoints.length
              : 0;

          return bCount - aCount;
        });
      switch (selectedFilter.value) {
        // Then sort based on the rating
        case "topRatings":
          return clustersByReviews.sort(
            (a: any, b: any) => b.rating - a.rating
          );
        // Then sort based on the rating
        case "bottomRatings":
          return clustersByReviews.sort(
            (a: any, b: any) => a.rating - b.rating
          );
        case "numberOfNewReviews":
          return clustersByReviews;
        default:
          return clusters;
      }
    }

    return clusters;
  };

  return (
    <FlexBox
      sx={{
        width: "100%",
        background: "#FFFFFF",
        height: "47%",
        maxHeight: "330px",
        minHeight: "330px",
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      {data.hidden ? (
        <TooltipComponent title={loadingTooltipTitle} placement="top">
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingWrapper bg={`#FFFFFF`} />
          </div>
        </TooltipComponent>
      ) : (
        <Fragment>
          <FlexBox
            sx={{
              width: "49%",
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "0.8rem",
              }}
            >
              <FlexBox>
                <FlexBox
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "17px",
                    textTransform: "uppercase",
                    color: "#3A3C40",
                  }}
                >
                  Topics
                </FlexBox>
                <FlexBox
                  sx={{
                    color: "#3A3C40",
                    fontSize: "10px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    marginLeft: "0.4rem",
                    alignItems: "center",
                    height: "100%",
                    // marginTop: "0.1rem",
                  }}
                >
                  {data.source
                    ? `${data.source.length} Data ${
                        data.source.length === 1 ? "source" : "sources"
                      }`
                    : ""}
                  {data.filter ? `, ${data.filter.label}` : ""}
                </FlexBox>
              </FlexBox>{" "}
              <FlexBox
                sx={{
                  fonrWeight: 500,
                  fontSize: "10px",
                  lineHeight: "14px",
                  color: "#3A3C40",
                }}
              >
                # reviews
              </FlexBox>
            </FlexBox>
            <FlexBox
              // onScroll={onScroll}
              sx={{
                padding: "0 0.1rem 0 0.1rem",
                height: "100%",
                overflowY: "auto",
                flexDirection: "column",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                "&::-webkit-scrollbar": {
                  width: "0.4rem",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#A3A6AB",
                  borderRadius: "5px",
                },
              }}
            >
              <Cluster
                clusterData={getClusters(data.data.clusterData, selectedFilter)}
                selectedSubtopic={data.data.selectedSubtopic}
                filterReviewsHandler={data.data.filterReviewsHandler}
                selectedSubcluster={data.data.selectedSubcluster}
                setSelectedSubtopic={data.data.setSelectedSubtopic}
                setSelectedSubcluster={data.data.setSelectedSubcluster}
                type={"Generic"}
              />
            </FlexBox>
          </FlexBox>
          {data.data.clusterData && data.data.clusterData.length !== 0 && (
            <SubClusters
              selectedSubtopic={getSelectedSubtopic(
                data.data.selectedSubtopic,
                selectedFilter
              )}
              filterReviewsHandler={data.data.filterReviewsHandler}
              selectedSubcluster={data.data.selectedSubcluster}
              setSelectedSubcluster={data.data.setSelectedSubcluster}
              filterOptions={textFilterOptions}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              type={"Generic"}
            />
          )}
        </Fragment>
      )}
    </FlexBox>
  );
};

export const Reviews = (data: Widget) => {
  const filteredData =
    data.data.filteredReviews && data.data.filteredReviews.length
      ? data.data.filteredReviews.filter(
          (rev: Review) => rev.sentimentScore !== undefined
          // &&
          // (parseFloat(rev.sentimentScore) < -0.1 ||
          //   parseFloat(rev.sentimentScore) > 0.1)
        )
      : [];

  const pageCount = Math.ceil(filteredData.length / data.data.itemsPerPage);
  const endOffset = data.data.itemOffset + data.data.itemsPerPage;
  const currentItems = filteredData.slice(data.data.itemOffset, endOffset);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset =
      (event.selected * data.data.itemsPerPage) % filteredData.length;
    data.data.setItemOffset(newOffset);
  };

  return (
    <FlexBox
      sx={{
        width: "100%",
        //minWidth: "150px",
        flexDirection: "column",
        padding: "4px",
        height: "59vh",
      }}
    >
      <ReviewFilters
        positive={data.data.positive}
        negative={data.data.negative}
        setPositive={data.data.setPositive}
        setNegative={data.data.setNegative}
        oneStar={data.data.oneStar}
        twoStar={data.data.twoStar}
        threeStar={data.data.threeStar}
        fourStar={data.data.fourStar}
        fiveStar={data.data.fiveStar}
        setOneStar={data.data.setOneStar}
        setTwoStar={data.data.setTwoStar}
        setThreeStar={data.data.setThreeStar}
        setFiveStar={data.data.setFiveStar}
        setFourStar={data.data.setFourStar}
        selectAll={data.data.selectAll}
        clearAll={data.data.clearAll}
      />
      <FlexBox
        sx={{
          flexDirection: "column",
          ...(data.data.height
            ? { height: data.data.height }
            : { height: "90vh" }),
        }}
      >
        <FlexBox
          sx={{
            fontSize: "15px",
            lineHeight: "18px",
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#3A3C40",
            marginBottom: "0.8rem",
            marginLeft: "0.4rem",
          }}
        >
          {`${filteredData.length} Reviews`}
        </FlexBox>
        <FlexBox
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          key={`react-paginate-${filteredData.length}-reviews`}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
            height: "100%",
            position: "relative",
            overflowY: "auto",
            padding: "0.3rem 0.2rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          }}
          key={`review-component-${filteredData.length}-${currentItems.length}`}
        >
          {currentItems && currentItems.length ? (
            currentItems.map((review: any, index: number) => {
              if (review.store && review.store === STORE.csv) {
                return (
                  <CsvReviewComponent
                    review={review}
                    index={index}
                    key={`csv-review-component-${index}`}
                    clusterPoints={data.data.clusterPoints}
                    reviewPoints={data.data.reviewPoints}
                    store={data.data.store}
                  />
                );
              }

              if (review.store && review.store === STORE.sof) {
                return (
                  <SOFReviewComponent
                    review={review}
                    index={index}
                    key={`sof-review-component-${index}`}
                    clusterPoints={data.data.clusterPoints}
                    reviewPoints={data.data.reviewPoints}
                    store={data.data.store}
                  />
                );
              }

              if (review.store && review.store === STORE.amazon) {
                return (
                  <AmazonReviewComponent
                    review={review}
                    index={index}
                    key={`amazon-review-component-${index}`}
                    clusterPoints={data.data.clusterPoints}
                    reviewPoints={data.data.reviewPoints}
                    store={data.data.store}
                  />
                );
              }

              if (review.store && review.store === STORE.github) {
                return (
                  <GithubReviewComponent
                    review={review}
                    index={index}
                    key={`github-review-component-${index}`}
                    clusterPoints={data.data.clusterPoints}
                    reviewPoints={data.data.reviewPoints}
                    store={data.data.store}
                  />
                );
              }

              return (
                <ReviewComponent
                  review={review}
                  index={index}
                  key={`review-component-${index}`}
                  reviewPoints={data.data.reviewPoints}
                  clusterPoints={data.data.clusterPoints}
                />
              );
            })
          ) : (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No reviews in selected range
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export const IndependentBarLineGraph = (data: Widget) => {
  return (
    <Fragment>
      <FlexBox
        sx={{
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {data.label
              ? data.label
              : data.data.title
              ? data.data.title
              : "Trending Data"}
          </FlexBox>
          {data.help ? (
            <TooltipComponent title={data.help} placement={"top"}>
              <div
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <img src={InfoIcon} alt="i" width={12} height={12} />
              </div>
            </TooltipComponent>
          ) : (
            <></>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            color: "#3A3C40",
            fontSize: "10px",
            lineHeight: "14px",
            fontWeight: 500,
            marginLeft: "0.4rem",
            alignItems: "center",
            height: "100%",
            // marginTop: "0.1rem",
          }}
        >
          {data.source
            ? `${data.source.length} Data ${
                data.source.length === 1 ? "source" : "sources"
              }`
            : ""}
          {data.filter ? `, ${data.filter.label}` : ""}
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          width: "100%",
        }}
      >
        <BarWithLine
          height={140}
          data={data.data.metrics}
          customYAxis={data.data.customYAxis}
        />
      </FlexBox>
    </Fragment>
  );
};

export const AreaWithLineGraph = (data: Widget) => {
  return (
    <Fragment>
      <FlexBox
        sx={{
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {data.label
              ? data.label
              : data.data.title
              ? data.data.title
              : "Trending Data"}
          </FlexBox>
          {data.help ? (
            <TooltipComponent title={data.help} placement={"top"}>
              <div
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <img src={InfoIcon} alt="i" width={12} height={12} />
              </div>
            </TooltipComponent>
          ) : (
            <></>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            color: "#3A3C40",
            fontSize: "10px",
            fontWeight: 500,
            marginLeft: "0.4rem",
            lineHeight: "21px",
            height: "100%",
            marginTop: "0.1rem",
          }}
        >
          {data.source
            ? `${data.source.length} Data ${
                data.source.length === 1 ? "source" : "sources"
              }`
            : ""}
          {data.filter ? `, ${data.filter.label}` : ""}
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          width: "100%",
        }}
      >
        <AreaWithLine
          height={140}
          data={data.data.metrics}
          customYAxis={data.data.customYAxis}
        />
      </FlexBox>
    </Fragment>
  );
};

const textFilterOptions = [
  {
    label: "# of reviews",
    value: "numberOfReviews",
  },
  {
    label: "Ratio",
    value: "ratio",
  },
];

export const HorizontalScaleGraph = (
  data: Widget,
  setShowReviews: (value: boolean) => void,
  showLoading: boolean
) => {
  // Setting default to ratios
  const [selectedFilter, setSelectedFilter] = useState(textFilterOptions[1]);
  // const [init, setInit] = useState(true);
  const [filteredData, setFilteredData] = useState<
    {
      positive: number;
      negative: number;
      clusterTopics: string;
      groupTopic: any;
      id: string;
    }[]
  >([]);

  useEffect(() => {
    if (selectedFilter.value === "ratio" && data.reviews) {
      const fil = [...data.reviews]
        // Sort based on number of reviews first
        .sort((a: any, b: any) => {
          let bCount =
            b.clusterGroup !== "0"
              ? getSubClusterCount(b.subClusters)
              : b.clusterPoints
              ? b.clusterPoints.length
              : 0;
          let aCount =
            a.clusterGroup !== "0"
              ? getSubClusterCount(a.subClusters)
              : a.clusterPoints
              ? a.clusterPoints.length
              : 0;

          return bCount - aCount;
        })
        // Then sort based on the sentiment
        .sort(function (a, b) {
          const aRatio = a.positive / a.negative;
          const bRatio = b.positive / b.negative;
          if (data.subComponent && data.subComponent === POSITIVE) {
            return bRatio - aRatio;
          } else return aRatio - bRatio;
        });
      setFilteredData(fil);
      // if (init) {
      //   setInit(false);
      //   data.data.setSelectedTopic(fil[0]);
      // }
    } else if (selectedFilter.value === "numberOfReviews" && data.reviews) {
      const fil = [...data.reviews]
        // Sort based on number of reviews first
        .sort((a: any, b: any) => {
          let bCount =
            b.clusterGroup !== "0"
              ? getSubClusterCount(b.subClusters)
              : b.clusterPoints
              ? b.clusterPoints.length
              : 0;
          let aCount =
            a.clusterGroup !== "0"
              ? getSubClusterCount(a.subClusters)
              : a.clusterPoints
              ? a.clusterPoints.length
              : 0;

          return bCount - aCount;
        })
        // Then sort based on the sentiment
        .sort(function (a, b) {
          if (data.subComponent && data.subComponent === POSITIVE) {
            return b.positive - a.positive;
          } else return b.negative - a.negative;
        });
      setFilteredData(fil);
      // if (init) {
      //   setInit(false);
      //   data.data.setSelectedTopic(fil[0]);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, data.reviews]);

  return (
    <Fragment>
      <FlexBox
        sx={{
          marginBottom: "1rem",
          marginRight: "1.5rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FlexBox
          sx={{
            alignItems: "center",
          }}
        >
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {`Top ${data.subComponent ? data.subComponent : ""} topics`}
          </FlexBox>
          <FlexBox
            sx={{
              color: "#3A3C40",
              fontSize: "10px",
              lineHeight: "14px",
              fontWeight: 500,
              marginLeft: "0.4rem",
              alignItems: "center",
              height: "100%",
              // marginTop: "0.15rem",
            }}
          >
            {data.source
              ? `${data.source.length} Data ${
                  data.source.length === 1 ? "source" : "sources"
                }`
              : ""}
            {data.filter ? `, ${data.filter.label}` : ""}
          </FlexBox>
        </FlexBox>
        <TextFilterPopover
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterOptions={textFilterOptions}
        />
      </FlexBox>
      <FlexBox
        sx={{
          flexDirection: "column",
          marginTop: "0.5rem",
          height: "80%",
        }}
      >
        {filteredData && filteredData.length ? (
          filteredData.slice(0, 7).map((review, index) => (
            <FlexBox
              sx={{
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  background: "#E3E6EB",
                },
                borderRadius: "4px",
                padding: 1,
                ...(data.data.selectedTopic &&
                data.data.selectedTopic.id === review.id
                  ? {
                      background: "#BABFC5",
                    }
                  : {}),
              }}
              key={`review-horizontal-scale-${index}-${review.positive}-${review.negative}`}
              onClick={
                data.data.setSelectedTopic
                  ? () => {
                      data.data.setSelectedTopic(review);
                      setShowReviews && setShowReviews(true);
                    }
                  : () => {}
              }
            >
              <FlexBox
                sx={{
                  width: "35%",
                  minWidth: "150px",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#2A2E37",
                }}
              >
                <FormattedText
                  text={
                    review.groupTopic
                      ? review.groupTopic.replace(/(^"|"|"|'|'|'$)/g, "")
                      : review.clusterTopics
                      ? review.clusterTopics.replace(/(^"|"|"|'|'|'$)/g, "")
                      : ""
                  }
                />
              </FlexBox>
              {data.subComponent && data.subComponent === POSITIVE ? (
                <FlexBox
                  sx={{
                    width: "63%",
                    // minWidth: "250px",
                    fontSize: "10px",
                  }}
                >
                  {review.positive ? (
                    <FlexBox
                      sx={{
                        width: `${Math.ceil(
                          (review.positive /
                            (review.negative + review.positive)) *
                            100
                        )}%`,
                        background: "#4FAEB0",
                        height: "16px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                        ...(review.negative === 0
                          ? {
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                            }
                          : {}),
                        color: "white",
                        textAlign: "left",
                        alignItems: "center",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      {selectedFilter.value === "ratio"
                        ? `${Math.ceil(
                            (review.positive /
                              (review.negative + review.positive)) *
                              100
                          )}%`
                        : review.positive}
                    </FlexBox>
                  ) : (
                    <></>
                  )}
                  {review.negative ? (
                    <FlexBox
                      sx={{
                        width: `${Math.ceil(
                          (review.negative /
                            (review.negative + review.positive)) *
                            100
                        )}%`,
                        background: "#DB4E82",
                        height: "16px",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        ...(review.positive === 0
                          ? {
                              borderTopLeftRadius: "4px",
                              borderBottomLeftRadius: "4px",
                            }
                          : {}),
                        color: "white",
                        justifyContent: "right",
                        alignItems: "center",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {selectedFilter.value === "ratio"
                        ? `${Math.floor(
                            (review.negative /
                              (review.negative + review.positive)) *
                              100
                          )}%`
                        : review.negative}
                    </FlexBox>
                  ) : (
                    <></>
                  )}
                </FlexBox>
              ) : (
                <FlexBox
                  sx={{
                    width: "63%",
                    // minWidth: "250px",
                    fontSize: "10px",
                  }}
                >
                  {review.negative ? (
                    <FlexBox
                      sx={{
                        width: `${Math.ceil(
                          (review.negative /
                            (review.negative + review.positive)) *
                            100
                        )}%`,
                        background: "#DB4E82",
                        height: "16px",
                        color: "white",
                        textAlign: "left",
                        alignItems: "center",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                        paddingLeft: "0.5rem",
                        ...(review.positive === 0
                          ? {
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                            }
                          : {}),
                      }}
                    >
                      {selectedFilter.value === "ratio"
                        ? `${Math.floor(
                            (review.negative /
                              (review.negative + review.positive)) *
                              100
                          )}%`
                        : review.negative}
                    </FlexBox>
                  ) : (
                    <></>
                  )}
                  {review.positive ? (
                    <FlexBox
                      sx={{
                        width: `${Math.ceil(
                          (review.positive /
                            (review.negative + review.positive)) *
                            100
                        )}%`,
                        background: "#4FAEB0",
                        height: "16px",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        color: "white",
                        ...(review.negative === 0
                          ? {
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                            }
                          : {}),
                        alignItems: "center",
                        justifyContent: "right",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {selectedFilter.value === "ratio"
                        ? `${Math.ceil(
                            (review.positive /
                              (review.negative + review.positive)) *
                              100
                          )}%`
                        : review.positive}
                    </FlexBox>
                  ) : (
                    <></>
                  )}
                </FlexBox>
              )}
            </FlexBox>
          ))
        ) : showLoading ? (
          <TooltipComponent title={loadingTooltipTitle} placement="top">
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <LoadingWrapper bg={`#FFFFFF`} />
            </div>
          </TooltipComponent>
        ) : (
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "17px",
              color: "#3A3C40",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            No topics in selected range
          </FlexBox>
        )}
      </FlexBox>
      {((data.hideLegend && data.subComponent === NEGATIVE) ||
        !data.hideLegend) && (
        <FlexBox
          sx={{
            marginTop: "1rem",
            ...(data.hideLegend ? { marginBottom: "0.5rem" } : {}),
          }}
        >
          <FlexBox
            sx={{
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                background: "#4FAEB0",
                borderRadius: "4px",
                transform: "matrix(1, 0, 0, 1, 0, 0)",
                width: "32px",
                height: "16px",
                marginRight: "1rem",
              }}
            ></FlexBox>
            <FlexBox
              sx={{
                marginRight: "1rem",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              Positive
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                background: "#DB4E82",
                borderRadius: "4px",
                transform: "matrix(1, 0, 0, 1, 0, 0)",
                width: "32px",
                height: "16px",
                marginRight: "1rem",
              }}
            ></FlexBox>
            <FlexBox
              sx={{
                marginRight: "1rem",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              Negative
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </Fragment>
  );
};

export const HorizontalGraphWithReviews = (data: Widget) => {
  return (
    <Fragment>
      {/* {data.hidden ? (
        <TooltipComponent title={loadingTooltipTitle} placement="top">
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingWrapper bg={`#FFFFFF`} />
          </div>
        </TooltipComponent>
      ) : ( */}
      <FlexBox
        sx={{
          justifyContent: "space-between",
          height: "100%",
          marginBottom: "0.5rem",
          ...(data.data.showReviews || data.data.width < 880
            ? { flexDirection: "column" }
            : {}),
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
            height: "100%",
            ...(data.data.showReviews || data.data.width < 880
              ? { width: "100%" }
              : { width: "49%" }),
          }}
        >
          {HorizontalScaleGraph(
            data.positive
              ? {
                  ...data.positive,
                  ...(data.data.showReviews || data.data.width < 880
                    ? { hideLegend: true }
                    : {}),
                }
              : data,
            data.data.setShowReviews,
            !!data.hidden
          )}
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
            ...(data.data.showReviews || data.data.width < 880
              ? { width: "100%", marginTop: "1rem" }
              : { width: "49%" }),
          }}
        >
          {HorizontalScaleGraph(
            data.negative
              ? {
                  ...data.negative,
                  ...(data.data.showReviews || data.data.width < 880
                    ? { hideLegend: true }
                    : {}),
                }
              : data,
            data.data.setShowReviews,
            !!data.hidden
          )}
        </FlexBox>
      </FlexBox>
      {/* )} */}
      <FlexBox
        sx={{
          width: "100%",
          height: "1rem",
          background: "#FFFFFF",
          boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
          borderRadius: "0px 0px 8px 8px",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: 0,
          padding: "0.8rem",
          left: 0,
        }}
      >
        <FlexBox
          sx={{
            fontSize: "13px",
            fontWeight: 600,
            lineHeight: "16px",
            color: "#82868C",
            padding: "0.5rem",
            cursor: "pointer",
          }}
          onClick={() =>
            data.data.setShowReviews &&
            data.data.setShowReviews(!data.data.showReviews)
          }
        >
          {`${data.data.showReviews ? "Hide" : "See"} reviews`}
          <FlexBox
            sx={{
              marginLeft: "4px",
              ...(data.data.showReviews
                ? { transform: "rotate(90deg)" }
                : { transform: "rotate(270deg)" }),
            }}
          >
            <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export const HorizontalGraphWithReviewsComponent = (data: Widget) => (
  <HorizontalScaleGraphWithReviews source={data.source} filter={data.filter} />
);

export const TopRatedRating = (data: Widget) => (
  <TopRatedRatingComponent data={data} />
);

export const getDataArray = (
  cluster: any,
  subCluster: any,
  attribute: string
) => {
  let dataArray: string[] = [];

  if (subCluster && subCluster.sentimentData) {
    subCluster.sentimentData.forEach((sent: any) => {
      if (sent[attribute] && !dataArray.includes(sent[attribute])) {
        dataArray.push(sent[attribute]);
      }
    });

    return dataArray;
  }

  if (!subCluster && cluster && cluster.subClusters) {
    if (cluster.clusterGroup !== "0") {
      cluster.subClusters.forEach((sub: any) => {
        if (sub && sub.sentimentData && sub.sentimentData.length) {
          sub.sentimentData.forEach((sent: any) => {
            if (sent[attribute] && !dataArray.includes(sent[attribute])) {
              dataArray.push(sent[attribute]);
            }
          });
        }
      });
    }

    if (
      cluster.clusterGroup === "0" &&
      cluster.sentimentData &&
      cluster.sentimentData.length
    ) {
      cluster.sentimentData.forEach((sent: any) => {
        if (sent[attribute] && !dataArray.includes(sent[attribute])) {
          dataArray.push(sent[attribute]);
        }
      });
    }

    return dataArray;
  }

  return dataArray;
};

export const getTotalNumberOfVotes = (
  cluster: any,
  subCluster: any,
  store?: string[]
) => {
  let votes = 0;
  let sentimentArray: any[] = [];

  if (subCluster && subCluster.sentimentData) {
    if (!store || (store && store.includes(STORE.aha))) {
      subCluster.sentimentData.forEach((sent: any) => {
        let rating = parseInt(sent.rating);
        if (rating) {
          votes += rating;
        }
      });

      return votes;
    } else {
      return subCluster.sentimentData;
    }
  }

  if (!subCluster && cluster && cluster.subClusters) {
    if (cluster.clusterGroup !== "0") {
      cluster.subClusters.forEach((sub: any) => {
        if (sub && sub.sentimentData && sub.sentimentData.length) {
          if (!store || (store && store.includes(STORE.aha))) {
            sub.sentimentData.forEach((sent: any) => {
              let rating = parseInt(sent.rating);
              if (rating) {
                votes += rating;
              }
            });
          } else {
            sentimentArray = sentimentArray.concat(sub.sentimentData);
          }
        }
      });
    }
    if (
      cluster.clusterGroup === "0" &&
      cluster.sentimentData &&
      cluster.sentimentData.length
    ) {
      if (!store || (store && store.includes(STORE.aha))) {
        cluster.sentimentData.forEach((sent: any) => {
          let rating = parseInt(sent.rating);
          if (rating) {
            votes += rating;
          }
        });
      } else {
        sentimentArray = sentimentArray.concat(cluster.sentimentData);
      }
    }

    return votes ? votes : sentimentArray;
  }

  return votes ? votes : sentimentArray;
};

export const topicDetailsComponent = (data: Widget) => {
  const dataArray = getDataArray(
    data.data.cluster,
    data.data.subCluster,
    "workspaceName"
  );

  const ideaArray = getDataArray(
    data.data.cluster,
    data.data.subCluster,
    "ideaCategory"
  );

  const store =
    data && data.source ? data.source.map((d: GET_DATA_STORE) => d.store) : [];

  const sentimentArray = getTotalNumberOfVotes(
    data.data.cluster,
    data.data.subCluster,
    store
  );

  const sentimentAnalysedCluster = data.data.cluster
    ? createSentimentCluster(data.data.cluster)
    : { positive: 0, negative: 0 };

  return (
    <Fragment>
      <FlexBox>
        <FlexBox
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 600,
            textTransform: "uppercase",
            //marginBottom: "1rem",
          }}
        >
          {`${data.data.title ? `${data.data.title} ` : ""}Details`}
        </FlexBox>
        {data.help ? (
          <TooltipComponent title={data.help} placement={"top"}>
            <div
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            >
              <img src={InfoIcon} alt="i" width={12} height={12} />
            </div>
          </TooltipComponent>
        ) : (
          <></>
        )}
      </FlexBox>

      {data.data && data.data.cluster && data.data.cluster.groupTopic ? (
        <Fragment>
          <FlexBox
            sx={{
              fontSize: "15px",
              lineHeight: "18px",
              color: "#0B0E1E",
              marginTop: "0.8rem",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                maxWidth: "36%",
                marginRight: "1rem",
                width: "fit-content",
              }}
            >
              {data.data.cluster.groupTopic
                ? data.data.cluster.groupTopic.replace(/(^"|"|"|'|'|'$)/g, "")
                : ""}
            </FlexBox>
            {data.data &&
            data.data.subCluster &&
            data.data.subCluster.clusterTopics ? (
              <Fragment>
                <FlexBox
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {" — "}
                </FlexBox>
                <FlexBox
                  sx={{
                    fontWeight: 500,
                    width: "36%",
                    marginLeft: "1rem",
                  }}
                >
                  {`${data.data.subCluster.clusterTopics.replace(
                    /(^"|"|"|'|'|'$)/g,
                    ""
                  )}`}
                </FlexBox>
              </Fragment>
            ) : (
              <></>
            )}
            {store && store.length !== 0 && !store?.includes(STORE.aha) ? (
              <FlexBox
                sx={{
                  maxWidth: "20%",
                  minWidth: "15%",
                  width: "fit-content",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#0B0E1E",
                    background: "#D7E5FA",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    fontSize: "10px",
                    lineHeight: "14px",
                  }}
                >
                  {`${sentimentArray.length} total ${
                    store && store.length && store.includes(STORE.sof)
                      ? "question"
                      : "review"
                  }${sentimentArray.length > 1 ? "s" : ""}`}
                </FlexBox>
              </FlexBox>
            ) : (
              <></>
            )}
          </FlexBox>

          {/* Description Section */}
          {store &&
          store.length !== 0 &&
          !store?.includes(STORE.aha) &&
          data.data &&
          data.data.cluster &&
          data.data.cluster.groupSummary ? (
            <Fragment>
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {"Description"}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                  flexWrap: "wrap",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "16px",
                  justifyContent: "space-between",
                  color: "#000000",
                  width: "100%",
                }}
              >
                {data.data.subCluster && data.data.subCluster.clusterSummary
                  ? data.data.subCluster.clusterSummary.replace(
                      /(^"|"|"|'|'|'$)/g,
                      ""
                    )
                  : data.data.cluster.groupSummary.replace(
                      /(^"|"|"|'|'|'$)/g,
                      ""
                    )}
              </FlexBox>{" "}
            </Fragment>
          ) : (
            <></>
          )}

          {/* WorkSpace Section */}
          {dataArray && dataArray.length !== 0 && store?.includes(STORE.aha) ? (
            <>
              {" "}
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {"Workspaces"}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                  flexWrap: "wrap",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "16px",
                  justifyContent: "space-between",
                  width: "100%",
                  marginLeft: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    width: "49%",
                    flexDirection: "column",
                  }}
                >
                  {dataArray
                    .slice(0, (dataArray.length + 1) / 2)
                    .map((workspace: string, index: number) => (
                      <FlexBox
                        key={`workspace-name-${index}-${workspace}-${Math.random()}`}
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "16px",
                          display: "list-item",
                          listStyleType: "disc",
                          marginBottom: "0.3rem",
                          width: "100%",
                        }}
                      >
                        {/* <FormattedText text={workspace} /> */}
                        {workspace}
                      </FlexBox>
                    ))}
                </FlexBox>
                <FlexBox
                  sx={{
                    width: "49%",
                    flexDirection: "column",
                  }}
                >
                  {dataArray
                    .slice((dataArray.length - 1) / 2 + 1, dataArray.length)
                    .map((workspace: string, index: number) => (
                      <FlexBox
                        key={`workspace-name-${2 * (index + 1) + 1}`}
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "16px",
                          display: "list-item",
                          listStyleType: "disc",
                          marginBottom: "0.3rem",
                          width: "100%",
                        }}
                      >
                        {/* <FormattedText text={workspace} /> */}
                        {workspace}
                      </FlexBox>
                    ))}
                </FlexBox>
              </FlexBox>
            </>
          ) : (
            <></>
          )}

          {/* Votes section */}
          {/* Restricting to only AHA store */}
          {store && store.length !== 0 && store?.includes(STORE.aha) ? (
            <>
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {`Total number of ${
                    store?.includes(STORE.aha) ? "votes" : "reviews"
                  }`}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#0B0E1E",
                    background: "#D7E5FA",
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  {sentimentArray}
                </FlexBox>
              </FlexBox>
            </>
          ) : (
            <></>
          )}

          {/* Average rating section for Normal App flow */}
          {store &&
          store.length !== 0 &&
          !store?.includes(STORE.aha) &&
          !store?.includes(STORE.sof) ? (
            <>
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {`Average rating`}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#0B0E1E",
                    background: "#D7E5FA",
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  {starRatingScore(data.filter!, sentimentArray)}
                </FlexBox>
              </FlexBox>
            </>
          ) : (
            <></>
          )}

          {/* Sentiment analysis section */}
          {store &&
          store.length !== 0 &&
          !store?.includes(STORE.aha) &&
          !store?.includes(STORE.sof) ? (
            <>
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {`Sentiment analysis`}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#0B0E1E",
                    background: "#4FAEB0",
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  {`Positive Reviews: ${sentimentAnalysedCluster.positive}`}
                </FlexBox>
                <FlexBox
                  sx={{
                    color: "#0B0E1E",
                    background: "#DB4E82",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "0.5rem",
                  }}
                >
                  {`Negative Reviews: ${sentimentAnalysedCluster.negative}`}
                </FlexBox>
              </FlexBox>
            </>
          ) : (
            <></>
          )}

          {/* Idea Section */}
          {ideaArray && ideaArray.length !== 0 && store?.includes(STORE.aha) ? (
            <>
              <FlexBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0.5rem 0",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.3rem",
                    color: "#3A3C40",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {"Idea Categories"}
                </FlexBox>
                <FlexBox
                  sx={{
                    flexGrow: 1,
                    height: "1px",
                    background: "#E3E6EB",
                  }}
                ></FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  marginBottom: "0.5rem",
                  flexWrap: "wrap",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {ideaArray.map((idea: string, index: number) => (
                  <FlexBox
                    key={`idea-name-${index}`}
                    sx={{
                      color: "#0B0E1E",
                      background: "#E3E6EB",
                      borderRadius: "4px",
                      padding: "2px 6px",
                      marginRight: "0.4rem",
                      marginBottom: "0.4rem",
                    }}
                  >
                    {idea}
                  </FlexBox>
                ))}
              </FlexBox>
            </>
          ) : (
            <></>
          )}
        </Fragment>
      ) : (
        <FlexBox
          sx={{
            fontSize: "14px",
            lineHeight: "17px",
            color: "#3A3C40",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          No topic selected
        </FlexBox>
      )}
    </Fragment>
  );
};
