import FlexBox from "../shared/wrappers/FlexBox";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import {
  DATASOURCE_PAGE,
  SETTINGS_PAGE,
  setWithExpiry,
  STORE,
} from "../shared/constants";
import axios from "axios";

const SlackRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getToken, setGetToken] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      if (location && location.search) {
        let search = location.search;
        if (!search.includes("error")) {
          let hashArray = search.replace("?", "").split("&");
          const foundIndex = hashArray.findIndex((item: string) =>
            item.includes("code")
          );

          if (foundIndex !== -1) {
            try {
              const access_code = hashArray[foundIndex].split("=")[1];

              // Get the access token from the code
              const response: any = await axios.get(
                `https://slack.com/api/oauth.v2.access?code=${access_code}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&client_secret=${process.env.REACT_APP_SLACK_CLIENT_SECRET}&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}`
              );

              if (
                response &&
                response.data &&
                response.data.authed_user &&
                response.data.authed_user.access_token
              ) {
                let access_token = response.data.authed_user.access_token;
                setWithExpiry("slack-token", access_token, 300);

                // Find the page where the user was redirected from
                // If datasource-page, then continue with navigation
                // If settings-page, then navigate to settings page with the state
                const foundPageIndex = hashArray.findIndex((item: string) =>
                  item.includes("state")
                );
                if (foundPageIndex !== -1) {
                  const page = hashArray[foundPageIndex].split("=")[1];
                  if (page === `${SETTINGS_PAGE}-page`) {
                    // navigate to settings page with the state
                    navigate("/dashboard/settings", {
                      state: {
                        selectedDataSource: STORE.slack,
                      },
                    });
                  } else if (page === `${DATASOURCE_PAGE}-page`) {
                    navigate("/dashboard/add-slack");
                  }
                }
              }
            } catch (e) {
              console.log("Some error happened");
            }
          }
        }

        // Logic if the user cancels during the process.
        if (search.includes("error")) {
          // Get the error data
          let hashArray = search.replace("?", "").split("&");
          const foundIndex = hashArray.findIndex((item: string) =>
            item.includes("error")
          );
          const foundPageIndex = hashArray.findIndex((item: string) =>
            item.includes("state")
          );

          if (foundIndex !== -1 && foundPageIndex !== -1) {
            // Check if access_denied
            const error = hashArray[foundIndex].split("=")[1];

            if (error === "access_denied") {
              // Get the page
              const page = hashArray[foundPageIndex].split("=")[1];

              if (page === `${SETTINGS_PAGE}-page`) {
                // navigate to settings page with the state
                navigate("/dashboard/settings", {});
              } else if (page === `${DATASOURCE_PAGE}-page`) {
                navigate("/dashboard/add-slack");
              }
            }
          }
        }
      }
    };

    if (!getToken) {
      setGetToken(true);
      getAccessToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <LoadingWrapper />
      {/* <a
        href="https://slack.com/oauth/v2/authorize?scope=&amp;user_scope=channels%3Ahistory%2Cchannels%3Aread%2Cusergroups%3Aread%2Cusers%3Aread%2Cusers%3Aread.email&amp;redirect_uri=https%3A%2F%2Fwww.rargusapp.com%2Fdashboard%2Fslack-redirect&amp;client_id=3129721873605.5594349010579"
        style={{
          alignItems: "center",
          color: "#000",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          borderRadius: "4px",
          display: "inline-flex",
          fontFamily: "Lato, sans-serif",
          fontSize: "18px",
          fontWeight: 600,
          height: "56px",
          justifyContent: "center",
          textDecoration: "none",
          width: "56px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            height: "28px",
            width: "28px",
            marginRight: 0,
          }}
          viewBox="0 0 122.8 122.8"
        >
          <path
            d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
            fill="#e01e5a"
          ></path>
          <path
            d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
            fill="#36c5f0"
          ></path>
          <path
            d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
            fill="#2eb67d"
          ></path>
          <path
            d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
            fill="#ecb22e"
          ></path>
        </svg>
      </a> */}
    </FlexBox>
  );
};

export default SlackRedirect;
