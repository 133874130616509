import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import {
  Filter,
  FilterTextPopover,
  AddWidgetsFilterPopover,
} from "../shared/wrappers/FilterPopper";
import WidgetContainer from "./WidgetContainer";
import {
  filterOptions,
  FilterOption,
  updateLayoutAndUserWidgets,
  getRouteName,
  getUuid,
  getEditorUserRoleAccess,
} from "../shared/constants";
import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import {
  AppActions,
  WidgetId,
} from "../../services/store/AppStore/AppStoreSlice";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import AddWidgetIcon from "../shared/icons/add-widget.svg";
import { useLocation, useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../shared/icons/delete.svg";
import { getWidgetList, WIDGET_IDS } from "../shared/widgetConstants";
import DeleteModal from "../shared/wrappers/DeleteModal";
import TooltipComponent from "../shared/wrappers/Tooltip";
import DragSettingIcon from "../shared/icons/drag-setting.svg";
import DashboardSettingsModal from "../shared/wrappers/DashboardSettingsModal";

const GenericDashboard = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editDashboard, setEditDashboard] = useState<boolean>(false);

  const appData: any = useSelector((state: RootState) => state.app);

  const [showDelete, setShowDelete] = useState(false);

  const [widgets, setWidgets] = useState<Filter[]>([]);
  const [widgetArray, setWidgetArray] = useState<Filter[]>([]);

  let type =
    props && props.match && props.match.params && props.match.params.path
      ? props.match.params.path
      : "";
  if (
    type === "" &&
    location.pathname &&
    location.pathname.includes("insights/")
  ) {
    let pathSplit = location.pathname.split("/");
    type = pathSplit[pathSplit.length - 1];
  }

  // Widget array
  useEffect(() => {
    if (
      widgets &&
      widgets.length === 0 &&
      widgetArray &&
      widgetArray.length === 0 &&
      appData.widgetLayout &&
      appData.widgetLayout.dashboard &&
      appData.widgetLayout.dashboard.widgets &&
      appData.widgetLayout.dashboard.widgets.length !== 0
    ) {
      const distinctWidgetList = getWidgetList(appData, "id");

      setWidgetArray(
        distinctWidgetList.map((widget: WidgetId) => ({
          label: widget.label,
          value: widget.id.toLowerCase(),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.widgetLayout]);

  // useEffect to change widgets to be shown on page
  useEffect(() => {
    if (appData.widgetLayout[type] && appData.widgetLayout[type].widgets) {
      // Selected widgets are changed
      setWidgets(
        appData.widgetLayout[type].widgets.map((widget: WidgetId) => ({
          label: widget.label,
          value: widget.id.toLowerCase(),
        }))
      );
    }
  }, [appData.widgetLayout, type, dispatch]);

  useEffect(() => {
    if (
      appData.widgetLayout[type] &&
      (appData.widgetLayout[type].name === undefined ||
        appData.widgetLayout[type].name === "")
    ) {
      setEditDashboard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOtherDataBasedOnWidgets = () => {
    if (
      appData.widgetLayout &&
      appData.widgetLayout[type] &&
      appData.widgetLayout[type].widgets &&
      appData.widgetLayout[type].widgets.length
    ) {
      // Determine required items based on the selected widget and add it
      let returnObject = {};
      appData.widgetLayout[type].widgets.forEach((widget: WidgetId) => {
        switch (widget.id) {
          case WIDGET_IDS.dashboardInsights: {
            returnObject = {
              ...returnObject,
            };
          }
        }
      });
    }
  };

  const closeDashboardSettingsHandler = (value?: boolean) => {
    if (!value) {
      // Close the widget
      dispatch(AppActions.setDashboardSettings(undefined));
      setEditDashboard(false);
    }
  };

  return (
    <Fragment>
      <DashboardLayoutWrapper>
        <FlexBox
          sx={{
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            background: "#F5F5F5",
            width: "100%",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          }}
          key={`generic-dashboard-container-${type}`}
        >
          <FlexBox
            sx={{
              width: "90%",
              height: "95%",
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "space-between",
                marginBottom: "1.5rem",
                alignItems: "center",
              }}
            >
              <FlexBox>
                <FlexBox
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#3579E3",
                  }}
                >
                  {appData.widgetLayout &&
                  appData.widgetLayout[type] &&
                  (appData.widgetLayout[type].name === undefined ||
                    appData.widgetLayout[type].name === "") ? (
                    <FlexBox
                      sx={{
                        width: "10rem",
                      }}
                    >
                      <TextField
                        fullWidth
                        id="input-with-sx"
                        label=""
                        variant="standard"
                        placeholder="Input name ..."
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiFormLabel-root": {
                            color: "#3579E3",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#3579E3",
                          },
                          "& .MuiInputBase-input-MuiInput-input": {
                            color: "#3579E3",
                          },
                        }}
                        onKeyDown={(e: any) => {
                          if (e.keyCode === 13 && e.target.value) {
                            // enableSearch(e.target.value);
                            let userWidgets = { ...appData.widgetLayout };
                            delete userWidgets[type];
                            userWidgets[getRouteName(e.target.value)] = {
                              ...appData.widgetLayout[type],
                              name: e.target.value,
                            };
                            updateLayoutAndUserWidgets(userWidgets, dispatch);
                            navigate(
                              location.pathname.replace(
                                type,
                                getRouteName(e.target.value)
                              )
                            );
                            e.target.value = "";
                          }
                        }}
                      />
                    </FlexBox>
                  ) : appData.widgetLayout[type] &&
                    appData.widgetLayout[type].name ? (
                    appData.widgetLayout[type].name
                  ) : (
                    ""
                  )}
                </FlexBox>
                {getEditorUserRoleAccess(appData.userRole) ? (
                  <Fragment>
                    <TooltipComponent title="Delete dashboard">
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "0.8rem",
                          marginTop: "0.3rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowDelete(true)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Delete"
                          width="16"
                          height="16"
                        />
                      </div>
                    </TooltipComponent>
                    <FlexBox
                      sx={{
                        fontWeight: "600",
                        fontSize: "13px",
                        lineHeight: "16px",
                        color: "#82868C",
                        margin: "0.2rem 0 0 1rem",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setEditDashboard(!editDashboard)}
                    >
                      {editDashboard ? "Finish editing" : "Edit dashboard"}
                    </FlexBox>
                  </Fragment>
                ) : (
                  <></>
                )}
              </FlexBox>

              <FlexBox>
                {editDashboard ? (
                  <FlexBox
                    sx={{
                      fontWeight: "600",
                      fontSize: "13px",
                      lineHeight: "16px",
                      color: "#82868C",
                      margin: "0.2rem 0 0 1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    // onClick={() => setEditDashboard(!editDashboard)}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "0.8rem",
                      }}
                      onClick={() => {
                        dispatch(
                          AppActions.setDashboardSettings({
                            id: type,
                            label: appData.widgetLayout[type].name,
                            ...(appData.widgetLayout[type].source &&
                            appData.widgetLayout[type].source.length
                              ? { source: appData.widgetLayout[type].source }
                              : {}),
                            ...(appData.widgetLayout[type].reset
                              ? { reset: appData.widgetLayout[type].reset }
                              : {}),
                            ...(appData.widgetLayout[type].filter
                              ? { filter: appData.widgetLayout[type].filter }
                              : {}),
                          })
                        );
                      }}
                    >
                      <img
                        src={DragSettingIcon}
                        alt={"setting"}
                        width={"16px"}
                        height={"16px"}
                        style={{
                          marginRight: "0.3rem",
                        }}
                      />
                      Update settings
                    </FlexBox>
                    <FlexBox
                      sx={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {getEditorUserRoleAccess(appData.userRole) ? (
                        <AddWidgetsFilterPopover
                          selectedFilter={widgets}
                          setSelectedFilter={(filter: Filter[]) =>
                            // setWidgets(filter)
                            {
                              const userWid = {
                                ...appData.widgetLayout,
                                [type]: {
                                  ...appData.widgetLayout[type],

                                  widgets: [
                                    ...appData.widgetLayout[type].widgets,
                                    ...filter.map((item: any) => ({
                                      id: item.value,
                                      widgetId: `${item.value}--${getUuid()}`,
                                      label: item.label,
                                    })),
                                  ],
                                },
                              };
                              updateLayoutAndUserWidgets(userWid, dispatch);
                            }
                          }
                          filterOptions={widgetArray}
                          width={"16rem"}
                          label={<FlexBox>{"Keywords"}</FlexBox>}
                          internalSearch={true}
                          disableSelectAll={true}
                          enableMultiselect={true}
                          popOffset={10.2}
                        >
                          <FlexBox
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={AddWidgetIcon}
                              alt={"add"}
                              width={"16px"}
                              height={"16px"}
                              style={{
                                marginRight: "0.3rem",
                              }}
                            />
                            {"Add widget"}
                          </FlexBox>
                        </AddWidgetsFilterPopover>
                      ) : (
                        <></>
                      )}
                    </FlexBox>
                  </FlexBox>
                ) : (
                  <FilterTextPopover
                    filterOptions={filterOptions}
                    selectedFilter={appData.filter}
                    setSelectedFilter={(filter: FilterOption) =>
                      dispatch(AppActions.setGlobalFilter(filter))
                    }
                  />
                )}
              </FlexBox>
            </FlexBox>
            {type && type !== "" ? (
              <FlexBox
              // sx={{
              //   height: "100%",
              // }}
              >
                {appData.store && appData.store !== "" ? (
                  <WidgetContainer
                    widgets={
                      appData.widgetLayout &&
                      appData.widgetLayout[type] &&
                      appData.widgetLayout[type].widgets &&
                      appData.widgetLayout[type].widgets.length
                        ? appData.widgetLayout[type].widgets.map(
                            (widget: WidgetId) => ({
                              ...widget,
                              // Add the dashboard level source to all widgets
                              ...(appData.widgetLayout[type] &&
                              appData.widgetLayout[type].source
                                ? // Check if user selected to reset the widget source
                                  (appData.widgetLayout[type] &&
                                    appData.widgetLayout[type].reset &&
                                    appData.widgetLayout[type].reset ===
                                      "yes") ||
                                  (appData.widgetLayout[type] &&
                                    appData.widgetLayout[type].reset &&
                                    appData.widgetLayout[type].reset === "no" &&
                                    widget &&
                                    !widget.source)
                                  ? {
                                      source: appData.widgetLayout[type].source,
                                      filter: appData.widgetLayout[type].filter,
                                    }
                                  : // DO nothing if the widget source is to be used. Source already belongs to widget property
                                    appData.widgetLayout[type] &&
                                    appData.widgetLayout[type].reset &&
                                    appData.widgetLayout[type].reset === "no" &&
                                    widget &&
                                    widget.source &&
                                    {}
                                : {}),
                            })
                          )
                        : []
                    }
                    otherData={getOtherDataBasedOnWidgets()}
                    type={type}
                    hideDraggable={!editDashboard}
                  />
                ) : (
                  <LoadingWrapper bg="#F5F5F5" />
                )}
              </FlexBox>
            ) : (
              <></>
            )}
          </FlexBox>
        </FlexBox>
      </DashboardLayoutWrapper>
      {showDelete ? (
        <DeleteModal
          onClose={() => {
            setShowDelete(false);
            let userWidgets = { ...appData.widgetLayout };
            delete userWidgets[type];
            updateLayoutAndUserWidgets(userWidgets, dispatch);
            navigate("/dashboard/insights/feedback");
          }}
          title={"Delete the dashboard?"}
          content={
            <FlexBox
              sx={{
                display: "-webkit-box",
              }}
            >
              Deleting{" "}
              <b style={{ margin: "0 0.3rem" }}>
                {appData.widgetLayout[type] && appData.widgetLayout[type].name
                  ? appData.widgetLayout[type].name
                  : type}
              </b>
              cannot be undone.
            </FlexBox>
          }
          show={showDelete}
          setShow={setShowDelete}
        ></DeleteModal>
      ) : (
        <></>
      )}
      {appData && appData.dashboardSettings ? (
        <DashboardSettingsModal
          onClose={() => {
            closeDashboardSettingsHandler();
          }}
          title={"Dashboard settings"}
          show={
            appData.dashboardSettings && appData.dashboardSettings.id
              ? true
              : false
          }
          setShow={closeDashboardSettingsHandler}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default GenericDashboard;
