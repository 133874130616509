import { Card, Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  COMBINED_DATA_SOURCE,
  COMBINED_DATA_SOURCE_TYPE,
} from "../shared/wrappers/WidgetSettingsModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect, useState } from "react";
import {
  getUserAppIDs,
  getUserRights,
  setUserDataAndRole,
  STORE,
} from "../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../services/api/Graphql.api";
import { RootState } from "../../services/store/store";

const DataSource = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const setUserData = async (email: string) => {
      const res: any = await getUserDetails(email);
      setCheckApp(false);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);

        // Getting appID on org level or individual level
        const appId = await getUserAppIDs(res.getUser);
        if (appId && appId.length && appId !== "[]") {
          localStorage.setItem("appId", appId);
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.user]);

  const [selectedDataSource, setSelectedDataSource] = useState(
    STORE["apple-play-store"]
  );

  const userRights = getUserRights(appData.user);

  const appWidget = (source: COMBINED_DATA_SOURCE_TYPE) => {
    if (
      (source.value === STORE.github || source.value === STORE.amazon) &&
      (!userRights || (userRights && !userRights.githubAccess))
    ) {
      return <></>;
    }
    return (
      <FlexBox
        key={`data-source-app-${source.label}-${source.label}`}
        sx={{
          border: "1px solid #82868C",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.5rem",
          marginRight: "1rem",
          marginBottom: "1rem",
          cursor: "pointer",
          position: "relative",
          minWidth: "7rem",
          ...(selectedDataSource && selectedDataSource === source.value
            ? { background: "#E3E6EB" }
            : { background: "#FFFFFF" }),
        }}
        onClick={() => setSelectedDataSource(source.value)}
      >
        {source.icon && Array.isArray(source.icon) ? (
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
            key={`${source.value}--first--second--icon`}
          >
            <LazyLoadImage
              key={`${source.value}--first--icon`}
              alt={"Store"}
              src={source.icon[0]}
              height={"32"}
              style={{ marginRight: "0.8rem" }}
            />
            <LazyLoadImage
              key={`${source.value}--second--icon`}
              alt={"Store"}
              src={source.icon[1]}
              height={"32"}
              style={{ marginRight: "0.5rem" }}
            />
          </FlexBox>
        ) : // Setting individual styling for zapier to increase the size beyond the box provided by the parent component
        source.value === STORE.zapier ? (
          <LazyLoadImage
            key={`${source.value}--icon`}
            alt={"Store"}
            src={source.icon}
            width={source.width ? source.width : 32}
            style={{ marginRight: "0.5rem", position: "absolute" }}
          />
        ) : (
          <LazyLoadImage
            key={`${source.value}--icon`}
            alt={"Store"}
            src={source.icon}
            height={"32"}
            style={{ marginRight: "0.5rem" }}
          />
        )}
      </FlexBox>
    );
  };

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",

          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Choose the data source
          </FlexBox>
          <Box
            sx={{
              mt: 4,
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
              height: "fit-content",
            }}
          >
            Please select a data source to continue.
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {Object.keys(COMBINED_DATA_SOURCE).map((item: string) =>
              appWidget(COMBINED_DATA_SOURCE[item])
            )}
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => {
                // Based on selected app
                if (selectedDataSource === STORE["apple-play-store"]) {
                  navigate("/dashboard/add-app");
                }

                if (selectedDataSource === STORE.aha) {
                  navigate("/dashboard/add-aha");
                }

                if (selectedDataSource === STORE.zapier) {
                  navigate("/dashboard/zapier-template");
                }

                if (selectedDataSource === STORE.slack) {
                  // window.location.href = `https://slack.com/oauth/v2/authorize?scope=&user_scope=${process.env.REACT_APP_SLACK_USER_SCOPES_PRIVATE}&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&state=${DATASOURCE_PAGE}-page`;
                  navigate("/dashboard/add-slack");
                }

                if (selectedDataSource === STORE.amazon) {
                  navigate("/dashboard/add-amazon");
                }

                if (selectedDataSource === STORE.github) {
                  navigate("/dashboard/add-github-issues");
                }
              }}
              disabled={selectedDataSource === "" ? true : false}
            >
              Next
            </Button>
          </Box>
          <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 500,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => {
                navigate(`/dashboard/home`);
              }}
            >
              {"Back"}
            </Button>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default DataSource;
