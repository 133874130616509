import { useState, useEffect, Fragment } from "react";
import { Widget } from "../Insights/WidgetContainer";
import { getFilterValues } from "../shared/constants";
import FlexBox from "../shared/wrappers/FlexBox";
import FormattedText from "./FormattedText";
import UnfilledStar from "../shared/icons/unfilled-star.svg";

const TopRatedRatingComponent = ({ data }: { data: Widget }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const constructData = (
    data: any,
    filter: { label: string; value: string }
  ) => {
    const { expression } = getFilterValues({
      filter,
    });

    // Construct Data Array and set
    let filterData: any[] = [];
    data.forEach((cluster: any) => {
      let rating = 0;
      cluster.subClusters.forEach((sub: any) => {
        if (expression(sub.date)) {
          rating += sub.rating;
        }
      });
      filterData.push({
        ...cluster,
        rating: (rating / cluster.subClusters.length).toFixed(1),
      });
    });

    // Set the filtered Data based on global filter change
    setFilteredData(filterData);
  };

  useEffect(() => {
    if (data.data && data.filter) {
      constructData(data.data, data.filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, data.filter]);

  return (
    <Fragment>
      <FlexBox
        sx={{
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 600,
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
      >
        {`Topics`}
      </FlexBox>
      <FlexBox
        sx={{
          width: "100%",
          justifyContent: "space-between",
          //flexDirection: "column",
        }}
      >
        <FlexBox
          sx={{
            width: "49%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHEight: "14px",
              color: "#3A3C40",
              marginBottom: "0.8rem",
            }}
          >
            Highest rated
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHEight: "12px",
              color: "#2A2E37",
              flexDirection: "column",
            }}
          >
            {[...filteredData]
              .sort((a: any, b: any) => b.rating - a.rating)
              .slice(0, 5)
              .map((high: any, index: number) => (
                <FlexBox key={`hight-rating-${index}`}>
                  <FlexBox
                    sx={{
                      background: "#E3E6EB",
                      borderRadius: "4px",
                      width: "3rem",
                      height: "20px",
                      padding: "1px 6px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "0.4rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {high.rating}
                    <img
                      src={UnfilledStar}
                      alt="*"
                      width="9px"
                      height="9px"
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </FlexBox>
                  <FlexBox>
                    {/* To be replaced with group topic name */}
                    <FormattedText text={high.clusterTopics} />
                  </FlexBox>
                </FlexBox>
              ))}
          </FlexBox>
        </FlexBox>
        <FlexBox
          sx={{
            width: "49%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHEight: "14px",
              color: "#3A3C40",
              marginBottom: "0.8rem",
            }}
          >
            Lowest rated
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHEight: "12px",
              color: "#2A2E37",
              flexDirection: "column",
            }}
          >
            {[...filteredData]
              .sort((a: any, b: any) => a.rating - b.rating)
              .slice(0, 5)
              .map((low: any, index: number) => (
                <FlexBox key={`low-rating-${index}`}>
                  <FlexBox
                    sx={{
                      background: "#E3E6EB",
                      borderRadius: "4px",
                      width: "3rem",
                      height: "20px",
                      padding: "1px 6px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "0.4rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {low.rating}
                    <img
                      src={UnfilledStar}
                      alt="*"
                      width="9px"
                      height="9px"
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </FlexBox>
                  <FlexBox>
                    {/* To be replaced with group topic name */}
                    <FormattedText text={low.clusterTopics} />
                  </FlexBox>
                </FlexBox>
              ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export default TopRatedRatingComponent;
