import { LoadingButton } from "@mui/lab";
import { Select, MenuItem, Box, Button, FormHelperText } from "@mui/material";
import { useState } from "react";
import { ADMIN_MANAGEMENT_TYPE, FilterOption } from "../shared/constants";
import FlexBox from "../shared/wrappers/FlexBox";
import LightTextField from "../shared/wrappers/LightTextField";
import {
  TextFieldWrapper,
  FullTextFieldWrapper,
} from "../shared/wrappers/StyledComponents";
import { Paragraph } from "../shared/wrappers/Typography";
import { USER_RIGHT, USER_ROLES } from "./AdminManagement";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createOrganizationUser } from "../../services/api/Apps.api";

type CreateNewUserProps = {
  setAddNewUser: (value: boolean) => void;
  userList: USER_RIGHT[];
  setUserList: (value: USER_RIGHT[]) => void;
  currentUser: USER_RIGHT;
  appID: string;
};

const CreateNewUser = ({
  setAddNewUser,
  userList,
  setUserList,
  currentUser,
  appID,
}: CreateNewUserProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const initialValues: {
    name: string;
    email: string;
    role: string;
  } = {
    name: "",
    email: "",
    role: "",
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    role: Yup.string().required("Role is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);

        // Call the lambda handler for adding the user
        try {
          const response = await createOrganizationUser({
            admin: currentUser.email,
            appID: appID,
            name: values.name,
            email: values.email,
            role: values.role,
            organization:
              currentUser && currentUser.organization
                ? currentUser.organization
                : "",
            type: ADMIN_MANAGEMENT_TYPE.create,
          });
          if (response && response["error"]) {
            setError(JSON.stringify(response["error"]));
            setLoading(false);
          } else {
            setUserList([
              ...userList,
              {
                name: values.name,
                email: values.email,
                role: values.role,
                organization:
                  currentUser && currentUser.organization
                    ? currentUser.organization
                    : "",
              },
            ]);
            setLoading(false);
            setAddNewUser(false);
          }
        } catch (e: any) {
          if (e && e.error) {
            setError(JSON.stringify(e.error));
          }

          setLoading(false);
        }
      },
    });

  return (
    <FlexBox
      sx={{
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <FlexBox
        sx={{
          height: "100%",
        }}
      >
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <FlexBox
              sx={{
                flexDirection: "column",
              }}
            >
              <FlexBox justifyContent="space-between" flexWrap="wrap">
                <TextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    {"Name"}
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name={"name"}
                    type={"name"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name || ""}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    disabled={initialValues.name === "" ? false : true}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    {"Email"}
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name={"email"}
                    type={"email"}
                    onBlur={handleBlur}
                    onChange={(event: any) => {
                      if (error && error !== "") {
                        setError("");
                      }
                      handleChange(event);
                    }}
                    value={values.email || ""}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    disabled={initialValues.email === "" ? false : true}
                  />
                </TextFieldWrapper>
              </FlexBox>
              <FullTextFieldWrapper>
                <Paragraph fontWeight={600} mb={1} mt={1}>
                  Role
                </Paragraph>
                <Select
                  fullWidth
                  labelId="role"
                  id="role"
                  name={"role"}
                  value={values.role}
                  multiple={false}
                  label=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.role && errors.role)}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #E5EAF2",
                  }}
                  MenuProps={{
                    sx: {
                      zIndex: 99999999,
                      height: "18rem",
                      // maxHeight: "15rem",
                      // overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: "0.4rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#A3A6AB",
                        borderRadius: "5px",
                      },
                    },
                  }}
                >
                  {USER_ROLES.map((item: FilterOption, index: number) => (
                    <MenuItem
                      value={item.value}
                      key={`${item}--menu-item-${index}`}
                    >
                      <FlexBox
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        <FlexBox
                          sx={{
                            width: "80%",
                          }}
                        >
                          <FlexBox
                            sx={{
                              color: "#0B0E1E",
                              fontSize: "13px",
                              lineHeight: "16px",
                              fontStyle: "normal",
                            }}
                          >
                            {item.label}
                          </FlexBox>
                        </FlexBox>
                        <FlexBox
                          sx={{
                            width: "10%",
                          }}
                        ></FlexBox>
                      </FlexBox>
                    </MenuItem>
                  ))}
                </Select>
              </FullTextFieldWrapper>
            </FlexBox>
            {error && error !== "" && (
              <FormHelperText
                error
                sx={{
                  // mt: 1,
                  fontSize: 13,
                  fontWeight: 500,
                  // textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}
            <Box
              sx={{
                mt: 1,
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  {"Adding app..."}
                </LoadingButton>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  Add new account
                </Button>
              )}
            </Box>
          </FlexBox>
        </form>
      </FlexBox>

      <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
        <Button
          type="button"
          variant="outlined"
          sx={{
            fontWeight: 500,
            border: "none",
            "&:hover": { border: "none", background: "#FFF" },
          }}
          onClick={() => {
            setAddNewUser(false);
          }}
        >
          {"Back"}
        </Button>
      </Box>
    </FlexBox>
  );
};

export default CreateNewUser;
