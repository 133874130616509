import { Box, Button, Card } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import utf8 from "utf8";
import PreFillZap from "../../ZapierIntegration/PreFillZap";
import { getHashedAPIKey } from "../constants";
import FlexBox from "./FlexBox";
import "./Modal.css";
import CopyToClipboardButton from "./CopyToClipboard";

const ZapierEditorModal = (props: {
  onClose: () => void;
  onSave: () => void;
  url: string;
  show: boolean;
  setShow: (value: boolean) => void;
  children?: any;
}) => {
  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.setShow(false);
    }

    if ((e.charCode || e.keyCode) === 13) {
      props.onClose && props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [token, setToken] = useState("");

  const encryptData = () => {
    setToken(btoa(utf8.encode(getHashedAPIKey())));
  };

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "90%",
          marginTop: "3rem",
          marginBottom: "3rem",
          height: "90%",
        }}
      >
        <Card
          sx={{
            padding: 4,
            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",
            width: "100%",
            height: "100%",
          }}
        >
          <FlexBox
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <PreFillZap url={props.url} />

            <Box
              sx={{
                mt: 4,
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {token === "" ? (
                <Button type="button" variant="contained" onClick={encryptData}>
                  Generate API Key
                </Button>
              ) : (
                <Fragment>
                  <FlexBox
                    sx={{
                      padding: "4px 8px",
                      borderRadius: "4px",
                      background: "#82868C",
                      color: "#FFF",
                      fontStyle: "italic",
                      width: "35%",
                      alignItems: "center",
                    }}
                  >
                    {token && token.length > 500
                      ? `${token.slice(0, 30)}...`
                      : token}
                  </FlexBox>
                  <FlexBox>
                    <CopyToClipboardButton text={token} />
                  </FlexBox>
                </Fragment>
              )}
              <Button
                variant="contained"
                sx={{
                  marginLeft: "1rem",
                }}
                onClick={() => props.onSave && props.onSave()}
              >
                Close
              </Button>
            </Box>
          </FlexBox>
        </Card>
      </div>
    </div>
  );
};

export default ZapierEditorModal;
