import Settings from "./Settings";
import { useEffect, useState } from "react";
import { getUserAttributes } from "../../services/context/Account";
import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";

const SettingsWrapper = () => {
  const [initialValues, setInitialValues] = useState<any>(null);
  const appData: any = useSelector((state: RootState) => state.app);
  useEffect(() => {
    const func = async () => {
      await getUserAttributes(({ email, name }) => {
        let initEmail = email;
        if (email === "") {
          initEmail =
            appData.user && appData.user.email ? appData.user.email : "";
        }
        let initName = name;
        if (name === "") {
          initName = appData.user && appData.user.name ? appData.user.name : "";
        }
        setInitialValues({
          email: initEmail,
          password: "",
          submit: null,
          oldPassword: "",
          confirmPassword: "",
          name: initName,
        });
      });
    };

    func();
  }, [appData.user]);

  return initialValues ? (
    <>
      <DashboardLayoutWrapper>
        <Settings initValues={initialValues} />
      </DashboardLayoutWrapper>
    </>
  ) : (
    <>
      <DashboardLayoutWrapper>
        <LoadingWrapper />
      </DashboardLayoutWrapper>
    </>
  );
};

export default SettingsWrapper;
