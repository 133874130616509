import { Button } from "@mui/material";
import { useEffect } from "react";
import "./Modal.css";

const Modal = (props: {
  title: string;
  onClose: () => void;
  show: boolean;
  children: any;
}) => {
  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHanlder = () => {
    props.onClose && props.onClose();
  };

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title">{props.title}</h4>
        </div>
        <div className="modal-body">{props.children}</div>
        <div className="modal-footer">
          <Button
            onClick={onCloseHanlder}
            variant="contained"
            type="button"
            sx={{
              background: "red",
              padding: "4px 6px",
              "&:hover": { backgroundColor: "red" },
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
