import { Box, styled } from "@mui/material";
import { ReactElement, Fragment } from "react";
import { useLocation, Outlet } from "react-router";
import ToastContainer from "../shared/wrappers/ToastContainer";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  width: `calc(100% - "220px")`,
  paddingLeft: "3rem",
  paddingRight: "3rem",
  transition: "all 0.3s",
  marginLeft: 220,
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1200,
    margin: "auto",
  },
}));

interface LayoutProps {
  children?: ReactElement;
}

const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation();

  if (pathname.includes("dashboard")) {
    return <>{children || <Outlet />}</>;
  }

  return (
    <Fragment>
      <Wrapper>
        <ToastContainer />
        <InnerWrapper>{children || <Outlet />}</InnerWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default Layout;
