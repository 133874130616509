import { Box } from "@mui/material";

const PreFillZap = ({ url }: { url: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <iframe
        title="Rargus Zapier"
        className="iframe-placeholder"
        src={url}
        style={{
          width: "100%",
          height: "100%",
          margin: "-3px",
        }}
      ></iframe>
    </Box>
  );
};

export default PreFillZap;
