import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  checkForNewAppAddition,
  DATA_BY_STORE,
  getHashedAPIKey,
  importPageExtention,
  SETTINGS_PAGE,
  setUserDataAndRole,
} from "../shared/constants";
import { AddFromSettingsProps } from "../AddApp/AddApp";
// import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import ZapierTemplate from "./ZapierTemplate";
import CopyToClipboardButton from "../shared/wrappers/CopyToClipboard";
import utf8 from "utf8";

const AddZapierTemplate = ({
  location,
  onBack,
  onAdd,
}: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);
  const [initApps, setInitApps] = useState<DATA_BY_STORE[]>([]);

  const [token, setToken] = useState("");

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setSeconds(seconds + 10);
    }, 10000);

    return () => clearInterval(interval);
  });

  const encryptData = () => {
    setToken(btoa(utf8.encode(getHashedAPIKey())));
  };

  // const secretPass = "328b7422-0908-11ee-82a2-4c796e8ce448";

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        if (res.getUser.userAppId) {
          localStorage.setItem(
            "appId",
            Array.isArray(JSON.parse(res.getUser.userAppId))
              ? JSON.stringify(JSON.parse(res.getUser.userAppId))
              : JSON.stringify([JSON.parse(res.getUser.userAppId)])
          );
          console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  // TODO: Create an interval that fetches users data from app schema and then checks if an app id is present.
  // TODO: Once the app id is associated, clear the interval, and start a new now
  // TODO: The new interval will fetch the app status as below. This should only be triggered after the first one has been
  // successful in getting an appID.

  const timeoutFunc = async () => {
    const email = localStorage.getItem("username") || appData.user.email;
    const res: any = await getUserDetails(email);
    if (res && res.getUser && res.getUser.userAppId) {
      let appIDs = Array.isArray(JSON.parse(res.getUser.userAppId))
        ? JSON.stringify(JSON.parse(res.getUser.userAppId))
        : JSON.stringify([JSON.parse(res.getUser.userAppId)]);
      localStorage.setItem("appId", appIDs);

      if (JSON.parse(appIDs) && JSON.parse(appIDs).length) {
        if (!location) {
          console.log("Navigate Now to import wait");
          navigate(`/dashboard/${importPageExtention}`);
        }

        // Need to identify when to take the user to the import page
        const check = await checkForNewAppAddition(initApps, appData, dispatch);
        if (location && onAdd && check) {
          console.log("Navigate Now to import wait");
          onAdd();
        }
      }
    }
  };

  useEffect(() => {
    if (appData && appData.app && appData.app.length) {
      setInitApps(appData.app);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timeoutFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  // // Use effect to check app import status and redirect user directly
  // useEffect(() => {
  //   const checkAppData = async (appData: any) => {
  //     // Check if all app data has success status...
  //     // If yes, navigate to feedback

  //     const res: any = await getAppDetails(appData, dispatch);
  //     if (res) {
  //       if (
  //         res.length !== 0 &&
  //         res.filter((val: { store: string; data: any }) => {
  //           return checkAllStatusFlags(val.data, [7], 4);
  //         }).length === 0
  //       ) {
  //         // The app data is succesfully processed
  //         navigate("/dashboard/insights/feedback");
  //         // To reload the page and fetch the newly added app
  //         if (location && location === "settings") {
  //           window.location &&
  //             window.location.reload &&
  //             window.location.reload();
  //         }
  //       }
  //     }
  //   };

  //   checkAppData(appData);
  //   const interval = setInterval(() => {
  //     checkAppData(appData);
  //   }, 10000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",
          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Zapier Integration Info
          </FlexBox>
          <Box
            sx={{
              mt: 3,
              ml: 1,
              mb: 1,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
            }}
          >
            Please select a pre-defined template to initiate the data import
            process.
          </Box>
          <ZapierTemplate />
          <FlexBox
            sx={{
              marginTop: "1.2rem",
              width: "100%",
            }}
          >
            {token === "" ? (
              <Button
                fullWidth
                type="button"
                variant="contained"
                // sx={{
                //   border: "none",
                //   padding: 0,
                //   "&:hover": { border: "none", background: "#FFF" },
                //   "&:disabled": {
                //     border: "none",
                //   },
                // }}
                onClick={encryptData}
              >
                Generate API Key
              </Button>
            ) : (
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <FlexBox
                  sx={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    background: "#82868C",
                    color: "#FFF",
                    fontStyle: "italic",
                    width: "85%",
                    alignItems: "center",
                  }}
                >
                  {token && token.length > 500
                    ? `${token.slice(0, 50)}...`
                    : token}
                </FlexBox>
                <FlexBox
                  sx={{
                    width: "10%",
                  }}
                >
                  <CopyToClipboardButton text={token} />
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
          {/* <PreFillZap /> */}
          <Box sx={{ mt: 2, justifyContent: "space-between", display: "flex" }}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 600,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => navigate("/dashboard/zapier-info")}
            >
              Import data directly on Zapier
            </Button>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 600,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => navigate("/dashboard/zapier-full-experience")}
            >
              Current zaps on Zapier
            </Button>
            {/* <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 600,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => navigate("/dashboard/zapier-partner")}
            >
              Want to check with partner api?
            </Button> */}
          </Box>
          <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 500,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => {
                if (location && onBack) {
                  onBack();
                } else {
                  navigate(`/dashboard/data-source`);
                }
              }}
            >
              {location && location === SETTINGS_PAGE
                ? "Back to settings"
                : "Back"}
            </Button>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default AddZapierTemplate;
