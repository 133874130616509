import FlexBox from "../shared/wrappers/FlexBox";
import { Review } from "../Analytics/Analytics";
import ReviewComponent from "../ReviewComponents/Review";
import { useEffect, useState } from "react";
import ReviewFilters from "../ReviewComponents/ReviewFilters";
import "../Analytics/pagination.css";
import ReactPaginate from "react-paginate";

type ReviewColumnProps = {
  filteredReviews: Review[];
};

const Reviews = ({ filteredReviews }: ReviewColumnProps) => {
  const itemsPerPage = 100;

  // Review Filter States
  const [reviewSelectFilters, setReviewSelectFilters] = useState({
    positive: true,
    negative: true,
    one: true,
    two: true,
    three: true,
    four: true,
    five: true,
  });

  const setReviewFilterValue = (value: boolean, type: string) => {
    setReviewSelectFilters({
      ...reviewSelectFilters,
      [type]: value,
    });
  };

  const [reviews, setReviews] = useState(filteredReviews);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews.length / itemsPerPage);

  useEffect(() => {
    if (reviews && reviews.length) {
      let maxOffset = reviews.length / 100;
      if (itemOffset > maxOffset) {
        setItemOffset(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);

  useEffect(() => {
    setReviews(filteredReviews);
  }, [filteredReviews]);

  const totalItems = reviews.length;

  useEffect(() => {
    if (filteredReviews) {
      if (filteredReviews && filteredReviews.length) {
        let filteredData: any[] = [...filteredReviews];

        if (!reviewSelectFilters.positive) {
          filteredData = filteredData.filter(
            (data) =>
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) <= 0.1
          );
        }
        if (!reviewSelectFilters.negative) {
          filteredData = filteredData.filter(
            (data) =>
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) >= -0.1
          );
        }
        if (!reviewSelectFilters.one) {
          filteredData = filteredData.filter((data) => data.rating !== 1);
        }
        if (!reviewSelectFilters.two) {
          filteredData = filteredData.filter((data) => data.rating !== 2);
        }
        if (!reviewSelectFilters.three) {
          filteredData = filteredData.filter((data) => data.rating !== 3);
        }
        if (!reviewSelectFilters.four) {
          filteredData = filteredData.filter((data) => data.rating !== 4);
        }
        if (!reviewSelectFilters.five) {
          filteredData = filteredData.filter((data) => data.rating !== 5);
        }

        setReviews(filteredData);
      } else {
        setReviews([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewSelectFilters, filteredReviews]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredReviews.length;
    setItemOffset(newOffset);
  };

  return (
    <FlexBox
      sx={{
        width: "100%",
        flexDirection: "column",
      }}
    >
      <ReviewFilters
        positive={reviewSelectFilters.positive}
        negative={reviewSelectFilters.negative}
        setPositive={(value: boolean) =>
          setReviewFilterValue(value, "positive")
        }
        setNegative={(value: boolean) =>
          setReviewFilterValue(value, "negative")
        }
        oneStar={reviewSelectFilters.one}
        twoStar={reviewSelectFilters.two}
        threeStar={reviewSelectFilters.three}
        fourStar={reviewSelectFilters.four}
        fiveStar={reviewSelectFilters.five}
        setOneStar={(value: boolean) => setReviewFilterValue(value, "one")}
        setTwoStar={(value: boolean) => setReviewFilterValue(value, "two")}
        setThreeStar={(value: boolean) => setReviewFilterValue(value, "three")}
        setFiveStar={(value: boolean) => setReviewFilterValue(value, "five")}
        setFourStar={(value: boolean) => setReviewFilterValue(value, "four")}
        selectAll={() =>
          setReviewSelectFilters({
            positive: true,
            negative: true,
            one: true,
            two: true,
            three: true,
            four: true,
            five: true,
          })
        }
        clearAll={() =>
          setReviewSelectFilters({
            positive: false,
            negative: false,
            one: false,
            two: false,
            three: false,
            four: false,
            five: false,
          })
        }
      />
      <FlexBox
        sx={{
          flexDirection: "column",
          height: "100%",
          maxHeight: "inherit",
          // display: "table",
          width: "100%",
        }}
      >
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <FlexBox
            sx={{
              fontSize: "15px",
              lineHeight: "18px",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#3A3C40",
              marginBottom: "0.8rem",
              marginLeft: "0.4rem",
            }}
          >
            {`${totalItems} ${`${totalItems > 1 ? "Feedbacks" : "Feedback"}`}`}
          </FlexBox>
        </FlexBox>
        <FlexBox
          sx={{
            width: "100%",
            justifyContent: "flex-start",
          }}
          key={`react-paginate-${filteredReviews.length}-reviews`}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            // forcePage={gotTo === "" ? undefined : parseInt(gotTo) - 1}
          />
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
            height: "70vh",
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "0.3rem 0.2rem",
            display: "inline-block",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          }}
          key={`review-component-${filteredReviews.length}-${currentItems.length}`}
        >
          {currentItems && currentItems.length ? (
            currentItems
              .sort((a: any, b: any) => {
                return a.rating - b.rating;
              })
              .map((review, index) => (
                // show idea component when the store is not apple or google
                <ReviewComponent
                  review={review}
                  index={index}
                  key={`review-component-${index}`}
                />
              ))
          ) : (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No feedbacks in selected range
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Reviews;
