import { useContext } from "react";
import { FormContext, FormContextAttr } from "./AdminBoard";
import AppSwitchPage from "./AppSwitchPage";
import CreateOrganisation from "./CreateOrganisation";
import DevPage from "./DevPage";

const Step = ({ location }: { location?: string | undefined }) => {
  const { activeStepIndex } = useContext(FormContext) as FormContextAttr;
  let stepContent = <></>;
  switch (activeStepIndex) {
    case 0:
      stepContent = <AppSwitchPage />;
      break;
    case 1:
      stepContent = <DevPage />;
      break;
    case 2:
      stepContent = <CreateOrganisation />;
      break;

    default:
      <></>;
      break;
  }

  return stepContent;
};

export default Step;
