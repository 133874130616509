import FlexBox from "../shared/wrappers/FlexBox";
import { Widget } from "../Insights/WidgetContainer";
import React, { useEffect, useMemo, useState } from "react";
import {
  COMPACT,
  NORMAL,
  EXPANDED,
  FULLWIDTH,
  HORIZONTAL_SCALE_GRAPH,
  BAR_LINE_GRAPH,
  LINE_GRAPH,
  SENTIMENTS_WIDGET,
  DETAILS_COMPONENT,
  ADD_WIDGET,
  LINE_GRAPH_WITH_SCALE,
  INDEPENDENT_LINE_GRAPH,
  ADDWIDGET,
  CONTAINERWIDTH,
  STAR_RATINGS,
  TOP_RATED_RATINGS,
  INDEPENDENT_BAR_LINE_GRAPH,
  CLUSTER_SUBCLUSTER,
  CONTAINER_WIDTH_AND_HEIGHT,
  REVIEWS,
  HORIZONTAL_GRAPH_WITH_REVIEWS,
  OVER_NORMAL,
  OVER_COMPACT,
  AREA_WITH_LINE_GRAPH,
  SUMMARY,
  NORMAL_TRANS,
  COMPACT_2,
  TOPIC_DETAILS,
  FilterOption,
  widgetSettingsOption,
  HORIZONTAL_GRAPH_WITH_REVIEWS_COMPONENT,
  FULLWIDTH_2,
  BAR_LINE_GRAPH_WITH_REVIEWS,
  FEEDBACKS,
  INDEPENDENT_LINE_GRAPH_EXTENTION,
} from "../shared/constants";
import {
  addWidgetComponent,
  BarWithLineGraph,
  DetailsComponent,
  lineGraphComponent,
  sentimentsComponent,
  HorizontalScaleGraph,
  lineGraphWithScaleComponent,
  IndependentLineGraphComponent,
  starRatingsComponent,
  TopRatedRating,
  IndependentBarLineGraph,
  ClusterSubcluster,
  Reviews,
  HorizontalGraphWithReviews,
  AreaWithLineGraph,
  SummaryComponent,
  topicDetailsComponent,
  HorizontalGraphWithReviewsComponent,
  BarLineGraphWithReviewsComponent,
  IndependentLineGraphComponentExtention,
} from "./InnerComponents";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";
import DragIcon from "../shared/icons/drag.svg";
import clsx from "clsx";
import "./DragDrop.css";
import {
  Filter,
  WidgetSettingsMultiPopover,
} from "../shared/wrappers/FilterPopper";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { WidgetId } from "../../services/store/AppStore/AppStoreSlice";
import { getWidgetList } from "../shared/widgetConstants";
import Feedbacks from "../Feedback/Reviews";

type DetailsWidgetProps = {
  id: string;
  data: Widget;
  index: number;
  maxHeight?: string;
  hideDraggable: boolean;
  overlay?: boolean;
  activeIndex?: number | undefined;
  handleWidgetSettings?: (widgetId: string[], action: string) => void;
  type: string;
};

export const getWidthFromType = (type: number) => {
  if (type === COMPACT || type === ADDWIDGET || type === COMPACT_2) {
    return "18rem";
  }

  if (type === NORMAL || type === NORMAL_TRANS) {
    return "36rem";
  }

  if (type === EXPANDED) {
    return "54rem";
  }

  if (type === FULLWIDTH || type === FULLWIDTH_2) {
    return "70rem";
  }

  return "18rem";
};

const DetailsWidget = ({
  data,
  index,
  id,
  maxHeight,
  hideDraggable,
  overlay,
  activeIndex,
  handleWidgetSettings,
  type,
}: DetailsWidgetProps) => {
  const { width } = useWindowDimensions();
  const appData: any = useSelector((state: RootState) => state.app);

  const [selectedSettings, setSelectedSettings] = useState<FilterOption[]>([]);

  useEffect(() => {
    if (selectedSettings && selectedSettings.length !== 0) {
      // Selected setting is remove widget
      if (
        selectedSettings.findIndex(
          (item: FilterOption) => item.value === "removeWidget"
        ) !== -1
      ) {
        return (
          handleWidgetSettings && handleWidgetSettings([id], "removeWidget")
        );
      }
      if (
        selectedSettings.findIndex(
          (item: FilterOption) => item.value === "replaceWidget"
        ) !== -1
      ) {
        // Open the filter to select the widget
        // Already handled by the Filter popover
        // return (
        //   handleWidgetSettings && handleWidgetSettings([id], "replaceWidget")
        // );
      }
      // Selected setting is edit widget
      if (
        selectedSettings.findIndex(
          (item: FilterOption) => item.value === "editWidget"
        ) !== -1
      ) {
        handleWidgetSettings && handleWidgetSettings([id], "editWidget");
      }

      setSelectedSettings([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSettings]);

  const {
    attributes,
    listeners,
    setNodeRef,
    // transform,
    transition,
    isDragging,
    over,
  } = useSortable({ id });

  // console.log(id, transform, CSS.Transform.toString(transform), transition);
  const style = {
    // transform: CSS.Transform.toString(transform) || "",
    transition: transition || "",
    width: "20px",
    height: "20px",
    background: "#FFF",
    display: "flex",
    top: "1.7rem",
    right: "1.7rem",
    transformOrigin: "50% 50%",
    borderRadius: "10px",
  };

  const getComponent = (value: string, data: Widget) => {
    switch (value) {
      case DETAILS_COMPONENT:
        return DetailsComponent(data);
      case LINE_GRAPH:
        return lineGraphComponent(data);
      case BAR_LINE_GRAPH:
        return BarWithLineGraph(data);
      case SENTIMENTS_WIDGET:
        return sentimentsComponent(data);
      case ADD_WIDGET:
        return addWidgetComponent();
      case HORIZONTAL_SCALE_GRAPH:
        return HorizontalScaleGraph(data, () => {}, false);
      case LINE_GRAPH_WITH_SCALE:
        return lineGraphWithScaleComponent(data);
      case INDEPENDENT_LINE_GRAPH:
        return IndependentLineGraphComponent(data);
      // To add some more fetaure in the github and sof data
      case INDEPENDENT_LINE_GRAPH_EXTENTION:
        return IndependentLineGraphComponentExtention(data);
      case STAR_RATINGS:
        return starRatingsComponent(data);
      case TOP_RATED_RATINGS:
        return TopRatedRating(data);
      case INDEPENDENT_BAR_LINE_GRAPH:
        return IndependentBarLineGraph(data);
      case CLUSTER_SUBCLUSTER:
        return ClusterSubcluster(data);
      case REVIEWS:
        return Reviews(data);
      case HORIZONTAL_GRAPH_WITH_REVIEWS:
        return HorizontalGraphWithReviews(data);
      case AREA_WITH_LINE_GRAPH:
        return AreaWithLineGraph(data);
      case SUMMARY:
        return SummaryComponent(data);
      case TOPIC_DETAILS:
        return topicDetailsComponent(data);
      case HORIZONTAL_GRAPH_WITH_REVIEWS_COMPONENT:
        return HorizontalGraphWithReviewsComponent(data);
      case BAR_LINE_GRAPH_WITH_REVIEWS:
        return BarLineGraphWithReviewsComponent(data);
      case FEEDBACKS:
        return Feedbacks({
          filteredReviews: data.data,
        });
      default:
        return <></>;
    }
  };

  let insertPosition =
    activeIndex !== undefined && over?.id === id && !isDragging
      ? index > activeIndex
        ? "after"
        : "before"
      : undefined;

  // console.log(activeIndex, id, over, over?.id === id, !isDragging, index);
  // if (insertPosition) {
  // Able to get insert position
  //   console.log(insertPosition, id);
  // }
  return (
    <React.Fragment>
      <div
        ref={setNodeRef}
        className={clsx("photo-frame", {
          overlay: overlay,
          active: isDragging,
          insertBefore: insertPosition === "before",
          insertAfter: insertPosition === "after",
        })}
        style={{
          display: "flex",
          // cursor: isDragging ? "grabbing" : hideDraggable ? "" : "",
          border: isDragging ? "1px solid #E3E6EB" : "",
          boxShadow: isDragging
            ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
            : "0px 7px 20px rgba(130, 134, 140, 0.08)",
          // transform: CSS.Transform.toString(transform),
          // transform: CSS.Transform.toString({
          //   x: transform && transform.x ? transform.x : 0,
          //   y: transform && transform.y ? transform.y : 0,
          //   scaleX: 1,
          //   scaleY: 1,
          // }),
          // Need to check if I can find the amount to transform based on the component type
          // {
          //   x: transform && transform.x ? transform.x : 0,
          //   y: transform && transform.y ? transform.y : 0,
          //   scaleX: transform && transform.scaleX ? transform.scaleX : 1,
          //   scaleY: transform && transform.scaleY ? transform.scaleY : 1,
          // }
          zIndex: isDragging ? "100" : "auto",
          opacity: isDragging ? 0.3 : 1,
          transition: transition,
          ...(data.type === FULLWIDTH
            ? {
                width: "100%",
                // minWidth: "600px",
                // maxWidth: "74rem",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === FULLWIDTH_2
            ? {
                width: "100%",
                // minWidth: "600px",
                // maxWidth: "74rem",
                // background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
              }
            : data.type === EXPANDED
            ? {
                // Adding responsiveness
                ...(width > 1600
                  ? { width: "74.5%" }
                  : width > 1500
                  ? { width: "74%" }
                  : width > 1300
                  ? { width: "73.5%" }
                  : width > 1200
                  ? { width: "72.8%" }
                  : width > 1100
                  ? { width: "71%" }
                  : width > 1000
                  ? { width: "68.5%" }
                  : width > 900
                  ? { width: "68%" }
                  : { width: "100%" }),
                //minWidth: "400px",
                // maxWidth: "55rem",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === NORMAL
            ? {
                ...(width > 1500
                  ? { width: "48.5%" }
                  : width > 1300
                  ? { width: "48%" }
                  : width > 1200
                  ? { width: "47.5%" }
                  : width > 1100
                  ? { width: "47%" }
                  : width > 1000
                  ? { width: "45%" }
                  : { width: "100%" }),
                //minWidth: "350px",
                // maxWidth: "37.5rem",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === COMPACT
            ? {
                ...(width >= 1200
                  ? { width: "23.5%", minWidth: "200px" }
                  : width < 1200 && width > 1100
                  ? { width: "47%", minWidth: "170px" }
                  : width >= 980
                  ? { width: "45.2%", minWidth: "170px" }
                  : width >= 800
                  ? { width: "47%", minWidth: "170px" }
                  : width >= 780
                  ? { width: "47.5%", minWidth: "170px" }
                  : width < 780
                  ? { width: "100%", minWidth: "170px" }
                  : // : width > 1000
                    // ? { width: "45%" }
                    {}),
                // width: "23.2%",
                // minWidth: "200px",
                // maxWidth: "18.5rem",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === ADDWIDGET
            ? {
                ...(width >= 1200
                  ? { width: "23.2%", minWidth: "200px" }
                  : width < 1200
                  ? { width: "47%", minWidth: "170px" }
                  : // : width > 1000
                    // ? { width: "45%" }
                    {}),
                // maxWidth: "18.5rem",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === CONTAINERWIDTH
            ? {
                width: "100%",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === CONTAINER_WIDTH_AND_HEIGHT
            ? {
                width: "100%",
                height: "100%",
                overflow: "hidden",
                padding: "1.5rem",
              }
            : data.type === OVER_NORMAL
            ? {
                width: "59%",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === OVER_COMPACT
            ? {
                width: "36%",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === NORMAL_TRANS
            ? {
                ...(width > 1500
                  ? { width: "49%" }
                  : width > 1300
                  ? { width: "49%" }
                  : width > 1200
                  ? { width: "49%" }
                  : width > 1100
                  ? { width: "49%" }
                  : width > 1000
                  ? { width: "45%" }
                  : { width: "100%" }),
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }
            : data.type === COMPACT_2 && {
                ...(width > 1600
                  ? { width: "22.5%" }
                  : width > 1500
                  ? { width: "23.2%" }
                  : width > 1350
                  ? { width: "23.2%" }
                  : width >= 1200
                  ? { width: "23.5%" }
                  : width >= 1050
                  ? { width: "25%" }
                  : width > 900
                  ? { width: "27%" }
                  : { width: "100%" }),
                //minWidth: "200px",
                // maxWidth: "18.5rem",
                background: "#FFFFFF",
                maxHeight: "fit-content",
                minHeight: "360px",
                padding: "1.5rem",
              }),
          overflow: "hidden",
          marginBottom: "1rem",
          marginRight: "1rem",
          borderRadius: "8px",
          flexDirection: "column",
          position: "relative",
          ...(maxHeight
            ? { maxHeight, width: getWidthFromType(data.type) }
            : {}),
        }}
        key={`${index}--expanded-widget-container`}
        {...attributes}
      >
        <div
          style={{
            ...style,
            position: "absolute",
            flexDirection: "row",
            ...(hideDraggable ? { display: "none" } : {}),
          }}
        >
          <WidgetSettingsMultiPopover
            selectedFilter={selectedSettings}
            setSelectedFilter={setSelectedSettings}
            filterOptions={widgetSettingsOption}
            selectedFilter2={useMemo(
              () =>
                appData.widgetLayout[type].widgets.map((widget: WidgetId) => ({
                  label: widget.label,
                  value: widget.id.toLowerCase(),
                  id: widget.widgetId,
                })),
              [appData.widgetLayout, type]
            )}
            setSelectedFilter2={(filter: Filter[]) => {
              handleWidgetSettings &&
                handleWidgetSettings(
                  [id, filter[0].id ? filter[0].id : filter[0].value],
                  "replaceWidget"
                );
            }}
            filterOptions2={getWidgetList(appData).map((widget: WidgetId) => ({
              label: widget.label,
              value: widget.id.toLowerCase(),
              id: widget.widgetId,
            }))}
            disableSelectAll={true}
            disableCheckbox={true}
            popOffset={4.45}
          />
          <FlexBox
            {...listeners}
            {...attributes}
            sx={{
              cursor: "grab",
              "cursor:move": "grab",
              marginLeft: "0.5rem",
            }}
          >
            <img src={DragIcon} alt={"drag"} width={"9px"} height={"15px"} />
          </FlexBox>
        </div>
        {getComponent(data.component, data)}
      </div>
    </React.Fragment>
  );
};

export default DetailsWidget;
