import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import {
  ADMIN_MANAGEMENT_TYPE,
  FilterOption,
  getUserRights,
  getUserRole,
} from "../shared/constants";
import FlexBox from "../shared/wrappers/FlexBox";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import TooltipComponent from "../shared/wrappers/Tooltip";
import DeleteIcon from "../shared/icons/delete.svg";
import CreateNewUser from "./CreateNewUser";
import { ListUsersOfOrganization } from "../../services/api/Graphql.api";
import DeleteModal from "../shared/wrappers/DeleteModal";
import { createOrganizationUser } from "../../services/api/Apps.api";

export const ROLES = {
  admin: "admin",
  viewer: "viewer",
  editor: "editor",
};

export const USER_ROLES = [
  {
    label: "Admin",
    value: ROLES.admin,
  },
  {
    label: "Viewer",
    value: ROLES.viewer,
  },
  {
    label: "Editor",
    value: ROLES.editor,
  },
];

export type USER_RIGHT = {
  name: string;
  email: string;
  role: string;
  organization: string;
};

const AdminManagement = () => {
  const appData: any = useSelector((state: RootState) => state.app);
  const [addNewUser, setAddNewUser] = useState(false);
  const [currentUser, setCurrentUser] = useState<USER_RIGHT | undefined>();
  const [userList, setUserList] = useState<USER_RIGHT[]>([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState<USER_RIGHT | undefined>();

  const [showDeleteModal, setShowDeleteModal] = useState<
    USER_RIGHT | undefined
  >();

  // Set the current user
  useEffect(() => {
    if (appData.user && appData.user.userRights) {
      const userRights = getUserRights(appData.user);

      if (userRights.isAdmin && userRights.isAdmin === true) {
        setCurrentUser({
          name: appData.user.name ? appData.user.name : appData.user.email,
          email: appData.user.email,
          role: getUserRole(userRights),
          organization: userRights.organization ? userRights.organization : "",
        });
      }
    }
  }, [appData.user]);

  // Set the users list
  useEffect(() => {
    if (currentUser && currentUser.organization) {
      const fetchUserList = async () => {
        const response = await ListUsersOfOrganization(
          currentUser.organization
        );

        if (
          response &&
          response.listUsers &&
          response.listUsers.items &&
          response.listUsers.items.length
        ) {
          const users = response.listUsers.items;

          let orgUsers: USER_RIGHT[] = [];

          users.forEach((user: any) => {
            const userRights = getUserRights(user);

            // Checking if the fetched list contains users of the same org as the current user
            if (
              userRights.organization &&
              currentUser &&
              currentUser.organization &&
              userRights.organization === currentUser.organization
            ) {
              orgUsers.push({
                name: user.name,
                email: user.email,
                role: getUserRole(userRights),
                organization: userRights.organization
                  ? userRights.organization
                  : "",
              });
            }
          });

          if (orgUsers && orgUsers.length) {
            setUserList(
              orgUsers.filter(
                (user: USER_RIGHT) => user.email !== currentUser.email
              )
            );
          }
        }
      };

      fetchUserList();
    }
  }, [currentUser]);

  const createUserRow = (currentLocalUser: USER_RIGHT, disabled?: boolean) => {
    const role = USER_ROLES.filter(
      (role: FilterOption) => role.value === currentLocalUser.role
    );
    let roleString = "";
    if (role && role.length) {
      roleString = role[0].label;
    }

    if (disabled) {
      roleString += " (You)";
    }
    return (
      <FlexBox
        sx={{
          width: "100%",
          height: "fit-content",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
        key={`admin-page-user-${currentLocalUser.email}-${currentLocalUser.name}`}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
            height: "2.5rem",
          }}
        >
          {currentLocalUser.name ? (
            <FlexBox
              sx={{
                width: "95%",
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#0B0E1E",
              }}
            >
              {currentLocalUser.name}
            </FlexBox>
          ) : (
            <></>
          )}
          {currentLocalUser.email ? (
            <FlexBox
              sx={{
                width: "95%",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#0B0E1E",
              }}
            >
              {currentLocalUser.email}
            </FlexBox>
          ) : (
            <></>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            width: "49%",
            height: "100%",
          }}
        >
          {disabled ? (
            <FlexBox
              sx={{
                width: "100%",
                padding: "1.2rem 0.7rem",
                height: "fit-content",
                borderRadius: "8px",
                alignItems: "center",
                ...(disabled
                  ? {
                      border: "1px solid #E3E6EB",
                    }
                  : {
                      border: "1px solid #82868C",
                    }),
              }}
            >
              <FlexBox
                sx={{
                  width: "92%",
                  alignItems: "center",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
              >
                {roleString}
              </FlexBox>

              <FlexBox
                sx={{
                  width: "8%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <FlexBox
                  sx={{
                    alignItems: "center",
                  }}
                  onClick={() => {}}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      // setShowDeleteModal({
                      //   appID: app.appID,
                      //   store: store,
                      //   title: app.name
                      //     ? app.name
                      //     : app.title
                      //     ? app.title
                      //     : "",
                      // });
                    }}
                  >
                    <img src={DeleteIcon} alt="Delete" width="16" height="16" />
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox
              sx={{
                borderRadius: "8px",
                border: "1px solid #82868C",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "0.5rem",
              }}
              key={`app-name-${currentLocalUser.email}-${currentLocalUser.name}`}
            >
              <FlexBox
                sx={{
                  width: "92%",
                }}
              >
                <Select
                  fullWidth
                  labelId="source"
                  id="source"
                  name={"source"}
                  value={currentLocalUser.role}
                  label=""
                  onChange={async (event: any) => {
                    if (event.target && event.target.value) {
                      // Update the role for that user in the schema
                      try {
                        setError("");
                        setLoading(currentLocalUser);
                        await createOrganizationUser({
                          admin:
                            currentUser && currentUser.email
                              ? currentUser.email
                              : "",
                          name: currentLocalUser.name,
                          email: currentLocalUser.email,
                          role: event.target.value,
                          organization:
                            currentUser && currentUser.organization
                              ? currentUser.organization
                              : "",
                          type: ADMIN_MANAGEMENT_TYPE.edit,
                        });

                        // Updating user role in UI
                        const foundIndex = userList.findIndex(
                          (user: USER_RIGHT) =>
                            user.email === currentLocalUser.email
                        );
                        if (foundIndex !== -1) {
                          let users = [...userList];
                          users[foundIndex] = {
                            ...users[foundIndex],
                            role: event.target.value,
                          };
                          setUserList(users);
                        }

                        setLoading(undefined);
                      } catch (e) {
                        setError("User is not an admin.");
                        setLoading(undefined);
                      }
                    }
                  }}
                  sx={{
                    borderRadius: "o",
                    border: "0",
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  MenuProps={{
                    sx: {
                      zIndex: 99999999,
                      height: "18rem",
                      // maxHeight: "15rem",
                      // overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: "0.4rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#A3A6AB",
                        borderRadius: "5px",
                      },
                    },
                  }}
                >
                  {USER_ROLES.map((item: FilterOption, index: number) => (
                    <MenuItem
                      value={item.value}
                      key={`${item.value}--menu-item-${index}`}
                    >
                      <FlexBox
                        sx={{
                          color: "#0B0E1E",
                          fontSize: "13px",
                          lineHeight: "16px",
                          fontStyle: "normal",
                        }}
                      >
                        {item.label}
                      </FlexBox>
                    </MenuItem>
                  ))}
                </Select>
              </FlexBox>
              <FlexBox
                sx={{
                  width: "8%",
                }}
              >
                {loading && loading.email === currentLocalUser.email ? (
                  <FlexBox
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress disableShrink={true} size={"1.4rem"} />
                  </FlexBox>
                ) : (
                  <TooltipComponent title="Remove user" placement="top">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        setShowDeleteModal(currentLocalUser);
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="Delete"
                        width="16"
                        height="16"
                      />
                    </div>
                  </TooltipComponent>
                )}
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    );
  };

  return (
    <Fragment>
      {currentUser ? (
        <FlexBox
          sx={{
            height: "100%",
            width: "100%",
            padding: "2rem 0 2rem 0",
          }}
        >
          {addNewUser ? (
            <CreateNewUser
              setAddNewUser={setAddNewUser}
              userList={userList}
              setUserList={setUserList}
              currentUser={currentUser}
              appID={
                appData.user && appData.user.userAppId
                  ? Array.isArray(appData.user.userAppId)
                    ? JSON.stringify(appData.user.userAppId)
                    : appData.user.userAppId
                  : ""
              }
            />
          ) : (
            <FlexBox
              sx={{
                height: "100%",
                width: "100%",
                // justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <FlexBox>
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    border: "none",
                    padding: 0,
                    "&:hover": { border: "none", background: "#FFF" },
                    "&:disabled": {
                      border: "none",
                    },
                  }}
                  onClick={() => {
                    setAddNewUser(true);
                  }}
                >
                  + Add new account
                </Button>
              </FlexBox>
              <FlexBox
                sx={{
                  mt: 2,
                  flexDirection: "column",
                  height: "100%",
                  maxHeight: "50vh",
                  overflowY: "auto",
                  paddingRight: "1.5rem",
                  "&::-webkit-scrollbar": {
                    width: "0.4rem",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#A3A6AB",
                    borderRadius: "5px",
                  },
                }}
              >
                {currentUser ? createUserRow(currentUser, true) : <></>}
                {userList && userList.length !== 0 ? (
                  userList.map((user: USER_RIGHT) => createUserRow(user))
                ) : (
                  <></>
                )}
                {error && error !== "" && (
                  <FormHelperText
                    error
                    sx={{
                      // mt: 1,
                      fontSize: 13,
                      fontWeight: 500,
                      // textAlign: "center",
                    }}
                  >
                    {error}
                  </FormHelperText>
                )}
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <LoadingWrapper />
      )}
      {showDeleteModal ? (
        <DeleteModal
          onClose={async () => {
            // Delete the user in the backend
            try {
              setLoading(showDeleteModal);
              await createOrganizationUser({
                admin:
                  currentUser && currentUser.email ? currentUser.email : "",
                name: showDeleteModal.name,
                email: showDeleteModal.email,
                role: showDeleteModal.role,
                organization:
                  currentUser && currentUser.organization
                    ? currentUser.organization
                    : "",
                type: ADMIN_MANAGEMENT_TYPE.delete,
              });

              setUserList(
                userList.filter(
                  (user: USER_RIGHT) => user.email !== showDeleteModal.email
                )
              );

              setLoading(undefined);
            } catch (e) {
              setError("Some error occured while deleting the user");
              setLoading(undefined);
            }

            setShowDeleteModal(undefined);
          }}
          title={`Remove ${
            showDeleteModal.name ? ` ${showDeleteModal.name} ` : "the user"
          }?`}
          content={
            <Box
              sx={{
                display: "-webkit-box",
              }}
            >
              Removing user{" "}
              <Box
                sx={{
                  paddingLeft: "0.3rem",
                  paddingRight: "0.3rem",
                  fontWeight: 700,
                }}
              >
                {showDeleteModal.name ? ` ${showDeleteModal.name} ` : ""}
              </Box>
              cannot be undone.
            </Box>
          }
          show={showDeleteModal ? true : false}
          setShow={(value: boolean) => {
            if (!value) {
              setShowDeleteModal(undefined);
            }
          }}
        ></DeleteModal>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default AdminManagement;
