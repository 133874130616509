import { Box, Button, FormHelperText } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
// import { deleteUserAppDetails } from "../../services/api/Graphql.api";
// import { useDispatch } from "react-redux";
// import { removeUserTokens } from "../../services/context/Account";
// import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { useNavigate } from "react-router-dom";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import {
  FullTextFieldWrapper,
  TextFieldWrapper,
} from "../shared/wrappers/StyledComponents";
import { LoadingButton } from "@mui/lab";
import { Paragraph } from "../shared/wrappers/Typography";
import { changePassword } from "../../services/context/Account";
import { SettingsProps } from "./Settings";

const Profile = ({ initValues }: SettingsProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<{
    name: string;
    email: string;
    password: string;
    submit: null;
    oldPassword: string;
    confirmPassword: string;
  }>(initValues);

  const changePasswordCallback = ({
    error,
    result,
  }: {
    error: string | null;
    result: string | null;
  }) => {
    setLoading(false);
    if (!error) {
      console.log("Password changed");
      setInitialValues({
        email: initialValues.email,
        password: "",
        submit: null,
        oldPassword: "",
        confirmPassword: "",
        name: initialValues.name,
      });
      setError("");

      // navigate somewhere
      navigate("/dashboard/insights/feedback");
    } else setError(error);
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    oldPassword: Yup.string()
      .required("Password is required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase and One Lowercase"
      ),
    password: Yup.string()
      .required("Password is required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase and One Lowercase"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);

        // Change Password Flow
        changePassword(
          values.email,
          values.oldPassword,
          values.password,
          changePasswordCallback
        );
      },
    });

  return (
    <FlexBox
      sx={{
        marginTop: "0.75rem",
        width: "100%",
        height: "100%",
      }}
    >
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{ width: "100%", overflowY: "auto" }}
      >
        <FlexBox justifyContent="space-between" flexWrap="wrap">
          <TextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Name"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"name"}
              type={"name"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              disabled={initialValues.name === "" ? false : true}
            />
          </TextFieldWrapper>

          <TextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Email"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"email"}
              type={"email"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ""}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={initialValues.email === "" ? false : true}
            />
          </TextFieldWrapper>
        </FlexBox>
        <FullTextFieldWrapper>
          <Paragraph fontWeight={600} mb={1} mt={1}>
            Current password
          </Paragraph>
          <LightTextField
            fullWidth
            name="oldPassword"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.oldPassword || ""}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
          />
        </FullTextFieldWrapper>
        <FullTextFieldWrapper>
          <Paragraph fontWeight={600} mb={1} mt={1}>
            New password
          </Paragraph>
          <LightTextField
            fullWidth
            name="password"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </FullTextFieldWrapper>
        <FullTextFieldWrapper>
          <Paragraph fontWeight={600} mb={1} mt={1}>
            Repeat new password
          </Paragraph>
          <LightTextField
            fullWidth
            name="confirmPassword"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.confirmPassword || ""}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </FullTextFieldWrapper>
        {error && (
          <FormHelperText
            error
            sx={{
              mt: 2,
              fontSize: 13,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {error}
          </FormHelperText>
        )}

        <Box sx={{ mt: 4 }}>
          {loading ? (
            <LoadingButton loading fullWidth variant="contained">
              {"Save"}
            </LoadingButton>
          ) : (
            <Button fullWidth type="submit" variant="contained">
              {"Save"}
            </Button>
          )}
        </Box>
      </form>
    </FlexBox>
  );
};

export default Profile;
