import FlexBox from "../shared/wrappers/FlexBox";
import CrossIcon from "../shared/icons/white-cross.svg";
import PlusIcon from "../shared/icons/grey-plus.svg";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

type ReviewFilterProps = {
  positive: boolean;
  negative: boolean;
  oneStar: boolean;
  twoStar: boolean;
  threeStar: boolean;
  fourStar: boolean;
  fiveStar: boolean;
  setPositive: (values: boolean) => void;
  setNegative: (values: boolean) => void;
  setOneStar: (values: boolean) => void;
  setTwoStar: (values: boolean) => void;
  setThreeStar: (values: boolean) => void;
  setFourStar: (values: boolean) => void;
  setFiveStar: (values: boolean) => void;
  selectAll: () => void;
  clearAll: () => void;
};

const ReviewFilters = ({
  positive,
  negative,
  oneStar,
  twoStar,
  threeStar,
  fourStar,
  fiveStar,
  setPositive,
  setNegative,
  setOneStar,
  setTwoStar,
  setThreeStar,
  setFourStar,
  setFiveStar,
  selectAll,
  clearAll,
}: ReviewFilterProps) => {
  const { width } = useWindowDimensions();

  return (
    <FlexBox
      sx={{
        marginBottom: "1rem",
        flexDirection: "column",
        minWidth: "30rem",
      }}
    >
      <FlexBox
        sx={{
          marginBottom: "0.5rem",
        }}
      >
        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),

            ...(oneStar
              ? { background: "#000", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setOneStar(!oneStar)}
        >
          1 star
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={oneStar ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            ...(twoStar
              ? { background: "#000", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setTwoStar(!twoStar)}
        >
          2 star
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={twoStar ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            ...(threeStar
              ? { background: "#000", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setThreeStar(!threeStar)}
        >
          3 star
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={threeStar ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            ...(fourStar
              ? { background: "#000", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setFourStar(!fourStar)}
        >
          4 star
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={fourStar ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            ...(fiveStar
              ? { background: "#000", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setFiveStar(!fiveStar)}
        >
          5 star
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={fiveStar ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            alignItems: "center",
            ...(positive
              ? { background: "#418F90", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setPositive(!positive)}
        >
          Positive
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={positive ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>
        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            alignItems: "center",
            ...(negative
              ? { background: "#DB4E82", color: "#FFFFFF" }
              : { color: "#82868C" }),
          }}
          onClick={() => setNegative(!negative)}
        >
          Negative
          <FlexBox
            sx={{
              marginLeft: "3px",
              alignItems: "center",
            }}
          >
            <img
              src={negative ? CrossIcon : PlusIcon}
              alt="cross"
              width="8px"
              height="8px"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            color: "#82868C",
          }}
          onClick={selectAll}
        >
          Select all
        </FlexBox>
        <FlexBox
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            padding: "6px 12px",
            borderRadius: "12px",
            cursor: "pointer",
            ...(width < 1100
              ? { marginRight: "0.1rem" }
              : { marginRight: "0.35rem" }),
            color: "#82868C",
          }}
          onClick={clearAll}
        >
          Clear all
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default ReviewFilters;
