import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import { FilterTextPopover } from "../shared/wrappers/FilterPopper";
import {
  APP_FEEDBACK,
  complaintsFunc,
  FilterOption,
  filterOptions,
} from "../shared/constants";
import { useSelector, useDispatch } from "react-redux";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { RootState } from "../../services/store/store";
import { useEffect } from "react";
import WidgetContainer from "../Insights/WidgetContainer";
import { DEFAULT_ADMIN_FEEDBACK_WIDGETS } from "../shared/widgetConstants";

const AppFeedback = () => {
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  // const graphData: any = useSelector((store: RootState) => store.graphs);

  useEffect(() => {
    if (
      !appData ||
      !appData.appFeedback ||
      (appData.appFeedback && appData.appFeedback.length === 0)
    ) {
      // Fetch the reviews from the schema
      complaintsFunc(
        APP_FEEDBACK,
        [],
        () => {},
        dispatch,
        AppActions.setAppFeedback,
        "listAppFeedbacks"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        <FlexBox
          sx={{
            width: "90%",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginBottom: "1.5rem",
              alignItems: "center",
              // width: "100%",
              // position: "fixed",
              // zIndex: "10",
              // background: "#F5F5F5",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                color: "#3579E3",
              }}
            >
              Rargus App Feedback
            </FlexBox>
            <FlexBox>
              <FilterTextPopover
                filterOptions={filterOptions}
                selectedFilter={appData.filter}
                setSelectedFilter={(filter: FilterOption) =>
                  dispatch(AppActions.setGlobalFilter(filter))
                }
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              sx={{
                width: "49.5%",
              }}
            >
              {appData &&
              appData.widgetLayout &&
              appData.widgetLayout["app-feedback"] ? (
                <WidgetContainer
                  widgets={[...DEFAULT_ADMIN_FEEDBACK_WIDGETS].splice(0, 1)}
                  type={"app-feedback"}
                  hideDraggable={true}
                  otherData={{
                    filteredReviews:
                      appData &&
                      appData.appFeedback &&
                      appData.appFeedback.length
                        ? appData.appFeedback
                        : [],
                  }}
                />
              ) : (
                <></>
              )}
            </FlexBox>
            <FlexBox
              sx={{
                width: "49.5%",
              }}
            >
              {appData &&
              appData.widgetLayout &&
              appData.widgetLayout["app-feedback"] ? (
                <WidgetContainer
                  widgets={[...DEFAULT_ADMIN_FEEDBACK_WIDGETS].splice(1, 3)}
                  type={"app-feedback"}
                  hideDraggable={true}
                  otherData={{
                    filteredReviews:
                      appData &&
                      appData.appFeedback &&
                      appData.appFeedback.length
                        ? appData.appFeedback
                        : [],
                  }}
                />
              ) : (
                <></>
              )}
            </FlexBox>
          </FlexBox>
          {/* <FlexBox>
            {appData &&
            appData.widgetLayout &&
            appData.widgetLayout["app-feedback"] ? (
              <WidgetContainer
                widgets={DEFAULT_ADMIN_FEEDBACK_WIDGETS}
                type={"app-feedback"}
                hideDraggable={true}
                otherData={{
                  filteredReviews:
                    appData && appData.appFeedback && appData.appFeedback.length
                      ? appData.appFeedback
                      : [],
                }}
              />
            ) : (
              <></>
            )}
          </FlexBox> */}
          {/* Previous Implementation  */}
          {/* <FlexBox
            sx={{
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              //   alignItems: "center",
            }}
          >
            <Reviews
              filteredReviews={
                appData && appData.appFeedback && appData.appFeedback.length
                  ? appData.appFeedback.map((item: AppFeedbackType) => ({
                      content: item.feedback,
                      date: item.createdAt,
                      rating: parseInt(item.rating),
                      username: item.user,
                      reviewID: item.id,
                    }))
                  : []
              }
            />
          </FlexBox> */}
        </FlexBox>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default AppFeedback;
