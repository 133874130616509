import { CognitoUserPool } from 'amazon-cognito-identity-js';

// User Pool : Client chosen shouldn't have client secret. 
// SDK login doesn't support client with client secret
const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
};

export default new CognitoUserPool(poolData);
