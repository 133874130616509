import { Box, Button, Card, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../services/hooks/useWindowDimensions";
import FlexBox from "./FlexBox";
import "./Modal.css";
import { FullTextFieldWrapper } from "./StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  DATA_BY_STORE,
  FilterOption,
  filterOptions,
  getRouteName,
  GET_DATA_STORE,
  STORE,
  updateLayoutAndUserWidgets,
} from "../constants";
import PlayStoreIcon from "../icons/play-store.svg";
import AppleStoreIcon from "../icons/apple-store.svg";
import AhaIcon from "../icons/aha.svg";
import CSVIcon from "../icons/csv.svg";
import SOFIcon from "../icons/sof.svg";
import SlackIcon from "../icons/slack.svg";
import AmazonIcon from "../icons/amazon.svg";
import GithubIcon from "../icons/github.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../services/store/store";
import LightTextField from "./LightTextField";
import { Paragraph } from "./Typography";
import { useNavigate } from "react-router";
import { Filter, FilterTextPopover } from "./FilterPopper";
// import Chip from "@mui/material/Chip";
// import OutlinedInput from "@mui/material/OutlinedInput";

export const DATA_SOURCE = {
  [STORE.apple]: {
    label: "Apple Store",
    value: STORE.apple,
    icon: AppleStoreIcon,
  },
  [STORE.google]: {
    label: "Play Store",
    value: STORE.google,
    icon: PlayStoreIcon,
  },
  [STORE.aha]: {
    label: "Aha!",
    value: STORE.aha,
    icon: AhaIcon,
  },
  [STORE.csv]: {
    label: "CSV",
    value: STORE.csv,
    icon: CSVIcon,
  },
  [STORE.sof]: {
    label: "Stack overflow",
    value: STORE.sof,
    icon: SOFIcon,
  },
  [STORE.slack]: {
    label: "Slack",
    value: "slack",
    icon: SlackIcon,
  },
  [STORE.amazon]: {
    label: "Amazon",
    value: STORE.amazon,
    icon: AmazonIcon,
  },
  [STORE.github]: {
    label: "Github",
    value: STORE.github,
    icon: GithubIcon,
  },
};

const DashboardSettingsModal = (props: {
  title: string;
  onClose: () => void;
  show: boolean;
  setShow: (value: boolean) => void;
  children?: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const appData: any = useSelector((state: RootState) => state.app);
  const [selectedFilter, setSelectedFilter] = useState(
    appData.dashboardSettings && appData.dashboardSettings.filter
      ? appData.dashboardSettings.filter
      : appData.filter
  );

  const [apps, setApps] = useState<any>({});

  useEffect(() => {
    let tApps: any = {};
    appData.app.forEach((app: DATA_BY_STORE) => {
      tApps[app.appID] = app.data.name;
    });
    setApps(tApps);
  }, [appData.app]);

  const { width } = useWindowDimensions();
  //   const closeOnEscapeKeyDown = (e: any) => {
  //     if ((e.charCode || e.keyCode) === 27) {
  //       props.setShow(false);
  //     }

  //     if ((e.charCode || e.keyCode) === 13) {
  //       props.onClose && props.onClose();
  //     }
  //   };

  //   useEffect(() => {
  //     document.body.addEventListener("keydown", closeOnEscapeKeyDown);
  //     return function cleanup() {
  //       document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  const onCloseHanlder = () => {
    props.onClose && props.onClose();
  };

  const initialValues: {
    label: string;
    source: string[];
    reset: string;
    date: string;
  } = {
    label:
      appData.dashboardSettings && appData.dashboardSettings.label
        ? appData.dashboardSettings.label
        : "",
    source:
      appData.dashboardSettings &&
      appData.dashboardSettings.source &&
      appData.dashboardSettings.source.length
        ? appData.dashboardSettings.source.map(
            (source: GET_DATA_STORE) => source.appID
          )
        : appData.app && appData.app.length
        ? appData.app.map((item: any) => item.appID)
        : [],
    reset:
      appData.dashboardSettings && appData.dashboardSettings.reset
        ? appData.dashboardSettings.reset
        : "yes",
    date:
      appData.dashboardSettings && appData.dashboardSettings.filter
        ? appData.dashboardSettings.filter.value
        : filterOptions[4].value,
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Please provide a label to the widget"),
    source: Yup.array().required("Please select a data source"),
    reset: Yup.string().required(
      "Please choose whether to reset underlying source settings"
    ),
    date: Yup.string().required("Please choose a time range"),
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (!loading) {
        setLoading(true);
      }
      // Find the widget
      const dashboardSettings = appData.dashboardSettings;
      if (dashboardSettings && dashboardSettings.id) {
        const foundDashboard = appData.widgetLayout[dashboardSettings.id];
        if (foundDashboard) {
          // Dashboard is found
          // Remove the previous one from the Layout
          let prevLayout = { ...appData.widgetLayout };
          delete prevLayout[dashboardSettings.id];

          // Get the new route
          const newRoute = getRouteName(values.label);

          // Get the source array
          let source: GET_DATA_STORE[] = [];
          appData.app.forEach((app: DATA_BY_STORE) => {
            if (values.source.includes(app.appID)) {
              source.push({
                appID: app.appID,
                store: app.store,
              });
            }
          });

          const userWidgets = {
            ...prevLayout,
            [newRoute]: {
              ...foundDashboard,
              name: values.label,
              source: source,
              reset: values.reset,
              filter: selectedFilter,
            },
          };

          updateLayoutAndUserWidgets(userWidgets, dispatch);

          navigate(`/dashboard/insights/${newRoute}`);
        }
      }

      setLoading(false);
      onCloseHanlder();
    },
  });

  const resetDashboardSettingsHandler = () => {
    if (!loading) {
      setLoading(true);
    }
    const dashboardSettings = appData.dashboardSettings;
    if (dashboardSettings && dashboardSettings.id) {
      const foundDashboard = appData.widgetLayout[dashboardSettings.id];
      if (foundDashboard) {
        // Dashboard is found
        // Remove the settings for the dashboard
        let prevLayout = { ...foundDashboard };
        delete prevLayout["source"];
        delete prevLayout["filter"];

        const userWidgets = {
          ...appData.widgetLayout,
          [dashboardSettings.id]: {
            ...prevLayout,
          },
        };

        updateLayoutAndUserWidgets(userWidgets, dispatch);
      }
    }

    setLoading(false);
    onCloseHanlder();
  };

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", width: "60%" }),
        }}
      >
        <Card
          sx={{
            padding: 4,
            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",
            height: "fit-content",
          }}
        >
          <FlexBox flexDirection="column" justifyContent="center">
            <FlexBox
              style={{
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38px",
                color: "#3579E3",
                fontFamily: "Barlow",
              }}
            >
              {props.title}
            </FlexBox>
            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Box
                sx={{
                  mt: 4,
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#0B0E1E",
                  minHeight: "4rem",
                  height: "fit-content",
                  width: "100%",
                }}
              >
                <FullTextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    Dashboard name
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name="label"
                    type="label"
                    onChange={handleChange}
                    value={values.label || ""}
                  />
                </FullTextFieldWrapper>
                <FullTextFieldWrapper key={`widget-settings-data-source`}>
                  <FlexBox
                    sx={{
                      // justifyContent: "space-between",
                      width: "100%",
                      // alignItems: "center",
                      padding: "0.5rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <FlexBox
                      sx={{
                        // justifyContent: "center",
                        marginBottom: "0.3rem",
                      }}
                    >
                      <FlexBox
                        sx={{
                          color: "#3A3C40",
                          lineHeight: "17px",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {"Data source"}
                      </FlexBox>
                    </FlexBox>
                    <FlexBox
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Select
                        fullWidth
                        labelId="source"
                        id="source"
                        name={"source"}
                        value={values.source}
                        multiple={true}
                        label=""
                        onChange={handleChange}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #E5EAF2",
                          maxHeight: "10rem",
                          overflow: "auto",
                          boxSizing: "border-box",
                        }}
                        renderValue={(selected) =>
                          selected &&
                          selected
                            ?.map((item: string) =>
                              apps && apps[item] ? apps[item] : ""
                            )
                            ?.join(", ")
                        }
                        // input={
                        //   <OutlinedInput
                        //     id="select-multiple-chip"
                        //     label="Chip"
                        //   />
                        // }
                        // renderValue={(selected) => (
                        //   <Box
                        //     sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        //   >
                        //     {selected.map((value) => (
                        //       <Chip key={value} label={value} />
                        //     ))}
                        //   </Box>
                        // )}
                        MenuProps={{
                          sx: {
                            zIndex: 99999999,
                            height: "18rem",
                            // maxHeight: "15rem",
                            // overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "0.4rem",
                            },
                            "&::-webkit-scrollbar-track": {
                              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#A3A6AB",
                              borderRadius: "5px",
                            },
                          },
                        }}
                      >
                        {appData.app.map(
                          (item: DATA_BY_STORE, index: number) => (
                            <MenuItem
                              value={item.appID}
                              key={`${item.appID}--menu-item-${index}`}
                            >
                              <FlexBox
                                sx={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <FlexBox
                                  sx={{
                                    width: "80%",
                                  }}
                                >
                                  <FlexBox
                                    sx={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "2rem",
                                      marginRight: "1rem",
                                    }}
                                  >
                                    <img
                                      src={DATA_SOURCE[item.store].icon}
                                      alt={"i"}
                                      height={20}
                                      width={20}
                                    />
                                  </FlexBox>
                                  <FlexBox
                                    sx={{
                                      color: "#0B0E1E",
                                      fontSize: "13px",
                                      lineHeight: "16px",
                                      fontStyle: "normal",
                                    }}
                                  >
                                    {/* ${DATA_SOURCE[item.store].label}  */}
                                    {`${
                                      item.data && item.data.name
                                        ? item.data.name
                                        : ""
                                    }`}
                                  </FlexBox>
                                </FlexBox>
                                <FlexBox
                                  sx={{
                                    width: "10%",
                                  }}
                                ></FlexBox>
                              </FlexBox>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FlexBox>
                  </FlexBox>
                </FullTextFieldWrapper>
                <FullTextFieldWrapper key={`widget-settings-date-range`}>
                  <FilterTextPopover
                    filterOptions={filterOptions}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={(filter: FilterOption) => {
                      if (filter && filter.value === "custom") {
                        setSelectedFilter(filter);
                        handleChange(filter.value);
                      } else {
                        setSelectedFilter(filter);
                        handleChange(filter.value);
                      }
                    }}
                    type={"widget"}
                  />
                </FullTextFieldWrapper>
                <FullTextFieldWrapper
                  key={`widget-settings-reset-widget-source`}
                >
                  <FlexBox
                    sx={{
                      // justifyContent: "space-between",
                      width: "100%",
                      // alignItems: "center",
                      padding: "0.5rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <FlexBox
                      sx={{
                        // justifyContent: "center",
                        marginBottom: "0.3rem",
                      }}
                    >
                      <FlexBox
                        sx={{
                          color: "#3A3C40",
                          lineHeight: "17px",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {
                          "Do you wish to reset selected source for underlying widgets?"
                        }
                      </FlexBox>
                    </FlexBox>
                    <FlexBox
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Select
                        fullWidth
                        labelId="reset"
                        id="reset"
                        name={"reset"}
                        value={values.reset}
                        multiple={false}
                        label=""
                        onChange={handleChange}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #E5EAF2",
                          maxHeight: "10rem",
                          overflow: "auto",
                          boxSizing: "border-box",
                        }}
                        MenuProps={{
                          sx: {
                            zIndex: 99999999,
                            height: "18rem",
                            // maxHeight: "15rem",
                            // overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "0.4rem",
                            },
                            "&::-webkit-scrollbar-track": {
                              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#A3A6AB",
                              borderRadius: "5px",
                            },
                          },
                        }}
                      >
                        {[
                          {
                            label: "Yes",
                            value: "yes",
                          },
                          {
                            label: "No",
                            value: "no",
                          },
                        ].map((item: Filter, index: number) => (
                          <MenuItem
                            value={item.value}
                            key={`${item.value}--menu-item-${index}`}
                          >
                            <FlexBox
                              sx={{
                                justifyContent: "space-between",
                              }}
                            >
                              <FlexBox
                                sx={{
                                  color: "#0B0E1E",
                                  fontSize: "13px",
                                  lineHeight: "16px",
                                  fontStyle: "normal",
                                }}
                              >
                                {`${item.label}`}
                              </FlexBox>
                            </FlexBox>
                          </MenuItem>
                        ))}
                      </Select>
                    </FlexBox>
                  </FlexBox>
                </FullTextFieldWrapper>
              </Box>
              <Box
                sx={{
                  mt: 4,
                  width: "100%",
                  textAlign: "end",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    border: "none",
                    "&:hover": { border: "none" },
                  }}
                  onClick={() => resetDashboardSettingsHandler()}
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    border: "none",
                    "&:hover": { border: "none" },
                  }}
                  onClick={() => props.setShow && props.setShow(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Box>
            </form>
          </FlexBox>
        </Card>
      </div>
    </div>
  );
};

export default DashboardSettingsModal;
