import { useState } from "react";
import FlexBox from "../shared/wrappers/FlexBox";
import DetailsWidget from "../Widgets/DetailsWidget";
import {
  DndContext,
  rectIntersection,
  // closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { getWidgetList, getWidgetObjects } from "../shared/widgetConstants";
import {
  AppActions,
  // AppActions,
  WidgetId,
} from "../../services/store/AppStore/AppStoreSlice";
// import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import type { DropAnimation } from "@dnd-kit/core";
// import { updateUserDetails } from "../../services/api/Graphql.api";
// import { snapCenterToCursor } from "@dnd-kit/modifiers";
import {
  getUuid,
  GET_DATA_STORE,
  updateLayoutAndUserWidgets,
} from "../shared/constants";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

export interface Widget {
  type: number;
  id: string;
  widgetId: string;
  label?: string;
  source?: GET_DATA_STORE[];
  help?: string;
  component: string;
  route?: string;
  hidden?: boolean;
  hide?: boolean;
  hideLegend?: boolean;
  positive?: Widget;
  negative?: Widget;
  subComponent?: string;
  expandable?: boolean;
  total?: number;
  lastMonthReviews?: number;
  lastMonthPercentage?: string | number;
  topReviews?: {
    rating: number;
    content: string;
  }[];
  reviews?: {
    positive: number;
    negative: number;
    clusterTopics: string;
    groupTopic: any;
    id: string;
  }[];
  dataSince?: string;
  data?: any;
  rating?: string;
  filter?: {
    label: string;
    value: string;
  };
  weightedAverage?: number;
}

type WidgetContainerProps = {
  widgets: WidgetId[];
  // TODO: Convert all widgets to id versions, and send the array of ids.
  // TODO: Create the widgets here based on id. Send back id to save in store and BE
  otherData: any;
  type: string;
  hideDraggable: boolean;
};

const WidgetContainer = ({
  widgets,
  otherData,
  type,
  hideDraggable,
}: WidgetContainerProps) => {
  // const [content, setContent] = useState<Widget[]>([]);
  const dispatch = useDispatch();

  const appData: any = useSelector((state: RootState) => state.app);
  const graphData: any = useSelector((state: RootState) => state.graphs);
  const [activeId, setActiveId] = useState(null);

  // Snap to grid
  // const [gridSize, setGridSize] = useState(30);
  // const style = {
  //   alignItems: "flex-start",
  // };
  // const buttonStyle = {
  //   marginLeft: gridSize - 20 + 1,
  //   marginTop: gridSize - 20 + 1,
  //   width: gridSize * 8 - 1,
  //   height: gridSize * 2 - 1,
  // };
  // const snapToGrid = useMemo(() => createSnapModifier(gridSize), [gridSize]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = widgets.findIndex(
        (item: WidgetId) => item.widgetId === active.id
      );
      const newIndex = widgets.findIndex(
        (item: WidgetId) => item.widgetId === over.id
      );

      const userWidgets = {
        ...appData.widgetLayout,
        [type]: {
          ...appData.widgetLayout[type],
          widgets: arrayMove(widgets, oldIndex, newIndex),
        },
      };

      updateLayoutAndUserWidgets(userWidgets, dispatch);
    }
  };

  // console.log(appData.widgetLayout)

  const handleWidgetSettings = (widgetId: string[], action: string) => {
    if (action === "removeWidget") {
      const userWidgets = {
        ...appData.widgetLayout,
        [type]: {
          ...appData.widgetLayout[type],
          widgets: [...appData.widgetLayout[type].widgets].filter(
            (item: WidgetId) => item.widgetId !== widgetId[0]
          ),
        },
      };

      updateLayoutAndUserWidgets(userWidgets, dispatch);
    }

    if (action === "replaceWidget") {
      const widgetList = getWidgetList(appData);
      const userWidgets = {
        ...appData.widgetLayout,
        [type]: {
          ...appData.widgetLayout[type],
          widgets: [...appData.widgetLayout[type].widgets].map(
            (item: WidgetId) => {
              if (item.widgetId === widgetId[0]) {
                // Return the replaced widget
                const foundIndex = widgetList.findIndex(
                  (item: WidgetId) => item.widgetId === widgetId[1]
                );
                if (foundIndex !== -1) {
                  return { ...widgetList[foundIndex] };
                }
              }
              if (item.widgetId === widgetId[1]) {
                // Return the replaced widget
                const foundIndex = widgetList.findIndex(
                  (item: WidgetId) => item.widgetId === widgetId[0]
                );
                if (foundIndex !== -1) {
                  if (
                    appData.widgetLayout[type].widgets
                      .map((w: WidgetId) => w.widgetId)
                      .includes(widgetList[foundIndex].widgetId)
                  ) {
                    return {
                      ...widgetList[foundIndex],
                      widgetId: `${widgetList[foundIndex].id}--${getUuid()}`,
                    };
                  }
                  return { ...widgetList[foundIndex] };
                }
              }
              return item;
            }
          ),
        },
      };

      updateLayoutAndUserWidgets(userWidgets, dispatch);
    }

    if (action === "editWidget") {
      // Open the Widget settings modal by setting the selected widget
      const foundIndex = appData.widgetLayout[type].widgets.findIndex(
        (item: WidgetId) => item.widgetId === widgetId[0]
      );
      if (foundIndex !== -1) {
        // Appending the type to send to widget settings for further modifications to the settings
        dispatch(
          AppActions.setWidgetSettings({
            ...appData.widgetLayout[type].widgets[foundIndex],
            type,
          })
        );
      }
    }
  };
  let widgetObjects = getWidgetObjects(widgets, appData, graphData, otherData);

  return (
    <DndContext
      sensors={sensors}
      // modifiers={[snapToGrid]}
      // modifiers={[snapCenterToCursor]}
      // collisionDetection={closestCenter}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <FlexBox
        sx={{
          width: "100%",
          height: "100%",
          //overflowY: "auto",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        <SortableContext items={widgetObjects} strategy={rectSortingStrategy}>
          {widgetObjects
            //to hide the widgets that have hidden flag as true
            .filter((con) => !con.hide)
            .map((widget: Widget, index: number) => {
              return (
                <DetailsWidget
                  data={widget}
                  index={index}
                  key={widget.widgetId}
                  id={widget.widgetId}
                  hideDraggable={hideDraggable}
                  activeIndex={
                    activeId
                      ? widgetObjects.findIndex(
                          (widget) => widget.widgetId === activeId
                        )
                      : undefined
                  }
                  handleWidgetSettings={handleWidgetSettings}
                  type={type}
                />
              );
            })}
          <DragOverlay
            // modifiers={[restrictToWindowEdges]}
            style={{
              // opacity: 1,
              // maxHeight: "360px",
              transformOrigin: "0 0 ",
            }}
            dropAnimation={dropAnimationConfig}
            // adjustScale
          >
            {activeId ? (
              <DetailsWidget
                data={
                  widgetObjects.find(
                    (item: any) => item.widgetId === activeId
                  ) || widgetObjects[0]
                }
                index={1000}
                key={activeId}
                id={activeId}
                maxHeight={"360px"}
                hideDraggable={true}
                type={type}
              />
            ) : null}
          </DragOverlay>
        </SortableContext>
        {/* {content
          //to hide the widgets that have hidden flag as true
          .filter((con) => !con.hide)
          .map((widget: Widget, index: number) => {
            return (
              <DetailsWidget
                data={widget}
                index={index}
                key={`${index}--details-widget-container`}
              />
            );
          })} */}
      </FlexBox>
    </DndContext>
  );
};

export default WidgetContainer;
