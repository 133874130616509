import Authorized from "./components/authentication/Authorized";
import Loading from "./components/shared/Loading";
import { Navigate } from "react-router-dom";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import Register from "./components/Login/Register";
import ForgotPassword from "./components/Login/ForgotPassword";
import Home from "./components/home/Home";
import Layout from "./components/home/Layout";
import CheckAuth from "./components/authentication/CheckAuth";
import HomeTest from "./components/AdminBoard/AdminBoard";
import AddApp from "./components/AddApp/AddApp";
import ProviderRedirect from "./components/Login/ProviderRedirect";
import ImportStatus from "./components/AddApp/ImportStatus";
import SettingsWrapper from "./components/Settings/SettingsWrapper";
import Feedback from "./components/Insights/Feedback";
import Sentiment from "./components/Insights/Sentiment";
import StarRating from "./components/Insights/StarRating";
import Analytics from "./components/Analytics/Analytics";
import Redirect from "./components/home/Redirect";
import IdleImport from "./components/AddApp/IdleImport";
import AddAha from "./components/Aha/AddAha";
import GenericDashboard from "./components/Insights/GenericDashboard";
import DataSource from "./components/AddApp/DataSource";
import ZapierInfo from "./components/ZapierIntegration/ZapierInfo";
import AddZapierTemplate from "./components/ZapierIntegration/AddZapierTemplate";
import ZapierFullExperience from "./components/ZapierIntegration/ZapierFullExperience";
import ZapierPartnerAPI from "./components/ZapierIntegration/PartnerAPI";
import ZapierRedirect from "./components/ZapierIntegration/ZapierRedirect";
import AddSlack from "./components/SlackIntegration/AddSlack";
import SlackRedirect from "./components/SlackIntegration/SlackRedirect";
import AppFeedback from "./components/Feedback/AppFeedback";
import AddAmazon from "./components/Amazon/AddAmazon";
import AddGithub from "./components/Github/AddGithub";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );

//Authentication Pages
const Login = Loadable(lazy(() => import("./components/Login/Login")));

// error
const Error = Loadable(lazy(() => import("./components/Unauthorized/404")));

let ADMIN_IDS: any = [];
try {
  ADMIN_IDS = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL_IDS || "[]");
} catch (e) {
  ADMIN_IDS = process.env.REACT_APP_ADMIN_EMAIL_IDS || [];
}

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <CheckAuth>
        <Login />
      </CheckAuth>
    ),
  },
  {
    path: "register",
    element: (
      <CheckAuth>
        <Register />
      </CheckAuth>
    ),
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "provider-redirect",
    element: <ProviderRedirect />,
  },
  {
    path: "dashboard",
    // Add the NavBar and Sidebar here
    element: (
      <Authorized>
        <Layout />
      </Authorized>
    ),
    // Add further layout and specific path related components here
    children: [
      {
        path: "",
        element: <Redirect />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "data-source",
        element: <DataSource />,
      },
      {
        path: "add-app",
        element: <AddApp />,
      },
      {
        path: "zapier-info",
        element: <ZapierInfo />,
      },
      {
        path: "zapier-template",
        element: <AddZapierTemplate />,
      },
      {
        path: "zapier-full-experience",
        element: <ZapierFullExperience />,
      },
      {
        path: "zapier-partner",
        element: <ZapierPartnerAPI />,
      },
      {
        path: "zapier-redirect",
        element: <ZapierRedirect />,
      },
      {
        path: "slack-redirect",
        element: <SlackRedirect />,
      },
      ...(ADMIN_IDS.includes(localStorage.getItem("username") || "")
        ? [
            {
              path: "rargus-admin",
              element: <HomeTest />,
            },
            {
              path: "rargus-feedback",
              element: <AppFeedback />,
            },
          ]
        : []),
      {
        path: "import-status",
        element: <ImportStatus />,
      },
      {
        path: "import",
        element: <IdleImport />,
      },
      {
        path: "settings",
        element: <SettingsWrapper />,
      },
      {
        path: "insights/feedback",
        element: <Feedback />,
      },
      {
        path: "insights/sentiments",
        element: <Sentiment />,
      },
      {
        path: "insights/ratings",
        element: <StarRating />,
      },
      {
        path: "insights/:path",
        element: <GenericDashboard />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "add-aha",
        element: <AddAha />,
      },
      {
        path: "add-slack",
        element: <AddSlack />,
      },
      {
        path: "add-amazon",
        element: <AddAmazon />,
      },
      {
        path: "add-github-issues",
        element: <AddGithub />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
