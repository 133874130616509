import FlexBox from "../shared/wrappers/FlexBox";

type TopicsProps = {
  data: any;
};

const Topics = ({ data }: TopicsProps) => {
  const getCount = (subClusters: any) => {
    let count = 0;
    subClusters.forEach(
      (cluster: any) => (count += cluster.sentimentData.length)
    );
    return count;
  };

  return (
    <FlexBox
      sx={{
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <FlexBox
        sx={{
          width: "48%",
          minHeight: "300px",
          maxHeight: "310px",
          flexDirection: "column",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        {data.topic && data.topic.length
          ? data.topic.map((topic: any, index: number) => {
              return (
                <FlexBox
                  sx={{
                    "&:hover": {
                      background: "#BABFC5",
                    },
                    padding: "8px 12px",
                    gap: "10px",
                    borderRadius: "4px",
                    height: "fit-content",
                    width: "100%",
                    cursor: "pointer",
                    justifyContent: "space-between",
                    ...(data.subTopic && data.subTopic.id === topic.id
                      ? { background: "#BABFC5" }
                      : {}),
                  }}
                  onClick={() => {
                    data.setSubTopic(topic);
                    data.setSubTopicCluster(undefined);
                  }}
                  key={`topic-name-${index}-${topic.groupTopic}`}
                >
                  <FlexBox>{`${index + 1}. ${
                    topic.groupTopic
                      ? topic.groupTopic.replace(/(^"|"|"|'|'|'$)/g, "")
                      : "Ungrouped topic"
                  }`}</FlexBox>
                  <FlexBox>{getCount(topic.subClusters)}</FlexBox>
                </FlexBox>
              );
            })
          : data.topic.length === 0 && (
              <FlexBox
                sx={{
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#3A3C40",
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                No topics in selected range
              </FlexBox>
            )}
      </FlexBox>
      <FlexBox
        sx={{
          width: "48%",
          borderRadius: "4px",
          overflowY: "auto",
          minHeight: "300px",
          maxHeight: "310px",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        {data.topic && data.topic.length !== 0 && data.subTopic && (
          <FlexBox
            sx={{
              background: "#E3E6EB",
              "&:hover": {
                background: "#BABFC5",
              },
              padding: "8px 12px",
              gap: "10px",
              height: "fit-content",
              width: "100%",
              cursor: "pointer",
              justifyContent: "space-between",
              ...(!data.subTopicCluster ? { background: "#BABFC5" } : {}),
            }}
            key={`subtopic-name-all-cluster`}
            onClick={() => {
              if (
                data.subTopic.subClusters &&
                data.subTopic.subClusters.length &&
                data.subTopic.subClusters[0].sentimentData &&
                data.subTopic.subClusters[0].sentimentData.length &&
                data.setFilteredBarLineReviews
              ) {
                let reviewObj: any[] = [];
                data.subTopic.subClusters.forEach((sub: any) => {
                  reviewObj = reviewObj.concat(sub.sentimentData);
                });
                data.setFilteredBarLineReviews(reviewObj);
                data.setSubTopicCluster(undefined);
              }
            }}
          >
            <FlexBox>{"all"}</FlexBox>
            <FlexBox>
              {data.subTopic.clusterGroup !== "0"
                ? getCount(data.subTopic.subClusters)
                : data.subTopic.count}
            </FlexBox>
          </FlexBox>
        )}
        {data.subTopic &&
        data.subTopic.clusterGroup !== "0" &&
        data.subTopic.subClusters ? (
          data.subTopic.subClusters.map((subTopic: any, index: number) => {
            return (
              <FlexBox
                sx={{
                  background: "#E3E6EB",
                  "&:hover": {
                    background: "#BABFC5",
                  },
                  padding: "8px 12px",
                  gap: "10px",
                  height: "fit-content",
                  width: "100%",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  ...(data.subTopicCluster &&
                  data.subTopicCluster.clusterLabel === subTopic.clusterLabel
                    ? { background: "#BABFC5" }
                    : {}),
                }}
                key={`subtopic-name-${index}`}
                onClick={() => {
                  if (
                    subTopic &&
                    subTopic.sentimentData &&
                    data.setFilteredBarLineReviews
                  ) {
                    data.setFilteredBarLineReviews(subTopic.sentimentData);
                    data.setSubTopicCluster(subTopic);
                  }
                }}
              >
                <FlexBox>
                  {subTopic.clusterTopics
                    ? subTopic.clusterTopics.replace(/(^"|"|"|'|'|'$)/g, "")
                    : "Ungrouped subtopic"}
                </FlexBox>
                <FlexBox>
                  {subTopic.sentimentData ? subTopic.sentimentData.length : 0}
                </FlexBox>
              </FlexBox>
            );
          })
        ) : data.subTopic && data.subTopic.clusterGroup !== "0" ? (
          <FlexBox
            sx={{
              fontSize: "14px",
              lineHeight: "17px",
              color: "#3A3C40",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            No subtopics in selected range
          </FlexBox>
        ) : (
          data.subTopic && (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              Topic has no subtopics
            </FlexBox>
          )
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default Topics;
