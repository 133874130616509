import FlexBox from "../shared/wrappers/FlexBox";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import { setWithExpiry } from "../shared/constants";

const ZapierRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let flag = true;
    if (location && location.hash) {
      let hash = location.hash;
      if (!hash.includes("error")) {
        let hashArray = hash.replace("#", "").split("&");
        const foundIndex = hashArray.findIndex((item: string) =>
          item.includes("access_token")
        );

        if (foundIndex !== -1) {
          try {
            const access_token = hashArray[foundIndex].split("=")[1];
            flag = false;
            console.log("zapier-token", access_token);
            setWithExpiry("zapier-token", access_token, 300);
            navigate("/dashboard/zapier-partner");
          } catch (e) {
            console.log("Some error happened");
          }
        }
      }
    }
    if (flag) {
      console.log("Access Token doesn't exists in the hash. Redirecting");
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <LoadingWrapper />
    </FlexBox>
  );
};

export default ZapierRedirect;
