import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useContext } from "react";
import { FormContext, FormContextAttr } from "../../Aha/AddAha";
import FlexBox from "./FlexBox";
import ArrowGreyIcon from "../icons/arrow-grey.svg";

type CustomizedSteppersProps = {
  steps: string[];
  activeStepIndex: number;
};

const CustomizedSteppers = ({ steps }: CustomizedSteppersProps) => {
  const { activeStepIndex, setActiveStepIndex } = useContext(
    FormContext
  ) as FormContextAttr;
  return (
    <Stack sx={{ width: "100%" }} spacing={4} mb={2}>
      <Stepper
        activeStep={activeStepIndex}
        connector={
          <FlexBox
            sx={{
              transform: "rotate(270deg)",
            }}
          >
            <img src={ArrowGreyIcon} alt=">" width="16px" height="16px" />
          </FlexBox>
        }
        sx={{
          color: "#82868C",
          fontSize: "10px",
          lineHeight: "14px",
          fontWeight: 600,
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => {
              if (
                index < activeStepIndex &&
                activeStepIndex !== steps.length - 1
              ) {
                setActiveStepIndex(index);
              }
              // setActiveStepIndex(index);
            }}
          >
            <StepLabel
              StepIconProps={{
                sx: {
                  color: "#82868C",
                  fontSize: "10px",
                  lineHeight: "14px",
                  fontWeight: 600,
                },
              }}
              StepIconComponent={() => <></>}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomizedSteppers;
