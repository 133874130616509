import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import {
  CheckboxFilterPopover,
  CheckboxGroupFilterPopover,
  Filter,
  FilterAdder,
  FilterTextPopover,
  TextFilterPopover,
} from "../shared/wrappers/FilterPopper";
import WidgetContainer from "../Insights/WidgetContainer";
import {
  constructLegend,
  filterOptions,
  COMPLAINTS,
  COMPLIMENTS,
  SUGGESTIONS,
  FilterOption,
  getFilterValues,
  STORE,
  distictObjectById,
  keywordOrSplit,
  getDataByStore,
  DATA_BY_STORE,
  GET_DATA_STORE,
} from "../shared/constants";
import { useState, useEffect, useCallback } from "react";
import SubClusters from "./SubClusters";
import Cluster, { getSubClusterCount, getWorkspaceCount } from "./Cluster";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { useLocation } from "react-router";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { getTotalNumberOfVotes } from "../Widgets/InnerComponents";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import AhaIcon from "../shared/icons/aha.svg";
import CSVIcon from "../shared/icons/csv.svg";
import SlackIcon from "../shared/icons/slack.svg";
import AmazonIcon from "../shared/icons/amazon.svg";
import GithubIcon from "../shared/icons/github.svg";
import SOFIcon from "../shared/icons/sof.svg";
import { getAnalyticsRelevancyReportData } from "../shared/widgetData";
import ReviewColumn from "../ReviewComponents/ReviewColumn";

export interface Review {
  appID: string;
  content: string;
  createdAt?: string;
  date: string;
  id: string;
  rating: number;
  replyContent: string;
  reviewID: string;
  sentiment?: string;
  sentimentCategory: string;
  sentimentScore: string;
  updatedAt?: string;
  username: string;
  ideaReference?: string;
  ideaCategory?: string;
  workspaceName?: string;
  status?: string;
  title?: string;
  reviewMapping?: ReviewPoint;
  company?: string;
  specialist?: string;
  destination?: string;
  url?: string;
  state?: string;
  repositoryOwner?: string;
  repositoryName?: string;
  store?: string;
  tags?: string[];
}

export type ReviewPoint = {
  reviewId: string;
  point: string;
};

const Analytics = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const appData: any = useSelector((store: RootState) => store.app);

  // Popular keywords filter for STORE aha
  const popularKeywords = [
    "Public Cloud",
    "Private Cloud",
    "Hybrid Cloud",
    "Kubernetes (K8s)",
    "Optimization",
    "Migration",
    "vSphere",
    "Cost",
    "Performance",
    "Security",
  ];
  const [keywords, setKeywords] = useState<Filter[]>([]);
  const [keywordArray, setKeywordArray] = useState<Filter[]>([]);

  // Location
  const location = useLocation();

  // Cluster Filter States
  const [compliments, setCompliments] = useState(true);
  const [complaints, setComplaints] = useState(true);
  const [suggestions, setSuggestions] = useState(true);

  // Subtopic
  const [selectedSubtopic, setSelectedSubtopic] = useState<any>();
  // Filtered Sub cluster
  const [filteredSubcluster, setFilteredSubcluster] = useState<any>();

  // Selected SubCluster for review
  const [selectedSubcluster, setSelectedSubcluster] = useState<any>();

  // Filtered Cluster Data
  const [filComplimentsData, setFilComplimentsData] = useState<any[]>([]);
  const [filComplaintsData, setFilComplaintsData] = useState<any[]>([]);
  const [filSuggestionsData, setFilSuggestionsData] = useState<any[]>([]);

  // Filtered Review Ids
  const [reviewId, setReviewId] = useState<string[]>([]);

  const [filteredReviews, setFilteredReviews] = useState<Review[]>([]);

  const [openLocation, setOpenLocation] = useState(false);

  // State to create the Review filter options based on status available
  const [reviewFilter, setReviewFilter] = useState<Filter[]>([]);
  const [selectedReviewFilter, setSelectedReviewFilter] = useState<Filter[]>(
    []
  );

  // State to create the Workspace filter options based on workspace
  const [workspaceFilter, setWorkspaceFilter] = useState<Filter[]>([]);
  const [selectedWorkspaceFilter, setSelectedWorkspaceFilter] = useState<
    Filter[]
  >([]);

  // Maininting sentimnets that pass the global filter
  const [filteredSentiments, setFilteredSentiments] = useState<Review[]>([]);

  // State to create the Workspace filter options based on workspace
  const [insightsFilter, setInsightsFilter] = useState<Filter[]>([]);
  const [selectedInsightsFilter, setSelectedInsightsFilter] = useState<
    Filter[]
  >([]);

  // State to create the Data Source Filter
  const [dataSourceFilter, setDataSourceFilter] = useState<Filter[]>([
    {
      label: "App store",
      value: "apple",
      icon: AppleStoreIcon,
      source: STORE.apple,
    },
    {
      label: "Play store",
      value: "google",
      icon: PlayStoreIcon,
      source: STORE.google,
    },
    {
      label: "Aha!",
      value: "aha",
      icon: AhaIcon,
      source: STORE.aha,
    },
    {
      label: "CSV",
      value: "csv",
      icon: CSVIcon,
      source: STORE.csv,
    },
    {
      label: "Stack overflow",
      value: STORE.sof,
      icon: SOFIcon,
      source: STORE.sof,
    },
    {
      label: "Slack",
      value: "slack",
      icon: SlackIcon,
      source: STORE.slack,
    },
    {
      label: "Amazon",
      value: STORE.amazon,
      icon: AmazonIcon,
      source: STORE.amazon,
    },
    {
      label: "Github",
      value: STORE.github,
      icon: GithubIcon,
      source: STORE.github,
    },
  ]);
  const [selectedDataSourceFilter, setSelectedDataSourceFilter] = useState<
    Filter[]
  >([]);

  // States to maintain the apps shown
  const [apps, setApps] = useState<Filter[]>([]);
  const [selectedApps, setSelectedApps] = useState<Filter[]>([]);

  // CSV Filters: Combined state management
  // const [csvFilters, setCsvFilter] = useState<{
  //   company: Filter[];
  //   specialist: Filter[];
  //   destination: Filter[];
  // }>({ company: [], specialist: [], destination: [] });
  // const [selectedCsvFilter, setSelectedCsvFilter] = useState<{
  //   company: Filter[];
  //   specialist: Filter[];
  //   destination: Filter[];
  // }>({ company: [], specialist: [], destination: [] });

  // Keyword filter for the csv data
  const [csvKeywords, setCsvKeywords] = useState<Filter[]>([]);
  const [csvKeywordArray, setCsvKeywordArray] = useState<Filter[]>([]);

  // Most Least votes sort for review
  const [reviewSort, setReviewSort] = useState({
    label: "most votes",
    value: "mostVotes",
  });

  // Sort for topic based on votes/workspace
  const [topicSort, setTopicSort] = useState({
    label: "# votes",
    value: "numberOfVotes",
  });

  // Cluster points array & the review point mapping array to be used for highlighting
  const [clusterPoints, setClusterPoints] = useState<ReviewPoint[]>([]);
  const [reviewPoints, setReviewPoints] = useState<ReviewPoint[]>([]);

  // State to maintain the filters to show
  const topicFilters: Filter[] = [
    {
      label: "Insights type",
      value: "insights",
    },
    ...(appData.store &&
    appData.store.length &&
    appData.store.includes(STORE.aha)
      ? [
          {
            label: "Workspace",
            value: "workspace",
          },
        ]
      : []),
    {
      label: "Keywords",
      value: "keywords",
    },
    ...((appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.github))) ||
    appData.store.includes(STORE.sof)
      ? [
          {
            label: "Name search",
            value: "csvKeywords",
          },
          // {
          //   label: "Specialist Name",
          //   value: "specialist",
          // },
          // {
          //   label: "Company Name",
          //   value: "company",
          // },
          // {
          //   label: "Destination Name",
          //   value: "destination",
          // },
        ]
      : []),
    // ...(appData.store &&
    // appData.store.length &&
    // (appData.store.includes(STORE.csv) ||
    //   appData.store.includes(STORE.google) ||
    //   appData.store.includes(STORE.apple))
    //   ? [
    //       {
    //         label: "App",
    //         value: "apps",
    //       },
    //     ]
    // : []),
  ];
  const reviewFilters: Filter[] = [
    {
      label: "Status",
      value: "status",
    },
  ];
  const [selectedTopicFilters, setSelectedTopicFilters] = useState<Filter[]>(
    []
  );
  const [selectedReviewFilters, setSelectedReviewFilters] = useState<Filter[]>(
    []
  );

  // States to open/close filters
  const [openFilters, setOpenFilters] = useState({
    insights: false,
    workspace: false,
    keywords: false,
    status: false,
    csvKeywords: false,
  });

  const [defaultStore, setDefaultStore] = useState(
    appData.app && appData.app.length
      ? appData.app.map((app: DATA_BY_STORE) => ({
          store: app.store,
          appID: app.appID,
        }))
      : // .map((s: GET_DATA_STORE) => s.store)
        []
  );

  useEffect(() => {
    if (
      // selectedTopicFilters.length &&
      // selectedTopicFilters.findIndex((fil: Filter) => fil.value === "apps") !==
      //   -1 &&
      selectedApps
    ) {
      // When the apps filter is selected
      const selApps = selectedApps.map((item: Filter) => item.value);
      setDefaultStore(
        appData.app
          .map((app: DATA_BY_STORE) => ({
            store: app.store,
            appID: app.appID,
          }))
          .filter((item: any) => selApps.includes(item.appID))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApps]);

  // useEffect(() => {
  //   if (
  //     selectedTopicFilters.findIndex((fil: Filter) => fil.value === "apps") ===
  //       -1 &&
  //     apps.length !== selectedApps.length
  //   ) {
  //     // When the apps filter is not selected
  //     setDefaultStore(
  //       appData.app.map((app: DATA_BY_STORE) => ({
  //         store: app.store,
  //         appID: app.appID,
  //       }))
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedInsightsFilter]);

  useEffect(() => {
    if (selectedDataSourceFilter) {
      let stores =
        appData.app && appData.app.length
          ? appData.app.map((app: DATA_BY_STORE) => ({
              store: app.store,
              appID: app.appID,
            }))
          : [];
      let selectedFilters = selectedDataSourceFilter
        ? selectedDataSourceFilter.map((filter: FilterOption) => filter.value)
        : [];

      let selStores = stores.filter((store: GET_DATA_STORE) =>
        selectedFilters.includes(store.store)
      );

      setDefaultStore(
        selStores
        // .map((s: GET_DATA_STORE) => s.store)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSourceFilter]);

  // Below functions are for keyword match
  const getKeywordFilterArray = (keywords: Filter[]) => {
    return keywords.map((fil) =>
      fil.value.match("(|)")
        ? fil.value
            .split("(")
            .join(keywordOrSplit)
            .replace(")", "")
            .toLowerCase()
        : fil.value.toLowerCase()
    );
  };

  const checkKeywordMatch = (
    keywordsFilterArray: string[],
    sentiment: Review
  ) => {
    return keywordsFilterArray.some((k) =>
      k.includes(keywordOrSplit)
        ? sentiment.content
            .toLowerCase()
            .includes(k.toLowerCase().split(keywordOrSplit)[0]) ||
          sentiment.content
            .toLowerCase()
            .includes(k.toLowerCase().split(keywordOrSplit)[1])
        : sentiment.content.toLowerCase().includes(k.toLowerCase())
    );
  };

  const checkCsvKeywordMatch = (
    keywordsFilterArray: string[],
    sentiment: Review
  ) => {
    return keywordsFilterArray.some(
      (k) =>
        (k.includes(keywordOrSplit)
          ? (sentiment.company &&
              sentiment.company
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[0])) ||
            (sentiment.company &&
              sentiment.company
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[1]))
          : sentiment.company &&
            sentiment.company.toLowerCase().includes(k.toLowerCase())) ||
        (k.includes(keywordOrSplit)
          ? (sentiment.destination &&
              sentiment.destination
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[0])) ||
            (sentiment.destination &&
              sentiment.destination
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[1]))
          : sentiment.destination &&
            sentiment.destination.toLowerCase().includes(k.toLowerCase())) ||
        (k.includes(keywordOrSplit)
          ? (sentiment.specialist &&
              sentiment.specialist
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[0])) ||
            (sentiment.specialist &&
              sentiment.specialist
                .toLowerCase()
                .includes(k.toLowerCase().split(keywordOrSplit)[1]))
          : sentiment.specialist &&
            sentiment.specialist.toLowerCase().includes(k.toLowerCase())) ||
        (k.includes(keywordOrSplit)
          ? (sentiment.tags &&
              sentiment.tags.length &&
              sentiment.tags
                .map((t: string) => t.toLowerCase())
                .includes(k.toLowerCase().split(keywordOrSplit)[0])) ||
            (sentiment.tags &&
              sentiment.tags.length &&
              sentiment.tags
                .map((t: string) => t.toLowerCase())
                .includes(k.toLowerCase().split(keywordOrSplit)[1]))
          : sentiment.tags &&
            sentiment.tags.length &&
            sentiment.tags
              .map((t: string) => t.toLowerCase())
              .includes(k.toLowerCase()))
    );
  };

  // Get the three filter arrays
  const getFilterArrays = () => {
    let reviewFilterArray: string[] = [];
    let workspaceFilterArray: string[] = [];
    let keywordsFilterArray: string[] = [];
    let csvKeywordsFilterArray: string[] = [];

    if (
      selectedReviewFilters.findIndex(
        (fil: Filter) => fil.value === "status"
      ) !== -1
    ) {
      reviewFilterArray = selectedReviewFilter.map((fil) => fil.value);
    } else {
      reviewFilterArray = reviewFilter.map((fil) => fil.value);
    }
    if (
      selectedTopicFilters.findIndex(
        (fil: Filter) => fil.value === "workspace"
      ) !== -1
    ) {
      workspaceFilterArray = selectedWorkspaceFilter.map((fil) => fil.value);
    } else {
      workspaceFilterArray = workspaceFilter.map((fil) => fil.value);
    }

    if (
      selectedTopicFilters.findIndex(
        (fil: Filter) => fil.value === "keywords"
      ) !== -1
    ) {
      keywordsFilterArray = getKeywordFilterArray(keywords);
    } else {
      keywordsFilterArray = [];
    }

    if (
      selectedTopicFilters.findIndex(
        (fil: Filter) => fil.value === "csvKeywords"
      ) !== -1
    ) {
      csvKeywordsFilterArray = getKeywordFilterArray(csvKeywords);
    } else {
      csvKeywordsFilterArray = [];
    }

    return [
      reviewFilterArray,
      workspaceFilterArray,
      keywordsFilterArray,
      csvKeywordsFilterArray,
    ];
  };

  // Customize the below function to get different variations of keywords to search for
  const getHighlightKeywords = (keywords: Filter[]) => {
    let highlight: string[] = [];

    if (
      selectedTopicFilters.findIndex(
        (fil: Filter) => fil.value === "keywords"
      ) !== -1
    ) {
      getKeywordFilterArray(keywords).forEach((k: string) => {
        if (k.includes(keywordOrSplit)) {
          let arr = k.split(keywordOrSplit);
          highlight = highlight.concat(arr);
        } else {
          highlight.push(k);
        }
      });
    }

    return highlight;
  };

  const getReviewPointMapping = useCallback(() => {
    let reviewMapping: ReviewPoint[] = [];

    if (filteredSubcluster) {
      if (
        filteredSubcluster.clusterGroup !== "0" &&
        filteredSubcluster.subClusters &&
        filteredSubcluster.subClusters.length
      ) {
        filteredSubcluster.subClusters.forEach((sub: any) => {
          if (sub.reviewMapping && sub.reviewMapping.length) {
            reviewMapping = reviewMapping.concat(sub.reviewMapping);
          }
        });
      }
      if (
        filteredSubcluster.clusterGroup === "0" &&
        filteredSubcluster.clusterTopics
      ) {
        if (
          filteredSubcluster.reviewMapping &&
          filteredSubcluster.reviewMapping.length
        ) {
          reviewMapping = reviewMapping.concat(
            filteredSubcluster.reviewMapping
          );
        }
      }
    }

    // console.log(subStrings);
    return reviewMapping;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSubcluster]);

  // use effect to determine the cluster points
  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      appData.store.includes(STORE.aha)
    ) {
      // The process of getting the cluster points an creating sub string from that
      // has been commented out for the time being.
      // If it is required in future, get the code from constants.

      let reviewPoints = getReviewPointMapping();
      // console.log(reviewPoints);
      setReviewPoints(reviewPoints);
    }

    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.slack))
    ) {
      let points = getReviewPointMapping();
      let clusteredPoints = distictObjectById(points, "point");
      setClusterPoints(clusteredPoints);

      // Set all the review points in this
      const complaintReviewMapping = getDataByStore(
        appData.complaintReviewMapping,
        defaultStore
      );
      const complimentReviewMapping = getDataByStore(
        appData.complimentReviewMapping,
        defaultStore
      );
      const suggestionReviewMapping = getDataByStore(
        appData.suggestionReviewMapping,
        defaultStore
      );
      if (
        complaintReviewMapping &&
        complimentReviewMapping &&
        suggestionReviewMapping
      ) {
        let reviewIds = clusteredPoints.map(
          (item: ReviewPoint) => item.reviewId
        );
        setReviewPoints(
          [
            ...complaintReviewMapping,
            ...complimentReviewMapping,
            ...suggestionReviewMapping,
          ].filter((item: ReviewPoint) => reviewIds.includes(item.reviewId))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSubcluster]);

  const clusterSort = (a: any, b: any) => {
    if (
      appData.store &&
      appData.store.length &&
      appData.store.includes(STORE.aha)
    ) {
      // Sort based on the topicSort Filter
      // Sort based on the number of votes
      if (topicSort && topicSort.value === "numberOfVotes") {
        let bCount =
          b.clusterGroup !== "0"
            ? getTotalNumberOfVotes(b, undefined)
            : getTotalNumberOfVotes(undefined, b);
        let aCount =
          a.clusterGroup !== "0"
            ? getTotalNumberOfVotes(a, undefined)
            : getTotalNumberOfVotes(undefined, a);
        return bCount - aCount;
      }

      // Sort based on the count of workspaces
      if (topicSort && topicSort.value === "numberOfWorkspaces") {
        let workspacesInA: Filter[] = getWorkspaceCount(a);
        let workspacesInB: Filter[] = getWorkspaceCount(b);

        return workspacesInB.length - workspacesInA.length;
      }
    } else if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.slack))
    ) {
      let bCount =
        b.clusterGroup !== "0"
          ? getSubClusterCount(b.subClusters)
          : b.sentimentData.length;
      let aCount =
        a.clusterGroup !== "0"
          ? getSubClusterCount(a.subClusters)
          : a.sentimentData.length;
      return bCount - aCount;
    }
    return 0;
  };

  useEffect(() => {
    if (appData.store && appData.store !== "") {
      if (appData.store.length && appData.store.includes(STORE.aha)) {
        setDataSourceFilter(
          dataSourceFilter.map((fil: Filter) => ({
            ...fil,
            disabled: fil.source !== STORE.aha,
          }))
        );

        setSelectedDataSourceFilter(
          dataSourceFilter.filter((fil: Filter) => fil.source === STORE.aha)
        );
      }

      if (
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.apple) ||
          appData.store.includes(STORE.google) ||
          appData.store.includes(STORE.slack))
      ) {
        setDataSourceFilter(
          dataSourceFilter.map((fil: Filter) => ({
            ...fil,
            disabled: !appData.store.includes(fil.source),
          }))
        );

        setSelectedDataSourceFilter(
          dataSourceFilter.filter((fil: Filter) =>
            appData.store.includes(fil.source)
          )
        );
      }

      if (
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.csv) ||
          appData.store.includes(STORE.sof) ||
          appData.store.includes(STORE.github) ||
          appData.store.includes(STORE.amazon))
      ) {
        setDataSourceFilter(
          dataSourceFilter.map((fil: Filter) => ({
            ...fil,
            disabled: !appData.store.includes(fil.source),
          }))
        );

        setSelectedDataSourceFilter(
          dataSourceFilter.filter((fil: Filter) =>
            appData.store.includes(fil.source)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.store]);

  // useEffect to set the apps shown on the page
  useEffect(() => {
    if (appData.app && appData.app.length) {
      const tempAppArray = appData.app.map((app: any) => ({
        label: app.data.name || app.data.title || app.data.developer,
        value: app.appID,
        icon:
          app.store === STORE.csv
            ? CSVIcon
            : app.store === STORE.slack
            ? SlackIcon
            : app.store === STORE.aha
            ? AhaIcon
            : app.store === STORE.sof
            ? SOFIcon
            : app.store === STORE.amazon
            ? AmazonIcon
            : app.store === STORE.github
            ? GithubIcon
            : app.data.icon
            ? app.data.icon
            : "",
        source: app.store,
      }));

      setApps(tempAppArray);
      setSelectedApps(tempAppArray);
    }
  }, [appData.app]);

  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.aha) ||
        appData.store.includes(STORE.github))
    ) {
      let reviewFilters: Filter[] = [];
      let workspaceFilters: Filter[] = [];
      getDataByStore(appData.sentiments, defaultStore).forEach(
        (rev: Review) => {
          if (
            rev.status &&
            reviewFilters.findIndex((fil: any) => fil.label === rev.status) ===
              -1
          ) {
            reviewFilters.push({
              label: rev.status,
              value: rev.status,
            });
          }

          if (
            rev.workspaceName &&
            workspaceFilters.findIndex(
              (fil: any) => fil.label === rev.workspaceName
            ) === -1
          ) {
            workspaceFilters.push({
              label: rev.workspaceName,
              value: rev.workspaceName,
            });
          }
        }
      );

      // Set the review filters
      setReviewFilter(reviewFilters);
      setSelectedReviewFilter(reviewFilters);

      // Set the workspace filters
      setWorkspaceFilter(workspaceFilters);
      setSelectedWorkspaceFilter(workspaceFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.sentiments, defaultStore]);

  const getInsightsFilter = () => {
    let insightsFilter: Filter[] = [];

    const complaintCluster = getDataByStore(
      appData.complaintCluster,
      defaultStore
    );

    const complimentCluster = getDataByStore(
      appData.complimentCluster,
      defaultStore
    );
    const suggestionCluster = getDataByStore(
      appData.suggestionCluster,
      defaultStore
    );

    if (complaintCluster && complaintCluster.length) {
      insightsFilter.push({
        label: "Complaints",
        value: "complaints",
      });
    }
    if (complimentCluster && complimentCluster.length) {
      insightsFilter.push({
        label: "Compliments",
        value: "compliments",
      });
    }
    if (suggestionCluster && suggestionCluster.length) {
      insightsFilter.push({
        label: "Suggestions",
        value: "suggestions",
      });
    }

    return insightsFilter;
  };

  useEffect(() => {
    // Adding Insights Filter to both App Flow and Aha flow
    if (appData.store && appData.store.length) {
      const insightsFilter = getInsightsFilter();

      setInsightsFilter(insightsFilter);
      setSelectedInsightsFilter(insightsFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appData.complaintCluster,
    appData.complimentCluster,
    appData.suggestionCluster,
    appData.store,
    // defaultStore,
  ]);

  // Adding Popular keywords for Aha Flow. Continuing with empty keywords array for Normal App flow
  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      appData.store.includes(STORE.aha) &&
      keywordArray.length === 0
    ) {
      setKeywordArray(
        [...popularKeywords].map((keyword: string) => ({
          label: keyword,
          value: keyword.toLowerCase(),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.store]);

  useEffect(() => {
    let filteredData = selectedSubtopic
      ? [...getDataByStore(appData.sentiments, defaultStore)]
      : [];
    let filComplaints: any[] = [];
    let filCompliments: any[] = [];
    let filSuggestions: any[] = [];

    // // Filter neutral reviews
    // filteredData.forEach((data) => {
    //   if (
    //     data.sentimentScore &&
    //     parseFloat(data.sentimentScore) <= 0.1 &&
    //     parseFloat(data.sentimentScore) >= -0.1
    //   ) {
    //     console.log(data.sentimentScore);
    //   }
    // });
    if (filteredSubcluster) {
      let reviewIds: string[] = [];
      if (filteredSubcluster.clusterGroup !== "0") {
        filteredSubcluster.subClusters.forEach((sub: any) => {
          reviewIds = reviewIds.concat(sub.reviewId);
        });
      }

      if (filteredSubcluster.clusterGroup === "0") {
        reviewIds = reviewIds.concat(filteredSubcluster.reviewId);
      }

      // Ids of the reviews to fetch from sentiment analysis
      filteredData = filteredData.filter((fil) =>
        reviewIds.includes(fil.reviewID)
      );
    }

    if (selectedSubcluster) {
      // Ids of the reviews to fetch from sentiment analysis
      filteredData = filteredData.filter((fil) =>
        selectedSubcluster.reviewId.includes(fil.reviewID)
      );
    }

    // // Rating and Sentiment score is limited to apps from apple or google
    // if (
    //   appData.store &&
    //   appData.store.length &&
    //   (appData.store.includes(STORE.apple) ||
    //     appData.store.includes(STORE.google) ||
    //     appData.store.includes(STORE.csv))
    // ) {

    // }

    const [
      reviewFilterArray,
      workspaceFilterArray,
      keywordsFilterArray,
      csvKeywordsFilterArray,
    ] = getFilterArrays();

    // For Aha Data source
    // Different Filters: status and workspace
    if (
      appData.store &&
      appData.store.length &&
      appData.store.includes(STORE.aha)
    ) {
      // Based on status

      filteredData = filteredData.filter((fil) =>
        reviewFilterArray && reviewFilterArray.length === 0
          ? true
          : reviewFilterArray.includes(fil.status)
      );

      // Based on workspaceName
      filteredData = filteredData.filter((fil) =>
        workspaceFilterArray && workspaceFilterArray.length === 0
          ? true
          : workspaceFilterArray.includes(fil.workspaceName)
      );
    }
    // For Kim kIm Data source
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.amazon)) &&
      csvKeywordsFilterArray.length !== 0
    ) {
      // Based on company, destination and specialist
      filteredData = filteredData.filter((fil) =>
        checkCsvKeywordMatch(csvKeywordsFilterArray, fil)
      );
    }

    // Keywords filter is common for all
    // Based on keywords
    // Some will return true if there is at least one match
    if (
      keywords &&
      keywords.length &&
      selectedTopicFilters.findIndex(
        (fil: Filter) => fil.value === "keywords"
      ) !== -1
    ) {
      filteredData = filteredData.filter((fil) =>
        checkKeywordMatch(keywordsFilterArray, fil)
      );
    }

    if (
      appData.store &&
      appData.store.length &&
      appData.store.includes(STORE.github)
    ) {
      // Based on status
      filteredData = filteredData.filter((fil) =>
        reviewFilterArray && reviewFilterArray.length === 0
          ? true
          : reviewFilterArray.includes(fil.status)
      );
    }

    // Global Filter Values are set here
    if (appData.filter) {
      const { expression } = getFilterValues(appData);

      try {
        filteredData = filteredData.filter((rev) => {
          return expression(rev.date);
        });

        const complaintCluster = getDataByStore(
          appData.complaintCluster,
          defaultStore
        );
        const complimentCluster = getDataByStore(
          appData.complimentCluster,
          defaultStore
        );
        const suggestionCluster = getDataByStore(
          appData.suggestionCluster,
          defaultStore
        );

        complaintCluster.forEach((comp: any) => {
          let subCluster: any[] = [];
          if (comp.clusterGroup !== "0") {
            comp.subClusters.forEach((sub: any) => {
              let workspaces: Filter[] = [];
              let subC = sub.sentimentData.filter((sent: Review) => {
                // Keywords needs to pass for both app and aha
                let acceptFlag =
                  expression(sent.date) &&
                  (keywordsFilterArray && keywordsFilterArray.length !== 0
                    ? checkKeywordMatch(keywordsFilterArray, sent)
                    : true) &&
                  // For the csv data source
                  (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                    ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                    : true);

                if (
                  appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.aha)
                ) {
                  if (
                    workspaces.findIndex(
                      (space: Filter) => space.value === sent.workspaceName
                    ) === -1 &&
                    acceptFlag
                  ) {
                    workspaces.push({
                      label: sent.workspaceName ? sent.workspaceName : "",
                      value: sent.workspaceName ? sent.workspaceName : "",
                    });
                  }
                  return (
                    workspaceFilterArray.includes(
                      sent.workspaceName ? sent.workspaceName : ""
                    ) && acceptFlag
                  );
                }
                return acceptFlag;
              });
              subCluster.push({
                ...sub,
                sentimentData: subC,
                workspaces,
              });
            });

            filComplaints.push({
              ...comp,
              subClusters: subCluster.filter(
                (clus) => clus.sentimentData.length !== 0
              ),
            });
          }

          if (comp.clusterGroup === "0") {
            let workspaces: Filter[] = [];
            let subC = comp.sentimentData.filter((sent: Review) => {
              let acceptFlag =
                expression(sent.date) &&
                (keywordsFilterArray && keywordsFilterArray.length !== 0
                  ? checkKeywordMatch(keywordsFilterArray, sent)
                  : true) &&
                // For the csv data source
                (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                  ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                  : true);

              if (
                appData.store &&
                appData.store.length &&
                appData.store.includes(STORE.aha)
              ) {
                if (
                  workspaces.findIndex(
                    (space: Filter) => space.value === sent.workspaceName
                  ) === -1 &&
                  acceptFlag
                ) {
                  workspaces.push({
                    label: sent.workspaceName ? sent.workspaceName : "",
                    value: sent.workspaceName ? sent.workspaceName : "",
                  });
                }
                return (
                  acceptFlag &&
                  workspaceFilterArray.includes(
                    sent.workspaceName ? sent.workspaceName : ""
                  )
                );
              }
              return acceptFlag;
            });
            filComplaints.push({
              ...comp,
              sentimentData: subC,
              workspaces,
            });
          }
        });

        complimentCluster.forEach((comp: any, index: number) => {
          let subCluster: any[] = [];
          if (comp.clusterGroup !== "0") {
            comp.subClusters.forEach((sub: any) => {
              let workspaces: Filter[] = [];
              let subC = sub.sentimentData.filter((sent: Review) => {
                let acceptFlag =
                  expression(sent.date) &&
                  (keywordsFilterArray && keywordsFilterArray.length !== 0
                    ? checkKeywordMatch(keywordsFilterArray, sent)
                    : true) &&
                  // For the csv data source
                  (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                    ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                    : true);

                if (
                  appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.aha)
                ) {
                  if (
                    workspaces.findIndex(
                      (space: Filter) => space.value === sent.workspaceName
                    ) === -1 &&
                    acceptFlag
                  ) {
                    workspaces.push({
                      label: sent.workspaceName ? sent.workspaceName : "",
                      value: sent.workspaceName ? sent.workspaceName : "",
                    });
                  }

                  return (
                    acceptFlag &&
                    workspaceFilterArray.includes(
                      sent.workspaceName ? sent.workspaceName : ""
                    )
                  );
                }
                return acceptFlag;
              });
              subCluster.push({
                ...sub,
                sentimentData: subC,
                workspaces,
              });
            });

            filCompliments.push({
              ...comp,
              subClusters: subCluster.filter(
                (clus) => clus.sentimentData.length !== 0
              ),
            });
          }

          if (comp.clusterGroup === "0") {
            let workspaces: Filter[] = [];
            let subC = comp.sentimentData.filter((sent: Review) => {
              let acceptFlag =
                expression(sent.date) &&
                (keywordsFilterArray && keywordsFilterArray.length !== 0
                  ? checkKeywordMatch(keywordsFilterArray, sent)
                  : true) &&
                // For the csv data source
                (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                  ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                  : true);

              if (
                appData.store &&
                appData.store.length &&
                appData.store.includes(STORE.aha)
              ) {
                if (
                  workspaces.findIndex(
                    (space: Filter) => space.value === sent.workspaceName
                  ) === -1 &&
                  acceptFlag
                ) {
                  workspaces.push({
                    label: sent.workspaceName ? sent.workspaceName : "",
                    value: sent.workspaceName ? sent.workspaceName : "",
                  });
                }

                return (
                  acceptFlag &&
                  workspaceFilterArray.includes(
                    sent.workspaceName ? sent.workspaceName : ""
                  )
                );
              }
              return acceptFlag;
            });
            filCompliments.push({
              ...comp,
              sentimentData: subC,
              workspaces,
            });
          }
        });

        suggestionCluster.forEach((comp: any, index: number) => {
          let subCluster: any[] = [];
          if (comp.clusterGroup !== "0") {
            comp.subClusters.forEach((sub: any) => {
              let workspaces: Filter[] = [];
              let subC = sub.sentimentData.filter((sent: Review) => {
                let acceptFlag =
                  expression(sent.date) &&
                  (keywordsFilterArray && keywordsFilterArray.length !== 0
                    ? checkKeywordMatch(keywordsFilterArray, sent)
                    : true) &&
                  // For the csv data source
                  (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                    ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                    : true);

                if (
                  appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.aha)
                ) {
                  if (
                    workspaces.findIndex(
                      (space: Filter) => space.value === sent.workspaceName
                    ) === -1 &&
                    acceptFlag
                  ) {
                    workspaces.push({
                      label: sent.workspaceName ? sent.workspaceName : "",
                      value: sent.workspaceName ? sent.workspaceName : "",
                    });
                  }
                  return (
                    acceptFlag &&
                    workspaceFilterArray.includes(
                      sent.workspaceName ? sent.workspaceName : ""
                    )
                  );
                }
                return acceptFlag;
              });
              subCluster.push({
                ...sub,
                sentimentData: subC,
                workspaces,
              });
            });

            filSuggestions.push({
              ...comp,
              subClusters: subCluster.filter(
                (clus) => clus.sentimentData.length !== 0
              ),
            });
          }

          if (comp.clusterGroup === "0") {
            let workspaces: Filter[] = [];
            let subC = comp.sentimentData.filter((sent: Review) => {
              let acceptFlag =
                expression(sent.date) &&
                (keywordsFilterArray && keywordsFilterArray.length !== 0
                  ? checkKeywordMatch(keywordsFilterArray, sent)
                  : true) &&
                // For the csv data source
                (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                  ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                  : true);

              if (
                appData.store &&
                appData.store.length &&
                appData.store.includes(STORE.aha)
              ) {
                if (
                  workspaces.findIndex(
                    (space: Filter) => space.value === sent.workspaceName
                  ) === -1 &&
                  acceptFlag
                ) {
                  workspaces.push({
                    label: sent.workspaceName ? sent.workspaceName : "",
                    value: sent.workspaceName ? sent.workspaceName : "",
                  });
                }
                return (
                  acceptFlag &&
                  workspaceFilterArray.includes(
                    sent.workspaceName ? sent.workspaceName : ""
                  )
                );
              }
              return acceptFlag;
            });
            filSuggestions.push({
              ...comp,
              sentimentData: subC,
              workspaces,
            });
          }
        });
      } catch (e) {
        //console.log(e);
      }
    }

    setFilComplaintsData(
      filComplaints.filter(
        (it) =>
          (it.clusterGroup !== "0" && it.subClusters.length !== 0) ||
          (it.clusterGroup === "0" &&
            it.sentimentData &&
            it.sentimentData.length)
      )
    );
    setFilComplimentsData(
      filCompliments.filter(
        (it) =>
          (it.clusterGroup !== "0" && it.subClusters.length !== 0) ||
          (it.clusterGroup === "0" &&
            it.sentimentData &&
            it.sentimentData.length)
      )
    );
    setFilSuggestionsData(
      filSuggestions.filter(
        (it) =>
          (it.clusterGroup !== "0" && it.subClusters.length !== 0) ||
          (it.clusterGroup === "0" &&
            it.sentimentData &&
            it.sentimentData.length)
      )
    );
    if (appData.store && appData.store.includes(STORE.aha)) {
      setFilteredReviews(distictObjectById(filteredData, "reviewID"));
    } else {
      setFilteredReviews(filteredData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // reviewSelectFilters,
    selectedReviewFilter,
    selectedWorkspaceFilter,
    selectedReviewFilters,
    selectedTopicFilters,
    keywords,
    appData.filter,
    appData.complaintCluster,
    appData.complimentCluster,
    appData.suggestionCluster,
    defaultStore,
  ]);

  useEffect(() => {
    if (appData.filter && appData.sentiments && appData.sentiments.length) {
      const { expression } = getFilterValues(appData);

      const sentiments = getDataByStore(appData.sentiments, defaultStore);

      const filSentiments: Review[] = sentiments.filter((sen: Review) =>
        expression(sen.date)
      );

      setFilteredSentiments(filSentiments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  useEffect(() => {
    // Removing the sub-clusters list when a filter on the cluster is removed
    // The following is done based on either data source
    if (
      (appData.store &&
        appData.store.length &&
        selectedSubtopic &&
        selectedInsightsFilter.findIndex(
          (fil: Filter) => fil.value === "compliments"
        ) === -1 &&
        getDataByStore(appData.complimentCluster, defaultStore).filter(
          (comp: any) => comp.id === selectedSubtopic.id
        ).length) ||
      (selectedSubtopic &&
        selectedInsightsFilter.findIndex(
          (fil: Filter) => fil.value === "complaints"
        ) === -1 &&
        getDataByStore(appData.complaintCluster, defaultStore).filter(
          (comp: any) => comp.id === selectedSubtopic.id
        ).length) ||
      (selectedSubtopic &&
        selectedInsightsFilter.findIndex(
          (fil: Filter) => fil.value === "suggestions"
        ) === -1 &&
        getDataByStore(appData.suggestionCluster, defaultStore).filter(
          (comp: any) => comp.id === selectedSubtopic.id
        ).length)
    ) {
      setFilteredSubcluster(undefined);
      setFilteredReviews([]);
    } else if (
      // When none of the data stores have the selected cluster
      appData.store &&
      appData.store.length &&
      selectedSubtopic &&
      getDataByStore(appData.complimentCluster, defaultStore).filter(
        (comp: any) => comp.id === selectedSubtopic.id
      ).length === 0 &&
      getDataByStore(appData.complaintCluster, defaultStore).filter(
        (comp: any) => comp.id === selectedSubtopic.id
      ).length === 0 &&
      getDataByStore(appData.suggestionCluster, defaultStore).filter(
        (comp: any) => comp.id === selectedSubtopic.id
      ).length === 0
    ) {
      setSelectedSubtopic(undefined);
      setSelectedSubcluster(undefined);
      setFilteredSubcluster(undefined);
      setFilteredReviews([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compliments,
    complaints,
    suggestions,
    selectedInsightsFilter,
    defaultStore,
  ]);

  // useEffect to filter the subcluster data based on global filter
  useEffect(() => {
    if (appData.filter && selectedSubtopic) {
      let subCluster: any[] = [];

      const { expression } = getFilterValues(appData);

      const clusterSentiment = getDataByStore(
        appData.clusterSentiment,
        defaultStore
      );

      const foundSubTopic = clusterSentiment.findIndex(
        (clus: any) => clus.id === selectedSubtopic.id
      );

      if (foundSubTopic !== -1) {
        let workspaces: Filter[] = [];
        const [
          // eslint-disable-next-line
          _,
          workspaceFilterArray,
          keywordsFilterArray,
          csvKeywordsFilterArray,
        ] = getFilterArrays();

        // For grouped clusters
        if (clusterSentiment[foundSubTopic].clusterGroup !== "0") {
          if (clusterSentiment[foundSubTopic].subClusters) {
            clusterSentiment[foundSubTopic].subClusters.forEach((sub: any) => {
              let subC = sub.sentimentData.filter((sent: Review) => {
                let acceptFlag =
                  expression(sent.date) &&
                  (keywordsFilterArray && keywordsFilterArray.length !== 0
                    ? checkKeywordMatch(keywordsFilterArray, sent)
                    : true) &&
                  // For the csv data source
                  (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                    ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                    : true);

                if (
                  appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.aha)
                ) {
                  if (
                    workspaces.findIndex(
                      (space: Filter) => space.value === sent.workspaceName
                    ) === -1 &&
                    acceptFlag
                  ) {
                    workspaces.push({
                      label: sent.workspaceName ? sent.workspaceName : "",
                      value: sent.workspaceName ? sent.workspaceName : "",
                    });
                  }
                  return (
                    acceptFlag &&
                    workspaceFilterArray.includes(
                      sent.workspaceName ? sent.workspaceName : ""
                    )
                  );
                }
                return acceptFlag;
              });
              subCluster.push({
                ...sub,
                sentimentData: subC,
                workspaces,
              });
            });
          }
        }

        // For ungrouped clusters
        if (
          clusterSentiment[foundSubTopic].clusterGroup === "0" &&
          clusterSentiment[foundSubTopic].sentimentData &&
          clusterSentiment[foundSubTopic].sentimentData.length
        ) {
          setFilteredReviews(
            [...clusterSentiment[foundSubTopic].sentimentData].filter(
              (sent: Review) => {
                let acceptFlag =
                  expression(sent.date) &&
                  (keywordsFilterArray && keywordsFilterArray.length !== 0
                    ? checkKeywordMatch(keywordsFilterArray, sent)
                    : true) &&
                  // For the csv data source
                  (csvKeywordsFilterArray && csvKeywordsFilterArray.length !== 0
                    ? checkCsvKeywordMatch(csvKeywordsFilterArray, sent)
                    : true);

                if (
                  appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.aha)
                ) {
                  if (
                    workspaces.findIndex(
                      (space: Filter) => space.value === sent.workspaceName
                    ) === -1 &&
                    acceptFlag
                  ) {
                    workspaces.push({
                      label: sent.workspaceName ? sent.workspaceName : "",
                      value: sent.workspaceName ? sent.workspaceName : "",
                    });
                  }
                  return (
                    acceptFlag &&
                    workspaceFilterArray.includes(
                      sent.workspaceName ? sent.workspaceName : ""
                    )
                  );
                }
                return acceptFlag;
              }
            )
          );
        }

        setFilteredSubcluster({
          ...selectedSubtopic,
          subClusters: subCluster.filter(
            (clus) => clus.sentimentData.length !== 0
          ),
          workspaces,
        });
      } else {
        selectedSubtopic.subClusters.forEach((sub: any) => {
          let subC = sub.sentimentData.filter((sent: Review) =>
            expression(sent.date)
          );
          subCluster.push({
            ...sub,
            sentimentData: subC,
          });
        });

        setFilteredSubcluster({
          ...selectedSubtopic,
          subClusters: subCluster.filter(
            (clus) => clus.sentimentData.length !== 0
          ),
        });
      }
    }
    if (!selectedSubtopic) {
      setFilteredSubcluster(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appData.filter,
    selectedReviewFilter,
    selectedSubtopic,
    appData.clusterSentiment,
    selectedWorkspaceFilter,
    selectedReviewFilters,
    selectedTopicFilters,
    // defaultStore,
    keywords,
  ]);

  // Setting the initial Topic when the page is loaded and the Complaints, Compliments and Suggestions are filtered based on the filters
  useEffect(() => {
    if (
      location &&
      (!location.state || (location.state && !location.state.filters)) &&
      !selectedSubtopic
    ) {
      if (filComplimentsData && filComplimentsData.length) {
        setSelectedSubtopic({
          ...filComplimentsData[0],
          type: "Compliments",
        });
      } else if (
        filComplaintsData &&
        filComplaintsData.length &&
        !selectedSubtopic
      ) {
        setSelectedSubtopic({
          ...filComplaintsData[0],
          type: "Complaints",
        });
      } else if (
        filSuggestionsData &&
        filSuggestionsData.length &&
        !selectedSubtopic
      ) {
        setSelectedSubtopic({
          ...filSuggestionsData[0],
          type: "Suggestions",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filComplimentsData, filComplaintsData, filSuggestionsData]);

  // Bar Graph Data
  const labels = constructLegend(appData.filter);

  const [reportData, setReportData] = useState<any>({
    labels,
    datasets: [
      // {
      //   label: "All reviews",
      //   data: [],
      //   backgroundColor: "white",
      //   borderColor: "#0B0E1E",
      //   borderWidth: 1,
      // },
      {
        label: "Cluster",
        data: [],
        backgroundColor: "white",
        borderColor: "#418F90",
        borderWidth: 1,
      },
    ],
  });

  // Report will change during global filter, individual filter like complaints, compliments and sentiments
  // And when the underlying data changes
  // Based on user's selection of the cluster, we will show the stats of the sub clusters
  useEffect(() => {
    // Report Relevancy shown only for Apps from google or apple store
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google))
    ) {
      // Get the labels for the report
      setReportData(
        getAnalyticsRelevancyReportData(
          appData,
          selectedSubtopic,
          selectedSubcluster,
          compliments,
          complaints,
          suggestions
        )
      );
    }

    // used to show the Report for github and sof also
    // with some more fetures
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.sof))
    ) {
      // Get the labels for the report
      setReportData(
        getAnalyticsRelevancyReportData(
          appData,
          selectedSubtopic,
          selectedSubcluster,
          compliments,
          complaints,
          suggestions
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appData.filter,
    filteredSubcluster,
    selectedSubcluster,
    appData.complaints,
    appData.compliments,
    appData.suggestions,
    appData.sentiments,
    complaints,
    compliments,
    suggestions,
    selectedSubtopic,
  ]);

  // Handler to set the list of review Ids to show
  // Changing logic so that data is shown when sentiment items has data
  const filterReviewsHandler = (reviewIds: any[]) => {
    if (reviewIds) {
      let filteredData = [...reviewIds];

      const [
        reviewFilterArray,
        workspaceFilterArray,
        keywordsFilterArray,
        csvKeywordsFilterArray,
      ] = getFilterArrays();

      // Based on keywords
      // Common for both App flow and Aha Flow
      if (
        keywords &&
        keywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "keywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkKeywordMatch(keywordsFilterArray, fil)
        );
      }

      // Based on Company, destination, specialist for csv
      if (
        csvKeywords &&
        csvKeywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "csvKeywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkCsvKeywordMatch(csvKeywordsFilterArray, fil)
        );
      }
      // For Aha Data source
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.aha)
      ) {
        // Based on status
        filteredData = filteredData.filter((fil) =>
          reviewFilterArray.includes(fil.status)
        );

        // Based on workspaceName
        filteredData = filteredData.filter((fil) =>
          workspaceFilterArray.includes(fil.workspaceName)
        );
      }
      // for github data source
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.github)
      ) {
        // Based on status
        filteredData = filteredData.filter((fil) =>
          reviewFilterArray.includes(fil.status)
        );
      }

      if (appData.store && appData.store === STORE.aha) {
        setFilteredReviews(distictObjectById(filteredData, "reviewID"));
      } else {
        setFilteredReviews(filteredData);
      }
    } else setReviewId(reviewIds);
  };

  useEffect(() => {
    if (
      appData.sentiments &&
      appData.sentiments.length &&
      reviewId &&
      reviewId.length
    ) {
      let filteredData = [...getDataByStore(appData.sentiments, defaultStore)];
      filteredData = filteredData.filter((fil) =>
        reviewId.includes(fil.reviewID)
      );

      const [
        reviewFilterArray,
        workspaceFilterArray,
        keywordsFilterArray,
        csvKeywordsFilterArray,
      ] = getFilterArrays();

      // Based on keywords
      // Common for App flow and Aha flow
      if (
        keywords &&
        keywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "keywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkKeywordMatch(keywordsFilterArray, fil)
        );
      }
      // Based on Company, destination, specialist for csv
      if (
        csvKeywords &&
        csvKeywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "csvKeywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkCsvKeywordMatch(csvKeywordsFilterArray, fil)
        );
      }

      // For Aha Data source
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.aha)
      ) {
        // Based on status
        filteredData = filteredData.filter((fil) =>
          reviewFilterArray.includes(fil.status)
        );

        // Based on workspaceName
        filteredData = filteredData.filter((fil) =>
          workspaceFilterArray.includes(fil.workspaceName)
        );
      }

      if (appData.store && appData.store === STORE.aha) {
        setFilteredReviews(distictObjectById(filteredData, "reviewID"));
      } else {
        setFilteredReviews(filteredData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.sentiments, reviewId]);

  // Getting the ids when a cluster group is clicked
  useEffect(() => {
    if (filteredSubcluster && filteredSubcluster.clusterGroup !== "0") {
      let sentiments: Review[] = [];
      filteredSubcluster.subClusters.forEach((sub: any) => {
        sentiments = sentiments.concat(sub.sentimentData);
      });

      let filteredData = [...sentiments];
      // if (
      //   appData.store &&
      //   appData.store.length &&
      //   (appData.store.includes(STORE.apple) ||
      //     appData.store.includes(STORE.google) ||
      //     appData.store.includes(STORE.csv))
      // ) {
      //   if (!reviewSelectFilters.positive) {
      //     filteredData = filteredData.filter(
      //       (data) =>
      //         data.sentimentScore !== undefined &&
      //         parseFloat(data.sentimentScore) <= 0.1
      //     );
      //   }
      //   if (!reviewSelectFilters.negative) {
      //     filteredData = filteredData.filter(
      //       (data) =>
      //         data.sentimentScore !== undefined &&
      //         parseFloat(data.sentimentScore) >= -0.1
      //     );
      //   }
      //   if (!reviewSelectFilters.one) {
      //     filteredData = filteredData.filter((data) => data.rating !== 1);
      //   }
      //   if (!reviewSelectFilters.two) {
      //     filteredData = filteredData.filter((data) => data.rating !== 2);
      //   }
      //   if (!reviewSelectFilters.three) {
      //     filteredData = filteredData.filter((data) => data.rating !== 3);
      //   }
      //   if (!reviewSelectFilters.four) {
      //     filteredData = filteredData.filter((data) => data.rating !== 4);
      //   }
      //   if (!reviewSelectFilters.five) {
      //     filteredData = filteredData.filter((data) => data.rating !== 5);
      //   }
      // }

      const [
        reviewFilterArray,
        workspaceFilterArray,
        keywordsFilterArray,
        csvKeywordsFilterArray,
      ] = getFilterArrays();

      // Based on keywords
      // Common for both App flow and Aha flow
      if (
        keywords &&
        keywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "keywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkKeywordMatch(keywordsFilterArray, fil)
        );
      }

      // Based on Company, destination, specialist for csv
      if (
        csvKeywords &&
        csvKeywords.length &&
        selectedTopicFilters.findIndex(
          (fil: Filter) => fil.value === "csvKeywords"
        ) !== -1
      ) {
        filteredData = filteredData.filter((fil) =>
          checkCsvKeywordMatch(csvKeywordsFilterArray, fil)
        );
      }

      // For Aha Data source
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.aha)
      ) {
        // Based on status
        filteredData = filteredData.filter((fil) =>
          reviewFilterArray.includes(fil.status!)
        );

        // Based on workspaceName
        filteredData = filteredData.filter((fil) =>
          workspaceFilterArray.includes(fil.workspaceName!)
        );
      }
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.github)
      ) {
        // Based on status
        filteredData = filteredData.filter((fil) =>
          reviewFilterArray.includes(fil.status!)
        );
      }
      if (appData.store && appData.store === STORE.aha) {
        setFilteredReviews(distictObjectById(filteredData, "reviewID"));
      } else {
        setFilteredReviews(filteredData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSubcluster]);

  // useEffect to check if any filter states are coming from any other page
  // Add the store logic here
  useEffect(() => {
    if (location && location.state && location.state.filters && !openLocation) {
      dispatch(AppActions.setGlobalFilter(location.state.filters.globalFilter));

      if (insightsFilter && insightsFilter.length) {
        setSelectedInsightsFilter(
          [...insightsFilter].filter(
            (fil: Filter) => fil.value === location.state.filters.localFilter
          )
        );

        setSelectedTopicFilters([
          ...selectedTopicFilters,
          {
            label: "Insights type",
            value: "insights",
          },
        ]);
      }

      if (location.state.filters.localFilter === COMPLIMENTS) {
        setComplaints(false);
        setSuggestions(false);
        if (filComplimentsData && filComplimentsData.length) {
          setSelectedSubtopic({
            ...filComplimentsData[0],
            type: "Compliments",
          });
          setOpenLocation(true);
        }
      }

      if (location.state.filters.localFilter === COMPLAINTS) {
        setCompliments(false);
        setSuggestions(false);
        if (filComplaintsData && filComplaintsData.length) {
          setSelectedSubtopic({
            ...filComplaintsData[0],
            type: "Complaints",
          });
          setOpenLocation(true);
        }
      }
      if (location.state.filters.localFilter === SUGGESTIONS) {
        setComplaints(false);
        setCompliments(false);
        if (filSuggestionsData && filSuggestionsData.length) {
          setSelectedSubtopic({
            ...filSuggestionsData[0],
            type: "Suggestions",
          });
          setOpenLocation(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    insightsFilter,
    dispatch,
    filComplaintsData,
    filComplimentsData,
    filSuggestionsData,
  ]);

  const getTopicCount = () => {
    let count = 0;
    // Common state to maintain showing one out of three
    if (appData.store) {
      if (
        selectedInsightsFilter.findIndex(
          (item: Filter) => item.value === "complaints"
        ) !== -1
      ) {
        count += filComplaintsData.length;
      }

      if (
        selectedInsightsFilter.findIndex(
          (item: Filter) => item.value === "compliments"
        ) !== -1
      ) {
        count += filComplimentsData.length;
      }

      if (
        selectedInsightsFilter.findIndex(
          (item: Filter) => item.value === "suggestions"
        ) !== -1
      ) {
        count += filSuggestionsData.length;
      }
    }
    return count;
  };

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxSizing: "border-box",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          overflowY: "auto",
          overflowX: "none",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        <FlexBox
          sx={{
            width: "90%",
            height: "95%",
            flexDirection: "column",
            overflowX: "none",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginBottom: "1.5rem",
              alignItems: "center",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                color: "#3579E3",
              }}
            >
              Analytics
            </FlexBox>
            <FlexBox>
              <FilterTextPopover
                filterOptions={filterOptions}
                selectedFilter={appData.filter}
                setSelectedFilter={(filter: FilterOption) =>
                  dispatch(AppActions.setGlobalFilter(filter))
                }
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              sx={{
                ...(width < 1250 ? { width: "54%" } : { width: "65%" }),
                // minWidth: "500px",
                flexDirection: "column",
              }}
            >
              {/* {appData.store &&
              (appData.store === STORE.apple ||
                appData.store === STORE.google) ? (
                <ClusterFilters
                  complaints={complaints}
                  compliments={compliments}
                  suggestions={suggestions}
                  setComplaints={setComplaints}
                  setCompliments={setCompliments}
                  setSuggestions={setSuggestions}
                  appData={appData}
                />
              ) : ( */}

              <FlexBox
                sx={{
                  flexWrap: "wrap",
                  marginTop: "0.25rem",
                }}
              >
                <FlexBox
                  sx={{
                    marginRight: "0.8rem",
                    marginBottom: "1rem",
                  }}
                >
                  <CheckboxGroupFilterPopover
                    selectedFilter={selectedApps}
                    setSelectedFilter={(
                      filter: { label: string; value: string }[]
                    ) => setSelectedApps(filter)}
                    filterOptions={apps}
                    width={"18rem"}
                    label={<FlexBox>{"Data sources"}</FlexBox>}
                    disableSelectAll={false}
                    popOffset={4.7}
                  />
                  {/* <CheckboxFilterPopover
                    selectedFilter={selectedDataSourceFilter}
                    setSelectedFilter={(
                      filter: { label: string; value: string }[]
                    ) => setSelectedDataSourceFilter(filter)}
                    filterOptions={dataSourceFilter}
                    width={"13rem"}
                    label={<FlexBox>{"Data source"}</FlexBox>}
                    disableSelectAll={true}
                    popOffset={5.2}
                  /> */}
                </FlexBox>
                {/* {appData.store &&
                appData.store.length &&
                selectedTopicFilters.length &&
                selectedTopicFilters.findIndex(
                  (fil: Filter) => fil.value === "apps"
                ) !== -1 ? (
                  <FlexBox
                    sx={{
                      marginRight: "0.8rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <CheckboxFilterPopover
                      selectedFilter={selectedApps}
                      setSelectedFilter={(
                        filter: { label: string; value: string }[]
                      ) => setSelectedApps(filter)}
                      filterOptions={apps}
                      width={"15rem"}
                      label={<FlexBox>{"App"}</FlexBox>}
                      disableSelectAll={true}
                      popOffset={5.2}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )} */}
                {selectedTopicFilters &&
                selectedTopicFilters.length &&
                selectedTopicFilters.findIndex(
                  (fil: Filter) => fil.value === "insights"
                ) !== -1 ? (
                  <FlexBox
                    sx={{
                      marginRight: "0.8rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <CheckboxFilterPopover
                      selectedFilter={selectedInsightsFilter}
                      setSelectedFilter={(
                        filter: { label: string; value: string }[]
                      ) => setSelectedInsightsFilter(filter)}
                      filterOptions={insightsFilter}
                      width={"12rem"}
                      label={<FlexBox>{"Insights type"}</FlexBox>}
                      removeFilter={() => {
                        const newfilters = selectedTopicFilters.filter(
                          (fil: Filter) => fil.value !== "insights"
                        );
                        setSelectedTopicFilters(newfilters);
                        setSelectedInsightsFilter(getInsightsFilter());
                      }}
                      openP={openFilters.insights}
                      setOpenP={(value: boolean) =>
                        setOpenFilters({ ...openFilters, insights: value })
                      }
                      popOffset={4.5}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )}
                {appData.store &&
                appData.store.length &&
                appData.store.includes(STORE.aha) &&
                selectedTopicFilters.length &&
                selectedTopicFilters.findIndex(
                  (fil: Filter) => fil.value === "workspace"
                ) !== -1 ? (
                  <FlexBox
                    sx={{
                      marginRight: "0.8rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <CheckboxFilterPopover
                      selectedFilter={selectedWorkspaceFilter}
                      setSelectedFilter={(
                        filter: { label: string; value: string }[]
                      ) => setSelectedWorkspaceFilter(filter)}
                      filterOptions={workspaceFilter}
                      // width={"12rem"}
                      label={<FlexBox>{"Workspace"}</FlexBox>}
                      removeFilter={() =>
                        setSelectedTopicFilters(
                          selectedTopicFilters.filter(
                            (fil: Filter) => fil.value !== "workspace"
                          )
                        )
                      }
                      openP={openFilters.workspace}
                      setOpenP={(value: boolean) =>
                        setOpenFilters({ ...openFilters, workspace: value })
                      }
                      popOffset={69}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )}
                {selectedTopicFilters &&
                selectedTopicFilters.length &&
                selectedTopicFilters.findIndex(
                  (fil: Filter) => fil.value === "keywords"
                ) !== -1 ? (
                  <FlexBox
                    sx={{
                      marginBottom: "1rem",
                      flexDirection: "column",
                      marginRight: "0.8rem",
                    }}
                  >
                    <CheckboxFilterPopover
                      selectedFilter={keywords}
                      setSelectedFilter={(filter: Filter[]) =>
                        setKeywords(filter)
                      }
                      filterOptions={keywordArray}
                      width={"13rem"}
                      label={<FlexBox>{"Keywords"}</FlexBox>}
                      enableSearch={(keyword: string) => {
                        setKeywordArray([
                          ...keywordArray,
                          {
                            label: keyword,
                            value: keyword,
                          },
                        ]);
                        setKeywords([
                          ...keywords,
                          {
                            label: keyword,
                            value: keyword,
                          },
                        ]);
                      }}
                      disableSelectAll={true}
                      removeFilter={() =>
                        setSelectedTopicFilters(
                          selectedTopicFilters.filter(
                            (fil: Filter) => fil.value !== "keywords"
                          )
                        )
                      }
                      openP={openFilters.keywords}
                      setOpenP={(value: boolean) =>
                        setOpenFilters({ ...openFilters, keywords: value })
                      }
                      popOffset={4}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )}
                {appData.store &&
                appData.store.length &&
                (appData.store.includes(STORE.csv) ||
                  appData.store.includes(STORE.github) ||
                  appData.store.includes(STORE.amazon) ||
                  appData.store.includes(STORE.sof)) &&
                selectedTopicFilters &&
                selectedTopicFilters.length &&
                selectedTopicFilters.findIndex(
                  (fil: Filter) => fil.value === "csvKeywords"
                ) !== -1 ? (
                  <FlexBox
                    sx={{
                      marginBottom: "1rem",
                      flexDirection: "column",
                      marginRight: "0.8rem",
                    }}
                  >
                    <CheckboxFilterPopover
                      selectedFilter={csvKeywords}
                      setSelectedFilter={(filter: Filter[]) =>
                        setCsvKeywords(filter)
                      }
                      filterOptions={csvKeywordArray}
                      width={"13rem"}
                      label={<FlexBox>{"Name search"}</FlexBox>}
                      enableSearch={(keyword: string) => {
                        setCsvKeywordArray([
                          ...csvKeywordArray,
                          {
                            label: keyword,
                            value: keyword,
                          },
                        ]);
                        setCsvKeywords([
                          ...csvKeywords,
                          {
                            label: keyword,
                            value: keyword,
                          },
                        ]);
                      }}
                      disableSelectAll={true}
                      removeFilter={() =>
                        setSelectedTopicFilters(
                          selectedTopicFilters.filter(
                            (fil: Filter) => fil.value !== "csvKeywords"
                          )
                        )
                      }
                      openP={openFilters.csvKeywords}
                      setOpenP={(value: boolean) =>
                        setOpenFilters({ ...openFilters, csvKeywords: value })
                      }
                      popOffset={4}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )}
                <FlexBox
                  sx={{
                    marginRight: "0.8rem",
                    marginBottom: "1rem",
                  }}
                >
                  <FilterAdder
                    selectedFilter={selectedTopicFilters}
                    setSelectedFilter={(filter: Filter[]) =>
                      setSelectedTopicFilters(filter)
                    }
                    filterOptions={topicFilters}
                    width={"7.8rem"}
                    label={<FlexBox>{"Add filter"}</FlexBox>}
                    disableSelectAll={true}
                    handleFilter={(type: string) =>
                      setTimeout(() => {
                        setOpenFilters({ ...openFilters, [type]: true });
                      }, 10)
                    }
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox
                sx={{
                  paddingRight: "1rem",
                }}
              >
                <FlexBox
                  sx={{
                    width: "100%",
                    background: "#FFFFFF",
                    height: "47%",
                    maxHeight: "330px",
                    minHeight: "330px",
                    marginBottom: "1.6rem",
                    borderRadius: "8px",
                    boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
                    padding: "1.5rem 1.5rem 0 1.5rem",
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <FlexBox
                    sx={{
                      width: "49%",
                      flexDirection: "column",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <FlexBox
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "17px",
                          textTransform: "uppercase",
                          color: "#3A3C40",
                        }}
                      >
                        Topics
                        <FlexBox
                          sx={{
                            marginLeft: "0.2rem",
                          }}
                        >{`(${getTopicCount()})`}</FlexBox>
                      </FlexBox>{" "}
                      {appData.store &&
                      appData.store.length &&
                      appData.store.includes(STORE.aha) ? (
                        <FlexBox
                          sx={{
                            alignItems: "center",
                            // marginBottom: "0.8rem",
                          }}
                        >
                          <TextFilterPopover
                            selectedFilter={topicSort}
                            setSelectedFilter={setTopicSort}
                            filterOptions={[
                              {
                                label: "# votes",
                                value: "numberOfVotes",
                              },
                              {
                                label: "# workspaces",
                                value: "numberOfWorkspaces",
                              },
                            ]}
                          />
                        </FlexBox>
                      ) : (
                        <FlexBox
                          sx={{
                            fonrWeight: 500,
                            fontSize: "10px",
                            lineHeight: "14px",
                            color: "#3A3C40",
                          }}
                        >
                          {`# ${
                            appData.store &&
                            appData.store.length &&
                            appData.store.includes(STORE.aha)
                              ? "votes"
                              : appData.store &&
                                appData.store.length &&
                                appData.store.includes(STORE.sof)
                              ? "questions"
                              : "reviews"
                          }`}
                        </FlexBox>
                      )}
                    </FlexBox>
                    <FlexBox
                      // onScroll={onScroll}
                      sx={{
                        padding: "0 0.1rem 0 0.1rem",
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                        flexDirection: "column",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        "&::-webkit-scrollbar": {
                          width: "0.4rem",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#A3A6AB",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {/* 
                      Removed the logic separation due to merging of Aha and Normal App Flow fiters
                      (appData.store === STORE.apple ||
                          appData.store === STORE.google) &&
                          compliments) ||
                          // Compliments from Aha
                          (appData.store === STORE.aha && */}
                      {appData.store &&
                        // Compliments from Play store or apple store
                        selectedInsightsFilter.findIndex(
                          (fil: Filter) => fil.value === "compliments"
                        ) !== -1 && (
                          <Cluster
                            clusterData={filComplimentsData.sort(clusterSort)}
                            selectedSubtopic={selectedSubtopic}
                            filterReviewsHandler={filterReviewsHandler}
                            setFilteredReviews={setFilteredReviews}
                            selectedSubcluster={selectedSubcluster}
                            setSelectedSubtopic={setSelectedSubtopic}
                            setSelectedSubcluster={setSelectedSubcluster}
                            topicSort={topicSort}
                            type={"Compliments"}
                          />
                        )}
                      {appData.store &&
                        // Complaints from Play store or apple store
                        selectedInsightsFilter.findIndex(
                          (fil: Filter) => fil.value === "complaints"
                        ) !== -1 && (
                          <Cluster
                            clusterData={filComplaintsData.sort(clusterSort)}
                            selectedSubtopic={selectedSubtopic}
                            filterReviewsHandler={filterReviewsHandler}
                            setFilteredReviews={setFilteredReviews}
                            selectedSubcluster={selectedSubcluster}
                            setSelectedSubtopic={setSelectedSubtopic}
                            setSelectedSubcluster={setSelectedSubcluster}
                            topicSort={topicSort}
                            type={"Complaints"}
                          />
                        )}
                      {appData.store &&
                        // Suggestions from Play store or apple store
                        selectedInsightsFilter.findIndex(
                          (fil: Filter) => fil.value === "suggestions"
                        ) !== -1 && (
                          <Cluster
                            clusterData={filSuggestionsData.sort(clusterSort)}
                            selectedSubtopic={selectedSubtopic}
                            filterReviewsHandler={filterReviewsHandler}
                            setFilteredReviews={setFilteredReviews}
                            selectedSubcluster={selectedSubcluster}
                            setSelectedSubtopic={setSelectedSubtopic}
                            setSelectedSubcluster={setSelectedSubcluster}
                            topicSort={topicSort}
                            type={"Suggestions"}
                          />
                        )}
                    </FlexBox>
                  </FlexBox>
                  <SubClusters
                    selectedSubtopic={filteredSubcluster}
                    filterReviewsHandler={filterReviewsHandler}
                    selectedSubcluster={selectedSubcluster}
                    setSelectedSubcluster={setSelectedSubcluster}
                    topicSort={topicSort}
                    // Sending empty string if the global store doesn't contain STORE.aha
                    type={appData.store.includes(STORE.aha) ? STORE.aha : ""}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <WidgetContainer
                  widgets={
                    appData.widgetLayout &&
                    appData.widgetLayout.analytics &&
                    appData.widgetLayout.analytics.widgets
                      ? appData.widgetLayout.analytics.widgets
                      : []
                  }
                  type={"analytics"}
                  hideDraggable={true}
                  otherData={{
                    reportData,
                    filteredSubcluster,
                    selectedSubcluster,
                  }}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox
              sx={{
                ...(width < 1250 ? { width: "44%" } : { width: "37%" }),
                minWidth: "250px",
                flexDirection: "column",
                padding: "4px",
                height: "41.5rem",
                ...(appData.store &&
                appData.store.length &&
                appData.store.includes(STORE.aha)
                  ? { height: "51rem" }
                  : {}),
                // height: "88.5%",
                maxHeight: "inherit",
              }}
            >
              <ReviewColumn
                filteredReviews={filteredReviews}
                reviewSort={reviewSort}
                reviewPoints={reviewPoints}
                clusterPoints={clusterPoints}
                getHighlightKeywords={getHighlightKeywords}
                keywords={keywords}
                selectedDataSourceFilter={selectedDataSourceFilter}
                selectedReviewFilters={selectedReviewFilters}
                setSelectedReviewFilter={setSelectedReviewFilter}
                openFilters={openFilters}
                setOpenFilters={setOpenFilters}
                reviewFilters={reviewFilters}
                reviewFilter={reviewFilter}
                setSelectedReviewFilters={setSelectedReviewFilters}
                setReviewSort={setReviewSort}
                selectedReviewFilter={selectedReviewFilter}
                filteredSentiments={filteredSentiments}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default Analytics;
