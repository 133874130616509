import { Box, Button, FormHelperText, MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormContext, FormContextAttr } from "./AddAha";
import FlexBox from "../shared/wrappers/FlexBox";
import { LoadingButton } from "@mui/lab";
import { Small } from "../shared/wrappers/Typography";
import { getAhaIdeasFromProducts } from "../../services/api/Apps.api";

export type Product = {
  created_at: string;
  id: string;
  name: string;
  product_line: boolean;
  reference_prefix: string;
  workspace_type: string;
};

type Attrs = {
  key: string;
  help: string;
  required: boolean;
  multiple?: boolean;
};

export const PREDEFINED_ATTRS: Attrs[] = [
  {
    key: "title",
    help: "Title for the idea",
    required: true,
  },
  {
    key: "description",
    help: "Description of the idea",
    required: true,
  },
  {
    key: "date",
    help: "Date when the idea was created",
    required: true,
  },
  {
    key: "submittedBy",
    help: "Submitted by",
    required: true,
  },
  {
    key: "votes",
    help: "Number of votes on the idea",
    required: true,
  },
  {
    key: "workspaceName",
    help: "Workspace or Product the idea is mapped to",
    required: false,
  },
  {
    key: "ideaReference",
    help: "Idea reference the idea is mapped to",
    required: false,
  },
  {
    key: "ideaCategory",
    help: "Category the idea is mapped to",
    required: false,
  },
  {
    key: "status",
    help: "Status of the workspace",
    required: false,
  },
  {
    key: "additional",
    help: "Please provide any additional field values",
    multiple: true,
    required: false,
  },
  {
    key: "ignore",
    help: "Please mark fields that could be dropped",
    multiple: true,
    required: false,
  },
];

const createObject = (arr: Attrs[]) => {
  let obj: any = {};

  arr.forEach((item: Attrs) => {
    if (item.multiple) {
      obj[item.key] = [];
    } else obj[item.key] = "";
  });

  return obj;
};

const MapAttributes = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    attributes,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues = {
    ...createObject(PREDEFINED_ATTRS),
    ...formData,
  };

  const createValidationSchema = (arr: Attrs[]) => {
    //products: Yup.array().required("Please select any one workspace"),
    let obj: any = {};
    arr.forEach((item: Attrs) => {
      if (item.required) {
        if (item.multiple) {
          obj[item.key] = Yup.array().required(`${item.key} is required`);
        } else obj[item.key] = Yup.string().required(`${item.key} is required`);
      } else {
        if (item.multiple) {
          obj[item.key] = Yup.array();
        } else obj[item.key] = Yup.string();
      }
    });

    return obj;
  };

  const validationSchema = Yup.object().shape(
    createValidationSchema(PREDEFINED_ATTRS)
  );

  const createMappingObject = (values: any) => {
    let mappingObject: any = {};
    // Required props within index
    if (
      values["title"] &&
      values["title"].length &&
      values["date"] &&
      values["date"].length
    ) {
      let index = [];
      if (values["title"]) {
        index.push({
          key: values["title"],
        });
      }

      if (values["ideaReference"]) {
        index.push({
          key: values["ideaReference"],
        });
      }

      if (values["status"]) {
        index.push({
          key: values["status"],
        });
      }

      if (values["workspaceName"]) {
        index.push({
          key: values["workspaceName"],
        });
      }

      if (values["ideaCategory"]) {
        index.push({
          key: values["ideaCategory"],
        });
      }

      if (values["date"]) {
        index.push({
          key: values["date"],
        });
      }

      mappingObject["index"] = index;
    } else {
      mappingObject["index"] = [
        {
          key: "none",
        },
      ];
    }

    if (values["description"] && values["description"].length) {
      mappingObject["review"] = [
        {
          key: values["description"],
        },
      ];
    } else {
      mappingObject["review"] = [
        {
          key: "none",
        },
      ];
    }

    if (values["date"] && values["date"].length) {
      mappingObject["date"] = [
        {
          key: values["date"],
        },
      ];
    } else {
      mappingObject["date"] = [
        {
          key: "none",
        },
      ];
    }

    if (values["votes"] && values["votes"].length) {
      mappingObject["rating"] = [
        {
          key: values["votes"],
        },
      ];
    } else {
      mappingObject["rating"] = [
        {
          key: "none",
        },
      ];
    }

    if (values["submittedBy"] && values["submittedBy"].length) {
      mappingObject["username"] = [
        {
          key: values["submittedBy"],
        },
      ];
    } else {
      mappingObject["username"] = [
        {
          key: "none",
        },
      ];
    }

    if (values["additional"] && values["additional"].length) {
      let arry: { key: string }[] = [];
      values["additional"].forEach((item: string) => {
        arry.push({
          key: item,
        });
      });
      mappingObject["replyContent"] = arry;
    } else {
      mappingObject["replyContent"] = [
        {
          key: "none",
        },
      ];
    }

    return mappingObject;
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading(true);
      if (values.products.length !== 0) {
        const mappingObject = createMappingObject(values);
        const data = {
          ...formData,
          ...values,
          mappingObject,
        };

        // Send mapping attr to backend to start importing
        getAhaIdeasFromProducts(
          formData.subdomain,
          formData.apikey,
          formData.workspaces,
          mappingObject,
          formData.name
        );

        try {
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        } catch (e) {
          setError("There was some error");
        }
      } else {
        setError("Error");
      }
      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Map the attributes
      </FlexBox>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "30px",
        }}
      >
        Please map the attributes from Aha! to respective pre-defined attributes
        in Rargus.
      </Box>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              maxHeight: "15rem",
              overflowY: "auto",
              overflowX: "none",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            {/* {attributes.map((filter: any, index: number) => {
            return ( */}
            {attributes && attributes.length !== 0 ? (
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                {PREDEFINED_ATTRS.map((attr: Attrs, index: number) => (
                  <FullTextFieldWrapper key={`checkbox-product-name-${index}`}>
                    <FlexBox
                      sx={{
                        // justifyContent: "space-between",
                        width: "100%",
                        // alignItems: "center",
                        padding: "0.5rem 1rem",
                        flexDirection: "column",
                      }}
                    >
                      <FlexBox
                        sx={
                          {
                            // justifyContent: "center",
                          }
                        }
                      >
                        <FlexBox>
                          {attr.required ? "* " : ""}
                          {`${attr.key.toUpperCase()}: ${attr.help}`}
                        </FlexBox>
                      </FlexBox>
                      <FlexBox
                        sx={{
                          width: "100%",
                          marginTop: "0.4rem",
                        }}
                      >
                        <Select
                          fullWidth
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name={attr.key}
                          multiple={!!attr.multiple}
                          value={values[attr.key]}
                          label=""
                          onChange={handleChange}
                          sx={{
                            borderRadius: "8px",
                            height: "2.6rem",
                          }}
                          MenuProps={{
                            sx: {
                              height: "18rem",
                              // maxHeight: "15rem",
                              // overflowY: "auto",
                              "&::-webkit-scrollbar": {
                                width: "0.4rem",
                              },
                              "&::-webkit-scrollbar-track": {
                                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                                webkitBoxShadow:
                                  "inset 0 0 6px rgba(0,0,0,0.00)",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#A3A6AB",
                                borderRadius: "5px",
                              },
                            },
                          }}
                        >
                          {Object.keys(attributes).map(
                            (item: string, index: number) => (
                              <MenuItem
                                value={item}
                                key={`${item}--menu-item-${index}`}
                              >
                                <em>{item}</em>
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FlexBox>
                    </FlexBox>
                  </FullTextFieldWrapper>
                ))}
              </FlexBox>
            ) : (
              <></>
            )}
            {/* );
          })}*/}
          </FlexBox>
          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default MapAttributes;
