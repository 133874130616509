import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import { FilterTextPopover } from "../shared/wrappers/FilterPopper";
import WidgetContainer from "./WidgetContainer";
import { FilterOption, filterOptions } from "../shared/constants";
import { useSelector, useDispatch } from "react-redux";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { RootState } from "../../services/store/store";

const Sentiment = () => {
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  // const graphData: any = useSelector((store: RootState) => store.graphs);

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        <FlexBox
          sx={{
            width: "90%",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginBottom: "1.5rem",
              alignItems: "center",
              // width: "100%",
              // position: "fixed",
              // zIndex: "10",
              // background: "#F5F5F5",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
                color: "#3579E3",
              }}
            >
              Sentiments
            </FlexBox>
            <FlexBox>
              <FilterTextPopover
                filterOptions={filterOptions}
                selectedFilter={appData.filter}
                setSelectedFilter={(filter: FilterOption) =>
                  dispatch(AppActions.setGlobalFilter(filter))
                }
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={
              {
                // marginTop: "4rem",
              }
            }
          >
            <WidgetContainer
              widgets={
                appData.widgetLayout &&
                appData.widgetLayout.sentiment &&
                appData.widgetLayout.sentiment.widgets
                  ? appData.widgetLayout.sentiment.widgets
                  : []
              }
              type={"sentiment"}
              hideDraggable={true}
              otherData={{}}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default Sentiment;
