import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#3579E3",
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Barlow",
    fontWeight: 500,
    color: "#FFFFFF",
  },
}));

export default TooltipComponent;
