import { ReactElement, Fragment, useEffect, useState } from "react";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import LogoutIcon from "../shared/icons/logout.svg";
import AnalyticsIcon from "../shared/icons/analytics.svg";
import SettingsIcon from "../shared/icons/setting.svg";
import InsightsIcon from "../shared/icons/insights.svg";
import { logout } from "../../services/context/Account";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  AppActions,
  WidgetId,
  WidgetPage,
} from "../../services/store/AppStore/AppStoreSlice";
import { RootState } from "../../services/store/store";
import {
  COMPLAINT_FETCH,
  COMPLIMENT_FETCH,
  constructClusterSentiment,
  constructDataArray,
  DATA_BY_STORE,
  DEFAULT_DASHBOARD_PAGES,
  getAppDetails,
  getDataByStore,
  getDBTable,
  getFeedbackDashboardGraph,
  getReviewMappings,
  getRouteName,
  getSentimentOverTimeGraph,
  getTableKey,
  getUserData,
  getEditorUserRoleAccess,
  // GET_DATA_STORE,
  removeApp,
  // removeCurrentApp,
  SENTIMENT_FETCH,
  setDataByStore,
  STORE,
  SUGGESTION_FETCH,
  TRENDING_FETCH,
  updateLayoutAndUserWidgets,
  // getUserRights,
  // getUserRole,
} from "../shared/constants";
import {
  DEFAULT_ADMIN_FEEDBACK_WIDGETS,
  DEFAULT_ANALYTICS_WIDGET,
  DEFAULT_DASHBOARD_WIDGETS,
  DEFAULT_RATING_WIDGETS,
  DEFAULT_SENTIMENT_WIDGETS,
} from "../shared/widgetConstants";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { fetchTheDBData } from "../../services/db/dynamo";
import { getDataUsingURLFromS3 } from "../../services/db/s3";
import { fetchFeedbackGraph } from "../../services/api/Apps.api";
import { GraphActions } from "../../services/store/GraphStore/GraphSlice";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import WidgetSettingsModal from "../shared/wrappers/WidgetSettingsModal";
import FeedbackIcon from "../shared/icons/feedback.svg";
import TooltipComponent from "../shared/wrappers/Tooltip";
import Feedback from "../Feedback/Feedback";
import { getGraphDetails } from "../../services/api/Graphql.api";
import { integer } from "aws-sdk/clients/cloudfront";
//import { AnyARecord } from "dns";
// import { Console } from "console";
// import { getUserDetails } from "../../services/api/Graphql.api";

type MenuItem = {
  label: string;
  icon?: any;
  regex: string;
  route?: string;
  children?: MenuItem[];
  function?: () => void;
};

interface DashboardLayoutWrapperProps {
  children: ReactElement;
}

// first it checks whether all four status are present or not if present then it checks
// if status of all flags 7 or not if 7 then it returns true else if any of the flag is not 7
// then it return false
// export const checkStatusOfAllFlags = (
//   // contains appId, store and data
//   res: any,
//   // contains the success value
//   statusFlags: number[],
//   // contains the length of flags
//   lengthCheck?: number
// ) => {
//   let result: any[] = [];

//   // Stores the status for the succesful flags
//   // Length is 4 if all falgs are in success state
//   // Length is less than 4 if any one status is not success
//   result = [
//     // status of complain
//     res.complaintStatus,
//     // status of complement
//     res.complementStatus,
//     // status of suggestion
//     res.suggestionStatus,
//     // status of sentiment
//     res.sentimentStatus,
//   ].filter((st) => statusFlags.includes(st));

//   // Evaluate if all 4 flags are present (lengthCheck)
//   if (lengthCheck && lengthCheck === 4) {
//     // The expresson is flase if all four flags are succesful
//     // The negation make it true and return
//     // Return true if all the flags are present and status of all the flags is successful
//     return !(result.length !== 4);
//   }

//   // Evaluate if no. of flags (lengthCheck) is not 4
//   // The expression is true if any or zero flag is succesful,
//   // The negation make it flase and return
//   return !(result.length >= 0);
// };

// Returns False when status is done
// Returns True when status is in progress
export const checkAllStatusFlags = (
  res: any,
  statusFlags: number[],
  lengthCheck?: number
) => {
  if (lengthCheck && lengthCheck === 4) {
    return (
      [
        res.complaintStatus,
        res.complementStatus,
        res.suggestionStatus,
        res.sentimentStatus,
      ].filter((st) => statusFlags.includes(st)).length !== 4
    );
  }

  return (
    [
      res.complaintStatus,
      res.complementStatus,
      res.suggestionStatus,
      res.sentimentStatus,
    ].filter((st) => statusFlags.includes(st)).length >= 1
  );
};

// Wrapper component on top of UI pages to show the side nav bar
const DashboardLayoutWrapper = ({ children }: DashboardLayoutWrapperProps) => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const appData: any = useSelector((state: RootState) => state.app);
  const graphData: any = useSelector((state: RootState) => state.graphs);

  const [fetchRaw, setFetchRaw] = useState(false);
  const [refetchStatus, setRefetchStatus] = useState(false);

  // Feedback popup state
  const [showFeedback, setShowFeedback] = useState(false);

  const [length, setLength] = useState<{ [key: string]: any }>({});
  const [senLength, setSenLength] = useState<{ [key: string]: any }>({});

  // const [size, setSize] = useState(0);
  const [appsData, setappsData] = useState<{ [key: string]: string }>({});

  const basePath = "/dashboard";

  // Check if current user is an admin
  let ADMIN_IDS: any = [];
  try {
    ADMIN_IDS = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL_IDS || "[]");
  } catch (e) {
    ADMIN_IDS = process.env.REACT_APP_ADMIN_EMAIL_IDS || [];
  }
  //console.log(appData)

  // check status of all apps if status of complement, complain, sentiment and suggestion is not 7
  // Then call removeCurrentApp function to remove those apps
  // useEffect(() => {
  //   const func = async () => {
  //     //
  //     // if appData is not present or appData.app is not present or
  //     // appData.app.length is zero or appdata.app of data is not present
  //     // then fetch the appData from getAppDetails
  //     if (
  //       !appData ||
  //       (appData && !appData.app) ||
  //       (appData &&
  //         appData.app &&
  //         (appData.app.length === 0 ||
  //           (appData.app.length !== 0 && !appData.app[0].data)))
  //     ) {
  //       //console.log(appData);
  //       // App details fetch
  //       const res = await getAppDetails(appData, dispatch);
  //       //console.log(res);

  //       // get the userRole
  //       const userData: any = await getUserDetails(
  //         localStorage.getItem("username") || ""
  //       );

  //       const userDetails =
  //         userData && userData.getUser ? userData.getUser : {};

  //       const userRights = getUserRights(userDetails);
  //       let userRole = {};
  //       if (userRights) {
  //         userRole = {
  //           name: userDetails.name ? userDetails.name : userDetails.email,
  //           email: userDetails.email,
  //           role: getUserRole(userRights),
  //           organization: userRights.organization
  //             ? userRights.organization
  //             : "",
  //         };
  //       }

  //       // Check if all status is 7
  //       if (res.length !== 0) {
  //         // fetch all the appIds present and store it in appIDs array
  //         let appIDs: string[] = [];
  //         if (userData && userData.getUser && userData.getUser.userAppId) {
  //           try {
  //             appIDs = JSON.parse(userData.getUser.userAppId);
  //           } catch (e) {
  //             console.log(e);
  //             appIDs = userData.getUser.userAppId;
  //           }
  //         }
  //         // conver appIds into string
  //         appIDs = appIDs.map((item) => item.toString());

  //         // Contains the store information
  //         let storeData: any[] = [];

  //         //console.log(appIDs);
  //         // stores the detial to remove form the user
  //         let removeAppDetails: any[] = [];

  //         // For each user app check the status flags
  //         // Unsuccesful means remove it from user
  //         // checkStatusOfAllFlags return true if status flags are succesful
  //         res.forEach(({ store, appID, data }: DATA_BY_STORE) => {
  //           if (!checkStatusOfAllFlags(data, [7], 4)) {
  //             // store the details of userApps whose status flags are unsuccesful
  //             // store to remove the data using store and appid
  //             removeAppDetails = [
  //               ...removeAppDetails,
  //               { store: store, appId: appID, data: data },
  //             ];
  //           } else {
  //             storeData = [...storeData, store];
  //           }
  //         });

  //         // Check whether the removeAppDetails contails any unsuccessful
  //         // if contains then call removeCurrentApp function to remove that app or group of unsuccessful apps
  //         if (removeAppDetails.length !== 0) {
  //           removeCurrentApp(
  //             localStorage.getItem("username") || appData.user.email || "",
  //             removeAppDetails,
  //             appIDs,
  //             userRole,
  //             storeData,
  //             dispatch,
  //             navigate
  //           );
  //         }
  //       }
  //       // if appData is present but userData is not present then redirectto home
  //       else {
  //         if (
  //           !userData ||
  //           (userData && !userData.getUser) ||
  //           (userData && userData.getUser && !userData.getUser.userAppId)
  //         )
  //           navigate("/dashboard/home");
  //       }
  //     }
  //   };

  //   func();
  //   if (
  //     !appData ||
  //     (appData && !appData.user) ||
  //     (appData && appData.user && !appData.user.email)
  //   ) {
  //     getUserData(appData, dispatch);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const func = async () => {
      if (
        !appData ||
        (appData && !appData.app) ||
        (appData &&
          appData.app &&
          (appData.app.length === 0 ||
            (appData.app.length !== 0 && !appData.app[0].data)))
      ) {
        // App details fetch
        const res = await getAppDetails(appData, dispatch);
        // console.log(res);

        // Check if all status is 7

        if (
          res.length !== 0 &&
          res.filter((val: { store: string; data: any }) => {
            return checkAllStatusFlags(val.data, [7], 4);
          }).length !== 0
        ) {
          removeApp(
            localStorage.getItem("username") || appData.user.email || "",
            dispatch,
            navigate
          );
        }
      }
    };

    func();
    if (
      !appData ||
      (appData && !appData.user) ||
      (appData && appData.user && !appData.user.email)
    ) {
      getUserData(appData, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (
  //     appData &&
  //     appData.app &&
  //     appData.app.length &&
  //     appData.app[0] &&
  //     appData.app[0].status !== undefined
  //   ) {
  //     // Check if some error occurred after the import
  //     if (
  //       appData.app &&
  //       appData.app.length &&
  //       appData.app.filter((val: any) =>
  //         checkAllStatusFlags(val.data, [
  //           STATUS_FLAG.GPT_ERROR,
  //           STATUS_FLAG.CLUSTERING_ERROR,
  //           STATUS_FLAG.SENTIMENT_ERROR,
  //         ])
  //       ).length >= 1
  //     ) {
  //       // The app resulted in error during import stage
  //       // Redirect to the add app
  //       removeApp(
  //         localStorage.getItem("username") || appData.user.email || "",
  //         dispatch,
  //         navigate
  //       );
  //     }
  //   }

  //   const checkApps = async () => {
  //     // First get the user
  //     const appId = await getUserAppIDs(appData.user);

  //     if (!appId || (appId && appId.length === 0)) {
  //       navigate("/dashboard/home");
  //     }
  //   };

  //   if (appData && appData.user && appData.user.email) {
  //     checkApps();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appData, dispatch]);

  // Get data
  const getData = async (
    appId: string,
    store: string,
    property: string,
    type: string,
    action: any
  ) => {
    try {
      const data = await fetchTheDBData(getDBTable(type), appId);

      dispatch(
        action(
          setDataByStore(appData[property], {
            appID: appId,
            store: store,
            data: data,
          })
        )
      );
    } catch (error) {
      // console.error("Error fetching data:", error);
      // Handle error as needed
    }
  };

  // Step 1: Check in s3 bucket if the data for the particular app exists
  // Step 2: If data is present, fetch the data and store it in an intermediate variable
  //         for complaints, compliments and suggestions. Do merge of sentiments on top of this
  //         to keep data in respective cluster variable in store
  // Step 3: If the data doesn't exist in s3, get the data from dynamodb and follow the older flow
  // Note: The merging of clusters and sentiments data is same for both the flows. The s3 flow bypasses
  //       the clustering phase, and thus is taken up after that. This ensures low data transfer
  // const getS3Data = async (
  //   appId: string,
  //   store: string,
  //   property: string,
  //   type: string,
  //   action: any,
  //   actionTwo?: any
  // ) => {
  //   try {
  //     const data = await getDataFromS3(appId, getTableKey(type));
  //     if (actionTwo) {
  //       if (data.data && data.data.length) {
  //         dispatch(
  //           actionTwo(
  //             setDataByStore(appData[property], {
  //               appID: appId,
  //               store: store,
  //               data: data.data,
  //             })
  //           )
  //         );
  //       } else {
  //         dispatch(
  //           actionTwo(
  //             setDataByStore(appData[property], {
  //               appID: appId,
  //               store: store,
  //               data: data,
  //             })
  //           )
  //         );
  //       }
  //     }
  //     // When 2nd action to store doesn't exist, in case of sentiments and trending topics,
  //     // dispatch to 1st action for store
  //     if (!actionTwo && action) {
  //       if (data.data && data.data.length) {
  //         dispatch(
  //           action(
  //             setDataByStore(appData[property], {
  //               appID: appId,
  //               store: store,
  //               data: data.data,
  //             })
  //           )
  //         );
  //       } else {
  //         dispatch(
  //           action(
  //             setDataByStore(appData[property], {
  //               appID: appId,
  //               store: store,
  //               data: data,
  //             })
  //           )
  //         );
  //       }
  //     }
  //   } catch (e) {
  //     // console.log("Error Occurred", e);
  //     // console.log("Trigger older flow", type);
  //     getData(appId, store, appData[property], type, action);
  //   }
  // };

  // fetch data from s3 bucket
  const getS3Data = async (
    appId: string,
    store: string,
    property: string,
    type: string,
    action: any,
    actionTwo?: any
  ) => {
    try {
      const data = await getDataUsingURLFromS3(appId, getTableKey(type));
      if (actionTwo) {
        // if data is divided in to multiple files then exicute this part pf code by checking the flag
        // if the flag is present and it value is 1 then multiple files are presents in s3
        // if flag is not 1 then that file is the last file
        if (data && data.flag && data.flag === 1) {
          let newData: { [key: string]: string } = {};
          newData[appId] = appId;
          setappsData((prevData) => ({ ...prevData, ...newData }));

          let length: { [key: string]: any } = {};
          length[appId] = 0;
          setLength((prevData) => ({ ...prevData, ...length }));

          let senLength: { [key: string]: any } = {};
          length[appId] = 0;
          setSenLength((prevData) => ({ ...prevData, ...senLength }));

          dispatch(
            actionTwo(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data.data,
              })
            )
          );

          // Initialize total_data as an empty array
          // it fetch all the data periodically and store
          let total_data: any[] = [];
          // initialized to 0 and used extact all the subsequent files after the first file
          let i: integer = 0;

          // used to identify the action to use
          const actionType: Number = 2;

          // if (data.limit % data.set_limit === 0 && data.set_limit % 100 === 0) {
          //   itr = data.limit / data.set_limit;
          // } else {
          //   itr = data.limit / data.set_limit + 1;
          // }
          // add new data to previous data to update the current state
          total_data = [...total_data, ...data.data];

          // increment i to fetch the next data from s3
          i = i + 1;

          // store the limit and set_limit value to use it in future
          // setLimit((prevLimit) => Math.max(prevLimit, data.limit));
          // setSize((prevSize) => Math.max(prevSize, data.set_limit));
          // call the function to fetch the data for the next file in s3
          addDataFromS3(
            appId,
            store,
            type,
            property,
            actionType,
            i,
            total_data,
            action,
            actionTwo
          );
        } else if (data.data && data.data.length) {
          dispatch(
            actionTwo(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data.data,
              })
            )
          );
        } else {
          dispatch(
            actionTwo(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data,
              })
            )
          );
        }
      }
      // When 2nd action to store doesn't exist, in case of sentiments and trending topics,
      // dispatch to 1st action for store
      if (!actionTwo && action) {
        // if data is divided in to multiple files then exicute this part of code by checking the flag
        // if the flag is present and it value is 1 then multiple files are presents in s3
        // if flag is not 1 then that file is the last file
        if (data && data.flag && data.flag === 1) {
          // use to store the appid whose file is divided
          let newData: { [key: string]: string } = {};
          newData[appId] = appId;
          // store the appids whose files are divided in multiple parts
          setappsData((prevData) => ({ ...prevData, ...newData }));
          dispatch(
            action(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data.data,
              })
            )
          );

          // Initialize total_data as an empty array
          // it fetch all the data periodically and store
          let total_data: any[] = [];

          // initialized to 0 and used extact all the subsequent files after the first file
          let i: integer = 0;
          const actionType: Number = 1;

          // add new data to previous data to update the current state
          total_data = [...total_data, ...data.data];

          // increment i to fetch the next data from s3
          i = i + 1;

          // call the function to fetch the data for the next file in s3
          addDataFromS3(
            appId,
            store,
            type,
            property,
            actionType,
            i,
            total_data,
            action,
            actionTwo
          );
        } else if (data.data && data.data.length) {
          dispatch(
            action(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data.data,
              })
            )
          );
        } else {
          dispatch(
            action(
              setDataByStore(appData[property], {
                appID: appId,
                store: store,
                data: data,
              })
            )
          );
        }
      }
    } catch (e) {
      // console.log("Error Occurred", e);
      // console.log("Trigger older flow", type);
      getData(appId, store, appData[property], type, action);
    }
  };

  // fetch the subsequent files from s3
  const addDataFromS3 = async (
    appId: string,
    store: string,
    type: string,
    property: string,
    actionType: any,
    i: integer,
    total_data: any[],
    action: any,
    actionTwo?: any
  ) => {
    try {
      // fetch the data from s3
      const data = await getDataUsingURLFromS3(
        appId,
        `${getTableKey(type)}_${i}`
      );
      // if data is divided in to multiple files then exicute this part of code by checking the f
      // if the flag is present and it value is 1 then multiple files are presents in s3
      // if flag is not 1 then that file is the last file
      if (data && data.flag && data.flag === 1) {
        // update the current data
        total_data = [...total_data, ...data.data];

        // increment i for next file fetch
        i = i + 1;

        // Recursive call for the next iteration
        addDataFromS3(
          appId,
          store,
          type,
          property,
          actionType,
          i,
          total_data,
          action,
          actionTwo
        );
      } else if (data) {
        // Flag is 0, indicating the last file.
        // Update the state with the remaining data and return.
        total_data = [...total_data, ...data.data];

        dispatch(
          actionTwo
            ? actionTwo(
                setDataByStore(appData[property], {
                  appID: appId,
                  store: store,
                  data: total_data,
                })
              )
            : action(
                setDataByStore(appData[property], {
                  appID: appId,
                  store: store,
                  data: total_data,
                })
              )
        );
      }
    } catch (e) {
      console.log("error", e);
      throw e;
    }
  };

  const getGraphData = async (
    appId: string,
    store: string,
    property: string,
    type: string,
    action: any
  ) => {
    // console.log("inside");
    let count = 0;
    const getGraphDataWithRetry = async (): Promise<any> => {
      try {
        // fetch the data from db using appId as id
        const data = await getGraphDetails(appId || "");
        // console.log(data);
        // check if data.getApp.url is not empty
        if (!data.getApp.url) {
          // If the url is empty, throw an error
          throw new Error("Data in the database is empty.");
        }

        // extract the object from url column (currenty present in url in appData)
        const curr_data = JSON.parse(data.getApp.url);

        // we use date as key for the object
        // extract the date on which it send to the db
        const dateKey = Object.keys(curr_data);
        const formatDate = (date: any) => date.toISOString().split("T")[0];
        // Get today's date
        const today = new Date();
        const formattedToday = formatDate(today);

        // Get yesterday's date
        // const yesterday = new Date(today);
        // yesterday.setDate(today.getDate() - 1);
        // const formattedYesterday = formatDate(yesterday);

        // // Get the day before yesterday's date
        // const dayBeforeYesterday = new Date(today);
        // dayBeforeYesterday.setDate(today.getDate() - 2);
        // const formattedDayBeforeYesterday = formatDate(dayBeforeYesterday);

        // check if the data is updated or not
        // if the data is pushed to the db today or yesterday or day before yesterday
        // then  we fetch that data
        if (!dateKey.includes(formattedToday)) {
          throw new Error("The DB contain old data");
        } else if (dateKey.includes(formattedToday)) {
          const actual_data = curr_data[formattedToday];
          return actual_data;
        }
        //  else if (dateKey.includes(formattedYesterday)) {
        //   const actual_data = curr_data[formattedYesterday];
        //   return actual_data;
        // } else if (dateKey.includes(formattedDayBeforeYesterday)) {
        //   const actual_data = curr_data[formattedDayBeforeYesterday];
        //   return actual_data;
        // }
      } catch (e) {
        // Handle errors if needed
        throw e;
      }
      // If data is not available, wait for 5 seconds before retrying
      await new Promise((resolve) => setTimeout(resolve, 5000));
      count++;
      // Retry for a maximum of 5 times
      if (count < 6) {
        return getGraphDataWithRetry();
      } else {
        throw new Error("Max retry count reached");
      }
    };
    try {
      let data = await getGraphDataWithRetry();
      // Backward compatibility for change to new API Gateway changes
      // console.log(data);
      if (data && data.body) {
        // console.log(data);
        let response = {};
        try {
          response = JSON.parse(data.body);
        } catch (e) {
          response = data.body;
        }
        data = response;
      }

      // Only checking if suggestions all time data is empty.
      // That is common among various data sources for the time being

      if (
        !data ||
        !data.allTime ||
        !data.allTime.data ||
        !data.allTime.data.suggestions ||
        (data &&
          data.allTime &&
          data.allTime.data &&
          data.allTime.data.suggestions &&
          data.allTime.data.suggestions.filter((d: number) => d !== 0)
            .length === 0)
      ) {
        throw new Error("No data existing");
      } else if (data) {
        dispatch(
          action([
            {
              store,
              appID: appId,
              data,
            },
          ])
        );
      }
    } catch (e) {
      try {
        let data = await fetchFeedbackGraph(appId);
        // Backward compatibility for change to new API Gateway changes
        // console.log("the data", data);
        if (data && data.body) {
          let response = {};
          try {
            response = JSON.parse(data.body);
          } catch (e) {
            response = data.body;
          }
          data = response;
        }

        // Only checking if suggestions all time data is empty.
        // That is common among various data sources for the time being
        if (
          data &&
          data.allTime &&
          data.allTime.data &&
          data.allTime.data.suggestions &&
          data.allTime.data.suggestions.filter((d: number) => d !== 0)
            .length === 0
        ) {
          throw new Error("No data existing");
        } else if (data) {
          dispatch(
            action([
              {
                store,
                appID: appId,
                data,
              },
            ])
          );
        }
      } catch (e) {
        // Maybe add a fallback scenario where the raw data is fetched
        // and the calculations are done to put in the store
        // console.log("Error during graph fetch");

        // if the ui unable to fetch the data from dashBoardgraph
        // them it will fetch the data from db
        // set the count to zero
        try {
          let data = await getGraphDataWithRetry();
          // Backward compatibility for change to new API Gateway changes
          // console.log(data);
          if (data && data.body) {
            let response = {};
            try {
              response = JSON.parse(data.body);
            } catch (e) {
              response = data.body;
            }
            data = response;
          }

          // Only checking if suggestions all time data is empty.
          // That is common among various data sources for the time being
          if (
            data &&
            data.allTime &&
            data.allTime.data &&
            data.allTime.data.suggestions &&
            data.allTime.data.suggestions.filter((d: number) => d !== 0)
              .length === 0
          ) {
            throw new Error("No data existing");
          } else if (data) {
            dispatch(
              action([
                {
                  store,
                  appID: appId,
                  data,
                },
              ])
            );
          }
        } catch (e) {
          setFetchRaw(true);
          getData(
            appId,
            store,
            property,
            COMPLAINT_FETCH,
            AppActions.setComplaintsData
          );
          getData(
            appId,
            store,
            property,
            COMPLIMENT_FETCH,
            AppActions.setComplimentsData
          );
          getData(
            appId,
            store,
            property,
            SUGGESTION_FETCH,
            AppActions.setSuggestionsData
          );
        }
      }
    }
  };

  // useEffect to call for Complaints, Compliments, Suggestions and Sentiments Data
  useEffect(() => {
    // SENTIMENT/COMPLIMENT/COMPLAINT/SUGGESTION
    // Check if the data already exists
    // Making sure data is fetched for all appIds attached to user
    if (
      !location.pathname.includes("rargus-admin") &&
      appData.app &&
      appData.app.length !== 0
    ) {
      let statusCheck: boolean[] = [];
      appData.app.forEach((app: DATA_BY_STORE) => {
        const store = [
          {
            store: app.store,
            appID: app.appID,
          },
        ];
        // .map((s: GET_DATA_STORE) => s.store);
        const sentimentFetch = getDataByStore(appData.sentimentFetch, store);

        const complaints = getDataByStore(appData.complaints, store);
        const compliments = getDataByStore(appData.compliments, store);
        const suggestions = getDataByStore(appData.suggestions, store);
        const sentiments = getDataByStore(appData.sentiments, store);
        const trendingTopics = getDataByStore(appData.trendingTopics, store);

        if (!checkAllStatusFlags(app.data, [7], 4)) {
          statusCheck = [...statusCheck, true];
        }

        if (sentimentFetch && sentimentFetch.length === 0) {
          // Complaints
          if (
            !appData ||
            (appData && !appData.complaints) ||
            (appData && appData.complaints && complaints.length === 0)
          ) {
            getS3Data(
              app.data.appID,
              app.store,
              "complaints",
              COMPLAINT_FETCH,
              AppActions.setComplaintsData,
              AppActions.setTempComplaintCluster
            );
          }

          // Compliments
          if (
            !appData ||
            (appData && !appData.compliments) ||
            (appData && appData.compliments && compliments.length === 0)
          ) {
            getS3Data(
              app.data.appID,
              app.store,
              "compliments",
              COMPLIMENT_FETCH,
              AppActions.setComplimentsData,
              AppActions.setTempComplimentCluster
            );
          }

          // Suggestions
          if (
            !appData ||
            (appData && !appData.suggestions) ||
            (appData && appData.suggestions && suggestions.length === 0)
          ) {
            getS3Data(
              app.data.appID,
              app.store,
              "suggestions",
              SUGGESTION_FETCH,
              AppActions.setSuggestionsData,
              AppActions.setTempSuggestionCluster
            );
          }

          // Sentiments
          if (
            !appData ||
            (appData && !appData.sentiments) ||
            (appData && appData.sentiments && sentiments.length === 0)
          ) {
            getS3Data(
              app.data.appID,
              app.store,
              "sentiments",
              SENTIMENT_FETCH,
              AppActions.setSentimentsData
            );
          }

          // Trending Topics
          if (
            !appData ||
            (appData && !appData.trendingTopics) ||
            (appData && appData.trendingTopics && trendingTopics.length === 0)
          ) {
            getS3Data(
              app.data.appID,
              app.store,
              "trendingTopics",
              TRENDING_FETCH,
              AppActions.setTrendingTopics
            );
          }


          dispatch(
            AppActions.setSentimentsFetch(
              setDataByStore(appData.sentimentFetch, {
                appID: app.appID,
                store: app.store,
                data: true,
              })
            )
          );
        }
      });

      if (statusCheck.length !== appData.app.length && !refetchStatus) {
        setRefetchStatus(true);
        console.log("Setting refetch App to true");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.app]);

  useEffect(() => {
    if (
      !location.pathname.includes("rargus-admin") &&
      appData.app &&
      appData.app.length !== 0
    ) {
      appData.app.forEach((app: DATA_BY_STORE) => {
        const store = [
          {
            store: app.store,
            appID: app.appID,
          },
        ];
        const dataFetched = getDataByStore(graphData.dataFetched, store);
        // Get Feedback Dashboard Graph
        if (
          !graphData ||
          (graphData && dataFetched.length === 0) ||
          appData.filter.value !== "custom"
        ) {
          getGraphData(
            app.data.appID,
            app.store,
            "feedback",
            "",
            GraphActions.setFeedbackGraphData
          );
          // So that no further fetch occurs
          dispatch(
            GraphActions.setDataFetched(
              setDataByStore(graphData.dataFetched, {
                appID: app.appID,
                store: app.store,
                data: true,
              })
            )
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.app]);

  useEffect(() => {
    if (refetchStatus && location && !location.pathname.includes("import")) {
      const checkAppData = async (appData: any) => {
        // Check if all app data has success status...
        // If yes, navigate to feedback
        const res: any = await getAppDetails(appData, dispatch);
        if (res) {
          if (
            res.length !== 0 &&
            res.filter((val: { store: string; data: any }) => {
              return checkAllStatusFlags(val.data, [7], 4);
            }).length === 0
          ) {
            // The app data is succesfully processed
            // TODO: Only one mail should go to user, hence some uniform logic needs to be present for this.
            // TODO: Check if the date of import is same as current date. If yes, send mail from here.
            // Otherwise wait for betch job to send the mail
            // await sendEmailToUser([localStorage.getItem("username") || ""]);
            setRefetchStatus(false);
          }
        }
      };

      checkAppData(appData);
      const interval = setInterval(() => {
        checkAppData(appData);
      }, 10000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchStatus]);

  useEffect(() => {
    if (fetchRaw) {
      const data = getFeedbackDashboardGraph(appData);
      dispatch(GraphActions.setFeedbackGraphData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.complaints, appData.compliments, appData.suggestions]);

  // Sentiment Over Time Graph Points Creation
  useEffect(() => {
    const data = getSentimentOverTimeGraph(appData);
    dispatch(GraphActions.setSentimentOverTimeGraphData(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.sentiments, dispatch]);

  // // To update the graphs that depend on filter value change and are not dynamic
  // useEffect(() => {
  //   if (
  //     appData.filter &&
  //     appData.filter.value === "custom" &&
  //     appData.filter.dates &&
  //     appData.filter.dates.length === 2
  //   ) {
  //     const data = getSentimentOverTimeGraph(appData);
  //     console.log(data);
  //     dispatch(GraphActions.setSentimentOverTimeGraphData(data));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appData.filter]);

  // Flow following the call from dynamodb
  useEffect(() => {
    if (
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.complaints &&
      appData.complaints.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const complaints = getDataByStore(appData.complaints, store);
          const complaintCluster = getDataByStore(
            appData.complaintCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);
          if (
            complaints &&
            complaints.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            complaintCluster &&
            (complaintCluster.length === 0 || appsData[store[0].appID])
          ) {
            const cluster = constructClusterSentiment(
              constructDataArray(complaints, "complaints"),
              sentiments
            );
            dispatch(
              AppActions.setComplaintReviewMapping(
                setDataByStore(appData.complaintReviewMapping, {
                  appID: app.appID,
                  store: app.store,
                  data: getReviewMappings(cluster),
                })
              )
            );
            dispatch(
              AppActions.setComplaintCluster(
                setDataByStore(appData.complaintCluster, {
                  appID: app.appID,
                  store: app.store,
                  data: cluster,
                })
              )
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.complaints, appData.sentiments]);

  // Flow following the call from s3
  useEffect(() => {
    if (
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.tempComplaintCluster &&
      appData.tempComplaintCluster.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const complaints = getDataByStore(
            appData.tempComplaintCluster,
            store
          );
          const complaintCluster = getDataByStore(
            appData.complaintCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);

          if (
            complaints &&
            complaints.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            complaintCluster &&
            (complaintCluster.length === 0 || appsData[store[0].appID])
          ) {
            const cluster = constructClusterSentiment(complaints, sentiments);
            dispatch(
              AppActions.setComplaintReviewMapping(
                setDataByStore(appData.complaintReviewMapping, {
                  appID: app.appID,
                  store: app.store,
                  data: getReviewMappings(cluster),
                })
              )
            );
            dispatch(
              AppActions.setComplaintCluster(
                setDataByStore(appData.complaintCluster, {
                  appID: app.appID,
                  store: app.store,
                  data: cluster,
                })
              )
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.tempComplaintCluster, appData.sentiments]);

  // Compliments flow following dynamodb
  useEffect(() => {
    if (
      appData.sentiments &&
      appData.sentiments.length &&
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.compliments &&
      appData.compliments.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const compliments = getDataByStore(appData.compliments, store);
          const complimentCluster = getDataByStore(
            appData.complimentCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);

          if (
            compliments &&
            compliments.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            complimentCluster &&
            (complimentCluster.length === 0 || appsData[store[0].appID])
          ) {
            const cluster = constructClusterSentiment(
              constructDataArray(compliments, "compliments"),
              sentiments
            );
            dispatch(
              AppActions.setComplimentReviewMapping(
                setDataByStore(appData.complimentReviewMapping, {
                  appID: app.appID,
                  store: app.store,
                  data: getReviewMappings(cluster),
                })
              )
            );
            dispatch(
              AppActions.setComplimentCluster(
                setDataByStore(appData.complimentCluster, {
                  appID: app.appID,
                  store: app.store,
                  data: cluster,
                })
              )
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.compliments, appData.sentiments]);

  // Compliments flow following s3
  useEffect(() => {
    if (
      (!appData ||
        (appData && !appData.complimentCluster) ||
        (appData && appData.complimentCluster)) &&
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.tempComplimentCluster &&
      appData.tempComplimentCluster.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const compliments = getDataByStore(
            appData.tempComplimentCluster,
            store
          );
          const complimentCluster = getDataByStore(
            appData.complimentCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);

          if (
            compliments &&
            compliments.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            complimentCluster &&
            (complimentCluster.length === 0 || appsData[store[0].appID])
          ) {
            const cluster = constructClusterSentiment(compliments, sentiments);
            dispatch(
              AppActions.setComplimentReviewMapping(
                setDataByStore(appData.complimentReviewMapping, {
                  appID: app.appID,
                  store: app.store,
                  data: getReviewMappings(cluster),
                })
              )
            );
            dispatch(
              AppActions.setComplimentCluster(
                setDataByStore(appData.complimentCluster, {
                  appID: app.appID,
                  store: app.store,
                  data: cluster,
                })
              )
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.tempComplimentCluster, appData.sentiments]);

  // Suggestion flow following dynamodb
  useEffect(() => {
    if (
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.suggestions &&
      appData.suggestions.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const suggestions = getDataByStore(appData.suggestions, store);
          const suggestionCluster = getDataByStore(
            appData.suggestionCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);
          // checking if suggestion cluster is not present for this store, and the sentiments data exist
          if (
            suggestions &&
            suggestions.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            suggestionCluster &&
            (suggestionCluster.length === 0 || appsData[store[0].appID])
          ) {
            const cluster = constructClusterSentiment(
              constructDataArray(suggestions, "suggestions"),
              sentiments
            );
            dispatch(
              AppActions.setSuggestionReviewMapping(
                setDataByStore(appData.suggestionReviewMapping, {
                  appID: app.appID,
                  store: app.store,
                  data: getReviewMappings(cluster),
                })
              )
            );
            dispatch(
              AppActions.setSuggestionCluster(
                setDataByStore(appData.suggestionCluster, {
                  appID: app.appID,
                  store: app.store,
                  data: cluster,
                })
              )
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.suggestions, appData.sentiments]);

  // // Suggestion flow following s3
  // useEffect(() => {
  //   if (
  //     (!appData ||
  //       (appData && !appData.suggestionCluster) ||
  //       (appData && appData.suggestionCluster)) &&
  //     appData.sentimentsObject &&
  //     appData.sentimentsObject.length &&
  //     appData.sentiments &&
  //     appData.sentiments.length &&
  //     appData.tempSuggestionCluster &&
  //     appData.tempSuggestionCluster.length
  //   ) {
  //     console.log(appData.tempSuggestionCluster);
  //     if (appData.app && appData.app.length) {
  //       appData.app.forEach((app: DATA_BY_STORE) => {
  //         const store = [
  //           {
  //             store: app.store,
  //             appID: app.appID,
  //           },
  //         ];
  //         // .map((s: GET_DATA_STORE) => s.store);

  //         const suggestions = getDataByStore(
  //           appData.tempSuggestionCluster,
  //           store
  //         );
  //         const suggestionCluster = getDataByStore(
  //           appData.suggestionCluster,
  //           store
  //         );
  //         const sentiments = getDataByStore(appData.sentimentsObject, store);
  //         console.log(sentiments);
  //         // checking if suggestion cluster is not present for this store, and the sentiments data exist
  //         if (
  //           suggestions &&
  //           suggestions.length !== 0 &&
  //           sentiments &&
  //           sentiments.length !== 0 &&
  //           suggestionCluster &&
  //           (suggestionCluster.length === 0)
  //         ) {
  //           const cluster = constructClusterSentiment(suggestions, sentiments);
  //           console.log(cluster);
  //           console.log(appData.suggestionCluster);
  //           //  new one

  //           dispatch(
  //             AppActions.setSuggestionReviewMapping(
  //               setDataByStore(appData.suggestionReviewMapping, {
  //                 appID: app.appID,
  //                 store: app.store,
  //                 data: getReviewMappings(cluster),
  //               })
  //             )
  //           );
  //           dispatch(
  //             AppActions.setSuggestionCluster(
  //               setDataByStore(appData.suggestionCluster, {
  //                 appID: app.appID,
  //                 store: app.store,
  //                 data: cluster,
  //               })
  //             )
  //           );
  //         }
  //         console.log(appData.suggestionCluster);
  //       });
  //     }
  //     // Example if distinct is required
  //     // dispatch(
  //     //   AppActions.setReviewMappings(
  //     //     distictObjectById(
  //     //       [...appData.reviewMappings, ...getReviewMappings(cluster)],
  //     //       "point"
  //     //     )
  //     //   )
  //     // );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appData.tempSuggestionCluster, appData.sentiments]);

  // modify the useEffect to handle multiple files at the same times
  // Suggestion flow following s3
  useEffect(() => {
    if (
      (!appData ||
        (appData && !appData.suggestionCluster) ||
        (appData && appData.suggestionCluster)) &&
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.tempSuggestionCluster &&
      appData.tempSuggestionCluster.length
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          const suggestions = getDataByStore(
            appData.tempSuggestionCluster,
            store
          );
          const suggestionCluster = getDataByStore(
            appData.suggestionCluster,
            store
          );
          const sentiments = getDataByStore(appData.sentimentsObject, store);
          const sentimentLength = getDataByStore(appData.sentiments, store);
          // checking if suggestion cluster is not present for this store, and the sentiments data exist
          if (
            suggestions &&
            suggestions.length !== 0 &&
            sentiments &&
            sentiments.length !== 0 &&
            suggestionCluster &&
            (suggestionCluster.length === 0 || appsData[store[0].appID])
          ) {
            if (
              suggestionCluster.length === 0 ||
              length[store[0].appID] !==
                suggestions.length + sentimentLength.length
            ) {
              const cluster = constructClusterSentiment(
                suggestions,
                sentiments
              );
              //  new one

              dispatch(
                AppActions.setSuggestionReviewMapping(
                  setDataByStore(appData.suggestionReviewMapping, {
                    appID: app.appID,
                    store: app.store,
                    data: getReviewMappings(cluster),
                  })
                )
              );
              dispatch(
                AppActions.setSuggestionCluster(
                  setDataByStore(appData.suggestionCluster, {
                    appID: app.appID,
                    store: app.store,
                    data: cluster,
                  })
                )
              );
              setLength((prevData) => ({
                ...prevData,
                [store[0].appID]: suggestions.length + sentimentLength.length,
              }));
            }
          }
        });
      }
      // Example if distinct is required
      // dispatch(
      //   AppActions.setReviewMappings(
      //     distictObjectById(
      //       [...appData.reviewMappings, ...getReviewMappings(cluster)],
      //       "point"
      //     )
      //   )
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.tempSuggestionCluster, appData.sentiments]);

  useEffect(() => {
    if (
      appData.clusterSentiment &&
      appData.sentiments &&
      appData.sentiments.length &&
      appData.sentimentsObject &&
      appData.sentimentsObject.length &&
      appData.complaintCluster &&
      appData.complimentCluster &&
      appData.suggestionCluster &&
      appData.trendingTopics &&
      appData.complimentCluster
    ) {
      if (appData.app && appData.app.length) {
        appData.app.forEach((app: DATA_BY_STORE) => {
          const store = [
            {
              store: app.store,
              appID: app.appID,
            },
          ];
          // .map((s: GET_DATA_STORE) => s.store);

          // Moving the check for fetch based on app store and checking if cluster sentiment exists
          const clusterSentiment = getDataByStore(
            appData.clusterSentiment,
            store
          );

          if (
            clusterSentiment &&
            (clusterSentiment.length === 0 || appsData[store[0].appID])
          ) {
            const complaint = getDataByStore(appData.complaintCluster, store)
              ? getDataByStore(appData.complaintCluster, store)
              : [];
            const compliment = getDataByStore(appData.complimentCluster, store)
              ? getDataByStore(appData.complimentCluster, store)
              : [];
            const suggestion = getDataByStore(appData.suggestionCluster, store)
              ? getDataByStore(appData.suggestionCluster, store)
              : [];
            if (
              (complaint && complaint.length !== 0) ||
              (compliment && compliment.length !== 0) ||
              (suggestion && suggestion.length !== 0)
            ) {
              const sentiments = getDataByStore(
                appData.sentimentsObject,
                store
              );
              const sentimentLengths = getDataByStore(
                appData.sentiments,
                store
              );

              const trendingTopics = getDataByStore(
                appData.trendingTopics,
                store
              );
              if (
                clusterSentiment.length === 0 ||
                senLength[store[0].appID] !==
                  complaint.length +
                    compliment.length +
                    suggestion.length +
                    sentimentLengths.length +
                    trendingTopics.length
              ) {
                const cluster = constructClusterSentiment(
                  [
                    ...(complaint ? complaint : []),
                    ...(compliment ? compliment : []),
                    ...(suggestion ? suggestion : []),
                  ],
                  sentiments ? sentiments : [],
                  trendingTopics ? trendingTopics : undefined
                );
                dispatch(
                  AppActions.setClusterSentiment(
                    setDataByStore(appData.clusterSentiment, {
                      appID: app.data.appID,
                      store: app.store,
                      data: cluster,
                    })
                  )
                );
                setSenLength((prevData) => ({
                  ...prevData,
                  [store[0].appID]:
                    complaint.length +
                    compliment.length +
                    suggestion.length +
                    sentimentLengths.length +
                    trendingTopics.length,
                }));
              }
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    appData.sentiments,
    appData.complaintCluster,
    appData.complimentCluster,
    appData.suggestionCluster,
    appData.trendingTopics,
  ]);

  // useEffect(() => {
  //   if (
  //     appData.clusterSentiment &&
  //     appData.sentiments &&
  //     appData.sentiments.length &&
  //     appData.sentimentsObject &&
  //     appData.sentimentsObject.length &&
  //     appData.complaintCluster &&
  //     appData.complimentCluster &&
  //     appData.suggestionCluster &&
  //     appData.trendingTopics &&
  //     appData.complimentCluster
  //   ) {
  //     if (appData.app && appData.app.length) {
  //       console.log(appData.app);
  //       appData.app.forEach((app: DATA_BY_STORE) => {
  //         const store = [
  //           {
  //             store: app.store,
  //             appID: app.appID,
  //           },
  //         ];
  //         // .map((s: GET_DATA_STORE) => s.store);

  //         // Moving the check for fetch based on app store and checking if cluster sentiment exists
  //         const clusterSentiment = getDataByStore(
  //           appData.clusterSentiment,
  //           store
  //         );
  //         console.log(appsData);
  //         console.log(appData.clusterSentiment);
  //         if (
  //           clusterSentiment &&
  //           (clusterSentiment.length === 0)
  //         ) {
  //           const complaint = getDataByStore(appData.complaintCluster, store);
  //           const compliment = getDataByStore(appData.complimentCluster, store);
  //           const suggestion = getDataByStore(appData.suggestionCluster, store);
  //           if (complaint || compliment || suggestion) {
  //             const sentiments = getDataByStore(
  //               appData.sentimentsObject,
  //               store
  //             );
  //             const trendingTopics = getDataByStore(
  //               appData.trendingTopics,
  //               store
  //             );
  //             const cluster = constructClusterSentiment(
  //               [
  //                 ...(complaint ? complaint : []),
  //                 ...(compliment ? compliment : []),
  //                 ...(suggestion ? suggestion : []),
  //               ],
  //               sentiments ? sentiments : [],
  //               trendingTopics ? trendingTopics : undefined
  //             );
  //             console.log(appsData);
  //             console.log(appsData[store[0].appID], " is ", cluster);
  //             console.log(appData.clusterSentiment);
  //             dispatch(
  //               AppActions.setClusterSentiment(
  //                 setDataByStore(appData.clusterSentiment, {
  //                   appID: app.data.appID,
  //                   store: app.store,
  //                   data: cluster,
  //                 })
  //               )
  //             );
  //             console.log(appData.clusterSentiment);
  //           }
  //         }
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   dispatch,
  //   appData.sentiments,
  //   appData.complaintCluster,
  //   appData.complimentCluster,
  //   appData.suggestionCluster,
  //   appData.trendingTopics,
  // ]);

  const getGenericDashboardPaths = (widgets: any) => {
    let paths: MenuItem[] = [];

    if (widgets && Object.keys(widgets) && Object.keys(widgets).length >= 4) {
      // Dashboard pages other than the default four exists
      Object.keys(widgets).forEach((page: string) => {
        if (!DEFAULT_DASHBOARD_PAGES.includes(widgets[page].name)) {
          let routeName;
          if (widgets[page].name === "" && page !== "newDashboard") {
            routeName = getRouteName(page);
          } else if (page === "newDashboard") {
            routeName = "newDashboard";
          } else routeName = getRouteName(widgets[page].name);
          paths.push({
            label: widgets[page].name || page,
            route: `${basePath}/insights/${routeName}`,
            regex: routeName,
          });
        }
      });
    }

    return paths;
  };

  // Generate the menu items for the generic dashboard paths
  const widgetAndGenericPagesSet = (widgets: {
    dashboard: WidgetPage;
    analytics: WidgetPage;
    sentiment: WidgetPage;
    rating: WidgetPage;
    "app-feedback"?: WidgetPage;
  }) => {
    let tempWidgets = { ...widgets };

    // If user is admin, add the feedback page to see app feedback data
    if (ADMIN_IDS.includes(localStorage.getItem("username"))) {
      tempWidgets = {
        ...tempWidgets,
        "app-feedback": {
          name: "App feedback",
          widgets: DEFAULT_ADMIN_FEEDBACK_WIDGETS,
        },
      };
    }

    dispatch(AppActions.setWidgetData(tempWidgets));
  };

  // Use effect to fetch the user's widget list and defined positions
  useEffect(() => {
    // Check if the user has any items that they shouldn't have, and if present, revert back to default view.
    const checkDashboardWidgets = (widgets: any) => {
      let checkedWidgets = {
        ...widgets,
        ...(widgets.analytics &&
        widgets.analytics.widgets &&
        widgets.analytics.widgets.length === 0
          ? {
              analytics: {
                ...widgets.analytics,
                widgets: DEFAULT_ANALYTICS_WIDGET(appData),
              },
            }
          : {}),
        ...(widgets.sentiment &&
        widgets.sentiment.widgets &&
        widgets.sentiment.widgets.length === 0
          ? {
              sentiment: {
                ...widgets.sentiment,
                widgets: DEFAULT_SENTIMENT_WIDGETS,
              },
            }
          : {}),
        ...(widgets.rating &&
        widgets.rating.widgets &&
        widgets.rating.widgets.length === 0
          ? { rating: { ...widgets.rating, widgets: DEFAULT_RATING_WIDGETS } }
          : {}),
      };
      if (
        appData.store &&
        appData.store.length &&
        appData.store.includes(STORE.aha)
      ) {
        checkedWidgets = {
          ...checkedWidgets,
          dashboard: {
            ...widgets.dashboard,
            widgets:
              widgets.dashboard.widgets &&
              widgets.dashboard.widgets.filter((widget: { id: string }) =>
                [
                  "feedback-dashboard-sentiments-graph",
                  "feedback-dashboard-star-ratings-graph",
                ].includes(widget.id)
              ).length !== 0
                ? DEFAULT_DASHBOARD_WIDGETS(appData)
                : widgets.dashboard.widgets,
          },
          analytics: {
            ...widgets.analytics,
            widgets: DEFAULT_ANALYTICS_WIDGET(appData),
            // widgets:
            // widgets.analytics.widgets &&
            // widgets.analytics.widgets.filter((widget: { id: string }) =>
            //   ["analytics-relevancy-graph"].includes(widget.id)
            // ).length !== 0
            //   ? DEFAULT_ANALYTICS_WIDGET(appData)
            //   : widgets.analytics.widgets,
          },
        };
      }

      if (
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.apple) ||
          appData.store.includes(STORE.google))
      ) {
        checkedWidgets = {
          ...checkedWidgets,
          dashboard: {
            ...widgets.dashboard,
            // Removing the check
            // If the widgets don't exist, the user needs to add them manually.
            // This condition is applicable only for Page Admins while changing between Aha and App Source
            // widgets:
            //   widgets.dashboard.widgets &&
            //   widgets.dashboard.widgets.filter((widget: { id: string }) =>
            //     [
            //       "feedback-dashboard-sentiments-graph",
            //       "feedback-dashboard-star-ratings-graph",
            //     ].includes(widget.id)
            //   ).length === 0
            //     ? DEFAULT_DASHBOARD_WIDGETS(appData)
            //     : widgets.dashboard.widgets,
          },
          analytics: {
            ...widgets.analytics,
            widgets:
              widgets.analytics.widgets &&
              widgets.analytics.widgets.filter((widget: { id: string }) =>
                ["analytics-relevancy-graph"].includes(widget.id)
              ).length === 0
                ? DEFAULT_ANALYTICS_WIDGET(appData)
                : widgets.analytics.widgets,
          },
        };
      }

      // use to show the line graph for the github and sof also
      if (
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.github) ||
          appData.store.includes(STORE.sof))
      ) {
        checkedWidgets = {
          ...checkedWidgets,
          dashboard: {
            ...widgets.dashboard,
            // Removing the check
            // If the widgets don't exist, the user needs to add them manually.
            // This condition is applicable only for Page Admins while changing between Aha and App Source
            // widgets:
            //   widgets.dashboard.widgets &&
            //   widgets.dashboard.widgets.filter((widget: { id: string }) =>
            //     [
            //       "feedback-dashboard-sentiments-graph",
            //       "feedback-dashboard-star-ratings-graph",
            //     ].includes(widget.id)
            //   ).length === 0
            //     ? DEFAULT_DASHBOARD_WIDGETS(appData)
            //     : widgets.dashboard.widgets,
          },
          analytics: {
            ...widgets.analytics,
            widgets:
              widgets.analytics.widgets &&
              widgets.analytics.widgets.filter((widget: { id: string }) =>
                ["analytics-relevancy-graph-extention"].includes(widget.id)
              ).length === 0
                ? DEFAULT_ANALYTICS_WIDGET(appData)
                : widgets.analytics.widgets,
          },
        };
      }
      return checkedWidgets;
    };
    const checkUserSchema = async () => {
      let flag = false;
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userWidgets)
      ) {
        const user = await getUserData(appData, dispatch);
        if (user && user.userWidgets && user.userWidgets.length) {
          flag = true;
          widgetAndGenericPagesSet(
            checkDashboardWidgets(JSON.parse(user.userWidgets))
          );
        }
      } else if (
        appData.user &&
        appData.user.userWidgets &&
        appData.user.userWidgets.length
      ) {
        flag = true;
        widgetAndGenericPagesSet(
          checkDashboardWidgets(JSON.parse(appData.user.userWidgets))
        );
      }
      if (!flag) {
        // Set default if no specifications present in user schema
        const sentimentWidgetConfig: WidgetId[] = DEFAULT_SENTIMENT_WIDGETS;
        const ratingWidgetConfig: WidgetId[] = DEFAULT_RATING_WIDGETS;
        const dashboardWidgetConfig: WidgetId[] =
          DEFAULT_DASHBOARD_WIDGETS(appData);
        const analyticsWidgetConfig: WidgetId[] =
          DEFAULT_ANALYTICS_WIDGET(appData);
        console.log(dashboardWidgetConfig);

        let widgets: {
          dashboard: WidgetPage;
          analytics: WidgetPage;
          sentiment: WidgetPage;
          rating: WidgetPage;
        } = {
          sentiment: {
            name: "Sentiments",
            widgets: sentimentWidgetConfig,
          },
          rating: {
            name: "Star ratings",
            widgets: ratingWidgetConfig,
          },
          dashboard: {
            name: "Feedback dashboard",
            widgets: dashboardWidgetConfig,
          },
          analytics: {
            name: "Analytics",
            widgets: analyticsWidgetConfig,
          },
        };

        widgetAndGenericPagesSet(widgets);
      }
    };
    // Setting in the store initially
    // In this place, first check if the user has any default setting
    if (
      appData.store &&
      appData.store.length !== 0 &&
      appData.widgetLayout &&
      appData.widgetLayout.dashboard &&
      appData.widgetLayout.dashboard.widgets &&
      appData.widgetLayout.dashboard.widgets.length === 0
    ) {
      // First check if the user schema contains userWidgets
      checkUserSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.store, dispatch]);

  // Use effect to handle data fetch when custom dates are selected
  useEffect(() => {
    if (
      appData.filter &&
      appData.filter.value === "custom" &&
      appData.filter.dates &&
      appData.filter.dates.length === 2 &&
      appData.app &&
      appData.app.length
    ) {
      appData.app.forEach(async (app: DATA_BY_STORE) => {
        let count = 0;

        const getGraphDataWithRetry = async (): Promise<any> => {
          try {
            const data = await getGraphDetails(app.data.appID || "");

            if (!data.getApp.url) {
              throw new Error("Data in the database is empty.");
            }

            const curr_data = JSON.parse(data.getApp.url);
            const dateKey = Object.keys(curr_data);
            const formatDate = (date: any) => date.toISOString().split("T")[0];
            const today = new Date();
            const formattedToday = formatDate(today);

            if (!dateKey.includes(formattedToday)) {
              throw new Error("The database contains old data");
            } else {
              const actual_data = curr_data[formattedToday];
              return actual_data;
            }
          } catch (e) {
            if (count < 5) {
              await new Promise((resolve) => setTimeout(resolve, 5000));
              count++;
              return getGraphDataWithRetry();
            } else {
              throw new Error("Max retry count reached");
            }
          }
        };

        try {
          let data = await fetchFeedbackGraph(app.data.appID, {
            date_1:
              new Date(appData.filter.dates[0]).toISOString().slice(0, -5) +
              "Z",
            date_2:
              new Date(appData.filter.dates[1]).toISOString().slice(0, -5) +
              "Z",
          });
          if (data && data.body) {
            let response = {};
            try {
              response = JSON.parse(data.body);
            } catch (e) {
              response = data.body;
            }
            data = response;
          }
          const foundIndex = graphData.feedback.findIndex(
            (item: DATA_BY_STORE) => item.store === app.store
          );
          if (foundIndex !== -1) {
            let feedback = [...graphData.feedback];
            feedback[foundIndex] = {
              ...feedback[foundIndex],
              data: {
                ...feedback[foundIndex].data,
                custom: data.custom,
              },
            };
            dispatch(GraphActions.setFeedbackGraphData(feedback));
          }
        } catch (e) {
          let data;
          try {
            data = await getGraphDataWithRetry();
          } catch (error) {
            throw new Error("No data existing");
          }

          if (data && data.body) {
            let response = {};
            try {
              response = JSON.parse(data.body);
            } catch (e) {
              response = data.body;
            }
            data = response;
          }
          if (
            data &&
            data.custom &&
            data.custom.data &&
            data.custom.data.suggestions &&
            data.custom.data.suggestions.filter((d: number) => d !== 0)
              .length === 0
          ) {
            throw new Error("No data existing");
          } else if (data) {
            const foundIndex = graphData.feedback.findIndex(
              (item: DATA_BY_STORE) => item.store === app.store
            );
            if (foundIndex !== -1) {
              let feedback = [...graphData.feedback];
              feedback[foundIndex] = {
                ...feedback[foundIndex],
                data: {
                  ...feedback[foundIndex].data,
                  custom: data.custom,
                },
              };
              dispatch(GraphActions.setFeedbackGraphData(feedback));
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.filter]);

  const handlePageAdd = () => {
    let newName = `New dashboard`;
    let routeName = getRouteName(newName);

    let userWidgets = {
      ...appData.widgetLayout,
      [routeName]: {
        name: "",
        widgets: [],
      },
    };
    updateLayoutAndUserWidgets(userWidgets, dispatch);
    navigate(`${basePath}/insights/${routeName}`);
  };

  const logoutHandler = () => {
    console.log("Removing data");
    dispatch(AppActions.setInit());
    dispatch(GraphActions.setInit());
    navigate("/login");
  };

  const menuItems: MenuItem[] = [
    {
      label: "Insights",
      route: `${basePath}/insights/feedback`,
      regex: "insights",
      icon: InsightsIcon,
      children: [
        {
          label: "Feedback dashboard",
          route: `${basePath}/insights/feedback`,
          regex: "feedback",
        },
        // Hide the Star Ratings and Sentiments page when the app is provided by any other source other than
        // Play Store or App Store. When installed from play store, number of installs in greater than or equal
        // to 0. When installed from app store, installs is -1. When the data is from any other source,
        // installs is undefined.
        // TODO: Use proper attribute in App Schema to define a data source
        ...(appData &&
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.apple) ||
          appData.store.includes(STORE.google) ||
          appData.store.includes(STORE.csv) ||
          appData.store.includes(STORE.amazon) ||
          appData.store.includes(STORE.slack))
          ? [
              {
                label: "Sentiments",
                route: `${basePath}/insights/sentiments`,
                regex: "sentiments",
              },
              {
                label: "Star ratings",
                route: `${basePath}/insights/ratings`,
                regex: "ratings",
              },
            ]
          : []),
        ...getGenericDashboardPaths(appData.widgetLayout),
        ...(getEditorUserRoleAccess(appData.userRole)
          ? [
              {
                label: "+ Add dashboard",
                // route: `${basePath}/insights/dashboard${genericPages.length + 1}`,
                regex: "add dashboard",
                // icon: AddWidgetIcon,
                function: () => handlePageAdd(),
              },
            ]
          : []),
      ],
    },
    {
      label: "Analytics",
      route: `${basePath}/analytics`,
      regex: "analytics",
      icon: AnalyticsIcon,
    },
    {
      label: "Settings",
      route: `${basePath}/settings`,
      regex: "settings",
      icon: SettingsIcon,
    },
    {
      label: "Logout",
      function: () => logout(logoutHandler),
      regex: "logout",
      icon: LogoutIcon,
    },
  ];

  const closeWidgetSettingsHandler = (value?: boolean) => {
    if (!value) {
      // Close the widget
      dispatch(AppActions.setWidgetSettings(undefined));
    }
  };

  return (
    <Fragment>
      <FlexBox
        sx={{
          height: "100%",
          display: "flex",
          ...(width > 800
            ? { flexDirection: "row" }
            : { flexDirection: "column" }),

          background: "#FFFFFF",
        }}
      >
        <FlexBox
          sx={{
            boxShadow: "0px 4px 23px rgba(130, 134, 140, 0.12)",
            position: "relative",
            ...(width > 800 ? { width: "260px" } : { width: "100%" }),
          }}
        >
          <FlexBox
            style={{
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: "1rem",
              top: "0.3rem",
            }}
          >
            {ADMIN_IDS &&
            ADMIN_IDS.length &&
            ADMIN_IDS.includes(localStorage.getItem("username")) ? (
              <FlexBox onClick={() => navigate("/dashboard/rargus-admin")}>
                <img
                  src={RargusIcon}
                  alt="Rargus Icon"
                  width="38"
                  height="38"
                  style={{
                    marginRight: "0.5rem",
                  }}
                />
              </FlexBox>
            ) : (
              <img
                src={RargusIcon}
                alt="Rargus Icon"
                width="38"
                height="38"
                style={{
                  marginRight: "0.5rem",
                }}
              />
            )}
            <img
              src={RargusTextIcon}
              alt="Rargus Icon"
              width="68"
              height="70"
            />
          </FlexBox>
          <FlexBox
            sx={{
              ...(width > 800
                ? {
                    flexDirection: "column",
                    margin: "6rem 0 0 1.5rem",
                    maxHeight: "80vh",
                    width: "100%",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.4rem",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#A3A6AB",
                      borderRadius: "5px",
                    },
                  }
                : {
                    flexDirection: "row",
                    marginLeft: "11rem",
                    width: "60%",
                    overflow: "auto",
                    padding: "0 1rem 0 1rem",
                    "&::-webkit-scrollbar": {
                      width: "0.4rem",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#A3A6AB",
                      borderRadius: "5px",
                    },
                  }),
            }}
          >
            {menuItems.map((item: MenuItem, index: number) => {
              return (
                <FlexBox
                  style={{
                    cursor: "pointer",
                    ...(width > 800
                      ? {
                          flexDirection: "column",
                          ...(item.children
                            ? {
                                height: `${
                                  (item.children.length + 1) * 3.5
                                }rem`,

                                // Check requirement in future. Need to fix showing all of the items within this item
                                // maxHeight: "20rem",
                                // overflow: "auto",
                                // // @ts-ignore
                                // "&::-webkit-scrollbar": {
                                //   width: "0.4rem",
                                // },
                                // "&::-webkit-scrollbar-track": {
                                //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                                //   webkitBoxShadow:
                                //     "inset 0 0 6px rgba(0,0,0,0.00)",
                                // },
                                // "&::-webkit-scrollbar-thumb": {
                                //   backgroundColor: "#A3A6AB",
                                //   borderRadius: "5px",
                                // },
                              }
                            : { height: "3.5rem", minHeight: "3.5rem" }),
                        }
                      : {
                          flexDirection: "row",
                          // width: `${
                          //   item.children ? (item.children.length + 1) * 5 : 5
                          // }rem`,
                          marginRight: "0.8rem",
                        }),
                    // marginBottom: "2rem",
                    justifyContent: "center",
                  }}
                  key={`dashboard-layout-menu-item-${index}-${item.label}`}
                >
                  <FlexBox
                    onClick={() =>
                      item.route
                        ? navigate(item.route)
                        : item.function && item.function()
                    }
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {item.icon ? (
                      <img
                        src={item.icon}
                        alt={`${item.label} icon`}
                        width="16"
                        height="16"
                        style={{ marginRight: "10px" }}
                      ></img>
                    ) : (
                      <FlexBox sx={{ marginLeft: "1.5rem" }}></FlexBox>
                    )}
                    <FlexBox
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        ...(item.regex && location.pathname.includes(item.regex)
                          ? { color: "#3579E3" }
                          : {}),
                      }}
                    >
                      {item.label.length > 19
                        ? `${item.label.slice(0, 19)}...`
                        : item.label}
                    </FlexBox>
                  </FlexBox>

                  {item.children &&
                    item.children.map((item, index) => (
                      <FlexBox
                        sx={{
                          flexDirection: "column",
                          ...(index === 0 ? { marginTop: "1rem" } : {}),
                        }}
                        key={`dashboard-layout-menu-item-child-${index}-${item.label}`}
                        onClick={() =>
                          item.route
                            ? navigate(item.route)
                            : item.function && item.function()
                        }
                      >
                        <FlexBox
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <FlexBox
                            sx={{
                              width: "2rem",
                              alignItems: "center",
                              // marginTop: "2rem",
                              justifyContent: "center",
                              height: "3.5rem",
                            }}
                          >
                            {item.regex &&
                              location.pathname.includes(item.regex) && (
                                <FlexBox
                                  sx={{
                                    borderRadius: "50%",
                                    background: "#3579E3",
                                    width: "8px",
                                    height: "8px",
                                  }}
                                ></FlexBox>
                              )}
                          </FlexBox>
                          {item.icon ? (
                            <img
                              src={item.icon}
                              alt={`${item.label} icon`}
                              width="16"
                              height="16"
                              style={{ marginRight: "10px" }}
                            ></img>
                          ) : (
                            <></>
                          )}
                          <FlexBox
                            style={{
                              cursor: "pointer",
                              // marginTop: "2rem",
                              ...(item.regex &&
                              location.pathname.includes(item.regex)
                                ? { color: "#3579E3" }
                                : { color: "#82868C" }),
                            }}
                          >
                            {item.label.length > 19
                              ? `${item.label.slice(0, 19)}...`
                              : item.label}
                          </FlexBox>
                        </FlexBox>
                      </FlexBox>
                    ))}
                </FlexBox>
              );
            })}
          </FlexBox>
        </FlexBox>
        {appData &&
        appData.app &&
        appData.app.length !== 0 &&
        appData.app[0].data &&
        appData.app[0].data.appID ? (
          <>
            {children}
            <TooltipComponent title={"Feedback"}>
              <div
                style={{
                  position: "absolute",
                  bottom: "1.5rem",
                  right: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowFeedback(true);
                }}
              >
                <img
                  src={FeedbackIcon}
                  width={48}
                  height={48}
                  alt="Feedback..."
                />
              </div>
            </TooltipComponent>
          </>
        ) : (
          <LoadingWrapper bg="#F5F5F5" />
        )}
        {appData && appData.widgetSettings ? (
          <WidgetSettingsModal
            onClose={() => {
              closeWidgetSettingsHandler();
            }}
            title={"Widget settings"}
            show={
              appData.widgetSettings && appData.widgetSettings.id ? true : false
            }
            setShow={closeWidgetSettingsHandler}
          ></WidgetSettingsModal>
        ) : (
          <></>
        )}
        {showFeedback ? (
          <Feedback
            title={"Feedback"}
            onClose={() => setShowFeedback(false)}
            show={showFeedback}
            setShow={setShowFeedback}
          />
        ) : (
          <></>
        )}
      </FlexBox>
    </Fragment>
  );
};

export default DashboardLayoutWrapper;
