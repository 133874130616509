import { Box } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import IdleImportIcon from "../shared/icons/idle-import.svg";

// TODO: Below
// ----------------------------------
// LAMBDA CREATION
// ----------------------------------
// -- From the mapping attributes, write better code to create the mapping object on UI
// -- Write a lambda that takes the mapping object, starts creating batches of 100, and triggers new lambda
// -- In the new lambda, first the 100 ideas based on the id senis fetched, and is processed based on the mapping object to get the desired final object
// -- This is uploaded to a bucket from where the new process based on VMware starts
// -- This process needs to stream-line based on new buckets and copying the lambda processes, but with the new code
// -- End result will be placed in the same schema, but mapping is determined based on the mapping object, which also needs to be stored.
// -- Other user entered data also needs to be stored in the app schema under some field.
// ----------------------------------

const StartImport = () => {
  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox flexDirection="column" justifyContent="center">
        <Box
          sx={{
            mt: 2,
            ml: 1,
            mb: 2,
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#0B0E1E",
            height: "fit-content",
          }}
        >
          Importing the data will take a couple of minutes. We will send you an
          email when we’re done.
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <img
            src={IdleImportIcon}
            alt="Idle import"
            width="364"
            height="191"
          />
        </Box>
      </FlexBox>
    </FlexBox>
  );
};

export default StartImport;
