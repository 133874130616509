import { Box, Button, FormHelperText } from "@mui/material";
import { useState, useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr } from "./AddAmazon";
import FlexBox from "../shared/wrappers/FlexBox";
import {
  ADMIN_MANAGEMENT_TYPE,
  // checkIfUserIsAdminOrPremium,
  getWithExpiry,
  SETTINGS_PAGE,
} from "../shared/constants";
import { useLocation, useNavigate } from "react-router";
import {
  createOrEditOrganization,
  startAmazonReviewsImport,
} from "../../services/api/Apps.api";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";

const Config = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const appData: any = useSelector((state: RootState) => state.app);

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    name: string;
    url: string;
    number: number;
  } = { name: "", url: "", number: 1000, ...formData };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("App name is required"),
    url: Yup.string().required("Product id is required"),
    number: Yup.number().required(
      "Please provide the number of reviews to import"
    ),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        if (values.url.length !== 0 && values.name.length !== 0) {
          const data = {
            ...formData,
            ...values,
          };

          try {
            const res = await startAmazonReviewsImport({
              user: localStorage.getItem("username") || "",
              accessToken: getWithExpiry("token"),
              baseUrl: `https://www.amazon.com/product-reviews/${values.url}`,
              appName: values.name,
              pages: Math.ceil(values.number / 10),
            });

            if (res && res.body) {
              const response = JSON.parse(res.body);
              if (response && response.appID) {
                // Add previous appIDs as well if exists
                let appIDs = [];
                if (appData.user.userAppId) {
                  try {
                    appIDs = JSON.parse(appData.user.userAppId);
                  } catch (e) {
                    console.log(e);
                    appIDs = appData.user.userAppId;
                  }
                }
                if (!Array.isArray(appIDs)) {
                  appIDs = [appIDs];
                }

                appIDs = appIDs.concat([response.appID]);

                localStorage.setItem("appId", JSON.stringify(appIDs));

                try {
                  await createOrEditOrganization({
                    admin: localStorage.getItem("username") || "",
                    appID: JSON.stringify(appIDs),
                    organization: appData?.userRole?.organization || "",
                    type: ADMIN_MANAGEMENT_TYPE.edit,
                  });
                } catch (e) {
                  console.log(e);
                }

                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
              }
            }
          } catch (e) {
            setError("There was some error");
          }
        } else {
          setError("Error");
        }
        setLoading(false);
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Provide the details below
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"App name"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"name"}
              type={"name"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <Small>
              This is the name that'll be assigned to the imported app
            </Small>
          </FullTextFieldWrapper>

          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Product ID"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"url"}
              type={"url"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.url || ""}
              error={Boolean(touched.url && errors.url)}
              helperText={touched.url && errors.url}
            />
            <Small>
              After you open your product on Amazon, find your product id here:{" "}
              <i>
                https://www.amazon.com/Droyek-Candle-Warmer-Electric-Candles/dp/
                <b>B0C7ML25K7</b>/ref=sr_1_1
              </i>
            </Small>
          </FullTextFieldWrapper>

          {/* <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Number of reviews"}
            </Paragraph>
            <LightTextField
              fullWidth
              disabled={checkIfUserIsAdminOrPremium(appData.user)}
              name={"number"}
              type={"number"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.number || ""}
              error={Boolean(touched.number && errors.number)}
              helperText={touched.number && errors.number}
            />
            <Small>
              If you wish to import more than 1000 reviews, please upgrade to
              premium.
            </Small>
          </FullTextFieldWrapper> */}

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Importing reviews"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Start import"}
              </Button>
            )}
          </Box>
          {location &&
          location.pathname &&
          !location.pathname.includes(SETTINGS_PAGE) ? (
            <Box
              sx={{
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Small
                color="primary.main"
                sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
                onClick={() => navigate("/dashboard/data-source")}
              >
                Back
              </Small>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Config;
