import { styled, TextareaAutosize, TextareaAutosizeProps } from "@mui/material";

const StyledTextareaAutosize = styled(TextareaAutosize)<TextareaAutosizeProps>(
  ({ theme }) => ({
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: "#E5EAF2",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E5EAF2",
    },
  })
);

const LightTextArea = (props: TextareaAutosizeProps) => {
  return <StyledTextareaAutosize {...props} />;
};

export default LightTextArea;
