import { Box, Button } from "@mui/material";
import { Small } from "../shared/wrappers/Typography";
import FlexBox from "../shared/wrappers/FlexBox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DATASOURCE_PAGE,
  SETTINGS_PAGE,
  setWithExpiry,
} from "../shared/constants";
import { FormContext, FormContextAttr } from "./AddSlack";
import { useContext, useState } from "react";
import { FormattedCheckbox } from "../shared/wrappers/FilterPopper";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";

const Basic = () => {
  const navigate = useNavigate();
  const uLocation = useLocation();
  const { formData, setFormData } = useContext(FormContext) as FormContextAttr;

  const [loading, setLoading] = useState(false);

  const initialValues: {
    private: boolean;
    direct: boolean;
    mpim: boolean;
  } = { private: false, direct: false, mpim: false, ...formData };

  const validationSchema = Yup.object().shape({
    private: Yup.boolean().required(
      "Channel scope acknowledgement is required"
    ),
    direct: Yup.boolean().required("Channel scope acknowledgement is required"),
    mpim: Yup.boolean().required("Channel scope acknowledgement is required"),
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading(true);
      try {
        const data = { ...formData, ...values };
        setFormData(data);

        // Determine user scope
        let userScope = process.env.REACT_APP_SLACK_USER_SCOPES_GENERAL || "";
        if (values.direct) {
          userScope += process.env.REACT_APP_SLACK_USER_SCOPES_DIRECT;
        }
        if (values.private) {
          userScope += process.env.REACT_APP_SLACK_USER_SCOPES_PRIVATE;
        }
        if (values.mpim) {
          userScope += process.env.REACT_APP_SLACK_USER_SCOPES_MPIM;
        }

        let url = `https://slack.com/oauth/v2/authorize?scope=&user_scope=${userScope}&redirect_uri=${
          process.env.REACT_APP_SLACK_REDIRECT_URI
        }&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&state=${
          uLocation && uLocation.pathname?.includes(SETTINGS_PAGE)
            ? SETTINGS_PAGE
            : DATASOURCE_PAGE
        }-page`;

        setWithExpiry(
          "slack_scope",
          JSON.stringify({
            private: values.private,
            direct: values.direct,
            mpim: values.mpim,
          })
        );

        window.location.href = url;
      } catch (e: any) {
        console.log(e.message);
      }

      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "85%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "10px",
        }}
      >
        Generate your user token
      </FlexBox>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "10px",
        }}
      >
        Please provide your access preferences* and continue to getting your
        token.
      </Box>
      {/* <HelpTextStep steps={SlackTokenHelper} /> */}
      <form noValidate onSubmit={handleSubmit}>
        <FullTextFieldWrapper key={`checkbox-product-name-private`}>
          <FlexBox
            sx={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              paddingLeft: "0.5rem",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  color: "#82868C",
                }}
              >
                {"Do you wish to import messages from Private Channels"}
              </FlexBox>
            </FlexBox>
            <FlexBox>
              <FormattedCheckbox
                checked={values.private}
                name={"private"}
                id={"private"}
                onChange={handleChange}
              />
            </FlexBox>
          </FlexBox>
        </FullTextFieldWrapper>
        <FullTextFieldWrapper key={`checkbox-product-name-direct`}>
          <FlexBox
            sx={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              paddingLeft: "0.5rem",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  color: "#82868C",
                }}
              >
                {
                  "Do you wish to import messages from your direct messages (im)"
                }
              </FlexBox>
            </FlexBox>
            <FlexBox>
              <FormattedCheckbox
                checked={values.direct}
                name={"direct"}
                id={"direct"}
                onChange={handleChange}
              />
            </FlexBox>
          </FlexBox>
        </FullTextFieldWrapper>
        <FullTextFieldWrapper key={`checkbox-product-name-direct-group`}>
          <FlexBox
            sx={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              paddingLeft: "0.5rem",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "center",
              }}
            >
              <FlexBox
                sx={{
                  color: "#82868C",
                }}
              >
                {
                  "Do you wish to import messages from your direct group messages (mpim)"
                }
              </FlexBox>
            </FlexBox>
            <FlexBox>
              <FormattedCheckbox
                checked={values.mpim}
                name={"mpim"}
                id={"mpim"}
                onChange={handleChange}
              />
            </FlexBox>
          </FlexBox>
        </FullTextFieldWrapper>
        <Small>
          * By default, Rargus will import data from only public channels within
          your workspace.
        </Small>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          {uLocation &&
          uLocation.pathname &&
          !uLocation.pathname.includes(SETTINGS_PAGE) ? (
            <Box
              sx={{
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Small
                color="primary.main"
                sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard/data-source");
                }}
              >
                Back
              </Small>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Basic;
