import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";
import {
  SocialIconButton,
  TextFieldWrapper,
} from "../shared/wrappers/StyledComponents";
import FlexBox from "../shared/wrappers/FlexBox";
import LightTextField from "../shared/wrappers/LightTextField";
import { H3, Paragraph, Small } from "../shared/wrappers/Typography";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import GoogleIcon from "../shared/icons/google.svg";
import FacebookIcon from "../shared/icons/facebook.svg";
import { authenticate } from "../../services/context/Account";
import AuthLayoutWrapper from "./AuthLayoutWrapper";
import { getUserDetails } from "../../services/api/Graphql.api";
import { useDispatch } from "react-redux";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  getUserAppIDs,
  SETTINGS_PAGE,
  setUserDataAndRole,
} from "../shared/constants";

const Login: FC = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    email: string;
    password: string;
    submit: null;
    remember: boolean;
  } = {
    email: "",
    password: "",
    submit: null,
    remember: false,
  };

  const navigate = useNavigate();
  const location = useLocation();

  const providerRedirectPath = "/provider-redirect";
  const loginRedirectPath = "/dashboard/home";
  const redirectHost = process.env.REACT_APP_REDIRECT_HOST;
  const clinetId = process.env.REACT_APP_RARGUS_CLIENT_ID;
  const authScope = process.env.REACT_APP_RARGUS_CLIENT_AUTH_SCOPE;
  const appName = process.env.REACT_APP_APP_NAME;
  const region = process.env.REACT_APP_APP_REGION;

  const loginURL = `https://${appName}.auth.${region}.amazoncognito.com/`;

  // Code to take the code from Provider login and get access token for the user

  // const location = useLocation();
  // const code = new URLSearchParams(location.search).get("code");
  // const callbcakUrl = `${redirectHost}${loginRedirectPath}`;
  // const clientSecret = process.env.REACT_APP_RARGUS_CLIENT_SECRET;

  // useEffect(() => {
  //   if (code && code.length) {
  //     // Provider Login Flow
  //     const func = async () => {
  //       const secretHash = btoa(utf8.encode(`${clinetId}:${clientSecret}`));
  //       const config = {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: `Basic ${secretHash}`,
  //         },
  //       };

  //       const res: any = await axios.post(
  //         `${loginURL}oauth2/token?grant_type=authorization_code&client_id=${clinetId}&code=${code}&redirect_uri=${callbcakUrl}`,
  //         {},
  //         config
  //       );
  //       if (res && res.data && res.data.access_token) {
  //         // CONTAINS THREE TYPES OF TOKEN
  //         // FOR API, ID TOKEN
  //         localStorage.setItem("token", res.data.id_token);
  //         localStorage.setItem("refreshToken", res.data.refresh_token);
  //         localStorage.setItem("username", "");
  //         navigate(loginRedirectPath);
  //       }
  //     };

  //     func();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [code, navigate]);

  const providerLogin = (providerName: string) => {
    window.location.href = `${loginURL}oauth2/authorize?identity_provider=${providerName}&redirect_uri=${redirectHost}${providerRedirectPath}&response_type=CODE&client_id=${clinetId}&scope=${authScope}`;
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase and One Lowercase"
      ),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        setLoading(true);

        // Local Login Flow
        authenticate &&
          authenticate(values.email, values.password, values.remember)
            .then(async (data: any) => {
              // After the user is verified, Check if the user already have an App added by checking the user db
              // If yes, then redirect to some other page rather than the App Page
              const res = await getUserDetails(values.email);
              if (res && res.getUser) {
                setUserDataAndRole(dispatch, res.getUser);

                localStorage.setItem("username", values.email);
                // Changing app vavigate when user has an app attached
                if (res && res.getUser && res.getUser._version) {
                  localStorage.setItem("userVersion", res.getUser._version);
                }

                const appId = await getUserAppIDs(res.getUser);

                if (appId && appId.length !== 0 && appId !== "[]") {
                  localStorage.setItem("appId", appId);

                  if (
                    location &&
                    location.pathname &&
                    location.pathname !== "/dashboard" &&
                    location.pathname !== "/dashboard/" &&
                    location.pathname !== "/login" &&
                    (location.pathname.includes("insights") ||
                      location.pathname.includes("analytics") ||
                      location.pathname.includes(SETTINGS_PAGE))
                  ) {
                    return navigate(location.pathname);
                  }
                  return navigate("/dashboard/insights/feedback");
                }
              }

              // If reaching this point, user doesn't have an app added
              if (
                location &&
                location.pathname &&
                location.pathname !== "/dashboard" &&
                location.pathname !== "/dashboard/" &&
                location.pathname !== "/login" &&
                !location.pathname.includes("insights") &&
                !location.pathname.includes("analytics") &&
                !location.pathname.includes(SETTINGS_PAGE) // TODO: Add constraints here to not redirect to pages with data
              ) {
                return navigate(location.pathname);
              }
              navigate(loginRedirectPath);

              setLoading(false);
            })
            .catch((err: any) => {
              setLoading(false);
              console.log(err);
              setError(
                "Email and Password that you entered do not match any account. Create an account now"
              );
            });
      },
    });

  return (
    <AuthLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
        }}
      >
        <Card
          sx={{
            padding: 4,
            border: "none",
            overflowY: "auto",
            ...(width < 1100 ? {} : { maxWidth: 600 }),
          }}
        >
          <FlexBox
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mb={5}
          >
            <FlexBox
              style={{
                fontSize: "68px",
                fontWeight: 700,
                lineHeight: "82px",
                color: "#3579E3",
                fontFamily: "Barlow",
              }}
            >
              {"Log in"}
            </FlexBox>
          </FlexBox>

          <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
            <SocialIconButton
              onClick={() => providerLogin("Google")}
              startIcon={<img src={GoogleIcon} alt="Google Icon" />}
            >
              Sign in with Google
            </SocialIconButton>
            <SocialIconButton
              onClick={() => providerLogin("Facebook")}
              startIcon={<img src={FacebookIcon} alt="Facebook Icon" />}
            >
              Sign in with Facebook
            </SocialIconButton>

            <Divider sx={{ my: 3, width: "100%", alignItems: "flex-start" }}>
              <H3 color="text.disabled" px={1}>
                Or
              </H3>
            </Divider>

            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <FlexBox justifyContent="space-between" flexWrap="wrap">
                <TextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1}>
                    {"Email"}
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name={"email"}
                    type={"email"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email || ""}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1}>
                    {"Password"}
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name={"password"}
                    type={"password"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password || ""}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </TextFieldWrapper>
              </FlexBox>

              <FlexBox
                mt={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControlLabel
                  control={
                    <Switch
                      name="remember"
                      checked={values.remember}
                      onChange={handleChange}
                    />
                  }
                  label="Remember Me"
                  sx={{ "& .MuiTypography-root": { fontWeight: 600 } }}
                />
              </FlexBox>

              {error && (
                <FormHelperText
                  error
                  sx={{
                    mt: 2,
                    fontSize: 13,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {error}
                </FormHelperText>
              )}

              <Box sx={{ mt: 4 }}>
                {loading ? (
                  <LoadingButton loading fullWidth variant="contained">
                    {"Sign In"}
                  </LoadingButton>
                ) : (
                  <Button fullWidth type="submit" variant="contained">
                    {"Sign In"}
                  </Button>
                )}
              </Box>
            </form>

            <Small
              margin="auto"
              mt={3}
              color="text.disabled"
              lineHeight={"27px"}
            >
              <Link to="/register">
                <Small color="primary.main" fontSize={"14px"} fontWeight="500">
                  Create an account
                </Small>
              </Link>
              <Small
                color="primary.main"
                margin={"0.5rem 1rem 0 1rem"}
                fontSize={"14px"}
                fontWeight="500"
              >
                {"*"}
              </Small>
              <Link to="/forgot-password">
                <Small color="primary.main" fontSize={"14px"} fontWeight="500">
                  Forgot password?
                </Small>
              </Link>
            </Small>
          </FlexBox>
        </Card>
      </FlexBox>
    </AuthLayoutWrapper>
  );
};

export default Login;
