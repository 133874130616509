import { Box, Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { dbCleanup } from "../../services/api/Apps.api";
import {
  deleteUserAppDetails,
  updateAppImportStatus,
} from "../../services/api/Graphql.api";
import { removeUserTokens } from "../../services/context/Account";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { GraphActions } from "../../services/store/GraphStore/GraphSlice";
import { STATUS_FLAG } from "../shared/constants";
import FlexBox from "../shared/wrappers/FlexBox";

const DevPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteApp, setDeleteApp] = useState(false);

  const user = localStorage.getItem("username");

  const removeApp = async () => {
    try {
      await deleteUserAppDetails(user || "");
      dispatch(AppActions.setInit());
      dispatch(GraphActions.setInit());
      dispatch(AppActions.setUserData({ email: user, appUserId: null }));
      localStorage.removeItem("appId");
      localStorage.removeItem("store");
      navigate("/dashboard/home");
    } catch (e) {
      removeUserTokens();
    }
  };

  const removeAppFromDB = async () => {
    try {
      let appIDs = JSON.parse(localStorage.getItem("appId") || "");
      if (!Array.isArray(appIDs)) {
        appIDs = [appIDs];
      }

      for (let i = 0; i < appIDs.length; i++) {
        await updateAppImportStatus(appIDs[i], {
          status: STATUS_FLAG.INIT,
          complementStatus: STATUS_FLAG.INIT,
          complaintStatus: STATUS_FLAG.INIT,
          suggestionStatus: STATUS_FLAG.INIT,
          sentimentStatus: STATUS_FLAG.INIT,
          reviewsFetched: STATUS_FLAG.INIT,
        });
        await dbCleanup(appIDs[i], "DELETE");
        localStorage.removeItem("appId");
        localStorage.removeItem("store");
      }

      navigate("/dashboard/home");
    } catch (e) {
      removeUserTokens();
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          mt: 4,
          mb: 3,
          ml: 1,
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
        }}
      >
        Use the buttons below to <br /> <br />
        1. Remove all apps from your account <br />
        2. Remove all your added apps from the database
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button fullWidth type="button" variant="contained" onClick={removeApp}>
          Remove app from User
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        {!deleteApp ? (
          <Button
            fullWidth
            type="button"
            variant="contained"
            onClick={() => setDeleteApp(true)}
          >
            Remove app from database
          </Button>
        ) : (
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              sx={{
                width: "80%",
                alignItems: "center",
              }}
            >
              Are you sure you want to delete all apps from db?
            </FlexBox>
            <Box
              sx={{
                width: "100%",
                textAlign: "end",
              }}
            >
              <Button
                type="button"
                variant="outlined"
                sx={{
                  border: "none",
                  "&:hover": { border: "none" },
                }}
                onClick={() => setDeleteApp(false)}
              >
                No
              </Button>
              <Button variant="contained" onClick={removeAppFromDB}>
                Yes
              </Button>
            </Box>
          </FlexBox>
        )}
      </Box>
    </Fragment>
  );
};

export default DevPage;
