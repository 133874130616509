import { AxiosResponse } from "axios";
import { Product } from "../../components/Aha/SelectProducts";
import {
  getWithExpiry,
  keywordOrSplit,
} from "../../components/shared/constants";
import api from "./api";

let token = localStorage.getItem("token");
let accessToken: string = "";
if (token) {
  try {
    accessToken = JSON.parse(token).value;
  } catch (e) {
    // Backward compatibility
    accessToken = token;
  }
}

export const getAppsList = () => api.get("").then(({ data }) => data);

export const searchPlayStoreApps = (searchQuery: string) =>
  api
    .post(`${"appSearchv3"}?query=${searchQuery}`, { query: searchQuery })
    .then(({ data }) => data);

export const addPlayStoreApp = (store: string, appData: any) =>
  api
    .post(
      `${"rargusImportPlaystoreReviews"}?store=${store}&appId=${
        appData.appId
      }&token=${accessToken}`,
      {
        ...appData,
        username: localStorage.getItem("username"),
        token: getWithExpiry("token"),
      }
    )
    .then(({ data }) => data);

export const addAppleStoreApp = (store: string, appData: any) =>
  api
    .post(
      `${"importAppstore"}?store=${store}&appId=${
        appData.appId
      }&token=${accessToken}`,
      {
        appData,
        username: localStorage.getItem("username"),
        token: getWithExpiry("token"),
      }
    )
    .then(({ data }) => data);

export const startGoogleImport = (
  appId: string,
  token: string,
  email: string
) =>
  api
    .post(
      `${"importGoogleReviews"}?appId=${appId}&token=${token}&email=${email}`,
      {
        continuation_token: false,
        appId,
        email,
        token,
      }
    )
    .then((data) => data);

export const startAppleImport = (
  appId: string,
  appName: string,
  releasedDate: string,
  token: string,
  email: string
) =>
  api
    .post(
      `${"importAppleReviews"}?appId=${appId}&appName=${appName}&releasedDate=${releasedDate}&token=${token}&email=${email}`,
      {
        appInstance: false,
        appId,
        appName,
        releasedDate,
        email,
        token,
      }
    )
    .then((data) => data);

export const dbCleanup = (appId: string, action: string, fetch?: string) =>
  api
    .get(
      `${"db-clean-up"}?appID=${appId}&action=${action}${
        action === "DELETE" ? "" : `&fetch=${fetch}`
      }`
    )
    .then((data) => data.data)
    .catch((e) => e);

export const fetchData = (appId: string, action: string, fetch?: string) =>
  api
    .get(
      `${
        fetch === "SENTIMENT"
          ? process.env.REACT_APP_API_FETCH_SENTIMENT_DATA
          : fetch === "COMPLAINT"
          ? process.env.REACT_APP_API_FETCH_COMPLAINT_DATA
          : fetch === "COMPLIMENT"
          ? "complimentFetch"
          : process.env.REACT_APP_API_FETCH_SUGGESTION_DATA
      }?appID=${appId}&action=${action}${
        action === "DELETE" ? "" : `&fetch=${fetch}`
      }`
    )
    .then((data) => data.data)
    .catch((e) => e);

export const importErrorHandler = (appObject: {
  appID: string;
  offset: number;
  size: number;
  limit: number;
  GPT: number;
  CLUSTERING: number;
  SENTIMENT: number;
}) => api.post(`${"importErrorHandler"}`, appObject).then((data) => data.data);

export const fetchFeedbackGraph = (
  appID: string,
  custom?: {
    date_1: string;
    date_2: string;
  }
) =>
  api
    .post(`${"getDashboardGraph"}`, {
      appID,
      ...(custom ? { custom } : {}),
    })
    .then((data) => data.data);

export const sendEmailToUser = (email: string[]) =>
  api
    .post(`${"sendeMail"}`, {
      email,
    })
    .then((data) => data.data);

export const startAhaDataImport = (subDomain: string, apiKey: string) =>
  api
    .post(`${"importAhaData"}`, {
      RARGUS_AHA_API_SUBDOMAIN: subDomain,
      RARGUS_AHA_API_KEY: apiKey,
    })
    .then((data) => data.data);

export const getAhaProducts = (subDomain: string, apiKey: string) =>
  api
    .post(`${"getAhaProductNames"}`, {
      RARGUS_AHA_API_SUBDOMAIN: subDomain,
      RARGUS_AHA_API_KEY: apiKey,
    })
    .then((data) => data.data)
    .catch((e) => {
      throw new Error("Some error occured");
    });

export const getAhaIdeasFromProducts = (
  subDomain: string,
  apiKey: string,
  products: Product[],
  mappingObject: any,
  appName: string
) =>
  api
    .post(`${"getIdeasByProduct"}`, {
      RARGUS_AHA_API_SUBDOMAIN: subDomain,
      RARGUS_AHA_API_KEY: apiKey,
      products,
      mappingObject,
      appName,
    })
    .then((data) => data.data);

export const getAhaMappingAttributes = (
  subDomain: string,
  apiKey: string,
  products: Product[]
) =>
  api
    .post(`${"getAhaMappingAttributes"}`, {
      RARGUS_AHA_API_SUBDOMAIN: subDomain,
      RARGUS_AHA_API_KEY: apiKey,
      products,
    })
    .then((data) => data.data);

export const createOrganizationUser = (createUser: {
  admin: string;
  appID?: string;
  organization: string;
  name: string;
  email: string;
  role?: string;
  type: string;
}) =>
  api.post(`${"createOrganizationUser"}`, createUser).then((data) => {
    if (data && data.data) {
      return data.data;
    }
    throw new Error(JSON.stringify(data));
  });

export const createOrEditOrganization = (createOrEditOrg: {
  admin: string;
  appID: string;
  organization: string;
  name?: string;
  email?: string;
  role?: string;
  type: string;
}) =>
  api.post(`${"createEditOrganization"}`, createOrEditOrg).then((data) => {
    if (data && data.data) {
      return data.data;
    }
    throw new Error(JSON.stringify(data));
  });

export const getTokenFromRefreshToken = (hashedKey: string) =>
  api
    .post(`${"refreshToken"}`, {
      refresh_token: hashedKey.split(keywordOrSplit)[0],
      secret_hash: hashedKey.split(keywordOrSplit)[1],
    })
    .then((data) => data.data);

export const getSlackChannels = (
  token: string,
  privateValue: boolean,
  direct: boolean,
  mpim: boolean
) =>
  api
    .post(`${"channel"}`, {
      token,
      private: privateValue,
      direct,
      mpim,
    })
    .then((data) => data.data);

export const startSlackDataImport = ({
  user,
  token,
  accessToken,
  channelId,
  includeBot,
  includeThread,
  name,
}: {
  user: string;
  token: string;
  accessToken: string;
  channelId: string[];
  includeBot: string;
  includeThread: string;
  name: string;
}) =>
  api
    .post(`${"importSlackData"}`, {
      user,
      token,
      accessToken,
      channelId,
      includeBot,
      includeThread,
      name,
    })
    .then((data) => data.data);

export const startAmazonReviewsImport = ({
  user,
  accessToken,
  baseUrl,
  appName,
  pages,
}: {
  user: string;
  accessToken: string;
  baseUrl: string;
  appName: string;
  pages: number;
}) =>
  api
    .post(`${"importAmazonData"}`, {
      user,
      accessToken,
      baseUrl,
      appName,
      pages,
    })
    .then((data) => data.data);

export const sendUserFeedback = ({
  feedback,
  rating,
  user,
}: {
  feedback: string;
  rating: string;
  user: string;
}) =>
  api
    .post(`${"feedback"}`, {
      feedback,
      rating,
      user,
    })
    .then((data) => data.data);

export const getGithubRepositories = (token: string) =>
  api
    .post(`${"getGitHubRepos"}`, {
      token,
    })
    .then((data) => data.data)
    .catch((e) => {
      throw new Error("Some error occured");
    });

export const startGithubIssuesImport = ({
  user,
  token,
  accessToken,
  appName,
  repositories,
  type,
}: {
  user: string;
  token: string;
  accessToken: string;
  appName: string;
  repositories: string;
  type: string;
}) =>
  api
    .post(`${"startGitHubIssuesImport"}`, {
      user,
      token,
      accessToken,
      appName,
      repositories,
      type,
    })
    .then((data) => data.data);

// use this function to create Presigned URLs to access the s3 objects without using accesskey
// it return the url in the data to accses th s3 object
export const getS3Object = (bucketParams: {
  Bucket: string | undefined;
  Key: string;
  expiration_time: number;
}) =>
  api
    .post("getS3Object", {
      bucketParams,
    })
    .then((response: AxiosResponse<any>) => response.data);

export const getDbData = (bucketParams: {
  appID: string;
  fetch: string;
  action: string;
}) =>
  api
    .post("getdbData", {
      bucketParams,
    })
    .then((response: AxiosResponse<any>) => response.data);

export const fetchAllApps = () =>
  api.post(`${"fetchAllApps"}`, {}).then((response) => response.data);
