import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import FlexBox from "../shared/wrappers/FlexBox";
import {
  Filter,
  FilterTextPopover,
  AddWidgetsFilterPopover,
} from "../shared/wrappers/FilterPopper";
import WidgetContainer from "./WidgetContainer";
import {
  filterOptions,
  FilterOption,
  updateLayoutAndUserWidgets,
  STORE,
  getUuid,
  getEditorUserRoleAccess,
} from "../shared/constants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import {
  AppActions,
  WidgetId,
} from "../../services/store/AppStore/AppStoreSlice";
import { getAppData } from "../../services/api/Graphql.api";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import AddWidgetIcon from "../shared/icons/add-widget.svg";
import { getWidgetList } from "../shared/widgetConstants";

const Feedback = () => {
  const dispatch = useDispatch();
  const appData: any = useSelector((store: RootState) => store.app);

  const [editDashboard, setEditDashboard] = useState<boolean>(false);

  const [widgets, setWidgets] = useState<Filter[]>([]);
  const [widgetArray, setWidgetArray] = useState<Filter[]>([]);

  // Widget array
  useEffect(() => {
    if (
      widgets &&
      widgets.length === 0 &&
      widgetArray &&
      widgetArray.length === 0 &&
      appData.widgetLayout &&
      appData.widgetLayout.dashboard &&
      appData.widgetLayout.dashboard.widgets &&
      appData.widgetLayout.dashboard.widgets.length !== 0
    ) {
      const distinctWidgetList = getWidgetList(appData, "id");
      setWidgetArray(
        distinctWidgetList.map((widget: WidgetId) => ({
          label: widget.label,
          value: widget.id.toLowerCase(),
        }))
      );

      setWidgets(
        appData.widgetLayout.dashboard.widgets.map((widget: WidgetId) => ({
          label: widget.label,
          value: widget.id.toLowerCase(),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.widgetLayout]);

  // useEffect to change widgets to be shown on page
  useEffect(() => {
    if (
      appData.widgetLayout.dashboard &&
      appData.widgetLayout.dashboard.widgets
    ) {
      // Selected widgets are changed
      setWidgets(
        appData.widgetLayout.dashboard.widgets.map((widget: WidgetId) => ({
          label: widget.label,
          value: widget.id.toLowerCase(),
        }))
      );
    }
  }, [appData.widgetLayout.dashboard, dispatch]);

  useEffect(() => {
    const func = async () => {
      if (
        !appData ||
        (appData && !appData.app) ||
        (appData &&
          appData.app &&
          appData.app.length !== 0 &&
          !appData.app[0].data) ||
        (appData &&
          appData.app &&
          appData.app.length !== 0 &&
          appData.app[0].data &&
          !appData.app[0].data.summary)
      ) {
        let appIDs = localStorage.getItem("appId") || "";
        if (Array.isArray(JSON.parse(appIDs))) {
          appIDs = JSON.parse(appIDs);
        }
        let apps: any[] = [];
        let store: string[] = [];

        for (let i = 0; i < appIDs.length; i++) {
          const res = await getAppData(appIDs[i]);
          if (
            appData.app &&
            (appData.app.length !== i + 1 ||
              (appData.app[i] && !appData.app[i].summary))
          ) {
            if (res.getApp.store) {
              store = [...store, res.getApp.store];
            } else {
              if (res.getApp.installs === null) {
                store = [...store, STORE.aha];
              }
              if (res.getApp.installs === "-1") {
                store = [...store, STORE.apple];
              } else if (res.getApp.installs) {
                store = [...store, STORE.google];
              }
            }
            apps = [
              ...apps,
              {
                store,
                data: { ...res.getApp },
              },
            ];
          }
        }

        dispatch(AppActions.setAppData(apps));
      }
    };

    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A3A6AB",
            borderRadius: "5px",
          },
        }}
      >
        <FlexBox
          sx={{
            width: "90%",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginBottom: "1.5rem",
              alignItems: "center",
            }}
          >
            <FlexBox>
              <FlexBox
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#3579E3",
                }}
              >
                Feedback dashboard
              </FlexBox>
              {getEditorUserRoleAccess(appData.userRole) ? (
                <FlexBox
                  sx={{
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#82868C",
                    margin: "0.2rem 0 0 1rem",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditDashboard(!editDashboard)}
                >
                  {editDashboard ? "Finish editing" : "Edit dashboard"}
                </FlexBox>
              ) : (
                <></>
              )}
            </FlexBox>

            <FlexBox>
              {editDashboard ? (
                <FlexBox
                  sx={{
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#82868C",
                    margin: "0.2rem 0 0 1rem",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  // onClick={() => setEditDashboard(!editDashboard)}
                >
                  <FlexBox
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {getEditorUserRoleAccess(appData.userRole) ? (
                      <AddWidgetsFilterPopover
                        selectedFilter={widgets}
                        setSelectedFilter={(filter: Filter[]) =>
                          // setWidgets(filter)
                          {
                            const userWid = {
                              ...appData.widgetLayout,
                              dashboard: {
                                ...appData.widgetLayout.dashboard,
                                widgets: [
                                  ...appData.widgetLayout.dashboard.widgets,
                                  ...filter.map((item: any) => ({
                                    id: item.value,
                                    widgetId: `${item.value}--${getUuid()}`,
                                    label: item.label,
                                  })),
                                ],
                              },
                            };
                            updateLayoutAndUserWidgets(userWid, dispatch);
                          }
                        }
                        filterOptions={widgetArray}
                        width={"16rem"}
                        label={<FlexBox>{"Keywords"}</FlexBox>}
                        internalSearch={true}
                        disableSelectAll={true}
                        enableMultiselect={true}
                        // disableCheckbox={true}
                        popOffset={10.2}
                      >
                        <FlexBox
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={AddWidgetIcon}
                            alt={"add"}
                            width={"16px"}
                            height={"16px"}
                            style={{
                              marginRight: "0.3rem",
                            }}
                          />
                          {"Add widget"}
                        </FlexBox>
                      </AddWidgetsFilterPopover>
                    ) : (
                      <></>
                    )}
                  </FlexBox>
                </FlexBox>
              ) : (
                <FilterTextPopover
                  filterOptions={filterOptions}
                  selectedFilter={appData.filter}
                  setSelectedFilter={(filter: FilterOption) =>
                    dispatch(AppActions.setGlobalFilter(filter))
                  }
                />
              )}
            </FlexBox>
          </FlexBox>
          <FlexBox
          // sx={{
          //   height: "100%",
          // }}
          >
            {appData.store && appData.store !== "" ? (
              <WidgetContainer
                widgets={
                  appData.widgetLayout &&
                  appData.widgetLayout.dashboard &&
                  appData.widgetLayout.dashboard.widgets &&
                  appData.widgetLayout.dashboard.widgets.length
                    ? appData.widgetLayout.dashboard.widgets
                    : []
                }
                otherData={{}}
                type={"dashboard"}
                hideDraggable={!editDashboard}
              />
            ) : (
              <LoadingWrapper bg="#F5F5F5" />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default Feedback;
