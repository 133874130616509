import { Box, Button, FormHelperText } from "@mui/material";
import { useContext, useState } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormContext, FormContextAttr } from "./AddGithub";
import FlexBox from "../shared/wrappers/FlexBox";
import { FormattedCheckbox } from "../shared/wrappers/FilterPopper";
import { LoadingButton } from "@mui/lab";
import { Small } from "../shared/wrappers/Typography";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PrivateIcon from "../shared/icons/private.svg";
import PublicIcon from "../shared/icons/public.svg";
import {
  createOrEditOrganization,
  startGithubIssuesImport,
} from "../../services/api/Apps.api";
import { ADMIN_MANAGEMENT_TYPE, getWithExpiry } from "../shared/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";

export type Repository = {
  repositoryId: string;
  repositoryName: string;
  repositoryOwner: string;
  repositoryUrl: string;
  issuesCount: number;
  isPrivate: Boolean;
};

const SelectRepository = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    repositories,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const appData: any = useSelector((state: RootState) => state.app);

  const initialValues: {
    repository: string[];
  } = {
    repository: [],
    ...formData,
  };

  const validationSchema = Yup.object().shape({
    repository: Yup.array().required("Please select atleast one repository"),
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading(true);

      if (values.repository && values.repository.length !== 0) {
        const repos = repositories.filter((p: Repository) =>
          values.repository.includes(p.repositoryName)
        );
        const data = { ...formData, ...values, repos };

        try {
          const res = await startGithubIssuesImport({
            user: localStorage.getItem("username") || "",
            token: formData.token,
            accessToken: getWithExpiry("token"),
            appName: formData.name,
            repositories: JSON.stringify(repos),
            type: "with_pat",
          });

          if (res && res.body) {
            const response = JSON.parse(res.body);
            if (response && response.appID) {
              // Add previous appIDs as well if exists
              let appIDs = [];
              if (appData.user.userAppId) {
                try {
                  appIDs = JSON.parse(appData.user.userAppId);
                } catch (e) {
                  console.log(e);
                  appIDs = appData.user.userAppId;
                }
              }
              if (!Array.isArray(appIDs)) {
                appIDs = [appIDs];
              }

              appIDs = appIDs.concat([response.appID]);

              localStorage.setItem("appId", JSON.stringify(appIDs));

              try {
                await createOrEditOrganization({
                  admin: localStorage.getItem("username") || "",
                  appID: JSON.stringify(appIDs),
                  organization: appData?.userRole?.organization || "",
                  type: ADMIN_MANAGEMENT_TYPE.edit,
                });
              } catch (e) {
                console.log(e);
              }

              setFormData(data);
              setActiveStepIndex(activeStepIndex + 1);
            } else if (response && response.message) {
              setError(response.message);
            }
          }
        } catch (e) {
          setError("There was some error during starting your import");
        }
      } else {
        setError("Please select atleast one repository");
      }
      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Select the repository(s)
      </FlexBox>
      {/* <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "30px",
        }}
      >
        Select the workspaces you wish to fetch the ideas from!
      </Box> */}
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              maxHeight: "15rem",
              overflowY: "auto",
              overflowX: "none",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            {repositories &&
              repositories.length &&
              repositories.map((filter: Repository, index: number) => {
                return (
                  <FullTextFieldWrapper key={`checkbox-product-name-${index}`}>
                    <FlexBox
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                        paddingLeft: "0.5rem",
                        marginBottom: "0.6rem",
                      }}
                    >
                      <FlexBox
                        sx={{
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <FlexBox
                          sx={{
                            width: "8%",
                            alignItems: "center",
                            height: "100%",
                            marginTop: "0.3rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          <LazyLoadImage
                            key={`${filter.repositoryId}--second--icon`}
                            alt={"Type"}
                            src={filter.isPrivate ? PrivateIcon : PublicIcon}
                            height={"32"}
                          />
                        </FlexBox>
                        <FlexBox
                          sx={{
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <FlexBox
                            sx={{
                              color: "#82868C",
                              width: "90%",
                              wordBreak: "break-word",
                            }}
                          >
                            <FlexBox
                              sx={{
                                width: "30%",
                              }}
                            >{`Repository Name:`}</FlexBox>
                            <FlexBox
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {filter.repositoryName}
                            </FlexBox>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              color: "#82868C",
                              width: "90%",
                              wordBreak: "break-word",
                            }}
                          >
                            <FlexBox
                              sx={{
                                width: "30%",
                              }}
                            >{`Owner Name:`}</FlexBox>
                            <FlexBox
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {filter.repositoryOwner}
                            </FlexBox>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              color: "#82868C",
                              width: "90%",
                              wordBreak: "break-word",
                            }}
                          >
                            <FlexBox
                              sx={{
                                width: "30%",
                              }}
                            >{`Repository URL:`}</FlexBox>
                            <FlexBox
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {/* <a
                                href={filter.repositoryUrl}
                                target="_blank"
                                rel="noreferrer"
                              > */}
                              {filter.repositoryUrl}
                              {/* </a> */}
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox>
                        <FormattedCheckbox
                          checked={values.repository.includes(
                            filter.repositoryName
                          )}
                          value={filter.repositoryName}
                          disabled={filter.issuesCount === 0}
                          name={"repository"}
                          id={"repository"}
                          onChange={handleChange}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FullTextFieldWrapper>
                );
              })}
          </FlexBox>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Importing data"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Start import"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default SelectRepository;
