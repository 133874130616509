import { Box, Button, FormHelperText } from "@mui/material";
import { useContext, useState } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormContext, FormContextAttr } from "./AddAha";
import FlexBox from "../shared/wrappers/FlexBox";
import { FormattedCheckbox } from "../shared/wrappers/FilterPopper";
import { getAhaMappingAttributes } from "../../services/api/Apps.api";
import { LoadingButton } from "@mui/lab";
import { Small } from "../shared/wrappers/Typography";

export type Product = {
  created_at: string;
  id: string;
  name: string;
  product_line: boolean;
  reference_prefix: string;
  workspace_type: string;
};

const SelectProducts = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    products,
    setAttributes,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    products: string[];
  } = {
    products: [],
    ...formData,
  };

  const validationSchema = Yup.object().shape({
    products: Yup.array().required("Please select any one workspace"),
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading(true);
      if (values.products.length !== 0) {
        const prod = products.filter((p: Product) =>
          values.products.includes(p.name)
        );
        const data = { ...formData, ...values, workspaces: prod };

        try {
          const response = await getAhaMappingAttributes(
            formData.subdomain,
            formData.apikey,
            prod
          );
          setAttributes(response);
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        } catch (e) {
          setError(
            "There was some error during fetching the ideas for the selected products"
          );
        }
      } else {
        setError("Please select atleast one workspace");
      }
      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Select the workspaces
      </FlexBox>
      {/* <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "30px",
        }}
      >
        Select the workspaces you wish to fetch the ideas from!
      </Box> */}
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              maxHeight: "15rem",
              overflowY: "auto",
              overflowX: "none",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            {products.map((filter: Product, index: number) => {
              return (
                <FullTextFieldWrapper key={`checkbox-product-name-${index}`}>
                  <FlexBox
                    sx={{
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <FlexBox
                        sx={{
                          ...((filter.name === "selectAll" &&
                            values.products.length === products.length) ||
                          values.products.findIndex(
                            (fil: string) => fil === filter.name
                          ) !== -1
                            ? { color: "#3579E3" }
                            : !!filter.product_line
                            ? { color: "#d9dbde" }
                            : { color: "#82868C" }),
                        }}
                      >
                        {filter.name}
                      </FlexBox>
                    </FlexBox>
                    <FlexBox>
                      <FormattedCheckbox
                        checked={values.products.includes(filter.name)}
                        value={filter.name}
                        disabled={!!filter.product_line}
                        name={"products"}
                        id={"products"}
                        onChange={handleChange}
                      />
                    </FlexBox>
                  </FlexBox>
                </FullTextFieldWrapper>
              );
            })}
          </FlexBox>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default SelectProducts;
