import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  importPageExtention,
  // getAppDetails,
  setUserDataAndRole,
} from "../shared/constants";
// import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import "./zapier.css";
import { AddFromSettingsProps } from "../AddApp/AddApp";

const ZapierFullExperience = ({ location }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        if (res.getUser.userAppId) {
          localStorage.setItem(
            "appId",
            Array.isArray(JSON.parse(res.getUser.userAppId))
              ? JSON.stringify(JSON.parse(res.getUser.userAppId))
              : JSON.stringify([JSON.parse(res.getUser.userAppId)])
          );
          console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  useEffect(() => {
    let interval = setInterval(async () => {
      const email = localStorage.getItem("username") || appData.user.email;
      const res: any = await getUserDetails(email);
      if (res && res.getUser && res.getUser.userAppId) {
        let appIDs = Array.isArray(JSON.parse(res.getUser.userAppId))
          ? JSON.stringify(JSON.parse(res.getUser.userAppId))
          : JSON.stringify([JSON.parse(res.getUser.userAppId)]);
        localStorage.setItem("appId", appIDs);
        if (JSON.parse(appIDs) && JSON.parse(appIDs).length) {
          console.log("Navigate Now to import wait");
          if (!location) {
            navigate(`/dashboard/${importPageExtention}`);
          }

          // Need to identify when to take the user to the import page
          // if (location && onAdd) {
          //   onAdd();
          // }
          clearInterval(interval);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // Use effect to check app import status and redirect user directly
  // useEffect(() => {
  //   const checkAppData = async (appData: any) => {
  //     // Check if all app data has success status...
  //     // If yes, navigate to feedback

  //     const res: any = await getAppDetails(appData, dispatch);
  //     if (res) {
  //       if (
  //         res.length !== 0 &&
  //         res.filter((val: { store: string; data: any }) => {
  //           return checkAllStatusFlags(val.data, [7], 4);
  //         }).length === 0
  //       ) {
  //         // The app data is succesfully processed
  //         navigate("/dashboard/insights/feedback");
  //       }
  //     }
  //   };

  //   checkAppData(appData);
  //   const interval = setInterval(() => {
  //     checkAppData(appData);
  //   }, 10000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",

          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Your Current Zaps
          </FlexBox>
          <Box
            sx={{
              mt: 3,
              ml: 1,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
            }}
          >
            The current list shows the zaps associated with Rargus from your
            account. Click on it to edit/modify your zap.
          </Box>
          <Box
            sx={{
              background: "#FFF",
              overflow: "hidden",
              mt: 2,
              maxHeight: "50vh",
              minHeight: "7rem",
            }}
          >
            <Box
              sx={{
                mt: "-7rem",
                ml: "-1rem",
                mr: "-1rem",
                mb: "-1rem",
                // pr: 1,
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Barlow",
                lineHeight: "20px",
                color: "#0B0E1E",
                maxHeight: "50vh",
                minHeight: "7rem",
                height: "fit-content",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.4rem",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#A3A6AB",
                  borderRadius: "5px",
                },
              }}
            >
              <zapier-full-experience
                client-id="EBWv47T1mULk1o7tedGH6MCbzKMK6OQaZ4O3iJTb"
                theme="light"
                intro-copy-display="hide"
                app-selection-display="hide"
                zap-limit="0"
                zap-create-from-scratch-display="hide"
              />
            </Box>
          </Box>

          <FlexBox
            sx={{
              marginTop: "1.2rem",
              width: "100%",
            }}
          ></FlexBox>
          {/* <Box sx={{ mt: 4 }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => navigate("/dashboard/data-source")}
            >
              Next
            </Button>
          </Box> */}
          <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 500,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => {
                navigate(`/dashboard/zapier-template`);
              }}
            >
              {"Back"}
            </Button>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default ZapierFullExperience;
