import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import {
  FullTextFieldWrapper,
  SocialIconButton,
  TextFieldWrapper,
} from "../shared/wrappers/StyledComponents";
import FlexBox from "../shared/wrappers/FlexBox";
import LightTextField from "../shared/wrappers/LightTextField";
import { H3, Paragraph, Small } from "../shared/wrappers/Typography";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import GoogleIcon from "../shared/icons/google.svg";
import FacebookIcon from "../shared/icons/facebook.svg";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../../services/UserPool";
import AuthLayoutWrapper from "./AuthLayoutWrapper";
import VerifyEmail from "../shared/images/verify-email.png";
import { authenticate } from "../../services/context/Account";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

const Register: FC = () => {
  const { width } = useWindowDimensions();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyProcess, setVerifyProcess] = useState(false);
  const [OTP, setOTP] = useState("");
  const initialValues: {
    name: string;
    terms: boolean;
    email: string;
    password: string;
    submit: null;
    remember: boolean;
  } = {
    email: "",
    password: "",
    submit: null,
    remember: false,
    name: "",
    terms: false,
  };

  let navigate = useNavigate();

  const loginRedirectPath = "/provider-redirect";
  const redirectHost = process.env.REACT_APP_REDIRECT_HOST;
  const appName = process.env.REACT_APP_APP_NAME;
  const region = process.env.REACT_APP_APP_REGION;
  const clinetId = process.env.REACT_APP_RARGUS_CLIENT_ID;
  const authScope = process.env.REACT_APP_RARGUS_CLIENT_AUTH_SCOPE;
  const loginURL = `https://${appName}.auth.${region}.amazoncognito.com/`;

  const providerLogin = (providerName: string) => {
    window.location.href = `${loginURL}oauth2/authorize?identity_provider=${providerName}&redirect_uri=${redirectHost}${loginRedirectPath}&response_type=CODE&client_id=${clinetId}&scope=${authScope}`;
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase and One Lowercase"
      ),
    name: Yup.string().required("Name is required"),
    terms: Yup.bool()
      .required("“Agree to the terms and conditions” to Sign up.")
      .oneOf([true]),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        setLoading(true);

        // Local Register Flow
        const attributeList = [];
        attributeList.push(
          new CognitoUserAttribute({
            Name: "email",
            Value: values.email,
          })
        );
        attributeList.push(
          new CognitoUserAttribute({
            Name: "name",
            Value: values.name,
          })
        );

        UserPool.signUp(
          values.email,
          values.password,
          attributeList,
          [],
          (err: any, data: any) => {
            if (err) {
              setLoading(false);
              const errorMessage = err.toString().split(":");
              setError(
                errorMessage && errorMessage.length
                  ? errorMessage[1].replace("PreSignUp failed with error", "")
                  : "Couldn't sign up"
              );
            } else {
              console.log(data);
              setLoading(false);
              setError("");
              setVerifyProcess(true);
            }
          }
        );
      },
    });

  const verifyAccount = () => {
    const user = new CognitoUser({
      Username: values.email,
      Pool: UserPool,
    });
    console.log(user);
    user.confirmRegistration(OTP, true, (err, data) => {
      if (err) {
        const errorMessage = err.toString().split(":");
        setError(
          errorMessage && errorMessage.length
            ? errorMessage[1]
            : "Couldn't verify code"
        );
      } else {
        // Logging in the user automatically after sign-up
        authenticate &&
          authenticate(values.email, values.password, values.remember)
            .then(async (data: any) => {
              // After the user is verified, Check if the user already have an App added by checking the user db
              // If yes, then redirect to some other page rather than the App Page
              setLoading(false);
              localStorage.setItem("username", values.email);
              localStorage.setItem("name", values.name);
              // Changing app vavigate when user has an app attached
              navigate("/dashboard/home");
            })
            .catch((err: any) => {
              setLoading(false);
              console.log(err);
              setError(
                "Email and Password that you entered do not match any account. Create an account now"
              );
            });
      }
    });
  };

  return (
    <AuthLayoutWrapper>
      {!verifyProcess ? (
        <FlexBox
          sx={{
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          }}
        >
          <Card
            sx={{
              padding: 4,
              overflowY: "auto",
              ...(width < 1150 ? {} : { maxWidth: 600 }),
              border: "none",
            }}
          >
            <FlexBox
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              mb={5}
            >
              <FlexBox
                style={{
                  fontSize: "68px",
                  fontWeight: 700,
                  lineHeight: "82px",
                  color: "#3579E3",
                  fontFamily: "Barlow",
                }}
              >
                {"Sign up"}
              </FlexBox>
            </FlexBox>

            <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
              <SocialIconButton
                onClick={() => providerLogin("Google")}
                startIcon={<img src={GoogleIcon} alt="Google Icon" />}
              >
                Sign in with Google
              </SocialIconButton>
              <SocialIconButton
                onClick={() => providerLogin("Facebook")}
                startIcon={<img src={FacebookIcon} alt="Facebook Icon" />}
              >
                Sign in with Facebook
              </SocialIconButton>

              <Divider sx={{ my: 3, width: "100%", alignItems: "flex-start" }}>
                <H3 color="text.disabled" px={1}>
                  Or
                </H3>
              </Divider>

              <form
                noValidate
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <FlexBox justifyContent="space-between" flexWrap="wrap">
                  <TextFieldWrapper>
                    <Paragraph fontWeight={600} mb={1}>
                      {"Name"}
                    </Paragraph>
                    <LightTextField
                      fullWidth
                      name={"name"}
                      type={"name"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name || ""}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </TextFieldWrapper>

                  <TextFieldWrapper>
                    <Paragraph fontWeight={600} mb={1}>
                      {"Email"}
                    </Paragraph>
                    <LightTextField
                      fullWidth
                      name={"email"}
                      type={"email"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || ""}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </TextFieldWrapper>
                </FlexBox>
                <FullTextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1}>
                    Password
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name="password"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password || ""}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FullTextFieldWrapper>

                <FlexBox
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    control={
                      <input
                        type="checkbox"
                        checked={values.terms}
                        onChange={handleChange}
                        name="terms"
                        style={{
                          width: "19px",
                          height: "19px",
                          background: "#E3E6EB",
                          borderRadius: "4px",
                          marginRight: "10px",
                          marginLeft: "14px",
                        }}
                      />
                    }
                    label="I agree to terms & conditions"
                    sx={{
                      marginTop: "0.5rem",
                      "& .MuiTypography-root": { fontWeight: 600 },
                    }}
                  />
                  {errors.terms && touched.terms && (
                    <FlexBox
                      sx={{
                        color: "#FD396D",
                        fontSize: "0.75rem",
                        lineHeight: "1.66",
                        margin: "0 14px 0 14px",
                      }}
                    >
                      {touched.terms &&
                        "“Agree to the terms and conditions” to Sign up."}
                    </FlexBox>
                  )}
                </FlexBox>

                {error && (
                  <FormHelperText
                    error
                    sx={{
                      mt: 2,
                      fontSize: 13,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </FormHelperText>
                )}

                <Box sx={{ mt: 4 }}>
                  {loading ? (
                    <LoadingButton loading fullWidth variant="contained">
                      {"Sign up"}
                    </LoadingButton>
                  ) : (
                    <Button fullWidth type="submit" variant="contained">
                      {"Sign up"}
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#3A3C40",
                  }}
                >
                  {"Note: By creating an account, you agree to our "}
                  <a
                    href="https://rargus.com/terms/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Small color="primary.main">Terms & Conditions</Small>
                  </a>
                  {" and "}
                  <a
                    href="https://rargus.com/privacy/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Small color="primary.main">Privacy Policies</Small>
                  </a>
                </Box>
              </form>

              <Small
                margin="auto"
                mt={3}
                color="text.disabled"
                fontSize={"14px"}
                lineHeight={"21px"}
              >
                <Small
                  color="#0B0E1E"
                  mr={1}
                  fontSize={"14px"}
                  fontWeight="500"
                >
                  {"Do you already have an account? "}
                </Small>
                <Link to="/login">
                  <Small
                    color="primary.main"
                    fontSize={"14px"}
                    fontWeight="500"
                  >
                    Sign in
                  </Small>
                </Link>
              </Small>
            </FlexBox>
          </Card>
        </FlexBox>
      ) : (
        <FlexBox
          sx={{
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          }}
        >
          <Card
            sx={{
              padding: 4,
              maxWidth: 600,
              minWidth: 400,
              border: "none",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <FlexBox
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 50,
                flexDirection: "column",
              }}
            >
              <FlexBox
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={VerifyEmail}
                  alt="Verify Email"
                  width="271"
                  height="139"
                />
              </FlexBox>
              <FlexBox
                sx={{
                  fontSize: "68px",
                  fontWeight: 700,
                  lineHeight: "82px",
                  color: "#3579E3",
                  fontFamily: "Barlow",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "700px",
                  marginBottom: "30px",
                }}
              >
                {"Almost there!"}
              </FlexBox>
              <FlexBox
                sx={{ padding: "0 1.7rem 0 1.7rem", flexDirection: "column" }}
              >
                <FlexBox
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "40px",
                    maxWidth: "440px",
                  }}
                >
                  {`We have sent an email to ${values.email} with a One Time Code. Please enter the One Time Code.`}
                </FlexBox>
                <FlexBox sx={{ marginBottom: "20px", textAlign: "left" }}>
                  <FullTextFieldWrapper>
                    <Paragraph fontWeight={600} mb={1}>
                      {"One Time Code"}
                    </Paragraph>
                    <LightTextField
                      fullWidth
                      name={"otp"}
                      type={"otp"}
                      onChange={(e) => setOTP(e.target.value)}
                      value={OTP}
                      error={!!error.length}
                      helperText={error}
                    />
                  </FullTextFieldWrapper>
                </FlexBox>
                <Box sx={{ marginBottom: "10px" }}>
                  {loading ? (
                    <LoadingButton loading fullWidth variant="contained">
                      {"Verifying Code"}
                    </LoadingButton>
                  ) : (
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      onClick={verifyAccount}
                    >
                      {"Verify Code"}
                    </Button>
                  )}
                </Box>
                <FlexBox
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "21px",
                    marginBottom: "20px",
                    maxWidth: "440px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {"Back to "}
                  <Link to="/login" style={{ paddingLeft: "0.3rem" }}>
                    <Small color="primary.main">Log in</Small>
                  </Link>
                </FlexBox>
              </FlexBox>
            </FlexBox>{" "}
          </Card>{" "}
        </FlexBox>
      )}
    </AuthLayoutWrapper>
  );
};

export default Register;
