import React, { useState, useRef, useLayoutEffect } from "react";
import { truncateFromEnd } from "../shared/constants";
import useElementWidth from "../../services/hooks/useElementWidth";
import useFitCharacterNumber from "../../services/hooks/useFitCharacterNumber";
import Tooltip from "../shared/wrappers/Tooltip";

type FormattedTextProps = {
  text: string;
  length?: number;
};

const FormattedText = ({ text, length }: FormattedTextProps) => {
  const offset = 2;
  const txtRef = useRef(null);
  const [textToShow, setTextToShow] = useState(
    length ? truncateFromEnd(text, length - offset) : text
  );
  const buttonWidth = useElementWidth(txtRef);
  const { textWidth, charNumber } = useFitCharacterNumber({
    ref: txtRef,
    maxWidth: buttonWidth,
    middleChars: "...",
  });

  useLayoutEffect(() => {
    if (txtRef && txtRef.current) {
      if (buttonWidth && charNumber && textWidth) {
        if (textWidth > buttonWidth) {
          setTextToShow(truncateFromEnd(text, charNumber - offset));
        }
      }
    }
  }, [buttonWidth, charNumber, text, textWidth]);

  return (
    <React.Fragment key={`react-formatted-text-${text}-${textToShow}`}>
      {text.length !== textToShow.length ? (
        <Tooltip title={text} placement="top">
          <div
            style={{ cursor: "pointer", width: "100%" }}
            ref={txtRef}
            key={`formatted-text-${text}-${textToShow}`}
          >
            {textToShow}
          </div>
        </Tooltip>
      ) : (
        <div
          ref={txtRef}
          style={{ width: "100%" }}
          key={`formatted--full-text-${text}-${textToShow}`}
        >
          {text}
        </div>
      )}
    </React.Fragment>
  );
};

export default FormattedText;
