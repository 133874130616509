import { WidgetId } from "../../services/store/AppStore/AppStoreSlice";
import {
  COMPLAINTS,
  COMPLIMENTS,
  constructData,
  constructLegend,
  getDataByStore,
  SENTIMENTS,
  SUGGESTIONS,
} from "./constants";

export const getDependentWidgetList = (widgets: WidgetId[]) => {
  let dependentList: string[] = [];

  if (widgets && widgets.length) {
    widgets.forEach((wid: WidgetId) => {
      if (wid.dependent && wid.dependent.length !== 0) {
        // Push the id of the object that is dependent
        dependentList.push(wid.id);
        wid.dependent.forEach((wid: WidgetId) => {
          dependentList.push(wid.id);
        });
      }
    });
  }

  return dependentList;
};

export const getFeedbackReportData = (
  appData: any,
  graphData: any,
  store: any[]
) => {
  // Get the labels for the report
  const label = constructLegend(appData.filter);
  if (graphData.feedback && graphData.feedback.length) {
    let fGraphData = getDataByStore(graphData.feedback, store);
    if (!Array.isArray(fGraphData)) {
      fGraphData = [fGraphData];
    }

    if (fGraphData && fGraphData.length !== 0) {
      let complaints: number[] = [];
      let compliments: number[] = [];
      let suggestions: number[] = [];
      fGraphData.forEach((graph: any, index: number) => {
        let feedbackGraphData: any;
        if (
          appData &&
          appData.filter &&
          appData.filter.value &&
          graph[appData.filter.value] &&
          graph[appData.filter.value]["data"]
        ) {
          feedbackGraphData = graph[appData.filter.value]["data"];
        }
        if (feedbackGraphData) {
          if (index === 0) {
            // Construct the data array for the graphs
            complaints = complaints.concat(feedbackGraphData[COMPLAINTS]);
            compliments = compliments.concat(feedbackGraphData[COMPLIMENTS]);
            suggestions = suggestions.concat(feedbackGraphData[SUGGESTIONS]);
          } else if (index >= 1) {
            // Construct the data array for the graphs
            complaints = [...complaints].map(
              (val: number, index: number) =>
                val + feedbackGraphData[COMPLAINTS][index]
            );
            compliments = [...compliments].map(
              (val: number, index: number) =>
                val + feedbackGraphData[COMPLIMENTS][index]
            );
            suggestions = [...suggestions].map(
              (val: number, index: number) =>
                val + feedbackGraphData[SUGGESTIONS][index]
            );
          }
        }
      });

      return {
        labels: label,
        datasets: [
          {
            id: "Compliments",
            label: "Compliments",
            data: compliments,
            backgroundColor: "white",
            borderColor: "#418F90",
            borderWidth: 1,
          },
          {
            id: "Complaints",
            label: "Complaints",
            data: complaints,
            backgroundColor: "white",
            borderColor: "#FF90A3",
            borderWidth: 1,
          },
          {
            id: "Suggestions",
            label: "Suggestions",
            data: suggestions,
            borderColor: "#8FB4F0",
            backgroundColor: "white",
            borderWidth: 1,
          },
        ],
      };
    }
  }
  return {
    labels: label,
    datasets: [
      {
        id: "Compliments",
        label: "Compliments",
        data: [],
        backgroundColor: "white",
        borderColor: "#418F90",
        borderWidth: 1,
      },
      {
        id: "Complaints",
        label: "Complaints",
        data: [],
        backgroundColor: "white",
        borderColor: "#FF90A3",
        borderWidth: 1,
      },
      {
        id: "Suggestions",
        label: "Suggestions",
        data: [],
        borderColor: "#8FB4F0",
        backgroundColor: "white",
        borderWidth: 1,
      },
    ],
  };
};

export const getAdminFeedbackInsightsData = (appData: any) => {
  // Get the labels for the report
  const label = constructLegend(appData.filter);

  if (appData.appFeedback && appData.appFeedback.length) {
    const [feedbackData] = constructData(appData.filter, appData.appFeedback);

    return {
      labels: label,
      datasets: [
        {
          id: "Feedbacks",
          label: "Feedbacks",
          data: feedbackData,
          backgroundColor: "white",
          borderColor: "#418F90",
          borderWidth: 1,
        },
      ],
    };
  }
  return {
    labels: label,
    datasets: [
      {
        id: "Feedbacks",
        label: "Feedbacks",
        data: [],
        backgroundColor: "white",
        borderColor: "#418F90",
        borderWidth: 1,
      },
    ],
  };
};

export const getSentimentTrendingTopicsReportData = (
  appData: any,
  subTopic: any,
  subTopicCluster: any,
  store: any[]
) => {
  const label = constructLegend(appData.filter);

  let reportReviewsData: any[] = [];

  if (subTopicCluster && subTopicCluster.sentimentData) {
    reportReviewsData = reportReviewsData.concat(subTopicCluster.sentimentData);
  } else if (
    subTopic &&
    subTopic.clusterGroup !== "0" &&
    subTopic.subClusters &&
    subTopic.subClusters.length
  ) {
    subTopic.subClusters.forEach((sub: any) => {
      reportReviewsData = reportReviewsData.concat(sub.sentimentData);
    });
  } else if (
    subTopic &&
    subTopic.clusterGroup === "0" &&
    subTopic.sentimentData &&
    subTopic.sentimentData.length
  ) {
    reportReviewsData = reportReviewsData.concat(subTopic.sentimentData);
  }

  const [positiveData, negativeData]: any[] = constructData(
    appData.filter,
    reportReviewsData.length
      ? reportReviewsData
      : getDataByStore(appData.sentiments, store),
    SENTIMENTS
  );

  return {
    labels: label,
    datasets: [
      {
        type: "line" as const,
        label: "Overall Sentiment",
        data: positiveData.map(
          (pos: number, index: number) => pos - negativeData[index]
        ),
        backgroundColor: "white",
        borderColor: "#0B0E1E",
        borderWidth: 1,
        //fill: false,
      },
      {
        type: "bar" as const,
        label: "Positive",
        data: positiveData,
        backgroundColor: "#4FAEB0",
        borderRadius: 4,
      },
      {
        type: "bar" as const,
        label: "Negative",
        data: negativeData.map((neg: number) => neg * -1),
        backgroundColor: "#DB4E82",
        borderRadius: 4,
      },
    ],
  };
};

export const getSentimentOverTimeReportData = (
  appData: any,
  defaultStore: any[]
) => {
  const labels = constructLegend(appData.filter);

  // const sentimentOverTime =
  //   graphData.sentimentOverTime[appData.filter.value]["data"];

  // // Construct the data array for the graphs
  // const positiveData = sentimentOverTime["positive"];
  // const negativeData = sentimentOverTime["negative"];
  // const overall = sentimentOverTime["overall"];
  const [positiveData, negativeData]: any[] = constructData(
    appData.filter,
    getDataByStore(appData.sentiments, defaultStore),
    SENTIMENTS
  );

  return {
    labels,
    datasets: [
      {
        label: "Overall Ratings",
        // data: overall,
        data: positiveData.map(
          (pos: any, index: number) => pos + negativeData[index]
        ),
        backgroundColor: "white",
        borderColor: "#0B0E1E",
        borderWidth: 1,
      },
      {
        label: "Positive",
        data: positiveData,
        backgroundColor: "white",
        borderColor: "#4FAEB0",
        borderWidth: 1,
      },
      {
        label: "Negative",
        data: negativeData,
        borderColor: "#DB4E82",
        backgroundColor: "white",
        borderWidth: 1,
      },
    ],
  };
};

export const getStackedRatingsOverTimeReportData = (
  appData: any,
  store: any[]
) => {
  // Setting the data in Bar Line Graph
  const labels = constructLegend(appData.filter);

  const sentiments = getDataByStore(appData.sentiments, store);

  // Move his data out to remove the number of computations
  const oneStar = [...sentiments].filter(
    (sent: any) => sent.rating >= 0 && sent.rating <= 1
  );
  const twoStar = [...sentiments].filter(
    (sent: any) => sent.rating > 1 && sent.rating <= 2
  );
  const threeStar = [...sentiments].filter(
    (sent: any) => sent.rating > 2 && sent.rating <= 3
  );
  const fourStar = [...sentiments].filter(
    (sent: any) => sent.rating > 3 && sent.rating <= 4
  );
  const fiveStar = [...sentiments].filter(
    (sent: any) => sent.rating > 4 && sent.rating <= 5
  );

  const [oneStarData] = constructData(appData.filter, oneStar);
  const [twoStarData] = constructData(appData.filter, twoStar);
  const [threeStarData] = constructData(appData.filter, threeStar);
  const [fourStarData] = constructData(appData.filter, fourStar);
  const [fiveStarData] = constructData(appData.filter, fiveStar);

  const [starRating, count] = constructData(
    appData.filter,
    sentiments,
    "rating"
  );

  return {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "Average star rating",
        data: starRating.map((star: number, index: number) => {
          if (count[index] === 0) {
            return "0.0";
          }

          return (star / count[index]).toFixed(1);
        }),
        backgroundColor: "white",
        borderColor: "#0B0E1E",
        borderWidth: 1,
        yAxisID: "right-y-axis",
        pointRadius: 3,
        //fill: false,
      },
      {
        type: "line" as const,
        label: "1",
        data: oneStarData,
        backgroundColor: "#DB4E82",
        //borderRadius: 4,
        fill: true,
        pointRadius: 2,
      },
      {
        type: "line" as const,
        label: "2",
        data: twoStarData,
        backgroundColor: "#FFA6AC",
        //borderRadius: 4,
        fill: true,
        pointRadius: 2,
      },
      {
        type: "line" as const,
        label: "3",
        data: threeStarData,
        backgroundColor: "#AFDADB",
        //borderRadius: 4,
        fill: true,
        pointRadius: 2,
      },
      {
        type: "line" as const,
        label: "4",
        data: fourStarData,
        backgroundColor: "#4FAEB0",
        //borderRadius: 4,
        fill: true,
        pointRadius: 2,
      },
      {
        type: "line" as const,
        label: "5",
        data: fiveStarData,
        backgroundColor: "#418F90",
        //borderRadius: 4,
        fill: true,
        pointRadius: 2,
      },
    ],
  };
};

export const getRatingsOverTimeBarLineReportData = (
  appData: any,
  store: any[]
) => {
  // Setting the data in Bar Line Graph
  // Setting this graph to always be set at filter for Last Year
  const labels = constructLegend(appData.filter);

  const [starRating, count] = constructData(
    appData.filter,
    getDataByStore(appData.sentiments, store),
    "rating"
  );

  const [positiveData, negativeData] = constructData(
    appData.filter,
    getDataByStore(appData.sentiments, store),
    SENTIMENTS
  );

  return {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "Average star rating",
        data: starRating.map((star: number, index: number) => {
          if (count[index] === 0) {
            return "0.0";
          }

          return (star / count[index]).toFixed(1);
        }),
        backgroundColor: "white",
        borderColor: "#0B0E1E",
        borderWidth: 1,
        yAxisID: "right-y-axis",
        //fill: false,
      },
      {
        type: "bar" as const,
        label: "Positive",
        data: positiveData,
        backgroundColor: "#4FAEB0",
        borderRadius: 4,
      },
      {
        type: "bar" as const,
        label: "Negative",
        data: negativeData.map((d) => -1 * d),
        backgroundColor: "#DB4E82",
        borderRadius: 4,
      },
    ],
  };
};

export const getAnalyticsRelevancyReportData = (
  appData: any,
  selectedSubtopic: any,
  selectedSubcluster: any,
  compliments: boolean,
  complaints: boolean,
  suggestions: boolean
) => {
  const label = constructLegend(appData.filter);

  let subCluster: any[] = [];
  let sentiments: any[] = [];

  if (!selectedSubtopic) {
    if (compliments) {
      subCluster = [...subCluster, ...appData.complaints];
    }
    if (complaints) {
      subCluster = [...subCluster, ...appData.compliments];
    }
    if (suggestions) {
      subCluster = [...subCluster, ...appData.suggestions];
    }
  } else if (selectedSubtopic && !selectedSubcluster) {
    if (selectedSubtopic && selectedSubtopic.clusterGroup !== "0") {
      subCluster = [...selectedSubtopic.subClusters];
      if (subCluster && subCluster.length) {
        subCluster.forEach((sub: any) => {
          if (sub.sentimentData && sub.sentimentData.length) {
            sentiments = sentiments.concat(sub.sentimentData);
          }
        });
      }
    } else if (
      selectedSubtopic &&
      selectedSubtopic.clusterGroup === "0" &&
      selectedSubtopic.sentimentData &&
      selectedSubtopic.sentimentData.length
    ) {
      sentiments = sentiments.concat(selectedSubtopic.sentimentData);
    }
  } else if (selectedSubtopic && selectedSubcluster) {
    if (
      selectedSubcluster &&
      selectedSubcluster.sentimentData &&
      selectedSubcluster.sentimentData.length
    ) {
      sentiments = [...selectedSubcluster.sentimentData];
    }
  }

  // const [subClusterData] = constructData(appData.filter, subCluster);
  // Always show the reviews line as the summation of all reviews

  const [reviewsData] = constructData(appData.filter, sentiments);

  // Set the labels and the data array
  // Need to work and fix this
  return {
    labels: label,
    datasets: [
      {
        label: "Reviews",
        // Dummy Data. Needs to be replaced with the number of reviews coming from
        data: reviewsData,
        backgroundColor: "white",
        borderColor: "#0B0E1E",
        borderWidth: 1,
      },
      // {
      //   label: "Cluster",
      //   data: subClusterData,
      //   backgroundColor: "white",
      //   borderColor: "#418F90",
      //   borderWidth: 1,
      // },
    ],
  };
};
