import { LoadingButton } from "@mui/lab";
import { Box, Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../services/hooks/useWindowDimensions";
import FlexBox from "./FlexBox";
import "./Modal.css";

const DeleteModal = (props: {
  title: string;
  content: string | any;
  onClose: () => void;
  show: boolean;
  setShow: (value: boolean) => void;
  children?: any;
  loading?: boolean;
}) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.setShow(false);
    }

    if ((e.charCode || e.keyCode) === 13) {
      props.onClose && props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHanlder = () => {
    setLoading(true);
    props.onClose && props.onClose();
  };

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Card
          sx={{
            padding: 4,
            ...(width < 800 ? { width: "90%" } : { maxWidth: "600px" }),
            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",
            height: "fit-content",
          }}
        >
          <FlexBox flexDirection="column" justifyContent="center">
            <FlexBox
              style={{
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38px",
                color: "#DB6C56",
                fontFamily: "Barlow",
              }}
            >
              {props.title}
            </FlexBox>
            <Box
              sx={{
                mt: 4,
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "28px",
                color: "#0B0E1E",
                minHeight: "4rem",
                height: "fit-content",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {props.content}
            </Box>
            <Box
              sx={{
                mt: 4,
                width: "100%",
                textAlign: "end",
              }}
            >
              <Button
                type="button"
                variant="outlined"
                sx={{
                  border: "none",
                  "&:hover": { border: "none" },
                }}
                onClick={() => props.setShow && props.setShow(false)}
              >
                Cancel
              </Button>
              {loading === true ? (
                <LoadingButton
                  loading
                  variant="contained"
                  sx={{
                    background: "#DB6C56",
                    "&:hover": { backgroundColor: "#DB6C56" },
                  }}
                >
                  {"Delete"}
                </LoadingButton>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    background: "#DB6C56",
                    "&:hover": { backgroundColor: "#DB6C56" },
                  }}
                  onClick={() => onCloseHanlder()}
                >
                  Delete
                </Button>
              )}
            </Box>
          </FlexBox>
        </Card>
      </div>
    </div>
  );
};

export default DeleteModal;
