import { WidgetId } from "../../services/store/AppStore/AppStoreSlice";
import { Widget } from "../Insights/WidgetContainer";
import {
  ADDWIDGET,
  ADD_WIDGET,
  AREA_WITH_LINE_GRAPH,
  BAR_LINE_GRAPH,
  BAR_LINE_GRAPH_WITH_REVIEWS,
  CLUSTER_SUBCLUSTER,
  COMPACT,
  COMPACT_2,
  COMPLAINTS,
  COMPLIMENTS,
  CONTAINERWIDTH,
  CONTAINER_WIDTH_AND_HEIGHT,
  createTopTopicsData,
  DETAILS_COMPONENT,
  distictArray,
  EXPANDED,
  FEEDBACKS,
  FULLWIDTH,
  FULLWIDTH_2,
  getDataByStore,
  getSentimentScore,
  getUuid,
  getWidgetSource,
  GET_DATA_STORE,
  HORIZONTAL_GRAPH_WITH_REVIEWS,
  HORIZONTAL_GRAPH_WITH_REVIEWS_COMPONENT,
  HORIZONTAL_SCALE_GRAPH,
  INDEPENDENT_BAR_LINE_GRAPH,
  INDEPENDENT_LINE_GRAPH,
  LINE_GRAPH_WITH_SCALE,
  MORE_SENTIMENTS,
  NEGATIVE,
  NORMAL,
  NORMAL_TRANS,
  OVER_COMPACT,
  OVER_NORMAL,
  POSITIVE,
  REVIEWS,
  SENTIMENTS_ALL,
  SENTIMENTS_WIDGET,
  starRatingScore,
  STAR_RATINGS,
  STORE,
  SUGGESTIONS,
  SUMMARY,
  TOPIC_DETAILS,
  INDEPENDENT_LINE_GRAPH_EXTENTION,
} from "./constants";
import { v4 as uuidv4 } from "uuid";
import {
  getAdminFeedbackInsightsData,
  getDependentWidgetList,
  getFeedbackReportData,
  getRatingsOverTimeBarLineReportData,
  getSentimentOverTimeReportData,
  getStackedRatingsOverTimeReportData,
} from "./widgetData";

export const WIDGET_IDS = {
  dashboardInsights: "feedback-dashboard-insights-over-time-graph",
  dashboardSentiments: "feedback-dashboard-sentiments-graph",
  dashboardRatings: "feedback-dashboard-star-ratings-graph",
  dashboardTldr: "feedback-dashboard-too-long-dont-read",
  dashboardComplaints: "feedback-dashboard-complaints-details",
  dashboardCompliments: "feedback-dashboard-compliments-details",
  dashboardSuggestions: "feedback-dashboard-suggestions-details",
  dashboardAddWidget: "feedback-dashboard-add-widget",
  sentimentSentiments: "sentiment-sentiments-graph",
  sentimentOverTime: "sentiment-over-time-graph",
  sentimentHorizontalScale: "sentiment-horizontal-scale-graph",
  sentimentHorizontalReview: "sentiment-horizontal-scale-review",
  sentimentHorizontalScaleWithReviews:
    "sentiment-horizontal-scale-graph-with-reviews",
  sentimentTrendingTopic: "sentiment-trending-topic",
  // sentimentTrendingTopic: "sentiment-trending-topic",
  sentimentTrendingReview: "sentiment-trending-review",
  sentimentTrendingTopicWithReviews: "sentiment-trending-topic-with-reviews",
  ratingStarRatings: "rating-star-ratings",
  ratingOverTime: "rating-amount-over-time",
  ratingTopicSubtopic: "rating-cluster-subcluster",
  ratingAreaLineGraph: "rating-over-time-area-line-graph",
  ratingGraphReviews: "rating-cluster-graph-reviews",
  analyticsRelevancy: "analytics-relevancy-graph",
  // used to add some new features in the analytics graph
  analyticsRelevancyExtention: "analytics-relevancy-graph-extention",
  analyticsTopicDetail: "analytics-topic-details",
  appFeedbackList: "app-feedback-list",
  appFeedbackInsights: "app-feedback-insights",
  appFeedbackRatings: "app-feedback-ratings",
};

export const getWidgetList = (appData: any, providedId?: string) => {
  let widgetList: WidgetId[] = [];
  if (appData && appData.widgetLayout) {
    const widgetPages = Object.keys(appData.widgetLayout);
    if (widgetPages && widgetPages.length) {
      const widgetsByPage: any = getWidgetsByPage(appData);
      widgetPages.forEach((page: string) => {
        if (page !== "analytics") {
          widgetList = widgetList.concat([
            ...(widgetsByPage && page && widgetsByPage[page]
              ? widgetsByPage[page]
              : appData.widgetLayout[page].widgets &&
                appData.widgetLayout[page].widgets.length
              ? appData.widgetLayout[page].widgets
              : []),
          ]);
        }
      });
    }
  }
  // Getting the distinct by the widgetId
  const distinctWidgetList = distictArray(
    widgetList,
    providedId ? providedId : "widgetId"
  );
  const dependentWidgets = getDependentWidgetList(distinctWidgetList);

  return distinctWidgetList.filter(
    (wid: any) => !dependentWidgets.includes(wid.id)
  );
};

const initIndex = "init";

// Default Feedback Dashboard widgets structure
export const DEFAULT_DASHBOARD_WIDGETS = (appData: any) => [
  {
    id: `${WIDGET_IDS.dashboardInsights}`,
    widgetId: `${WIDGET_IDS.dashboardInsights}--${initIndex}`,
    label: "Insights over time",
  },
  ...(appData &&
  appData.store.length &&
  (appData.store.includes(STORE.apple) ||
    appData.store.includes(STORE.google) ||
    appData.store.includes(STORE.csv) ||
    appData.store.includes(STORE.amazon) ||
    appData.store.includes(STORE.slack))
    ? [
        //   {
        //     id: "feedback-dashboard-sentiments-graph",
        //     label: "Current rating with link",
        //   },
        //   {
        //     id: "feedback-dashboard-star-ratings-graph",
        //     label: "Sentiment score with link",
        //   },
        // ]
        {
          id: `${WIDGET_IDS.ratingStarRatings}`,
          widgetId: `${WIDGET_IDS.ratingStarRatings}--${initIndex}`,
          label: "Current rating",
        },
        {
          id: `${WIDGET_IDS.sentimentSentiments}`,
          widgetId: `${WIDGET_IDS.sentimentSentiments}--${initIndex}`,
          label: "Sentiment score",
        },
      ]
    : []),
  {
    id: `${WIDGET_IDS.dashboardTldr}`,
    widgetId: `${WIDGET_IDS.dashboardTldr}--${initIndex}`,
    label: "tl;dr",
  },
  ...(appData &&
  appData.store &&
  appData.store.length &&
  (appData.store.includes(STORE.aha) ||
    appData.store.includes(STORE.sof) ||
    appData.store.includes(STORE.github))
    ? [
        {
          id: `${WIDGET_IDS.dashboardSuggestions}`,
          widgetId: `${WIDGET_IDS.dashboardSuggestions}--${initIndex}`,
          label: "Suggestions",
        },
      ]
    : []),
  {
    id: `${WIDGET_IDS.dashboardComplaints}`,
    widgetId: `${WIDGET_IDS.dashboardComplaints}--${initIndex}`,
    label: "Complaints",
  },
  {
    id: `${WIDGET_IDS.dashboardCompliments}`,
    widgetId: `${WIDGET_IDS.dashboardCompliments}--${initIndex}`,
    label: "Compliments",
  },
  ...(appData &&
  appData.store.length &&
  (appData.store.includes(STORE.apple) ||
    appData.store.includes(STORE.google) ||
    appData.store.includes(STORE.csv) ||
    appData.store.includes(STORE.amazon) ||
    appData.store.includes(STORE.slack))
    ? [
        {
          id: `${WIDGET_IDS.dashboardSuggestions}`,
          widgetId: `${WIDGET_IDS.dashboardSuggestions}--${initIndex}`,
          label: "Suggestions",
        },
      ]
    : []),
  // {
  //   id: "feedback-dashboard-add-widget",
  // },
];

export const DEFAULT_ADMIN_FEEDBACK_WIDGETS: WidgetId[] = [
  {
    id: `${WIDGET_IDS.appFeedbackList}`,
    widgetId: `${WIDGET_IDS.appFeedbackList}--${initIndex}`,
    label: "Feedbacks",
  },
  {
    id: `${WIDGET_IDS.appFeedbackInsights}`,
    widgetId: `${WIDGET_IDS.appFeedbackInsights}--${initIndex}`,
    label: "Feedback insights",
  },
  {
    id: `${WIDGET_IDS.appFeedbackRatings}`,
    widgetId: `${WIDGET_IDS.appFeedbackRatings}--${initIndex}`,
    label: "Feedback ratings",
  },
];

export const DEFAULT_SENTIMENT_WIDGETS: WidgetId[] = [
  {
    id: `${WIDGET_IDS.sentimentSentiments}`,
    widgetId: `${WIDGET_IDS.sentimentSentiments}--${initIndex}`,
    label: "Sentiment score",
  },
  {
    id: `${WIDGET_IDS.sentimentOverTime}`,
    widgetId: `${WIDGET_IDS.sentimentOverTime}--${initIndex}`,
    label: "Sentiment over time",
  },
  {
    id: `${WIDGET_IDS.sentimentHorizontalScaleWithReviews}`,
    widgetId: `${WIDGET_IDS.sentimentHorizontalScaleWithReviews}--${initIndex}`,
    label: "Top sentiment topics",
  },
  // {
  //   id: "sentiment-horizontal-scale-graph",
  //   label: "Top sentiment topics",
  // },
  // {
  //   id: "sentiment-horizontal-scale-review",
  //   label: "TST reviews",
  //   dependent: [
  //     {
  //       id: "sentiment-horizontal-scale-graph",
  //       label: "Top sentiment topics",
  //     },
  //   ],
  // },
  {
    id: `${WIDGET_IDS.sentimentTrendingTopicWithReviews}`,
    widgetId: `${WIDGET_IDS.sentimentTrendingTopicWithReviews}--${initIndex}`,
    label: "Trending topics",
  },
  // {
  //   id: "sentiment-trending-topic",
  //   label: "Trending topics",
  // },
  // {
  //   id: "sentiment-trending-review",
  //   label: "TT reviews",
  //   dependent: [
  //     {
  //       id: "sentiment-trending-topic",
  //       label: "Trending topics",
  //     },
  //   ],
  // },
];

export const DEFAULT_RATING_WIDGETS = [
  {
    id: `${WIDGET_IDS.ratingStarRatings}`,
    widgetId: `${WIDGET_IDS.ratingStarRatings}--${initIndex}`,
    label: "Current rating",
  },
  {
    id: `${WIDGET_IDS.ratingOverTime}`,
    widgetId: `${WIDGET_IDS.ratingOverTime}--${initIndex}`,
    label: "Amount of ratings over time",
  },
  {
    id: `${WIDGET_IDS.ratingTopicSubtopic}`,
    widgetId: `${WIDGET_IDS.ratingTopicSubtopic}--${initIndex}`,
    label: "Topics by rating",
  },
  {
    id: `${WIDGET_IDS.ratingAreaLineGraph}`,
    widgetId: `${WIDGET_IDS.ratingAreaLineGraph}--${initIndex}`,
    label: "Star ratings over time",
  },
  {
    id: `${WIDGET_IDS.ratingGraphReviews}`,
    widgetId: `${WIDGET_IDS.ratingGraphReviews}--${initIndex}`,
    label: "TBR reviews",
    dependent: [
      {
        id: `${WIDGET_IDS.ratingTopicSubtopic}`,
        widgetId: `${WIDGET_IDS.ratingTopicSubtopic}--${initIndex}`,
        label: "Topics by rating",
      },
    ],
  },
];

export const DEFAULT_ANALYTICS_WIDGET = (appData: any) => [
  // Commented due to migration of topic details for all flows
  ...(appData &&
  appData.store.length &&
  (appData.store.includes(STORE.apple) || appData.store.includes(STORE.google))
    ? [
        {
          id: `${WIDGET_IDS.analyticsRelevancy}`,
          widgetId: `${WIDGET_IDS.analyticsRelevancy}--${initIndex}`,
          label: "Relevancy",
        },
      ]
    : []),
  // use to show some graph reiated data in the analytics page for github and sof
  // added here to add some more feture
  ...(appData &&
  appData.store.length &&
  (appData.store.includes(STORE.sof) || appData.store.includes(STORE.github))
    ? [
        {
          id: `${WIDGET_IDS.analyticsRelevancyExtention}`,
          widgetId: `${WIDGET_IDS.analyticsRelevancyExtention}--${initIndex}`,
          label: "Relevancy",
        },
      ]
    : []),
  ...(appData &&
  appData.store &&
  appData.store.length &&
  appData.store.includes(STORE.aha)
    ? [
        {
          id: `${WIDGET_IDS.analyticsTopicDetail}`,
          widgetId: `${WIDGET_IDS.analyticsTopicDetail}--${initIndex}`,
          label: "Topic details",
        },
      ]
    : []),

  {
    id: `${WIDGET_IDS.analyticsTopicDetail}`,
    widgetId: `${WIDGET_IDS.analyticsTopicDetail}--${initIndex}`,
    label: "Topic details",
  },
];

export const getWidgetsByPage = (appData: any) => ({
  dashboard: DEFAULT_DASHBOARD_WIDGETS(appData),
  rating: DEFAULT_RATING_WIDGETS,
  sentiment: DEFAULT_SENTIMENT_WIDGETS,
  analytics: DEFAULT_ANALYTICS_WIDGET(appData),
  "app-feedback": DEFAULT_ADMIN_FEEDBACK_WIDGETS,
});

export const getWidgetObject = (
  widget: WidgetId,
  appData: any,
  graphData: any,
  otherData: any
) => {
  // Get the id for the widget type
  const id = widget.id;

  let defaultStore = getWidgetSource(appData, widget);

  let filter = widget.filter
    ? { filter: widget.filter }
    : { filter: appData.filter };

  let mappedProps = {
    ...(defaultStore ? { source: defaultStore } : {}),
    ...filter,
    ...(widget.label ? { label: widget.label } : {}),
  };

  switch (id) {
    case WIDGET_IDS.dashboardAddWidget:
      return {
        id: WIDGET_IDS.dashboardAddWidget,
        type: ADDWIDGET,
        component: ADD_WIDGET,
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardSentiments:
      return {
        id: WIDGET_IDS.dashboardSentiments,
        type: COMPACT,
        component: STAR_RATINGS,
        subComponent: MORE_SENTIMENTS,
        route: "/dashboard/insights/ratings",
        data: {
          store: localStorage.getItem("store") || "",
        },
        hidden: getDataByStore(appData.sentiments, defaultStore).length === 0,
        rating: starRatingScore(
          filter.filter,
          getDataByStore(appData.sentiments, defaultStore)
        ),
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardRatings:
      return {
        id: WIDGET_IDS.dashboardRatings,
        type: COMPACT,
        component: SENTIMENTS_WIDGET,
        subComponent: MORE_SENTIMENTS,
        hidden: getDataByStore(appData.sentiments, defaultStore).length === 0,
        route: "/dashboard/insights/sentiments",
        ...getSentimentScore(
          filter.filter,
          getDataByStore(appData.sentiments, defaultStore)
        ),
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardTldr:
      let app = getDataByStore(appData.app, defaultStore);
      if (!Array.isArray) {
        app = [app];
      }
      let summary = "";

      if (app && app.length === 1) {
        summary = app && app[0] && app[0].summary ? app[0].summary.trim() : "";
      } else if (app && app.length > 1) {
        app.forEach((app: any) => {
          summary += `Source: ${app.name || app.title || app.developer}${
            app.store && app.store !== STORE.csv ? ` (${app.store})` : ""
          }\n${app.summary ? app.summary.trim() : ""}\n\n`;
        });
      }
      return {
        id: WIDGET_IDS.dashboardTldr,
        type: NORMAL,
        component: SUMMARY,
        help: "A summary of the top information you should pay attention to over the time period specified in this dashboard.",
        hidden: app && app[0] && app[0].summary === "",
        data: {
          content: summary,
        },
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardComplaints:
      return {
        id: WIDGET_IDS.dashboardComplaints,
        type: NORMAL,
        component: DETAILS_COMPONENT,
        subComponent: COMPLAINTS,
        help: "Shows the top complaint topics over the time period specified in this dashboard. Each topic shows the average star rating of a review in that topic, if available. Also shows the number of topics over the specified time period.",
        hidden:
          getDataByStore(appData.complaintCluster, defaultStore).length === 0,
        data: {
          data: getDataByStore(appData.complaintCluster, defaultStore),
          hideRating: appData.store.includes(STORE.aha),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardCompliments:
      return {
        id: WIDGET_IDS.dashboardCompliments,
        type: NORMAL,
        component: DETAILS_COMPONENT,
        subComponent: COMPLIMENTS,
        help: "Shows the top compliment topics over the time period specified in this dashboard. Each topic shows the average star rating of a review in that topic, if available. Also shows the number of topics over the specified time period.",
        hidden:
          getDataByStore(appData.complimentCluster, defaultStore).length === 0,
        data: {
          data: getDataByStore(appData.complimentCluster, defaultStore),
          hideRating: appData.store.includes(STORE.aha),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardSuggestions:
      return {
        id: WIDGET_IDS.dashboardSuggestions,
        type: NORMAL,
        component: DETAILS_COMPONENT,
        subComponent: SUGGESTIONS,
        help: "Shows the top suggestion topics over the time period specified in this dashboard. Each topic shows the average star rating of a review in that topic, if available. Also shows the number of topics over the specified time period.",
        hidden:
          getDataByStore(appData.suggestionCluster, defaultStore).length === 0,
        data: {
          data: getDataByStore(appData.suggestionCluster, defaultStore),
          hideRating: appData.store.includes(STORE.aha),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.dashboardInsights:
      return {
        id: WIDGET_IDS.dashboardInsights,
        type: NORMAL,
        component: INDEPENDENT_LINE_GRAPH,
        help: "Shows the volume of complaints, compliments, and suggestions over time.",
        hidden:
          getDataByStore(appData.complaints, defaultStore).length === 0 &&
          getDataByStore(appData.compliments, defaultStore).length === 0 &&
          getDataByStore(appData.suggestions, defaultStore).length === 0,
        data: {
          title: "Insights over time",
          metrics: getFeedbackReportData(
            {
              ...appData,
              filter: filter.filter,
            },
            graphData,
            defaultStore
          ),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentSentiments:
      return {
        id: WIDGET_IDS.sentimentSentiments,
        type: COMPACT_2,
        component: SENTIMENTS_WIDGET,
        subComponent: SENTIMENTS_ALL,
        help: "Shows the sentiment of your feedback rated on a scale of 0-100, 0 being perfectly negative and 100 being perfectly positive.",
        hidden: getDataByStore(appData.sentiments, defaultStore).length === 0,
        ...getSentimentScore(
          filter.filter,
          getDataByStore(appData.sentiments, defaultStore)
        ),
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentOverTime:
      return {
        id: WIDGET_IDS.sentimentOverTime,
        type: EXPANDED,
        component: LINE_GRAPH_WITH_SCALE,
        data: {
          title: "Sentiment over time",
          metrics: getSentimentOverTimeReportData(
            {
              ...appData,
              filter: filter.filter,
            },
            defaultStore
          ),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentHorizontalScale:
      return {
        id: WIDGET_IDS.sentimentHorizontalScale,
        type: otherData.showReviews
          ? otherData.width < 1200
            ? NORMAL
            : OVER_NORMAL
          : FULLWIDTH,
        component: HORIZONTAL_GRAPH_WITH_REVIEWS,
        hidden:
          getDataByStore(appData.clusterSentiment, defaultStore).length === 0,
        data: {
          showReviews: otherData.showReviews,
          setShowReviews: otherData.setShowReviews,
          width: otherData.width,
        },
        positive: {
          id: uuidv4(),
          type: NORMAL,
          component: HORIZONTAL_SCALE_GRAPH,
          subComponent: POSITIVE,
          reviews: createTopTopicsData(otherData.filteredSentiments),
          data: {
            setSelectedTopic: otherData.setSelectedTopic,
            selectedTopic: otherData.selectedTopic,
          },
        },
        negative: {
          id: uuidv4(),
          type: NORMAL,
          component: HORIZONTAL_SCALE_GRAPH,
          subComponent: NEGATIVE,
          reviews: createTopTopicsData(otherData.filteredSentiments),
          data: {
            setSelectedTopic: otherData.setSelectedTopic,
            selectedTopic: otherData.selectedTopic,
          },
        },
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentHorizontalReview:
      return {
        id: WIDGET_IDS.sentimentHorizontalReview,
        type: otherData.width < 1200 ? NORMAL_TRANS : OVER_COMPACT,
        component: REVIEWS,
        hide: !otherData.showReviews,
        data: {
          // reviews: filteredClusterReviews,
          height: "33rem",
          positive: otherData.positive,
          setPositive: otherData.setPositive,
          negative: otherData.negative,
          setNegative: otherData.setNegative,
          oneStar: otherData.oneStar,
          setOneStar: otherData.setOneStar,
          twoStar: otherData.twoStar,
          setTwoStar: otherData.setTwoStar,
          threeStar: otherData.threeStar,
          setThreeStar: otherData.setThreeStar,
          fourStar: otherData.fourStar,
          setFourStar: otherData.setFourStar,
          fiveStar: otherData.fiveStar,
          setFiveStar: otherData.setFiveStar,
          filteredReviews: otherData.filteredReviews,
          setFilteredReviews: otherData.setFilteredReviews,
          reviewPoints: otherData.reviewPoints,
          clusterPoints: otherData.clusterPoints,
          itemOffset: otherData.itemOffset,
          setItemOffset: otherData.setItemOffset,
          currentItems: otherData.currentItems,
          itemsPerPage: otherData.itemsPerPage,
          store: defaultStore.map((i: GET_DATA_STORE) => i.store),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentHorizontalScaleWithReviews:
      return {
        id: WIDGET_IDS.sentimentHorizontalScaleWithReviews,
        component: HORIZONTAL_GRAPH_WITH_REVIEWS_COMPONENT,
        type: FULLWIDTH_2,
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentTrendingTopicWithReviews:
      return {
        id: WIDGET_IDS.sentimentTrendingTopicWithReviews,
        component: BAR_LINE_GRAPH_WITH_REVIEWS,
        type: FULLWIDTH_2,
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentTrendingTopic:
      return {
        id: WIDGET_IDS.sentimentTrendingTopic,
        type: otherData.showBarLineReviews
          ? otherData.width < 1200
            ? NORMAL
            : OVER_NORMAL
          : FULLWIDTH,
        component: BAR_LINE_GRAPH,
        help: "Topics that have recently started to show a trend. These topics are topics that are new, or have had a significant recent change in either volume, sentiment, or star rating.",
        hidden:
          getDataByStore(appData.clusterSentiment, defaultStore).length === 0,
        data: {
          width: otherData.width,
          title: "Trending Topics",
          metrics: otherData.reportData,
          topic: otherData.filteredSentiments,
          subTopic: otherData.subTopic,
          setSubTopic: otherData.setSubTopic,
          subTopicCluster: otherData.subTopicCluster,
          setSubTopicCluster: otherData.setSubTopicCluster,
          showReviews: otherData.showBarLineReviews,
          setShowReviews: otherData.setShowBarLineReviews,
          setFilteredBarLineReviews: otherData.setFilteredBarLineReviews,
        },
        ...mappedProps,
      };
    case WIDGET_IDS.sentimentTrendingReview:
      return {
        id: WIDGET_IDS.sentimentTrendingReview,
        type: otherData.width < 1200 ? NORMAL_TRANS : OVER_COMPACT,
        hide: !otherData.showBarLineReviews,
        component: REVIEWS,
        data: {
          // reviews: filteredBarLineReviews,
          height: "37rem",
          positive: otherData.positive2,
          setPositive: otherData.setPositive2,
          negative: otherData.negative2,
          setNegative: otherData.setNegative2,
          oneStar: otherData.oneStar2,
          setOneStar: otherData.setOneStar2,
          twoStar: otherData.twoStar2,
          setTwoStar: otherData.setTwoStar2,
          threeStar: otherData.threeStar2,
          setThreeStar: otherData.setThreeStar2,
          fourStar: otherData.fourStar2,
          setFourStar: otherData.setFourStar2,
          fiveStar: otherData.fiveStar2,
          setFiveStar: otherData.setFiveStar2,
          filteredReviews: otherData.filteredReviews2,
          setFilteredReviews: otherData.setFilteredReviews2,
          reviewPoints: otherData.reviewPoints2,
          clusterPoints: otherData.clusterPoints2,
          itemOffset: otherData.itemOffset,
          setItemOffset: otherData.setItemOffset,
          currentItems: otherData.currentItems,
          itemsPerPage: otherData.itemsPerPage,
          store: defaultStore.map((i: GET_DATA_STORE) => i.store),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.ratingStarRatings:
      return {
        id: WIDGET_IDS.ratingStarRatings,
        type: COMPACT_2,
        component: STAR_RATINGS,
        subComponent: SENTIMENTS_ALL,
        help: "The average star rating of all pieces of feedback over the time period specified in this dashboard. The rating takes into account all data sources specified. Note, this may vary from public app store ratings as no weights are applied to the reviews when calculating the current rating.",
        hidden: getDataByStore(appData.sentiments, defaultStore).length === 0,
        data: {
          store: defaultStore,
        },
        rating: starRatingScore(
          filter.filter,
          getDataByStore(appData.sentiments, defaultStore)
        ),
        ...mappedProps,
      };
    case WIDGET_IDS.ratingOverTime:
      return {
        id: WIDGET_IDS.ratingOverTime,
        type: EXPANDED,
        component: INDEPENDENT_BAR_LINE_GRAPH,
        data: {
          title: "Amount of star ratings over time",
          metrics: getRatingsOverTimeBarLineReportData(
            {
              ...appData,
              filter: filter.filter,
            },
            defaultStore
          ),
          customYAxis: " ★ ",
        },
        ...mappedProps,
      };
    case WIDGET_IDS.ratingTopicSubtopic:
      return {
        id: WIDGET_IDS.ratingTopicSubtopic,
        type: CONTAINERWIDTH,
        component: CLUSTER_SUBCLUSTER,
        hidden:
          getDataByStore(appData.clusterSentiment, defaultStore).length === 0,
        data: {
          clusterData: [
            ...(otherData.filteredSentiments &&
            otherData.filteredSentiments.length
              ? otherData.filteredSentiments
              : []),
          ],
          selectedSubtopic: otherData.selectedSubtopic,
          setSelectedSubtopic: otherData.setSelectedSubtopic,
          selectedSubcluster: otherData.selectedSubcluster,
          setSelectedSubcluster: otherData.setSelectedSubcluster,
          filterReviewsHandler: otherData.filterReviewsHandler,
        },
        ...mappedProps,
      };
    case WIDGET_IDS.ratingAreaLineGraph:
      return {
        id: WIDGET_IDS.ratingAreaLineGraph,
        type: CONTAINERWIDTH,
        component: AREA_WITH_LINE_GRAPH,
        help: "Shows the volume of ratings over time broken down by star rating.",
        data: {
          title: "Star ratings over time",
          metrics: getStackedRatingsOverTimeReportData(
            {
              ...appData,
              filter: filter.filter,
            },
            defaultStore
          ),
          customYAxis: " ★ ",
        },
        ...mappedProps,
      };
    case WIDGET_IDS.ratingGraphReviews:
      return {
        id: WIDGET_IDS.ratingGraphReviews,
        type: CONTAINER_WIDTH_AND_HEIGHT,
        component: REVIEWS,
        data: {
          reviews: otherData.filteredReviews,
          height: "40rem",
          positive: otherData.positive,
          setPositive: otherData.setPositive,
          negative: otherData.negative,
          setNegative: otherData.setNegative,
          oneStar: otherData.oneStar,
          setOneStar: otherData.setOneStar,
          twoStar: otherData.twoStar,
          setTwoStar: otherData.setTwoStar,
          threeStar: otherData.threeStar,
          setThreeStar: otherData.setThreeStar,
          fourStar: otherData.fourStar,
          setFourStar: otherData.setFourStar,
          fiveStar: otherData.fiveStar,
          setFiveStar: otherData.setFiveStar,
          selectAll: otherData.selectAll,
          clearAll: otherData.clearAll,
          filteredReviews: otherData.filteredReviews2,
          setFilteredReviews: otherData.setFilteredReviews,
          reviewPoints: otherData.reviewPoints,
          clusterPoints: otherData.clusterPoints,
          itemOffset: otherData.itemOffset,
          setItemOffset: otherData.setItemOffset,
          currentItems: otherData.currentItems,
          itemsPerPage: otherData.itemsPerPage,
          store: defaultStore.map((i: GET_DATA_STORE) => i.store),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.analyticsRelevancy:
      return {
        id: uuidv4(),
        type: CONTAINERWIDTH,
        component: INDEPENDENT_LINE_GRAPH,
        data: {
          title: "Relevancy",
          metrics: otherData.reportData,
        },
        ...mappedProps,
      };

    // used to add some more features in the line graph for github and sof
    case WIDGET_IDS.analyticsRelevancyExtention:
      return {
        id: uuidv4(),
        type: CONTAINERWIDTH,
        component: INDEPENDENT_LINE_GRAPH_EXTENTION,
        data: {
          title: "Relevancy",
          metrics: otherData.reportData,
        },
        ...mappedProps,
      };
    case WIDGET_IDS.analyticsTopicDetail:
      return {
        id: uuidv4(),
        type: CONTAINERWIDTH,
        component: TOPIC_DETAILS,
        data: {
          title: "Topic",
          cluster: otherData.filteredSubcluster,
          subCluster: otherData.selectedSubcluster,
        },
        ...mappedProps,
      };
    case WIDGET_IDS.appFeedbackList:
      return {
        id: uuidv4(),
        type: CONTAINER_WIDTH_AND_HEIGHT,
        component: FEEDBACKS,
        data: otherData.filteredReviews,
        ...mappedProps,
      };
    case WIDGET_IDS.appFeedbackInsights:
      return {
        id: uuidv4(),
        type: CONTAINERWIDTH,
        component: INDEPENDENT_LINE_GRAPH,
        help: "Shows the volume of feedbacks on Rargus over time.",
        hidden: appData.appFeedback.length === 0,
        data: {
          title: "Feedback insights over time",
          metrics: getAdminFeedbackInsightsData({
            ...appData,
            filter: filter.filter,
          }),
        },
        ...mappedProps,
      };
    case WIDGET_IDS.appFeedbackRatings:
      return {
        id: uuidv4(),
        type: CONTAINERWIDTH,
        component: STAR_RATINGS,
        subComponent: SENTIMENTS_ALL,
        help: "The average star rating of all pieces of feedback for rargus over the time period specified in this dashboard.",
        hidden: appData.appFeedback.length === 0,
        data: {
          store: defaultStore,
        },
        rating: starRatingScore(filter.filter, appData.appFeedback),
        ...mappedProps,
      };
    default:
      return null;
  }
};

export const getWidgetObjects = (
  widgets: WidgetId[],
  appData: any,
  graphData: any,
  otherData: any
) => {
  let widgetObjects: Widget[] = [];

  if (widgets && widgets.length) {
    widgets.forEach((widget: WidgetId) => {
      let wObj: any = getWidgetObject(widget, appData, graphData, otherData);
      if (wObj) {
        widgetObjects.push({
          ...wObj,
          widgetId: widget.widgetId
            ? widget.widgetId
            : `${wObj.id}--${getUuid()}`,
        });
      }
    });
  }

  return widgetObjects;
};
