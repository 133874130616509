import { Box, Button, FormHelperText, MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import FlexBox from "../shared/wrappers/FlexBox";
import LightTextField from "../shared/wrappers/LightTextField";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph } from "../shared/wrappers/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ADMIN_MANAGEMENT_TYPE, STORE } from "../shared/constants";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import CSVIcon from "../shared/icons/csv.svg";
import AHAIcon from "../shared/icons/aha.svg";
import SOFIcon from "../shared/icons/sof.svg";
import SlackIcon from "../shared/icons/slack.svg";
import AmazonIcon from "../shared/icons/amazon.svg";
import GithubIcon from "../shared/icons/github.svg";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import { getDate } from "../ReviewComponents/Csv";
import { FormContext, FormContextAttr } from "./AdminBoard";
import { createOrEditOrganization } from "../../services/api/Apps.api";
import { ROLES } from "../Settings/AdminManagement";

const CreateOrganisation = () => {
  const { apps } = useContext(FormContext) as FormContextAttr;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues: {
    name: string;
    adminName: string;
    adminEmail: string;
    appID: string[];
  } = {
    name: "",
    adminName: "",
    adminEmail: "",
    appID: [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please provide a name for the organisation"),
    appID: Yup.array().required("Please select an app"),
    adminName: Yup.string().required("Please provide the name of the admin"),
    adminEmail: Yup.string()
      .email("Must be a valid email")
      .required("Please mention an admin"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (!loading) {
        setLoading(true);
        setError("");
      }
      // Write the logic to send the details to lambda and create the org and user (if required)
      try {
        const res = await createOrEditOrganization({
          admin: localStorage.getItem("username") || "",
          appID: JSON.stringify(values.appID),
          organization: values.name,
          name: values.adminName,
          email: values.adminEmail,
          role: ROLES.admin,
          type: ADMIN_MANAGEMENT_TYPE.create,
        });
        if (res && res.body) {
          const parsedBody = JSON.parse(res.body);

          // Check if error object exists in that
          if (parsedBody && parsedBody.error) {
            setError(parsedBody.error);
          }
        }
      } catch (e) {
        console.log(e);
        setError(`${e}`);
      }

      // Reset the values in the form fields
      handleReset({});
      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        maxHeight: "50vh",
        overflowY: "auto",
        padding: "0 0.5rem 0 0.5rem",
        "&::-webkit-scrollbar": {
          width: "0.4rem",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#A3A6AB",
          borderRadius: "5px",
        },
      }}
    >
      {/* <Box
        sx={{
          mt: 4,
          mb: 3,
          ml: 1,
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
        }}
      >
        To create a new Organisation with an admin, use the form below.
      </Box> */}
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            mt: 4,
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#0B0E1E",
            minHeight: "4rem",
            height: "fit-content",
            width: "100%",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              Organisation name
            </Paragraph>
            <LightTextField
              fullWidth
              name="name"
              type="name"
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              Admin Name
            </Paragraph>
            <LightTextField
              fullWidth
              name="adminName"
              type="adminName"
              onChange={handleChange}
              value={values.adminName || ""}
              onBlur={handleBlur}
              error={Boolean(touched.adminName && errors.adminName)}
              helperText={touched.adminName && errors.adminName}
            />
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              Admin Email
            </Paragraph>
            <LightTextField
              fullWidth
              name="adminEmail"
              type="adminEmail"
              onChange={handleChange}
              value={values.adminEmail || ""}
              error={Boolean(touched.adminEmail && errors.adminEmail)}
              helperText={touched.adminEmail && errors.adminEmail}
            />
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <FlexBox
              sx={{
                // justifyContent: "space-between",
                width: "100%",
                // alignItems: "center",
                padding: "0.5rem 0",
                flexDirection: "column",
              }}
            >
              <FlexBox
                sx={{
                  // justifyContent: "center",
                  marginBottom: "0.3rem",
                }}
              >
                <FlexBox
                  sx={{
                    color: "#3A3C40",
                    lineHeight: "17px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {"App (s)"}
                </FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  width: "100%",
                }}
              >
                <Select
                  fullWidth
                  labelId="appID"
                  id="appID"
                  name={"appID"}
                  value={values.appID}
                  multiple={true}
                  label=""
                  onChange={handleChange}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #E5EAF2",
                  }}
                  MenuProps={{
                    sx: {
                      zIndex: 99999999,
                      height: "18rem",
                      // maxHeight: "15rem",
                      // overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: "0.4rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#A3A6AB",
                        borderRadius: "5px",
                      },
                    },
                  }}
                >
                  {apps && apps.length !== 0 ? (
                    apps.map((item: any, index: number) => (
                      <MenuItem
                        value={item.appID}
                        key={`${item.appID}--menu-item-${index}`}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <FlexBox
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <FlexBox
                            sx={{
                              width: "80%",
                            }}
                          >
                            <FlexBox
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "10%",
                                marginRight: "1rem",
                              }}
                            >
                              <img
                                src={
                                  item.store && item.store === STORE.csv
                                    ? CSVIcon
                                    : item.store && item.store === STORE.slack
                                    ? SlackIcon
                                    : item.store && item.store === STORE.sof
                                    ? SOFIcon
                                    : item.store && item.store === STORE.aha
                                    ? AHAIcon
                                    : item.store && item.store === STORE.amazon
                                    ? AmazonIcon
                                    : item.store && item.store === STORE.github
                                    ? GithubIcon
                                    : item.installs
                                    ? item.installs !== "-1"
                                      ? PlayStoreIcon
                                      : AppleStoreIcon
                                    : AHAIcon
                                }
                                alt={"i"}
                                height={20}
                                width={20}
                              />
                            </FlexBox>
                            <FlexBox
                              sx={{
                                color: "#0B0E1E",
                                fontSize: "13px",
                                lineHeight: "16px",
                                fontStyle: "normal",
                                alignItems: "center",
                                width: "80%",
                              }}
                            >
                              {`${item.name || item.developer}`}
                            </FlexBox>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              width: "20%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {getDate(item.createdAt)}
                          </FlexBox>
                        </FlexBox>
                      </MenuItem>
                    ))
                  ) : (
                    <LoadingWrapper />
                  )}
                </Select>
              </FlexBox>
            </FlexBox>
          </FullTextFieldWrapper>
        </Box>

        {error && (
          <FormHelperText
            error
            sx={{
              mt: 2,
              fontSize: 13,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {error}
          </FormHelperText>
        )}

        <Box
          sx={{
            mt: 4,
            width: "100%",
            textAlign: "end",
          }}
        >
          <Button
            type="button"
            variant="outlined"
            sx={{
              border: "none",
              "&:hover": { border: "none" },
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </Box>
      </form>
    </FlexBox>
  );
};

export default CreateOrganisation;
