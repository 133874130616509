import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormContext, FormContextAttr } from "./AddAha";
import FlexBox from "../shared/wrappers/FlexBox";
import { useNavigate } from "react-router-dom";

const Basic = () => {
  const navigate = useNavigate();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext) as FormContextAttr;
  const initialValues: {
    name: string;
  } = { name: "", ...formData };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Application name is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        if (values.name !== "") {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Add your Aha! app
      </FlexBox>
      {/* <Box
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#0B0E1E",
          height: "fit-content",
          marginBottom: "30px",
        }}
      >
        Input details of your Aha! data source to add it.
      </Box> */}
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"App name"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"name"}
              type={"name"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name || ""}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <Small>Your application will be refered to with this name.</Small>
          </FullTextFieldWrapper>
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            <Button fullWidth type="submit" variant="contained">
              {"Continue"}
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => navigate("/dashboard/data-source")}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Basic;
