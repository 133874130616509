import { useContext } from "react";
import { FormContext, FormContextAttr } from "./AddAmazon";
import Config from "./Config";
import StartImport from "./StartImport";

const Step = ({ location }: { location: string | undefined }) => {
  const { activeStepIndex } = useContext(FormContext) as FormContextAttr;
  let stepContent = <></>;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Config />;
      break;
    case 1:
      stepContent = <StartImport />;
      break;
    default:
      <></>;
      break;
  }

  return stepContent;
};

export default Step;
