import FlexBox from "../shared/wrappers/FlexBox";
import UnfilledStar from "../shared/icons/unfilled-star.svg";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { STORE } from "../shared/constants";
import { getTotalNumberOfVotes } from "../Widgets/InnerComponents";
import { Filter } from "../shared/wrappers/FilterPopper";
import { ClusterData } from "../shared/types";

type ClusterProps = {
  clusterData: ClusterData[];
  selectedSubtopic: any;
  filterReviewsHandler: (reviews: any[]) => void;
  selectedSubcluster: any;
  setSelectedSubtopic: (value: any) => void;
  setSelectedSubcluster: (values: any) => void;
  type: string;
  setFilteredReviews?: (review: any[]) => void;
  topicSort?: Filter;
};

export const getSubClusterCount = (subClusters: any[]) => {
  let count = 0;

  subClusters.forEach((sub: any) => {
    count += sub.sentimentData.length;
  });
  return count;
};

export const getWorkspaceCount = (a: any) => {
  let workspacesInA: Filter[] = [];

  // Grouped A
  if (a.clusterGroup !== "0") {
    if (a.subClusters && a.subClusters.length) {
      a.subClusters.forEach((sub: any) => {
        if (sub.workspaces && sub.workspaces.length) {
          sub.workspaces.forEach((work: Filter) => {
            if (
              workspacesInA.findIndex(
                (fil: Filter) => fil.value === work.value
              ) === -1
            ) {
              workspacesInA.push(work);
            }
          });
        }
      });
    }
  }

  // Ungrouped A
  if (a.clusterGroup === "0") {
    if (a.workspaces && a.workspaces.length) {
      a.workspaces.forEach((work: Filter) => {
        if (
          workspacesInA.findIndex((fil: Filter) => fil.value === work.value) ===
          -1
        ) {
          workspacesInA.push(work);
        }
      });
    }
  }

  return workspacesInA;
};

const Cluster = ({
  clusterData,
  selectedSubtopic,
  filterReviewsHandler,
  setFilteredReviews,
  selectedSubcluster,
  setSelectedSubtopic,
  setSelectedSubcluster,
  type,
  topicSort,
}: ClusterProps) => {
  const appData: any = useSelector((state: RootState) => state.app);

  const getCountForAha = (subtopic: any, topicSort?: Filter) => {
    if (topicSort && topicSort.value) {
      if (topicSort.value === "numberOfVotes") {
        if (subtopic.clusterGroup !== "0") {
          return getTotalNumberOfVotes(subtopic, undefined);
        }

        if (subtopic.clusterGroup === "0") {
          return getTotalNumberOfVotes(subtopic, undefined);
        }
      }

      if (topicSort.value === "numberOfWorkspaces") {
        return getWorkspaceCount(subtopic).length;
      }
    }

    return 0;
  };

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        // Find a fix to this
        // height: "100%",
      }}
    >
      <FlexBox
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {type !== "Generic" && (
          <FlexBox
            sx={{
              marginRight: "0.3rem",
              color: "#3A3C40",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {type}
          </FlexBox>
        )}
        <FlexBox
          sx={{
            flexGrow: 1,
            height: "1px",
            background: "#E3E6EB",
          }}
        ></FlexBox>
      </FlexBox>
      {clusterData && clusterData.length !== 0
        ? clusterData.map((subtopic, index) => (
            <Fragment key={`subtopic-item-${index}-${type}`}>
              {subtopic.subClusters &&
              subtopic.subClusters.length &&
              subtopic.clusterGroup !== "0" ? (
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    cursor: "pointer",
                    //   marginBottom: "0.5rem",
                    ...(type === "Compliments"
                      ? {
                          "&:hover": {
                            background: "#CFE9E9",
                          },
                        }
                      : type === "Suggestions"
                      ? {
                          "&:hover": {
                            background: "#DDD5FF",
                          },
                        }
                      : type === "Complaints"
                      ? {
                          "&:hover": {
                            background: "#F4CFD9",
                          },
                        }
                      : type === "Generic"
                      ? subtopic.rating && subtopic.rating >= 2.5
                        ? {
                            "&:hover": {
                              background: "#AFDADB",
                            },
                          }
                        : subtopic.rating &&
                          subtopic.rating < 2.5 && {
                            "&:hover": {
                              background: "#FFC5C3",
                            },
                          }
                      : {}),
                    ...(selectedSubtopic &&
                    selectedSubtopic.id &&
                    selectedSubtopic.id === subtopic.id
                      ? type === "Compliments"
                        ? {
                            background: "#AFDADB",
                          }
                        : type === "Suggestions"
                        ? {
                            background: "#CCC1FF",
                          }
                        : type === "Complaints"
                        ? {
                            background: "#F4CFD9",
                          }
                        : type === "Generic"
                        ? subtopic.rating && subtopic.rating >= 2.5
                          ? {
                              background: "#AFDADB",
                            }
                          : subtopic.rating &&
                            subtopic.rating < 2.5 && {
                              background: "#FFC5C3",
                            }
                        : {}
                      : {}),
                  }}
                  key={`subtopic-item-${index}-${type}`}
                  onClick={() => {
                    setSelectedSubtopic({ ...subtopic, type });
                    setSelectedSubcluster(undefined);
                  }}
                >
                  <FlexBox
                    sx={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                      width: "80%",
                    }}
                  >
                    {type === "Generic" && (
                      <FlexBox
                        sx={{
                          background: "#E3E6EB",
                          borderRadius: "4px",
                          width: "3rem",
                          height: "20px",
                          padding: "1px 6px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "0.4rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {subtopic.rating}
                        <img
                          src={UnfilledStar}
                          alt="*"
                          width="9px"
                          height="9px"
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </FlexBox>
                    )}
                    {subtopic.groupTopic
                      ? subtopic.groupTopic.replace(/(^"|"|"|'|'|'$)/g, "")
                      : "Ungrouped Cluster"}
                  </FlexBox>
                  <FlexBox
                    sx={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                    }}
                  >
                    {appData.store && appData.store === STORE.aha
                      ? getCountForAha(subtopic, topicSort)
                      : getSubClusterCount(subtopic.subClusters)}
                  </FlexBox>
                </FlexBox>
              ) : (
                <></>
              )}
              {subtopic.clusterGroup === "0" ? (
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    cursor: "pointer",
                    //   marginBottom: "0.5rem",
                    ...(type === "Compliments"
                      ? {
                          "&:hover": {
                            background: "#CFE9E9",
                          },
                        }
                      : type === "Suggestions"
                      ? {
                          "&:hover": {
                            background: "#DDD5FF",
                          },
                        }
                      : type === "Complaints"
                      ? {
                          "&:hover": {
                            background: "#F4CFD9",
                          },
                        }
                      : type === "Generic"
                      ? subtopic.rating && subtopic.rating >= 2.5
                        ? {
                            "&:hover": {
                              background: "#AFDADB",
                            },
                          }
                        : subtopic.rating &&
                          subtopic.rating < 2.5 && {
                            "&:hover": {
                              background: "#FFC5C3",
                            },
                          }
                      : {}),
                    ...(selectedSubtopic &&
                    selectedSubtopic.id &&
                    selectedSubtopic.id === subtopic.id
                      ? type === "Compliments"
                        ? {
                            background: "#AFDADB",
                          }
                        : type === "Suggestions"
                        ? {
                            background: "#CCC1FF",
                          }
                        : type === "Complaints"
                        ? {
                            background: "#F4CFD9",
                          }
                        : type === "Generic"
                        ? subtopic.rating && subtopic.rating >= 2.5
                          ? {
                              background: "#AFDADB",
                            }
                          : subtopic.rating &&
                            subtopic.rating < 2.5 && {
                              background: "#FFC5C3",
                            }
                        : {}
                      : {}),
                  }}
                  key={`subtopic-item-${index}-${type}`}
                  onClick={() => {
                    setSelectedSubtopic({ ...subtopic, type });
                    setSelectedSubcluster(undefined);
                    if (
                      subtopic.sentimentData &&
                      subtopic.sentimentData.length
                    ) {
                      filterReviewsHandler(subtopic.sentimentData);
                    } else {
                      filterReviewsHandler(
                        subtopic.reviewId ? subtopic.reviewId : []
                      );
                      if (subtopic.reviewId && subtopic.reviewId.length === 0) {
                        setFilteredReviews && setFilteredReviews([]);
                      }
                    }
                  }}
                >
                  <FlexBox
                    sx={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                      width: "80%",
                    }}
                  >
                    {type === "Generic" && (
                      <FlexBox
                        sx={{
                          background: "#E3E6EB",
                          borderRadius: "4px",
                          width: "3rem",
                          height: "20px",
                          padding: "1px 6px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "0.4rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {subtopic.rating}
                        <img
                          src={UnfilledStar}
                          alt="*"
                          width="9px"
                          height="9px"
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </FlexBox>
                    )}
                    {subtopic.clusterTopics
                      ? subtopic.clusterTopics.replace(/(^"|"|"|'|'|'$)/g, "")
                      : `Ungrouped topic ${index + 1}`}
                  </FlexBox>
                  <FlexBox
                    sx={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: "#0B0E1E",
                    }}
                  >
                    {appData.store && appData.store === STORE.aha
                      ? getCountForAha(subtopic, topicSort)
                      : subtopic.sentimentData
                      ? subtopic.sentimentData.length
                      : subtopic.count}
                  </FlexBox>
                </FlexBox>
              ) : (
                <></>
              )}
            </Fragment>
          ))
        : clusterData.length === 0 && (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No topics in selected range
            </FlexBox>
          )}
    </FlexBox>
  );
};

export default Cluster;
