import { useContext } from "react";
import { FormContext, FormContextAttr, REPO } from "./AddGithub";
import PrivateRepoConfig from "./PrivateRepoConfig";
import PublicRepoConfig from "./PublicRepoConfig";
import RepoType from "./RepoType";
import SelectRepository from "./SelectRepository";
import StartImport from "./StartImport";
const Step = () => {
  const { activeStepIndex, repoType } = useContext(
    FormContext
  ) as FormContextAttr;
  let stepContent = <></>;
  if (repoType === REPO.withPAT) {
    switch (activeStepIndex) {
      case 0:
        stepContent = <RepoType />;
        break;
      case 1:
        stepContent = <PrivateRepoConfig />;
        break;
      case 2:
        stepContent = <SelectRepository />;
        break;
      case 3:
        stepContent = <StartImport />;
        break;
      default:
        <></>;
        break;
    }
  }

  if (repoType === REPO.withoutPAT) {
    switch (activeStepIndex) {
      case 0:
        stepContent = <RepoType />;
        break;
      case 1:
        stepContent = <PublicRepoConfig />;
        break;
      case 2:
        stepContent = <StartImport />;
        break;
      default:
        <></>;
        break;
    }
  }

  return stepContent;
};

export default Step;
