import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/store/store";
import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import "pure-react-carousel/dist/react-carousel.es.css";

// STARTS HERE
import * as AWS from "aws-sdk";
import { ConfigurationOptions } from "aws-sdk";

const configuration: ConfigurationOptions = {
  region: "us-east-1",
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
};

AWS.config.update(configuration);
// ENDS HERE

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
