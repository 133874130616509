import api from "./graphqlapi";

const getUserQuery = `
query MyQuery($input: ID!) {
    getUser(id: $input) {
      email
      name
      userAppId
      userWidgets
      isPremium
      userRights
    }
  }
`;

const deleteUserApp = `
mutation MyMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userAppId
      email
      name
      isPremium
      userRights
    }
  }
`;

const updateUserSchema = `
mutation MyMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userAppId
      email
      name
      userWidgets
      isPremium
      userRights
    }
  }
`;

const listUsersOfOrg = `
query MyQuery($input: String!) {
  listUsers(filter: {userRights: {contains: $input}}) {
    items {
      email
      isPremium
      name
      userAppId
      userRights
      userWidgets
    }
  }
}
`;

const getReviews = `
query MyQuery($input: ID!) {
  listReviews(filter: {appID: {eq: $input}}) {
    items {
      rating
      thumbsUpCount
      userImage
      userName
      content
      replyContent
    }
  }
}
`;

const getAppQuery = `
query MyQuery($input: ID!) {
  getApp(id: $input) {
    appID
    avgRating
    complaintStatus
    continousToken
    complementStatus
    createdAt
    description
    developer
    developerEmail
    developerId
    genreId
    genre
    icon
    id
    installs
    store
    name
    numberOfRating
    numberOfReviews
    releasedDate
    reviewsFetched
    sentimentStatus
    status
    suggestionStatus
    summary
    updatedAt
    url
  }
}
`;

const getNextApp = `
query MyQuery($input: Boolean, $input2: String) {
    listApps(filter: {incrementalFetch: {eq: $input}, complaintStatus: {eq: 7}, complementStatus: {eq: 7}, sentimentStatus: {eq: 7}, suggestionStatus: {eq: 7}},  nextToken: $input2) {
        items {
            appID
            avgRating
            description
            genre
            icon
            installs
            store
            numberOfRating
            numberOfReviews
            releasedDate
            version
            name
            id
            reviewsFetched
            status
            complementStatus
            complaintStatus
            suggestionStatus
            sentimentStatus
        }
    }
}
`;

const getComplaintsQuery = `
query MyQuery($input: ID!) {
  listComplaints(filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      groupTopic
      clusterLabel
      clusterPoints
      clusterTopics
      complaints
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const fetchNextComplaintsQuery = `
query MyQuery($input: ID!, $input2: String!) {
  listComplaints(nextToken: $input2, filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      clusterLabel
      groupTopic
      clusterPoints
      clusterTopics
      complaints
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const getComplimentsQuery = `
query MyQuery($input: ID!) {
  listCompliments(filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      clusterLabel
      groupTopic
      clusterPoints
      clusterTopics
      compliments
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const fetchNextComplimentsQuery = `
query MyQuery($input: ID!, $input2: String!) {
  listCompliments(nextToken: $input2, filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      clusterLabel
      groupTopic
      clusterPoints
      clusterTopics
      compliments
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const getSuggestionsQuery = `
query MyQuery($input: ID!) {
  listSuggestions(filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      clusterLabel
      groupTopic
      clusterPoints
      clusterTopics
      suggestions
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const fetchNextSuggestionsQuery = `
query MyQuery($input: ID!, $input2: String!) {
  listSuggestions(nextToken: $input2, filter: {appID: {eq: $input}}, limit: 1000) {
    nextToken
    items {
      appID
      clusterGroup
      clusterLabel
      groupTopic
      clusterPoints
      clusterTopics
      suggestions
      content
      createdAt
      date
      rating
      id
      reviewID
    }
  }
}
`;

const updateAppStatus = `
mutation MyMutation($input: UpdateAppInput!) {
  updateApp(input: $input) {
    appID
    status
    complaintStatus
    complementStatus
    sentimentStatus
    suggestionStatus
    reviewsFetched
  }
}
`;

const getSentiments = `
query MyQuery($input: ID!) {
  listSentiments(filter: {appID: {eq: $input}}, limit: 100) {
    items {
      appID
      content
      createdAt
      date
      id
      rating
      replyContent
      reviewID
      sentiment
      sentimentCategory
      sentimentScore
      updatedAt
      username
    }
    nextToken
  }
}
`;

const getSentimentsNextPage = `
query MyQuery($input: ID!, $input2: String!) {
  listSentiments(filter: {appID: {eq: $input}}, nextToken: $input2, limit: 100) {
    items {
      appID
      content
      createdAt
      date
      id
      rating
      replyContent
      reviewID
      sentiment
      sentimentCategory
      sentimentScore
      updatedAt
      username
    }
    nextToken
  }
}
`;

const getTrendingTopics = `
query MyQuery($input: ID!) {
  listTrendings(filter: {appID: {eq: $input}}) {
    items {
      appID
      clusterLabel
      clusterReviews
      clusterTopics
      content
      createdAt
      date
      id
      recencyScore
      reviewID
      type
      updatedAt
    }
    nextToken
  }
}
`;

const getNextTrendingTopics = `
query MyQuery($input: ID!, $input2: String!) {
  listTrendings(filter: {appID: {eq: $input}}, nextToken: $input2) {
    items {
      appID
      clusterLabel
      clusterReviews
      clusterTopics
      content
      createdAt
      date
      id
      recencyScore
      reviewID
      type
      updatedAt
    }
    nextToken
  }
}
`;

const listAllApps = `
query MyQuery {
  listApps(filter: {complaintStatus: {eq: 7}, complementStatus: {eq: 7}, sentimentStatus: {eq: 7}, suggestionStatus: {eq: 7}}) {
    items {
      appID
      avgRating
      complaintStatus
      complementStatus
      continousToken
      description
      developer
      developerEmail
      developerId
      icon
      id
      installs
      store
      name
      numberOfRating
      numberOfReviews
      releasedDate
      reviewsFetched
      sentimentStatus
      status
      suggestionStatus
      summary
      url
      genre
      genreId
    }
    nextToken
  }
}
`;

const getAppFeedbackList = `
query MyQuery {
  listAppFeedbacks {
    items {
      createdAt
      feedback
      id
      rating
      user
    }
    nextToken
  }
}
`;

const getNextAppFeedbackList = `
query MyQuery($input: String!) {
  listAppFeedbacks(nextToken: $input) {
    items {
      createdAt
      feedback
      id
      rating
      user
    }
    nextToken
  }
}
`;

const getOrganisations = `
query MyQuery($input: String!) {
  listOrganisations(filter: {name: {eq: $input}}) {
    items {
      admin
      appID
      name
      id
    }
    nextToken
  }
}
`;

const getNextOrganisations = `
query MyQuery($input: String!, $input2: String!) {
  listOrganisations(filter: {name: {eq: $input}}, nextToken: $input2) {
    items {
      admin
      appID
      name
      id
    }
    nextToken
  }
}
`;

export const getGraphDetails = (appId: string) =>
  api
    .post(``, { query: getAppQuery, variables: { input: appId } })
    .then(({ data }) => data.data);

export const getUserDetails = (user: string) =>
  api
    .post(``, { query: getUserQuery, variables: { input: user } })
    .then(({ data }) => data.data);

export const deleteUserAppDetails = (user: string) =>
  api
    .post("", {
      query: deleteUserApp,
      variables: { input: { id: user, userAppId: null } },
    })
    .then(({ data }) => data.data);

export const updateUserAppDetails = (user: string, userAppId: string) =>
  api
    .post("", {
      query: deleteUserApp,
      variables: { input: { id: user, userAppId: userAppId } },
    })
    .then(({ data }) => data.data);

export const ListUsersOfOrganization = (organization: string) =>
  api
    .post("", { query: listUsersOfOrg, variables: { input: organization } })
    .then(({ data }) => data.data);

export const getReviewsData = (appId: string) =>
  api
    .post("", { query: getReviews, variables: { input: appId } })
    .then(({ data }) => data.data);

export const getAppData = (appId: string) =>
  api
    .post("", { query: getAppQuery, variables: { input: appId } })
    .then(({ data }) => data.data);

export const getComplaintsData = (appId: string) =>
  api
    .post("", { query: getComplaintsQuery, variables: { input: appId } })
    .then(({ data }) => data.data);

export const fetchNextComplaintsData = (appId: string, nextToken: string) =>
  api
    .post("", {
      query: fetchNextComplaintsQuery,
      variables: { input: appId, input2: nextToken },
    })
    .then(({ data }) => data.data);

export const fetchNextComplimentsData = (appId: string, nextToken: string) =>
  api
    .post("", {
      query: fetchNextComplimentsQuery,
      variables: { input: appId, input2: nextToken },
    })
    .then(({ data }) => data.data);

export const fetchNextSuggestionsData = (appId: string, nextToken: string) =>
  api
    .post("", {
      query: fetchNextSuggestionsQuery,
      variables: { input: appId, input2: nextToken },
    })
    .then(({ data }) => data.data);

export const getComplimentsData = (appId: string) =>
  api
    .post("", { query: getComplimentsQuery, variables: { input: appId } })
    .then(({ data }) => data.data);

export const getSuggestionsData = (appId: string) =>
  api
    .post("", { query: getSuggestionsQuery, variables: { input: appId } })
    .then(({ data }) => data.data);

export const updateAppImportStatus = (
  appId: string,
  status: {
    status: number;
    complementStatus?: number;
    complaintStatus?: number;
    suggestionStatus?: number;
    sentimentStatus?: number;
    reviewsFetched?: number;
  }
) =>
  api
    .post("", {
      query: updateAppStatus,
      variables: {
        input: {
          id: appId,
          ...status,
        },
      },
    })
    .then(({ data }) => data.data);

export const fetchSentimentsData = (appId: string) =>
  api
    .post("", {
      query: getSentiments,
      variables: { input: appId },
    })
    .then(({ data }) => data.data);

export const fetchSentimentsNextPageData = (appId: string, nextToken: string) =>
  api
    .post("", {
      query: getSentimentsNextPage,
      variables: { input: appId, input2: nextToken },
    })
    .then(({ data }) => data.data);

export const getTrendingTopicsData = (appId: string) =>
  api
    .post("", { query: getTrendingTopics, variables: { input: appId } })
    .then(({ data }) => data.data);

export const getNextTrendingTopicsData = (appId: string, nextToken: string) =>
  api
    .post("", {
      query: getNextTrendingTopics,
      variables: { input: appId, input2: nextToken },
    })
    .then(({ data }) => data.data);

export const getAllApps = () =>
  api.post("", { query: listAllApps }).then(({ data }) => data.data);

export const listAddedApps = async () => {
  let appList: any[] = [];
  let nextToken = "";

  while (true) {
    let fetchQuery = listAllApps;
    let variables;
    if (nextToken !== "") {
      fetchQuery = getNextApp;
      variables = {
        input: true,
        input2: nextToken,
      };
    }

    const list: any = await api
      .post("", { query: fetchQuery, ...(variables ? { variables } : {}) })
      .then(({ data }) => data.data);
    if (list.listApps && list.listApps.items) {
      appList = appList.concat(list.listApps.items);
    }
    if (list.listApps && list.listApps.nextToken) {
      nextToken = list.listApps.nextToken;
    } else {
      break;
    }
  }

  return appList;
};

export const addAppToUser = (user: string, appID: string) =>
  api
    .post("", {
      query: deleteUserApp,
      variables: { input: { id: user, userAppId: appID } },
    })
    .then(({ data }) => data.data);

export const updateUserDetails = (userDetails: {
  id: string;
  userWidgets?: string;
  userRights?: string;
}) =>
  api
    .post("", {
      query: updateUserSchema,
      variables: { input: userDetails },
    })
    .then(({ data }) => data.data);

export const fetchAppFeedbackList = (appId: string, token?: string) =>
  api
    .post("", {
      query: getAppFeedbackList,
    })
    .then(({ data }) => data.data);

export const fetchNextAppFeedbackList = (appId: string, token?: string) =>
  api
    .post("", {
      query: getNextAppFeedbackList,
      variables: { input: token },
    })
    .then(({ data }) => data.data);

export const fetchOrganisationsList = (name: string, token?: string) =>
  api
    .post("", {
      query: getOrganisations,
      variables: { input: name },
    })
    .then(({ data }) => data.data);

export const fetchNextOrganisationsList = (name: string, token?: string) =>
  api
    .post("", {
      query: getNextOrganisations,
      variables: { input: name, input2: token },
    })
    .then(({ data }) => data.data);
