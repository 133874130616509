import axios from "axios";
import { fetchAllApps, getDbData } from "../api/Apps.api";

// const config = {
//   region: process.env.REACT_APP_APP_REGION,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
// };

// const docClient = new AWS.DynamoDB.DocumentClient(config);

// Fetch data from db directly from different schemas
// export const fetchDBData2 = (
//   tableName: string,
//   appId: string,
//   callback: (items: any[]) => void
// ) => {
//   var params = {
//     AWS_SDK_LOAD_CONFIG: 1,
//     TableName: tableName,
//     IndexName: "byAppID",
//     KeyConditionExpression: `appID = :appID`,
//     ExpressionAttributeValues: {
//       ":appID": appId,
//     },
//   };

//   let items: any[] = [];
//   try {
//     docClient.query(params, function (err, data) {
//       if (!err) {
//         if (data && data.Items) {
//           items = data.Items;
//           callback(data.Items);
//         }
//       }
//       if (err) {
//         console.log("error", err);
//         callback([]);
//         return [];
//       }
//     });
//   } catch (e) {
//     console.log("Exception", e);
//     callback([]);
//   }

//   return items;
// };

// export const fetchDBData = (
//   tableName: string,
//   appId: string,
//   callback: (items: any[]) => void
// ) => {
//   var params: any = {
//     AWS_SDK_LOAD_CONFIG: 1,
//     TableName: tableName,
//     // Changing GSI to one with sort key on date filter
//     // IndexName: "byAppID",
//     IndexName: "byAppIdSortDate",
//     KeyConditionExpression: `appID = :appID`,
//     // true -> ascending, false -> descending
//     ScanIndexForward: false,
//     ExpressionAttributeValues: {
//       ":appID": appId,
//     },
//   };
//   let fullResult: {
//     Items: any[];
//     Count: number;
//     ScannedCount: number;
//   } = { Items: [], Count: 0, ScannedCount: 0 };

//   const queryExecute = (callback: (items: any[]) => void, count: number) => {
//     docClient.query(params, (err, result) => {
//       if (err) {
//         callback([]);
//         return;
//       }

//       const { Items, LastEvaluatedKey, Count, ScannedCount } = result;
//       let newCount = count;

//       if (Items && Items.length) {
//         fullResult.Items = [...fullResult.Items, ...Items];

//         // if (
//         //   fullResult.Items.length / 3000 > 0 &&
//         //   Math.floor(fullResult.Items.length / 3000) >
//         //     Math.floor(newCount / 3000)
//         // ) {
//         //   callback(fullResult.Items);
//         //   newCount = fullResult.Items.length;
//         // }
//       }
//       if (Count) {
//         fullResult.Count += Count;
//       }
//       if (ScannedCount) {
//         fullResult.ScannedCount += ScannedCount;
//       }

//       if (!LastEvaluatedKey) {
//         // console.log(fullResult.Count);
//         callback(fullResult.Items);
//         return;
//       }

//       params.ExclusiveStartKey = LastEvaluatedKey;
//       queryExecute(callback, newCount);
//     });
//   };

//   queryExecute(callback, 0);
// };

// export const fetchAppData = (
//   tableName: string,
//   callback: (items: any[]) => void
// ) => {
//   var params: any = {
//     AWS_SDK_LOAD_CONFIG: 1,
//     TableName: tableName,
//     // Changing GSI to one with sort key on date filter
//     // IndexName: "byAppID",
//     IndexName: "byAppIdSortDate",
//     KeyConditionExpression: `complaintStatus = :com AND complementStatus = :comp`,
//     // true -> ascending, false -> descending
//     ScanIndexForward: false,
//     ExpressionAttributeValues: {
//       ":com": 7,
//       ":comp": 7,
//     },
//   };
//   let fullResult: {
//     Items: any[];
//     Count: number;
//     ScannedCount: number;
//   } = { Items: [], Count: 0, ScannedCount: 0 };

//   const queryExecute = (callback: (items: any[]) => void, count: number) => {
//     docClient.query(params, (err, result) => {
//       if (err) {
//         callback([]);
//         return;
//       }

//       const { Items, LastEvaluatedKey, Count, ScannedCount } = result;
//       let newCount = count;

//       if (Items && Items.length) {
//         fullResult.Items = [...fullResult.Items, ...Items];

//         // if (
//         //   fullResult.Items.length / 3000 > 0 &&
//         //   Math.floor(fullResult.Items.length / 3000) >
//         //     Math.floor(newCount / 3000)
//         // ) {
//         //   callback(fullResult.Items);
//         //   newCount = fullResult.Items.length;
//         // }
//       }
//       if (Count) {
//         fullResult.Count += Count;
//       }
//       if (ScannedCount) {
//         fullResult.ScannedCount += ScannedCount;
//       }

//       if (!LastEvaluatedKey) {
//         // console.log(fullResult.Count);
//         callback(fullResult.Items);
//         return;
//       }

//       params.ExclusiveStartKey = LastEvaluatedKey;
//       queryExecute(callback, newCount);
//     });
//   };

//   queryExecute(callback, 0);
// };

// export const fetchAllApps = async () => {
//   try {
//     var params = {
//       TableName: "App-pehdb5dpwvea3ayhwqqtsn3b6u-staging",
//     };
//     var result = await docClient.scan(params).promise();
//     if (result && result.Items) {
//       return result.Items;
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

export const fetchAllAppsFromDb = async () => {
  try {
    const response = await fetchAllApps();
    if (response.statusCode !== 200) {
      throw new Error(`HTTP error! Status: ${response.statusCode}`);
    }

    const data = await response.body;
    return JSON.parse(data);
    // Process the data as needed
    // ...
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

export const fetchTheDBData = async (tableName: string, appId: string) => {
  try {
    const dbParams = {
      appID: appId,
      fetch: tableName,
      action: "GET",
    };
    // Make a request to the Api to generate the presigned url
    // By sending those bucket parameters
    const presignedUrl = await getDbData(dbParams);

    // Parse the JSON string to access the downloadURL property
    const parsedBody = JSON.parse(presignedUrl.body);
    const actualPresignedUrl = parsedBody.downloadURL;

    // Make an HTTP request to the presigned URL to get the S3 object
    const response = await axios.get(actualPresignedUrl);

    if (response.status === 200) {
      if (response.data) {
        // send the data of the response to access those data.
        const data = response.data;
        return data;
      } else {
        // throw new Error("Readable stream is null");
      }
    } else {
      // throw new Error(`Failed to fetch S3 object. Status: ${response.status}`);
    }
  } catch (err) {
    // throw new Error(`Error occurred: ${err}`);
  }
};
