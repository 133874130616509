import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { Fragment, useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  allowedZaps,
  getWithExpiry,
  importPageExtention,
  setUserDataAndRole,
} from "../shared/constants";
import { AddFromSettingsProps } from "../AddApp/AddApp";
import axios from "axios";
import ZapierEditorModal from "../shared/wrappers/ZapierEditorModal";
import { SocialIconButton } from "../shared/wrappers/StyledComponents";
import ZapierIcon from "../shared/icons/zapier.svg";

export type Template = {
  id: number;
  steps: {
    id: number;
    uuid: string;
    title: string;
    slug: string;
    description: string;
    image: string;
    hex_color: string;
    images: {
      url_16x16: string;
      url_32x32: string;
      url_64x64: string;
      url_128x128: string;
    };
    api: string;
    url: string;
    label: string;
  }[];
  title: string;
  slug: string;
  status: string;
  description_plain: string;
  description_raw: string;
  url: string;
  description: string;
  create_url: string;
  type: string;
};

const ZapierPartnerAPI = ({ location }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  const [tokenExists, setTokenExists] = useState(false);

  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  // Use the token to fetch the list of zap templates
  useEffect(() => {
    const func = async () => {
      const token = getWithExpiry("zapier-token");
      if (token && token.length) {
        setTokenExists(true);
        try {
          const response: any = await axios.get(
            `https://api.zapier.com/v1/zap-templates?client_id=${process.env.REACT_APP_ZAPIER_CLIENT_ID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response && response.data && response.data.length) {
            setTemplates(response.data);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        setTokenExists(false);
      }
    };

    func();
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      const email = localStorage.getItem("username") || appData.user.email;
      const res: any = await getUserDetails(email);
      if (res && res.getUser && res.getUser.userAppId) {
        let appIDs = Array.isArray(JSON.parse(res.getUser.userAppId))
          ? JSON.stringify(JSON.parse(res.getUser.userAppId))
          : JSON.stringify([JSON.parse(res.getUser.userAppId)]);
        localStorage.setItem("appId", appIDs);
        if (JSON.parse(appIDs) && JSON.parse(appIDs).length) {
          console.log("Navigate Now to import wait");
          if (!location) {
            navigate(`/dashboard/${importPageExtention}`);
          }

          // Need to identify when to take the user to the import page
          // if (location && onAdd) {
          //   onAdd();
          // }
          clearInterval(interval);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUrl = () => {
    // Based on the template, add the necessary steps string
    if (selectedTemplate && selectedTemplate.create_url) {
      if (selectedTemplate.id === 1499686) {
        let url = selectedTemplate.create_url;
        url +=
          "?steps[0][app]=SlackAPI&steps[0][action]=channel_message&steps[0][params][listen_for_bots]=false&steps[1][app]=RargusCLIAPI@latest&steps[1][action]=analyseCreate";
        return url;
      }
      return selectedTemplate.create_url;
    }
    return "";
  };

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        if (res.getUser.userAppId) {
          localStorage.setItem(
            "appId",
            Array.isArray(JSON.parse(res.getUser.userAppId))
              ? JSON.stringify(JSON.parse(res.getUser.userAppId))
              : JSON.stringify([JSON.parse(res.getUser.userAppId)])
          );
          console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  // TODO: Same as in AddZapierTemplate
  // Use effect to check app import status and redirect user directly
  // useEffect(() => {
  //   const checkAppData = async (appData: any) => {
  //     // Check if all app data has success status...
  //     // If yes, navigate to feedback

  //     const res: any = await getAppDetails(appData, dispatch);
  //     if (res) {
  //       if (
  //         res.length !== 0 &&
  //         res.filter((val: { store: string; data: any }) => {
  //           return checkAllStatusFlags(val.data, [7], 4);
  //         }).length === 0
  //       ) {
  //         // The app data is succesfully processed
  //         navigate("/dashboard/insights/feedback");
  //         // To reload the page and fetch the newly added app
  //         if (location && location === "settings") {
  //           window.location &&
  //             window.location.reload &&
  //             window.location.reload();
  //         }
  //       }
  //     }
  //   };

  //   checkAppData(appData);
  //   const interval = setInterval(() => {
  //     checkAppData(appData);
  //   }, 10000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Fragment>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
        }}
      >
        <FlexBox
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: "1rem",
            top: "0.3rem",
          }}
        >
          <img
            src={RargusIcon}
            alt="Rargus Icon"
            width="38"
            height="38"
            style={{
              marginRight: "0.5rem",
            }}
          />
          <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
        </FlexBox>
        <Card
          sx={{
            padding: 4,
            ...(width < 800
              ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
              : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",

            height: "fit-content",
          }}
        >
          <FlexBox
            flexDirection="column"
            justifyContent="center"
            sx={{
              height: "100%",
            }}
          >
            <FlexBox
              style={{
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38px",
                color: "#3579E3",
                fontFamily: "Barlow",
              }}
            >
              Zapier Partner API
            </FlexBox>
            {tokenExists ? (
              <Fragment>
                <Box
                  sx={{
                    mt: 3,
                    ml: 1,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#0B0E1E",
                  }}
                >
                  Please select a zap template to initiate the data import
                  process from your desired app.
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    ml: 1,
                    pr: 1,
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#0B0E1E",
                    maxHeight: "35vh",
                    flexDirection: "column",
                    // height: "fit-content",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.4rem",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#A3A6AB",
                      borderRadius: "5px",
                    },
                  }}
                >
                  {templates && templates.length ? (
                    templates
                      .filter((template: Template) =>
                        allowedZaps.includes(template.id)
                      )
                      .map((template: Template, index: number) => (
                        <FlexBox
                          key={`template-zapier-${index}-${template.id}`}
                          sx={{
                            padding: "15px 15px 15px 20px",
                            borderRadius: "4px",
                            border: "1px solid #82868C",
                            marginBottom: "0.4rem",
                            cursor: "pointer",
                            ":hover": {
                              color: "#FFF",
                              background: "#82868C",
                            },
                          }}
                          onClick={() => setSelectedTemplate(template)}
                        >
                          {template.title}
                        </FlexBox>
                      ))
                  ) : (
                    <FlexBox
                      sx={{
                        fontSize: "14px",
                        lineHeight: "17px",
                        color: "#3A3C40",
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      No zap templates found!
                    </FlexBox>
                  )}
                </Box>
              </Fragment>
            ) : (
              <FlexBox
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SocialIconButton
                  onClick={() => {
                    window.location.href = `https://zapier.com/oauth/authorize?client_id=${process.env.REACT_APP_ZAPIER_CLIENT_ID}&redirect_uri=https://www.rargusapp.com/dashboard/zapier-redirect&response_type=token`;
                  }}
                  startIcon={
                    <img src={ZapierIcon} alt="Zapier Icon" width={"73"} />
                  }
                >
                  Sign in with Zapier
                </SocialIconButton>
              </FlexBox>
            )}
            <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
              <Button
                type="button"
                variant="outlined"
                sx={{
                  fontWeight: 500,
                  border: "none",
                  "&:hover": { border: "none", background: "#FFF" },
                }}
                onClick={() => {
                  navigate(`/dashboard/zapier-template`);
                }}
              >
                {"Back"}
              </Button>
            </Box>
          </FlexBox>
        </Card>
      </FlexBox>
      {selectedTemplate && selectedTemplate.id ? (
        <ZapierEditorModal
          onClose={() => {
            setSelectedTemplate(undefined);
          }}
          onSave={() => {
            // Do things with the template and then set it to undefined
            setSelectedTemplate(undefined);
          }}
          url={getUrl()}
          show={selectedTemplate && selectedTemplate.id ? true : false}
          setShow={(value: boolean) => {
            if (!value) {
              setSelectedTemplate(undefined);
            }
          }}
        ></ZapierEditorModal>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default ZapierPartnerAPI;
