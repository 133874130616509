import { Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import IdleImportIcon from "../shared/icons/idle-import.svg";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../services/store/store";
import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import { getAppDetails, SETTINGS_PAGE } from "../shared/constants";
import { FormContext, FormContextAttr } from "./AddGithub";

const StartImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);

  const { location, onBack } = useContext(FormContext) as FormContextAttr;

  // Use effect to check app import status and redirect user directly
  useEffect(() => {
    const checkAppData = async (appData: any) => {
      // Check if all app data has success status...
      // If yes, navigate to feedback

      const res: any = await getAppDetails(appData, dispatch);
      if (res) {
        if (
          res.length !== 0 &&
          res.filter((val: { store: string; data: any }) => {
            return checkAllStatusFlags(val.data, [7], 4);
          }).length === 0
        ) {
          // The app data is succesfully processed
          // TODO: Only one mail should go to user, hence some uniform logic needs to be present for this.
          // TODO: Check if the date of import is same as current date. If yes, send mail from here.
          // Otherwise wait for betch job to send the mail
          // await sendEmailToUser([localStorage.getItem("username") || ""]);
          navigate("/dashboard/insights/feedback");
          // To reload the page and fetch the newly added app
          // if (location && location === "settings") {
          //   window.location &&
          //     window.location.reload &&
          //     window.location.reload();
          // }
        }
      }
    };

    checkAppData(appData);
    const interval = setInterval(() => {
      checkAppData(appData);
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox flexDirection="column" justifyContent="center">
        <Box
          sx={{
            mt: 2,
            ml: 1,
            mb: 2,
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#0B0E1E",
            height: "fit-content",
          }}
        >
          Importing the data will take a couple of minutes. We will send you an
          email when we’re done.
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <img
            src={IdleImportIcon}
            alt="Idle import"
            width="364"
            height="191"
          />
        </Box>
        {location && location === SETTINGS_PAGE ? (
          <Box sx={{ mt: 1, justifyContent: "center" }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => {
                if (location && onBack) {
                  onBack();
                } else {
                  navigate(`/dashboard/home`);
                }
              }}
            >
              Back to settings
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default StartImport;
