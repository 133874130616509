import { useLayoutEffect, useState, useRef } from "react";
import { truncateFromEnd } from "../shared/constants";
import useElementWidth from "../../services/hooks/useElementWidth";
import useFitCharacterNumber from "../../services/hooks/useFitCharacterNumber";
import FlexBox from "../shared/wrappers/FlexBox";
import StarCompliment from "../shared/icons/star-blue.svg";
import StarComplaint from "../shared/icons/star-pink.svg";
import StarSuggestion from "../shared/icons/star-purple.svg";
import { COMPLIMENTS, COMPLAINTS, SUGGESTIONS } from "../shared/constants";
import Tooltip from "../shared/wrappers/Tooltip";
import { getTotalNumberOfVotes } from "./InnerComponents";
import DislikeIcon from "../shared/icons/dislike.svg";

type CommentsWidgetProps = {
  data: {
    avgRating: number;
    clusterTopics: string;
    groupTopic: string;
  };
  index: number;
  subComponent: string | undefined;
  hideRating?: boolean;
};

const CommentsWidget = ({
  data,
  index,
  subComponent,
  hideRating,
}: CommentsWidgetProps) => {
  const btnRef = useRef(null);
  const [buttonText, setButtonText] = useState(
    truncateFromEnd(data.groupTopic, 70)
  );
  const buttonWidth = useElementWidth(btnRef);
  const { textWidth, charNumber } = useFitCharacterNumber({
    ref: btnRef,
    maxWidth: buttonWidth,
    middleChars: "...",
  });
  useLayoutEffect(() => {
    if (btnRef && btnRef.current) {
      if (buttonWidth && charNumber && textWidth) {
        if (textWidth > buttonWidth)
          setButtonText(truncateFromEnd(data.groupTopic, charNumber));
      }
    }
  }, [buttonWidth, charNumber, data.groupTopic, textWidth]);

  const getAverageRating = (data: any) => {
    let rating = 0;
    let count = 0;

    if (data.clusterGroup !== "0") {
      data.subClusters.forEach((sub: any) => {
        rating += sub.rating;
        count++;
      });
    }

    if (
      data.clusterGroup === "0" &&
      data.sentimentData &&
      data.sentimentData.length
    ) {
      data.sentimentData.forEach((item: any) => {
        rating += item.rating;
        count++;
      });
    }

    rating = Number((rating / (count ? count : 1)).toFixed(1));

    return rating;
  };

  return (
    <FlexBox
      sx={{
        marginTop: "0.35rem",
      }}
      key={`${index}--comments-widget`}
    >
      {!hideRating && getAverageRating(data) !== 0.0 ? (
        <FlexBox
          sx={{
            background: "#E3E6EB",
            borderRadius: "4px",
            width: "3rem",
            padding: "1px 6px",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.4rem",
          }}
        >
          {getAverageRating(data).toFixed(1)}{" "}
          <img
            src={
              subComponent
                ? subComponent === COMPLIMENTS
                  ? StarCompliment
                  : subComponent === COMPLAINTS
                  ? StarComplaint
                  : subComponent === SUGGESTIONS
                  ? StarSuggestion
                  : StarCompliment
                : StarCompliment
            }
            alt="*"
            width="9px"
            height="9px"
            style={{
              marginLeft: "5px",
            }}
          />
        </FlexBox>
      ) : (
        <FlexBox
          sx={{
            background: "#E3E6EB",
            borderRadius: "4px",
            width: "3rem",
            padding: "1px 6px",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "0.4rem",
          }}
        >
          {getTotalNumberOfVotes(data, undefined)}{" "}
          <img
            src={DislikeIcon}
            alt="*"
            width="12px"
            height="12px"
            style={{
              marginLeft: "5px",
              transform: "scale(-1)",
            }}
          />
        </FlexBox>
      )}

      {data.groupTopic.length > 70 ? (
        <Tooltip
          title={data.groupTopic.replace(/(^"|"|"$)/g, "")}
          placement="top"
        >
          <div
            style={{
              cursor: "pointer",
              width: "100%",
            }}
            ref={btnRef}
          >
            {`${buttonText.replace(/(^"|"|"|'|'|'$)/g, "")}`}
          </div>
        </Tooltip>
      ) : (
        <FlexBox>{buttonText.replace(/(^"|"|"|'|'|'$)/g, "")}</FlexBox>
      )}
    </FlexBox>
  );
};

export default CommentsWidget;
