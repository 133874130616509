import FlexBox from "../shared/wrappers/FlexBox";
import UnfilledStarIcon from "../shared/icons/unfilled-star.svg";
import FilledStarIcon from "../shared/icons/filled-star.svg";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import {
  checkNoneList,
  distictArray,
  keywordOrSplit,
  months,
  STORE,
} from "../shared/constants";
import Highlighter from "react-highlight-words";

type ReviewProp = {
  review: Review;
  index: number;
  clusterPoints?: ReviewPoint[];
  reviewPoints?: ReviewPoint[];
  highlightKeywords?: string[];
  store?: string[];
};

const ReviewComponent = ({
  review,
  index,
  clusterPoints,
  reviewPoints,
  highlightKeywords,
}: ReviewProp) => {
  const getDate = (dateStr: string) => {
    const dateObj = new Date(dateStr);

    return `${
      months[dateObj.getMonth()]
    } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  };
  let reviewId = review.reviewID.split("-")[0] || `Review ${index + 1}`;

  if (
    review &&
    review.store &&
    review.store === STORE.slack &&
    reviewId.includes(keywordOrSplit)
  ) {
    reviewId = reviewId.split(keywordOrSplit)[1];
  }

  let clusteredPoints: ReviewPoint[] = [];
  if (clusterPoints && clusterPoints.length) {
    clusteredPoints = clusterPoints.filter(
      (rev: ReviewPoint) => review.reviewID === rev.reviewId
    );
    clusteredPoints = clusteredPoints.map((point: ReviewPoint) => ({
      ...point,
      point: point && point.point ? point.point.replace("-", "") : "",
    }));
  }

  let reviewedPoints: ReviewPoint[] = [];
  if (reviewPoints && reviewPoints.length) {
    reviewedPoints = reviewPoints.filter(
      (rev: ReviewPoint) =>
        review.reviewID === rev.reviewId && !checkNoneList(rev.point)
    );
    reviewedPoints = reviewedPoints.map((point: ReviewPoint) => ({
      ...point,
      point: point && point.point ? point.point.replace("-", "") : "",
    }));
  }

  // Getting the distict array for fail-safe
  reviewedPoints = distictArray(reviewedPoints, "point");

  return (
    <FlexBox
      sx={{
        marginTop: "4px",
        marginBottom: "16px",
        background: "#F5F5F5",
        height: "fit-content",
        padding: "20px",
        flexDirection: "column",
        boxShadow: "0px 2px 4px rgba(130, 134, 140, 0.16)",
        borderRadius: "8px",
        position: "relative",
      }}
      key={`reviews-${index}`}
    >
      <FlexBox
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              flexDirection: "column",
              textAlign: "left",
              alignItems: "left",
              paddingLeft: "10px",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#3A3C40",
              }}
            >
              {reviewId}
            </FlexBox>
            <FlexBox
              sx={{
                marginTop: "4px",
              }}
            >
              <FlexBox
                sx={{
                  marginTop: "0.1rem",
                }}
              >
                {[1, 2, 3, 4, 5].map((r: number) => {
                  if (r <= review.rating) {
                    return (
                      <img
                        src={FilledStarIcon}
                        alt="Star"
                        width="12px"
                        height="12px"
                        style={{ marginRight: "2px" }}
                        key={`filled-star-${r}`}
                      />
                    );
                  }
                  return (
                    <img
                      src={UnfilledStarIcon}
                      alt="Star"
                      width="12px"
                      height="12px"
                      style={{ marginRight: "2px" }}
                      key={`unfilled-star-${r}`}
                    />
                  );
                })}
              </FlexBox>
              <FlexBox
                sx={{
                  fontSize: "10px",
                  lineHeight: "14px",
                  marginLeft: "0.5rem",
                  ...(review.sentimentScore !== undefined &&
                  parseFloat(review.sentimentScore) >= 0
                    ? { color: "#418F90" }
                    : { color: "#DB4E82" }),
                }}
              >
                {review.sentimentScore !== undefined &&
                parseFloat(review.sentimentScore) > 0.1
                  ? "Positive "
                  : review.sentimentScore !== undefined &&
                    parseFloat(review.sentimentScore) < -0.1 &&
                    "Negative "}
                {/* {parseFloat(review.sentimentScore)} */}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox
          sx={{
            textAlign: "right",
          }}
        >
          <FlexBox
            sx={{
              color: "#82868C",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {review.username && review.username.length > 30
              ? `${review.username.slice(0, 30)}...`
              : review.username}
          </FlexBox>
          <FlexBox
            sx={{
              color: "#0B0E1E",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
              marginLeft: "0.5rem",
            }}
          >
            {getDate(review.date)}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {/* Main Points Section */}
      {(clusteredPoints && clusteredPoints.length !== 0) ||
      (reviewedPoints && reviewedPoints.length !== 0) ? (
        <FlexBox
          sx={{
            marginTop: "0.8rem",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FlexBox
              sx={{
                marginRight: "0.3rem",
                color: "#3A3C40",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              {"Main points"}
            </FlexBox>
            <FlexBox
              sx={{
                flexGrow: 1,
                height: "1px",
                background: "#E3E6EB",
              }}
            ></FlexBox>
          </FlexBox>

          <FlexBox
            sx={{
              flexDirection: "column",
              marginLeft: "1rem",
              marginTop: "0.3rem",
              color: "#000000",
            }}
          >
            {reviewedPoints.length !== 0
              ? reviewedPoints
                  // Remove any blank points
                  .filter((item: ReviewPoint) => item.point !== "")
                  .map((point: ReviewPoint, index: number) => (
                    <FlexBox
                      key={`idea-point-${index}-${point}`}
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                        display: "list-item",
                        listStyleType: "disc",
                        ...(clusteredPoints.filter((clus: ReviewPoint) => {
                          return (
                            point.point
                              .toLowerCase()
                              // replace special characters and comma
                              .replace(/[^A-Za-z ]*/, "")
                              .replace(",", "")
                              .replace(/['"]+/g, "")
                              .replaceAll("-", "")
                              .replaceAll(":", "")
                              .includes(
                                clus.point
                                  // Replacing un-required characters in the cluster point
                                  .replaceAll("-", "")
                                  .replace(/(^"|"|"$)/g, "")
                                  .trim()
                                  .toLowerCase()
                                  // replace
                                  .replace(/[^A-Za-z ]*/, "")
                                  .replace(",", "")
                                  .replace(/['"]+/g, "")
                                  .replaceAll(":", "")
                              )
                          );
                        }).length !== 0
                          ? { fontWeight: 700 }
                          : {}),
                      }}
                    >
                      {point.point}
                    </FlexBox>
                  ))
              : clusteredPoints.map((point: ReviewPoint, index: number) => (
                  <FlexBox
                    key={`idea-point-${index}-${point}`}
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      display: "list-item",
                      listStyleType: "disc",
                      fontWeight: 700,
                    }}
                  >
                    {point.point}
                  </FlexBox>
                ))}
          </FlexBox>
        </FlexBox>
      ) : (
        <></>
      )}
      <FlexBox
        sx={{
          // minHeight: "40px",
          height: "fit-content",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "21px",
          color: "#000000",
          marginTop: "15px",
          marginBottom: "15px",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {/* {review.content && review.content.length > 200 ? (
          <TooltipComponent title={review.content} placement="top">
            <div>{`${review.content.slice(0, 200)}...`}</div>
          </TooltipComponent>
        ) : (
          review.content
        )} */}
        <Highlighter
          searchWords={highlightKeywords ? highlightKeywords : []}
          // searchWords={[]}
          autoEscape={true}
          textToHighlight={review.content}
        />
      </FlexBox>
      {review.replyContent && review.replyContent.length ? (
        <FlexBox
          sx={{
            marginLeft: "0.3rem",
            marginRight: "0.3rem",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#82868C",
            }}
          >
            Developer replied
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              color: "#000000",
              marginTop: "4px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {/* {review.replyContent && review.replyContent.length > 100 ? (
              <TooltipComponent title={review.replyContent} placement="top">
                <div>{`${review.replyContent.slice(0, 100)}...`}</div>
              </TooltipComponent>
            ) : (
              review.replyContent
            )} */}
            {review.replyContent}
          </FlexBox>
        </FlexBox>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default ReviewComponent;
