import { useContext } from "react";
import { FormContext, FormContextAttr } from "./AddAha";
import Basic from "./Basic";
import Config from "./Config";
import MapAttributes from "./MapAttributes";
import Products from "./SelectProducts";
import StartImport from "./StartImport";
const Step = () => {
  const { activeStepIndex } = useContext(FormContext) as FormContextAttr;
  let stepContent = <></>;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Basic />;
      break;
    case 1:
      stepContent = <Config />;
      break;
    case 2:
      stepContent = <Products />;
      break;
    case 3:
      stepContent = <MapAttributes />;
      break;
    case 4:
      stepContent = <StartImport />;
      break;
    default:
      <></>;
      break;
  }

  return stepContent;
};

export default Step;
