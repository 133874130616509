import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import {
  getWithExpiry,
  setWithExpiry,
} from "../../components/shared/constants";
import UserPool from "../../services/UserPool";

export const removeUserTokens = () => {
  localStorage.clear();
  window.location.href = "/login";
};

let cognitoUserDefault: CognitoUser;

export const rememberDevice = (user: any) => {
  // const local = JSON.parse(localStorage.getItem("cognitoUser") || "{}");
  // const cognitoUser = cognitoUserDefault || local;
  user.setDeviceStatusRemembered({
    onSuccess: function (result: any) {
      console.log("call result: " + result);
    },
    onFailure: function (err: any) {
      alert(err.message || JSON.stringify(err));
    },
  });
};

export const doNotRememberDevice = () => {
  const local = JSON.parse(localStorage.getItem("cognitoUser") || "{}");
  const cognitoUser = cognitoUserDefault || local;
  cognitoUser.setDeviceStatusNotRemembered({
    onSuccess: function (result) {
      console.log("call result: " + result);
    },
    onFailure: function (err) {
      alert(err.message || JSON.stringify(err));
    },
  });
};

export const sendVerificationCode = (
  Username: string,
  callback: () => void
) => {
  const cognitoUser = new CognitoUser({
    Username,
    Pool: UserPool,
  });

  if (cognitoUser) {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log("CodeDeliveryData from forgotPassword: " + data);
        callback();
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  }
};

export const changeForgotPassword = (
  Username: string,
  verificationCode: string,
  newPassword: string,
  callback: () => void,
  errorCallback: () => void
) => {
  const cognitoUser = new CognitoUser({
    Username,
    Pool: UserPool,
  });
  if (cognitoUser) {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        console.log("Password confirmed!");
        callback();
      },
      onFailure(err) {
        console.log("Password not confirmed!");
        errorCallback();
      },
    });
  }
};

export const changePassword = async (
  email: string,
  oldPassword: string,
  newPassword: string,
  callback: ({
    error,
    result,
  }: {
    error: string | null;
    result: string | null;
  }) => void
) => {
  let cognitoUser = cognitoUserDefault;
  // Getting the cognito user object using the email and password provided during password reset
  if (!cognitoUser) {
    await authenticate(email, oldPassword, false);
    cognitoUser = cognitoUserDefault;
  }
  cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
    if (err) {
      callback({
        error: err.message || JSON.stringify(err),
        result: null,
      });
      return;
    }
    callback({
      error: null,
      result: "success",
    });
  });
};

export const getUserAttributes = async (
  callback: ({ email, name }: { email: string; name: string }) => void
) => {
  const local = JSON.parse(localStorage.getItem("cognitoUser") || "{}");
  const cognitoUser = cognitoUserDefault || local;
  try {
    if (cognitoUser) {
      return await cognitoUser.getUserAttributes(function (
        err: any,
        result: any
      ) {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          return {
            email: localStorage.getItem("username") || "",
            name: localStorage.getItem("name") || "",
          };
        }
        if (result) {
          let email = result.filter((res: any) => res.getName() === "email");
          let name = result.filter((res: any) => res.getName() === "name");

          callback({
            email: email && email.length ? email[0].getValue() : "",
            name: name && name.length ? name[0].getValue() : "",
          });
        }
      });
    } else {
      if (local) {
        callback({
          email: localStorage.getItem("username") || "",
          name: localStorage.getItem("name") || "",
        });
      }
    }
  } catch (e) {
    if (local) {
      callback({
        email: "",
        name: "",
      });
    }
  }
};

export const getSession = async (callback: () => void, user?: CognitoUser) => {
  await new Promise((resolve, reject) => {
    const local = JSON.parse(localStorage.getItem("cognitoUser") || "{}");
    const cognitoUser = cognitoUserDefault || local;
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          removeUserTokens();
          reject(err);
        } else {
          localStorage.setItem("Session", JSON.stringify(session));
          resolve(session);
          callback();
        }
      });
    } else {
      reject();
    }
  });
};

// Authenticate the user with the SAML identity provider
// const authenticateUser = (username: string, password: string) => {
//   const authenticationData = {
//     Username: username,
//     Password: password,
//   };

//   const authenticationDetails = new AuthenticationDetails(authenticationData);

//   const userData = {
//     Username: username,
//     Pool: UserPool,
//   };

//   const cognitoUser = new CognitoUser(userData);

//   cognitoUser.authenticateUser(authenticationDetails, {
//     onSuccess: (session) => {
//       // User is authenticated, you can now use the session
//       console.log("Authentication successful", session);
//     },
//     onFailure: (error) => {
//       console.error("Authentication failed", error);
//     },
//   });
// };

// Authenticate users with the normal username and password flow
export const authenticate = async (
  Username: string,
  Password: string,
  Remember: boolean
) => {
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });

    cognitoUserDefault = user;
    localStorage.setItem("cognitoUser", JSON.stringify(user));

    user.authenticateUser(authDetails, {
      onSuccess: (result: any) => {
        if (result && result && result.idToken) {
          setWithExpiry("token", result.idToken.jwtToken, 1);
          setWithExpiry("idToken", result.idToken.jwtToken);
          setWithExpiry("refreshToken", result.refreshToken.token, 30);
          // localStorage.setItem("token", result.idToken.jwtToken);
          // localStorage.setItem("idToken", result.accessToken.jwtToken);
          // localStorage.setItem("refreshToken", result.refreshToken.token);
          if (Remember) {
            setWithExpiry("rememberMe", 1, 30);
          }
          getSession(() => {}, user);
        }
        resolve(result);
      },
      onFailure: (err) => {
        console.log("login failure", err);
        if (getWithExpiry("token")) {
          localStorage.removeItem("token");
        }
        reject(err);
      },
      newPasswordRequired: (data) => {
        console.log("new password required", data);
        if (getWithExpiry("token")) {
          localStorage.removeItem("token");
        }
        resolve(data);
      },
    });
  });
};

export const getUserDetails = (Username: string) => {
  const cognitoUser = new CognitoUser({
    Username,
    Pool: UserPool,
  });

  return cognitoUser.getUserData(
    function (err, userData) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      console.log("User data for user " + userData);
    },
    { bypassCache: true }
  );
};

export const refreshToken = async (username: string, refreshToken: any) => {
  console.log(username, refreshToken);
  const cognitoUser =
    cognitoUserDefault ||
    new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

  const token = cognitoUser.refreshSession(refreshToken, (err, session) => {
    if (err) {
      console.log(err);
      removeUserTokens();
    }

    console.log(session);
  });

  console.log("NEW TOKEN ACCOUNT", token);
  return token;
};

export const logout = (callback: () => void) => {
  const user: CognitoUser | null = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  localStorage.clear();
  callback();
};
