import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { STORE } from "../shared/constants";
import {
  CheckboxFilterPopover,
  Filter,
  FilterAdder,
  TextFilterPopover,
} from "../shared/wrappers/FilterPopper";
import FlexBox from "../shared/wrappers/FlexBox";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import CsvReviewComponent from "./Csv";
import IdeaComponent from "./Idea";
import ReviewComponent from "./Review";
import { Fragment, useEffect, useState } from "react";
import ReviewFilters from "./ReviewFilters";
import "../Analytics/pagination.css";
import ReactPaginate from "react-paginate";
import SOFReviewComponent from "./SOF";
import AmazonReviewComponent from "./Amazon";
import GithubReviewComponent from "./Github";

type OpenFilters = {
  insights: boolean;
  workspace: boolean;
  keywords: boolean;
  status: boolean;
  csvKeywords: boolean;
};

type ReviewColumnProps = {
  filteredReviews: Review[];
  reviewSort: Filter;
  setReviewSort: (value: Filter) => void;
  reviewPoints: ReviewPoint[];
  clusterPoints: ReviewPoint[];
  getHighlightKeywords: (keywords: Filter[]) => string[];
  keywords: Filter[];
  selectedDataSourceFilter: Filter[];
  selectedReviewFilters: Filter[];
  selectedReviewFilter: Filter[];
  setSelectedReviewFilter: (filters: Filter[]) => void;
  openFilters: OpenFilters;
  setOpenFilters: (value: OpenFilters) => void;
  reviewFilters: Filter[];
  reviewFilter: Filter[];
  setSelectedReviewFilters: (value: Filter[]) => void;
  filteredSentiments: Review[];
};

const ReviewColumn = ({
  filteredReviews,
  reviewSort,
  setReviewSort,
  reviewPoints,
  clusterPoints,
  getHighlightKeywords,
  keywords,
  selectedDataSourceFilter,
  selectedReviewFilters,
  selectedReviewFilter,
  setSelectedReviewFilter,
  openFilters,
  setOpenFilters,
  reviewFilters,
  reviewFilter,
  setSelectedReviewFilters,
  filteredSentiments,
}: ReviewColumnProps) => {
  const appData: any = useSelector((state: RootState) => state.app);
  const itemsPerPage = 100;

  // Review Filter States
  const [reviewSelectFilters, setReviewSelectFilters] = useState({
    positive: true,
    negative: true,
    one: true,
    two: true,
    three: true,
    four: true,
    five: true,
  });

  const setReviewFilterValue = (value: boolean, type: string) => {
    setReviewSelectFilters({
      ...reviewSelectFilters,
      [type]: value,
    });
  };

  const [reviews, setReviews] = useState(filteredReviews);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews.length / itemsPerPage);

  useEffect(() => {
    if (reviews && reviews.length) {
      let maxOffset = reviews.length / 100;
      if (itemOffset > maxOffset) {
        setItemOffset(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);

  useEffect(() => {
    setReviews(filteredReviews);
  }, [filteredReviews]);

  const totalItems = reviews.length;

  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.slack))
    ) {
      if (filteredReviews && filteredReviews.length) {
        let filteredData: any[] = [...filteredReviews];

        if (!reviewSelectFilters.positive) {
          filteredData = filteredData.filter(
            (data) =>
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) <= 0.1
          );
        }
        if (!reviewSelectFilters.negative) {
          filteredData = filteredData.filter(
            (data) =>
              data.sentimentScore !== undefined &&
              parseFloat(data.sentimentScore) >= -0.1
          );
        }
        if (!reviewSelectFilters.one) {
          filteredData = filteredData.filter((data) => data.rating !== 1);
        }
        if (!reviewSelectFilters.two) {
          filteredData = filteredData.filter((data) => data.rating !== 2);
        }
        if (!reviewSelectFilters.three) {
          filteredData = filteredData.filter((data) => data.rating !== 3);
        }
        if (!reviewSelectFilters.four) {
          filteredData = filteredData.filter((data) => data.rating !== 4);
        }
        if (!reviewSelectFilters.five) {
          filteredData = filteredData.filter((data) => data.rating !== 5);
        }

        setReviews(filteredData);
      } else {
        setReviews([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewSelectFilters, filteredReviews]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredReviews.length;
    setItemOffset(newOffset);
  };

  return (
    <Fragment>
      {appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.slack)) ? (
        <ReviewFilters
          positive={reviewSelectFilters.positive}
          negative={reviewSelectFilters.negative}
          setPositive={(value: boolean) =>
            setReviewFilterValue(value, "positive")
          }
          setNegative={(value: boolean) =>
            setReviewFilterValue(value, "negative")
          }
          oneStar={reviewSelectFilters.one}
          twoStar={reviewSelectFilters.two}
          threeStar={reviewSelectFilters.three}
          fourStar={reviewSelectFilters.four}
          fiveStar={reviewSelectFilters.five}
          setOneStar={(value: boolean) => setReviewFilterValue(value, "one")}
          setTwoStar={(value: boolean) => setReviewFilterValue(value, "two")}
          setThreeStar={(value: boolean) =>
            setReviewFilterValue(value, "three")
          }
          setFiveStar={(value: boolean) => setReviewFilterValue(value, "five")}
          setFourStar={(value: boolean) => setReviewFilterValue(value, "four")}
          selectAll={() =>
            setReviewSelectFilters({
              positive: true,
              negative: true,
              one: true,
              two: true,
              three: true,
              four: true,
              five: true,
            })
          }
          clearAll={() =>
            setReviewSelectFilters({
              positive: false,
              negative: false,
              one: false,
              two: false,
              three: false,
              four: false,
              five: false,
            })
          }
        />
      ) : (
        appData.store &&
        appData.store.length &&
        (appData.store.includes(STORE.aha) ||
          appData.store.includes(STORE.github)) && (
          <FlexBox
            sx={{
              flexWrap: "wrap",
              // marginTop: "0.25rem",
            }}
          >
            {selectedReviewFilters &&
            selectedReviewFilters.length &&
            selectedReviewFilters.findIndex(
              (fil: Filter) => fil.value === "status"
            ) !== -1 ? (
              <FlexBox
                sx={{
                  marginBottom: "1rem",
                  flexDirection: "column",
                  marginRight: "0.8rem",
                }}
              >
                <CheckboxFilterPopover
                  selectedFilter={selectedReviewFilter}
                  setSelectedFilter={(
                    filter: { label: string; value: string }[]
                  ) => setSelectedReviewFilter(filter)}
                  filterOptions={reviewFilter}
                  width={"13rem"}
                  label={<FlexBox>{"Status"}</FlexBox>}
                  removeFilter={() =>
                    setSelectedReviewFilters(
                      selectedReviewFilters.filter(
                        (fil: Filter) => fil.value !== "status"
                      )
                    )
                  }
                  openP={openFilters.status}
                  setOpenP={(value: boolean) =>
                    setOpenFilters({ ...openFilters, status: value })
                  }
                  popOffset={3.45}
                />
              </FlexBox>
            ) : (
              <></>
            )}

            <FlexBox
              sx={{
                marginRight: "0.8rem",
                marginBottom: "1rem",
              }}
            >
              <FilterAdder
                selectedFilter={selectedReviewFilters}
                setSelectedFilter={(filter: Filter[]) =>
                  setSelectedReviewFilters(filter)
                }
                filterOptions={reviewFilters}
                width={"7.8rem"}
                label={<FlexBox>{"Add filter"}</FlexBox>}
                disableSelectAll={true}
                handleFilter={(type: string) =>
                  setTimeout(() => {
                    setOpenFilters({ ...openFilters, [type]: true });
                  }, 50)
                }
              />
            </FlexBox>
          </FlexBox>
        )
      )}
      <FlexBox
        sx={{
          flexDirection: "column",
          height: "100%",
          maxHeight: "inherit",
          // display: "table",
          width: "100%",
        }}
      >
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <FlexBox
            sx={{
              fontSize: "15px",
              lineHeight: "18px",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#3A3C40",
              marginBottom: "0.8rem",
              marginLeft: "0.4rem",
            }}
          >
            {` ${totalItems} out of ${
              filteredSentiments ? filteredSentiments.length : 0
            } ${
              appData.store &&
              appData.store.length &&
              appData.store.includes(STORE.aha)
                ? `${totalItems > 1 ? "Ideas" : "Idea"}`
                : appData.store &&
                  appData.store.length &&
                  appData.store.includes(STORE.sof)
                ? `${filteredSentiments.length > 1 ? "Questions" : "Question"}`
                : `${filteredSentiments.length > 1 ? "Reviews" : "Review"}`
            }`}
          </FlexBox>
          {appData.store &&
          appData.store.length &&
          appData.store.includes(STORE.aha) ? (
            <FlexBox
              sx={{
                alignItems: "center",
                marginBottom: "0.8rem",
              }}
            >
              <TextFilterPopover
                selectedFilter={reviewSort}
                setSelectedFilter={setReviewSort}
                filterOptions={[
                  {
                    label: "most votes",
                    value: "mostVotes",
                  },
                  {
                    label: "least votes",
                    value: "leastVotes",
                  },
                ]}
              />
            </FlexBox>
          ) : (
            <></>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            width: "100%",
            justifyContent: "flex-start",
          }}
          key={`react-paginate-${filteredReviews.length}-reviews`}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            // forcePage={gotTo === "" ? undefined : parseInt(gotTo) - 1}
          />
        </FlexBox>
        <FlexBox
          sx={{
            flexDirection: "column",
            height: "100%",
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "0.3rem 0.2rem",
            display: "inline-block",
            maxHeight: "inherit",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A3A6AB",
              borderRadius: "5px",
            },
          }}
          key={`review-component-${filteredReviews.length}-${currentItems.length}`}
        >
          {currentItems && currentItems.length ? (
            currentItems
              .sort((a: any, b: any) => {
                if (reviewSort.value === "mostVotes") {
                  return b.rating - a.rating;
                }

                return a.rating - b.rating;
              })
              .map((review, index) =>
                // show idea component when the store is not apple or google
                review.store && review.store === STORE.aha ? (
                  <IdeaComponent
                    review={review}
                    index={index}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    key={`review-component-${index}`}
                  />
                ) : review.store && review.store === STORE.csv ? (
                  <CsvReviewComponent
                    review={review}
                    index={index}
                    key={`csv-review-component-${index}`}
                    clusterPoints={clusterPoints}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    store={selectedDataSourceFilter.map((item) => item.value)}
                  />
                ) : review.store && review.store === STORE.sof ? (
                  <SOFReviewComponent
                    review={review}
                    index={index}
                    key={`sof-review-component-${index}`}
                    clusterPoints={clusterPoints}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    store={selectedDataSourceFilter.map((item) => item.value)}
                  />
                ) : review.store && review.store === STORE.amazon ? (
                  <AmazonReviewComponent
                    review={review}
                    index={index}
                    key={`amazon-review-component-${index}`}
                    clusterPoints={clusterPoints}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    store={selectedDataSourceFilter.map((item) => item.value)}
                  />
                ) : review.store && review.store === STORE.github ? (
                  <GithubReviewComponent
                    review={review}
                    index={index}
                    key={`github-review-component-${index}`}
                    clusterPoints={clusterPoints}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    store={selectedDataSourceFilter.map((item) => item.value)}
                  />
                ) : (
                  <ReviewComponent
                    review={review}
                    index={index}
                    key={`review-component-${index}`}
                    clusterPoints={clusterPoints}
                    reviewPoints={reviewPoints}
                    highlightKeywords={getHighlightKeywords(keywords)}
                    store={selectedDataSourceFilter.map((item) => item.value)}
                  />
                )
              )
          ) : (
            <FlexBox
              sx={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#3A3C40",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No reviews in selected range
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export default ReviewColumn;
