import { Box, Button, Card } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayoutWrapper from "./AuthLayoutWrapper";
import ProviderRedirectImage from "../shared/images/provider-redirect.png";

const ProviderRedirect = () => {
  const navigate = useNavigate();

  return (
    <AuthLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
        }}
      >
        <Card
          sx={{
            padding: 4,
            maxWidth: 600,
            minWidth: 400,
            border: "none",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <FlexBox
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 50,
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={ProviderRedirectImage}
                alt="Provider Redirect"
                width="119"
                height="101"
              />
            </FlexBox>
            <FlexBox
              sx={{
                fontSize: "68px",
                fontWeight: 700,
                lineHeight: "82px",
                color: "#3579E3",
                fontFamily: "Barlow",
                alignItems: "center",
                justifyContent: "center",
                width: "680px",
                height: "164px",
                fontStyle: "bold",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              {"Thanks for your interest in Rargus"}
            </FlexBox>
            <FlexBox
              sx={{ padding: "0 1.7rem 0 1.7rem", flexDirection: "column" }}
            >
              <Fragment>
                <FlexBox
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "40px",
                    maxWidth: "440px",
                    fontFamily: "Barlow",
                    alignItems: "center",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#0B0E1E",
                  }}
                >
                  {
                    "We are currently in beta for Facebook and Google sign ups. If you sign up with your corporate email, you can have immediate access."
                  }
                </FlexBox>
                <Box sx={{ marginBottom: "10px" }}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => navigate("/register")}
                  >
                    {"Back to Sign up"}
                  </Button>
                </Box>
              </Fragment>
            </FlexBox>
          </FlexBox>
        </Card>{" "}
      </FlexBox>
    </AuthLayoutWrapper>
  );
};

export default ProviderRedirect;
