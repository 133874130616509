import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import {
  // getAppDetails,
  getHashedAPIKey,
  importPageExtention,
  setUserDataAndRole,
} from "../shared/constants";
import CopyToClipboardButton from "../shared/wrappers/CopyToClipboard";
import { AddFromSettingsProps } from "../AddApp/AddApp";
// import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import utf8 from "utf8";

export const ZapierIntegrationSteps = [
  "Login to zapier at https://zapier.com/app/dashboard and signin through your preferred method",
  'On the Dashboard page, you can find the button "+ Create Zap". Click on it to create a new zap.',
  'In the search bar, type "slack" and select the Slack app from among the options below.',
  'In the "Event" input box, choose "New Message Posted to Channel" and click on "Continue".',
  "Under Account, follow the nevessary steps to connect your slack account to Zapier. Once done, you'll be able to access it throughout the application.",
  'Under Trigger, choose the channel you wish to import the data from, and set the "Trigger for Bot Messages?" to No(Default value). Click on continue.',
  'Under Test, click on the button "Test trigger" to test Zapier\'s connection with your Slack account. You should be able to see one of the most recent messages of your selected channel. Click on Continue.',
  "Under Action, search for Rargus and choose the first one in the options.",
  'Under Event, choose the event "Create Analysis" and click on Continue.',
  "Under Account, you should be able to see the option to connect your Rargus account. A pop-up will appear once you initiate the connection.",
  'Click on the "Generate API Key" button on the rargusapp.com/dashboard/zapier-info to generate your API Key and provide the same on the pop-up on Zapier. Click on Continue',
  "Under Actions, map the attributes to the correct fields in slack. For the Source Type, select slack from the drop-down. For Created at, select a timestamp attribute from the dropdown. Map the text field to the Input Text field and the username of the user to the Username field. You may leave the rating field blank. Click on continue.",
  'Click on "Test action" to test the entire flow.',
  'On successful flow, you\'ll the option to "Publish" you zap. Click on it.',
  'After the Zap is published, you\'ll see a pop-up to "Transfer existing data". Click on it.',
  'On the next page, in the records shown, there\'ll be a checkbox to select the records to trasfer into your integration. You can click on individual records to choose from all records, or you could click on the top-most checkbox and click on "select all" to choose all the records. For better results, choose more than 200 records.',
  'If you have more than 100 records in your selected channel, and wish to import all data, click on the "Upgrade plan" on top and proceed through to remove any scraping limits on your account.',
  'Click on "Next" at the bottom.',
  'On the next page, click on "Send data" to start the transfer.',
  "The data import has started. Once the process is done, you'll get a notification on the email registered with your Rargus account.",
];

const ZapierInfo = ({ location }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  const [token, setToken] = useState("");

  // const secretPass = "328b7422-0908-11ee-82a2-4c796e8ce448";

  const encryptData = () => {
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(
    //     `${localStorage.getItem("token")}##$##${localStorage.getItem(
    //       "idToken"
    //     )}`
    //   ),
    //   secretPass
    // ).toString();
    setToken(btoa(utf8.encode(getHashedAPIKey())));
  };

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        if (res.getUser.userAppId) {
          localStorage.setItem(
            "appId",
            Array.isArray(JSON.parse(res.getUser.userAppId))
              ? JSON.stringify(JSON.parse(res.getUser.userAppId))
              : JSON.stringify([JSON.parse(res.getUser.userAppId)])
          );
          console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  // TODO: Same as in AddZapierTemplate
  useEffect(() => {
    let interval = setInterval(async () => {
      const email = localStorage.getItem("username") || appData.user.email;
      const res: any = await getUserDetails(email);
      if (res && res.getUser && res.getUser.userAppId) {
        let appIDs = Array.isArray(JSON.parse(res.getUser.userAppId))
          ? JSON.stringify(JSON.parse(res.getUser.userAppId))
          : JSON.stringify([JSON.parse(res.getUser.userAppId)]);
        localStorage.setItem("appId", appIDs);
        if (JSON.parse(appIDs) && JSON.parse(appIDs).length) {
          console.log("Navigate Now to import wait");
          if (!location) {
            navigate(`/dashboard/${importPageExtention}`);
          }

          // Need to identify when to take the user to the import page
          // if (location && onAdd) {
          //   onAdd();
          // }
          clearInterval(interval);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use effect to check app import status and redirect user directly
  // useEffect(() => {
  //   const checkAppData = async (appData: any) => {
  //     // Check if all app data has success status...
  //     // If yes, navigate to feedback

  //     const res: any = await getAppDetails(appData, dispatch);
  //     if (res) {
  //       if (
  //         res.length !== 0 &&
  //         res.filter((val: { store: string; data: any }) => {
  //           return checkAllStatusFlags(val.data, [7], 4);
  //         }).length === 0
  //       ) {
  //         // The app data is succesfully processed
  //         navigate("/dashboard/insights/feedback");
  //         // To reload the page and fetch the newly added app
  //         if (location && location === "settings") {
  //           window.location &&
  //             window.location.reload &&
  //             window.location.reload();
  //         }
  //       }
  //     }
  //   };

  //   checkAppData(appData);
  //   const interval = setInterval(() => {
  //     checkAppData(appData);
  //   }, 10000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",

          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Zapier Integration Info
          </FlexBox>
          <Box
            sx={{
              mt: 3,
              ml: 1,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
            }}
          >
            Please follow the following steps to start a manual trigger of data
            from a source into Rargus through Zapier.
          </Box>
          <Box
            sx={{
              mt: 1,
              ml: 1,
              pr: 1,
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#0B0E1E",
              maxHeight: "35vh",
              // height: "fit-content",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            <ol>
              {ZapierIntegrationSteps.map((item: string, index: number) => (
                <li key={`zapier-info-${index}-${item}`}>{item}</li>
              ))}
            </ol>
          </Box>

          <FlexBox
            sx={{
              marginTop: "1.2rem",
              width: "100%",
            }}
          >
            {token === "" ? (
              <Button
                fullWidth
                type="button"
                variant="contained"
                // sx={{
                //   border: "none",
                //   padding: 0,
                //   "&:hover": { border: "none", background: "#FFF" },
                //   "&:disabled": {
                //     border: "none",
                //   },
                // }}
                onClick={encryptData}
              >
                Generate API Key
              </Button>
            ) : (
              <FlexBox
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <FlexBox
                  sx={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    background: "#82868C",
                    color: "#FFF",
                    fontStyle: "italic",
                    width: "85%",
                    alignItems: "center",
                  }}
                >
                  {token && token.length > 500
                    ? `${token.slice(0, 50)}...`
                    : token}
                </FlexBox>
                <FlexBox
                  sx={{
                    width: "10%",
                  }}
                >
                  <CopyToClipboardButton text={token} />
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
          {/* <Box sx={{ mt: 4 }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => navigate("/dashboard/data-source")}
            >
              Next
            </Button>
          </Box> */}
          <Box sx={{ mt: 1, justifyContent: "center", textAlign: "center" }}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                fontWeight: 500,
                border: "none",
                "&:hover": { border: "none", background: "#FFF" },
              }}
              onClick={() => {
                navigate(`/dashboard/zapier-template`);
              }}
            >
              {"Back"}
            </Button>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default ZapierInfo;
