import {
  Box,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import { Fragment, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import {
  ADMIN_MANAGEMENT_TYPE,
  checkIfUserInOrg,
  DATA_BY_STORE,
  getAdminUserRoleAccess,
  STORE,
} from "../shared/constants";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import DeleteIcon from "../shared/icons/delete.svg";
import TooltipComponent from "../shared/wrappers/Tooltip";
import { addAppToUser } from "../../services/api/Graphql.api";
import DeleteModal from "../shared/wrappers/DeleteModal";
import { useNavigate } from "react-router";
import { COMBINED_DATA_SOURCE } from "../shared/wrappers/WidgetSettingsModal";
import { checkAllStatusFlags } from "../home/DashboardLayoutWrapper";
import { GraphActions } from "../../services/store/GraphStore/GraphSlice";
import CSVIcon from "../shared/icons/csv.svg";
import AHAIcon from "../shared/icons/aha.svg";
import SOFIcon from "../shared/icons/sof.svg";
import SlackIcon from "../shared/icons/slack.svg";
import AmazonIcon from "../shared/icons/amazon.svg";
import GithubIcon from "../shared/icons/github.svg";
import { createOrEditOrganization } from "../../services/api/Apps.api";

type DataSourceProps = {
  selectedDataSource: string | undefined;
  setSelectedDataSource: (value: string) => void;
};

const DataSources = ({ setSelectedDataSource }: DataSourceProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appData: any = useSelector((state: RootState) => state.app);

  const [disableSource, setDisableSource] = useState(true);

  // State to control the disabling of Next button
  const [disableNext, setDisableNext] = useState(true);
  // State to control the Delete modal
  const [showDeleteModal, setShowDeleteModal] = useState<{
    appID: string;
    title: string;
    store: string;
  }>();

  // Error State
  const [error, setError] = useState("");

  // App list
  const [apps, setApps] = useState(
    appData.app && appData.app.length ? appData.app : []
  );

  // Data source state
  const [dataSource, setDataSource] = useState(STORE["apple-play-store"]);

  const [inportLoading, setImportLoading] = useState(false);

  useEffect(() => {
    if (appData.app && appData.app.length) {
      setApps(appData.app);

      const appStatusCheck = appData.app.filter((app: any) =>
        checkAllStatusFlags(app.data, [7], 4)
      ).length;
      if (inportLoading && appStatusCheck === 0) {
        // Import successful for all apps after getting unsucessfult before
        console.log("Loading Page");
        window.location && window.location.reload && window.location.reload();
      }

      if (!inportLoading && appStatusCheck !== 0) {
        setImportLoading(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.app]);

  // Use effect to control the Add data sources button
  useEffect(() => {
    if (appData.user && appData.app && appData.app.length) {
      const checkNewDataSourceAddition = async () => {
        // Check if the current user belongs to an organization
        const isPartOfOrg = await checkIfUserInOrg(appData.user);
        if (isPartOfOrg) {
          if (appData.app.length < 20) {
            setDisableSource(false);
          } else if (appData.app.length >= 20) {
            setDisableSource(true);
          }
        } // Check if user exists in the premium list
        else if (appData.user.isPremium) {
          // Setting limit of max of two data sources at any time
          if (appData.user.isPremium === "two" && appData.app.length < 6) {
            setDisableSource(false);
          } else if (
            appData.user.isPremium === "two" &&
            appData.app.length >= 5
          ) {
            setDisableSource(true);
          }

          // Unlimited data sources plan
          if (appData.user.isPremium === "infinite") {
            setDisableSource(false);
          }
        }
      };

      checkNewDataSourceAddition();
    }
  }, [appData.user, appData.app]);

  const showApp = (app: any, store: string, index: number) => {
    if (store !== "") {
      return (
        <FlexBox
          sx={{
            width: "100%",
            padding: "0.5rem 0.5rem 0 0.5rem",
            height: "fit-content",
            border: "1px solid black",
            borderRadius: "8px",
            marginBottom: "1rem",
          }}
          key={`app-name-${index}-${store}-${app.appID}`}
        >
          <FlexBox
            sx={{
              width: "100%",
              marginBottom: "0.5rem",
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              sx={{
                width: "85%",
                alignItems: "center",
              }}
            >
              {(app.installs || localStorage.getItem("store") || app.store) && (
                <LazyLoadImage
                  alt={"Store"}
                  src={
                    app.store && app.store === STORE.csv
                      ? CSVIcon
                      : app.store && app.store === STORE.slack
                      ? SlackIcon
                      : app.store && app.store === STORE.sof
                      ? SOFIcon
                      : app.store && app.store === STORE.aha
                      ? AHAIcon
                      : app.store && app.store === STORE.amazon
                      ? AmazonIcon
                      : app.store && app.store === STORE.github
                      ? GithubIcon
                      : app.installs
                      ? app.installs !== "-1"
                        ? PlayStoreIcon
                        : AppleStoreIcon
                      : AHAIcon
                  }
                  width={"14"}
                  style={{ marginRight: "0.5rem" }}
                />
              )}
              {app.icon && (
                <LazyLoadImage
                  alt={"app"}
                  src={app.icon}
                  width={"20"}
                  effect="blur"
                  style={{ marginRight: "0.5rem", marginTop: "3px" }}
                  placeholder={<CircularProgress />}
                />
              )}
              {app.name
                ? app.name
                : app.title
                ? app.title
                : app.developer
                ? app.developer
                : ""}
            </FlexBox>
            <FlexBox
              sx={{
                width: "15%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <FlexBox
                sx={{
                  alignItems: "center",
                }}
                onClick={() => {}}
              >
                {!checkAllStatusFlags(app, [7], 4) &&
                getAdminUserRoleAccess(appData.userRole) ? (
                  <TooltipComponent title="Delete app" placement="top">
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        // marginLeft: "0.8rem",
                        // marginTop: "0.3rem",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        setShowDeleteModal({
                          appID: app.appID,
                          store: store,
                          title: app.name
                            ? app.name
                            : app.title
                            ? app.title
                            : "",
                        });
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="Delete"
                        width="16"
                        height="16"
                      />
                    </div>
                  </TooltipComponent>
                ) : getAdminUserRoleAccess(appData.userRole) ? (
                  <TooltipComponent title="Importing data..." placement="top">
                    <div
                      style={{
                        color: "#82868C",
                        fontSize: "14px",
                        lineHeight: "21px",
                        cursor: "pointer",
                      }}
                    >
                      {"Loading..."}
                    </div>
                  </TooltipComponent>
                ) : (
                  <></>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      );
    }
    // New App needs to be added
    return (
      <FlexBox
        sx={{
          flexDirection: "column",
          marginBottom: "1rem",
        }}
        key={`app-name-${index}-${store}`}
      >
        <Select
          fullWidth
          labelId="source"
          id="source"
          name={"source"}
          value={dataSource}
          label=""
          onChange={(event: any) => {
            if (event.target && event.target.value) {
              setError("");
              setDataSource(event.target.value);
            }
          }}
          sx={{
            borderRadius: "8px",
            border: "1px solid black",
          }}
          MenuProps={{
            sx: {
              zIndex: 99999999,
              height: "18rem",
              // maxHeight: "15rem",
              // overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            },
          }}
        >
          {Object.keys(COMBINED_DATA_SOURCE).map(
            (item: string, index: number) => (
              <MenuItem value={item} key={`${item}--menu-item-${index}`}>
                <FlexBox
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <FlexBox
                    sx={{
                      width: "80%",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "1.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      {Array.isArray(COMBINED_DATA_SOURCE[item].icon) ? (
                        COMBINED_DATA_SOURCE[item].icon &&
                        COMBINED_DATA_SOURCE[item].icon.length &&
                        Array.isArray(COMBINED_DATA_SOURCE[item].icon) &&
                        COMBINED_DATA_SOURCE[item].icon.map(
                          (icon: any, index: number) => (
                            <FlexBox
                              sx={{
                                marginRight: "0.3rem",
                              }}
                              key={`icon-array-${icon}-${index}`}
                            >
                              {" "}
                              <img src={icon} alt={"i"} width={"14"} />
                            </FlexBox>
                          )
                        )
                      ) : (
                        <img
                          key={`icon-individual-${COMBINED_DATA_SOURCE[item].icon}`}
                          src={COMBINED_DATA_SOURCE[item].icon}
                          alt={"i"}
                          width={"14"}
                        />
                      )}
                    </FlexBox>
                    <FlexBox
                      sx={{
                        color: "#0B0E1E",
                        fontSize: "13px",
                        lineHeight: "16px",
                        fontStyle: "normal",
                      }}
                    >
                      {COMBINED_DATA_SOURCE[item].label}
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    sx={{
                      width: "10%",
                    }}
                  ></FlexBox>
                </FlexBox>
              </MenuItem>
            )
          )}
        </Select>
        {error && error !== "" && (
          <FormHelperText
            error
            sx={{
              // mt: 1,
              fontSize: 13,
              fontWeight: 500,
              // textAlign: "center",
            }}
          >
            {error}
          </FormHelperText>
        )}
      </FlexBox>
    );
  };

  return (
    <Fragment>
      <FlexBox
        sx={{
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          padding: "2rem 0 2rem 0",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
              height: "fit-content",
              marginBottom: "30px",
            }}
          >
            Type the name of your app in the Apple app store or Google play
            store
          </Box>
          <FlexBox
            sx={{
              position: "relative",
              maxHeight: "27vh",
              overflowY: "auto",
              padding: "0 0.5rem 0 0.5rem",
              flexDirection: "column",
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#A3A6AB",
                borderRadius: "5px",
              },
            }}
          >
            {apps && apps.length ? (
              apps.map((app: DATA_BY_STORE, index: number) =>
                showApp(app.data, app.store, index)
              )
            ) : (
              <></>
            )}
          </FlexBox>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              color: "#3A3C40",
            }}
          >
            {"Note: You'll be able to change/add more data sources later on."}
          </Box>

          <FlexBox
            sx={{
              marginTop: "1.2rem",
            }}
          >
            <Button
              type="button"
              variant="outlined"
              disabled={
                disableSource || !getAdminUserRoleAccess(appData.userRole)
              }
              sx={{
                border: "none",
                padding: 0,
                "&:hover": { border: "none", background: "#FFF" },
                "&:disabled": {
                  border: "none",
                },
              }}
              onClick={() => {
                setApps([
                  // Adding the new one at the beginning of the list
                  {
                    appID: "",
                    store: "",
                    data: {},
                  },
                  ...apps,
                ]);
                setDisableNext(false);
                setError("");
              }}
            >
              + Add more data sources
            </Button>
          </FlexBox>
          {/* <FlexBox
          sx={{
            color: "#0B0E1E",
            marginTop: "0.25rem",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
          }}
        >
          <b style={{ marginRight: "0.2rem" }}>Upgrade to Premium</b> to add
          multiple data sources.
        </FlexBox> */}
        </FlexBox>
        <FlexBox>
          <Button
            fullWidth
            disabled={disableNext}
            type="button"
            variant="contained"
            onClick={async () => {
              // Take to the corresponding imports based on data source selection
              if (dataSource) {
                // In case of settings page, check with the previously added applications
                // If data any app exists from the same store as current, prevent addition
                // and show error to user

                // Commenting check for store
                // if (appData.store && appData.store.length) {
                //   if (appData.store.includes(dataSource)) {
                //     console.log(
                //       "App from same store already exists. Showing error to user."
                //     );
                //     setError(
                //       `An app from ${dataSource.toUpperCase()} already exists in your account.`
                //     );
                //     return;
                //   }
                // }
                console.log(
                  "User doesn't contain App from same store. Taking them forward",
                  dataSource
                );
                setSelectedDataSource(dataSource);
              }
            }}
          >
            Next
          </Button>
        </FlexBox>
      </FlexBox>
      {showDeleteModal ? (
        <DeleteModal
          onClose={async () => {
            // Add the logic to check if the user is an admin of the org
            // If that org exists, and the user is part of the admin list
            // update the app list in the org
            // Above is done in the lambda that is triggered
            // Delete the app id from user
            const appIDs = apps.filter(
              (a: DATA_BY_STORE) => a.appID !== showDeleteModal.appID
            );
            await addAppToUser(
              localStorage.getItem("username") || "",
              JSON.stringify(appIDs.map((a: DATA_BY_STORE) => a.appID))
            );
            try {
              await createOrEditOrganization({
                admin:
                  appData?.userRole?.email || localStorage.getItem("username"),
                appID: JSON.stringify(
                  appIDs.map((a: DATA_BY_STORE) => a.appID)
                ),
                organization: appData?.userRole?.organization || "",
                type: ADMIN_MANAGEMENT_TYPE.edit,
              });
            } catch (e) {
              console.log(e);
            }

            setApps(appIDs);
            localStorage.setItem(
              "appId",
              JSON.stringify(appIDs.map((a: any) => a.appID))
            );
            // dispatch(AppActions.setAppData(appIDs));
            dispatch(AppActions.removeDataSource(showDeleteModal));
            dispatch(GraphActions.removeDataSource(showDeleteModal));
            setShowDeleteModal(undefined);
            if (appIDs.length === 0) {
              navigate("/dashboard/home");
            }
          }}
          title={"Remove the source?"}
          content={
            <Box
              sx={{
                display: "-webkit-box",
                flexWrap: "wrap",
              }}
            >
              Removing{" "}
              <Box
                sx={{
                  paddingLeft: "0.3rem",
                  paddingRight: "0.3rem",
                  fontWeight: 700,
                }}
              >
                {showDeleteModal.title ? ` ${showDeleteModal.title} ` : ""}
              </Box>
              cannot be undone.
            </Box>
          }
          show={showDeleteModal ? true : false}
          setShow={(value: boolean) => {
            if (!value) {
              setShowDeleteModal(undefined);
            }
          }}
        ></DeleteModal>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default DataSources;
