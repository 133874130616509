import FlexBox from "../shared/wrappers/FlexBox";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import UnfilledStarIcon from "../shared/icons/unfilled-star.svg";
import FilledStarIcon from "../shared/icons/filled-star.svg";
import {
  checkNoneList,
  distictArray,
  keywordOrSplit,
  months,
  STORE,
} from "../shared/constants";
import Highlighter from "react-highlight-words";
import TooltipComponent from "../shared/wrappers/Tooltip";

type AmazonReviewProp = {
  review: Review;
  index: number;
  clusterPoints?: ReviewPoint[];
  reviewPoints?: ReviewPoint[];
  highlightKeywords?: string[];
  store?: string[];
};

export const getDate = (dateStr: string) => {
  const dateObj = new Date(dateStr);

  return `${
    months[dateObj.getMonth()]
  } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
};

const AmazonReviewComponent = ({
  review,
  index,
  clusterPoints,
  reviewPoints,
  highlightKeywords,
  store,
}: AmazonReviewProp) => {
  let reviewId = review.reviewID.split("-")[0] || `Review ${index + 1}`;

  if (
    review.reviewID.split("-") &&
    review.reviewID.split("-")[0] &&
    review.reviewID.split("-")[0] === ""
  ) {
    reviewId = `Review ${index + 1}`;
  }

  if (
    store &&
    store.includes(STORE.amazon) &&
    review.reviewID.includes(keywordOrSplit)
  ) {
    reviewId = review.reviewID.split(keywordOrSplit)[0];
    if (reviewId.includes("-") && reviewId.length > 12) {
      reviewId = review.reviewID.split("-")[0];
    }
  }

  let clusteredPoints: ReviewPoint[] = [];
  if (clusterPoints && clusterPoints.length) {
    clusteredPoints = clusterPoints.filter(
      (rev: ReviewPoint) => review.reviewID === rev.reviewId
    );
    clusteredPoints = clusteredPoints.map((point: ReviewPoint) => ({
      ...point,
      point: point.point.replace("-", ""),
    }));
  }

  let reviewedPoints: ReviewPoint[] = [];
  if (reviewPoints && reviewPoints.length) {
    reviewedPoints = reviewPoints.filter(
      (rev: ReviewPoint) =>
        review.reviewID === rev.reviewId && !checkNoneList(rev.point)
    );
    reviewedPoints = reviewedPoints.map((point: ReviewPoint) => ({
      ...point,
      point: point.point.replace("-", ""),
    }));
  }

  // Getting the distict array for fail-safe
  reviewedPoints = distictArray(reviewedPoints, "point");

  return (
    <FlexBox
      sx={{
        marginTop: "4px",
        marginBottom: "16px",
        background: "#F5F5F5",
        height: "fit-content",
        padding: "20px",
        flexDirection: "column",
        boxShadow: "0px 2px 4px rgba(130, 134, 140, 0.16)",
        borderRadius: "8px",
        position: "relative",
        width: "100%",
      }}
      key={`ideas-${index}`}
    >
      <FlexBox
        sx={{
          width: "100%",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              flexDirection: "column",
              textAlign: "left",
              alignItems: "left",
            }}
          >
            <FlexBox
              sx={{
                textTransform: "uppercase",
                fondWeight: 500,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              <FlexBox
                sx={{
                  color: "#3579E3",
                }}
              >
                {"Created"}
              </FlexBox>
              <FlexBox
                sx={{
                  color: "#0B0E1E",
                  marginLeft: "0.5rem",
                }}
              >
                {getDate(review.date)}
              </FlexBox>
            </FlexBox>
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#3A3C40",
                marginTop: "0.5rem",
              }}
            >
              {`Title: ${reviewId}`}
            </FlexBox>
            <FlexBox
              sx={{
                marginTop: "0.5rem",
              }}
            >
              <FlexBox
                sx={{
                  marginTop: "0.1rem",
                }}
              >
                {[1, 2, 3, 4, 5].map((r: number) => {
                  if (r <= review.rating) {
                    return (
                      <img
                        src={FilledStarIcon}
                        alt="Star"
                        width="12px"
                        height="12px"
                        style={{ marginRight: "2px" }}
                        key={`filled-star-${r}`}
                      />
                    );
                  }
                  return (
                    <img
                      src={UnfilledStarIcon}
                      alt="Star"
                      width="12px"
                      height="12px"
                      style={{ marginRight: "2px" }}
                      key={`unfilled-star-${r}`}
                    />
                  );
                })}
              </FlexBox>
              <FlexBox
                sx={{
                  fontSize: "10px",
                  lineHeight: "14px",
                  marginLeft: "0.5rem",
                  ...(review.sentimentScore !== undefined &&
                  parseFloat(review.sentimentScore) >= 0
                    ? { color: "#418F90" }
                    : { color: "#DB4E82" }),
                }}
              >
                {review.sentimentScore !== undefined &&
                parseFloat(review.sentimentScore) > 0.1
                  ? "Positive "
                  : review.sentimentScore !== undefined &&
                    parseFloat(review.sentimentScore) < -0.1 &&
                    "Negative "}
                {/* {parseFloat(review.sentimentScore)} */}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {/* Main Points Section */}
      <FlexBox
        sx={{
          marginTop: "0.8rem",
          flexDirection: "column",
        }}
      >
        {/* Main Points Section */}
        {(clusteredPoints && clusteredPoints.length !== 0) ||
        (reviewedPoints && reviewedPoints.length !== 0) ? (
          <FlexBox
            sx={{
              marginTop: "0.8rem",
              flexDirection: "column",
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FlexBox
                sx={{
                  marginRight: "0.3rem",
                  color: "#3A3C40",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {"Main points"}
              </FlexBox>
              <FlexBox
                sx={{
                  flexGrow: 1,
                  height: "1px",
                  background: "#E3E6EB",
                }}
              ></FlexBox>
            </FlexBox>

            <FlexBox
              sx={{
                flexDirection: "column",
                marginLeft: "1rem",
                marginTop: "0.3rem",
                color: "#000000",
              }}
            >
              {reviewedPoints.length !== 0
                ? reviewedPoints.map((point: ReviewPoint, index: number) => (
                    <FlexBox
                      key={`idea-point-${index}-${point}`}
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                        display: "list-item",
                        listStyleType: "disc",
                        ...(clusteredPoints.filter((clus: ReviewPoint) => {
                          return (
                            point.point
                              .toLowerCase()
                              // replace special characters and comma
                              .replace(/[^A-Za-z ]*/, "")
                              .replace(",", "")
                              .replace(/['"]+/g, "")
                              .replaceAll("-", "")
                              .replaceAll(":", "")
                              .includes(
                                clus.point
                                  // Replacing un-required characters in the cluster point
                                  .replaceAll("-", "")
                                  .replace(/(^"|"|"$)/g, "")
                                  .trim()
                                  .toLowerCase()
                                  // replace
                                  .replace(/[^A-Za-z ]*/, "")
                                  .replace(",", "")
                                  .replace(/['"]+/g, "")
                                  .replaceAll(":", "")
                              )
                          );
                        }).length !== 0
                          ? { fontWeight: 700 }
                          : {}),
                      }}
                    >
                      {point.point}
                    </FlexBox>
                  ))
                : clusteredPoints.map((point: ReviewPoint, index: number) => (
                    <FlexBox
                      key={`idea-point-${index}-${point}`}
                      sx={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        display: "list-item",
                        listStyleType: "disc",
                        fontWeight: 700,
                      }}
                    >
                      {point.point}
                    </FlexBox>
                  ))}
            </FlexBox>
          </FlexBox>
        ) : (
          <></>
        )}
        <FlexBox
          sx={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "#0B0E1E",
            marginLeft: "0.5rem",
            marginTop: "0.3rem",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {review.company && (
            <FlexBox
              sx={{
                fontWeight: 500,
                background: "#FFFFFF",
                borderRadius: "4px",
                padding: "2px 6px",
                width: "fit-content",
              }}
            >
              {review.company}
            </FlexBox>
          )}
          {review.destination && (
            <FlexBox
              sx={{
                fontWeight: 500,
                background: "#FBDFEF",
                borderRadius: "4px",
                padding: "2px 6px",
                width: "fit-content",
                ...(review.company ? { marginLeft: "1rem" } : {}),
              }}
            >
              {review.destination}
            </FlexBox>
          )}
          {review.specialist && (
            <FlexBox
              sx={{
                fontWeight: 700,
                background: "#FECFEF",
                borderRadius: "4px",
                padding: "2px 6px",
                width: "fit-content",
                ...(review.company || review.destination
                  ? { marginLeft: "1rem" }
                  : {}),
              }}
            >
              {review.specialist}
            </FlexBox>
          )}
          {review.url && (
            <TooltipComponent title={"Open in amazon"} placement="top">
              <a
                style={{
                  display: "flex",
                  fontWeight: 500,
                  background: "#CFE9E9",
                  borderRadius: "4px",
                  padding: "2px 6px",
                  //   wordBreak: "break-all",
                  cursor: "pointer",
                  width: "fit-content",
                  ...(review.company || review.destination || review.specialist
                    ? { marginLeft: "1rem" }
                    : {}),
                }}
                href={review.url}
                target="_blank"
                rel="noreferrer"
              >
                {"Open in a new tab"}
              </a>
            </TooltipComponent>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          // minHeight: "40px",
          height: "fit-content",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000000",
          marginTop: "15px",
          marginBottom: "15px",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        <Highlighter
          searchWords={highlightKeywords ? highlightKeywords : []}
          // searchWords={[]}
          autoEscape={true}
          textToHighlight={review.content}
        />
      </FlexBox>
      {review.replyContent && review.replyContent.length ? (
        <FlexBox
          sx={{
            marginLeft: "0.3rem",
            marginRight: "0.3rem",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#82868C",
            }}
          >
            Developer replied
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              color: "#000000",
              marginTop: "4px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {review.replyContent}
          </FlexBox>
        </FlexBox>
      ) : (
        <></>
      )}
      <FlexBox
        sx={{
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 500,
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <FlexBox
          sx={{
            color: "#0B0E1E",
            background: "#D7E5FA",
            borderRadius: "4px",
            padding: "2px 6px",
          }}
        >
          {`${review.rating} ${review.rating <= 1 ? "vote" : "votes"}`}
        </FlexBox> */}
        <FlexBox
          sx={{
            // marginLeft: "1rem",
            color: "#82868C",
          }}
        >{`Submitted by ${review.username}`}</FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default AmazonReviewComponent;
