// import * as AWS from "aws-sdk";
// import { S3Client } from "@aws-sdk/client-s3";
// import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getS3Object } from "../api/Apps.api";
import axios from "axios";

// const credential = new AWS.Credentials(
//   process.env.REACT_APP_AWS_ACCESS_ID || "",
//   process.env.REACT_APP_AWS_SECRET_KEY || ""
// );

// const s3Client = new S3Client({
//   region: process.env.REACT_APP_APP_REGION || "",
//   credentials: credential,
// });

// export const getDataFromS3 = async (appID: string, table: string) => {
//   try {
//     // Create bucket key
//     const key = `${appID}_${table}.json`;
//     // Define bucket params
//     const bucketParams = {
//       Bucket: process.env.REACT_APP_RESULT_BUCKET,
//       Key: key,
//     };
//     // Get the object from the Amazon S3 bucket. It is returned as a ReadableStream.
//     const data = await s3Client.send(new GetObjectCommand(bucketParams));
//     // Convert the ReadableStream to a string.
// if (data && data.Body) {
// const response = await data.Body.transformToString();
// const parsed = JSON.parse(response);

//       return parsed;
//     }
//   } catch (err) {
//     throw new Error("Error occured, key not present");
//   }
// };

export const getDataUsingURLFromS3 = async (appID: string, table: string) => {
  try {
    // Create bucket key
    const key = `${appID}_${table}.json`;
    const expiration_time = 600;
    // Define bucket params
    const bucketParams = {
      Bucket: process.env.REACT_APP_RESULT_BUCKET,
      Key: key,
      expiration_time: expiration_time,
    };

    // Make a request to the Api to generate the presigned url
    // By sending those bucket parameters
    const presignedUrl = await getS3Object(bucketParams);

    // Parse the JSON string to access the downloadURL property
    const parsedBody = JSON.parse(presignedUrl.body);
    const actualPresignedUrl = parsedBody.downloadURL;

    // Make an HTTP request to the presigned URL to get the S3 object
    const response = await axios.get(actualPresignedUrl);

    if (response.status === 200) {
      if (response.data) {
        // send the data of the response to access those data.
        const data = response.data;
        return data;
      } else {
        // throw new Error("Readable stream is null");
      }
    } else {
      // throw new Error(`Failed to fetch S3 object. Status: ${response.status}`);
    }
  } catch (err) {
    // throw new Error(`Error occurred: ${err}`);
  }
};
