import { Box, Button, Card, FormHelperText, Rating } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import FlexBox from "../shared/wrappers/FlexBox";
import "../shared/wrappers/Modal.css";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Paragraph } from "../shared/wrappers/Typography";
import LightTextArea from "../shared/wrappers/LightTextArea";
import { sendUserFeedback } from "../../services/api/Apps.api";
// import RadioGroupRating from "./Rating";

const Feedback = (props: {
  title: string;
  onClose: () => void;
  show: boolean;
  setShow: (value: boolean) => void;
  children?: any;
}) => {
  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();
  const closeOnEscapeKeyDown = (e: any) => {
    // Backspace
    if ((e.charCode || e.keyCode) === 27) {
      props.setShow(false);
    }

    // Enter
    // if ((e.charCode || e.keyCode) === 13) {
    //   props.onClose && props.onClose();
    // }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHanlder = () => {
    props.onClose && props.onClose();
  };

  const initialValues: {
    feedback: string;
    rating: string;
  } = {
    feedback: "",
    rating: "0",
  };

  const validationSchema = Yup.object().shape({
    feedback: Yup.string().required("Please provide a feedback"),
    rating: Yup.string().required("Please provide a rating"),
  });

  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (!loading) {
        setLoading(true);
      }
      // Send the feedback to backend to store in the schema
      // Could also trigger emails to admins if required
      try {
        await sendUserFeedback({
          feedback: values.feedback,
          rating: `${values.rating}`,
          user: localStorage.getItem("username") || "",
        });
      } catch (e) {
        console.log("Some error occured", e);
      }

      setLoading(false);
      onCloseHanlder();
    },
  });

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", width: "60%" }),
        }}
      >
        <Card
          sx={{
            padding: 4,
            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",
            height: "fit-content",
          }}
        >
          <FlexBox flexDirection="column" justifyContent="center">
            <FlexBox
              style={{
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38px",
                color: "#3579E3",
                fontFamily: "Barlow",
              }}
            >
              {props.title}
            </FlexBox>
            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Box
                sx={{
                  mt: 4,
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#0B0E1E",
                  minHeight: "4rem",
                  height: "fit-content",
                  width: "100%",
                }}
              >
                <FullTextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    Please provide your feedback
                  </Paragraph>
                  <LightTextArea
                    style={{
                      width: "100%",
                      fontFamily: "Barlow",
                      padding: "0.7rem",
                    }}
                    minRows={5}
                    maxRows={8}
                    name="feedback"
                    onChange={handleChange}
                    value={values.feedback || ""}
                    onBlur={handleBlur}
                    // error={Boolean(touched.feedback && errors.feedback)}
                    // helperText={touched.feedback && errors.feedback}
                  />
                </FullTextFieldWrapper>
                <FullTextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    How happy are you with the app?
                  </Paragraph>
                  {/* <RadioGroupRating
                    name={"rating"}
                    value={values.rating || 0}
                    onChange={handleChange}
                  /> */}
                  <Rating
                    name={"rating"}
                    value={parseInt(values.rating || "0")}
                    onChange={handleChange}
                  />
                </FullTextFieldWrapper>
              </Box>
              {errors && (errors.feedback || errors.rating) && (
                <FormHelperText
                  error
                  sx={{
                    mt: 2,
                    fontSize: 13,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {errors.feedback || errors.rating}
                </FormHelperText>
              )}
              <Box
                sx={{
                  mt: 4,
                  width: "100%",
                  textAlign: "end",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    border: "none",
                    "&:hover": { border: "none" },
                  }}
                  onClick={() => props.setShow && props.setShow(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Send feedback
                </Button>
              </Box>
            </form>
          </FlexBox>
        </Card>
      </div>
    </div>
  );
};

export default Feedback;
