import { Box, Button, FormHelperText } from "@mui/material";
import { useState, useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import LightTextField from "../shared/wrappers/LightTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr } from "./AddAha";
import { getAhaProducts } from "../../services/api/Apps.api";
import FlexBox from "../shared/wrappers/FlexBox";

const Config = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    setProducts,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    subdomain: string;
    apikey: string;
  } = { subdomain: "", apikey: "", ...formData };

  const validationSchema = Yup.object().shape({
    subdomain: Yup.string().required("Sub-domain is required"),
    apikey: Yup.string().required("API Key  is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading(true);
        try {
          if (values.subdomain !== "" && values.apikey !== "") {
            const data = { ...formData, ...values };
            const response = await getAhaProducts(
              values.subdomain,
              values.apikey
            );
            if (response && response.length) {
              setProducts(response);
              setFormData(data);
              setActiveStepIndex(activeStepIndex + 1);
            } else {
              setError(
                "We are facing some issue with your provided configuration"
              );
            }
          }
        } catch (e: any) {
          setError(e.message);
        }

        setLoading(false);
      },
    });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Provide config details
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"Sub-domain"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"subdomain"}
              type={"subdomain"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.subdomain || ""}
              error={Boolean(touched.subdomain && errors.subdomain)}
              helperText={touched.subdomain && errors.subdomain}
            />
            <Small>
              You can find your sub-domain in the url for your Aha! page. For
              example, https://<b>rargus.aha.io</b>/products
            </Small>
          </FullTextFieldWrapper>
          <FullTextFieldWrapper>
            <Paragraph fontWeight={600} mb={1} mt={1}>
              {"API Key"}
            </Paragraph>
            <LightTextField
              fullWidth
              name={"apikey"}
              type={"apikey"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.apikey || ""}
              error={Boolean(touched.apikey && errors.apikey)}
              helperText={touched.apikey && errors.apikey}
            />
            <Small>
              You can create a new API key from here:
              <i>
                https://<b>your-sub-domain</b>/settings/api_keys
              </i>
            </Small>
          </FullTextFieldWrapper>

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching workspaces"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Small
              color="primary.main"
              sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Back
            </Small>
          </Box>
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default Config;
