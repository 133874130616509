import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: any = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      position: "bottom",
      display: true,
      align: "start",
      labels: {
        color: "#3A3C40",
        font: {
          size: 9,
          family: "Barlow",
        },
        boxWidth: 18,
        borderRadius: 4,
        boxHeight: 1,
      },
    },
    tooltip: {
      backgroundColor: "#3579E3",
      titleColor: "#FFFFFF",
      displayColors: false,
      // Setting the Tooltip label as Axes and data
      // callbacks: {
      //     label: function(context: any) {
      //         let label = context.formattedValue || '';

      //         return label;
      //     }
      // }
    },
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
          family: "Barlow",
        },
      },
    },
    y: {
      ticks: {
        font: {
          size: 9,
          family: "Barlow",
        },
      },
      //   weight: 10
    },
  },
};

const LineChart = ({ height, data }: any) => {
  return <Line options={options} data={data} height={300} width={"300px"} />;
};

export default LineChart;
