import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { getUserAppIDs, setUserDataAndRole } from "../shared/constants";

const Home = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);

        // Fetch the app list based on org or individual user
        const appId = await getUserAppIDs(res.getUser);
        if (appId && appId.length && appId !== "[]") {
          localStorage.setItem("appId", appId);
          //console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
            : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),

          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",

          height: "fit-content",
        }}
      >
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38px",
              color: "#3579E3",
              fontFamily: "Barlow",
            }}
          >
            Let’s get started
          </FlexBox>
          <Box
            sx={{
              mt: 4,
              ml: 1,
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0B0E1E",
              minHeight: "8rem",
              height: "fit-content",
            }}
          >
            We analyze your customer feedback for your app reviews and provide
            actionable insights. With all of our reports, you can dig as deep
            into the data as you want. Read full reviews from customers,
            understand who they are, and make decisions based off of the full
            picture. Try it for yourself.
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => navigate("/dashboard/data-source")}
            >
              Next
            </Button>
          </Box>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default Home;
