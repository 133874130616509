import { Card, Box, Button } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import { useState, createContext, useEffect } from "react";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import Stepper from "./SlackStepper";
import Step from "./Step";
import { AddFromSettingsProps } from "../AddApp/AddApp";
import { getWithExpiry, SETTINGS_PAGE } from "../shared/constants";

export type FormContextAttr = {
  activeStepIndex: number;
  setActiveStepIndex: (val: number) => void;
  formData: any;
  setFormData: (value: any) => void;
  channels: any[];
  setChannels: (value: any) => void;
  attributes: any[];
  setAttributes: (value: any) => void;
  location: string | undefined;
  onBack: (() => void) | undefined;
};

export const FormContext = createContext<FormContextAttr | null>(null);

const AddSlack = ({ location, step, onBack }: AddFromSettingsProps) => {
  const { width } = useWindowDimensions();

  // States for step form and form data
  const [activeStepIndex, setActiveStepIndex] = useState(step ? step : 0);
  const [formData, setFormData] = useState({});
  const [channels, setChannels] = useState([]);
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    const slackToken = getWithExpiry("slack-token");

    if (slackToken && slackToken.length) {
      setActiveStepIndex(step ? step : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        background: "#F5F5F5",
      }}
    >
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "1rem",
          top: "0.3rem",
        }}
      >
        <img
          src={RargusIcon}
          alt="Rargus Icon"
          width="38"
          height="38"
          style={{
            marginRight: "0.5rem",
          }}
        />
        <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
      </FlexBox>
      <Card
        sx={{
          padding: 4,
          ...(width < 800
            ? {
                width: "90%",
                marginTop: "5rem",
                marginBottom: "3rem",
                height: "fit-content",
              }
            : {
                maxWidth: "600px",
                minWidth: "600px",
                height: "fit-content",
                // height: "76%",
                // minHeight: "30rem",
                // maxHeight: "37rem",
              }),
          border: "none",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
          borderRadius: "16px",
        }}
      >
        <FlexBox
          flexDirection="column"
          sx={{
            height: "100%",
          }}
        >
          <FormContext.Provider
            value={{
              activeStepIndex,
              setActiveStepIndex,
              formData,
              setFormData,
              channels,
              setChannels,
              attributes,
              setAttributes,
              location,
              onBack,
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              <Stepper
                steps={[
                  "Generate your token",
                  "Config details",
                  "Select channel",
                  "Start app import",
                ]}
                activeStepIndex={activeStepIndex}
              />
              <Step location={location} />
              {location && location === SETTINGS_PAGE ? (
                <Box sx={{ justifyContent: "center" }}>
                  <Button
                    fullWidth
                    type="button"
                    variant="outlined"
                    sx={{
                      fontWeight: 500,
                      border: "none",
                      "&:hover": { border: "none", background: "#FFF" },
                      marginBottom: "1rem",
                    }}
                    onClick={() => {
                      if (location && onBack && activeStepIndex === 0) {
                        onBack();
                      } else {
                        setActiveStepIndex(activeStepIndex - 1);
                      }
                    }}
                  >
                    {location &&
                    location === SETTINGS_PAGE &&
                    activeStepIndex === 0
                      ? "Back to settings"
                      : "Back"}
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </FormContext.Provider>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default AddSlack;
