import { Box } from "@mui/material";

const ZapierTemplate = () => {
  return (
    <Box
      sx={{
        // mt: 1,
        ml: 1,
        pr: 1,
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Barlow",
        lineHeight: "20px",
        color: "#0B0E1E",
        maxHeight: "35vh",
        minHeight: "7rem",
        // height: "fit-content",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0.4rem",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#A3A6AB",
          borderRadius: "5px",
        },
      }}
    >
      <zapier-zap-templates
        theme="light"
        ids="1499686"
        limit={5}
        link-target="new-tab"
        presentation="row"
        use-this-zap="hide"
      />
    </Box>
  );
};

export default ZapierTemplate;
