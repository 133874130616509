import { Box, Button, Grid, Autocomplete, TextField } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ADMIN_MANAGEMENT_TYPE,
  getAppDetails,
  getUserAppIDs,
  STORE,
} from "../shared/constants";
import PlayStoreIcon from "../shared/icons/play-store.svg";
import AppleStoreIcon from "../shared/icons/apple-store.svg";
import CSVIcon from "../shared/icons/csv.svg";
import AhaIcon from "../shared/icons/aha.svg";
import SlackIcon from "../shared/icons/slack.svg";
import SOFIcon from "../shared/icons/sof.svg";
import AmazonIcon from "../shared/icons/amazon.svg";
import GithubIcon from "../shared/icons/github.svg";
import { createOrEditOrganization } from "../../services/api/Apps.api";
import { addAppToUser } from "../../services/api/Graphql.api";
import { GraphActions } from "../../services/store/GraphStore/GraphSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../services/store/store";
import FlexBox from "../shared/wrappers/FlexBox";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { FormContext, FormContextAttr } from "./AdminBoard";

const AppSwitchPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);

  const { apps } = useContext(FormContext) as FormContextAttr;

  const [selectedApp, setSelectedApp] = useState<any>("");
  const [searchText, setSearchText] = useState<any>("");

  const addAppToUserHandler = async (flag: boolean) => {
    if (selectedApp && selectedApp.length && searchText.appID) {
      // For Aha flow, installs is null
      // For App flow, installs has data
      let foundIndex = apps.findIndex(
        (app: any) => app.appID === searchText.appID
      );

      if (foundIndex !== -1) {
        try {
          let appIDs = [];
          if (flag) {
            // Add previous appIDs as well if exists
            const appId = await getUserAppIDs(appData.user);
            if (appId && appId.length && appId !== "[]") {
              appIDs = JSON.parse(appId);
            }
          }

          // Add only when the app id doesn't already belong in the list
          if (!appIDs.includes(apps[foundIndex]["appID"])) {
            appIDs = appIDs.concat(
              Array.isArray(apps[foundIndex]["appID"])
                ? apps[foundIndex]["appID"]
                : [apps[foundIndex]["appID"]]
            );
          }

          let appID = JSON.stringify(appIDs);

          try {
            await createOrEditOrganization({
              admin:
                appData?.userRole?.email || localStorage.getItem("username"),
              appID: appID,
              organization: appData?.userRole?.organization || "",
              type: ADMIN_MANAGEMENT_TYPE.edit,
            });
          } catch (e) {
            console.log(e);
          }

          await addAppToUser(localStorage.getItem("username") || "", appID);
          localStorage.setItem("appId", appID);
          localStorage.removeItem("store");
          dispatch(AppActions.setInit());
          dispatch(GraphActions.setInit());
          await getAppDetails(
            {
              app: {
                appID: apps[foundIndex]["appID"],
              },
            },
            dispatch
          );
          navigate("/dashboard/insights/feedback");
        } catch (e) {
          console.log("Some error happened during app addition");
        }
      }
    }
  };

  return (
    <Fragment>
      <Grid item xs={6} mt={4} style={{ position: "relative" }}>
        <Box
          sx={{
            mb: 3,
            ml: 1,
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#0B0E1E",
            height: "fit-content",
          }}
        >
          To add an app to your account, use the dropdown below. The first
          button adds the selected app on top of your previous apps. The second
          button adds only the current app to your account.
        </Box>
        <Autocomplete
          loading={apps && apps.length === 0}
          id={"autocomplete"}
          // disableCloseOnSelect={true}
          fullWidth
          renderInput={(params) => (
            <TextField variant="outlined" {...params} fullWidth />
          )}
          value={searchText}
          onInputChange={(event, newValue) => {
            setSelectedApp(newValue);
            //debounceChangeHandler(newValue)
          }}
          // onBlur={(e: any) => {
          //     setSearchText(e.target.value.trim());
          // }}
          onChange={(event, newValue) => {
            setSearchText(newValue);
          }}
          options={apps}
          getOptionLabel={(option: any) =>
            option.name || option.developer || ""
          }
          isOptionEqualToValue={(option, value) => {
            if (value === "") {
              return false;
            }

            return option === value;
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={`${option.name || option.developer}--${option.appID}`}
            >
              <LazyLoadImage
                alt={"Store"}
                src={
                  option.store && option.store === STORE.csv
                    ? CSVIcon
                    : option.store && option.store === STORE.slack
                    ? SlackIcon
                    : option.store && option.store === STORE.aha
                    ? AhaIcon
                    : option.store && option.store === STORE.sof
                    ? SOFIcon
                    : option.store && option.store === STORE.amazon
                    ? AmazonIcon
                    : option.store && option.store === STORE.github
                    ? GithubIcon
                    : option.installs
                    ? option.installs !== "-1"
                      ? PlayStoreIcon
                      : AppleStoreIcon
                    : ""
                }
                width={"20"}
              />
              {option.icon && (
                <LazyLoadImage
                  alt={"app"}
                  src={option.icon}
                  width={"20"}
                  effect="blur"
                  style={{ marginRight: "4px" }}
                />
              )}
              {option.name || option.developer || ""}
            </Box>
          )}
          freeSolo={false}
        />

        <Box
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#3A3C40",
          }}
        >
          {"Note: Select an app to switch to from the dropdown."}
        </Box>
        <FlexBox justifyContent={"space-between"} sx={{ mt: 2 }}>
          <Box sx={{ mt: 2, width: "48%" }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => addAppToUserHandler(true)}
            >
              Expand app to user's list
            </Button>
          </Box>
          <Box sx={{ mt: 2, width: "48%" }}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              onClick={() => addAppToUserHandler(false)}
            >
              Add only current app to user
            </Button>
          </Box>
        </FlexBox>
      </Grid>
    </Fragment>
  );
};

export default AppSwitchPage;
