import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler
);

export const options: any = (customYAxis: any) => ({
  maintainAspectRatio: false,
  //   elements: {
  //     point: {
  //       radius: 0,
  //     },
  //   },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      position: "bottom",
      display: true,
      align: "start",
      labels: {
        color: "#3A3C40",
        font: {
          size: 9,
          family: "Barlow",
        },
        boxWidth: 18,
        borderRadius: 4,
      },
    },
    tooltip: {
      backgroundColor: "#3579E3",
      titleColor: "#FFFFFF",
      displayColors: false,
      // Setting the Tooltip label as Axes and data
      // callbacks: {
      //     label: function(context: any) {
      //         let label = context.formattedValue || '';

      //         return label;
      //     }
      // }
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
          family: "Barlow",
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 9,
          family: "Barlow",
        },
      },
      //   weight: 10
    },
    ...(customYAxis
      ? {
          "right-y-axis": {
            suggestedMin: 1,
            suggestedMax: 5,
            stepSize: 1,
            beginAtZero: false,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 9,
                family: "Barlow",
              },
              callback: function (value: any, index: number, ticks: any) {
                return parseFloat(value).toFixed(1) + customYAxis;
              },
            },
            position: "right",
          },
        }
      : {}),
  },
});

const AreaWithLine = ({ height, data, customYAxis }: any) => {
  return (
    <Chart
      type="bar"
      data={data}
      options={options(customYAxis)}
      height={300}
      width={"300px"}
    />
  );
};

export default AreaWithLine;
