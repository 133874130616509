import { Fragment, ReactNode } from "react";
import { getSession } from "../../services/context/Account";
import { getWithExpiry } from "../shared/constants";

interface CheckAuthProps {
  children: ReactNode;
}

const CheckAuth = ({ children }: CheckAuthProps) => {
  const token = getWithExpiry("token");

  const navigateHandler = () => {
    window.location.href = "/dashboard/home";
  };

  const findSessionDetails = async () => {
    try {
      await getSession(navigateHandler);
    } catch (e) {
      console.log("User session invalid");
    }
  };

  if (token && token !== "") {
    // Check if User's session is valid
    findSessionDetails();
  }

  return <Fragment>{children}</Fragment>;
};

export default CheckAuth;
