import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { RootState } from "../../services/store/store";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import { Widget } from "../Insights/WidgetContainer";
import {
  STORE,
  distictObjectById,
  getFilterValues,
  FULLWIDTH,
  HORIZONTAL_GRAPH_WITH_REVIEWS,
  NORMAL,
  HORIZONTAL_SCALE_GRAPH,
  POSITIVE,
  createTopTopicsData,
  NEGATIVE,
  REVIEWS,
  getDataByStore,
  FilterOption,
  GET_DATA_STORE,
} from "../shared/constants";
import { ClusterData } from "../shared/types";
import { WIDGET_IDS } from "../shared/widgetConstants";
import FlexBox from "../shared/wrappers/FlexBox";
import { HorizontalScaleGraph, Reviews } from "./InnerComponents";
import { v4 as uuidv4 } from "uuid";
import ArrowGreyIcon from "../shared/icons/arrow-grey.svg";

type HorizontalScaleGraphWithReviewsProps = {
  source: GET_DATA_STORE[] | undefined;
  filter: FilterOption | undefined;
};

const HorizontalScaleGraphWithReviews = ({
  source,
  filter,
}: HorizontalScaleGraphWithReviewsProps) => {
  const appData: any = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  // Review Filter States
  const [positive, setPositive] = useState(true);
  const [negative, setNegative] = useState(true);
  const [oneStar, setOneStar] = useState(true);
  const [twoStar, setTwoStar] = useState(true);
  const [threeStar, setThreeStar] = useState(true);
  const [fourStar, setFourStar] = useState(true);
  const [fiveStar, setFiveStar] = useState(true);

  // Control show/hide of reviews
  const [showReviews, setShowReviews] = useState(false);
  // Choose Topic Subtopic
  const [selectedTopic, setSelectedTopic] = useState<any>();
  // Reviews
  const [filteredClusterReviews, setFilteredClusterReviews] = useState<
    Review[]
  >([]);

  const [filteredReviews, setFilteredReviews] = useState<any[]>([]);

  const [filteredSentiments, setFilteredSentiments] = useState<any[]>([]);

  // Cluster Points and Review Points
  const [clusterPoints, setClusterPoints] = useState<ReviewPoint[]>([]);
  const [reviewPoints, setReviewPoints] = useState<ReviewPoint[]>([]);

  // Pagination offset value
  const [itemOffset, setItemOffset] = useState(0);

  // Pagination variables
  const itemsPerPage = 100;

  useEffect(() => {
    if (filteredReviews && filteredReviews.length) {
      let maxOffset = filteredReviews.length / 100;
      if (itemOffset > maxOffset) {
        setItemOffset(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReviews]);

  const getReviewPointMapping = useCallback(() => {
    let reviewMapping: ReviewPoint[] = [];

    if (selectedTopic) {
      if (
        selectedTopic.clusterGroup !== "0" &&
        selectedTopic.subClusters &&
        selectedTopic.subClusters.length
      ) {
        selectedTopic.subClusters.forEach((sub: any) => {
          if (sub.reviewMapping && sub.reviewMapping.length) {
            reviewMapping = reviewMapping.concat(sub.reviewMapping);
          }
        });
      }
      if (selectedTopic.clusterGroup === "0" && selectedTopic.clusterTopics) {
        if (selectedTopic.reviewMapping && selectedTopic.reviewMapping.length) {
          reviewMapping = reviewMapping.concat(selectedTopic.reviewMapping);
        }
      }
    }

    // console.log(subStrings);
    return reviewMapping;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic]);

  // use effect to determine the cluster points
  useEffect(() => {
    if (
      appData.store &&
      appData.store.length &&
      (appData.store.includes(STORE.apple) ||
        appData.store.includes(STORE.google) ||
        appData.store.includes(STORE.csv) ||
        appData.store.includes(STORE.github) ||
        appData.store.includes(STORE.amazon) ||
        appData.store.includes(STORE.sof) ||
        appData.store.includes(STORE.slack)) &&
      source &&
      source.length
    ) {
      let points = getReviewPointMapping();
      let clusteredPoints = distictObjectById(points, "point");
      setClusterPoints(clusteredPoints);

      // Set all the review points in this
      const complaintReviewMapping = getDataByStore(
        appData.complaintReviewMapping,
        source
      );
      const complimentReviewMapping = getDataByStore(
        appData.complimentReviewMapping,
        source
      );
      const suggestionReviewMapping = getDataByStore(
        appData.suggestionReviewMapping,
        source
      );
      if (
        complaintReviewMapping &&
        complimentReviewMapping &&
        suggestionReviewMapping
      ) {
        let reviewIds = clusteredPoints.map(
          (item: ReviewPoint) => item.reviewId
        );
        setReviewPoints(
          [
            ...complaintReviewMapping,
            ...complimentReviewMapping,
            ...suggestionReviewMapping,
          ].filter((item: ReviewPoint) => reviewIds.includes(item.reviewId))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic]);

  useEffect(() => {
    let filteredData: any[] = [...filteredClusterReviews];
    let filCluster: any[] = [];

    // Filter neutral reviews
    // filteredData = filteredData.filter(
    //   (data) =>
    //     data.sentimentScore &&
    //     (parseFloat(data.sentimentScore) > 0.1 ||
    //       parseFloat(data.sentimentScore) < -0.1)
    // );

    if (!positive) {
      filteredData = filteredData.filter(
        (data) =>
          data.sentimentScore !== undefined &&
          parseFloat(data.sentimentScore) <= 0.1
      );
    }
    if (!negative) {
      filteredData = filteredData.filter(
        (data) =>
          data.sentimentScore !== undefined &&
          parseFloat(data.sentimentScore) >= -0.1
      );
    }
    if (!oneStar) {
      filteredData = filteredData.filter((data) => data.rating !== 1);
    }
    if (!twoStar) {
      filteredData = filteredData.filter((data) => data.rating !== 2);
    }
    if (!threeStar) {
      filteredData = filteredData.filter((data) => data.rating !== 3);
    }
    if (!fourStar) {
      filteredData = filteredData.filter((data) => data.rating !== 4);
    }
    if (!fiveStar) {
      filteredData = filteredData.filter((data) => data.rating !== 5);
    }

    // Global Filter Values are set here
    if (appData.filter && source && source.length) {
      const { expression } = getFilterValues({
        filter: filter ? filter : appData.filter,
      });

      try {
        filteredData = filteredData.filter((rev) => {
          return expression(rev.date);
        });

        const clusterSentiment = getDataByStore(
          appData.clusterSentiment,
          source
        );
        clusterSentiment.forEach((comp: any, index: number) => {
          let subCluster: any[] = [];
          comp.subClusters.forEach((sub: any) => {
            let subC = sub.sentimentData.filter((sent: any) =>
              expression(sent.date)
            );
            subCluster.push({
              ...sub,
              sentimentData: subC,
            });
          });

          filCluster.push({
            ...comp,
            subClusters: subCluster.filter(
              (clus) => clus.sentimentData.length !== 0
            ),
          });
        });
      } catch (e) {
        //console.log(e);
      }
    }

    // let distinctArray = [
    //   ...new Map(
    //     filteredData.map((item: any) => [item["username"], item])
    //   ).values(),
    // ];
    setFilteredSentiments(
      filCluster.filter((it) => it.subClusters.length !== 0)
    );
    setFilteredReviews(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneStar,
    twoStar,
    threeStar,
    fourStar,
    fiveStar,
    positive,
    negative,
    appData.filter,
    filteredClusterReviews,
    appData.sentiments,
    filter,
    source,
  ]);

  // UseEffct to change the reviews array for Horizontal Bar for Top Topics
  // based on cluster selection
  useEffect(() => {
    if (selectedTopic) {
      let reviewObjs: any[] = [];

      if (selectedTopic.clusterGroup !== "0") {
        if (selectedTopic.subClusters) {
          selectedTopic.subClusters.forEach((sub: any) => {
            reviewObjs = reviewObjs.concat(sub.sentimentData);
          });
        }
      }

      if (selectedTopic.clusterGroup === "0") {
        reviewObjs = reviewObjs.concat(selectedTopic.sentimentData);
      }

      setFilteredClusterReviews(reviewObjs);
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (source && source.length) {
      const { expression } = getFilterValues({
        filter: filter ? filter : appData.filter,
      });

      const clusterSentiment = getDataByStore(appData.clusterSentiment, source);
      if (clusterSentiment && clusterSentiment.length) {
        let subCluster: any[] = [];
        clusterSentiment[0].subClusters.forEach((sub: any) => {
          let subC = sub.sentimentData.filter((sent: any) =>
            expression(sent.date)
          );
          subCluster.push({
            ...sub,
            sentimentData: subC,
          });
        });

        const sortedData = [...clusterSentiment].sort(function (
          a: ClusterData,
          b: ClusterData
        ) {
          const aRatio = a.positive! / a.negative!;
          const bRatio = b.positive! / b.negative!;
          return bRatio - aRatio;
        });
        setSelectedTopic(sortedData[0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appData.sentiments,
    appData.complaintCluster,
    appData.complimentCluster,
    appData.suggestionCluster,
    appData.complaints,
    appData.compliments,
    appData.suggestions,
    appData.clusterSentiment,
    appData.trendingTopics,
    dispatch,
    appData.filter,
  ]);

  const selectAllHandler = () => {
    setPositive(true);
    setNegative(true);
    setOneStar(true);
    setTwoStar(true);
    setThreeStar(true);
    setFourStar(true);
    setFiveStar(true);
  };

  const clearAllHandler = () => {
    setPositive(false);
    setNegative(false);
    setOneStar(false);
    setTwoStar(false);
    setThreeStar(false);
    setFourStar(false);
    setFiveStar(false);
  };

  const data: Widget = useMemo(
    () => ({
      id: WIDGET_IDS.sentimentHorizontalScale,
      widgetId: `${WIDGET_IDS.sentimentHorizontalScale}--init`,
      source,
      filter: filter ? filter : appData.filter,
      type: FULLWIDTH,
      component: HORIZONTAL_GRAPH_WITH_REVIEWS,
      hidden:
        getDataByStore(
          appData.clusterSentiment,
          source && source.length ? source : []
        ).length === 0,
      positive: {
        id: uuidv4(),
        widgetId: `${"positive-scale"}--init`,
        source,
        filter: filter ? filter : appData.filter,
        type: NORMAL,
        component: HORIZONTAL_SCALE_GRAPH,
        subComponent: POSITIVE,
        reviews: createTopTopicsData(filteredSentiments),
        data: {
          setSelectedTopic: setSelectedTopic,
          selectedTopic: selectedTopic,
        },
      },
      negative: {
        id: uuidv4(),
        widgetId: `${"negative-scale"}--init`,
        type: NORMAL,
        component: HORIZONTAL_SCALE_GRAPH,
        subComponent: NEGATIVE,
        reviews: createTopTopicsData(filteredSentiments),
        data: {
          setSelectedTopic: setSelectedTopic,
          selectedTopic: selectedTopic,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTopic, filteredSentiments]
  );

  return (
    <FlexBox
      sx={{
        width: "100%",
        height: "100%",
        // background: "#FFFFFF",
        // ...(width < 800 ? { flexDirection: "column" } : {}),
      }}
    >
      <FlexBox
        sx={{
          background: "#FFFFFF",
          height: "100%",
          padding: "1.5rem",
          borderRadius: "8px",
          boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
          ...(showReviews
            ? width < 1200
              ? { width: "49%" }
              : { width: "59%" }
            : { width: "100%" }),
        }}
      >
        {/* {data.hidden ? (
          <TooltipComponent title={loadingTooltipTitle} placement="top">
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <LoadingWrapper bg={`#FFFFFF`} />
            </div>
          </TooltipComponent>
        ) : ( */}
        <FlexBox
          sx={{
            justifyContent: "space-between",
            height: "100%",
            marginBottom: "0.5rem",
            width: "100%",
            ...(showReviews || width < 880 ? { flexDirection: "column" } : {}),
          }}
        >
          <FlexBox
            sx={{
              flexDirection: "column",
              height: "100%",
              width: "100%",
              // ...(showReviews || width < 880
              //   ? { width: "100%" }
              //   : { width: "49%" }),
            }}
          >
            {HorizontalScaleGraph(
              data.positive
                ? {
                    ...data.positive,
                    ...(showReviews || width < 880 ? { hideLegend: true } : {}),
                  }
                : data,
              setShowReviews,
              !!data.hidden
            )}
          </FlexBox>
          <FlexBox
            sx={{
              flexDirection: "column",
              width: "100%",
              // ...(showReviews || width < 880
              //   ? { width: "100%", marginTop: "1rem" }
              //   : { width: "49%" }),
            }}
          >
            {HorizontalScaleGraph(
              data.negative
                ? {
                    ...data.negative,
                    ...(showReviews || width < 880 ? { hideLegend: true } : {}),
                  }
                : data,
              setShowReviews,
              !!data.hidden
            )}
          </FlexBox>
        </FlexBox>
        {/* )} */}
        <FlexBox
          sx={{
            ...(showReviews
              ? width < 1200
                ? { width: "49%" }
                : { width: "59%" }
              : { width: "100%" }),
            height: "1rem",
            background: "#FFFFFF",
            boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
            borderRadius: "0px 0px 8px 8px",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            padding: "0.8rem",
            left: 0,
          }}
        >
          <FlexBox
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              lineHeight: "16px",
              color: "#82868C",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => setShowReviews && setShowReviews(!showReviews)}
          >
            {`${showReviews ? "Hide" : "See"} reviews`}
            <FlexBox
              sx={{
                marginLeft: "4px",
                ...(showReviews
                  ? { transform: "rotate(90deg)" }
                  : { transform: "rotate(270deg)" }),
              }}
            >
              <img src={ArrowGreyIcon} alt="arrow" width="16px" height="16px" />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      {showReviews ? (
        <FlexBox
          sx={{
            ...(width < 1200 ? { width: "49%" } : { width: "39%" }),
            padding: "1.5rem",
            borderRadius: "8px",
            boxShadow: "0px 7px 20px rgba(130, 134, 140, 0.08)",
          }}
        >
          {Reviews({
            id: WIDGET_IDS.sentimentHorizontalReview,
            widgetId: `${WIDGET_IDS.sentimentHorizontalReview}--init`,
            type: FULLWIDTH,
            component: REVIEWS,
            hide: !showReviews,
            data: {
              // reviews: filteredClusterReviews,
              height: "32rem",
              positive: positive,
              setPositive: setPositive,
              negative: negative,
              setNegative: setNegative,
              oneStar: oneStar,
              setOneStar: setOneStar,
              twoStar: twoStar,
              setTwoStar: setTwoStar,
              threeStar: threeStar,
              setThreeStar: setThreeStar,
              fourStar: fourStar,
              setFourStar: setFourStar,
              fiveStar: fiveStar,
              setFiveStar: setFiveStar,
              selectAll: selectAllHandler,
              clearAll: clearAllHandler,
              filteredReviews: filteredReviews,
              setFilteredReviews: setFilteredReviews,
              reviewPoints: reviewPoints,
              clusterPoints: clusterPoints,
              itemOffset,
              setItemOffset,
              itemsPerPage,
              store: source ? source.map((i: GET_DATA_STORE) => i.store) : [],
            },
            filter: appData.filter,
          })}
        </FlexBox>
      ) : (
        <></>
      )}
    </FlexBox>
  );
};

export default HorizontalScaleGraphWithReviews;
