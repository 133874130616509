import { createSlice } from "@reduxjs/toolkit";
import {
  DATA_BY_STORE,
  setDataByStore,
} from "../../../components/shared/constants";
import {
  FeedbackGraphInit,
  SentimentOverTimeGraphInit,
} from "./GraphInitState";

// Need to move all graphs to the reducer, and make the calculations before
// rendering happens. Would take away useEffect calculations in lot of places,
// and would only need to set pre-calculated data

export const GraphSlice: any = createSlice({
  name: "graphs",
  initialState: {
    dataFetched: [],
    feedback: FeedbackGraphInit,
    sentimentOverTime: SentimentOverTimeGraphInit,
  } as any,
  reducers: {
    setFeedbackGraphData: (state, action) => {
      const existingData = JSON.parse(JSON.stringify(state["feedback"]));
      state["feedback"] = setDataByStore(existingData, action.payload[0]);
    },
    setSentimentOverTimeGraphData: (state, action) => {
      state["sentimentOverTime"] = action.payload;
    },
    setDataFetched: (state, action) => {
      state["dataFetched"] = action.payload;
    },
    removeDataSource: (state, action) => {
      const key = action.payload;
      state["feedback"] = JSON.parse(JSON.stringify(state["feedback"])).filter(
        (item: DATA_BY_STORE) =>
          item.appID !== key.appID && item.store !== key.store
      );
      state["dataFetched"] = JSON.parse(
        JSON.stringify(state["dataFetched"])
      ).filter(
        (item: DATA_BY_STORE) =>
          item.appID !== key.appID && item.store !== key.store
      );
    },
    setInit: (state, action) => {
      state["feedback"] = FeedbackGraphInit;
      state["dataFetched"] = [];
    },
  },
});

// this is for dispatch
export const GraphActions: any = GraphSlice.actions;

// this is for configureStore
export default GraphSlice.reducer;
