import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { ukoTheme } from "./components/shared/theme/index";

function App() {
  const allPages = useRoutes(routes);

  // App theme
  const appTheme = ukoTheme({
    theme: 'light',
    direction: "rtl",
    responsiveFontSizes: false,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
          <CssBaseline />
          {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
