import { Card, Box } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Small } from "../shared/wrappers/Typography";
import DislikeIcon from "../shared/icons/dislike.svg";
import UnfilledStarIcon from "../shared/icons/unfilled-star.svg";
import FilledStarIcon from "../shared/icons/filled-star.svg";
import TickIcon from "../shared/icons/tick.svg";
import TickLightIcon from "../shared/icons/tick-light.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import { getAppData, getUserDetails } from "../../services/api/Graphql.api";
import { CSSTransition } from "react-transition-group";
import {
  getDBTable,
  removeApp,
  STATUS_FLAG,
  SENTIMENT_FETCH,
  getTableKey,
  setUserDataAndRole,
} from "../shared/constants";
import { AppActions } from "../../services/store/AppStore/AppStoreSlice";
import { useDispatch } from "react-redux";
import Modal from "../shared/wrappers/Modal";
import { fetchTheDBData } from "../../services/db/dynamo";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import { getDataUsingURLFromS3 } from "../../services/db/s3";
// import { getDataFromS3 } from "../../services/db/s3";

const ImportStatus = () => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkAppData = async (appId: string) => {
      // Check if all app data has success status...
      // If yes, navigate to feedback
      // If not, show the error Modal// Check if all app data has success status...

      const app: any = await getAppData(appId);
      if (app && app.getApp) {
        if (
          [
            app.getApp.complaintStatus,
            app.getApp.complimentStatus,
            app.getApp.suggestionStatus,
            app.getApp.sentimentStatus,
          ].filter((status) => status === STATUS_FLAG.PROCESS_COMPLETE)
            .length === 4
        ) {
          // The app data is succesfully processed
          navigate("/dashboard/insights/feedback");
        } else if (
          [
            app.getApp.complaintStatus,
            app.getApp.complimentStatus,
            app.getApp.suggestionStatus,
            app.getApp.sentimentStatus,
            app.getApp.status,
          ].filter((status) =>
            [
              STATUS_FLAG.GPT_ERROR,
              STATUS_FLAG.CLUSTERING_ERROR,
              STATUS_FLAG.SENTIMENT_ERROR,
            ].includes(status)
          ).length >= 2
        ) {
          // App Data is not completely processed, all values do not have status code 7
          setError(
            "Our backend servers are down. Please wait a while before adding the application again. If you keep facing the issue, contact our developers at <a href='mailto:info@rargus.com'>info@rargus.com</a>"
          );
        }
      }
    };

    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);
        if (res.getUser.userAppId) {
          localStorage.setItem(
            "appId",
            Array.isArray(JSON.parse(res.getUser.userAppId))
              ? JSON.stringify(JSON.parse(res.getUser.userAppId))
              : JSON.stringify([JSON.parse(res.getUser.userAppId)])
          );
          checkAppData(res.getUser.userAppId);
        } else {
          navigate("/dashboard/data-source");
        }
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      checkAppData(appData.user.appUserId);
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  const [showReviews, setShowReviews] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [stepProgress, setStepProgress] = useState(0);
  const [minProg, setMinProg] = useState(0);
  const [reviewIndex, setReviewIndex] = useState(0);
  const [importComplete, setImportComplete] = useState(false);
  const [status, setStatus] = useState({
    status: 1,
    complementStatus: 0,
    complaintStatus: 0,
    suggestionStatus: 0,
    sentimentStatus: 0,
  });

  const [steps, setSteps] = useState<any[]>([]);
  const [minutes, setMinutes] = useState(15);

  useEffect(() => {
    if (minutes && minutes <= -9 && minutes > -10 && !error) {
      setError(
        "Our backend servers are down. Please wait a while before adding the application again. If you keep facing the issue, contact our developers at info@rargus.com"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMinutes((minutes) => minutes - 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(AppActions.setComplaintsData([]));
    dispatch(AppActions.setComplimentsData([]));
    dispatch(AppActions.setSuggestionsData([]));
    dispatch(AppActions.setSentimentsData([]));
    dispatch(AppActions.setComplaintCluster([]));
    dispatch(AppActions.setComplimentCluster([]));
    dispatch(AppActions.setSuggestionCluster([]));
    dispatch(AppActions.setClusterSentiment([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Math.floor(currentProgress) >= 99 && minutes <= 1) {
      // Show the import complete step
      setImportComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress]);

  useEffect(() => {
    setSteps([
      {
        text: "Add App data to Database",
        state: 1,
      },
      {
        text: "Associate App to User",
        state: 1,
      },
      {
        text: "Start app reviews import",
        state: 1,
      },
      {
        text: "Import latest 1000 reviews",
        state: 2,
      },
      {
        text: "Process Imported reviews",
        state: 3,
      },
      {
        text: "Get complaints, compliments, suggestions and sentiment data",
        state: 3,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For changing the review comments we show on the Import Status Page
  // Mapped to Sentiments Data
  useEffect(() => {
    if (appData.sentiments && appData.sentiments.length) {
      let reviewChange = setInterval(() => {
        setReviewIndex(Math.floor(Math.random() * appData.sentiments.length));
      }, 5000);
      let timeOut = setInterval(() => {
        setShowReviews((currentState) => !currentState);
      }, 30000);

      return () => {
        clearInterval(timeOut);
        clearInterval(reviewChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.sentiments]);

  useEffect(() => {
    if (
      importComplete &&
      [
        status.complaintStatus,
        status.complementStatus,
        status.suggestionStatus,
        status.sentimentStatus,
      ].filter((status) => status === STATUS_FLAG.PROCESS_COMPLETE).length === 4
    ) {
      navigate("/dashboard/insights/feedback");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importComplete]);

  const totalReviews =
    appData && appData.app.numberOfReviews
      ? appData.app.numberOfReviews < 1500
        ? appData.app.numberOfReviews
        : 1500
      : 1;

  useEffect(() => {
    const func = async () => {
      const progress = await getAppData(
        appData.appID || localStorage.getItem("appId")
      );
      // if (
      //   res &&
      //   res.listReviews &&
      //   res.listReviews.items &&
      //   res.listReviews.items.length
      // ) {
      //   setReview(res.listReviews.items);
      // }

      if (progress && progress.getApp && progress.getApp.reviewsFetched) {
        if (progress.getApp.reviewsFetched) {
          setCurrentProgress(
            progress.getApp.reviewsFetched
              ? Math.ceil((progress.getApp.reviewsFetched * 100) / totalReviews)
              : 1
          );
        }
        // If status exists, then other items also exist
        if (progress.getApp.status) {
          const {
            status,
            complementStatus,
            complaintStatus,
            suggestionStatus,
            sentimentStatus,
          } = progress.getApp;
          setStatus({
            status,
            complementStatus,
            complaintStatus,
            suggestionStatus,
            sentimentStatus,
          });
        }
      }

      if (progress && !progress.getApp) {
        setError(
          "Our backend servers are down. Please wait a while before adding the application again. If you keep facing the issue, contact our developers at info@rargus.com"
        );
      }
    };

    func();
    const interval = setInterval(() => {
      func();
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setMinProg((prev) => prev + 2);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  // Processing By GPT Done Use Effect
  useEffect(() => {
    let stepProg = 0;
    if (status.status >= STATUS_FLAG.IMPORT_DONE) {
      stepProg += 10;
    }
    if (status.sentimentStatus === STATUS_FLAG.PROCESS_COMPLETE) {
      stepProg += 10;
    }
    if (status.complaintStatus === STATUS_FLAG.PROCESS_COMPLETE) {
      stepProg += 10;
    }
    if (status.complementStatus === STATUS_FLAG.PROCESS_COMPLETE) {
      stepProg += 10;
    }
    if (status.suggestionStatus === STATUS_FLAG.PROCESS_COMPLETE) {
      stepProg += 10;
    }

    // Calculate time left based on the step progress bar shown
    let time: number = Math.ceil(9 - (stepProg / (9 * 10)) * 10);
    // Change the time only when the counter shows more
    if (time < minutes) {
      setMinutes(time);
    }

    if (minProg < (10 - time) * 6) {
      setMinProg((10 - time) * 6);
    }

    // Adding the progress until then based on time
    setStepProgress(stepProg);

    let baseSteps = [
      {
        text: "Add App data to Database",
        state: 1,
      },
      {
        text: "Associate App to User",
        state: 1,
      },
      {
        text: "Start app reviews import",
        state: 1,
      },
      {
        text: "Import latest 1000 reviews",
        state: 1,
      },
    ];

    // Import Complete Status Use Effect
    // Remove the dummy timer based import after implementation is done
    if (
      status.status === STATUS_FLAG.IMPORT_DONE &&
      ![
        status.complaintStatus,
        status.complementStatus,
        status.suggestionStatus,
        status.sentimentStatus,
      ].includes(7)
    ) {
      setSteps([
        ...baseSteps,
        {
          text: "Process Imported reviews",
          state: 2,
        },
        {
          text: "Get complaints, compliments, suggestions and sentiment data",
          state: 3,
        },
      ]);
    }

    if (
      status.status === STATUS_FLAG.IMPORT_DONE &&
      [
        status.complaintStatus,
        status.complementStatus,
        status.suggestionStatus,
        status.sentimentStatus,
      ].includes(7)
    ) {
      setSteps([
        ...baseSteps,
        {
          text: "Process Imported reviews",
          state: 1,
        },
        ...(status.sentimentStatus === STATUS_FLAG.PROCESS_COMPLETE
          ? [
              {
                text: "Sentiment Data",
                state: 1,
              },
            ]
          : []),
        ...(status.suggestionStatus === STATUS_FLAG.PROCESS_COMPLETE
          ? [
              {
                text: "Suggestions Data",
                state: 1,
              },
            ]
          : []),
        ...(status.complementStatus === STATUS_FLAG.PROCESS_COMPLETE
          ? [
              {
                text: "Compliments Data",
                state: 1,
              },
            ]
          : []),

        ...(status.complaintStatus === STATUS_FLAG.PROCESS_COMPLETE
          ? [
              {
                text: "Complaints Data",
                state: 1,
              },
            ]
          : []),
        ...([
          status.complaintStatus,
          status.complementStatus,
          status.suggestionStatus,
          status.sentimentStatus,
        ].filter((flag) => flag === STATUS_FLAG.PROCESS_COMPLETE).length === 4
          ? [
              {
                text: "Process Done",
                state: 2,
              },
            ]
          : [
              {
                text: `Get ${
                  status.complaintStatus === STATUS_FLAG.PROCESS_COMPLETE
                    ? ""
                    : "complaints"
                }${
                  status.complementStatus === STATUS_FLAG.PROCESS_COMPLETE
                    ? ""
                    : ", compliments"
                }${
                  status.suggestionStatus === STATUS_FLAG.PROCESS_COMPLETE
                    ? ""
                    : ", suggestions"
                }${
                  status.sentimentStatus === STATUS_FLAG.PROCESS_COMPLETE
                    ? ""
                    : ", sentiment"
                } data`,
                state: 2,
              },
            ]),
      ]);
    }

    if (status.sentimentStatus === STATUS_FLAG.PROCESS_COMPLETE) {
      if (
        !appData ||
        (appData && !appData.sentiments) ||
        (appData && appData.sentiments && appData.sentiments.length === 0)
      ) {
        // dispatch(AppActions.setSentimentsFetch(true));
        const func = async () => {
          try {
            const data = await fetchTheDBData(
              getDBTable(SENTIMENT_FETCH),
              localStorage.getItem("appId") || ""
            );
            dispatch(AppActions.setSentimentsData(data));
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error as needed
          }
        };

        const getS3Data = async (
          type: string,
          action: any,
          actionTwo?: any
        ) => {
          try {
            const data = await getDataUsingURLFromS3(
              localStorage.getItem("appId") || "",
              getTableKey(type)
            );
            if (actionTwo) {
              if (data.data && data.data.length) {
                dispatch(actionTwo(data.data));
              } else {
                dispatch(actionTwo(data));
              }
            }
            // When 2nd action to store doesn't exist, in case of sentiments and trending topics,
            // dispatch to 1st action for store
            if (!actionTwo && action) {
              if (data.data && data.data.length) {
                dispatch(action(data.data));
              } else {
                dispatch(action(data));
              }
            }
          } catch (e) {
            // console.log("Error Occured", e);
            // console.log("Trigger older flow", type);
            func();
          }
        };

        if (
          !appData ||
          (appData && !appData.sentiments) ||
          (appData && appData.sentiments && appData.sentiments.length === 0)
        ) {
          getS3Data(SENTIMENT_FETCH, AppActions.setSentimentsData);
        }
      }
    }

    if (
      [
        status.complaintStatus,
        status.complementStatus,
        status.suggestionStatus,
        status.sentimentStatus,
      ].filter((flag) => flag === STATUS_FLAG.PROCESS_COMPLETE).length === 4
    ) {
      setImportComplete(true);
    }

    // Error state check
    let errorStates = 0;
    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(status.status)
    ) {
      console.log("Common status Error occured");
      errorStates++;
    }
    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(status.complaintStatus)
    ) {
      dispatch(
        AppActions.setToastData([
          ...appData.toast,
          {
            description: "Error Occured during processing Complaints data",
          },
        ])
      );
      console.log("Complaints Error");
      errorStates++;
    }

    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(status.complementStatus)
    ) {
      dispatch(
        AppActions.setToastData([
          ...appData.toast,
          {
            description: "Error Occured during processing Compliments data",
          },
        ])
      );
      console.log("Compliment Error");
      errorStates++;
    }

    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(status.suggestionStatus)
    ) {
      dispatch(
        AppActions.setToastData([
          ...appData.toast,
          {
            description: "Error Occured during processing Suggestions data",
          },
        ])
      );
      console.log("Suggestion Error");
      errorStates++;
    }

    if (
      [
        STATUS_FLAG.GPT_ERROR,
        STATUS_FLAG.CLUSTERING_ERROR,
        STATUS_FLAG.SENTIMENT_ERROR,
      ].includes(status.sentimentStatus)
    ) {
      dispatch(
        AppActions.setToastData([
          ...appData.toast,
          {
            description: "Error Occured during processing Sentiments data",
          },
        ])
      );
      console.log("Sentiment Error");
      errorStates++;
    }

    // Waiting for error to happen in two Lambda's before opening the pop-up and stopping the operation
    if (
      errorStates >= 1 &&
      [
        status.complaintStatus,
        status.complementStatus,
        status.suggestionStatus,
        status.sentimentStatus,
      ].filter((flag) => flag === STATUS_FLAG.PROCESS_COMPLETE).length !== 4
    ) {
      setError(
        "Our backend servers are down. Please wait a while before adding the application again. If you keep facing the issue, contact our developers at info@rargus.com"
      );
    } else setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch]);

  const createStepComponent = (label: string, state: number) => {
    return (
      <FlexBox
        key={`step--${label}--${state}`}
        sx={{
          width: "100%",
          flexDirection: "row",
          margin: "10px 0 0 0",
          ...(state === 1
            ? { color: "#0B0E1E" }
            : state === 2
            ? { fontSize: "20px", fontWeight: 500, lineHeight: "21px" }
            : { color: "#82868C", fill: "#82868C" }),
        }}
      >
        <FlexBox
          sx={{
            width: "100%",
          }}
        >
          <img
            src={state === 3 ? TickLightIcon : TickIcon}
            alt="Done"
            style={{ marginRight: "20px" }}
          />{" "}
          {label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
        </FlexBox>{" "}
      </FlexBox>
    );
  };
  return (
    <Fragment>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
        }}
      >
        <FlexBox
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: "1rem",
            top: "0.3rem",
          }}
        >
          <img
            src={RargusIcon}
            alt="Rargus Icon"
            width="38"
            height="38"
            style={{
              marginRight: "0.5rem",
            }}
          />
          <img src={RargusTextIcon} alt="Rargus Icon" width="68" height="70" />
        </FlexBox>
        <Card
          sx={{
            padding: 4,
            ...(width < 800
              ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
              : { maxWidth: "600px", minWidth: "568px", minHeight: "327px" }),
            border: "none",
            boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            borderRadius: "16px",
            height: "fit-content",
          }}
        >
          {/* <ToastContainer /> */}
          <FlexBox flexDirection="column">
            <FlexBox
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FlexBox
                style={{
                  fontSize: "32px",
                  fontWeight: 700,
                  lineHeight: "38px",
                  color: "#3579E3",
                  fontFamily: "Barlow",
                  marginBottom: "20px",
                }}
              >
                {importComplete
                  ? "Data imported!"
                  : stepProgress > 99
                  ? "Processing data..."
                  : "Importing data..."}
              </FlexBox>
              <FlexBox
                sx={{
                  textAlign: "right",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {importComplete
                  ? ""
                  : minutes > 1
                  ? `${minutes} minutes left`
                  : `less than a minute left`}
              </FlexBox>
            </FlexBox>
            <FlexBox
              sx={{
                flexDirection: "row",
                marginTop: "16px",
              }}
            >
              <FlexBox
                sx={{
                  width: `${stepProgress + minProg}%`,
                  height: "6px",
                  background: "#3579E3",
                  borderRadius: "5px",
                }}
              ></FlexBox>
              <FlexBox
                sx={{
                  width: `${100 - (stepProgress + minProg)}%`,
                  height: "6px",
                  background: "#E3E6EB",
                  borderRadius: "5px",
                }}
              ></FlexBox>
            </FlexBox>
            {/* {importComplete ? (
            <FlexBox
              sx={{
                marginTop: "20px",
                borderRadius: "8px",
                width: "100%",
                minHeight: "262px",
                height: "fit-content",
                padding: "20px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={DoneIcon} alt="Done" width="160px" height="160px" />
            </FlexBox>
          ) : ( */}
            <>
              {showReviews &&
              appData &&
              appData.sentiments &&
              appData.sentiments.length ? (
                <CSSTransition
                  nodeRef={nodeRef}
                  in={true}
                  timeout={200}
                  classNames="my-node"
                >
                  <div ref={nodeRef}>
                    <React.Fragment>
                      <FlexBox
                        sx={{
                          marginTop: "20px",
                          background: "#F5F5F5",
                          borderRadius: "8px",
                          width: "100%",
                          minHeight: "192px",
                          height: "fit-content",
                          padding: "20px",
                          flexDirection: "column",
                        }}
                      >
                        <FlexBox
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <FlexBox>
                            <FlexBox
                              sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                // Remove the color, and add the user image below on Integration
                                background: "purple",
                                width: "24px",
                                height: "24px",
                                marginTop: "4px",
                              }}
                            >
                              {/* <img
                                src={appData.sentiments[reviewIndex].icon}
                                alt=""
                                width="12px"
                                height="12px"
                                style={{ marginRight: "2px" }}
                              /> */}
                            </FlexBox>
                            <FlexBox
                              sx={{
                                flexDirection: "column",
                                textAlign: "left",
                                alignItems: "left",
                                paddingLeft: "10px",
                              }}
                            >
                              <FlexBox
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "13px",
                                  lineHeight: "16px",
                                  color: "#3A3C40",
                                }}
                              >
                                {appData.sentiments[reviewIndex].reviewID}
                              </FlexBox>
                              <FlexBox
                                sx={{
                                  marginTop: "4px",
                                }}
                              >
                                {[1, 2, 3, 4, 5].map((r: number) => {
                                  if (
                                    r <= appData.sentiments[reviewIndex].rating
                                  ) {
                                    return (
                                      <img
                                        src={FilledStarIcon}
                                        alt="Star"
                                        width="12px"
                                        height="12px"
                                        style={{ marginRight: "2px" }}
                                        key={`filled-star-${r}`}
                                      />
                                    );
                                  }
                                  return (
                                    <img
                                      src={UnfilledStarIcon}
                                      alt="Star"
                                      width="12px"
                                      height="12px"
                                      style={{ marginRight: "2px" }}
                                      key={`unfilled-star-${r}`}
                                    />
                                  );
                                })}
                              </FlexBox>
                            </FlexBox>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              textAlign: "right",
                              alignItems: "center",
                              ...(appData.sentiments[reviewIndex].sentimentScore
                                ? parseFloat(
                                    appData.sentiments[reviewIndex]
                                      .sentimentScore
                                  ) > 0
                                  ? { transform: "scale(-1)" }
                                  : {}
                                : { display: "none" }),
                            }}
                          >
                            <img
                              src={DislikeIcon}
                              alt="Dislike"
                              width="24px"
                              height="24px"
                            />
                          </FlexBox>
                        </FlexBox>
                        <FlexBox
                          sx={{
                            height: "83px",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#000000",
                            marginTop: "15px",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {appData.sentiments[reviewIndex].content.length > 330
                            ? `${appData.sentiments[reviewIndex].content.slice(
                                0,
                                330
                              )}...`
                            : appData.sentiments[reviewIndex].content}
                        </FlexBox>
                      </FlexBox>
                      <FlexBox
                        sx={{
                          marginTop: "20px",
                          background:
                            "linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 109.18%)",
                          borderRadius: "8px",
                          width: "100%",
                          height: "49px",
                        }}
                      ></FlexBox>
                    </React.Fragment>
                  </div>
                </CSSTransition>
              ) : (
                <FlexBox
                  sx={{
                    marginTop: "20px",
                    borderRadius: "8px",
                    width: "100%",
                    minHeight: "262px",
                    height: "fit-content",
                    padding: "20px",
                    flexDirection: "column",
                  }}
                >
                  {steps.map((item) =>
                    createStepComponent(item.text, item.state)
                  )}
                </FlexBox>
              )}
            </>
            {/* )} */}
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Box sx={{ mt: 2 }}>
                {/* {importComplete ? (
                <Button
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={() => navigate("/dashboard/insights/feedback")}
                >
                  Go to Dashboard
                </Button>
              ) : ( */}
                <FlexBox
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeApp(
                      localStorage.getItem("username") ||
                        appData.user.email ||
                        "",
                      dispatch,
                      navigate
                    );
                  }}
                >
                  <Small
                    color="primary.main"
                    sx={{ fontSize: "14px", lineHeight: "21px" }}
                  >
                    Cancel
                  </Small>
                </FlexBox>
                {/* )} */}
              </Box>
            </Box>
          </FlexBox>
        </Card>
      </FlexBox>
      {error && error.length !== 0 ? (
        <Modal
          onClose={() => {
            setError("");
            // Removing the app associated with the user
            removeApp(
              localStorage.getItem("username") || appData.user.email || "",
              dispatch,
              navigate
            );
            navigate("/dashboard/home");
          }}
          title={"An error occured"}
          show={error.length !== 0}
        >
          <FlexBox>{error}</FlexBox>
        </Modal>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default ImportStatus;
