import FlexBox from "../shared/wrappers/FlexBox";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../services/store/store";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../services/api/Graphql.api";
import LoadingWrapper from "../shared/wrappers/LoadingWrapper";
import { removeUserTokens } from "../../services/context/Account";
import { getUserAppIDs, setUserDataAndRole } from "../shared/constants";

const Redirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appData: any = useSelector((state: RootState) => state.app);
  const [checkApp, setCheckApp] = useState(true);

  useEffect(() => {
    const setUserData = async (email: string) => {
      setCheckApp(false);
      const res: any = await getUserDetails(email);
      if (res && res.getUser) {
        setUserDataAndRole(dispatch, res.getUser);
        localStorage.setItem("username", res.getUser.email);

        const appIDs = await getUserAppIDs(res.getUser);
        //console.log(appIDs);
        if (appIDs && appIDs.length && appIDs !== "[]") {
          localStorage.setItem("appId", appIDs);
          // console.log("Navigate Now");
          navigate("/dashboard/insights/feedback");
        } else {
          // User has an empty array in userAppId
          navigate("/dashboard/home");
        }
      } else {
        removeUserTokens();
      }
    };

    if (appData && appData.user && appData.user.appUserId) {
      navigate("/dashboard/insights/feedback");
    }

    if (checkApp) {
      if (
        !appData ||
        (appData && !appData.user) ||
        (appData && appData.user && !appData.user.userAppId)
      ) {
        setUserData(localStorage.getItem("username") || appData.user.email);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        background: "#F5F5F5",
      }}
    >
      <LoadingWrapper />
    </FlexBox>
  );
};

export default Redirect;
