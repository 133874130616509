import { Card } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import { Fragment, useEffect, useState } from "react";
import Profile from "./Profile";
import DataSources from "./DataSources";
import AddApp from "../AddApp/AddApp";
import { getUserRights, SETTINGS_PAGE, STORE } from "../shared/constants";
import AddAha from "../Aha/AddAha";
import IdleImport from "../AddApp/IdleImport";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/store";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";
import AdminManagement from "./AdminManagement";
import AddZapierTemplate from "../ZapierIntegration/AddZapierTemplate";
import AddSlack from "../SlackIntegration/AddSlack";
import { useLocation } from "react-router";
import AddAmazon from "../Amazon/AddAmazon";
import UserSettings from "./UserSettings";
import AddGithub from "../Github/AddGithub";

export type SettingsProps = {
  initValues: any;
};

const Settings = ({ initValues }: SettingsProps) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(1);
  const [openLocation, setOpenLocation] = useState(false);
  const { width } = useWindowDimensions();

  const appData: any = useSelector((state: RootState) => state.app);

  const [userRights, setUserRights] = useState<
    | {
        isAdmin?: boolean;
        zapierAccess?: boolean;
        experimental?: boolean;
      }
    | undefined
  >();

  useEffect(() => {
    if (appData.user && appData.user.userRights) {
      setUserRights(getUserRights(appData.user));
    }
  }, [appData.user]);

  // Set the Data source here, and then let the user move forward in Selection from here
  const [selectedDataSource, setSelectedDataSource] = useState<
    string | undefined
  >();

  // Set the state if coming from any source
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.selectedDataSource &&
      !openLocation
    ) {
      setSelectedDataSource(location.state.selectedDataSource);
      setOpenLocation(true);
    }
  }, [location, openLocation]);

  return (
    <FlexBox
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {selectedDataSource && selectedDataSource.length ? (
        selectedDataSource === STORE["apple-play-store"] ? (
          <FlexBox
            sx={{
              width: "100%",
            }}
          >
            <AddApp
              location={SETTINGS_PAGE}
              store={
                appData && appData.store && appData.store.length
                  ? appData.store
                  : []
              }
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </FlexBox>
        ) : selectedDataSource === STORE.aha ? (
          <Fragment>
            <AddAha
              location={SETTINGS_PAGE}
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </Fragment>
        ) : selectedDataSource === STORE.zapier ? (
          <Fragment>
            <AddZapierTemplate
              location={SETTINGS_PAGE}
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </Fragment>
        ) : selectedDataSource === STORE.slack ? (
          <Fragment>
            <AddSlack
              location={SETTINGS_PAGE}
              step={openLocation ? 1 : 0}
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </Fragment>
        ) : selectedDataSource === STORE.amazon ? (
          <Fragment>
            <AddAmazon
              location={SETTINGS_PAGE}
              step={openLocation ? 1 : 0}
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </Fragment>
        ) : selectedDataSource === STORE.github ? (
          <Fragment>
            <AddGithub
              location={SETTINGS_PAGE}
              step={openLocation ? 1 : 0}
              onAdd={() => {
                setSelectedDataSource("import");
              }}
              onBack={() => {
                setSelectedDataSource(undefined);
              }}
            />
          </Fragment>
        ) : (
          selectedDataSource === "import" && (
            <Fragment>
              <IdleImport
                location={SETTINGS_PAGE}
                onBack={() => {
                  setSelectedDataSource(undefined);
                }}
              />
            </Fragment>
          )
        )
      ) : (
        <Fragment>
          <FlexBox
            sx={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              background: "#F5F5F5",
              width: "100%",
              boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
            }}
          >
            <Card
              sx={{
                padding: "32px 32px 0 32px",
                maxWidth: "600px",
                border: "none",
                boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
                borderRadius: "16px",
                ...(width < 800
                  ? { width: "90%", marginTop: "5rem", marginBottom: "3rem" }
                  : {
                      maxWidth: "600px",
                      minWidth: "600px",
                      minHeight: "620px",
                    }),
                // minWidth: "600px",
                // minHeight: "620px",
                height: "fit-content",
              }}
            >
              <FlexBox
                flexDirection="column"
                justifyContent="center"
                mb={5}
                sx={{
                  height: "100%",
                }}
              >
                <FlexBox
                  style={{
                    fontSize: "32px",
                    fontWeight: 700,
                    lineHeight: "38px",
                    color: "#3579E3",
                    fontFamily: "Barlow",
                  }}
                >
                  Settings
                </FlexBox>
                <FlexBox
                  sx={{
                    marginTop: "0.75rem",
                    width: "100%",
                    paddingBottom: "1rem",
                    borderBottom: "1.5px solid  #E3E6EB",
                    cursor: "pointer",
                  }}
                >
                  <FlexBox
                    sx={{
                      ...(activeIndex === 1
                        ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
                        : { backgroundColor: "white", color: "#82868C" }),

                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      minHeight: "32px",
                      height: "fit-content",
                      padding: "10px 15px",
                      borderRadius: "4px",
                    }}
                    onClick={() => setActiveIndex(1)}
                  >
                    Profile
                  </FlexBox>
                  <FlexBox
                    sx={{
                      ...(activeIndex === 2
                        ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
                        : { backgroundColor: "white", color: "#82868C" }),
                      width: "fit-content",
                      minHeight: "32px",
                      height: "fit-content",
                      marginLeft: "1rem",
                      padding: "10px 15px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                    }}
                    onClick={() => setActiveIndex(2)}
                  >
                    Add or remove data sources
                  </FlexBox>
                  {userRights &&
                    userRights.isAdmin &&
                    userRights.isAdmin === true && (
                      <FlexBox
                        sx={{
                          ...(activeIndex === 3
                            ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
                            : { backgroundColor: "white", color: "#82868C" }),
                          width: "fit-content",
                          minHeight: "32px",
                          height: "fit-content",
                          marginLeft: "1rem",
                          padding: "10px 15px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                        onClick={() => setActiveIndex(3)}
                      >
                        Account management
                      </FlexBox>
                    )}
                  {userRights &&
                    userRights.experimental &&
                    userRights.experimental === true && (
                      <FlexBox
                        sx={{
                          ...(activeIndex === 4
                            ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
                            : { backgroundColor: "white", color: "#82868C" }),
                          width: "fit-content",
                          minHeight: "32px",
                          height: "fit-content",
                          marginLeft: "1rem",
                          padding: "10px 15px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                        onClick={() => setActiveIndex(4)}
                      >
                        Experimental
                      </FlexBox>
                    )}
                  {/* {userRights &&
                    userRights.zapierAccess &&
                    userRights.zapierAccess === true && (
                      <FlexBox
                        sx={{
                          ...(activeIndex === 4
                            ? { backgroundColor: "#D7E5FA", color: "#0B0E1E" }
                            : { backgroundColor: "white", color: "#82868C" }),
                          width: "fit-content",
                          minHeight: "32px",
                          height: "fit-content",
                          marginLeft: "1rem",
                          padding: "10px 15px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                        onClick={() => setActiveIndex(4)}
                      >
                        Zapier Integration
                      </FlexBox>
                    )} */}
                </FlexBox>

                {activeIndex && activeIndex === 2 ? (
                  <DataSources
                    selectedDataSource={selectedDataSource}
                    setSelectedDataSource={setSelectedDataSource}
                  />
                ) : activeIndex && activeIndex === 1 ? (
                  <Profile initValues={initValues} />
                ) : activeIndex &&
                  activeIndex === 3 &&
                  userRights &&
                  userRights.isAdmin &&
                  userRights.isAdmin === true ? (
                  <AdminManagement />
                ) : activeIndex && activeIndex === 4 ? (
                  <UserSettings initValues={{ user: appData.user }} />
                ) : (
                  <></>
                )}
              </FlexBox>
            </Card>
          </FlexBox>

          {/* {showDelete ? (
        <ImportNotificationsModal
          onClose={() => {
            navigate("/dashboard/insights/feedback");
            setShowDelete(false);
          }}
          title={"Import in progress"}
          content={
            "The app import is in progress. You will be notified once the import is done."
          }
          show={showDelete}
          setShow={setShowDelete}
        ></ImportNotificationsModal>
      ) : (
        <></>
      )} */}
        </Fragment>
      )}
    </FlexBox>
  );
};

export default Settings;
