import { ReactElement, Fragment } from "react";
import FlexBox from "../shared/wrappers/FlexBox";
import RargusIcon from "../shared/icons/rargus.svg";
import RargusTextIcon from "../shared/icons/rargus-text.svg";
import useWindowDimensions from "../../services/hooks/useWindowDimensions";

interface AuthLayoutWrapperProps {
  children: ReactElement;
}

// Wrapper around Login, Register and Forgot Password Component
const AuthLayoutWrapper = ({ children }: AuthLayoutWrapperProps) => {
  const { width } = useWindowDimensions();
  return (
    <Fragment>
      <FlexBox
        sx={{
          height: "100%",
          display: "flex",
          overflowY: "auto",
          ...(width > 800
            ? { flexDirection: "row" }
            : { flexDirection: "column" }),
        }}
      >
        <FlexBox
          sx={{
            ...(width > 800
              ? { width: "220px", height: "100%", flexDirection: "column" }
              : {
                  width: "100%",
                  height: "5rem",
                  flexDirection: "row",
                  paddingTop: "0.3rem",
                }),

            background: "#F5F5F5",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={RargusIcon}
            alt="Rargus Logo"
            {...(width > 800
              ? { width: "120", height: "120" }
              : { width: "30", height: "30" })}
            style={{
              marginBottom: "0.5rem",
            }}
          />
          <img
            src={RargusTextIcon}
            alt="Rargus Icon"
            {...(width > 800
              ? { width: "105", height: "34" }
              : { width: "105", height: "20" })}
          />
        </FlexBox>
        {children}
      </FlexBox>
    </Fragment>
  );
};

export default AuthLayoutWrapper;
