import { Box, Button, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { useState, useContext } from "react";
import { FullTextFieldWrapper } from "../shared/wrappers/StyledComponents";
import { Paragraph, Small } from "../shared/wrappers/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { FormContext, FormContextAttr, REPO } from "./AddGithub";
import FlexBox from "../shared/wrappers/FlexBox";
import PrivateIcon from "../shared/icons/private.svg";
import PublicIcon from "../shared/icons/public.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router";
import { SETTINGS_PAGE } from "../shared/constants";

const RepoType = () => {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    repoType,
    setRepoType,
  } = useContext(FormContext) as FormContextAttr;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: {
    type: string;
  } = { type: repoType, ...formData };

  const navigate = useNavigate();
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Import type is required"),
  });

  const { values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading(true);
      setError("");
      try {
        if (values.type !== "") {
          const data = { ...formData, type: values.type };
          setRepoType(values.type);
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }
      } catch (e: any) {
        setError(e.message);
      }

      setLoading(false);
    },
  });

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "85%",
      }}
    >
      <FlexBox
        style={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "38px",
          color: "#3579E3",
          fontFamily: "Barlow",
          marginBottom: "20px",
        }}
      >
        Please select import type
      </FlexBox>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "row",
          }}
        >
          <RadioGroup
            name="type"
            id="type"
            onChange={handleChange}
            value={values.type}
            onBlur={handleBlur}
          >
            <FullTextFieldWrapper>
              <FlexBox
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Radio value={REPO.withPAT} />
                </FlexBox>
                <FlexBox
                  sx={{
                    marginTop: "0.3rem",
                    width: "20%",
                  }}
                >
                  <FlexBox
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignitems: "center",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                        alignitems: "center",
                      }}
                    >
                      <LazyLoadImage
                        key={`first--icon`}
                        alt={"Type"}
                        src={PrivateIcon}
                        height={"32"}
                      />
                    </FlexBox>
                    <FlexBox
                      sx={{
                        color: "#82868C",
                      }}
                    >
                      Private
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignitems: "center",
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                        alignitems: "center",
                      }}
                    >
                      <LazyLoadImage
                        key={`second--icon`}
                        alt={"Type"}
                        src={PublicIcon}
                        height={"32"}
                      />
                    </FlexBox>
                    <FlexBox
                      sx={{
                        color: "#82868C",
                      }}
                    >
                      Public
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    {"With Personal Access Token (PAT)"}
                  </Paragraph>
                  <Small>
                    With PAT, issues from both private and public repos can be
                    imported.
                  </Small>
                </FlexBox>
              </FlexBox>
            </FullTextFieldWrapper>
            <FullTextFieldWrapper>
              <FlexBox
                sx={{
                  flexDirection: "row",
                }}
              >
                <FlexBox
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Radio value={REPO.withoutPAT} />
                </FlexBox>
                <FlexBox
                  sx={{
                    marginTop: "0.3rem",
                    width: "20%",
                  }}
                >
                  <FlexBox
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignitems: "center",
                      marginRight: "0.5rem",
                    }}
                  >
                    <FlexBox
                      sx={{
                        justifyContent: "center",
                        alignitems: "center",
                      }}
                    >
                      <LazyLoadImage
                        key={`second--icon`}
                        alt={"Type"}
                        src={PublicIcon}
                        height={"32"}
                      />
                    </FlexBox>
                    <FlexBox
                      sx={{
                        color: "#82868C",
                      }}
                    >
                      Public
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Paragraph fontWeight={600} mb={1} mt={1}>
                    {"Without Personal Access Token (PAT)"}
                  </Paragraph>
                  <Small>
                    Without PAT, only issues from public repo can be imported.
                  </Small>
                </FlexBox>
              </FlexBox>
            </FullTextFieldWrapper>
          </RadioGroup>
          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                {"Fetching repositories"}
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                {"Continue"}
              </Button>
            )}
          </Box>
          {location &&
          location.pathname &&
          !location.pathname.includes(SETTINGS_PAGE) ? (
            <Box
              sx={{
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Small
                color="primary.main"
                sx={{ fontSize: "14px", lineHeight: "21px", cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard/data-source");
                }}
              >
                Back
              </Small>
            </Box>
          ) : (
            <></>
          )}
        </FlexBox>
      </form>
    </FlexBox>
  );
};

export default RepoType;
