import FlexBox from "../shared/wrappers/FlexBox";
import { Review, ReviewPoint } from "../Analytics/Analytics";
import { months } from "../shared/constants";
import Highlighter from "react-highlight-words";

type IdeaProp = {
  review: Review;
  index: number;
  reviewPoints: ReviewPoint[];
  highlightKeywords?: string[];
};

const IdeaComponent = ({
  review,
  index,
  reviewPoints,
  highlightKeywords,
}: IdeaProp) => {
  const getDate = (dateStr: string) => {
    const dateObj = new Date(dateStr);

    return `${
      months[dateObj.getMonth()]
    } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  };
  let reviewId =
    review.reviewID.split("##$##")[0] ||
    review.reviewID.split("-")[0] ||
    `Review ${index + 1}`;

  let referenceId =
    review.reviewID.split("##$##")[1] || review.reviewID.split("-")[1] || ``;

  let ideaStatus = "Needs review";

  const getMainPoints = (mainPoints: string) => {
    const pointsArray = mainPoints.split("\n");
    return pointsArray.map((point) => point.replace("-", ""));
  };

  let clusteredPoints: ReviewPoint[] = [];
  if (reviewPoints && reviewPoints.length) {
    clusteredPoints = reviewPoints.filter(
      (rev: ReviewPoint) => review.reviewID === rev.reviewId
    );
  }

  return (
    <FlexBox
      sx={{
        marginTop: "4px",
        marginBottom: "16px",
        background: "#F5F5F5",
        height: "fit-content",
        padding: "20px",
        flexDirection: "column",
        boxShadow: "0px 2px 4px rgba(130, 134, 140, 0.16)",
        borderRadius: "8px",
        position: "relative",
        width: "100%",
      }}
      key={`ideas-${index}`}
    >
      <FlexBox
        sx={{
          width: "100%",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              flexDirection: "column",
              textAlign: "left",
              alignItems: "left",
            }}
          >
            <FlexBox
              sx={{
                textTransform: "uppercase",
                fondWeight: 500,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              <FlexBox
                sx={{
                  color: "#3579E3",
                }}
              >
                {"Created"}
              </FlexBox>
              <FlexBox
                sx={{
                  color: "#0B0E1E",
                  marginLeft: "0.5rem",
                }}
              >
                {getDate(review.date)}
              </FlexBox>
            </FlexBox>
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#3A3C40",
                marginTop: "0.5rem",
              }}
            >
              {review.title || reviewId}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {/* Main Points Section */}
      <FlexBox
        sx={{
          marginTop: "0.8rem",
          flexDirection: "column",
        }}
      >
        <FlexBox
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FlexBox
            sx={{
              marginRight: "0.3rem",
              color: "#3A3C40",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            {"Main points"}
          </FlexBox>
          <FlexBox
            sx={{
              flexGrow: 1,
              height: "1px",
              background: "#E3E6EB",
            }}
          ></FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: "column",
            marginLeft: "1rem",
            marginTop: "0.3rem",
            color: "#000000",
          }}
        >
          {getMainPoints(
            review.sentimentCategory || review.sentiment || ""
          ).map((point: any, index: number) => (
            <FlexBox
              key={`idea-point-${index}-${point}`}
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "16px",
                display: "list-item",
                listStyleType: "disc",
                ...(clusteredPoints.filter((clus: ReviewPoint) => {
                  // console.log(
                  //   point
                  //     .toLowerCase()
                  //     // replace special characters and comma
                  //     .replace(/[^A-Za-z ]*/, "")
                  //     .replace(",", "")
                  //     .replace(/['"]+/g, "")
                  //     .replaceAll("-", "")
                  //     .replaceAll(":", ""),
                  //   clus.point
                  //     // Replacing un-required characters in the cluster point
                  //     .replaceAll("-", "")
                  //     .replace(/(^"|"|"$)/g, "")
                  //     .trim()
                  //     .toLowerCase()
                  //     // replace
                  //     .replace(/[^A-Za-z ]*/, "")
                  //     .replace(",", "")
                  //     .replace(/['"]+/g, "")
                  //     .replaceAll(":", "")
                  // );
                  return (
                    point
                      .toLowerCase()
                      // replace special characters and comma
                      .replace(/[^A-Za-z ]*/, "")
                      .replace(",", "")
                      .replace(/['"]+/g, "")
                      .replaceAll("-", "")
                      .replaceAll(":", "")
                      .includes(
                        clus.point
                          // Replacing un-required characters in the cluster point
                          .replaceAll("-", "")
                          .replace(/(^"|"|"$)/g, "")
                          .trim()
                          .toLowerCase()
                          // replace
                          .replace(/[^A-Za-z ]*/, "")
                          .replace(",", "")
                          .replace(/['"]+/g, "")
                          .replaceAll(":", "")
                      )
                  );
                }).length !== 0
                  ? { fontWeight: 700 }
                  : {}),
              }}
            >
              {point}
            </FlexBox>
          ))}
        </FlexBox>

        <FlexBox
          sx={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "#0B0E1E",
            marginLeft: "0.5rem",
            marginTop: "0.3rem",
            alignItems: "center",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              background: "#FFFFFF",
              borderRadius: "4px",
              padding: "2px 6px",
            }}
          >
            {review.ideaReference || referenceId}
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              background: "#FBDFEF",
              borderRadius: "4px",
              padding: "2px 6px",
              marginLeft: "1rem",
            }}
          >
            {review.workspaceName || ""}
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 700,
              marginLeft: "1rem",
            }}
          >
            {review.status || ideaStatus}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{
          // minHeight: "40px",
          height: "fit-content",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000000",
          marginTop: "15px",
          marginBottom: "15px",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        <Highlighter
          searchWords={highlightKeywords ? highlightKeywords : []}
          // searchWords={[]}
          autoEscape={true}
          textToHighlight={review.content}
        />
      </FlexBox>
      {review.replyContent && review.replyContent.length ? (
        <FlexBox
          sx={{
            marginLeft: "0.3rem",
            marginRight: "0.3rem",
            flexDirection: "column",
          }}
        >
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#82868C",
            }}
          >
            Developer replied
          </FlexBox>
          <FlexBox
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              color: "#000000",
              marginTop: "4px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {review.replyContent}
          </FlexBox>
        </FlexBox>
      ) : (
        <></>
      )}
      <FlexBox
        sx={{
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 500,
          alignItems: "center",
          width: "100%",
        }}
      >
        <FlexBox
          sx={{
            color: "#0B0E1E",
            background: "#D7E5FA",
            borderRadius: "4px",
            padding: "2px 6px",
          }}
        >
          {`${review.rating} ${review.rating <= 1 ? "vote" : "votes"}`}
        </FlexBox>
        <FlexBox
          sx={{
            marginLeft: "1rem",
            color: "#82868C",
          }}
        >{`Submitted by ${review.username}`}</FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default IdeaComponent;
