import { Card } from "@mui/material";
import FlexBox from "../shared/wrappers/FlexBox";
import DashboardLayoutWrapper from "../home/DashboardLayoutWrapper";
import { useNavigate } from "react-router";
import { createContext, useEffect, useState } from "react";
import Step from "./Step";
import Stepper from "./AdminStepper";
import { fetchAllAppsFromDb } from "../../services/db/dynamo";
import { STATUS_FLAG } from "../shared/constants";

export type FormContextAttr = {
  activeStepIndex: number;
  setActiveStepIndex: (val: number) => void;
  apps: any[];
};

export const FormContext = createContext<FormContextAttr | null>(null);

// Component to manage admin functionalities
const HomeTest = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("username");

  // States for step form and form data
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [apps, setApps] = useState<any[]>([]);

  let ADMIN_IDS: any = [];
  try {
    ADMIN_IDS = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL_IDS || "[]");
  } catch (e) {
    ADMIN_IDS = process.env.REACT_APP_ADMIN_EMAIL_IDS || [];
  }

  useEffect(() => {
    if (ADMIN_IDS) {
      if (!ADMIN_IDS.includes(user || "")) {
        navigate("/dashboard/insights/feedback");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchApps = async () => {
      const apps = await fetchAllAppsFromDb();

      // Fliter the list of apps
      if (apps && apps.length) {
        let filteredApps: any[] = [];
        apps.forEach((item: any) => {
          if (
            item.complaintStatus &&
            item.complementStatus &&
            item.suggestionStatus &&
            item.sentimentStatus &&
            item.complaintStatus === STATUS_FLAG.PROCESS_COMPLETE &&
            item.complementStatus === STATUS_FLAG.PROCESS_COMPLETE &&
            item.suggestionStatus === STATUS_FLAG.PROCESS_COMPLETE &&
            item.sentimentStatus === STATUS_FLAG.PROCESS_COMPLETE
          ) {
            filteredApps.push(item);
          }
        });

        // Sort based on the creation date
        setApps(
          filteredApps.sort(
            (a: any, b: any) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );
      }
    };

    fetchApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayoutWrapper>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "#F5F5F5",
          width: "100%",
          boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
        }}
      >
        <FormContext.Provider
          value={{
            activeStepIndex,
            setActiveStepIndex,
            apps,
          }}
        >
          <Card
            sx={{
              padding: 4,
              maxWidth: "600px",
              border: "none",
              boxShadow: "0px 4px 23px rgba(98, 151, 233, 0.12)",
              borderRadius: "16px",
              minWidth: "568px",
              minHeight: "250px",
              height: "fit-content",
            }}
          >
            <FlexBox flexDirection="column" justifyContent="center" mb={2}>
              <FlexBox
                style={{
                  fontSize: "32px",
                  fontWeight: 700,
                  lineHeight: "38px",
                  color: "#3579E3",
                  fontFamily: "Barlow",
                }}
              >
                Admin Board
              </FlexBox>
            </FlexBox>
            <Stepper
              steps={[
                "App Switch Page",
                "Developer Page",
                "Create Organisation",
              ]}
              activeStepIndex={activeStepIndex}
            />
            <Step />
          </Card>
        </FormContext.Provider>
      </FlexBox>
    </DashboardLayoutWrapper>
  );
};

export default HomeTest;
